import { USERS_ACCOUNT_CREATE } from '../../types'
import API from '../../../utils/api'
// import { newUriAPI } from '../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${USERS_ACCOUNT_CREATE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${USERS_ACCOUNT_CREATE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${USERS_ACCOUNT_CREATE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const reset = (data) => {
	return {
		type: `${USERS_ACCOUNT_CREATE}_RESET`,
		payload: {
			...data,
		},
	}
}

export const requestUserAccountCreate = (data, type_dispatch = 'default') => {
	return async (dispatch) => {
		if (type_dispatch == 'RESET') {
			dispatch(reset())
		} else {
			dispatch(loading())
			try {
				let response = await API({
					method: 'POST',
					data: data,
					url: '/users/user/create',
				})
				if (response) {
					if (response.data.error_backend) {
						dispatch(failure(response.data.error_backend))
					} else {
						dispatch(success(response.data))
					}
				}
			} catch (err) {
				dispatch(failure(err))
			}
		}
	}
}
