import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	statusButton: ({ variant }) => ({
		backgroundColor: !variant ? '#fff' : theme.palette.status['darkBlue'],
		color: !variant
			? theme.palette.status['darkBlue']
			: theme.palette.status.text1,
		fontSize: '0.8rem',
		padding: theme.spacing(0.6, 1),
		borderRadius: theme.shape.borderRadius,
		textAlign: 'center',
		opacity: 1,
		marginRight: theme.spacing(2),
		height: '35px',
		minWidth: '70px',
		boxShadow: '0px 3px 6px #00000026',
		border: !variant
			? '1px solid #fff'
			: `1px solid ${theme.palette.status['darkBlue']}`,
		'&:hover': {
			backgroundColor: !variant ? '#fff' : theme.palette.status['darkBlue'],
			color: !variant
				? theme.palette.status['darkBlue']
				: theme.palette.status.text1,
			opacity: 1,
			border: `1px solid ${theme.palette.status['darkBlue']}`,
		},
		'&:last-child': {
			marginRight: theme.spacing(0),
		},
	}),
}))

export default styles
