import React, { useState } from 'react'
import LayoutComponent from 'Components/layout'
import {
	Container,
	Box,
	Grid,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputBase,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Breadcrumbs from 'Components/breadcrumbs'
import { ComplaintStyle } from './styles'
import xcheck from '../../../assets/images/icons/staticpage/xcheck.png'
import checked from '../../../assets/images/icons/staticpage/checked.png'
import { navigate } from 'gatsby'
// import imgComplaint from '../../../assets/images/icons/staticpage/alur-pengembalian-produk.png'

const Complaint = () => {
	const classes = ComplaintStyle()

	const CustomInput = withStyles((theme) => ({
		root: {
			'label + &': {
				marginTop: theme.spacing(3),
			},
		},
		input: {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
			},
		},
	}))(InputBase)

	const lang = {
		id: '/pages/id/complaint',
		en: '/pages/en/complaint',
	}
	const activeLink = window.location.pathname

	const handleChange = (event) => {
		navigate(event.target.value)
	}

	// const handleNavigate = path => {
	// 	navigate(path)
	// }

	const [breadcrumbs] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Kebijakan komplain',
			link: '#',
		},
	])

	return (
		<>
			<LayoutComponent>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrumbs} />
					</Box>
					<Box>
						<Grid container justify='space-between'>
							<Grid item xs={6}>
								<Typography variant='h6'>
									Kebijakan komplain
								</Typography>
							</Grid>
							<Grid container item justify='flex-end' alignItems='center' xs={3}>
								<FormControl>
									<Select
										id='lang-product-return'
										value={activeLink}
										onChange={handleChange}
										input={<CustomInput fullWidth variant='outlined' color='primary' />}
									>
										<MenuItem value={lang['id']}>Bahasa Indonesia</MenuItem>
										<MenuItem value={lang['en']}>Bahasa Inggris</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box mt={2}>
						<ol>
							<li>
								<p>Kami tidak menerima komplain terhadap produk yang telah diterima dengan alasan berubah pikiran atau kesalahan pemesanan yang dilakukan oleh Pelanggan secara sengaja ataupun tidak disengaja, termasuk kesalahan pemilihan warna produk, kesalahan pengukuran, dan kesalahan atas detail informasi lainnya.</p>
							</li>
							<li>
								<p>Pelanggan dapat mengajukan komplain terhadap produk yang telah diterima dalam jangka waktu 3 hari kerja sejak tanggal penerimaan produk. </p>
							</li>
							<li>
								<p>Proses pengajuan komplain dapat dilakukan dengan mengisi link Google form berikut ini :  (<a href="https://docs.google.com/forms/d/e/1FAIpQLSfWe39pDNrXNMH2eO_ADJCsCOtRZrOjL9mx50oBnqhvbkJqpg/viewform?vc=0&amp;c=0&amp;w=1&amp;flr=0&amp;gxids=7628">Complaint Form</a>). </p>
							</li>
							<li>
								<p>Kami akan melakukan pengecekan berkala untuk pengajuan komplain yang Kami telah terima. </p>
							</li>
							<li>
								<p>Setelah pengajuan komplain disetujui oleh Kami, Pelanggan dapat mengirimkan kembali Produk yang dikomplain dan akan Kami tindak lanjuti sesuai dengan ketentuan yang berlaku. </p>
							</li>
							<li>
								<p>Kami memiliki hak untuk menolak komplain terhadap produk yang diterima jika;</p>
								<ol>
									<li>
										<p>Permohonan diajukan lebih dari 3 hari kerja setelah tanggal penerimaan Produk; atau</p>
									</li>
									<li>
										<p>Produk yang ingin dikomplain tidak sesuai dengan Ketentuan.</p>
									</li>
								</ol>
							</li>
							<li>
								<p>Dalam hal Pelanggan bermaksud untuk mengajukan komplain dalam waktu lebih dari 3 hari kerja setelah tanggal penerimaan Produk, Pelanggan dapat menghubungi service center terkait yang tertera dalam kartu garansi Produk untuk memproses penukaran atau pengembalian Produk tersebut.</p>
							</li>
							<li>
								<p>Pengiriman Produk kembali kepada Kami tidak menyatakan bahwa klaim diterima. Setelah Kami menerima Produk dari Pelanggan, Kami akan memeriksa kondisi Produk dan kelayakan Produk untuk di klaim atau dikembalikan sesuai dengan Ketentuan.</p>
							</li>
							<li>
								<p>Kami akan memberitahukan hasil pemeriksaan Kami atas komplain Produk kepada Pelanggan dalam waktu 3 (tiga) hari kerja setelah tanggal penerimaan Produk oleh Kami.</p>
							</li>
							<li>
								<p>Jika berdasarkan hasil pemeriksaan Kami diputuskan bahwa Produk sesuai dengan Ketentuan dan oleh karenanya layak untuk ditukar atau dikembalikan (Productuk Return), Kami akan mengirimkan Produk pengganti kepada Pelanggan serta menanggung biaya pengiriman Produk pengganti tersebut.</p>
							</li>
							<li>
								<p>Jika berdasarkan hasil pemeriksaan Kami diputuskan bahwa Produk yang dikembalikan oleh Pelanggan tidak sesuai dengan Ketentuan, maka Kami memiliki hak untuk mengembalikan Produk tersebut kembali kepada Pelanggan.</p>
							</li>
							<li>
								<p>Pelanggan dapat menghubungi Customer Service dalam hal Pelanggan belum menerima Produk yang di klaim dalam waktu 21 (dua puluh satu) hari kerja sejak tanggal pemberitahuan hasil pemeriksaan, untuk mendapatkan informasi mengenai status pengiriman Produk yang dikomplain.</p>
							</li>
							<li>
								<p>Dalam hal terdapat ketidaksepakatan atau perbedaan pendapat mengenai hasil pemeriksaan Kami atas Produk yang ingin dikembalikan, dan apabila Pelanggan tidak menghubungi Customer Service dalam kurun waktu 3 hari kerja , maka Pelanggan akan dianggap telah menerima hasil pemeriksaan Kami, dan mengembalikan Produk tersebut kembali kepada Pelanggan.</p>
							</li>
							<li>
								<p>Produk yang telah di klaim dan diterima kembali oleh Pelanggan akan diperlakukan sama dengan Produk baru, dan oleh karenanya Ketentuan ini akan berlaku sama terhadap Produk pengganti tersebut.</p>
							</li>
						</ol>

						<p>Ketentuan Komplain</p>
						<p>Kami akan menyatakan kelayakan terhadap Produk pada saat Produk telah Kami terima untuk proses pemeriksaan. Pengembalian Produk tunduk kepada kriteria sebagai berikut:</p>
						<div className={classes.returnTable}>
							<p>Kriteria Produk ( <img src={xcheck} width="16" height="15" /> = wajib terpenuhi, <img src={checked} width="16" height="16" /> = tidak terpenuhi )</p>
							<table>
								<thead>
									<tr>
										<th width='30%'>Alasan Pengembalian</th>
										<th width='10%'>Segel Utuh</th>
										<th width='10%'>Produk Baru</th>
										<th width='10%'>
											Komponen Lengkap
											<br />
											<span>(termasuk dus, aksesoris, hadiah, bundling)</span>
										</th>
										<th width='10%'>Kemasan Box Asli</th>
										<th width='10%'>Produk Tidak Rusak</th>
										<th width='10%'>Label &amp; Tag</th>
										<th width='10%'>
											SN &amp; IMEI sama
											<br />
											<span>(khusus elektronik)</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Rusak / Broken</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
									</tr>
									<tr>
										<td>Cacat / Defect</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
									</tr>
									<tr>
										<td>Salah kirim barang</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
									</tr>
									<tr>
										<td>Tidak lengkap</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
									</tr>
									<tr>
										<td>Tidak sesuai deskripsi web</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
									</tr>
									<tr>
										<td>Lewat tanggal kadaluarsa (e.g. makanan)</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={checked} width={27} />
										</td>
										<td>
											<img src={xcheck} width={27} />
										</td>
									</tr>
								</tbody>
							</table>
							<p>Catatan: pengembalian Produk tidak berlaku terhadap kategori Produk di bawah ini kecuali jika Produk dinyatakan rusak, tidak berfungsi atau mengalami kesalahan pengiriman.</p>
							<ul>
								<li>
									<p>Produk Kecantikan</p>
								</li>
								<li>
									<p>Makanan, termasuk yang bertahan dengan pengawet dan tanpa pengawet</p>
								</li>
								<li>
									<p>Pakaian dalam</p>
								</li>
								<li>
									<p>Produk yang dapat dikonsumsi</p>
								</li>
								<li>
									<p>Voucher, tiket.</p>
								</li>
							</ul>
						</div>
					</Box>
				</Container>
			</LayoutComponent>
		</>
	)
}

export default Complaint
