import * as Yup from 'yup'

const schema = Yup.object().shape({
	addressLabel: Yup.string().required('Wajib diisi'),
	addressType: Yup.object()
		.shape({
			type: Yup.string(),
			name: Yup.string(),
		})
		.required('Wajib diisi')
		.nullable(),
	location: Yup.object()
		.shape({
			id: Yup.number(),
			complete_name: Yup.string(),
			level: Yup.string(),
		})
		.required('Wajib diisi')
		.test(
			'is-location-correct',
			'Format data belum sesuai',
			(value) => value?.level === '4',
		)
		.nullable(),
	address: Yup.string().required('Wajib diisi'),
	pic: Yup.string().required('Wajib diisi'),
	mobile: Yup.string().required('Wajib diisi'),
	active: Yup.object().required('Pilih Status').nullable(),
})

export default schema
