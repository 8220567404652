/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CircularProgress, Box, Button, Typography } from '@material-ui/core'
import {
	resetAction,
	downloadPDFAction,
} from 'States/actions/Transactions/Report/downloadFileAction'
import CustomModal from 'Components/modal'
import { Print } from 'Components/svgs'

const PrintReport = (props) => {
	const dispatch = useDispatch()
	const ReportFile = useSelector((state) => state.reportFile)

	const [modalNotif, setModalNotif] = useState(false)
	const [err_message, setErrMessage] = useState('')

	const ext = props.ext
	const reportType = props.reportType
	const reportID = props.reportID
	const uri = '/report/' + ext + '/' + reportType + '/' + reportID

	const downloadPDF = () => {
		dispatch(downloadPDFAction(uri))
	}

	useEffect(() => {
		if (
			ReportFile.isLoading == false &&
			ReportFile.data &&
			ReportFile.data.result &&
			ReportFile.data.result.file
		) {
			const linkSource = `data:application/pdf;base64,${ReportFile.data.result.file}`
			const downloadLink = document.createElement('a')
			console.log(downloadLink)
			const fileName = ReportFile.data.result.filename
			downloadLink.href = linkSource
			downloadLink.download = fileName
			downloadLink.click()
			setErrMessage('')
			dispatch(resetAction())
		} else if (ReportFile.isError && ReportFile.error) {
			let err
			if (ReportFile.error.http_body) {
				let http_body = JSON.parse(ReportFile.error.http_body)
				err = http_body.data.result
			} else {
				err = 'Failed get file PDF'
			}
			setErrMessage(err)
			setModalNotif(true)
		}
	}, [ReportFile.isLoading])

	return (
		<Box style={{ order: '2', marginLeft: 'auto' }}>
			{ReportFile.isLoading ? (
				<CircularProgress size={20} />
			) : (
				<Print style={{ cursor: 'pointer' }} onClick={() => downloadPDF()} />
			)}
			<CustomModal
				icon={'error'}
				open={modalNotif}
				onClose={() => setModalNotif(false)}
			>
				<Typography variant='h6'>
					<strong>{err_message}</strong>
				</Typography>
				<Box
					display='flex'
					flexDirection='row'
					mt={3}
					justifyContent='center'
					width={300}
				>
					<Button
						size='small'
						variant='contained'
						onClick={() => setModalNotif(false)}
					>
						OK
					</Button>
				</Box>
			</CustomModal>
		</Box>
	)
}

PrintReport.propTypes = {
	ext: PropTypes.string,
	reportType: PropTypes.string,
	reportID: PropTypes.number,
}

export default PrintReport
