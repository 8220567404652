import { DEPARTMENT_DELETE } from '../../../types'
import API from '../../../../utils/api'
import { navigate } from 'gatsby'
// import { newUriAPI } from '../../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${DEPARTMENT_DELETE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${DEPARTMENT_DELETE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${DEPARTMENT_DELETE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const clearDeleteDepartment = () => {
	return {
		type: `${DEPARTMENT_DELETE}_CLEAR`,
	}
}

export const deleteDepartment = (id) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			const response = await API({
				method: 'DELETE',
				url: `/companies/departments/delete/${id}`,
			})

			dispatch(success(response.data))
			navigate('/dashboard/department')
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
