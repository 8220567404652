/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import { MOUList, MOUDetail, MOUForm } from '../routes'
import { rolesText } from '../constants/text'

export const MouNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard/mou'
				roles={rolesText('all')}
				Component={MOUList}
			/>
			<PrivateRoute
				path='/dashboard/mou/detail/:mouNumber'
				roles={rolesText('all')}
				Component={MOUDetail}
			/>
			<PrivateRoute
				path='/dashboard/mou/form/:mouNumber'
				roles={rolesText('all')}
				Component={MOUForm}
			/>
		</>
	)
}
