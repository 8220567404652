import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dashboard,
	},
	containerRoot: {
		padding: theme.spacing(3, 12),
	},
	datepicker: {
		width: 227,
	},
	tableRoot: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},
	textareaRoot: {
		width: 380,
	},
	containerField: {
		alignItems: 'center',
		justifyContent: 'space-around',
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(2),
	},
	outlinedInputRoot: {
		overflow: 'hidden',
		paddingRight: 0,
		marginTop: 4,
	},
	outlinedInputadornedEnd: {
		backgroundColor: theme.palette.secondary,
	},
	autocompleteRoot: {
		width: 250,
	},
	buttonDanger: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	buttonWidth: {
		minWidth: 120,
	},
	containerButton: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	kembali: {
		marginRight: theme.spacing(2),
	},
	colorImgBox: {
		backgroundColor: theme.palette.grey.A100,
	},
	colorPlus: {
		color: theme.palette.grey[100],
	},
	textValidation: {
		color: theme.palette.error.light,
	},
}))

export default styles
