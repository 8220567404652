/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
import React, { useRef, useEffect, useLayoutEffect } from 'react'
import { navigate } from 'gatsby'
import { orderBy, filter, takeRight } from 'lodash'
import TabPanel from 'Components/tabPanel'
import Breadcrums from 'Components/breadcrumbs'
import LayoutComponent from 'Components/layout'
import { withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import {
	Box,
	Grid,
	Typography,
	Button,
	Tab,
	Tabs,
	Paper,
	Container,
	CircularProgress,
	Divider,
} from '@material-ui/core'
import ImageGallery from 'react-image-gallery'
import { detailStyles } from './styles'
import { ShoppingCart } from '@material-ui/icons'
import { formatMoney } from '../../utils/formatMoney'
import {
	requestDetailProduct,
	calculatePdpPrice,
	getPrice,
	resetAction,
} from 'States/actions/detailProductAction'
import { requestCatalogPricing } from 'States/actions/catalogAction'
import { setRedirectAction } from 'States/actions/authAction'
import Parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { addToCartAction, modalSuccess } from 'States/actions/cartAction'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'Components/modal'
import CardListing from 'Components/cardListing'
import Slugify from 'slugify'
import SEO from 'Components/seo'
import { rolePemohon, checkRole } from '../../utils/acl'
import image_not_found from '../../assets/images/content/image_not_found.png'
import { useMeasure } from '../../hooks/useMeasure'
import { getDetailCategoriesSlug } from 'States/actions/catalogAction'

const imageStorage = process.env.GATSBY_IMAGE_URL
const imageLocation = {
	original: `${imageStorage}/450x450/erp/`,
	thumbnail: `${imageStorage}/180x180/erp/`,
}

const MyTabs = withStyles({
	root: {
		'&>div>div': {
			borderBottom: '4px solid #eee',
		},
	},
	indicator: {
		backgroundColor: '#1890ff',
		height: 4,
	},
})(Tabs)

const MyTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontSize: '1rem',
		fontWeight: theme.typography.fontWeightBold,
		marginRight: theme.spacing(4),
		'&::active': {
			color: '#1890ff',
			fontWeight: theme.typography.fontWeightBold,
		},
		'&:focus': {
			// color: '#40a9ff',
		},
	},
}))((props) => <Tab disableRipple {...props} />)

const Detail = (props) => {
	const classes = detailStyles()
	const [value, setValue] = React.useState(0)
	const [breadcrums, setBreadcrums] = React.useState([])
	const [idVariant, setIdVariant] = React.useState(null)
	const [idProduct, setIdProduct] = React.useState(null)
	const [count, setCount] = React.useState(1)
	const [total, setTotal] = React.useState(1)
	const [sku, setSku] = React.useState('')
	const [skuID, setSkuID] = React.useState(0)
	const [hide, setHide] = React.useState(false)
	const [priceList, setPriceList] = React.useState([])
	const [heightEl, setHeightEl] = React.useState()
	const [variantSelected, setVariantSelected] = React.useState({})
	const [productLastSeen, setProductLastSeen] = React.useState([])
	const [img, setImg] = React.useState([
		{ original: image_not_found, thumbnail: image_not_found },
	])
	const [newPriceTier, setNewPriceTier] = React.useState([])
	const [quantityWarning, setQuantityWarning] = React.useState(false)
	const [roleWarning, setRoleWarning] = React.useState(false)
	const [spesification, setSpesification] = React.useState(null)
	//define data from state
	const [dataDetail, setDataDetail] = React.useState({
		title: '',
		brandName: '',
		feature: '',
		fullDesc: '',
		spec: [],
		image: '',
		uomName: '',
		variant: [],
	})

	const dispatch = useDispatch()
	const detailProduct = useSelector((state) => state.detailProductReducer)
	const cartData = useSelector((state) => state.cart)
	const auth = useSelector((state) => state.auth)
	const isLoggedIn = auth.loggedIn
	let allowedPemohon = false
	if (isLoggedIn) {
		allowedPemohon = checkRole(`${rolePemohon}`, false)
	}
	const username = isLoggedIn ? auth.data.data.username : 'guest'
	const catalogPrice = useSelector((state) => state.catalogReducer.catalogPrice)
	const categorySlugs = useSelector((state) => state.categorySlug)

	const apiUrl = process.env.GATSBY_IMAGE_URL

	const handleAddToCart = () => {
		if (isLoggedIn) {
			if (allowedPemohon) {
				let arrSlug = props.productId.split('-')
				const arrId = takeRight(arrSlug, 2)
				let itemToAdd = {
					product_id: parseInt(arrId[0]),
					product_name: detailProduct.data.data.name,
					notes: '',
					qty: count,
					sku_id: skuID,
				}
				if (isNaN(count)) {
					setQuantityWarning(true)
				} else {
					dispatch(addToCartAction({ ...itemToAdd }))
				}
			} else {
				setRoleWarning(true)
			}
		} else {
			dispatch(setRedirectAction(window.location.pathname))
			navigate('/login')
		}
	}

	const closeSuccess = () => {
		dispatch(modalSuccess(false))
	}

	const redirectTo = (pageName) => {
		dispatch(modalSuccess(false))
		if (pageName == 'cart') {
			navigate('/cart')
			// window.location.href = '/cart'
		} else navigate('/catalog/search?q=')
	}

	const counterRef = useRef(null)
	const conRef = React.useRef(null)
	const [rect, divRef] = useMeasure([detailProduct.data])

	const handleChangeCounter = (e) => {
		const { default_variant } = detailProduct.data.data
		const location = window.location.pathname.split('/')
		let arrSlug = location[location.length - 1].split('-')
		const arrId = takeRight(arrSlug, 2)
		let id_variant = arrId[1]
		if (id_variant == arrId[0]) id_variant = default_variant.id
		const numOnly = e.target.validity.valid ? e.target.value : count
		setCount(parseInt(numOnly))
		dispatch(calculatePdpPrice(arrId[0], id_variant, parseInt(numOnly)))
	}

	// variant mapping function
	const handleVariantSelected = (type, value) => {
		let arrSlug = props.productId.split('-')
		const arrId = takeRight(arrSlug, 2)
		const { product_template_media_ids, image_1024_filestore, group_variant } =
			detailProduct.data.data

		let temp_variant = {
			...variantSelected,
			[type]: value,
		}
		setVariantSelected(temp_variant)

		let varStr = []
		let keysGrup = ''
		for (var keys in temp_variant) {
			if (Object.prototype.hasOwnProperty.call(temp_variant, keys)) {
				varStr.push(temp_variant[keys])
			}
		}
		keysGrup = varStr.join(',')
		// filter variant group
		const variant = group_variant.find((val) => val.key === keysGrup);
		if (arrSlug) {
			arrSlug[arrSlug.length - 1] = variant.id
			window.history.replaceState(null, null, `${arrSlug.join('-')}`)
		}

		if (variant) {
			handleSetImage({
				defaultImage: [
					...(variant.image_1024_filestore_variant
						? [variant.image_1024_filestore_variant, image_1024_filestore]
						: [image_1024_filestore]),
				],
				extraImages: variant.images || product_template_media_ids,
			});

			if (variant.price_list && variant.price_list.length > 0) {
				handleSetPriceList(variant.price_list);
			} else {
				setPriceList([]);
			}
			dispatch(calculatePdpPrice(arrId[0], variant.id, parseInt(count)))
			setSku(variant.sku_no);
			setSkuID(variant.id || 0)
			handleSpec(variant)
		}
	}
	// end of variant mapping function

	const handleIncrease = () => {
		const { default_variant } = detailProduct.data.data
		const location = window.location.pathname.split('/')
		let arrSlug = location[location.length - 1].split('-')
		const arrId = takeRight(arrSlug, 2)
		let id_variant = arrId[1]
		if (id_variant == arrId[0]) id_variant = default_variant.id
		let countTemp = parseInt(count) + 1
		countTemp = countTemp || 1
		setCount(countTemp)
		dispatch(calculatePdpPrice(arrId[0], id_variant, countTemp))
	}

	const handleDecrease = () => {
		const { default_variant } = detailProduct.data.data
		const location = window.location.pathname.split('/')
		let arrSlug = location[location.length - 1].split('-')
		const arrId = takeRight(arrSlug, 2)
		let id_variant = arrId[1]
		if (id_variant == arrId[0]) id_variant = default_variant.id
		const minVal = parseInt(counterRef.current.min)
		if (minVal === count) return false
		let countTemp = parseInt(count) - 1
		countTemp = countTemp || 1
		setCount(countTemp)
		dispatch(calculatePdpPrice(arrId[0], id_variant, countTemp))
	}

	const handleSetTotal = (val) => {
		let tmp_total = val * count
		if (!count) return false
		setTotal(tmp_total)
	}

	const handleChange = (event, indexToShow) => {
		setValue(indexToShow)
	}

	const generateLink = (path, productId, productName) => {
		const slug = Slugify(`${productName} ${productId} ${productId} `)
		return `${path}/${slug}`
	}

	const handlePriceLastSeen = (index, id) => {
		let price = ''
		catalogPrice.data.map((val) => {
			if (val.result.product_template_id == id) {
				price = formatMoney(val.result.price)
			}
		})
		return price
	}

	// set collapse description
	const handleHide = (val) => {
		if (!hide) {
			setHeightEl(6000)
		} else {
			setHeightEl(370)
		}
		setHide(val)
	}

	// breadcrums function
	const handleSetBreadcrums = (category_slug) => {
		dispatch(getDetailCategoriesSlug(category_slug))
	}
	// end of breadcrums function

	useEffect(() => {
		if (categorySlugs.data && !categorySlugs.isLoading) {
			let tmp_breadcrums = [
				{
					segment: 'Home',
					link: '/',
				},
			]
			categorySlugs.data && categorySlugs.data.slug_compile
				? categorySlugs.data.slug_compile.forEach((item) => {
					tmp_breadcrums.push({
						segment: item.name,
						link: '/catalog/' + item.slug,
					})
				})
				: null
			setBreadcrums([...tmp_breadcrums])
		}
	}, [categorySlugs])

	// set images funciton
	const handleSetImage = ({ defaultImage, extraImages }) => {
		const tempImages = []

		defaultImage.map((img) => {
			return tempImages.push({
				original: `${imageLocation.original}${img}`,
				thumbnail: `${imageLocation.thumbnail}${img}`,
			})
		})

		extraImages.map((img) => {
			return tempImages.push({
				original: `${imageLocation.original}${img.image_512_filestore_media}`,
				thumbnail: `${imageLocation.thumbnail}${img.image_512_filestore_media}`,
			})
		})
		setImg(tempImages)
	}
	// end of set images function

	const handleSetPriceList = (val) => {
		let tem_priceList = []
		val.map((item) => tem_priceList.push(item))
		setPriceList(orderBy(tem_priceList, ['min_qty'], ['asc']))
	}

	// set spisification
	const handleSpec = (spec) => {
		let tmp_spec = {
			product_height: spec.product_height,
			product_weight: spec.product_weight,
			product_length: spec.product_length,
			product_width: spec.product_width,
			length: spec.length,
			height: spec.height,
			weight: spec.weight,
			width: spec.width,
		}
		setSpesification(tmp_spec)
	}

	//hooks for updating total
	useEffect(() => {
		if (detailProduct.price) {
			handleSetTotal(detailProduct.price.result?.price ?? 0)
		}
	}, [detailProduct.price])

	// hooks for requesting data to api
	useEffect(() => {
		// =======slug arrar ========
		let arrSlug = props.productId.split('-')
		if (arrSlug.length < 3) {
			props.navigate('/404')
		}
		const arrId = takeRight(arrSlug, 2)
		setIdVariant(arrId[1])
		setIdProduct(arrId[0])
		// =======end of slug =========
		dispatch(requestDetailProduct(arrId[0], arrId[1]))
	}, [dispatch, props.productId])

	// hooks for set default state
	useEffect(() => {
		const arrSlug = props.productId.split('-')
		const arrId = takeRight(arrSlug, 2)
		if (detailProduct.data && detailProduct.data.data) {
			const {
				default_variant,
				categ_id,
				product_template_media_ids,
				image_1024_filestore,
				group_variant,
			} = detailProduct.data.data
			handleSetBreadcrums(categ_id.slug)
			let tmp_variantSelected = {}
			let imageFileStore = image_1024_filestore ?? ''

			// =========== set default data ================
			setDataDetail({
				title: detailProduct.data.data.name,
				brandName: detailProduct.data.data.product_brand_id?.name ?? '',
				feature: detailProduct.data.data.features,
				fullDesc: detailProduct.data.data.full_description,
				spec: detailProduct.data.data.specification_ids ?? [],
				image: imageFileStore,
				uomName: detailProduct.data.data.uom_id?.name ?? '',
				variant: detailProduct.data.data.attribute_line_ids ?? [],
			})
			// =========== set default variant ================
			const filterVariantDefault = filter(
				group_variant,
				(variant) => variant.id.toString() === idVariant
			)
			if (group_variant.length >= 2 && idProduct !== idVariant) {
				// ======== filter variant by id variant ========
				if (filterVariantDefault[0]) {
					dispatch(calculatePdpPrice(arrId[0], arrId[1]))
					handleSpec(filterVariantDefault[0])
					setSku(filterVariantDefault[0].sku_no)
					setSkuID(filterVariantDefault[0].id)
					filterVariantDefault[0].attribute_values.map((value) => {
						tmp_variantSelected[value.attribute_line_id.attribute_id.name] =
							value.name
					})
					setVariantSelected(tmp_variantSelected)
					if (
						filterVariantDefault[0].price_list &&
						filterVariantDefault[0].price_list.length
					) {
						handleSetPriceList(filterVariantDefault[0].price_list)
					}

					handleSetImage({
						defaultImage: [
							...(filterVariantDefault[0].image_1024_filestore_variant
								? [
									filterVariantDefault[0].image_1024_filestore_variant,
									image_1024_filestore,
								]
								: [image_1024_filestore]),
						],
						extraImages:
							filterVariantDefault[0].images ||
							product_template_media_ids,
					})
				} else {
					props.navigate('/404')
				}
				// ======== end of filter variant by id variant =======
			} else {
				setSku(default_variant.sku_no)
				setSkuID(default_variant.id)
				handleSpec(default_variant)
				dispatch(calculatePdpPrice(arrId[0], default_variant.id))
				if (default_variant.price_list && default_variant.price_list.length) {
					handleSetPriceList(default_variant.price_list)
				}

				handleSetImage({
					defaultImage: [
						...(default_variant.image_1024_filestore_variant
							? [
								default_variant.image_1024_filestore_variant,
								image_1024_filestore,
							]
							: [image_1024_filestore]),
					],
					extraImages:
						default_variant.images || product_template_media_ids,
				});

				default_variant.attribute_values.map((value) => {
					tmp_variantSelected[value.attribute_line_id.attribute_id.name] = value.name
				})
				setVariantSelected(tmp_variantSelected)
			}
			// =========== end of set default variant =========
		}
	}, [props.productId, detailProduct.data, detailProduct.isLoading])
	// end of hooks for set default state

	// hooks to add 6 latest product seen
	useEffect(() => {
		if (detailProduct.data && detailProduct.data.data) {
			let localStorageName = `lastSeen:${username}`
			let product = JSON.parse(localStorage.getItem(localStorageName)) || []
			let flagHaveSameItem = false
			let id = detailProduct.data.data.id ?? ''
			if (product.filter((e) => e.id === id).map((e) => e.id).length < 1) {
				product.push(detailProduct.data.data)
				if (product.length > 6) {
					product.shift()
				}
				localStorage.setItem(localStorageName, JSON.stringify(product))
			} else {
				flagHaveSameItem = true
			}

			if (!flagHaveSameItem && product.length > 5) {
				product.pop()
			} else if (
				product.length == 1 &&
				(flagHaveSameItem || product[0].id == id)
			) {
				product.shift()
			}

			product.reverse()
			setProductLastSeen(product)
			dispatch(requestCatalogPricing(product))
		}
	}, [detailProduct.data])
	// end hooks to add 6 latest product seen

	useLayoutEffect(() => {
		if (rect.height < 370) {
			setHide(true)
		} else {
			setHide(false)
		}
	}, [rect.height])

	// hooks for price tier
	useEffect(() => {
		if (priceList.length) {
			const { default_variant } = detailProduct.data.data
			const location = window.location.pathname.split('/')
			let arrSlug = location[location.length - 1].split('-')
			const arrId = takeRight(arrSlug, 2)
			let promises = []
			let id_variant = arrId[1]
			if (id_variant == arrId[0]) id_variant = default_variant.id
			priceList.map((price) => {
				if (auth.data) {
					promises.push(
						getPrice(
							parseInt(arrId[0]),
							parseInt(auth.data.data.companies.partner_id),
							parseInt(id_variant),
							0,
							price.min_qty
						)
					)
				} else {
					promises.push(
						getPrice(parseInt(arrId[0]), 0, parseInt(id_variant), 0, price.min_qty)
					)
				}
			})
			Promise.all(promises).then((res) => setNewPriceTier(res))
		}
	}, [priceList])

	//unmount to do reset state
	useEffect(() => {
		return () => {
			dispatch(resetAction())
		}
	}, [])

	return (
		<LayoutComponent>
			<Grid container justify='center' alignItems='center'>
				<Grid item lg={11}>
					<Box height={60} mb={1} alignItems='center' display='flex'>
						{detailProduct.isLoading ? (
							<Skeleton variant='rect' height={20} width='100%' />
						) : (
							<Breadcrums breadcrumbs={breadcrums} />
						)}
					</Box>
				</Grid>
				<Grid item lg={10}>
					<Grid container style={{ marginBottom: 24 }}>
						<Grid item lg={4} md={4}>
							{!detailProduct.isLoading ? (
								<Box style={{ minHeight: 500 }} marginRight={2}>
									<ImageGallery
										onErrorImageURL={image_not_found}
										items={img}
										showNav={false}
										showFullscreenButton={false}
										showPlayButton={false}
									/>
								</Box>
							) : (
								<Skeleton height={350} variant='rect' width={350} />
							)}
						</Grid>
						<Grid item lg={8} md={8}>
							<Box marginLeft={2}>
								{detailProduct.isLoading ? (
									<Skeleton variant='text' />
								) : (
									<Typography variant='h5'>
										<strong id='detail-product-name'>{dataDetail.title}</strong>
									</Typography>
								)}

								<Box
									display='flex'
									paddingBottom={1}
									flexDirection='row'
									className={`${classes.sectionControl}`}
									marginTop={1}
								>
									<Box flex={0.6}>
										{detailProduct.isLoading ? (
											<Skeleton variant='text' />
										) : (
											<Typography variant='body1'> SKU No: {sku} </Typography>
										)}
									</Box>
									<Box flex={1}>
										{detailProduct.isLoading ? (
											<Skeleton variant='text' />
										) : (
											<Typography variant='body1'>
												{' '}
												Brand: {dataDetail.brandName}
											</Typography>
										)}
									</Box>
								</Box>

								<Box
									display='flex'
									paddingBottom={6}
									marginTop={1}
									flexDirection='column'
									className={`${classes.sectionControl}`}
								>
									<Box display='flex' flexDirection='row' flex={1}>
										<Box flex={0.1}>
											{detailProduct.isLoading ? (
												<Skeleton variant='text' />
											) : (
												<Typography variant='body1'>
													<strong>Harga</strong>
												</Typography>
											)}
										</Box>
										<Box flex={1} display='flex' flexDirection='column'>
											{detailProduct.isLoading ? (
												<Skeleton variant='text' />
											) : (
												<Typography variant='body1'>
													{' '}
													{detailProduct.price && (
														<strong className='price-text'>
															{formatMoney(detailProduct.price.result?.price ?? 0)}
														</strong>
													)}
													{detailProduct.data && (
														<small>
															&nbsp;/
															{dataDetail.uomName}
														</small>
													)}{' '}
												</Typography>
											)}
											{detailProduct.isLoading ? (
												<Skeleton variant='rect' height={20} width={120} />
											) : (
												<Typography variant='caption'>
													<i>(belum termasuk pajak)</i>
												</Typography>
											)}
										</Box>
									</Box>

									{/* grosir */}
									{detailProduct.isLoading ? (
										<Box py={1}>
											<Skeleton variant='rect' width='100%' height={50} />
										</Box>
									) : priceList.length &&
										!(priceList.length === 1 && priceList[0].min_qty === 1) ? (
										<>
											<Box marginTop={1}>
												<Box>
													<Typography variant='body1'>
														<strong>Harga Grosir</strong>
													</Typography>
												</Box>
											</Box>
											<Box marginTop={1} display='flex' flex={1}>
												<Box display='flex' flexDirection='row' height={75}>
													<Box flex={1} p={1} className={classes.saleTitle}>
														<Typography variant='body2'>
															<strong>Jumlah Pembelian</strong>
														</Typography>
														<Typography variant='body2'>
															<strong>Harga</strong>
														</Typography>
													</Box>
													<Box ref={conRef} className={classes.containerSalePrice}>
														{priceList.map((item, index) => (
															<Box key={index} className={classes.salePrice}>
																<Typography variant='body2'>
																	{index == priceList.length - 1
																		? '≥ ' + item.min_qty
																		: `${item.min_qty} - ${priceList[index + 1].min_qty - 1}`}
																</Typography>
																<Typography variant='body2'>
																	{newPriceTier[index] &&
																		formatMoney(newPriceTier[index].data.data.result.price)}
																</Typography>
															</Box>
														))}
													</Box>
												</Box>
											</Box>
										</>
									) : null}

									{/* grosir end */}

									{/* variant selection */}
									{detailProduct.isLoading ? (
										<Box py={1}>
											<Skeleton variant='rect' height={30} width='100%' />
										</Box>
									) : (
										dataDetail.variant.length != 0 &&
										dataDetail.variant.map((item, indexAttr) => {
											if (item.attribute_id.display_type === 'color') {
												return (
													<Box
														key={indexAttr}
														display='flex'
														flexDirection='row'
														alignItems='center'
														flex={1}
														marginTop={2}
													>
														<Box flex={0.1}>
															<Typography variant='body1'>
																<strong>{item.attribute_id.name}</strong>
															</Typography>
														</Box>
														<Box className={classes.colorVariant}>
															{item.value_ids.map((val, id) => (
																<Box key={id.toString()} component='span'>
																	<Paper
																		onClick={() =>
																			handleVariantSelected(
																				item.attribute_id.name,
																				val.name,
																				dataDetail.image
																			)
																		}
																		className={`${classes.colorItem} ${variantSelected[item.attribute_id.name] === val.name
																			? classes.active
																			: null
																			}`}
																	>
																		<Box mr={1} height={25} width={25} bgcolor={val.html_color} />
																		<Typography variant='caption'>{val.name}</Typography>
																	</Paper>
																</Box>
															))}
														</Box>
													</Box>
												)
											}

											return (
												<Box
													key={indexAttr}
													display='flex'
													alignItems='center'
													flexDirection='row'
													flex={1}
													marginTop={2}
												>
													<Box flex={0.1}>
														<Typography variant='body1'>
															<strong>{item.attribute_id.name}</strong>
														</Typography>
													</Box>
													<Box className={classes.colorVariant}>
														{item.value_ids.map((val, id) => (
															<Box
																key={id}
																component='span'
																className={classes.colorVariantChild}
															>
																<Paper
																	onClick={() =>
																		handleVariantSelected(item.attribute_id.name, val.name)
																	}
																	className={`${classes.variantItem} ${variantSelected[item.attribute_id.name] === val.name
																		? classes.active
																		: null
																		}`}
																>
																	<Typography variant='caption' component='p' align='center'>
																		{val.name}
																	</Typography>
																</Paper>
															</Box>
														))}
													</Box>
												</Box>
											)
										})
									)}
									{/* variant selection */}
								</Box>

								<Box
									display='flex'
									paddingBottom={1}
									flexDirection='column'
									marginTop={1}
									className={`${classes.sectionControl}`}
								>
									<Box
										display='flex'
										flexDirection='row'
										marginBottom={1}
										justifyContent='space-between'
									>
										{detailProduct.isLoading ? (
											<Skeleton variant='rect' width={123} height={33} />
										) : (
											<Typography variant='body1'>
												<strong>Jumlah Pembelian</strong>
											</Typography>
										)}
										{detailProduct.isLoading ? (
											<Skeleton variant='rect' width={123} height={33} />
										) : (
											<Box display='flex' flexDirection='row'>
												<Button
													className={classes.buttonGroupPreppend}
													onClick={() => handleDecrease()}
													disableElevation
													variant='contained'
													color='secondary'
													size='small'
												>
													<strong>-</strong>
												</Button>
												<input
													ref={counterRef}
													min={1}
													className={classes.buttonGroupInput}
													pattern='[0-9]*'
													type='number'
													onChange={(e) => handleChangeCounter(e)}
													value={count}
												/>
												<Button
													className={classes.buttonGroupAppend}
													onClick={() => handleIncrease()}
													disableElevation
													variant='contained'
													color='secondary'
													size='small'
												>
													<strong>+</strong>
												</Button>
											</Box>
										)}
									</Box>
									<Box display='flex' flexDirection='row' justifyContent='space-between'>
										{detailProduct.isLoading ? (
											<Skeleton variant='rect' width={123} height={33} />
										) : (
											<Typography variant='body1'>
												<strong>TOTAL</strong>
											</Typography>
										)}
										{detailProduct.isLoading ? (
											<Skeleton variant='rect' width={123} height={33} />
										) : (
											<Typography variant='body1'>
												<strong>{formatMoney(total)}</strong>
											</Typography>
										)}
									</Box>
								</Box>

								<Box display='flex' justifyContent='flex-end' paddingTop={3}>
									{detailProduct.isLoading ? (
										<Skeleton variant='rect' width={200} height={40} />
									) : (
										<Button
											id='btn-add-to-cart'
											variant='contained'
											color='secondary'
											endIcon={<ShoppingCart fontSize='small' />}
											onClick={() => handleAddToCart()}
											disabled={cartData.addCart.isLoading}
										// disabled={true}
										>
											<Typography variant='body1' style={{ textTransform: 'capitalize' }}>
												{cartData.addCart.isLoading && (
													<CircularProgress color='secondary' size={15} thickness={2} />
												)}
												<strong> Tambah Ke Keranjang </strong>
											</Typography>
										</Button>
									)}
								</Box>

								<Modal
									open={cartData.addCart.showModal}
									disableBackdropClick
									onClose={() => closeSuccess()}
									icon='success'
								>
									<Typography variant='h6'>
										Item berhasil dimasukkan ke keranjang
									</Typography>
									<Grid className={classes.paddingTop}>
										<Button
											color='secondary'
											onClick={() => closeSuccess()}
											variant='outlined'
											size='small'
										>
											Kembali Belanja
										</Button>
										&nbsp;&nbsp;&nbsp;
										<Button
											id='btn-navigate-to-cart'
											color='secondary'
											onClick={() => redirectTo('cart')}
											variant='contained'
											size='small'
										>
											Lihat Keranjang
										</Button>
									</Grid>
								</Modal>

								<Modal open={quantityWarning} icon='warning' disableBackdropClick>
									<Typography variant='h6'>
										Silakan isi kuantitas terlebih dahulu.
									</Typography>
									<Grid className={classes.paddingTop}>
										<Button
											onClick={() => {
												setQuantityWarning(false)
											}}
											color='secondary'
											variant='outlined'
											size='small'
										>
											Tutup
										</Button>
									</Grid>
								</Modal>

								<Modal open={roleWarning} icon='error' disableBackdropClick>
									<Typography variant='h6'>Gagal Menambah ke Keranjang</Typography>
									<Typography variant='body2'>
										Anda tidak memiliki akses sebagai pemohon
									</Typography>
									<Grid className={classes.paddingTop}>
										<Button
											id='btn-cancel'
											onClick={() => {
												setRoleWarning(false)
											}}
											color='secondary'
											variant='outlined'
											size='small'
										>
											Kembali
										</Button>
									</Grid>
								</Modal>
							</Box>
						</Grid>

						{/* description tab */}
						<Grid item lg={12} style={{ marginTop: 32, marginBottom: 20 }}>
							<MyTabs value={value} onChange={handleChange}>
								<MyTab label='Deskripsi' value={0} />
								<MyTab label='Info & Spesifikasi' value={1} />
							</MyTabs>
							<TabPanel value={value} index={0}>
								<Box
									className={classes.containerDesc}
									ref={divRef}
									style={{ maxHeight: heightEl }}
								>
									<Typography variant='body1'>
										<strong>Fitur</strong>
									</Typography>
									<Typography variant='body1' component='span'>
										{detailProduct.isLoading ? null : Parse(dataDetail.feature)}
									</Typography>

									<Typography variant='body1'>
										<strong>Deskripsi</strong>
									</Typography>
									<Typography variant='body1' component='span'>
										{detailProduct.isLoading ? null : Parse(dataDetail.fullDesc)}
									</Typography>
									{rect.height < 6000 && !hide && <Box className={classes.backdrop} />}
								</Box>
								{rect.height < 370 ? null : (
									<Box
										className={classes.viewMore}
										display='flex'
										flex={1}
										justifyContent='center'
										alignItems='flex-end'
									>
										<Typography
											align='center'
											variant='caption'
											onClick={() => handleHide(!hide)}
											style={{ cursor: 'pointer' }}
										>
											{!hide ? 'Lihat Selengkapnya' : 'Lebih Sedikit'}
										</Typography>
									</Box>
								)}
							</TabPanel>

							{/* Info & spesifikasi */}
							<TabPanel value={value} index={1}>
								<Container>
									<Grid container>
										<Grid item lg={5}>
											<Grid container>
												<Grid item lg={5}>
													<Typography variant='body2'>
														<strong>Dimensi Produk (p x l x t)</strong>
													</Typography>
													<Typography variant='body2'>
														<strong>Dimensi Kemasan (p x l x t)</strong>
													</Typography>
												</Grid>
												{!spesification ? null : (
													<Grid item lg={7}>
														<Typography variant='body2'>{`(${spesification.product_length} x ${spesification.product_width} x ${spesification.product_height} ) cm`}</Typography>
														<Typography variant='body2'>{`(${spesification.length} x ${spesification.width} x ${spesification.height} ) cm`}</Typography>
													</Grid>
												)}
											</Grid>
										</Grid>
										<Grid item lg={5}>
											<Grid container>
												<Grid item lg={5}>
													<Typography variant='body2'>
														<strong>Berat Produk</strong>
													</Typography>
													<Typography variant='body2'>
														<strong>Berat Kemasan</strong>
													</Typography>
												</Grid>
												{!spesification ? null : (
													<Grid item lg={7}>
														<Typography variant='body2'>{`${spesification.product_weight} kg`}</Typography>
														<Typography variant='body2'>{`${spesification.weight} kg`}</Typography>
													</Grid>
												)}
											</Grid>
										</Grid>
									</Grid>

									<Grid container>
										{detailProduct.isLoading
											? null
											: dataDetail.spec.length != 0 &&
											dataDetail.spec.map((item) => (
												<Grid key={item.id} item lg={5}>
													<Grid container>
														<Grid item lg={5}>
															<Typography variant='body2'>
																<strong>{item.specification_id.name}</strong>
															</Typography>
														</Grid>
														<Grid item lg={7}>
															<Typography variant='body2'>{item.value}</Typography>
														</Grid>
													</Grid>
												</Grid>
											))}
									</Grid>
								</Container>
							</TabPanel>
							{/* info & spesifikasi end */}
						</Grid>

						{/* */}
						{productLastSeen.length > 0 && (
							<Grid container className={classes.marginTop}>
								<Grid item lg={12} className={classes.paddingBottom2}>
									<Typography variant='body1'>
										<strong>Produk Terakhir Yang Anda Lihat</strong>
									</Typography>
								</Grid>
								<Divider className={classes.divWidth} />
								<Grid item lg={12} className={classes.paddingTop2}>
									<Box display='grid' gridTemplateColumns='repeat(5, 1fr)' gridGap={10}>
										{productLastSeen.map((item, index) => {
											if (item.id == idProduct) {
												return ''
											}

											return (
												<CardListing
													href={generateLink('/catalog/detail', item.id, item.name)}
													key={index}
													price={
														catalogPrice.isLoading == false ? (
															handlePriceLastSeen(index, item.id)
														) : (
															<Skeleton
																width='80%'
																height={20}
																variant='rect'
																style={{ margin: 'auto' }}
															/>
														)
													}
													alert={item.product_variant_ids.length > 1 ? true : false}
													alertText='Produk tersedia dengan pilihan'
													title={item.name}
													media={
														item.image_512_filestore != ''
															? `${apiUrl}/180x180/erp/${item.image_512_filestore}`
															: image_not_found
													}
												/>
											)
										})}
									</Box>
								</Grid>
							</Grid>
						)}
					</Grid>
					{detailProduct.isLoading ? null : <SEO title={dataDetail.name} />}
				</Grid>
			</Grid>
		</LayoutComponent>
	)
}

Detail.propTypes = {
	productId: PropTypes.string,
	navigate: PropTypes.func,
}
export default Detail
