import { APPROVAL_CONFIGURE_ACTIVATE } from '../../../types'
import API from '../../../../utils/api'
// import { newUriAPI } from '../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${APPROVAL_CONFIGURE_ACTIVATE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${APPROVAL_CONFIGURE_ACTIVATE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${APPROVAL_CONFIGURE_ACTIVATE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const reset = (data) => {
	return {
		type: `${APPROVAL_CONFIGURE_ACTIVATE}_RESET`,
		payload: {
			...data,
		},
	}
}

export const requestACActivate = (data, type_dispatch = 'default') => {
	return async (dispatch) => {
		if (type_dispatch == 'RESET') {
			dispatch(reset())
		} else {
			dispatch(loading())
			try {
				let response = await API({
					method: 'PUT',
					url: '/purchase-requests/activate',
				})

				if (response) {
					if (response.data.error_backend) {
						dispatch(failure(response.data.error_backend))
					} else {
						dispatch(success(response.data))
					}
				}
			} catch (err) {
				dispatch(failure(err))
			}
		}
	}
}
