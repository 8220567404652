import * as Yup from 'yup'
import API from '../../../utils/api'

Yup.addMethod(Yup.string, 'isNameExist', function (errorMessage) {
	return this.test('test-name', errorMessage, function (value) {
		const { path, createError, parent } = this

		return new Promise((resolve, reject) => {
			API({
				method: 'GET',
				url: `budgets/check?budget_name=${value}`,
			})
				.then((response) => {
					if (
						response.data.data.items &&
						response.data.data.items.length > 0 &&
						response.data.data.items.find((x) => x.id !== parent.id)
					) {
						reject(createError({ path, message: errorMessage }))
					} else {
						resolve(true)
					}
				})
				.catch((err) => {
					console.log(err)
					resolve(true)
				})
		})
	})
})

const schemaDepartment = Yup.object().shape({
	name: Yup.string()
		.trim()
		.min(10, 'Wajib diisi minimal 10 karakter')
		.max(50, 'Maksimal 50 karakter')
		.isNameExist(
			'Sudah terdapat anggaran dengan nama ini, silakan masukkan nama anggaran yang berbeda'
		)
		.required('Wajib diisi minimal 10 karakter'),
	descriptions: Yup.string()
		.trim()
		.min(10, 'Wajib diisi minimal 10 karakter')
		.max(100, 'Maksimal 100 karakter')
		.required('Wajib diisi minimal 10 karakter'),
	total: Yup.number()
		.typeError('Wajib diisi lebih dari nol')
		.min(1, 'Wajib diisi lebih dari nol')
		.required('Wajib diisi lebih dari nol'),
	departemen: Yup.array().min(1, 'Wajib diisi'),
	// active: Yup.object().required('Pilih Status').nullable(),
})

export default schemaDepartment
