import { makeStyles } from '@material-ui/core/styles'

export const ProfileDetailStyles = makeStyles((theme) => ({
	image: {
		width: 128,
		height: 128,
		display: 'flex',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	buttonAction: {
		marginLeft: '5px',
	},
	btnSave: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnCancel: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	customTooltip: {
		padding: '0',
		minWidth: '0',
	},
	customIcon: {
		fontSize: '14px',
		marginLeft: '5px',
	},
}))
