import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	iconAction: {
		fontSize: '22px',
	},
	textCenter: {
		textAlign: 'center',
	},
	buttonAction: {
		padding: theme.spacing(0.5, 1),
		minWidth: 'initial',
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		width: '32px',
		top: 'inherit',
	},
	selectContainer: {
		borderRadius: theme.shape.borderRadius,
		paddingRight: '45px !important',
		marginRight: theme.spacing(0.5),
		width: 90,
	},
	typoPage: {
		paddingTop: theme.spacing(0.5),
		marginRight: theme.spacing(2),
	},
	typoPage2: {
		paddingTop: theme.spacing(0.5),
		marginRight: theme.spacing(2),
		fontSize: '1rem',
		fontWeight: '600',
	},
	statusFilter: {
		marginBottom: theme.spacing(1),
		fontSize: '12px',
		color: '#898E95',
	},
	paddingTopFilter: {
		paddingTop: 20,
	},
	dropdownLimit: {
		marginTop: theme.spacing(2),
	},
}))

export default styles
