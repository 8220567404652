import React, { createRef, useEffect, useRef, useState } from 'react'
import LayoutDashboard from 'Components/layoutDashboard'
import { useSelector, useDispatch } from 'react-redux'
import {
	requestDetailCompany,
	getContactList,
	resetAction,
	resetContactAction,
} from 'States/actions/CompanyProfile/profileAction'
import {
	Box,
	Grid,
	Typography,
	Paper,
	Divider,
	Button,
	Card,
	CardContent,
	Tooltip,
	Collapse,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Image from 'material-ui-image'
import Breadcrumbs from 'Components/breadcrumbs'
import PropTypes from 'prop-types'
import { CompanyprofileStyle } from './styles'
import { withStyles } from '@material-ui/styles'
import { getFileThumb } from '../../../utils/fileThumbnail'

const imageStorage = process.env.GATSBY_IMAGE_URL

const Companyprofile = ({ navigate }) => {
	const classes = CompanyprofileStyle()
	const dispatch = useDispatch()
	const companyDetail = useSelector((state) => state.companyProfile.company)
	const contactList = useSelector((state) => state.companyProfile.contacts)
	const [expandPic, setExpandPic] = useState(false)
	const [docNameOverflow, setDocNameOverflow] = useState([])
	const [docNumOverflow, setDocNumOverflow] = useState([])
	const [docTypeOverflow, setDocTypeOverflow] = useState([])
	const docNameRef = useRef(
		companyDetail.data &&
			companyDetail.data.company_details &&
			companyDetail.data.company_details.res_partner_document_ids.length > 0
			? companyDetail.data.company_details.res_partner_document_ids.map(() =>
					createRef()
			  )
			: []
	)
	const docNumberRef = useRef(
		companyDetail.data &&
			companyDetail.data.company_details &&
			companyDetail.data.company_details.res_partner_document_ids.length > 0
			? companyDetail.data.company_details.res_partner_document_ids.map(() =>
					createRef()
			  )
			: []
	)
	const docTypeRef = useRef(
		companyDetail.data &&
			companyDetail.data.company_details &&
			companyDetail.data.company_details.res_partner_document_ids.length > 0
			? companyDetail.data.company_details.res_partner_document_ids.map(() =>
					createRef()
			  )
			: []
	)

	//start of set state data company profile
	const [profile, setProfile] = useState({
		logo: '',
		name: '',
		background: '',
		address: '',
		location: '',
		phoneNumber: '',
		mobileNumber: '',
		termPayment: '',
		status: '',
		document: [],
	})
	const [contactData, setContactData] = useState([])
	// end of set state data company profile

	useEffect(() => {
		docNameRef.current =
			companyDetail.data &&
			companyDetail.data.company_details &&
			companyDetail.data.company_details.res_partner_document_ids.length > 0
				? companyDetail.data.company_details.res_partner_document_ids.map(() =>
						createRef()
				  )
				: []
		docNumberRef.current =
			companyDetail.data &&
			companyDetail.data.company_details &&
			companyDetail.data.company_details.res_partner_document_ids.length > 0
				? companyDetail.data.company_details.res_partner_document_ids.map(() =>
						createRef()
				  )
				: []
		docTypeRef.current =
			companyDetail.data &&
			companyDetail.data.company_details &&
			companyDetail.data.company_details.res_partner_document_ids.length > 0
				? companyDetail.data.company_details.res_partner_document_ids.map(() =>
						createRef()
				  )
				: []
		if (companyDetail.data) {
			setProfile({
				logo: companyDetail.data.company_details?.image_512_filestore ?? '',
				name: companyDetail.data.company_details?.name ?? '-',
				background: companyDetail.data.company_details?.company_background ?? '-',
				address: companyDetail.data.company_details?.street ?? '-',
				location:
					companyDetail.data.company_details?.region_id?.complete_name ?? '-',
				phoneNumber: companyDetail.data.company_details?.phone ?? '-',
				mobileNumber: companyDetail.data.company_details.mobile,
				termPayment:
					companyDetail.data.company_details?.property_payment_term_id?.name ?? '',
				status: companyDetail.data.is_pr_active,
				document:
					companyDetail.data.company_details?.res_partner_document_ids ?? [],
			})
		}
	}, [companyDetail])

	useEffect(() => {
		if (contactList.data) {
			setContactData(contactList.data.result ?? [])
		}
	}, [contactList])

	useEffect(() => {
		if (docNameRef.current.length > 0) {
			const nextDocNameOverflow = docNameRef.current.map((ref) =>
				ref.current ? isOverflowActive(ref.current) : false
			)
			setDocNameOverflow(nextDocNameOverflow)
		}
	}, [docNameRef.current])

	useEffect(() => {
		if (docNumberRef.current.length > 0) {
			const nextdocNumOverflow = docNumberRef.current.map((ref) =>
				ref.current ? isOverflowActive(ref.current) : false
			)
			setDocNumOverflow(nextdocNumOverflow)
		}
	}, [docNumberRef.current])

	useEffect(() => {
		if (docTypeRef.current.length > 0) {
			const nextdocTypeOverflow = docTypeRef.current.map((ref) =>
				ref.current ? isOverflowActive(ref.current) : false
			)
			setDocTypeOverflow(nextdocTypeOverflow)
		}
	}, [docTypeRef.current])

	const isOverflowActive = (e) => {
		return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth
	}

	useEffect(() => {
		dispatch(requestDetailCompany())
		dispatch(getContactList())
		return () => {
			dispatch(resetAction())
			dispatch(resetContactAction())
		}
	}, [])

	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Profil Perusahaan',
			link: '#',
		},
	]

	const StyleTooltip = withStyles(() => ({
		tooltip: {
			marginTop: 5,
			marginBottom: 5,
			maxWidth: 'none',
		},
	}))(Tooltip)

	return (
		<LayoutDashboard>
			<Box height={70} alignItems='center' display='flex'>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Box>
			<Box py={1}>
				<Typography variant='h6'>Profil Perusahaan</Typography>
				<Paper variant='outlined' className={classes.boxSpacing}>
					<Box p={2}>
						<Typography variant='h6'>Informasi Profil</Typography>
						<Divider className={classes.marginTB1} />
						<Grid
							container
							spacing={0}
							justify='space-between'
							className={classes.formField}
						>
							<Grid container item xs={6}>
								<Grid item xs={4} className={classes.formLabel}>
									<Typography variant='subtitle1' className={classes.labelRequired}>
										Logo
									</Typography>
								</Grid>
								<Grid item xs={8} className={classes.formLabel}>
									<Box className={classes.image}>
										{companyDetail.isLoading ? (
											<Skeleton variant='rect' width={180} height={180} />
										) : !profile.logo ? null : (
											<Image
												src={`${imageStorage}/180x180/erp/${profile.logo}`}
												alt='Profile image'
											/>
										)}
									</Box>
								</Grid>
								<Grid item xs={4} className={classes.formLabel}>
									<Typography variant='subtitle1' className={classes.labelRequired}>
										Nama Pembeli
									</Typography>
								</Grid>
								<Grid item xs={8} className={classes.formLabel}>
									<Typography variant='body1'>
										{companyDetail.isLoading ? (
											<Skeleton variant='text' width={250} />
										) : (
											profile.name
										)}
									</Typography>
								</Grid>
								<Grid item xs={4} className={classes.formLabel}>
									<Typography variant='subtitle1' className={classes.labelRequired}>
										Latar Belakang
									</Typography>
								</Grid>
								<Grid item xs={8} className={classes.formLabel}>
									<Typography variant='body1'>
										{companyDetail.isLoading ? (
											<Skeleton variant='text' width={250} />
										) : (
											profile.background
										)}
									</Typography>
								</Grid>
							</Grid>
							<Grid container item xs={6}>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='subtitle1' className={classes.labelRequired}>
										Alamat Lengkap
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='body1'>
										{companyDetail.isLoading ? (
											<>
												<Skeleton variant='text' width={250} />
												<Skeleton variant='text' width={250} />
												<Skeleton variant='text' width={200} />
											</>
										) : (
											profile.address
										)}
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='subtitle1' className={classes.labelRequired}>
										Lokasi
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='body1'>
										{companyDetail.isLoading ? (
											<Skeleton variant='text' width={250} />
										) : (
											profile.location
										)}
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='subtitle1' className={classes.labelRequired}>
										No. Telepon
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='body1'>
										{companyDetail.isLoading ? (
											<Skeleton variant='text' width={250} />
										) : (
											profile.phoneNumber
										)}
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='subtitle1'>No. Ponsel</Typography>
								</Grid>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='body1'>
										{companyDetail.isLoading ? (
											<Skeleton variant='text' width={200} />
										) : (
											profile.mobileNumber
										)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Paper>
				<Paper variant='outlined' className={classes.boxSpacing}>
					<Box p={2}>
						<Typography variant='h6'>Kontak PIC</Typography>
						<Divider className={classes.marginTB1} />
						{contactData.length > 8 ? (
							<>
								<Collapse in={expandPic} collapsedSize={518}>
									<Box mt={2}>
										<Grid container spacing={0} className={classes.formField}>
											<Grid container item spacing={1} xs={12}>
												{/* =============================================== */}
												{contactList.isLoading
													? Array.from(Array(4)).map((_, i) => (
															<Grid item xs={3} key={i}>
																<Card variant='outlined'>
																	<CardContent>
																		<Grid container spacing={2}>
																			<Grid container item xs={12}>
																				<Typography variant='subtitle1'>
																					<Skeleton variant='text' width={200} />
																				</Typography>
																			</Grid>
																			<Grid container item xs={12}>
																				<Typography variant='subtitle1'>
																					<Skeleton variant='text' width={180} />
																				</Typography>
																			</Grid>
																			<Grid container item xs={12}>
																				<Typography variant='subtitle1'>
																					<Skeleton variant='text' width={200} />
																				</Typography>
																			</Grid>
																			<Grid container item xs={12}>
																				<Typography variant='subtitle1'>
																					<Skeleton variant='text' width={180} />
																				</Typography>
																			</Grid>
																			<Grid container item xs={12}>
																				<Typography variant='subtitle1'>
																					<Skeleton variant='text' width={180} />
																				</Typography>
																			</Grid>
																		</Grid>
																	</CardContent>
																</Card>
															</Grid>
													  ))
													: contactData.length != 0 &&
													  contactData.map((item) => (
															<Grid item xs={3} key={item.id}>
																<Card variant='outlined'>
																	<CardContent>
																		<Grid container spacing={2}>
																			<Grid container item xs={4}>
																				<Typography variant='subtitle1'>Nama</Typography>
																			</Grid>
																			<Grid container item xs={8}>
																				<Typography variant='body1' noWrap>
																					{item.name}
																				</Typography>
																			</Grid>
																			<Grid container item xs={4}>
																				<Typography variant='subtitle1'>Jabatan</Typography>
																			</Grid>
																			<Grid container item xs={8}>
																				<Typography variant='body1' noWrap>
																					{item.function}
																				</Typography>
																			</Grid>
																			<Grid container item xs={4}>
																				<Typography variant='subtitle1'>Email</Typography>
																			</Grid>
																			<Grid container item xs={8}>
																				<Typography variant='body1' noWrap>
																					{item.email}
																				</Typography>
																			</Grid>
																			<Grid container item xs={4}>
																				<Typography variant='subtitle1'>No. Ponsel</Typography>
																			</Grid>
																			<Grid container item xs={8}>
																				<Typography variant='body1' noWrap>
																					{item.mobile}
																				</Typography>
																			</Grid>
																			<Grid container item xs={4}>
																				<Typography variant='subtitle1'>No. Telp</Typography>
																			</Grid>
																			<Grid container item xs={8}>
																				<Typography variant='body1' noWrap>
																					{item.phone}
																				</Typography>
																			</Grid>
																		</Grid>
																	</CardContent>
																</Card>
															</Grid>
													  ))}
												{/* ======================================================================= */}
											</Grid>
										</Grid>
									</Box>
								</Collapse>
								<Box mt={2}>
									<Typography variant='subtitle2' align='right'>
										<a
											className={classes.link}
											onClick={() => {
												setExpandPic(!expandPic)
											}}
										>
											{expandPic ? 'Lihat Lebih Sedikit' : 'Lihat Selengkapnya'}
										</a>
									</Typography>
								</Box>
							</>
						) : (
							<Box mt={2}>
								<Grid container spacing={0} className={classes.formField}>
									<Grid container item spacing={1} xs={12}>
										{/* =============================================== */}
										{contactList.isLoading
											? Array.from(Array(4)).map((_, i) => (
													<Grid item xs={3} key={i}>
														<Card variant='outlined'>
															<CardContent>
																<Grid container spacing={2}>
																	<Grid container item xs={12}>
																		<Typography variant='subtitle1'>
																			<Skeleton variant='text' width={200} />
																		</Typography>
																	</Grid>
																	<Grid container item xs={12}>
																		<Typography variant='subtitle1'>
																			<Skeleton variant='text' width={180} />
																		</Typography>
																	</Grid>
																	<Grid container item xs={12}>
																		<Typography variant='subtitle1'>
																			<Skeleton variant='text' width={200} />
																		</Typography>
																	</Grid>
																	<Grid container item xs={12}>
																		<Typography variant='subtitle1'>
																			<Skeleton variant='text' width={180} />
																		</Typography>
																	</Grid>
																	<Grid container item xs={12}>
																		<Typography variant='subtitle1'>
																			<Skeleton variant='text' width={180} />
																		</Typography>
																	</Grid>
																</Grid>
															</CardContent>
														</Card>
													</Grid>
											  ))
											: contactData.length != 0 &&
											  contactData.map((item) => (
													<Grid item xs={3} key={item.id}>
														<Card variant='outlined'>
															<CardContent>
																<Grid container spacing={2}>
																	<Grid container item xs={4}>
																		<Typography variant='subtitle1'>Nama</Typography>
																	</Grid>
																	<Grid container item xs={8}>
																		<Typography variant='body1' noWrap>
																			{item.name}
																		</Typography>
																	</Grid>
																	<Grid container item xs={4}>
																		<Typography variant='subtitle1'>Jabatan</Typography>
																	</Grid>
																	<Grid container item xs={8}>
																		<Typography variant='body1' noWrap>
																			{item.function}
																		</Typography>
																	</Grid>
																	<Grid container item xs={4}>
																		<Typography variant='subtitle1'>Email</Typography>
																	</Grid>
																	<Grid container item xs={8}>
																		<Typography variant='body1' noWrap>
																			{item.email}
																		</Typography>
																	</Grid>
																	<Grid container item xs={4}>
																		<Typography variant='subtitle1'>No. Ponsel</Typography>
																	</Grid>
																	<Grid container item xs={8}>
																		<Typography variant='body1' noWrap>
																			{item.mobile}
																		</Typography>
																	</Grid>
																	<Grid container item xs={4}>
																		<Typography variant='subtitle1'>No. Telp</Typography>
																	</Grid>
																	<Grid container item xs={8}>
																		<Typography variant='body1' noWrap>
																			{item.phone}
																		</Typography>
																	</Grid>
																</Grid>
															</CardContent>
														</Card>
													</Grid>
											  ))}
										{/* ======================================================================= */}
									</Grid>
								</Grid>
							</Box>
						)}
					</Box>
				</Paper>
				<Paper variant='outlined' className={classes.boxSpacing}>
					<Box p={2}>
						<Typography variant='h6'>Informasi Tambahan</Typography>
						<Divider className={classes.marginTB1} />
						<Grid container>
							<Grid container item xs={6}>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='subtitle1'>Term Of Payment</Typography>
								</Grid>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='body1'>
										{companyDetail.isLoading ? (
											<Skeleton variant='text' width={100} />
										) : (
											String(profile.termPayment).replace('Days', 'hari')
										)}
									</Typography>
								</Grid>
							</Grid>
							<Grid container item xs={6}>
								<Grid item xs={6} className={classes.formLabel}>
									<Typography variant='subtitle1'>
										Status Manajemen Persetujuan
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.formLabel}>
									{companyDetail.isLoading ? (
										<Skeleton variant='text' width={100} />
									) : profile.status ? (
										<Typography variant='body1'>Aktif</Typography>
									) : (
										<Typography variant='body1'>Tidak Aktif</Typography>
									)}
								</Grid>
							</Grid>
						</Grid>
						{/* <Grid container direction='column'>
							<Grid item xs={12} className={classes.formLabel}>
								<Typography variant='subtitle1'>Tampilan harga diluar PPN</Typography>
							</Grid>
							<Grid item xs={12} className={classes.formLabel}>
								<FormControl>
									<FormGroup>
										<FormControlLabel
											value='end'
											control={<Checkbox color='primary' className={classes.bcheckBox} checked />}
											label='Harga produk akan ditampilkan dengan harga diluar pajak pada halaman listing katalog dan halaman detail produk.'
											labelPlacement='end'
										/>
									</FormGroup>
								</FormControl>
							</Grid>
						</Grid> */}
					</Box>
				</Paper>
				<Paper variant='outlined' className={classes.boxSpacing}>
					<Box p={2} pb={5}>
						<Typography variant='h6'>Dokumentasi</Typography>
						<Divider className={classes.marginTB1} />
						<Box className={classes.boxSpacing}>
							<Grid container spacing={4}>
								{/* ====================================================== */}
								{companyDetail.isLoading ? (
									Array.from(Array(2)).map((_, i) => (
										<Grid item key={i} xs={12} lg={6} className={classes.boxSpacing}>
											<Grid container spacing={2} alignItems='center'>
												<Grid item xs={3}>
													<Skeleton variant='rect' width={100} height={100} />
												</Grid>
												<Grid item xs={9}>
													<Box mb={1}>
														<Skeleton variant='text' width={100} />
													</Box>
													<Box mb={1}>
														<Skeleton variant='text' width={250} />
													</Box>
													<Box mb={1}>
														<Skeleton variant='text' width={250} />
													</Box>
												</Grid>
											</Grid>
										</Grid>
									))
								) : profile.document.length == 0 ? (
									<Grid container justify='content-center'>
										<Grid xs={12}>
											<Box mt={5} mb={2}>
												<Typography
													varian
													style={{ textAlign: 'center', color: '#a2a4a7' }}
												>
													Tidak ada dokumen yang terlampir
												</Typography>
											</Box>
										</Grid>
									</Grid>
								) : (
									profile.document.map((item, index) => (
										<Grid
											item
											xs={12}
											lg={6}
											key={item.id}
											className={classes.boxSpacing}
										>
											<Grid container spacing={2} alignItems='center'>
												<Grid item xs={3}>
													<Box className={classes.docImage}>
														{getFileThumb(
															item.file_name,
															item.file_store_mimetype,
															item.file_store_name
														)}
													</Box>
												</Grid>
												<Grid item xs={9}>
													<Box>
														<Grid container justify='flex-start'>
															<Grid item xs={2}>
																<Typography variant='subtitle1'>Nama</Typography>
															</Grid>
															<Grid item xs={10} lg={7}>
																<StyleTooltip
																	placement='bottom-start'
																	disableHoverListener={!docNameOverflow[index]}
																	title={
																		<Typography variant='body1' style={{ fontSize: 13 }}>
																			{item.name}
																		</Typography>
																	}
																>
																	<Typography
																		variant='body1'
																		className={classes.ellipsisText}
																		ref={docNameRef.current[index]}
																	>
																		{item.name}
																	</Typography>
																</StyleTooltip>
															</Grid>
														</Grid>
														<Grid container justify='flex-start'>
															<Grid item xs={2}>
																<Typography variant='subtitle1'>Nomor</Typography>
															</Grid>
															<Grid item xs={10} lg={7}>
																<StyleTooltip
																	placement='bottom-start'
																	disableHoverListener={!docNumOverflow[index]}
																	title={
																		<Typography variant='body1' style={{ fontSize: 13 }}>
																			{item.document_number}
																		</Typography>
																	}
																>
																	<Typography
																		variant='body1'
																		className={classes.ellipsisText}
																		ref={docNumberRef.current[index]}
																	>
																		{item.document_number}
																	</Typography>
																</StyleTooltip>
															</Grid>
														</Grid>
														<Grid container justify='flex-start'>
															<Grid item xs={2}>
																<Typography variant='subtitle1'>Tipe</Typography>
															</Grid>
															<Grid item xs={10} lg={7}>
																<StyleTooltip
																	placement='bottom-start'
																	disableHoverListener={!docTypeOverflow[index]}
																	title={
																		<Typography variant='body1' style={{ fontSize: 13 }}>
																			{item.legality_document_id.name}
																		</Typography>
																	}
																>
																	<Typography
																		variant='body1'
																		className={classes.ellipsisText}
																		ref={docTypeRef.current[index]}
																	>
																		{item.legality_document_id.name}
																	</Typography>
																</StyleTooltip>
															</Grid>
														</Grid>
													</Box>
												</Grid>
											</Grid>
										</Grid>
									))
								)}
								{/* ======================================================= */}
							</Grid>
						</Box>
					</Box>
				</Paper>
				<Grid
					container
					spacing={1}
					justify='flex-end'
					className={classes.boxSpacing}
				>
					<Grid item>
						<Button
							variant='outlined'
							color='primary'
							onClick={() => navigate('/dashboard')}
						>
							Kembali
						</Button>
					</Grid>
					<Grid item>
						<Button
							id='btn-ubah'
							variant='contained'
							color='secondary'
							onClick={(event) => {
								event.preventDefault()
								navigate('/dashboard/company-profile/edit', {
									replace: true,
								})
							}}
						>
							Ubah
						</Button>
					</Grid>
				</Grid>
			</Box>
		</LayoutDashboard>
	)
}

Companyprofile.propTypes = {
	navigate: PropTypes.func,
}
export default Companyprofile
