import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Typography, TextField, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { PurchaseRequestDetailStyles } from './styles'
import Modal from 'Components/modal'
import { requestPRDetail } from 'States/actions/Transactions/PurchaseRequest/detail'

const Component = (props) => {
	const classes = PurchaseRequestDetailStyles()
	const dispatch = useDispatch()
	const [cancelReason, setCancelReason] = useState('')
	const [statusICon, setStatusIcon] = useState(props.modalStatus)

	useEffect(() => {
		setStatusIcon(props.modalStatus)
	}, [props.modalStatus])

	return (
		<Modal
			open={props.succesModal || props.afterCancel || props.cancelModal}
			icon={statusICon ? statusICon : 'question'}
		>
			{props.afterCancel ? (
				<Box mt={2}>
					<Typography variant='body1'>
						<strong>
							{props.modalStatus == 'success'
								? 'Purchase Request Berhasil Ditolak'
								: 'Purchase Request Gagal Ditolak'}
						</strong>
					</Typography>
					<Button
						variant='contained'
						color='secondary'
						size='small'
						onClick={() => {
							dispatch(requestPRDetail(props.prNumber))
							props.onClose()
							setCancelReason('')
						}}
					>
						OK
					</Button>
				</Box>
			) : props.cancelModal ? (
				<>
					<Typography variant='body1'>Apakah Anda yakin</Typography>
					<Typography variant='body1'>
						Ingin menolak Purchase Request ini ?
					</Typography>
					<Box mt={2} width='525px'>
						<TextField
							variant='outlined'
							multiline
							fullWidth
							rows={4}
							value={cancelReason}
							placeholder='Masukkan alasan'
							onChange={(e) => setCancelReason(e.target.value)}
						/>
					</Box>
					<Box mt={2}>
						<Button
							variant='outlined'
							color='secondary'
							size='small'
							disable={props.approveLoading}
							onClick={() => {
								props.onClose()
								setCancelReason('')
							}}
						>
							Batal
						</Button>
						<Button
							variant='contained'
							color='secondary'
							disable={props.approveLoading}
							size='small'
							className={`${classes.buttonAction} ${classes.btnCancel}`}
							onClick={() => props.handleReject(cancelReason)}
						>
							Ya, Tolak
						</Button>
					</Box>
				</>
			) : (
				<Box mt={2}>
					<Typography variant='body1'>
						<strong>
							{props.docFile.length != 0
								? props.modalStatus == 'success'
									? 'Purchase Order Berhasil Dibuat'
									: 'Purchase Order Gagal Dibuat'
								: props.modalStatus == 'success'
								? 'Purchase Request Berhasil Disetujui'
								: 'Purchase Request Gagal Disetujui'}
						</strong>
					</Typography>
					<Button
						variant='contained'
						color='secondary'
						size='small'
						onClick={
							props.modalStatus == 'success'
								? () => props.handleSuccessCreate()
								: () => props.onClose()
						}
					>
						OK
					</Button>
				</Box>
			)}
		</Modal>
	)
}

Component.propTypes = {
	succesModal: PropTypes.bool,
	modalStatus: PropTypes.string,
	onClose: PropTypes.func,
	afterCancel: PropTypes.bool,
	cancelModal: PropTypes.bool,
	prNumber: PropTypes.string,
	approveLoading: PropTypes.bool,
	handleReject: PropTypes.func,
	docFile: PropTypes.array,
	handleSuccessCreate: PropTypes.func,
}

export default Component
