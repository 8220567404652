import React, { useState, useEffect, useMemo } from 'react'
import styles from './styles'
import PropTypes from 'prop-types'
import {
	Box,
	Typography,
	Grid,
	Select,
	MenuItem,
	CircularProgress,
} from '@material-ui/core'
import DataTable from 'react-data-table-component'
import {
	dataTableStyle,
	dataTableStyleNoPadding,
	tableFixed,
} from '../../assets/dataTable'
import { Boxes } from 'Components/svgs'
import Pagination from '@material-ui/lab/Pagination'
import ButtonFilter from '../buttonFilter'

const Component = (props) => {
	const classes = styles()
	const [totalRecord, setTotalRecord] = useState(1)
	const [totalPage, setTotalPage] = useState(1)
	const [selectedState, setSelectedState] = useState(null)

	useMemo(() => {
		if (props.buttonState) {
			let findSelectedState = props.buttonState.find(
				(element) =>
					element.status == props.queryState || element.state == props.queryState
			)
			setSelectedState(findSelectedState ? findSelectedState : null)
		}
	}, [props.queryState])

	useEffect(() => {
		if (props.isReport) {
			setTotalRecord(props.totalRowData)
			setTotalPage(Math.ceil(props.totalRowData / props.perPage))
		} else {
			if (
				props.pagination &&
				props.pagination.total_records &&
				props.pagination.total_records > 0
			) {
				setTotalRecord(props.pagination.total_records)
				setTotalPage(Math.ceil(props.pagination.total_records / props.perPage))
			} else if (
				props.pagination &&
				props.pagination.total_record &&
				props.pagination.total_record
			) {
				setTotalRecord(props.pagination.total_record)
				setTotalPage(Math.ceil(props.pagination.total_record / props.perPage))
			} else {
				setTotalRecord(0)
				setTotalPage(0)
			}
		}
	}, [props.pagination, props.totalRowData])

	const handleChangeStatus = (event, btnStatus) => {
		props.buttonStatusClick(event, btnStatus)
	}

	const handleChangeState = (event, btnState) => {
		props.buttonStateClick(event, btnState)
	}

	const handlelimitChange = (event, btnState) => {
		props.limitChange(event, btnState)
	}

	const handlePageChange = (event, page) => {
		props.paginationPageChange(event, page)
	}

	const buildStatusButton = () => {
		return props.buttonStatus && props.buttonStatus.length != 0
			? props.buttonStatus.map((row, index) => {
				let variant = row.state == props.queryStatus
				return (
					<ButtonFilter
						variant={variant}
						onClick={(event) => handleChangeStatus(event, row.state)}
						key={index}
						status={row.status}
						customStatus={true}
					/>
				)
			})
			: null
	}

	const buildTahapButton = () => {
		return props.buttonState && props.buttonState.length != 0
			? props.buttonState.map((row, index) => {
				let variant =
					row.status == props.queryState || row.state == props.queryState
				return (
					<ButtonFilter
						variant={variant}
						onClick={(event) => handleChangeState(event, row.state)}
						key={index}
						status={row.status}
					/>
				)
			})
			: null
	}

	const buildDropdownLimit = (items) => {
		return (
			<Grid
				container
				justify='flex-end'
				alignItems='center'
				classes={{ root: classes.dropdownLimit }}
			>
				<Typography className={classes.typoPage2}>
					<strong>Tampilkan</strong>
				</Typography>
				<Select
					classes={{ root: classes.selectContainer, icon: classes.selectIcon }}
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={props.perPage}
					onChange={handlelimitChange}
				>
					{items.map((row, index) => {
						return (
							<MenuItem key={index} value={row}>
								{row}
							</MenuItem>
						)
					})}
				</Select>
			</Grid>
		)
	}

	return (
		<>
			{('buttonState' in props || 'limitValue' in props) && (
				<Box>
					<Grid container>
						<Grid item xs={9}>
							{props && props.buttonState ? (
								<>
									<Typography className={classes.statusFilter}>
										{props.labelState}
									</Typography>
									{buildTahapButton()}
								</>
							) : (
								''
							)}
							{selectedState && selectedState.child ? (
								<>
									<Typography
										className={[classes.statusFilter, classes.paddingTopFilter]}
									>
										{props.labelStatus}
									</Typography>
									{buildStatusButton()}
								</>
							) : (
								''
							)}
						</Grid>
						{'limitValue' in props ? buildDropdownLimit(props.limitValue) : ' '}
					</Grid>
				</Box>
			)}
			<Box>
				<DataTable
					columns={props.columns}
					customStyles={
						'buttonFilter' in props || 'limitValue' in props
							? props.fixedWidth
								? { ...dataTableStyle, ...tableFixed }
								: dataTableStyle
							: props.fixedWidth
							? { ...dataTableStyleNoPadding, ...tableFixed }
							: dataTableStyleNoPadding
					}
					data={totalRecord > 0 ? props.data : []}
					progressPending={props.isLoading}
					responsive
					theme='mbizTheme'
					noDataComponent={
						<Box className={classes.textCenter} py={10}>
							<Boxes />
							<Typography>
								Tidak ada data {props.name} yang dapat ditampilkan
							</Typography>
						</Box>
					}
					progressComponent={
						<Box className={classes.textCenter} py={10}>
							<CircularProgress color='secondary' variant='indeterminate' />
							<Typography>Mohon Tunggu</Typography>
						</Box>
					}
				/>
			</Box>

			{('pagination' in props &&
				props.pagination &&
				!props.isLoading &&
				totalRecord &&
				totalRecord > 0 && (
					<Box my={2}>
						<Grid container spacing={3} direction='row'>
							<Grid item xs={3}>
								{props &&
								props.pagination &&
								!props.isLoading &&
								props.transactionDesc ? (
									<Typography className={classes.typoPage}>
										{props.data && props.data.length
											? `Memuat ${props.pagination.total_records} ${props.transactionDesc}`
											: ''}
									</Typography>
								) : null}
							</Grid>
							<Grid item xs={9}>
								<Grid
									container
									justify='flex-end'
									alignItems='flex-end'
									direction='row'
								>
									<Typography className={classes.typoPage}>
										{props.data && props.data.length
											? props.isReport
												? `Menampilkan ${
														props.pageNow == 1 ? 1 : props.perPage * (props.pageNow - 1) + 1
												  } - ${
														props.perPage * (props.pageNow - 1) + props.data.length
												  } baris data dari ${props.totalRowData}`
												: `Menampilkan ${
														props.pageNow == 1 ? 1 : props.perPage * (props.pageNow - 1) + 1
												  } - ${
														props.perPage * (props.pageNow - 1) + props.data.length
												  } data dari ${totalRecord}`
											: ''}
									</Typography>
									<Pagination
										count={totalPage}
										defaultValue={props.pageNow}
										page={props.pageNow}
										shape='rounded'
										color='secondary'
										onChange={handlePageChange}
										siblingCount={2}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				)) ||
				null}
		</>
	)
}

Component.propTypes = {
	buttonStatus: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	buttonStatusClick: PropTypes.func,
	buttonState: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	buttonStateClick: PropTypes.func,
	limitValue: PropTypes.array,
	perPage: PropTypes.number,
	limitChange: PropTypes.func,
	columns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	isLoading: PropTypes.bool,
	pagination: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	name: PropTypes.string,
	paginationPageChange: PropTypes.func,
	pageNow: PropTypes.number,
	isReport: PropTypes.bool,
	totalRowData: PropTypes.number,
	transactionDesc: PropTypes.string,
	fixedWidth: PropTypes.bool,
	queryState: PropTypes.string,
	queryStatus: PropTypes.string,
	labelStatus: PropTypes.string,
	labelState: PropTypes.string,
}

Component.defaultProps = {
	fixedWidth: false,
	labelStatus: 'Status',
	labelState: 'Tahap',
}

export default Component
