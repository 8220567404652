import { BUDGET_LIST_VALID } from '../../types'
import API from '../../../utils/api'

const loading = () => {
	return {
		type: `${BUDGET_LIST_VALID}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${BUDGET_LIST_VALID}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${BUDGET_LIST_VALID}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestBudgetList = (contact_id) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			let response = await API({
				method: 'GET',
				url: `/budgets/valid/list?contact_id=${contact_id}`,
			})
			dispatch(success(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
