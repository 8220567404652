import React from 'react'
import { useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	Paper,
	ButtonBase,
	Container,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import DescriptionIcon from '@material-ui/icons/Description'
import { dashboardStyle } from './styles'
import { navigate } from 'gatsby'

const Dashboard = () => {
	const classes = dashboardStyle()
	const auth = useSelector((state) => state.auth)
	const handleNavigate = (path) => {
		navigate(path)
	}
	// const handleLogout = () => {
	// 	dispatch(logoutAction())
	// }

	return (
		<DashboardLayout>
			<Container styles='margin: 0px;'>
				<Box mb={3} pt={3} alignItems='center' display='flex'>
					<Typography variant='h6'>Selamat Datang {auth.data.data.name}</Typography>
				</Box>
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Grid item>
								<Paper elevation={0}>
									<Box display='flex' alignItems='center' height={80}>
										<Grid container justify='space-between'>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													borderRight={1}
													className={classes.borderColor}
												>
													<HelpIcon fontSize='large' />
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='flex-start'
													height={80}
												>
													<Box display='block'>
														<Typography variant='subtitle1'>How To</Typography>
														<Typography variant='body2'>
															Lihat Cara Melakukan Pembelian
														</Typography>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													onClick={() => handleNavigate('/pages/how-to')}
												>
													<ButtonBase className={classes.buttonFull}>
														<ChevronRightIcon fontSize='large' />
													</ButtonBase>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Paper>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid item>
								<Paper elevation={0}>
									<Box display='flex' alignItems='center' height={80}>
										<Grid container justify='space-between'>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													borderRight={1}
													className={classes.borderColor}
												>
													<ShoppingBasketIcon fontSize='large' />
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='flex-start'
													height={80}
												>
													<Box display='block'>
														<Typography variant='subtitle1'>Katalog</Typography>
														<Typography variant='body2'>Lihat Katalog Mbiz</Typography>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													onClick={() => handleNavigate('/catalog/search?q=')}
												>
													<ButtonBase className={classes.buttonFull}>
														<ChevronRightIcon fontSize='large' />
													</ButtonBase>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Paper>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid item>
								<Paper elevation={0}>
									<Box display='flex' alignItems='center' height={80}>
										<Grid container justify='space-between'>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													borderRight={1}
													className={classes.borderColor}
												>
													<DescriptionIcon fontSize='large' />
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='flex-start'
													height={80}
												>
													<Box display='block'>
														<Typography variant='subtitle1'>RFQ</Typography>
														<Typography variant='body2'>
															Lihat Request for Quotation yang pernah terbuat
														</Typography>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													onClick={() => handleNavigate('/dashboard/rfq')}
												>
													<ButtonBase className={classes.buttonFull}>
														<ChevronRightIcon fontSize='large' />
													</ButtonBase>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Paper>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid item>
								<Paper elevation={0}>
									<Box display='flex' alignItems='center' height={80}>
										<Grid container justify='space-between'>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													borderRight={1}
													className={classes.borderColor}
												>
													<DescriptionIcon fontSize='large' />
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='flex-start'
													height={80}
												>
													<Box display='block'>
														<Typography variant='subtitle1'>Quotation</Typography>
														<Typography variant='body2'>
															Lihat Quotation yang pernah diterima
														</Typography>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													onClick={() => handleNavigate('/dashboard/quotation?state=sent')}
												>
													<ButtonBase className={classes.buttonFull}>
														<ChevronRightIcon fontSize='large' />
													</ButtonBase>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Paper>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid item>
								<Paper elevation={0}>
									<Box display='flex' alignItems='center' height={80}>
										<Grid container justify='space-between'>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													borderRight={1}
													className={classes.borderColor}
												>
													<DescriptionIcon fontSize='large' />
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='flex-start'
													height={80}
												>
													<Box display='block'>
														<Typography variant='subtitle1'>Purchase Order</Typography>
														<Typography variant='body2'>
															Lihat Purchase Order yang pernah terbuat
														</Typography>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													onClick={() => handleNavigate('/dashboard/purchase-order')}
												>
													<ButtonBase className={classes.buttonFull}>
														<ChevronRightIcon fontSize='large' />
													</ButtonBase>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Paper>
							</Grid>
						</Grid>
						{/* <Grid item xs={6}>
							<Grid item>
								<Paper elevation={0}>
									<Box display='flex' alignItems='center' height={80}>
										<Grid container justify='space-between'>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
													borderRight={1}
													className={classes.borderColor}
												>
													<DescriptionIcon fontSize='large' />
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='flex-start'
													height={80}
												>
													<Box display='block'>
														<Typography variant='subtitle1'>
															Purchase Order Diterima
														</Typography>
														<Typography variant='body2'>
															Lihat Pesanan Purchase Order yang telah diterima
														</Typography>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={2}>
												<Box
													display='flex'
													alignItems='center'
													justifyContent='center'
													height={80}
												>
													<ButtonBase className={classes.buttonFull}>
														<ChevronRightIcon fontSize='large' />
													</ButtonBase>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Paper>
							</Grid>
						</Grid> */}
					</Grid>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

export default Dashboard
