import { CREATE_PO } from '../../../types'
import { success, failure, loading } from '../../../commonActions'
import API from '../../../../utils/api'

export const resetAction = () => {
	return {
		type: `${CREATE_PO}_RESET`,
	}
}

export const createPo = (saleOrderId, data, storage_id) => {
	return async (dispatch, getState) => {
		let quotationDetail = getState().detailQuotation.data.client_order_ref
		if (quotationDetail === false) {
			quotationDetail = ''
		}
		dispatch(loading(CREATE_PO))
		try {
			const response = await API({
				method: 'POST',
				url: '/purchase-orders/create',
				data: {
					id: Number(saleOrderId),
					customer_order_notes: data.buying_note,
					client_order_ref: data.refNumber === '' ? quotationDetail : data.refNumber,
					storage_id: storage_id,
					budget_data: data.budget_data,
				},
			})
			if (response.data) {
				if ('error_backend' in response.data) {
					let error
					error = response.data.error_backend
					dispatch(failure(CREATE_PO, error))
				} else {
					dispatch(success(CREATE_PO, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(CREATE_PO, err))
		}
	}
}
