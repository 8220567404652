import React, { useEffect, useState } from 'react'
import {
	Paper,
	Table,
	TableRow,
	TableBody,
	TableHead,
	Box,
	Grid,
	Typography,
	TableCell,
} from '@material-ui/core'
import { filter } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
	searchInvoice,
	calculateTotal,
	selectInvoice,
} from 'States/actions/Transactions/Invoice/getInvoiceByName'
import { Delete } from '@material-ui/icons'
import Select from 'react-select'
import Styles from './styles'
import { numeralFormat } from '../../../../utils/formatMoney'

const Component = () => {
	const classes = Styles()
	const dispatch = useDispatch()
	const data = useSelector((state) => state.bulkPayment)
	const [selectedInvoice, setSelectedInvoice] = useState([])

	useEffect(() => {
		dispatch(searchInvoice())
	}, [])

	useEffect(() => {
		dispatch(calculateTotal(selectedInvoice))
		dispatch(selectInvoice(selectedInvoice))
	}, [selectedInvoice])

	const handleSelect = (value) => {
		let newState = selectedInvoice
		if (!selectedInvoice.length) {
			setSelectedInvoice((prevState) => [...prevState, value])
		} else {
			let data = filter(newState, (n) => n.id !== value.id)
			setSelectedInvoice(data)
			setSelectedInvoice((prevState) => [...prevState, value])
		}
	}

	const handleRemove = (element) => {
		let newSelected = selectedInvoice
		let data = filter(newSelected, (n) => n.label != element.label)
		setSelectedInvoice(data)
	}

	return (
		<Box mb={3}>
			<Paper>
				<Grid container>
					<Grid item lg={12}>
						<Box p={3}>
							<Grid container alignItems='center'>
								<Grid item lg={2}>
									<Typography variant='body1'>
										<strong>No. Tagihan</strong>
									</Typography>
								</Grid>
								<Grid item>
									<Select
										className={classes.select}
										isSearchable
										isLoading={data.isLoading}
										options={data.data}
										hideSelectedOptions
										onChange={(val) => handleSelect(val)}
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item lg={12}>
						<Box px={3}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>No</TableCell>
										<TableCell>No. Tagihan</TableCell>
										<TableCell>Tanggal Tagihan</TableCell>
										<TableCell>Total Tagihan</TableCell>
										<TableCell>Jatuh Tempo</TableCell>
										<TableCell>Sisa Pembayaran</TableCell>
										<TableCell>Aksi</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{!selectedInvoice.length
										? null
										: selectedInvoice.map((item, index) => (
												<TableRow key={index}>
													<TableCell>{index + 1}</TableCell>
													<TableCell>
														<a
															href={`${window.location.origin}/dashboard/invoice/${item.label}`}
															target='blank'
														>
															{item.label}
														</a>
													</TableCell>
													<TableCell>{item.invoice_date}</TableCell>
													<TableCell>{numeralFormat(item.amount_total_signed)}</TableCell>
													<TableCell>{item.invoice_date_due}</TableCell>
													<TableCell>{numeralFormat(item.amount_residual_signed)}</TableCell>
													<TableCell>
														<Delete onClick={() => handleRemove(item)} />
													</TableCell>
												</TableRow>
										  ))}
								</TableBody>
							</Table>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	)
}

export default Component
