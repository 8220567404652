import { USERS_ACCOUNT_UPDATE_NEW_USER } from '../../types'
import API from '../../../utils/api'

const loading = () => {
	return {
		type: `${USERS_ACCOUNT_UPDATE_NEW_USER}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${USERS_ACCOUNT_UPDATE_NEW_USER}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${USERS_ACCOUNT_UPDATE_NEW_USER}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestUserAccountUpdateNewUser = (data, userID) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			let response = await API({
				method: 'PUT',
				data: { ...data },
				url: '/users/new/update/' + userID,
			})
			if (response) {
				if (response.data.error_backend) {
					dispatch(failure(response.data.error_backend))
				} else {
					dispatch(success(response.data))
				}
			}
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
