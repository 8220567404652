import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 250
const styles = makeStyles((theme) => ({
	container: {
		width: '100%',
		minHeight: '100%',
		[theme.breakpoints.up('lg')]: {
			padding: 0,
		},
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	containerPadding: {
		backgroundColor: theme.palette.background.dashboard,
		padding: theme.spacing(0),
		[theme.breakpoints.up('xl')]: {
			width: 'calc( 100% - 250px )',
		},
	},
}))
export default styles
