import { PROMO_PAGES, CATALOG_NEW_PRICE } from '../../types'
import API from '../../../utils/api'
import { success, loading, failure } from '../../commonActions'

export const resetAction = () => {
	return {
		type: `${PROMO_PAGES}_RESET`,
	}
}

export const getPromoPages = (tagName, auth) => {
	return async (dispatch) => {
		dispatch(loading(PROMO_PAGES))
		try {
			let response = await API({
				method: 'GET',
				url: `/promo/pages/${tagName}`,
			})
			if (response.data && response.data.error_backend) {
				dispatch(failure(PROMO_PAGES, 'not found tag name'))
			} else {
				dispatch(success(PROMO_PAGES, response.data))
				if (response.data) {
					dispatch(success(CATALOG_NEW_PRICE, null))
					response.data.data.items.map(async (val) => {
						let product_id = val.id
						let price_param_req = {}
						price_param_req.partner_id = 0
						if (response.data && auth.loggedIn) {
							price_param_req.partner_id = auth.data.data.companies.partner_id
						}
						price_param_req.pricelist_id = 0
						price_param_req.add_qty = 1
						price_param_req.product_id = 0
						price_param_req.product_template_id = product_id
						try {
							const resp_price = await API({
								method: 'POST',
								url: '/price-list/product',
								data: price_param_req,
							})
							if (resp_price) {
								if (resp_price.data.error_backend) {
									let error = {}
									error.err = resp_price.data.error_backend
									dispatch(failure(CATALOG_NEW_PRICE, error))
								} else {
									dispatch(success(CATALOG_NEW_PRICE, resp_price.data))
								}
							}
						} catch (err) {
							err.http_body
								? dispatch(failure(CATALOG_NEW_PRICE, err.http_body))
								: dispatch(failure(CATALOG_NEW_PRICE, err.http_body))
						}
					})
				}
			}
		} catch (err) {
			dispatch(failure(PROMO_PAGES, err))
		}
	}
}
