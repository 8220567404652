import React from 'react'
import { Box, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './styles'
import NumericInput from 'react-numeric-input'

const Component = (props) => {
	const classes = styles()
	return (
		<Box display='flex' flexDirection='row' justifyContent={props.justify}>
			<Button
				className={classes.buttonGroupPreppend}
				onClick={props.onDecrease}
				disableElevation
				variant='contained'
				color='secondary'
				size='small'
			>
				<strong>-</strong>
			</Button>
			<NumericInput
				min={props.minValue}
				className={classes.buttonGroupInput}
				pattern='[0-9]*'
				type='number'
				onChange={props.onInputChange}
				value={props.value}
				max={props.maxValue}
				noStyle
			/>
			<Button
				className={classes.buttonGroupAppend}
				onClick={props.onIncrease}
				disableElevation
				variant='contained'
				color='secondary'
				size='small'
			>
				<strong>+</strong>
			</Button>
		</Box>
	)
}

Component.propTypes = {
	value: PropTypes.number,
	justify: PropTypes.string,
	onIncrease: PropTypes.func,
	onDecrease: PropTypes.func,
	onInputChange: PropTypes.func,
	maxValue: PropTypes.number,
	minValue: PropTypes.number,
}

Component.defaultProps = {
	value: 0,
	justify: 'center',
	minValue: 1,
}

export default Component
