import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	status: ({ variant }) => ({
		backgroundColor: theme.palette.status[variant],
		color:
			variant === 'yellow' || variant === 'lightBlue'
				? theme.palette.status.text2
				: theme.palette.status.text1,
		fontSize: '0.8rem',
		padding: theme.spacing(0.5, 0.7),
		borderRadius: theme.shape.borderRadius,
		width: '100%',
		textAlign: 'center',
	}),
}))

export default styles
