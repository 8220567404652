import React from 'react'
import PropTypes from 'prop-types'
import Header from '../header'
import Footer from '../footer'
import Sidebar from '../sidebar'
import Styles from './styles'
import { Container, Box } from '@material-ui/core'
import SEO from '../seo'
// import ScrollToTop from '../scrollToTop'
import { withStyles } from '@material-ui/styles'

const GlobalCss = withStyles({
	'@global': {
		'.rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret': {
			color: '#007BFF',
		},
		'.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active:hover':
			{
				borderColor: '#e5e5ea !important',
			},
		'.rs-picker-daterange.rs-picker-default': {
			width: 'calc(100% - 20px)',
		},
		'.rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable': {
			textTransform: 'uppercase',
			fontWeight: '500',
		},
		'.searchContainer .MuiFormControl-root.MuiTextField-root.nameSearch': {
			width: 'calc(100% - 50px)',
		},
		'.searchContainer input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInput-inputTypeSearch':
			{
				padding: '9px',
				marginTop: '1px',
				fontSize: '14px',
			},
		'.searchContainer button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary':
			{
				padding: '7px 9px 6px',
				marginTop: '1px',
			},
		'.searchContainer span.rs-picker-toggle-placeholder': {
			fontSize: '16px',
			color: '#a9acaf',
		},
		a: {
			textDecoration: 'none',
			color: '#007BFF',
		},
	},
})(() => null)

const LayoutComponent = ({ children }) => {
	const classes = Styles()
	return (
		<Box>
			<GlobalCss />
			<SEO />
			<Header />
			<Container className={classes.containerPadding} maxWidth='xl'>
				<Box display='flex'>
					<Sidebar />
					{children}
				</Box>
			</Container>
			<Footer className={classes.footers} />
			{/* <ScrollToTop /> */}
		</Box>
	)
}

LayoutComponent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	header: PropTypes.bool,
	newsletter: PropTypes.bool,
	className: PropTypes.string,
	withMedia: PropTypes.bool,
	variant: PropTypes.string,
}

LayoutComponent.defaultProps = {
	header: true,
	newsletter: true,
	withMedia: false,
	variant: 'dark',
}

export default LayoutComponent
