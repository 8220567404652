import React from 'react'
import { Grid } from '@material-ui/core'
import LayoutComponent from 'Components/layout'
import { AccountForm } from 'Components/forms'
import { loginStyle } from '../Login/styles'
import Logo from '../../assets/images/svgs/logo-white.svg'
import PropTypes from 'prop-types'

const handleNavigate = (path) => {
	// navigate(path)
	window.location.href = path
}

const Account = () => {
	const classes_loginStyle = loginStyle()
	return (
		<LayoutComponent
			header={false}
			newsletter={false}
			className={classes_loginStyle.login_background}
			variant='light'
		>
			<Grid className={classes_loginStyle.form_login} container>
				<Grid item xs={12} sm={2}></Grid>
				<Grid item xs={12} sm={4} className={classes_loginStyle.container}>
					<div className={classes_loginStyle.container_content}>
						<img
							onClick={() => handleNavigate('/')}
							src={Logo}
							className={classes_loginStyle.mbiz_image}
						/>
						<div className={classes_loginStyle.small}>
							Platform <span className={classes_loginStyle.text_warning}>B2B</span>{' '}
							Indonesia
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={4} className={classes_loginStyle.container_field}>
					<AccountForm classes={classes_loginStyle} />
				</Grid>
				<Grid item xs={12} sm={2}></Grid>
			</Grid>
		</LayoutComponent>
	)
}

Account.propTypes = {
	className: PropTypes.object,
}
export default Account
