import React from 'react'
import Image from 'material-ui-image'
import { getFileExtension } from "./globalUtils"
import { Pdf, PPT, Docx, Excel, BlankFile } from 'Components/svgs'

const imageStorage = process.env.GATSBY_IMAGE_URL

const listExt = {
	excel: ['xls', 'xlsx'],
	doc: ['doc', 'docx', 'dotx'],
	ppt: ['ppt', 'pptx'],
	pdf: ['pdf'],
	image: ['jpg', 'jpeg', 'png', 'gif', 'jpe'],
}

const listMimeType = {
	excel: [
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	],
	doc: [
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	],
	ppt: [
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	],
	pdf: ['application/pdf'],
	image: ['image/jpeg', 'image/png', 'image/gif'],
}

export const getFileThumb = (filename, mimetype, fstorename) => {
  let file_ext = getFileExtension(filename)
  let ext = file_ext ? file_ext.toLowerCase() : ''
  if (listExt.excel.includes(ext) || listMimeType.excel.includes(mimetype)) {
    return <Excel />
  } else if (listExt.doc.includes(ext) || listMimeType.doc.includes(mimetype)) {
    return <Docx />
  } else if (listExt.ppt.includes(ext) || listMimeType.ppt.includes(mimetype)) {
    return <PPT />
  } else if (listExt.pdf.includes(ext) || listMimeType.pdf.includes(mimetype)) {
    return <Pdf />
  } else if (
    listExt.image.includes(ext) ||
    listMimeType.image.includes(mimetype)
  ) {
    return <Image src={`${imageStorage}/180x180/erp/${fstorename}`} />
  }
  return <BlankFile />
}