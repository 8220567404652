import { makeStyles } from '@material-ui/core/styles'

export const PrivacyPolicyStyle = makeStyles((theme) => ({
	contentList: {
		fontSize: theme.typography.body1.fontSize,
		counterReset: 'item',
		listStyleType: 'decimal',
		display: 'block',
		padding: 0,
		textAlign: 'justify',

		'&>li': {
			margin: '10px 0 20px 30px',
			'&::before': {
				content: 'counters(item, ".")',
				counterIncrement: 'item',
				position: 'absolute',
				marginRight: '100%',
				right: '10px',
			},
		},

		'&>li>ol': {
			padding: 0,
			counterReset: 'item',
			listStyleType: 'decimal',
		},

		'&>li>ol>li>ol': {
			paddingLeft: 20,
			counterReset: 'item',
			listStyleType: 'decimal',
		},

		'&>li>ol>li': {
			listStyle: 'decimal outside',
			margin: '10px 0 10px 30px',
			position: 'relative',
			display: 'block',

			/* '&::before': {
				content: 'counters(item, ".")',
				counterIncrement: 'item',
				position: 'absolute',
				marginRight: '100%',
				right: '10px',
			}, */

			'&>span': {
				position: 'absolute',
				left: -30,
			},

			'&>b': {
				display: 'block',
				marginTop: 10,
			},
		},

		'&>li>ol>li>ol>li': {
			listStyle: 'decimal outside',
			margin: '10px 0 10px 30px',
			position: 'relative',
			display: 'block',

			'&::before': {
				content: 'counters(item, ".")',
				counterIncrement: 'item',
				position: 'absolute',
				marginRight: '100%',
				right: '10px',
			},
		},
	},

	linkColor: {
		color: theme.palette.secondary.main,
	},
}))
