import React from 'react'
import {
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	Typography,
	Box,
	Paper,
	Divider,
	DialogContentText,
	Button,
} from '@material-ui/core'
import { roleStyle } from './styles'
import PropTypes from 'prop-types'

function Component({ handleClose, scroll, open }) {
	const classes = roleStyle()
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			scroll={scroll}
			aria-labelledby='scroll-dialog-title'
			maxWidth='md'
			aria-describedby='scroll-dialog-description'
		>
			<DialogTitle id='scroll-dialog-title' onClose={handleClose}>
				Roles
			</DialogTitle>
			<DialogContent dividers={scroll === 'paper'}>
				<DialogContentText id='scroll-dialog-description' tabIndex={-1}>
					<Paper variant='outlined' className={classes.textDialog}>
						<Box p={2}>
							<Typography variant='subtitle1'>Admin</Typography>
							<Divider />
							<Typography variant='body1' gutterBottom>
								Akun dengan role Admin akan dapat mengatur administrasi perusahaan pada
								sistem seperti:
							</Typography>
							<ul>
								<li>Melengkapi/memperbarui informasi profil perusahaan</li>
								<li>mengelola akun yang digunakan perusahaan</li>
								<li>mengatur manajemen persetujuan dari perusahaan</li>
							</ul>
						</Box>
					</Paper>
					<Paper variant='outlined' className={classes.textDialog}>
						<Box p={2}>
							<Typography variant='subtitle1'>Penyetuju</Typography>
							<Divider />
							<Typography variant='body1' gutterBottom>
								Jika Manejemen persetujuan dalam keadaan aktif, akun dengan role
								Penyetuju akan dapat menyetujui ataupun menolak dokumen Purchase Request
								yang dibuat oleh akun Staff dan lalu dapat membuat Purchase Order dari
								dokumen PO tersebut.
							</Typography>
						</Box>
					</Paper>
					<Paper variant='outlined' className={classes.textDialog}>
						<Box p={2}>
							<Typography variant='subtitle1'>Pemohon</Typography>
							<Divider />
							<Typography variant='body1' gutterBottom>
								Akun dengan role Pemohon akan dapat melakukan proses-proses utama
								transaksi seperti:
							</Typography>
							<ul>
								<li>memasukan produk yang akan di beli ke dalam keranjang</li>
								<li>membuat RFQ dari keranjang yang dimiliki </li>
								<li>menolak Quotation yang diterima dari penjual</li>
								<li>
									membuat Order dari Quotation yang diterima dari Penjual (jika manajemen
									persetujuan tidak aktif)
								</li>
								<li>
									membuat Purchase Request dari Quotation yang diterima dari penjual
									(jika manajemen persetujuan diaktifkan)
								</li>
							</ul>
						</Box>
					</Paper>
					<Paper variant='outlined' className={classes.textDialog}>
						<Box p={2}>
							<Typography variant='subtitle1'>Finance</Typography>
							<Divider />
							<Typography variant='body1' gutterBottom>
								Akun dengan role Finance akan dapat melakukan aktifitas yang berkaitan
								dengan keuangan perusahaan seperti:
							</Typography>
							<ul>
								<li>mendaftarkan akun bank perusahaan pada sistem</li>
								<li>
									melakukan konfirmasi pembayaran dari tagihan yang dimilik perusahaan
									ada sistem
								</li>
							</ul>
						</Box>
					</Paper>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	)
}

Component.propTypes = {
	handleClose: PropTypes.func,
	scroll: PropTypes.string,
	open: PropTypes.bool,
}

export default Component
