import { APPROVAL_DEPARTMENT_DETAIL } from '../../../types'
import API from '../../../../utils/api'

export const resetAction = () => {
	return {
		type: `${APPROVAL_DEPARTMENT_DETAIL}_RESET`,
	}
}

const loading = () => {
	return {
		type: `${APPROVAL_DEPARTMENT_DETAIL}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${APPROVAL_DEPARTMENT_DETAIL}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${APPROVAL_DEPARTMENT_DETAIL}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestADDetail = (id) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			let response = await API({
				method: 'GET',
				url: '/approvers/details/departments/' + id,
			})

			dispatch(success(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
