import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	buttonSearch: {
		padding: '4.5px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: theme.shape.borderRadius,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	customButton: {
		fontSize: '12px',
		marginRight: '10px',
	},
	buttonDetail: {
		fontSize: '12px',
	},
	gridSearch: {
		width: '100%',
	},
	iconAction: {
		fontSize: '22px',
	},
	textCenter: {
		textAlign: 'center',
	},
	buttonAction: {
		padding: theme.spacing(0.5, 1),
		minWidth: 'initial',
	},
	container: {
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		flex: 1,
	},
}))

export default useStyles
