/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import {
	ApprovalEproc,
	ApprovalEprocAdd,
	ApprovalCategory,
	ApprovalCategoryAdd,
	ApprovalConfig,
	ApprovalDepartment,
	ApprovalDepartmentAdd,
	ApprovalDepartmentEdit,
} from '../routes'
import { rolesText } from '../constants/text'

export const ApprovalNav = () => {
	return (
		<>
			{/* Approval E-PROCUREMENT */}
			<PrivateRoute
				path='/dashboard/approval/eproc'
				// roles={`${penyetuju},${finance},${admin}`}
				roles={rolesText('admin')}
				Component={ApprovalEproc}
			/>
			<PrivateRoute
				path='/dashboard/approval/eproc/add'
				roles={rolesText('admin')}
				Component={ApprovalEprocAdd}
			/>
			{/* <PrivateRoute
				path='/dashboard/approval/eproc/edit/:approverID'
				roles={rolesText('admin')}
				Component={ApprovalEprocEdit}
			/> */}
			{/* Approval Category */}
			<PrivateRoute
				path='/dashboard/approval/category'
				// roles={`${penyetuju},${finance},${admin}`}
				roles={rolesText('admin')}
				Component={ApprovalCategory}
			/>
			<PrivateRoute
				path='/dashboard/approval/category/add'
				roles={rolesText('admin')}
				Component={ApprovalCategoryAdd}
			/>
			{/* <PrivateRoute
				path='/dashboard/approval/category/edit/:approverID'
				roles={rolesText('admin')}
				Component={ApprovalCategoryEdit}
			/> */}
			{/* Approval Config */}
			<PrivateRoute
				path='/dashboard/approval/configure'
				roles={rolesText('admin')}
				Component={ApprovalConfig}
			/>
			{/* Approval Departement */}
			<PrivateRoute
				path='/dashboard/approval/department'
				// roles={`${penyetuju},${finance},${admin}`}
				roles={rolesText('admin')}
				Component={ApprovalDepartment}
			/>
			<PrivateRoute
				path='/dashboard/approval/department/add'
				roles={rolesText('admin')}
				Component={ApprovalDepartmentAdd}
			/>
			<PrivateRoute
				path='/dashboard/approval/department/edit/:approverID'
				roles={rolesText('admin')}
				Component={ApprovalDepartmentEdit}
			/>
		</>
	)
}
