import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import PropTypes from 'prop-types'
import {
	Box,
	Typography,
	Grid,
	Paper,
	TextField,
	Button,
	CircularProgress,
	Collapse,
	Tooltip,
	Select,
	MenuItem,
	Container,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import Breadcrumbs from 'Components/breadcrumbs'
import { ApprovalAddStyles } from './styles'
import { navigate } from 'gatsby'
import AutoComplete from '@material-ui/lab/Autocomplete'
import { requestUserAccountList } from 'States/actions/UsersAccount/listAction'
import { requestADUpdate } from 'States/actions/Approval/Department/ADUpdateAction'
import { requestADList } from 'States/actions/Approval/Department/ADListAction'
import { requestDepartmentList } from 'States/actions/Company/Department/listAction'
import {
	requestADDetail,
	resetAction,
} from 'States/actions/Approval/Department/ADDetailAction'
import { HighlightOffOutlined } from '@material-ui/icons'
import Alert from 'Components/alert'

// import { requestListFilterDepartment } from 'States/actions/listFilterDepartmentAction'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import schema from './schema'
import { filterUserApproval } from '../../../../utils/globalUtils'
import CustomModal from 'Components/modal'

const ApprovalDepartmentEdit = ({ approverID }) => {
	const classes = ApprovalAddStyles()

	const dispatch = useDispatch()
	const listUserReducer = useSelector((state) => state.UsersAccountListReducer)
	const listDepartmentReducer = useSelector(
		(state) => state.departmentListReducer
	)
	const listApprovalReducer = useSelector((state) => state.ADListReducer)
	const detailApprover = useSelector((state) => state.ADDetailReducer)
	const responseData = useSelector((state) => state.ADUpdateReducer)

	const [listUser, setListUser] = useState([])
	const [listDepartment, setListDepartment] = useState([])
	const [errorBackend, setErrorBackend] = useState({})
	const [modalNotif, setModalNotif] = useState(false)
	const [notifError, setNotifError] = useState(false)
	const [notifSuccess, setNotifSuccess] = useState(false)
	const [listLevel, setListLevel] = useState([])
	const [dataSelected, setDataSelected] = useState({
		user: '',
		department: '',
		level: 'none',
		min: 0,
		max: 0,
	})
	const [qsDepartment, setQsDepartment] = useState({ limit: 200, status: 99 })

	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '/dashboard',
		},
		{
			segment: 'Manajemen Persetujuan',
			link: '/dashboard/approval/department',
		},
		{
			segment: 'Ubah Penyetuju Departemen',
			link: '#',
		},
	])

	const queryStringUser = { limit: 100, status: 99, role_id: 7 }

	useEffect(() => {
		dispatch(requestADDetail(approverID))

		let queryString = {
			limit: 200,
			status: 99,
		}
		dispatch(requestUserAccountList({ queryString: queryStringUser }))
		dispatch(requestADList({ queryString: qsDepartment }))
		dispatch(
			requestDepartmentList({
				queryString: { ...queryString, filter: '{"status":"99"}' },
			})
		)
		return () => {
			dispatch(resetAction())
		}
	}, [])

	// hooks for set default state
	useEffect(() => {
		if (listUserReducer.data) {
			let result = listUserReducer.data.items
			if (result) {
				setListUser(filterUserApproval(result))
			}
		} else {
			setListUser([])
		}
	}, [listUserReducer])

	// list department
	useEffect(() => {
		if (listDepartmentReducer.data) {
			let result = listDepartmentReducer.data.items
			if (result) {
				setListDepartment(result)
			}
		} else {
			setListDepartment([])
		}
	}, [listDepartmentReducer])

	// data selected
	useEffect(() => {
		// set selectedData
		if (listUser.length > 0 && detailApprover.data) {
			var userSelected, departmentSelected

			// select user
			let tmpUserSelected = listUser.filter(function (user) {
				return user.id == detailApprover.data.user_id
			})
			userSelected =
				tmpUserSelected && tmpUserSelected.length != 0 ? tmpUserSelected[0] : null

			// select department
			let tmpDeparmentSelected = listDepartment.filter(function (department) {
				return department.id == detailApprover.data.department_id
			})
			departmentSelected =
				tmpDeparmentSelected && tmpDeparmentSelected.length != 0
					? tmpDeparmentSelected[0]
					: null

			if (departmentSelected || userSelected) {
				setDataSelected({
					user: (userSelected && userSelected.name) || '',
					userSelected: userSelected,
					department: (departmentSelected && departmentSelected.name) || '',
					departmentSelected: departmentSelected,
					min: detailApprover.data.min_transaction || 0,
					max: detailApprover.data.max_transaction || 0,
					level: detailApprover.data.level || 'none',
				})
			}
		}
	}, [listUser, detailApprover, listDepartment])

	useEffect(() => {
		if (listLevel.length > 0 && detailApprover.data) {
			setDataSelected({
				...dataSelected,
				level: detailApprover.data.level || 'none',
			})
		}
	}, [listLevel, detailApprover.data])

	// list level
	// list approver
	useEffect(() => {
		// success
		if (listApprovalReducer.data) {
			let result = listApprovalReducer.data.items
			if (result) {
				let arrLevel = []
				for (let i = 0; i < result.length; i++) {
					arrLevel.push(result[i].level)
				}
				if (arrLevel.length > 0) {
					let resultLevel = []
					let maxLevel = Math.max(...arrLevel)
					for (let i = 0; i < maxLevel; i++) {
						resultLevel.push({ level: i + 1 })
					}

					setListLevel(resultLevel)
				}
			}
		}
	}, [listApprovalReducer])

	// get error_backend and parse
	useEffect(() => {
		var tmpErrorBackend
		if (responseData.error) {
			if (responseData.error.http_body) {
				let http_body = responseData.error.http_body
				if (http_body) {
					let body_obj = JSON.parse(http_body)
					tmpErrorBackend = body_obj
					setErrorBackend(body_obj)
				}
			}
		}

		// set notif error
		if (
			(tmpErrorBackend &&
				tmpErrorBackend.error &&
				// containsString(tmpErrorBackend.error.message, ['Error', 'error']) &&
				tmpErrorBackend.error.message) ||
			responseData.isError
		) {
			setNotifError(true)
		}

		// set notif success
		if (
			!responseData.isError &&
			responseData.data &&
			responseData.data.message != ''
		) {
			setNotifSuccess(true)
		}

		// show modal
		if (responseData.data != null) {
			setModalNotif(true)
		}
	}, [responseData])

	useEffect(() => {
		dispatch(requestADUpdate({}, approverID, 'RESET'))
	}, [])

	const handleChangeMinMax = (event) => {
		var value = event.target.value.toString()
		var result

		if (value == '0' || value == 0) {
			return value
		}

		if (parseInt(value)) {
			result = parseInt(value)
		} else {
			result = value.match(/\d+/)
			if (!result) {
				result = ''
			}
		}

		return result
	}

	const handleSubmit = (formdata) => {
		let dataCreate = {}
		dataCreate.user_id = formdata.user.id || formdata.userSelected.id
		dataCreate.department_id =
			formdata.department.id || formdata.departmentSelected.id
		dataCreate.level = formdata.level
		dataCreate.min_transaction = parseInt(formdata.min)
		dataCreate.max_transaction = parseInt(formdata.max)

		dispatch(requestADUpdate(dataCreate, approverID))
	}

	const handleModalOK = () => {
		setModalNotif(false)
		if (notifSuccess) {
			dispatch(requestADUpdate({}, approverID, 'RESET'))
			navigate('/dashboard/approval/department')
		}
	}

	const handleSelectDepartment = (v) => {
		var qs = qsDepartment
		if (typeof v == 'object' && v != null && v.id) {
			qs = { ...qsDepartment, department_id: v.id }
			setQsDepartment(qs)
		} else {
			setQsDepartment(qs)
		}
		dispatch(requestADList({ queryString: qs }))
	}

	const handleInputChangeDepartment = (v) => {
		if (!v || v == null) {
			v = ''
		}
		if (v.length > 2) {
			let queryString = { ...qsDepartment, query: JSON.stringify({ name: v }) }
			dispatch(requestDepartmentList({ queryString: queryString }))
		}
	}

	const handleInputChangeFullname = (value) => {
		if (!value || value == null) {
			value = ''
		}
		if (value.length > 2) {
			let queryString = { ...queryStringUser, keyword: value }
			dispatch(requestUserAccountList({ queryString: queryString }))
		}
	}

	return (
		<Formik
			enableReinitialize
			initialValues={dataSelected}
			validationSchema={schema}
			onSubmit={(values) => handleSubmit(values)} //handleSubmit(values, setSubmitting)
		>
			{({ values, errors, touched, ...others }) => {
				// console.log('values: ', values)
				return (
					<>
						<Form method='post'>
							<DashboardLayout>
								<Container>
									<Box>
										<Breadcrumbs breadcrumbs={breadcrums} />
									</Box>
									<Box mt={2}>
										<Typography variant='h6'>
											Manajemen Persetujuan - Ubah Penyetuju Departemen
										</Typography>
									</Box>
									<Box>
										<Collapse in={notifError}>
											<Alert iconClose={<HighlightOffOutlined />} variant={'alert'}>
												<Typography variant='body1'>
													{(errorBackend.error && errorBackend.error.message) ||
														'Terjadi kesalahan server'}
												</Typography>
											</Alert>
										</Collapse>
										<Collapse in={notifSuccess}>
											<Alert iconClose={<HighlightOffOutlined />} variant={'success'}>
												<Typography variant='body1'>
													{notifSuccess && 'Success Updated.'}
												</Typography>
											</Alert>
										</Collapse>
									</Box>
									<Box mt={2}>
										<Paper>
											<Box p={1}>
												<Grid container spacing={3}>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Nama Lengkap</Typography>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<AutoComplete
																id='user'
																name='user'
																options={listUser}
																getOptionLabel={(option) => option.name}
																onChange={(e, v, r) => {
																	if (r == 'select-option') {
																		others.setFieldValue('user', v.name)
																		others.setFieldValue('userSelected', v)
																	}
																}}
																onInputChange={(e, v, r) => {
																	if (r == 'clear' || r == 'input') {
																		others.setFieldValue('user', v)
																		others.setFieldValue('userSelected', {})
																		handleInputChangeFullname(v)
																	}
																}}
																onClose={(e, r) => {
																	if (r != 'select-option') {
																		others.setFieldValue('user', '')
																		others.setFieldValue('userSelected', {})
																	}
																}}
																inputValue={values.user || ''}
																style={{
																	width: '100%',
																}}
																renderInput={(params) => (
																	<Field name='user'>
																		{({ field }) => (
																			<TextField
																				placeholder='Pilih User'
																				fullWidth
																				margin='dense'
																				variant='outlined'
																				error={errors.user && touched.user}
																				{...params}
																				{...field}
																			/>
																		)}
																	</Field>
																)}
															/>
															<span
																style={{
																	color: 'red',
																}}
															>
																<ErrorMessage name='user' />
															</span>
														</Grid>
													</Grid>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Departemen</Typography>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<AutoComplete
																id='department'
																name='department'
																options={listDepartment}
																getOptionLabel={(option) => option.name}
																onChange={(e, v, r) => {
																	if (r == 'select-option') {
																		handleSelectDepartment(v)
																		others.setFieldValue('department', v.name)
																		others.setFieldValue('departmentSelected', v)
																	}
																}}
																onInputChange={(e, v, r) => {
																	if (r == 'clear' || r == 'input') {
																		handleInputChangeDepartment(v)
																		others.setFieldValue('department', '')
																	}
																}}
																onClose={(e, r) => {
																	if (r != 'select-option') {
																		others.setFieldValue('department', '')
																	}
																}}
																style={{
																	width: '100%',
																}}
																inputValue={values.department || ''}
																renderInput={(params) => (
																	<Field name='department'>
																		{({ field }) => (
																			<TextField
																				placeholder='Pilih Departemen'
																				fullWidth
																				margin='dense'
																				variant='outlined'
																				error={errors.department && touched.department}
																				{...params}
																				{...field}
																			/>
																		)}
																	</Field>
																)}
															/>
															<span
																style={{
																	color: 'red',
																}}
															>
																<ErrorMessage name='department' />
															</span>
														</Grid>
													</Grid>
												</Grid>
												<Grid container spacing={3}>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Username</Typography>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<TextField
																variant='outlined'
																margin='dense'
																placeholder='Username'
																fullWidth
																value={
																	(values.userSelected && values.userSelected.username) || ''
																}
																// inputProps={{
																// 	readOnly: true,
																// }}
																disabled={true}
															/>
															<span
																style={{
																	color: 'red',
																}}
															>
																<ErrorMessage name='username' />
															</span>
														</Grid>
													</Grid>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Level</Typography>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<Field name='level'>
																{({ field }) => (
																	<Select
																		{...field}
																		variant='outlined'
																		placeholder='Pilih Level'
																		error={errors.level && touched.level}
																		value={values.level || ''}
																		onChange={(event) =>
																			others.setFieldValue('level', event.target.value)
																		}
																		style={{
																			width: '100%',
																			color: values.level == 'none' ? '#ada5a5' : 'black',
																		}}
																	>
																		{listLevel.length > 0 &&
																			listLevel.map((row, index) => {
																				return (
																					<MenuItem key={index} value={row.level}>
																						{row.level}
																					</MenuItem>
																				)
																			})}
																	</Select>
																)}
															</Field>
															<span
																style={{
																	color: 'red',
																}}
															>
																<ErrorMessage name='level' />
															</span>
														</Grid>
													</Grid>
												</Grid>
												<Grid container spacing={3}>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Email</Typography>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<TextField
																variant='outlined'
																margin='dense'
																type='email'
																placeholder='Email'
																value={(values.userSelected && values.userSelected.email) || ''}
																fullWidth
																// inputProps={{
																// 	readOnly: true,
																// }}
																disabled={true}
															/>
														</Grid>
													</Grid>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Batas Transaksi</Typography>
															<Tooltip
																title={
																	<React.Fragment>
																		<Typography variant='body1'>
																			Batas Transaksi adalah batas nilai Total dari dokumen agar
																			dapat disetujui Penyetuju Departemen ini
																			<br /> isi <strong>Hingga</strong> dengan angka 0 jika tidak
																			ada batas maksimal transaksi
																		</Typography>
																	</React.Fragment>
																}
																arrow
																placement='top-end'
															>
																<InfoIcon
																	style={{
																		fontSize: 16,
																	}}
																/>
															</Tooltip>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<Grid container spacing={3} justify='space-between'>
																<Grid container item xs={6} alignItems='center'>
																	<Grid container item direction='column'>
																		<Grid item xs={12}>
																			Dari
																		</Grid>
																		<Grid item xs={12}>
																			<Field name='min'>
																				{({ field }) => (
																					<TextField
																						variant='outlined'
																						margin='dense'
																						placeholder='0'
																						fullWidth
																						defaultValue={values.min}
																						{...field}
																						onChange={(event) => {
																							others.setFieldValue('min', handleChangeMinMax(event))
																						}}
																					/>
																				)}
																			</Field>
																			<span
																				style={{
																					color: 'red',
																				}}
																			>
																				<ErrorMessage name='min' />
																			</span>
																		</Grid>
																	</Grid>
																</Grid>
																<Grid container item xs={6} alignItems='center'>
																	<Grid container item direction='column'>
																		<Grid item xs={12}>
																			Hingga
																		</Grid>
																		<Grid item xs={12}>
																			<Field name='max'>
																				{({ field }) => (
																					<TextField
																						{...field}
																						variant='outlined'
																						margin='dense'
																						placeholder='0'
																						fullWidth
																						value={values.max}
																						error={
																							errors.max || errors.validation_min_max ? true : false
																						}
																						onChange={(event) => {
																							others.setFieldValue('max', handleChangeMinMax(event))
																						}}
																					/>
																				)}
																			</Field>
																			<span
																				style={{
																					color: 'red',
																				}}
																			>
																				<ErrorMessage name='max' />
																			</span>
																		</Grid>
																	</Grid>
																</Grid>
															</Grid>
															<span
																style={{
																	color: 'red',
																}}
															>
																{errors.validation_min_max}
															</span>
														</Grid>
													</Grid>
												</Grid>
											</Box>
										</Paper>
										<Box mt={2}>
											<Grid container justify='flex-end'>
												<Grid item>
													<Box>
														<Button
															size='medium'
															className={`${classes.buttonAction} ${classes.btnCancel}`}
															onClick={() => {
																navigate('/dashboard/approval/department')
															}}
														>
															Batal
														</Button>
														<Button
															type='submit'
															size='medium'
															className={`${classes.buttonAction} ${classes.btnSave}`}
															disabled={responseData.isLoading}
														>
															Simpan{' '}
															{responseData.isLoading && (
																<CircularProgress
																	size={14}
																	style={{
																		marginLeft: '5px',
																	}}
																/>
															)}
														</Button>
													</Box>
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Container>
							</DashboardLayout>
						</Form>
						<CustomModal
							icon={notifSuccess ? 'success' : 'error'}
							open={modalNotif}
							onClose={() => setModalNotif(false)}
						>
							<Typography variant='h6'>
								{notifSuccess ? (
									<strong>Ubah Penyetuju Departemen Berhasil</strong>
								) : (
									<strong>Ubah Penyetuju Departemen Gagal</strong>
								)}
							</Typography>
							<Box
								display='flex'
								flexDirection='row'
								mt={3}
								justifyContent='center'
								// width={300}
							>
								<Button
									className={classes.buttonWidth}
									size='small'
									classes={{
										contained: classes.buttonDanger,
									}}
									variant='contained'
									onClick={() => handleModalOK()}
								>
									{' '}
									OK{' '}
								</Button>
							</Box>
						</CustomModal>
					</>
				)
			}}
		</Formik>
	)
}

ApprovalDepartmentEdit.propTypes = {
	approverID: PropTypes.number,
}

export default ApprovalDepartmentEdit
