/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import styles from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { statusText } from '../../../../assets/status'
import Status from 'Components/status'
import {
	requestDetailPo,
	resetAction as resetActionDetailPo,
} from 'States/actions/Transactions/PurchaseOrder/detailAction'
import { requestDetailPOBudget } from 'States/actions/Transactions/PurchaseOrder/detailBudget'
import { resetAction as resetActionBudget } from 'States/actions/Transactions/PurchaseOrder/detailBudget'
import { withStyles } from '@material-ui/core/styles'
import { Info, List } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import API from '../../../../utils/api'
import {
	Box,
	Button,
	Container,
	Paper,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
	CircularProgress,
	IconButton,
	Tooltip,
} from '@material-ui/core'
import ModalConfirm from './modalConfirm'
import ModalDetailDN from './ModalDetailDN'
import Layout from 'Components/layout'
// import Alert from 'Components/alert'
import Breadcrumbs from 'Components/breadcrumbs'
import Image from 'material-ui-image'
import { numeralFormat } from '../../../../utils/formatMoney'
import {
	dateFormatFromOdoo,
	generateAddress,
	getFileExtension,
	convertBytesToString,
} from '../../../../utils/globalUtils'
import { rolePenyetuju, rolePemohon, checkRole } from '../../../../utils/acl'
import ScrollToTop from 'Components/scrollToTop'
import Timeline from 'Components/timeline'
import PrintReport from 'Components/buttonPrintReport'
import {
	getTimeline,
	resetAction,
} from 'States/actions/Transactions/Timeline/timelineAction'
import {
	Download,
	Pdf,
	Docx,
	Excel,
	Doc,
	Jpg,
	Other,
	Png,
} from 'Components/svgs'
import Label from 'Components/label'
import { dataTransactions } from '../../../../utils/bodyDataObject'
import { Check } from '@material-ui/icons'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)
const CustomCellDokumen = withStyles(() => ({
	root: {
		border: 'none',
		height: 20,
		padding: 0,
	},
}))(TableCell)
const BorderedCell = withStyles(() => ({
	root: {
		borderTop: '1px solid #ddd',
	},
}))(TableCell)

const StyledTableCell = withStyles(() => ({
	head: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},
}))(TableCell)

const Component = ({ poNumber, navigate }) => {
	const classes = styles()
	const dispatch = useDispatch()
	const [dnId, setDnId] = useState(0)
	const [dnNumber, setdnNumber] = useState('')
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalDN, setModalDN] = useState(false)
	const [dataDetail, setDataDetail] = useState(null)
	const [breadcrums, setBreadcrums] = useState([])
	const [eprocId, setEprocId] = useState(null)
	const detailPO = useSelector((state) => state.detailPO)
	const user = useSelector((state) => state.auth.data)
	const detailPOBudget = useSelector((state) => state.detailPOBudget)
	const allowedKonfPenerimaanPesanan = checkRole(
		`${rolePenyetuju},${rolePemohon}`,
		false
	)
	// data detail state
	const [dataPO, setDataPO] = useState({ ...dataTransactions })

	const imageStorage = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'

	const timelineData = useSelector((state) => state.timelineReducer)

	useEffect(() => {
		API.get(`/approvers/list/eproc?query={"user_id":"${user.data.id}"}`)
			.then((res) => {
				if (res.data.data.items) {
					setEprocId(res.data.data.items[0].user_id)
				}
			})
			.catch((err) => console.log(err))
		dispatch(requestDetailPo(poNumber))
		return () => {
			dispatch(resetActionDetailPo())
		}
	}, [poNumber])

	useEffect(() => {
		if (
			detailPO.data != null &&
			detailPO.isLoading == false &&
			detailPO.data.purchase_order_no == poNumber
		) {
			let dataTemp = dataPO
			dataTemp.noPO = detailPO.data.purchase_order_no ?? '-'
			dataTemp.noMOU = detailPO.data.sales_agreement_id?.name ?? '-'
			dataTemp.noPR = detailPO.data.purchase_request_no ?? '-'
			dataTemp.noQuotation = detailPO.data.quotation_no ?? '-'
			dataTemp.noRFQ = detailPO.data.rfq_no ?? '-'
			dataTemp.NoRefPO = detailPO.data.client_order_ref ?? '-'
			dataTemp.state = detailPO.data.state
			dataTemp.timeCreate = detailPO.data.po_create_date
			dataTemp.requestor = detailPO.data.requestor_name
			dataTemp.deparRequestor = detailPO.data.requestor_department
			dataTemp.internalNotes = detailPO.data.customer_internal_notes ?? '-'
			dataTemp.shippingAdd = detailPO.data.partner_shipping_id.street ?? '-'
			dataTemp.shippingAddLocation = detailPO.data.partner_shipping_id.region_id.complete_name ?? '-'
			dataTemp.billingAdd = detailPO.data.partner_invoice_id.street ?? '-'
			dataTemp.billingAddLocation = detailPO.data.partner_invoice_id.region_id.complete_name ?? '-'
			dataTemp.expectedDelivDate = detailPO.data.expected_delivery_date
			dataTemp.receiverName = detailPO.data.receiver_name
			dataTemp.receiverPhone = detailPO.data.receiver_phone
			dataTemp.note = detailPO.data.note ?? '-'
			dataTemp.amountUntax = detailPO.data.amount_untaxed ?? 0
			dataTemp.amountTotal = detailPO.data.amount_total ?? 0
			dataTemp.amountGroup = detailPO.data.amount_by_group ?? []
			dataTemp.orderLine = detailPO.data.order_line ?? []
			dataTemp.id = detailPO.data.id
			dataTemp.shippingVendor =
				detailPO.data.shipping_vendor && detailPO.data.shipping_vendor.length != 0
					? detailPO.data.shipping_vendor
					: null
			dataTemp.status = detailPO.data.shipping_status
			dataTemp.media = detailPO.data.media ?? []
			setDataPO(dataTemp)
			detailPO.data.transaction_id && detailPO.data.transaction_id[0]
				? dispatch(getTimeline(detailPO.data.transaction_id[0].id))
				: null
		} else {
			setDataPO({ ...dataTransactions })
			dispatch(resetAction())
		}
	}, [detailPO.data, detailPO.isLoading, poNumber])

	const splitVariant = (str) => {
		const arr = str.match(/\(.+?\)/g)
		let joinVar = '-'
		if (arr) {
			const variant = arr.map((item) => item.slice(1, -1))
			joinVar = variant
		}

		return joinVar
	}

	useEffect(() => {
		if (detailPO.data) {
			handleSetBreadcrums(detailPO.data.purchase_order_no)
			dispatch(requestDetailPOBudget(detailPO.data.id))
		}
		return () => {
			dispatch(resetActionBudget())
		}
	}, [detailPO.data])

	// breadcrums function
	const handleSetBreadcrums = (value) => {
		let tmp_breadcrums = [
			{
				segment: 'Home',
				link: '/dashboard/',
			},
			{
				segment: 'Transaksi',
				link: '#',
			},
			{
				segment: 'Purchase Order',
				link: '../purchase-order',
			},
		]
		setBreadcrums([
			...tmp_breadcrums,
			{
				segment: value,
				link: '#',
			},
		])
	}

	const handleDetail = (
		data,
		poNo,
		dnNumber,
		shippingProvider,
		receivedDate,
		state
	) => {
		setModalDN(!modalDN)
		let newData = data
		newData.poNumber = poNo
		newData.dnNumber = dnNumber
		newData.shippingProvider = shippingProvider
		newData.receivedDate = receivedDate
		newData.state = state
		setDataDetail(newData)
	}

	const handleModalConfirm = (dnId, dnNumber) => {
		setModalConfirm(!modalConfirm)
		setDnId(dnId)
		setdnNumber(dnNumber)
	}

	const buttonDisbaledKonfirmasi = () => {
		return (
			<Tooltip
				placement='bottom'
				title='Aksi ini belum dapat dilakukan'
				classes={{
					tooltip: classes.tooltipContainerShipping,
				}}
				disableHoverListener={false}
			>
				<span>
					<Button
						variant='contained'
						color='secondary'
						size='small'
						disabled
						classes={{ root: classes.buttonShipping }}
					>
						<Typography variant='caption'>Konfirmasi Terima</Typography>
					</Button>
				</span>
			</Tooltip>
		)
	}

	const renderButton = (state, id, dnNumber) => {
		if (state === 'done') {
			if (user.data.companies.is_pr_active) {
				if (
					(allowedKonfPenerimaanPesanan &&
						user.data.contact_id === detailPO.data.requestor_id) ||
					user.data.id === eprocId
				) {
					return (
						<Button
							variant='contained'
							color='secondary'
							size='small'
							onClick={() => handleModalConfirm(id, dnNumber)}
							classes={{ root: classes.buttonShipping }}
						>
							<Typography variant='caption'>Konfirmasi Terima</Typography>
						</Button>
					)
				} else {
					return buttonDisbaledKonfirmasi()
				}
			} else if (
				allowedKonfPenerimaanPesanan &&
				user.data.contact_id === detailPO.data.requestor_id
			) {
				return (
					<Button
						variant='contained'
						color='secondary'
						size='small'
						onClick={() => handleModalConfirm(id, dnNumber)}
						classes={{ root: classes.buttonShipping }}
					>
						<Typography variant='caption'>Konfirmasi Terima</Typography>
					</Button>
				)
			} else {
				return buttonDisbaledKonfirmasi()
			}
		} else {
			{
				return buttonDisbaledKonfirmasi()
			}
		}
	}

	const renderDn = (data) => {
		let picking_ids = data?.picking_ids ?? []
		const filterPicking = picking_ids.filter(
			(item) =>
				item.state === 'assigned' ||
				item.state === 'done' ||
				item.state === 'received'
		)
		return (
			<Box mt={3} mb={3}>
				<Typography variant='h6'>Daftar Surat Jalan</Typography>
				<Paper>
					<Box py={2} px={3}>
						<TableContainer className={classes.containerTable}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<StyledTableCell align='center' width='2%'>
											No
										</StyledTableCell>
										<StyledTableCell align='center' width='13%'>
											No. Surat Jalan
										</StyledTableCell>
										<StyledTableCell align='center' width='17%'>
											No. Surat Jalan Mbiz
										</StyledTableCell>
										<StyledTableCell align='center' width='15%'>
											Status
										</StyledTableCell>
										<StyledTableCell align='center' width='10%'>
											Tanggal Dikirim
										</StyledTableCell>
										<StyledTableCell align='center' width='10%'>
											Tanggal Diterima
										</StyledTableCell>
										<StyledTableCell align='center' width='10%'>
											Penerima
										</StyledTableCell>
										<StyledTableCell align='center' width='23%'>
											Aksi
										</StyledTableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{detailPO.isLoading ? (
										<TableRow key={'skeleton'}>
											<CustomCell>
												<Skeleton variant='text' />
											</CustomCell>
											<CustomCell align='center'>
												<Skeleton variant='text' />
											</CustomCell>
											<CustomCell align='center'>
												<Skeleton variant='text' />
											</CustomCell>
											<CustomCell align='center'>
												<Skeleton variant='text' />
											</CustomCell>
											<CustomCell align='center'>
												<Skeleton variant='text' />
											</CustomCell>
											<CustomCell align='center'>
												<Skeleton variant='text' />
											</CustomCell>
											<CustomCell align='center'>
												<Skeleton variant='text' />
											</CustomCell>
											<CustomCell align='center'>
												<Skeleton variant='text' />
											</CustomCell>
										</TableRow>
									) : (
										filterPicking.length != 0 &&
										filterPicking.map((item, index) => (
											<TableRow key={index}>
												<TableCell align='center'>
													<Typography variant='caption'>{index + 1}</Typography>
												</TableCell>
												<TableCell align='center'>
													<Typography variant='caption'>
														{item.name ? item.name : '-'}
													</Typography>
												</TableCell>
												<TableCell align='center'>
													<Typography variant='caption'>
														{item.mbiz_delivery_no ? item.mbiz_delivery_no : '-'}
													</Typography>
												</TableCell>
												<TableCell align='center'>
													<Typography variant='caption'>{statusText(item.state)}</Typography>
												</TableCell>
												<TableCell align='center'>
													<Typography variant='caption'>
														{item.date_done
															? dateFormatFromOdoo(item.date_done, 'dd MMM yyyy')
															: '-'}
													</Typography>
												</TableCell>
												<TableCell align='center'>
													<Typography variant='caption'>
														{item.received_date
															? dateFormatFromOdoo(item.received_date, 'dd MMM yyyy')
															: '-'}
													</Typography>
												</TableCell>
												<TableCell align='center'>
													{item.receiver_name ? (
														item.receiver_name.length > 12 ? (
															<Tooltip
																placement='bottom'
																title={item.receiver_name}
																classes={{
																	tooltip: classes.tooltipContainerShipping,
																}}
																disableHoverListener={false}
															>
																<Typography variant='caption'>{`${item.receiver_name.slice(
																	0,
																	12
																)}...`}</Typography>
															</Tooltip>
														) : (
															<Typography variant='caption'>{item.receiver_name}</Typography>
														)
													) : (
														<Typography variant='caption'>-</Typography>
													)}
												</TableCell>
												<TableCell align='center'>
													{item.state == 'received' ? (
														<Box
															flexDirection='row'
															display='flex'
															alignItems='center'
															ml='5px'
														>
															<Check classes={{ root: classes.styleReceivedShipping }} />
															<Typography
																classes={{ root: classes.textReceivedShipping }}
																align='center'
															>
																Terkonfirmasi
															</Typography>
															<Button
																onClick={() =>
																	handleDetail(
																		item,
																		detailPO.data.purchase_order_no,
																		item.name,
																		item.shipping_provider_id,
																		item.received_date,
																		item.state
																	)
																}
																style={{
																	marginLeft: 5,
																	height: '30px',
																}}
																variant='contained'
																color='secondary'
																size='small'
															>
																<List fontSize='small' />
															</Button>
														</Box>
													) : (
														<Box flexDirection='row'>
															{renderButton(item.state, item.id, item.name)}
															<Button
																onClick={() =>
																	handleDetail(
																		item,
																		detailPO.data.purchase_order_no,
																		item.name,
																		item.shipping_provider_id,
																		item.received_date,
																		item.state
																	)
																}
																style={{
																	marginLeft: 5,
																	height: '30px',
																}}
																variant='contained'
																color='secondary'
																size='small'
															>
																<List fontSize='small' />
															</Button>
														</Box>
													)}
												</TableCell>
											</TableRow>
										))
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{detailPO.isLoading ? null : filterPicking.length == 0 ? (
							<Box mt={2} display='flex' alignItems='center' justifyContent='center'>
								<Typography variant='caption' className={classes.textDokumen}>
									Belum Ada Surat Jalan
								</Typography>
							</Box>
						) : null}
					</Box>
				</Paper>
			</Box>
		)
	}

	const renderStatus = (status) => {
		if (status == 'done') {
			return 'sale_done'
		}

		return status
	}

	const fileThumbIcon = {
		height: 44,
		width: 28,
	}

	const fileThumbIconOther = {
		height: 32,
		width: 24,
	}

	const listExt = {
		excel: ['xls', 'xlsx'],
		doc: ['doc'],
		docx: ['docx'],
		pdf: ['pdf'],
		jpg: ['jpg', 'jpeg'],
		png: ['png'],
	}

	const getFileThumb = (ext) => {
		if (listExt.excel.includes(ext)) {
			return <Excel style={fileThumbIcon} />
		} else if (listExt.doc.includes(ext)) {
			return <Doc style={fileThumbIcon} />
		} else if (listExt.docx.includes(ext)) {
			return <Docx style={fileThumbIcon} />
		} else if (listExt.pdf.includes(ext)) {
			return <Pdf style={fileThumbIcon} />
		} else if (listExt.jpg.includes(ext)) {
			return <Jpg style={fileThumbIcon} />
		} else if (listExt.png.includes(ext)) {
			return <Png style={fileThumbIcon} />
		} else {
			return <Other style={fileThumbIconOther} />
		}
	}

	const downloadFile = (file, fileName) => {
		window.location.href = `${
			process.env.GATSBY_API_URL + '/' + process.env.GATSBY_API_VERSION
		}/storages/download/${file}/${fileName}`
	}

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>

				{/* top form */}
				<Box mb={3}>
					<Grid style={{ display: 'flex' }}>
						{dataPO.id && (
							<PrintReport ext='pdf' reportType='buyer_purchase_order' reportID={dataPO.id} />
						)}
						<Typography variant='h6'>Rincian Pembelian (Purchase Order)</Typography>
					</Grid>
					<Paper className={classes.formRfqTop}>
						<Box py={2} px={3}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Purchase Order</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.noPO
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. MOU</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															<a
																href={`${window.location.origin}/dashboard/mou/detail/${dataPO.noMOU}`}
																target='blank'
															>
																{dataPO.noMOU}
															</a>
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Purchase Request</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															<a
																href={`${window.location.origin}/dashboard/purchase-request/${dataPO.noPR}`}
																target='blank'
															>
																{dataPO.noPR}
															</a>
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Quotation</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															<a
																href={`${window.location.origin}/dashboard/quotation/${dataPO.noQuotation}`}
																target='blank'
															>
																{dataPO.noQuotation}
															</a>
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. RFQ</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															<a
																href={`${window.location.origin}/dashboard/rfq/${dataPO.noRFQ}`}
																target='blank'
															>
																{dataPO.noRFQ}
															</a>
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Referensi PO</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.NoRefPO
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Tahap</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Box width={120}>
														{detailPO.isLoading ? (
															<Skeleton variant='text' width='80%' />
														) : (
															<Status status={renderStatus(dataPO.state)} />
														)}
													</Box>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Status</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.status
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Waktu PO dibuat</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dateFormatFromOdoo(dataPO.timeCreate, 'dd MMMM yyyy')
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
										<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Pemohon</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.requestor
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Departemen Pemohon</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.deparRequestor
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Untuk Keperluan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.internalNotes
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Ekspektasi Pesanan Diterima</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dateFormatFromOdoo(dataPO.expectedDelivDate, 'dd MMMM yyyy') || '-'
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Pengiriman</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.shippingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.shippingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Nama Penerima</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.receiverName
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Telepon Penerima</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.receiverPhone
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.billingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{detailPO.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPO.billingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Box>
				{/* end of top form */}

				{/* bottom form */}
				<Box>
					<Paper>
						<Box py={2} px={3}>
							{/* table */}
							<Box>
								<Table classes={{ root: classes.tableRoot }}>
									<TableHead>
										<TableRow>
											<TableCell align='center'>No</TableCell>
											<TableCell align='center'>Produk</TableCell>
											<TableCell align='center'>Unit</TableCell>
											<TableCell align='center'>Kuantitas</TableCell>
											<TableCell align='center'>Terkirim</TableCell>
											<TableCell align='center'>Harga / Unit</TableCell>
											<TableCell align='center'>Total Harga</TableCell>
											<TableCell align='center'>Pajak</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{detailPO.isLoading ? (
											<TableRow key={'skeleton'}>
												<CustomCell>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell>
													<Box display='flex' flexDirection='row'>
														<Box overflow='hidden' width={60} height={60}>
															<Skeleton variant='rect' width={60} height={60} />
														</Box>
														<Box
															ml={1}
															display='flex'
															flexDirection='column'
															justifyContent='space-between'
															style={{ width: '100%' }}
														>
															<Box>
																<Typography variant='body2'>
																	<Skeleton variant='text' width={250} />
																</Typography>
																<Typography variant='caption'>
																	<Skeleton variant='text' width={250} />
																</Typography>
															</Box>
															<Typography variant='caption'>
																<Skeleton variant='text' width={250} />
															</Typography>
														</Box>
													</Box>
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
											</TableRow>
										) : (
											dataPO.orderLine.map((item, index) => (
												<TableRow key={item.id}>
													<CustomCell>{index + 1}</CustomCell>
													<CustomCell>
														<Box display='flex' flexDirection='row'>
															<Box>
																<Image
																	src={
																		imageStorage +
																		item.product_id.product_tmpl_id.image_128_filestore
																	}
																	style={{ width: 60, height: 60 }}
																/>
															</Box>
															<Box
																ml={1}
																display='flex'
																flexDirection='column'
																justifyContent='space-between'
															>
																<Box>
																	<Typography variant='body2'>
																		{item.product_id.sku_no}
																	</Typography>
																	<Typography variant='caption'>{item.name}</Typography>
																</Box>
																<Typography variant='caption'>
																	varian: {splitVariant(item.name)}
																</Typography>
																{item.eta_date && (
																	<Label type='success'>
																		<Typography
																			variant='caption'
																			style={{
																				fontWeight: '500',
																			}}
																		>
																			Estimasi diterima pada{' '}
																			{dateFormatFromOdoo(item.eta_date, 'dd MMMM yyyy')}
																		</Typography>
																	</Label>
																)}
															</Box>
														</Box>
													</CustomCell>
													<CustomCell align='center'>{item.product_uom.name}</CustomCell>
													<CustomCell align='center'>{item.product_uom_qty}</CustomCell>
													<CustomCell align='center'>{item.qty_delivered}</CustomCell>
													<CustomCell align='center'>
														{numeralFormat(item.price_unit)}
													</CustomCell>
													<CustomCell align='center'>
														{numeralFormat(item.price_subtotal)}
													</CustomCell>
													<CustomCell
														align='center'
														style={{
															maxWidth: 120,
														}}
													>
														{item.tax_id.map((tax) => `${tax.name} `)}
													</CustomCell>
												</TableRow>
											))
										)}
										{dataPO.shippingVendor && (
											<>
												<TableRow>
													<BorderedCell colSpan={8}>
														<Typography variant='body1'>
															<strong>Rincian Pengiriman</strong>
														</Typography>
													</BorderedCell>
												</TableRow>
												<TableRow>
													<BorderedCell />
													<BorderedCell colSpan={5}>
														<Typography variant='body1'>
															<strong>Ekspedisi</strong>
														</Typography>
													</BorderedCell>
													<BorderedCell align='center'>
														<Typography variant='body1'>
															<strong>Biaya Pengiriman</strong>
														</Typography>
													</BorderedCell>
													<BorderedCell align='center'>
														<Typography variant='body1'>
															<strong>Pajak</strong>
														</Typography>
													</BorderedCell>
												</TableRow>
												{dataPO.shippingVendor.map((item, index) => (
													<TableRow key={index}>
														<CustomCell />
														<CustomCell colSpan={5}>
															<Box display='flex' flexDirection='row'>
																<Box>
																	<Image
																		src={
																			imageStorage +
																			item.product_id.product_tmpl_id.image_128_filestore
																		}
																		style={{ width: 60, height: 60 }}
																	/>
																</Box>
																<Box ml={1} display='flex' alignItems='center'>
																	<Box>
																		<Typography variant='body2'>{item.name}</Typography>
																	</Box>
																</Box>
															</Box>
														</CustomCell>
														<CustomCell align='center'>
															{numeralFormat(item.price_unit)}
														</CustomCell>
														<CustomCell
															align='center'
															style={{
																maxWidth: 120,
															}}
														>
															{item.tax_id.map((tax) => `${tax.name} `)}
														</CustomCell>
													</TableRow>
												))}
											</>
										)}
									</TableBody>
								</Table>
							</Box>
							{/* end of table */}

							{/* total */}
							<Box mt={2}>
								<Box display='flex' flex='1' justifyContent='space-between' mb={2}>
									<Box pr={8}>
										{!detailPOBudget.isLoading &&
											detailPOBudget.data &&
											detailPOBudget.data.name && (
												<Box mb={1.5}>
													<Label type='grey' size='md'>
														<Box
															style={{
																display: 'flex',
																alignItems: 'center',
																flexDirection: 'row',
															}}
														>
															<Typography variant='body2'>
																Transaksi ini menggunakan anggaran{' '}
																<strong>{detailPOBudget.data.name}</strong> sebesar{' '}
																<strong>
																	{numeralFormat(
																		detailPOBudget.data.budget_transaction[0].total
																	)}
																</strong>
																<Tooltip
																	placement='top-end'
																	title='Nilai anggaran diambil dari nominal Subtotal (diluar pajak) saat Quotation diproses oleh Pemohon. Nilai anggaran akan otomatis dikembalikan jika transaksi dibatalkan/ditolak.'
																	arrow
																	classes={{
																		arrow: classes.arrow,
																		tooltip: classes.tooltipContainer,
																	}}
																>
																	<IconButton
																		size='small'
																		classes={{ root: classes.styleIconbutton }}
																	>
																		<Info fontSize='small' className={classes.smallICon} />
																	</IconButton>
																</Tooltip>
															</Typography>
														</Box>
													</Label>
												</Box>
											)}
										<Typography variant='body1'>
											<strong>Catatan Permintaan Khusus</strong>
										</Typography>
										<Typography variant='body1'>
											{detailPO.isLoading ? null : dataPO.note}
										</Typography>
									</Box>
									<Box display='flex' minWidth={300} flexDirection='column'>
										<Box px={3} display='flex' flex='1' justifyContent='space-between'>
											<Typography variant='body1'>Subtotal</Typography>
											<Typography variant='body1'>
												{detailPO.isLoading ? null : numeralFormat(dataPO.amountUntax)}
											</Typography>
										</Box>
										{detailPO.isLoading
											? null
											: dataPO.amountGroup.map((tax, index) => (
													<Box
														key={index}
														px={3}
														display='flex'
														flex='1'
														justifyContent='space-between'
													>
														<Typography variant='body1'>{tax[0]}</Typography>
														<Typography variant='body1'>{numeralFormat(tax[1])}</Typography>
													</Box>
											  ))}
										<Box className={classes.totalPrice}>
											<Typography variant='body1'>
												<strong>TOTAL</strong>
											</Typography>
											<Typography variant='body1'>
												{detailPO.isLoading ? null : numeralFormat(dataPO.amountTotal)}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Paper>
				</Box>
				{/* end of bottom form */}

				{renderDn(detailPO.data)}

				{/* middle form (dokumen)*/}
				<Box mb={3}>
					<Typography variant='h6'>Dokumen</Typography>
					<Paper>
						<Box py={2} px={3}>
							<TableContainer className={classes.containerTable}>
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											<StyledTableCell align='center' width='5%'>
												No
											</StyledTableCell>
											<StyledTableCell align='left' width='55%'>
												Nama Dokumen
											</StyledTableCell>
											<StyledTableCell align='left' width='20%'>
												Tanggal Unggah
											</StyledTableCell>
											<StyledTableCell align='center' width='10%'>
												Ukuran
											</StyledTableCell>
											<StyledTableCell align='center' width='10%'>
												Unduh
											</StyledTableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{detailPO.isLoading ? (
											<TableRow key={'skeleton'}>
												<CustomCell>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell>
													<Box display='flex' flexDirection='row' alignItems='center'>
														<Box
															display='flex'
															flexDirection='column'
															justifyContent='space-between'
															style={{ width: '60%' }}
														>
															<Typography variant='caption'>
																<Skeleton variant='text' />
															</Typography>
														</Box>
													</Box>
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
											</TableRow>
										) : dataPO.media.length != 0 ? (
											dataPO.media.map((item, index) => {
												let file_ext = getFileExtension(item.original_name)
												let ext = file_ext ? file_ext.toLowerCase() : ''
												let nameSlice =
													item.original_name && item.original_name.length >= 80
														? item.original_name.slice(0, 78)
														: item.original_name
												return (
													<TableRow key={item.id}>
														<CustomCellDokumen
															align='center'
															style={{ fontSize: '12px' }}
															width='5%'
														>
															{index + 1}
														</CustomCellDokumen>
														<CustomCellDokumen align='left' width='55%'>
															<Box
																display='flex'
																flexDirection='row'
																justifyContent='flex-start'
																alignItems='center'
															>
																{getFileThumb(ext)}
																<Box
																	ml={1}
																	display='flex'
																	flexDirection='column'
																	justifyContent='center'
																	alignItems='center'
																>
																	<Box>
																		{item.original_name && item.original_name.length >= 80 ? (
																			<Tooltip
																				classes={{ tooltip: classes.tooltip }}
																				placement='right'
																				title={item.original_name}
																				arrow
																			>
																				<Typography variant='caption' style={{ fontSize: '12px' }}>
																					{nameSlice}....{ext}
																				</Typography>
																			</Tooltip>
																		) : (
																			<Typography variant='caption' style={{ fontSize: '12px' }}>
																				{nameSlice}
																			</Typography>
																		)}
																	</Box>
																</Box>
															</Box>
														</CustomCellDokumen>
														<CustomCellDokumen
															style={{ fontSize: '12px', paddingLeft: 17, paddingRight: 5 }}
															align='left'
															width='20%'
															// padding={16}
														>
															{dateFormatFromOdoo(
																item.create_date,
																'dd MMMM yyyy HH:mm',
																true
															)}
														</CustomCellDokumen>
														<CustomCellDokumen
															align='center'
															style={{ fontSize: '12px' }}
															width='10%'
														>
															{convertBytesToString(item.file_size, true)}
														</CustomCellDokumen>
														<CustomCellDokumen align='center' width='10%'>
															<IconButton
																onClick={() => downloadFile(item.file, item.original_name)}
															>
																<Download style={{ width: '16px', height: '16px' }} />
															</IconButton>
														</CustomCellDokumen>
													</TableRow>
												)
											})
										) : null}
									</TableBody>
								</Table>
							</TableContainer>
							{detailPO.isLoading ? null : dataPO.media.length == 0 ? (
								<Box mt={2} display='flex' alignItems='center' justifyContent='center'>
									<Typography variant='caption' className={classes.textDokumen}>
										Tidak ada dokumen yang terlampir
									</Typography>
								</Box>
							) : null}
						</Box>
					</Paper>
				</Box>
				{/* end of middle form (dokumen) */}

				{/* button action wrapper */}
				<Box className={classes.containerButton} mt={2}>
					<Button
						className={classes.buttonWidth}
						variant='contained'
						onClick={() => navigate('/dashboard/purchase-order')}
						size='small'
						color='secondary'
					>
						Kembali
					</Button>
				</Box>
				{/* end of button action wrapper */}

				{/* modal */}
				{!detailPO.isLoading && detailPO.data ? (
					<ModalConfirm
						data={detailPO.data}
						dnid={dnId}
						open={modalConfirm}
						dnNumber={dnNumber}
						onClose={() => handleModalConfirm()}
						idSaleOrder={dataPO.id}
					/>
				) : null}
				<ModalDetailDN
					data={dataDetail}
					open={modalDN}
					onClose={() => setModalDN(!modalDN)}
				/>
				{/* end of modal */}

				{/* Timeline */}
				{timelineData.isLoading ? (
					<Box style={{ textAlign: 'center' }} mt={2}>
						<CircularProgress size={20} />
					</Box>
				) : timelineData.isLoading == false &&
				  detailPO.isLoading == false &&
				  timelineData.data &&
				  timelineData.data.length > 0 &&
				  dataPO.id ? (
					<>
						<Box mt={0}>
							<Typography variant='h6'>Timeline</Typography>
						</Box>
						<Box mt={'10px'}>
							<Paper elevation={2}>
								<Timeline data={timelineData.data} id={dataPO.id} />
							</Paper>
						</Box>
					</>
				) : null}
				{/*End Timeline */}
			</Container>
			<ScrollToTop />
		</Layout>
	)
}

Component.propTypes = {
	poNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	navigate: PropTypes.func,
}

export default React.memo(Component)
