import { makeStyles } from '@material-ui/core/styles'

export const ApprovalStyles = makeStyles((theme) => ({
	buttonSearch: {
		padding: '7px 9px 6px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: theme.shape.borderRadius,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	gridSearch: {
		width: '85%',
	},
	buttonExport: {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
	},
	buttonAction: {
		margin: '0 5px',
		minWidth: 0,
	},
	btnEdit: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnDelete: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	searchField: {
		width: 300,
		'&>div>input': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			padding: '9px 0 8px 18px',
		},
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		padding: '4px 6.7px',

		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	selectContainer: {
		borderRadius: theme.shape.borderRadius,
		paddingRight: '45px !important',
		marginRight: theme.spacing(0.5),
	},
	selectInput: {
		padding: '9px 0 8px 18px !important',
	},
	inputRoot: {
		paddingRight: '0 !important',
	},
	endAdornment: {
		top: 'inherit',
	},
	buttonRed: {
		color: theme.palette.text.secondary,
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
}))
