import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	container: {
		marginTop: '2%',
		marginBottom: '1%',
		color: '#6f757c',
	},
	link: {
		color: '#6f757c',
		display: 'flex',
	},
	icon: {
		marginRight: theme.spacing(0.5),
		width: 20,
		height: 20,
	},
}))

export default styles
