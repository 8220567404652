/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import styles from './styles'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { map, sumBy, trim } from 'lodash'
import { requestRfqItems } from 'States/actions/Transactions/RFQ/submitRfqAction'
import Layout from 'Components/layout'
import { Edit, Error, Warning, Event } from '@material-ui/icons'
import Alert from 'Components/alert'
import { submitRfq } from 'States/actions/Transactions/RFQ/submitRfqAction'
import { requestAddress } from 'States/actions/companyAddressAction'
import { withStyles } from '@material-ui/core/styles'
import { formatMoney, numeralFormat } from '../../utils/formatMoney'
import {
	isAlphabetPuctuationRegex,
	isCommonButton,
	isNumberPuctuationRegex,
} from '../../utils/globalUtils'
import Image from 'material-ui-image'
import CustomModal from 'Components/modal'
import schema from './schema'
import Beadcrums from 'Components/breadcrumbs'
import { Formik, Form, Field } from 'formik'
import ModalAddress from './modalAddress'
import {
	Box,
	Button,
	IconButton,
	Container,
	Paper,
	Typography,
	Grid,
	TextField,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)

const breadcrums = [
	{
		segment: 'Home',
		link: '/',
	},
	{
		segment: 'Keranjang Belanja',
		link: '/cart/',
	},
	{
		segment: 'Form RFQ',
		link: '#',
	},
]

const dataFormRFQ = {
	partnerShippingId: '',
	partnerInvoiceId: '',
	requestorName: '',
	department: '',
	shippingAddress: '',
	billingAddress: '',
	recipient: '',
	recipientPhone: '',
	listCartItem: [],
	shippingStreet: '',
	billingStreet: '',
}

const Component = ({ navigate }) => {
	const classes = styles()

	const [selectedDate, setSelectedDate] = useState(null)
	const [confirmModal, setConfirmModal] = useState(false)
	const [modalAddress, setModalAddress] = useState(false)
	const [modalAddressType, setModalAddressType] = useState('')
	const [noRef, setNoRef] = useState('')
	const [keperluan, setKeperluan] = useState('')
	const dispatch = useDispatch()
	const auth = useSelector((state) => state.auth)
	const userProfile = useSelector((state) => state.userProfileReducer)
	const companyDetail = useSelector(
		(state) => state.auth.data.data.company_details
	)
	const shippingAddress = useSelector(
		(state) => state.companyAddressReducer.shippingAddressReducer
	)
	const billingAddress = useSelector(
		(state) => state.companyAddressReducer.billingAddressReducer
	)
	const listCartItem = useSelector((state) => state.submitRfq)
	const imageStorage = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'

	const [formRFQ, setFormRFQ] = useState(dataFormRFQ)

	// first action
	useEffect(() => {
		dispatch(requestAddress('billing', true))
		dispatch(requestAddress('shipping', true))
		dispatch(requestRfqItems())
	}, [])
	// ===================

	useEffect(() => {
		let dataTemp = formRFQ
		if (!userProfile.isLoading) {
			dataTemp.requestorName = userProfile.data?.name ?? ''
		}
		if (auth) {
			dataTemp.department =
				auth?.data?.data?.user_department?.company_departments?.name ?? ''
		}
		if (!shippingAddress.isLoading) {
			dataTemp.shippingAddress =
				shippingAddress.defaultAddress?.region_id?.complete_name
			dataTemp.shippingStreet = shippingAddress.defaultAddress?.street
			dataTemp.recipient = shippingAddress.defaultAddress
				? shippingAddress.defaultAddress.pic || ''
				: companyDetail.pic || ''
			dataTemp.recipientPhone = shippingAddress.defaultAddress
				? shippingAddress.defaultAddress.phone
				: companyDetail.phone
			dataTemp.partnerShippingId =
				shippingAddress.defaultAddress?.id || companyDetail.id
		}
		if (!billingAddress.isLoading) {
			dataTemp.billingAddress =
				billingAddress.defaultAddress?.region_id?.complete_name
			dataTemp.billingStreet = billingAddress.defaultAddress?.street
			dataTemp.partnerInvoiceId =
				billingAddress.defaultAddress?.id || companyDetail.id
		}
		if (!listCartItem.isLoading) {
			dataTemp.listCartItem = listCartItem.filteredItems.data
		}
		setFormRFQ({ ...formRFQ, dataTemp })
	}, [
		userProfile.isLoading,
		shippingAddress.isLoading,
		billingAddress.isLoading,
		formRFQ.listCartItem,
	])

	useEffect(() => {
		if (modalAddressType == 'shipping') {
			setFormRFQ({
				...formRFQ,
				recipient: shippingAddress.defaultAddress?.pic,
				recipientPhone: shippingAddress.defaultAddress?.phone,
				shippingAddress: shippingAddress.defaultAddress?.region_id?.complete_name,
				shippingStreet: shippingAddress.defaultAddress?.street,
				partnerShippingId: shippingAddress.defaultAddress?.id,
			})
		} else {
			setFormRFQ({
				...formRFQ,
				billingAddress: billingAddress.defaultAddress?.region_id?.complete_name,
				billingStreet: billingAddress.defaultAddress?.street,
				partnerInvoiceId: billingAddress.defaultAddress?.id,
			})
		}
	}, [modalAddress])

	const handleDateChange = (date) => {
		setSelectedDate(date)
	}

	const handleModal = (type) => {
		setModalAddressType(type)
		dispatch(requestAddress(type, false))
		setModalAddress(true)
	}

	const handleSubmitForm = (values) => {
		setConfirmModal(false)
		let data = {}
		data.partner_invoice_id = formRFQ.partnerInvoiceId
		data.partner_shipping_id = formRFQ.partnerShippingId
		data.cart_items_id = map(formRFQ.listCartItem, 'cart_item_id')
		data.client_order_ref = values.noRef
		data.expected_delivery_date = moment(selectedDate).format('YYYY-MM-DD')
		data.receiver_name = trim(values.namaPenerima)
		data.receiver_phone = trim(values.receiverPhone)
		data.customer_internal_notes = values.keperluan
		data.note = values.note
		data.requestor_department = formRFQ.department
		data.requestor_name = formRFQ.requestorName
		dispatch(submitRfq(data))
	}

	const handleValidateForm = async (validateForm, action) => {
		const error = await validateForm()
		const currentErrorKeys = Object.keys(error)
		if (currentErrorKeys.length) {
			currentErrorKeys.map((item) => {
				action.setFieldError(item)
				action.setFieldTouched(item)
			})
		} else {
			setConfirmModal(true)
		}
	}

	const calculateSubtotal = (val) => {
		let subTotal = sumBy(val, (item) => item.qty * item.selling_price)
		return subTotal
	}

	const calculateTaxes = (val) => {
		let taxes = []
		let uniq_tax = []

		val.map((itm) => {
			itm.taxes.map((tax) => {
				taxes.push({
					[tax.name]: (tax.amount * (itm.qty * itm.selling_price)) / 100,
				})
			})
		})

		for (var i in taxes) {
			let prop_name = Object.getOwnPropertyNames(taxes[i])[0]

			if (
				!uniq_tax.some((obj) =>
					Object.prototype.hasOwnProperty.call(obj, prop_name)
				)
			) {
				uniq_tax.push({
					[prop_name]: taxes[i][prop_name],
				})
			} else {
				let counter = uniq_tax
					.map(function (e) {
						let prop_name2 = Object.getOwnPropertyNames(e)[0]
						return prop_name2
					})
					.indexOf(prop_name)

				uniq_tax[counter][prop_name] =
					uniq_tax[counter][prop_name] + taxes[i][prop_name]
			}
		}

		return uniq_tax
	}

	const calculateTotal = (val) => {
		let taxes = calculateTaxes(val)
		let totalTax = null
		if (taxes.length) {
			totalTax = taxes
				.map((itm) => itm[Object.keys(itm)])
				.reduce((acc, val) => acc + val)
		}
		let subTotal = calculateSubtotal(val)
		return totalTax + subTotal
	}

	const renderLabel = (date) => {
		if (date) {
			return moment(date).format('DD MMMM YYYY')
		} else {
			return 'Pilih Tanggal'
		}
	}

	const handleChangeField = (e) => {
		if (e.target.name == 'noRef') {
			setNoRef(e.target.value)
		} else if (e.target.name == 'keperluan') {
			setKeperluan(e.target.value)
		}
	}

	const keyDownReceiverName = (event) => {
		if (!isAlphabetPuctuationRegex(event.key)) {
			event.preventDefault()
		}
	}

	const keyDownReceiverPhone = (event) => {
		if (isCommonButton(event, window)) {
			return
		}
		if (!isNumberPuctuationRegex(event.key)) {
			event.preventDefault()
		}
	}

	const onPasteReceiverName = (event) => {
		let pasteData = event.clipboardData.getData('Text')
		if (!isAlphabetPuctuationRegex(pasteData)) {
			event.preventDefault()
		}
	}
	const onPasteReceiverPhone = (event) => {
		let pasteData = event.clipboardData.getData('Text')
		if (!isNumberPuctuationRegex(pasteData)) {
			event.preventDefault()
		}
	}

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Beadcrums breadcrumbs={breadcrums} />
				</Box>
				<Formik
					enableReinitialize
					initialValues={{
						noRef: noRef,
						noTeleponPemohon: '',
						keperluan: keperluan,
						namaPenerima: formRFQ.recipient,
						receiverPhone: formRFQ.recipientPhone,
						note: '',
					}}
					validationSchema={schema}
					onSubmit={(values) => handleSubmitForm(values)}
				>
					{({
						errors,
						touched,
						values,
						handleChange,
						submitForm,
						validateForm,
						...action
					}) => (
						<>
							<Form method='post'>
								<Box mb={3}>
									<Typography variant='h6' id='label-form-rfq-title'>
										Form RFQ
									</Typography>
									<Paper className={classes.formRfqTop}>
										<Box py={2} px={3}>
											<Box>
												<Grid container>
													<Grid item lg={5}>
														<Box mb={1.5}>
															<Grid container alignItems='center'>
																<Grid item lg={5}>
																	<Typography variant='body2'>
																		<strong>No. Referensi</strong>
																	</Typography>
																</Grid>
																<Grid item lg={7}>
																	<Field
																		as={TextField}
																		placeholder='Masukkan no. referesi PO anda'
																		fullWidth
																		className={classes.textfield}
																		onChange={(e) => {
																			handleChange(e)
																			handleChangeField(e)
																		}}
																		name='noRef'
																		margin='dense'
																		variant='outlined'
																		size='small'
																	/>
																	<Box>
																		{errors.noRef && touched.noRef && (
																			<Typography color='error' variant='caption'>
																				{errors.noRef}
																			</Typography>
																		)}
																	</Box>
																</Grid>
															</Grid>
														</Box>
														<Box mb={3.25}>
															<Grid container alignItems='center'>
																<Grid item lg={5}>
																	<Typography variant='body2'>
																		<strong>Pemohon</strong>
																	</Typography>
																</Grid>
																<Grid item lg={7}>
																	<Typography variant='body2'>
																		{formRFQ.requestorName}
																	</Typography>
																</Grid>
															</Grid>
														</Box>
														<Box mb={1.5}>
															<Grid container alignItems='center'>
																<Grid item lg={5}>
																	<Typography variant='body2'>
																		<strong>Departemen Pemohon</strong>
																	</Typography>
																</Grid>
																<Grid item lg={7}>
																	<Typography variant='body2'>{formRFQ.department}</Typography>
																</Grid>
															</Grid>
														</Box>
														<Box>
															<Grid container alignItems='center'>
																<Grid item lg={5}>
																	<Box className={classes.labelInput}>
																		<Typography variant='body2'>
																			<strong>Untuk Keperluan</strong>
																		</Typography>
																		<Tooltip
																			placement='top-start'
																			title='Isi field ini jika Anda memiliki catatan pembelian untuk disampaikan kepada tim internal'
																			arrow
																			classes={{
																				tooltip: classes.tooltipContainer,
																			}}
																		>
																			<IconButton size='small'>
																				<Error fontSize='small' />
																			</IconButton>
																		</Tooltip>
																	</Box>
																</Grid>
																<Grid item lg={7}>
																	<TextField
																		className={classes.textfield}
																		onChange={(e) => {
																			handleChange(e)
																			handleChangeField(e)
																		}}
																		value={values.keperluan}
																		name='keperluan'
																		margin='dense'
																		variant='outlined'
																		size='small'
																		placeholder='Masukkan keperluan anda'
																		fullWidth
																	/>
																</Grid>
															</Grid>
														</Box>
													</Grid>

													<Grid item lg={7}>
														<Box mb={1.5}>
															<Grid container alignItems='center'>
																<Grid item lg={5}>
																	<Box className={classes.labelInput}>
																		<Typography variant='body2'>
																			<strong>Ekspektasi Pesanan Diterima</strong>
																		</Typography>
																		<Tooltip
																			placement='top-start'
																			title='Isi field ini untuk memberitahukan tanggal ekspektasi pesanan ingin Anda terima. Tanggal ini sebagai informasi untuk tim kami dalam memperkirakan pengiriman dan bukan sebagai masa berlaku pesanan'
																			arrow
																			classes={{
																				tooltip: classes.tooltipContainer,
																			}}
																		>
																			<IconButton size='small'>
																				<Error fontSize='small' />
																			</IconButton>
																		</Tooltip>
																	</Box>
																</Grid>
																<Grid item lg={6}>
																	<KeyboardDatePicker
																		className={classes.datepicker}
																		minDate={new Date()}
																		autoOk
																		disableToolbar
																		variant='inline'
																		format={'YYYY-MM-DD'}
																		margin='dense'
																		inputVariant='outlined'
																		id='date-picker-inline'
																		value={selectedDate}
																		fullWidth
																		labelFunc={renderLabel}
																		onChange={handleDateChange}
																		KeyboardButtonProps={{
																			'aria-label': 'change date',
																		}}
																		keyboardIcon={<Event color='secondary' />}
																	/>
																</Grid>
															</Grid>
														</Box>
														<Box mb={1.5}>
															<Grid container>
																<Grid item lg={5}>
																	<Typography variant='body2'>
																		<strong>Alamat Pengiriman</strong>
																	</Typography>
																</Grid>
																<Grid item lg={6}>
																	<Typography variant='body2'>
																		{formRFQ.shippingStreet}
																	</Typography>
																	<Typography variant='body2' className={classes.addressText}>
																		{formRFQ.shippingAddress}
																	</Typography>
																</Grid>
																<Grid item lg={1}>
																	<Grid container justifyContent='flex-end'>
																		<IconButton
																			size='small'
																			onClick={() => handleModal('shipping')}
																		>
																			<Edit fontSize='small' color='primary' />
																		</IconButton>
																	</Grid>
																</Grid>
															</Grid>
														</Box>
														<Box mb={1.5}>
															<Grid container alignItems='center'>
																<Grid item lg={5}>
																	<Typography variant='body2'>
																		<strong>Nama Penerima</strong>
																	</Typography>
																</Grid>
																<Grid item lg={6}>
																	<TextField
																		fullWidth
																		placeholder='Masukkan Nama Penerima'
																		onChange={handleChange}
																		value={values.namaPenerima}
																		error={errors.namaPenerima && touched.namaPenerima}
																		name='namaPenerima'
																		margin='dense'
																		variant='outlined'
																		size='small'
																		inputProps={{
																			maxLength: 100,
																		}}
																		onKeyDown={(e) => {
																			keyDownReceiverName(e)
																		}}
																		onPaste={(e) => {
																			onPasteReceiverName(e)
																		}}
																	/>
																	<Box>
																		{errors.namaPenerima && touched.namaPenerima && (
																			<Typography color='error' variant='caption' component='span'>
																				<Box display='flex' flexDirection='row' alignItems='center'>
																					<Warning style={{ fontSize: 15, marginRight: 5 }} />
																					{errors.namaPenerima}
																				</Box>
																			</Typography>
																		)}
																	</Box>
																</Grid>
															</Grid>
														</Box>
														<Box mb={1.5}>
															<Grid container alignItems='center'>
																<Grid item lg={5}>
																	<Typography variant='body2'>
																		<strong>No. Telepon Penerima</strong>
																	</Typography>
																</Grid>
																<Grid item lg={6}>
																	<TextField
																		fullWidth
																		placeholder='Masukkan No. Telepon Penerima'
																		onChange={handleChange}
																		value={values.receiverPhone}
																		error={errors.receiverPhone && touched.receiverPhone}
																		name='receiverPhone'
																		margin='dense'
																		variant='outlined'
																		size='small'
																		inputProps={{
																			maxLength: 50,
																		}}
																		onKeyDown={(e) => {
																			keyDownReceiverPhone(e)
																		}}
																		onPaste={(e) => {
																			onPasteReceiverPhone(e)
																		}}
																	/>
																	<Box>
																		{errors.receiverPhone && touched.receiverPhone && (
																			<Typography color='error' variant='caption' component='span'>
																				<Box display='flex' flexDirection='row' alignItems='center'>
																					<Warning style={{ fontSize: 15, marginRight: 5 }} />
																					{errors.receiverPhone}
																				</Box>
																			</Typography>
																		)}
																	</Box>
																</Grid>
															</Grid>
														</Box>
														<Box mb={1.5}>
															<Grid container>
																<Grid item lg={5}>
																	<Typography variant='body2'>
																		<strong>Alamat Tagihan</strong>
																	</Typography>
																</Grid>
																<Grid item lg={6}>
																	<Typography variant='body2'>
																		{formRFQ.billingStreet}
																	</Typography>
																	<Typography variant='body2' className={classes.addressText}>
																		{formRFQ.billingAddress}
																	</Typography>
																</Grid>
																<Grid item lg={1}>
																	<Grid container justifyContent='flex-end'>
																		<IconButton
																			size='small'
																			onClick={() => handleModal('billing')}
																		>
																			<Edit fontSize='small' color='primary' />
																		</IconButton>
																	</Grid>
																</Grid>
															</Grid>
														</Box>
													</Grid>
												</Grid>
											</Box>
										</Box>
									</Paper>
								</Box>

								{/* bottom form */}
								<Box>
									<Paper>
										<Box py={2} px={3}>
											{/* table */}
											<Box>
												<Table
													classes={{
														root: classes.tableRoot,
													}}
												>
													<TableHead>
														<TableRow>
															<TableCell align='center'>No</TableCell>
															<TableCell align='left' style={{ paddingLeft: '84px' }}>
																Produk
															</TableCell>
															<TableCell align='center'>Unit</TableCell>
															<TableCell align='center'>Kuantitas</TableCell>
															<TableCell align='center'>Harga / Unit</TableCell>
															<TableCell align='center'>Total Harga</TableCell>
															<TableCell align='center'>Pajak</TableCell>
														</TableRow>
													</TableHead>

													<TableBody>
														{formRFQ.listCartItem.map((item, index) => (
															<TableRow key={index}>
																<CustomCell>{index + 1}</CustomCell>
																<CustomCell>
																	<Box
																		display='flex'
																		flexDirection='row'
																		alignItems='center'
																		justifyContent='start'
																	>
																		<Box>
																			<Image
																				src={imageStorage + item.image_512_filestore}
																				style={{ width: 60, height: 60 }}
																			/>
																		</Box>
																		<Box
																			ml={1}
																			display='flex'
																			flexDirection='column'
																			justifyContent='space-between'
																		>
																			<Box>
																				<Typography variant='body2'>
																					{item.variant.sku_no}
																				</Typography>
																				<Typography variant='caption'>
																					{item.variant.name}
																				</Typography>
																			</Box>
																			<Typography variant='overline'>
																				varian:{' '}
																				{item.variant.product_template_attribute_value_ids.map(
																					(item) => `${item.name} `
																				)}
																			</Typography>
																		</Box>
																	</Box>
																</CustomCell>
																<CustomCell align='center'>{item.measureType}</CustomCell>
																<CustomCell align='center'>{item.qty}</CustomCell>
																<CustomCell align='center'>
																	{formatMoney(item.selling_price)}
																</CustomCell>
																<CustomCell align='center'>
																	{formatMoney(item.selling_price * item.qty)}
																</CustomCell>
																<CustomCell align='center'>
																	{item.taxes.map((tax) => `${tax.name} `)}
																</CustomCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</Box>
											{/* end of table */}

											{/* total */}
											<Box mt={2}>
												<Box display='flex' flex='1' justifyContent='space-between' mb={2}>
													<Box>
														<Box className={classes.labelInput}>
															<Typography variant='body1'>
																<strong>Catatan Permintaan Khusus</strong>
															</Typography>
															<Tooltip
																placement='top-start'
																title='Isi field ini jika Anda memiliki permintaan khusus/catatan pembelian khusus untuk disampaikan kepada tim Mbiz'
																arrow
																classes={{
																	tooltip: classes.tooltipContainer,
																}}
															>
																<IconButton size='small'>
																	<Error fontSize='small' />
																</IconButton>
															</Tooltip>
														</Box>
														<TextField
															value={values.note}
															onChange={handleChange}
															name='note'
															classes={{
																root: classes.textareaRoot,
															}}
															multiline
															rows={4}
															variant='outlined'
														/>
													</Box>
													<Box display='flex' minWidth={300} flexDirection='column'>
														<Box
															px={3}
															display='flex'
															flex='1'
															justifyContent='space-between'
														>
															<Typography variant='body1'>Subtotal</Typography>
															<Typography variant='body1'>
																{numeralFormat(calculateSubtotal(formRFQ.listCartItem))}
															</Typography>
														</Box>
														{calculateTaxes(formRFQ.listCartItem).map((itm, index) => (
															<Box
																px={3}
																key={index}
																display='flex'
																flex='1'
																justifyContent='space-between'
															>
																<Typography variant='body1'>{Object.keys(itm)}</Typography>
																<Typography variant='body1'>
																	{numeralFormat(itm[Object.keys(itm)])}
																</Typography>
															</Box>
														))}
														<Box className={classes.totalPrice}>
															<Typography variant='body1'>
																<strong>TOTAL</strong>
															</Typography>
															<Typography variant='body1'>
																{numeralFormat(calculateTotal(formRFQ.listCartItem))}
															</Typography>
														</Box>
													</Box>
												</Box>
											</Box>
											{/* end of total */}
											<Alert variant='warning'>
												<Typography variant='caption'>
													*Total harga diatas belum termasuk biaya pengiriman, biaya
													pengiriman akan diinformasikan pada Quotation yang anda terima
													setelah Mbiz mengkonfirmasi permintaan anda
												</Typography>
											</Alert>
										</Box>
									</Paper>
								</Box>
								<Box className={classes.containerButton} mt={2}>
									<Button
										id='btn-submit-rfq'
										className={classes.buttonWidth}
										variant='contained'
										onClick={() => handleValidateForm(validateForm, action)}
										size='small'
										color='secondary'
									>
										Submit RFQ
									</Button>
									<Button
										className={`${classes.buttonWidth} ${classes.kembali}`}
										variant='outlined'
										color='secondary'
										onClick={() => navigate('/cart')}
										size='small'
									>
										{' '}
										Kembali
									</Button>
								</Box>
							</Form>

							<CustomModal
								icon='question'
								open={confirmModal}
								onClose={() => setConfirmModal(false)}
							>
								<Typography variant='h6'>
									<strong>Submit RFQ?</strong>
								</Typography>
								<Box
									display='flex'
									flexDirection='row'
									mt={3}
									justifyContent='space-around'
									width={300}
								>
									<Button
										className={[classes.buttonWidth]}
										size='small'
										classes={{
											contained: classes.buttonDanger,
										}}
										variant='contained'
										onClick={() => setConfirmModal(false)}
									>
										{' '}
										Batal{' '}
									</Button>
									<Button
										className={classes.buttonWidth}
										size='small'
										color='secondary'
										variant='contained'
										type='submit'
										onClick={() => submitForm()}
									>
										{' '}
										Ya{' '}
									</Button>
								</Box>
							</CustomModal>
						</>
					)}
				</Formik>
				<ModalAddress
					open={modalAddress}
					addressType={modalAddressType}
					onClose={() => setModalAddress(false)}
				/>
			</Container>
		</Layout>
	)
}

Component.propTypes = {
	navigate: PropTypes.func,
}

export default React.memo(Component)
