// React import
import React, { useEffect, useState } from 'react'

// External
import {
	Button,
	Box,
	Checkbox,
	Container,
	FormControlLabel,
	Grid,
	IconButton,
	Paper,
	Tooltip,
	Typography,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { navigate } from '@reach/router'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

// Internal
import Breadcrumbs from 'Components/breadcrumbs'
import DashboardLayout from 'Components/dashboardLayout'
import {
	requestBudgetDetail,
	resetAction,
} from 'States/actions/Budget/detailAction'
import HistoryBudget from './pagination'

// Parent and sibling
import { detailBudgetStyle } from './styles'
import { statusText } from '../../../assets/status'

const initDataBudgets = {
	name: '',
	descriptions: '',
	date_start: '',
	date_stop: '',
	auto_non_active: false,
	total: 0,
	total_used: 0,
	status: '',
}

const DetailBudget = ({ budgetID }) => {
	const classes = detailBudgetStyle()
	const dispatch = useDispatch()
	const budgetDetail = useSelector((state) => state.budgetDetailReducer)
	const [dataBudget, setDataBudget] = useState(initDataBudgets)

	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/dashboard',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Pengaturan Pembelian',
			link: '#',
		},
		{
			segment: 'Anggaran',
			link: '/dashboard/purchase-setting/budget',
		},
		{
			segment: 'Rincian Anggaran',
			link: '#',
		},
	]

	const numberOptions = {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}

	const printDepartment = () => {
		if (budgetDetail.data !== null) {
			let dept = budgetDetail.data.budget_departments
			if (dept.length > 0) {
				return dept.map((item) => {
					return (
						<Paper elevation={0} className={classes.successLabel} key={item.id}>
							<Typography className={classes.fontSize14}>
								{item.departments.name}
							</Typography>
						</Paper>
					)
				})
			} else {
				return <Typography className={classes.fontSize14}>-</Typography>
			}
		}
	}

	useEffect(() => {
		setDataBudget({ ...initDataBudgets })
		dispatch(requestBudgetDetail(budgetID))
		return () => {
			dispatch(resetAction())
		}
	}, [])

	useEffect(() => {
		if (budgetDetail.isLoading == false && budgetDetail.data != null) {
			let dataTemp = dataBudget
			dataTemp.name = budgetDetail.data.name
			dataTemp.descriptions = budgetDetail.data.descriptions
			dataTemp.date_start = budgetDetail.data.date_start
			dataTemp.date_stop = budgetDetail.data.date_stop
			dataTemp.auto_non_active = budgetDetail.data.auto_non_active
			dataTemp.total = budgetDetail.data.total
			dataTemp.total_used = budgetDetail.data.total_used
			dataTemp.status = budgetDetail.data.status
			setDataBudget(dataTemp)
		}
	}, [budgetDetail.isLoading, budgetDetail.data])

	return (
		<DashboardLayout>
			<Container>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>

				{/* Headline Section */}
				<Box my={2}>
					<Typography variant='h6'>Rincian Anggaran</Typography>
				</Box>

				{/* Budget Detail Section */}
				<Box my={2}>
					<Paper>
						<Box p={2}>
							{/* Nama Anggaran Section */}
							<Box className={classes.detailBox}>
								<Box className={classes.rowBox}>
									<Typography variant='subtitle2'>Nama Anggaran</Typography>
								</Box>
								<Box>
									{budgetDetail.isLoading ? (
										<Skeleton variant='text' width={300} />
									) : (
										<Typography className={classes.fontSize14}>
											{dataBudget.name}
										</Typography>
									)}
								</Box>
							</Box>

							{/* Deskripsi Anggaran Section */}
							<Box className={classes.detailBox}>
								<Box className={classes.rowBox}>
									<Typography variant='subtitle2'>Deskripsi Anggaran</Typography>
								</Box>
								<Box>
									{budgetDetail.isLoading ? (
										<Skeleton variant='text' width={500} />
									) : (
										<Typography className={classes.fontSize14}>
											{dataBudget.descriptions}
										</Typography>
									)}
								</Box>
							</Box>

							{/* Periode Anggaran Section */}
							<Box className={classes.detailBox}>
								<Box className={classes.rowBox}>
									<Typography variant='subtitle2'>Periode Anggaran</Typography>
								</Box>
								<Box className={classes.columnBox}>
									{budgetDetail.isLoading ? (
										<Skeleton variant='text' width={300} />
									) : (
										<>
											<Typography className={classes.fontSize14}>
												{moment(dataBudget.date_start).format('DD MMMM YYYY')} -{' '}
												{moment(dataBudget.date_stop).format('DD MMMM YYYY')}
											</Typography>
											<FormControlLabel
												disabled
												classes={{ disabled: classes.backgroundTransparent }}
												control={
													<Checkbox checked={dataBudget.auto_non_active} size='small' />
												}
												label={
													<Typography className={classes.fontSize14}>
														Otomatis nonaktifkan status anggaran jika telah melewati tanggal
														berakhir periode anggaran
													</Typography>
												}
											/>
										</>
									)}
								</Box>
							</Box>

							{/* Total Anggaran Section */}
							<Box className={classes.detailBox}>
								<Box className={classes.rowBox}>
									<Box className={classes.tooltipBox}>
										<Typography variant='subtitle2'>Total Anggaran</Typography>
										<Tooltip
											placement='top-start'
											title='Perkiraan total nominal anggaran yang ditentukan untuk pembelian (tidak termasuk pajak) pada setiap transaksinya'
											arrow
											classes={{
												arrow: classes.arrow,
												tooltip: classes.tooltipContainer,
											}}
										>
											<IconButton size='small' className={classes.iconColor}>
												<Info fontSize='small' className={classes.smallICon} />
											</IconButton>
										</Tooltip>
									</Box>
								</Box>
								<Box className={classes.columnBox}>
									{budgetDetail.isLoading ? (
										<Skeleton variant='text' width={200} />
									) : (
										<Typography className={classes.fontSize14}>
											Rp {dataBudget.total.toLocaleString('en-US', numberOptions)}
										</Typography>
									)}
									<Paper elevation={0} className={classes.warningLabel}>
										<Typography className={`${classes.fontSize12} `}>
											Nominal anggaran yang akan dikurangi ketika transaksi telah diproses
											melewati tahap Quotation adalah sejumlah nilai Subtotal (tidak
											termasuk pajak) transaksi tersebut.
										</Typography>
									</Paper>
								</Box>
							</Box>

							{/* Sisa Anggaran Section */}
							<Box className={classes.detailBox}>
								<Box className={classes.rowBox}>
									<Typography variant='subtitle2'>Sisa Anggaran</Typography>
								</Box>
								<Box>
									{budgetDetail.isLoading ? (
										<Skeleton variant='text' width={200} />
									) : (
										<Typography className={classes.fontSize14}>
											Rp{' '}
											{(dataBudget.total - dataBudget.total_used).toLocaleString(
												'en-US',
												numberOptions
											)}
										</Typography>
									)}
								</Box>
							</Box>

							{/* Departmen Section */}
							<Box className={classes.detailBox}>
								<Box className={classes.rowBox}>
									<Box className={classes.tooltipBox}>
										<Typography variant='subtitle2'>Departemen</Typography>
										<Tooltip
											placement='top-start'
											title='Seluruh akun pemohon di bawah departemen yang dipilih akan diwajibkan menggunakan anggaran setiap memproses Quotation menjadi Purchase Request/Purchase Order di Mbiz.co.id'
											arrow
											classes={{
												arrow: classes.arrow,
												tooltip: classes.tooltipContainer,
											}}
										>
											<IconButton size='small' className={classes.iconColor}>
												<Info fontSize='small' className={classes.smallICon} />
											</IconButton>
										</Tooltip>
									</Box>
								</Box>
								{budgetDetail.isLoading ? (
										<Skeleton variant='text' width={200} />
									) : (
										<Box className={classes.departmentList}>{printDepartment()}</Box>
									)}
							</Box>

							{/* Status Section */}
							<Box className={`${classes.detailBox} ${classes.centerBox}`}>
								<Box className={classes.rowBox}>
									<Box className={classes.tooltipBox}>
										<Typography variant='subtitle2'>Status</Typography>
										<Tooltip
											placement='top-start'
											title='Hanya anggaran yang aktif yang akan dapat dipilih untuk digunakan pada transaksi'
											arrow
											classes={{
												arrow: classes.arrow,
												tooltip: classes.tooltipContainer,
											}}
										>
											<IconButton size='small' className={classes.iconColor}>
												<Info fontSize='small' className={classes.smallICon} />
											</IconButton>
										</Tooltip>
									</Box>
								</Box>
								<Box className={classes.rowBox}>
									{budgetDetail.isLoading ? (
										<Skeleton variant='text' />
									) : (
										<Typography className={classes.fontSize14}>
											{statusText(dataBudget.status.toString())}
										</Typography>
									)}
								</Box>
							</Box>
						</Box>
					</Paper>
				</Box>

				{/* History Section */}
				<HistoryBudget budgetID={budgetID} />

				{/* Button Kembali */}
				<Box my={2}>
					<Grid container justify='flex-end'>
						<Grid item>
							<Box>
								<Button
									size='small'
									variant='outlined'
									color='primary'
									onClick={(event) => {
										event.preventDefault()
										navigate('/dashboard/purchase-setting/budget', {
											replace: true,
										})
									}}
								>
									Kembali
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

DetailBudget.propTypes = {
	budgetID: PropTypes.string,
}

export default DetailBudget
