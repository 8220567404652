import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	Modal,
	Fade,
	Paper,
	Backdrop,
	Box,
	Typography,
	Divider,
	TextField,
	Button,
	Tooltip,
	IconButton,
	Grid,
} from '@material-ui/core'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import {
	createPr,
	resetAction,
} from 'States/actions/Transactions/Quotation/createPR'
import PropTypes from 'prop-types'
import styles from './styles'
import { MultiSelect } from '../../../../components/dropdown'
import { Help } from '@material-ui/icons/'
import { dateFormat } from '../../../../utils/globalUtils'
import stylesHTML from './stylesHTML'
import ModalMessage from './modalMessage'

const Component = (props) => {
	const classes = styles()
	const dispatch = useDispatch()
	const data = useSelector((state) => state.PRCreate)
	//untuk field anggaran
	const budgetList = useSelector((state) => state.budgetListValidReducer)
	const [selectedOptions, setSelectedOptions] = useState()
	const [errorAnggaran, setErrorAnggaran] = useState(false)
	//modal messages
	const [successModal, setSuccessModal] = useState(false)
	const [isUpdateBudget, setIsUpdateBudget] = useState(false)

	const submitForm = (values) => {
		let validateAnggaran =
			budgetList && budgetList.data && budgetList.data.items.length != 0
				? selectedOptions
					? false
					: true
				: false
		setErrorAnggaran(validateAnggaran)
		if (!validateAnggaran) {
			budgetList && budgetList.data && budgetList.data.items.length != 0
				? (values.budget_data = {
						budget_id: selectedOptions.id,
						current_total: selectedOptions.total,
						current_used: selectedOptions.total_used,
						requestor_id: props.requestor_id,
				  })
				: null
			dispatch(createPr(props.id, values))
		}
	}

	const handleToggleOption = (selectedOptions) =>
		setSelectedOptions(selectedOptions)

	const handleClearOptions = () => setSelectedOptions()

	const numberOptions = {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}

	const renderOption = (option) => {
		return (
			<React.Fragment>
				{option ? (
					<div>
						<span style={stylesHTML.textTitleOptionSelect}>{option.name}</span>
						<br />
						<span style={stylesHTML.textDescOptionPadding}>
							Periode Anggaran : {dateFormat(option.date_start, 'dd MMMM yyyy')} -
							{dateFormat(option.date_stop, 'dd MMMM yyyy')}
						</span>
						<br />
						<div style={stylesHTML.styleDesc}>
							<span style={stylesHTML.textDescOptionSelect}>Sisa Anggaran : </span>
							<span
								style={
									option.total - option.total_used > 0
										? stylesHTML.textDescOptionSelect
										: stylesHTML.textcolorError
								}
							>
								Rp{' '}
								{(option.total - option.total_used).toLocaleString(
									'en-US',
									numberOptions
								)}
							</span>
						</div>
					</div>
				) : (
					<div></div>
				)}
			</React.Fragment>
		)
	}

	const closeModal = () => {
		setSelectedOptions()
		setErrorAnggaran(false)
		props.onClose()
	}

	useEffect(() => {
		if (data.data || data.isError) {
			setSuccessModal(true)
			setIsUpdateBudget(
				data.isError
					? data.data.http_body.includes('there is update on budget') ||
							data.data.http_body.includes('budget data not found')
					: false
			)
		}
	}, [data.data, data.isError])

	const handleSuccessModal = () => {
		setSuccessModal(false)
		dispatch(resetAction())
		navigate('/dashboard/purchase-request')
		closeModal()
	}

	return (
		<>
			<Modal
				className={classes.modal}
				BackdropComponent={Backdrop}
				open={props.open}
				BackdropProps={{
					timeout: 500,
				}}
				onClose={() => closeModal()}
				onBackdropClick={() => closeModal()}
				{...props}
			>
				<Fade in={props.open} className={classes.modalFade}>
					<Paper>
						<Box
							display='flex'
							flexDirection='column'
							className={classes.modalContent}
						>
							<Typography variant='h6'> Membuat Purchase Request </Typography>
							<Divider className={classes.divider} />
							<Formik
								initialValues={{
									notes: '',
								}}
								onSubmit={(values) => submitForm(values)}
							>
								{({ handleChange, handleSubmit, values }) => (
									<Form method='post' onSubmit={handleSubmit}>
										{budgetList &&
										budgetList.data &&
										budgetList.data.items.length != 0 ? (
											<Box display='flex' flexDirection='column' mt={3}>
												<Box display='flex' flexDirection='row'>
													<Typography className={classes.labelRequired} variant='body1'>
														<strong>Anggaran</strong>
													</Typography>
													<Tooltip
														placement='top-start'
														title={
															<span>
																Anda diwajibkan untuk memilih anggaran sebelum memproses
																Quotation ke tahap berikutnya. Setelah Quotation berhasil
																diproses lebih lanjut,{' '}
																<strong>
																	nilai Anggaran akan dikurangi sesuai nominal Subtotal yang ada
																	di Quotation (diluar Pajak).
																</strong>
																<br /> <br />
																Nilai anggaran akan dikembalikan jika nantinya terdapat
																pembatalan/penolakan transaksi.{' '}
															</span>
														}
														arrow
														classes={{
															arrow: classes.arrow,
															tooltip: classes.tooltipContainer,
														}}
													>
														<IconButton
															size='small'
															classes={{ root: classes.styleIconbutton }}
														>
															<Help fontSize='small' className={classes.smallICon} />
														</IconButton>
													</Tooltip>
												</Box>
												<MultiSelect
													items={budgetList.data.items}
													getOptionLabel={(option) => option.name || ''}
													selectedValues={selectedOptions}
													placeholder='Pilih Anggaran'
													onToggleOption={handleToggleOption}
													onClearOptions={handleClearOptions}
													isMultiple={false}
													isFullWidth={true}
													classStyle={{
														option: classes.option,
														inputRoot: `${classes.inputRootBorder} ${
															errorAnggaran ? classes.borderError : classes.borderInput
														}`,
														endAdornment: classes.endAdornment,
														popupIndicator: classes.selectIcon,
														listbox: classes.listbox,
													}}
													renderOption={renderOption}
												/>
												{selectedOptions ? (
													<div>
														<Typography className={classes.detailAnggaran}>
															Periode Anggaran :{' '}
															{dateFormat(selectedOptions.date_start, 'dd MMMM yyyy')} -
															{dateFormat(selectedOptions.date_stop, 'dd MMMM yyyy')}
														</Typography>

														<Grid container direction='row'>
															<Typography className={classes.detailAnggaran}>
																Sisa Anggaran :
															</Typography>
															<Typography
																className={
																	selectedOptions.total - selectedOptions.total_used > 0
																		? classes.detailAnggaran
																		: `${classes.detailAnggaran} ${classes.errorColorText}`
																}
															>
																Rp{' '}
																{(
																	selectedOptions.total - selectedOptions.total_used
																).toLocaleString('en-US', numberOptions)}
															</Typography>
														</Grid>
													</div>
												) : null}
												{errorAnggaran ? (
													<Grid container direction='row' item>
														<Typography variant='caption' color='error'>
															Anggaran harus dipilih
														</Typography>
													</Grid>
												) : null}
											</Box>
										) : null}

										<Box display='flex' flexDirection='column' mt='12px'>
											<Typography
												className={classes.inputLabelModalConfirm}
												variant='body1'
											>
												<strong>Masukkan Catatan Untuk Manajerial (Opsional) </strong>
											</Typography>
											<TextField
												disabled={data.isLoading}
												name='notes'
												onChange={handleChange}
												value={values.notes}
												fullWidth
												multiline
												rows={3}
												size='small'
												variant='outlined'
												// placeholder='Catatan Pembelian'
											/>
										</Box>

										<Box mt={3} display='flex' justifyContent='end'>
											<Button
												onClick={() => closeModal()}
												variant='contained'
												size='small'
												className={[classes.buttonDanger, classes.kembali].join(' ')}
											>
												Batal
											</Button>
											<Button
												disabled={data.isLoading}
												type='submit'
												variant='contained'
												size='small'
												color='secondary'
											>
												Buat Purchase Request
											</Button>
										</Box>
									</Form>
								)}
							</Formik>
						</Box>
					</Paper>
				</Fade>
			</Modal>
			<ModalMessage
				succesModal={successModal}
				modalStatus={
					data.isError ? (isUpdateBudget ? 'warning' : 'error') : 'success'
				}
				handleSuccessModal={() => handleSuccessModal()}
				onClose={() => {
					setSuccessModal(false)
					dispatch(resetAction())
				}}
				isPR={true}
			/>
		</>
	)
}

Component.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	requestor_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Component
