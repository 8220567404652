import { USERS_ACCOUNT_UPDATE } from '../../types'
import API from '../../../utils/api'

const loading = () => {
	return {
		type: `${USERS_ACCOUNT_UPDATE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${USERS_ACCOUNT_UPDATE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${USERS_ACCOUNT_UPDATE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const reset = (data) => {
	return {
		type: `${USERS_ACCOUNT_UPDATE}_RESET`,
		payload: {
			...data,
		},
	}
}

export const requestUserAccountUpdate = (
	data,
	userID,
	type_dispatch = 'default'
) => {
	return async (dispatch) => {
		if (type_dispatch == 'RESET') {
			dispatch(reset())
		} else {
			dispatch(loading())
			try {
				let response = await API({
					method: 'PUT',
					data: data,
					url: '/users/update/' + userID,
				})
				if (response) {
					if (response.data.error_backend) {
						dispatch(failure(response.data.error_backend))
					} else {
						// if (type_user == 'current_user') {
						// 	let name = response.data.data.name
						// 	let mobile = response.data.data.mobile
						// 	var new_response = auth
						// 	new_response.data.data.name = name
						// 	new_response.data.data.mobile = mobile

						// 	dispatch({
						// 		type: `${LOGIN}_FULFILLED`,
						// 		payload: new_response,
						// 		update: true,
						// 	})
						// }
						dispatch(success(response.data))
					}
				}
			} catch (err) {
				dispatch(failure(err))
			}
		}
	}
}
