import { DEPARTMENT_UPDATE } from '../../../types'
import API from '../../../../utils/api'
import { navigate } from 'gatsby'
// import { newUriAPI } from '../../../../utils/globalUtils'

export const resetAction = () => {
	return {
		type: `${DEPARTMENT_UPDATE}_RESET`,
	}
}

const loading = () => {
	return {
		type: `${DEPARTMENT_UPDATE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${DEPARTMENT_UPDATE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${DEPARTMENT_UPDATE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const loadingData = () => {
	return {
		type: `${DEPARTMENT_UPDATE}_LOAD_DATA`,
	}
}

const successGetData = (data) => {
	return {
		type: `${DEPARTMENT_UPDATE}_DATA`,
		payload: {
			...data,
		},
	}
}

export const clearUpdateDepartment = () => {
	return {
		type: `${DEPARTMENT_UPDATE}_CLEAR`,
	}
}

export const updateDepartment = ({ id, name, status }) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			const response = await API({
				method: 'PUT',
				url: `/companies/departments/update/${id}`,
				data: { name, status },
			})

			if ('error_backend' in response.data) {
				dispatch(failure(response.data.error_backend.http_body))
			} else {
				dispatch(success(response.data))
				navigate('/dashboard/department')
			}
		} catch (err) {
			dispatch(failure(err))
		}
	}
}

export const detailDepartment = (id) => {
	return async (dispatch) => {
		dispatch(loadingData())
		try {
			const response = await API({
				method: 'GET',
				url: `/companies/departments/detail/${id}`,
			})

			dispatch(successGetData(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
