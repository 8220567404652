import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	container: ({ variant }) => ({
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor:
			variant === 'alert'
				? theme.palette.alert.main
				: variant === 'warning'
				? theme.palette.warning.light
				: theme.palette.success.light,
		padding: 8,
		borderRadius: 5,
		boxShadow: `0px 0px 2px ${
			variant === 'alert'
				? theme.palette.alert.main
				: variant === 'warning'
				? theme.palette.warning.light
				: theme.palette.success.light
		}`,
	}),
	paper: () => ({
		position: 'absolute',
		width: 600,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(6),
		borderRadius: theme.shape.borderRadius,
		textAlign: 'center',
		display: 'inline-grid',
		justifyContent: 'center',
	}),
	iconContent: {
		marginBottom: theme.spacing(3),
	},
	iconContent10: {
		marginBottom: 10,
	},
	iconWarning: {
		color: theme.palette.warning.dark,
		fontSize: '150px!important',
	},
}))

export default styles
