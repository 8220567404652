import React from 'react'
import styles from './styles'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { statusText } from '../../assets/status'

const Component = (props) => {
	let variant = props.variant
	let text = props.customStatus ? props.status : statusText(props.status)
	const classes = styles({ variant })
	return (
		<Button className={classes.statusButton} onClick={props.onClick}>
			{text}
		</Button>
	)
}

Component.propTypes = {
	status: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	variant: PropTypes.bool,
	customStatus: PropTypes.bool,
}

Component.displayName = 'Button Status'

export default Component
