/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'
import LayoutComponent from 'Components/layout'
import {
	Container,
	Box,
	Grid,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputBase,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Breadcrumbs from 'Components/breadcrumbs'
import { navigate } from 'gatsby'
import { TermAndConditionsStyle } from './styles'

const TermAndConditions = () => {
	const classes = TermAndConditionsStyle()

	const CustomInput = withStyles((theme) => ({
		root: {
			'label + &': {
				marginTop: theme.spacing(3),
			},
		},
		input: {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'&#34;Segoe UI&#34;',
				'Roboto',
				'&#34;Helvetica Neue&#34;',
				'Arial',
				'sans-serif',
				'&#34;Apple Color Emoji&#34;',
				'&#34;Segoe UI Emoji&#34;',
				'&#34;Segoe UI Symbol&#34;',
			].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
			},
		},
	}))(InputBase)

	const lang = {
		id: '/pages/id/term-and-condition',
		en: '/pages/en/term-and-condition',
	}
	const activeLink = window.location.pathname

	const handleChange = (event) => {
		navigate(event.target.value)
	}

	const [breadcrumbs] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Syarat dan Ketentuan',
			link: '#',
		},
	])

	return (
		<>
			<LayoutComponent>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrumbs} />
					</Box>
					<Box>
						<Grid container justify='space-between'>
							<Grid item xs={6}>
								<Typography variant='h6'>Term and Condition</Typography>
							</Grid>
							<Grid container item justify='flex-end' alignItems='center' xs={3}>
								<FormControl>
									<Select
										id='lang-product-return'
										value={activeLink}
										onChange={handleChange}
										input={<CustomInput fullWidth variant='outlined' color='primary' />}
									>
										<MenuItem value={lang['id']}>Bahasa Indonesia</MenuItem>
										<MenuItem value={lang['en']}>Bahasa Inggris</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box mt={2}>
						<Box mb={1} textAlign='justify'>
							<Typography variant='body1'>
								Welcome to Mbiz.co.id. Thank you for visiting our Website. This page
								displays the Terms and Conditions to use www.Mbiz.co.id ("
								<strong>Website</strong>"). Please take the time to read this Terms and
								Conditions before further accessingour Website. The Terms and Conditions
								and other Terms are applicable and bind all of the Website User, both
								customers andUs. By accessing and having an account at our Website, You
								have agreed and are legally bound to this Termsand Conditions. Any
								updates made to this Terms and Conditions will be presented on the
								Website, and You areobliged to see, read and study such amendments. For
								your convenience, we recommend You to visit this pageand regularly read
								this Terms and Conditions. Any use of the Website after an update is
								displayed online will bedeemed as Your acceptance of and adherence to
								the Terms and Conditions applicable at that time.
							</Typography>
						</Box>

						<Box mt={2}>
							<ol className={classes.contentList}>
								<li>
									<strong>ABOUT US</strong>
									<br />
									<a href='https://www.mbiz.co.id' target='blank'>
										www.mbiz.co.id
									</a>
									, is a Website created, owned and managed by PT. Brilliant Ecommerce
									Berjaya (&#34;<strong>BEB</strong>&#34;).
									<ol>
										<li>
											BEB is an affiliated company of Lippo Group engaging in e-Commerce in
											the form of B2B(Business to Business),which provides, markets and
											promotes goods online, including electroniccatalogues (e-Catalogue)
											to its customers, which is provided through this Website . The
											establishmentof the company was legalized by the Minister of Law and
											Human Rights based on the Decree of theMinister of Law and Human
											Rights No. AHU. 2449494.AH.01.01. 2015 dated 31 July 2015 , with
											itsregistered address at Lippo Kuningan 21st floor, Jalan HR. Rasuna
											Said Blok B-12, Setiabudi,Kuningan, South Jakarta 12940.
										</li>
										<li>
											This Terms and Conditions is Our copyright-protected intellectual
											property. Any use of thisterms and Conditions by any parties,
											including but not limited to the extract or copy of this Terms
											andConditions or the use for other purposes, among others, for
											commercial purposes in offering productswithout our knowledge and
											authorization is not permitted. Violations of Our intellectual
											property rightsare subject to sanctions by prevailing laws.
										</li>
										<li>
											We are fully supported by a number of sellers who have the capability
											and business license tocarry out the work ordered by the Customers
											through the Website.
										</li>
										<li>
											We have access to update any information in relation with the
											products and services inWebsite. Therefore we advise You to review
											and re-check the information of products on the Websitebefore you
											proceed with a transaction.
										</li>
									</ol>
								</li>
								<li>
									<strong>DEFINITION</strong>
									<ol>
										<li>
											<p>In this Terms and Conditions:</p>
											<p>
												{' '}
												&#34;<b>Account</b>&#34; means your registered account in Website
												which you use to perform transactions on theWebsite;
											</p>
											<p>
												{' '}
												&#34;<b>You</b>&#34; means the Website User, both with or without an
												Account
											</p>
											<p>
												{' '}
												&#34;<b>Product Price</b>&#34; means the selling price of a Product
												as stated in the Website
											</p>
											<p>
												{' '}
												&#34;<b>Business Days</b>&#34; means days in 1 (one) calendar week
												where people are working, other than Saturday,Sunday or National
												Holiday;
											</p>
											<p>
												{' '}
												&#34;<b>Product Information</b>&#34; means an accurate image,
												information, descriptions, and/ or any forms of dataconcerning
												Products, including but not limited to price list and quantity,
												supplies, replacement,dismissal, warranty, type and/ or Product
												type;
											</p>
											<p>
												{' '}
												&#34;<b>Total Amount</b>&#34; means the total amount of price listed
												in the Order Confirmation and will be paid byCustomer, which
												includes product price, shipping cost and other related costs
												including any applicabletax that We may impose;
											</p>
											<p>
												{' '}
												&#34;<b>We</b>&#34; means BEB or Mbiz.co.id or the Company;
											</p>
											<p>
												{' '}
												&#34;<b>Miscellaneous Provisions</b>&#34; means terms for Complaint
												Policy and Privacy Policy, which constitutes as unity and integral
												part of the Terms and Conditions;
											</p>
											<p>
												{' '}
												&#34;<b>Order Confirmation</b>&#34; means the notification to
												Customer where BEB acknowledge and agree to PO between Customers and
												BEB;
											</p>
											<p>
												{' '}
												&#34;<b>Service</b>&#34; means information, services and/ or
												functions provided on the Website;
											</p>
											<p>
												{' '}
												&#34;<b>Customer</b>&#34; means any individual with an account and
												place order on the Website;
											</p>
											<p>
												{' '}
												&#34;<b>Penalty</b>&#34; means certain amount of cost charged by BEB
												to the Customer in regards with theviolations of stipulations in
												this Terms and Conditions, including but not limited to the
												violation ofArticle 6.3 (c)(ii) of this Terms and Conditions;
											</p>
											<p>
												{' '}
												&#34;<b>Upfront Payment</b>&#34; means one of the option of payment
												where Customer is required to do paymentsin accordance with Product
												Price within 3 x 24 hours after PO received between Customer and
												BEB.In the case where Customer does not make payment within
												stipulated time, the PO between Customerand BEB will be considered
												cancelled and never happened;
											</p>
											<p>
												{' '}
												&#34;<b>PO between Customer and BEB</b>&#34; means a document or an
												order form of product from Websitesubmitted by Customer to BEB;
											</p>
											<p>
												{' '}
												&#34;<b>Product</b>&#34; means product or service marketed, promoted
												and sold through the Website
											</p>
											<p>
												{' '}
												&#34;<b>Ordered Product</b>&#34; means the product ordered by
												Customer;
											</p>
											<p>
												{' '}
												&#34;<b>Returned Product</b>&#34; means damaged, defect, destroyed,
												broken, expired, incomplete, dysfunction,and/or not in accordance
												with the order stated in the PO between Customer and BEB, as
												described anddefined further in
												<a
													target='blank'
													href={`${window.location.origin}/pages/en/complaint`}
													style={{ color: '#1A9AED' }}
												>
													&nbsp; Complaint policy
												</a>{' '}
											</p>
											<p>
												{' '}
												&#34;<b>System</b>&#34; means (a) system, platform, software,
												program, or Internet network, jointly or separately,and a unity
												managed and used on the Website to support the operation of the
												Website andimplementation of marketing activities, sales, delivery
												and/ or Products payment through the Websiteand the provision of
												other services as provided on the Website, which connects Us and
												Customer, and(b) system, procedure and manual mechanism that is
												mutually agreed in writing by the Parties in termsof system,
												platform, software, program, or internet network as referred to (a)
												above has not operated oris not in operation because of certain
												condition or cause (including but not limited to condition
												ofmalfunction or a maintenance over a system, platform, software,
												program, or internet network asreferred to in (a) above;
											</p>
											<p>
												{' '}
												&#34;<b>Point of Delivery</b>&#34; means place to deliver the
												Ordered Product as specified by customer in PObetween Customer and
												BEB;
											</p>
											<p>
												{' '}
												&#34;<b>Term of Payment</b>&#34; means one of the option of payment
												methods done by the Customer after Wedeliver the Ordered Product to
												the Customer. The Customer is required to make payments to BEB
												inaccordance with the payment due date arrangement agreed upon by
												the Customer and BEB duringOrder Confirmation.
											</p>
											<p>
												{' '}
												&#34;<b>Transaction</b>&#34; means a sale and purchase activity
												between the Customer and Us through the Website.
											</p>
										</li>
										<li>
											{' '}
											References to an "article" or "clause" is an article or a clause in
											this Terms and Conditions;
										</li>
										<li>
											{' '}
											Titles and subtitles in this Terms and Condition are for ease of
											reference only and shall not in any way affect the interpretation or
											construction of this Terms and Conditions. Words with singular
											references shall also be considered plural and vice versa. Words with
											gender references shall include any gender and reference to person or
											party including individuals, companies, corporations, or partnership.
											Reference to "include" or similar words or phrases, means without
											limitation.
										</li>
									</ol>
								</li>
								<li>
									<b>REGISTRATION REQUIREMENTS</b>
									<br />
									To register and become the owner of an Account in the Website, You must
									meet all of the followingregistration requirements cumulatively:
									<ol>
										<li>
											{' '}
											In the form of a business entity that is legally established under
											the laws of the Republic of Indonesiaand holds a Taxable Company
											(PKP) status;
										</li>
										<li>
											{' '}
											open an account through Your representative either designated by the
											articles of association or based ona power of attorney as an
											authorized party to sign all electronic and non-electronic documents
											issuedfor the purpose of Transaction in Our Website, as well as to
											provide relevant information or instructionsrelated to Transaction,
											based on the applicable articles of associations and its amendments
											(if any);
										</li>
										<li> independently manage Your Account;</li>
										<li>
											{' '}
											provide this following documents during registration process:
											<ol>
												<li>
													{' '}
													Deed of establishment and all of its amendments including approval
													or notification receipt from theMinistry of Law and Human Rights;
												</li>
												<li> The identity cards of the owner or board of Directors ;</li>
												<li> Taxpayer Number;</li>
												<li> Taxable Entrepreneur Confirmation Letter;</li>
												<li> Registration Letter;</li>
												<li> Domicile Letter;</li>
												<li> Business License of Trading / Business License;</li>
												<li> Company Registration Certificate / Business Main Number;</li>
												<li>
													Other licenses required for you to run your business based on the
													applicable laws and regulation;and
												</li>
												<li>Your Financial Statements if needed.</li>
											</ol>
										</li>
										<li>
											{' '}
											Fill out and sign Compliance Form that will be provided to you during
											registration.
										</li>
										<li>
											Complete and sign the Non Disclosure Agreement and the Agreement that
											We will provide to you atregistration
										</li>
									</ol>
								</li>
								<li>
									<b>PROHIBITION</b>
									<br /> In using the Website, whether intentional or unintentional, you
									are prohibited to:
									<ol>
										<li> Misuse this website for any purposes against the law</li>
										<li>
											{' '}
											Transmit, disseminate and/ or distribute viruses into or through the
											Website in any form including, butnot limited to, trojan horses,
											worms, logic bombs,
										</li>
										<li>
											{' '}
											send or post dangerous materials or technology of any form into or
											through the Website,
										</li>
										<li>
											{' '}
											commit violation of any rights of any party, including Our
											intellectual property rights or any otherparty,
										</li>
										<li>
											{' '}
											conduct any act to pretend or claiming to be another person or entity
											or a particular party, provide awrong, untrue or false description,
											identity or information
										</li>
										<li>
											{' '}
											in any way, act, write or disseminate messages that are offensive,
											harassing, violating decency orcausing disruption to the public order
										</li>
										<li>
											{' '}
											enter or obtain illegal access and/ or intervene or disrupt computer
											system or network that connect oris connected to the Service and/ or
											the Website,
										</li>
										<li> damage the data in the Website,</li>
										<li> disturb other users,</li>
										<li>
											send advertisement or unsolicited promotional material into Website;
										</li>
										<li>
											attempt to affect the performance or function of any computer
											facilities or access to the entireWebsite; and/ or
										</li>
										<li>
											intervene, intercept and/ or conduct other adverse measures or cause
											any disturbance to the Websiteand/ or server Website and/ or hosting
											of the Website.
										</li>
									</ol>
									Any violation of this Terms and Conditions constitute as a violation of
									laws and/or applicable laws inIndonesia. In the event that the
									violation occurs We shall take legal action in accordance with the
									prevailinglaws.
								</li>
								<li>
									<b>ENTIRE AGREEMENT</b>
									<br />
									<ol>
										<li>
											The Terms and Conditions and Miscellaneous Provisions implemented by
											Us constitute entireagreement between you and Us relating to the
											usage of Website. Any waiver of one or more of theTerms and
											Conditions and/ or Other Conditions shall be effective only if made
											in writing, and signedby an authorized person who has the right and
											authority to act for and on behalf of You and Us.
										</li>
										<li>
											Transactions occurs between the Customer and BEB will be based on the
											agreement between partieswith respect and adhere to the Terms and
											Conditions and other Conditions, unless We make a separatespecific
											agreement with the Customer, in which is a unity and integral part
											with this Terms andConditions.
										</li>
									</ol>
								</li>
								<li>
									<b>TERMS OF TRANSACTIONBETWEEN US AND CUTOME</b>
									<br />
									<ol>
										<li>
											Registration
											<ol>
												<li>
													In order to use this Website, You need to meet all the Registration
													Requirements asstipulated in Article 3 of the Terms and Conditions.
													You are required to provide Us with an accurate,complete, and
													truthful information and data. The arrangements of your personal
													data shallbe regulated in Our Privacy Policy.
												</li>
												<li>
													You are required to notify to Us if there is any changes to the
													information provided tous through the relevant part of the Website.
												</li>
											</ol>
										</li>
										<li>
											Order
											<ol>
												<li>
													Information contained in this Terms and Conditions and
													Miscellaneous Provision as well as detailscontained in this Website
													is not an offer of sale. New transactions shall be deemed to arise
													at thetime when
													<ol>
														<li>
															PO between Customer and BEB has been submitted by Customer to
															BEB;
														</li>
														<li>
															a method of payment provided, namely Advance Payment or Term of
															Payment that have beenagreed in the PO between Customer and BEB;
															AND
														</li>
														<li>
															such matters have been approved by BEB as stated in the Order
															Confirmation.
														</li>
													</ol>
												</li>
												<li>
													<p>
														To place an order, the Customer may choose the desired Product on
														the Website, then proceed to create PO between Customer and BEB on
														the Website.
													</p>
												</li>
												<li>
													<p>
														Customer is given time to submit PO between Customer and BEB for
														all orders made by Customer. In the event that the PO between
														Customer and BEB is not received within the specified time, the
														order for goods will be automatically cancelled.
													</p>
												</li>
												<li>
													<p>
														Customer cannot submit cancellations PO between Customer and BEB.
														If you have any questions regarding the cancellation, Customer can
														contact Us via Customer Service at help@mbiz.co.id or telephone
														number 021 400 01 036.
													</p>
												</li>
											</ol>
										</li>
										<li>
											Price and Payment
											<ol>
												<li>
													All Product Information displayed on the Website are information
													provided by Us. Product pricesthat binds Customer is the price of
													Product displayed on the Website at the time the Customerplace a PO
													between the customer and BEB.
												</li>
												<li>
													Product prices displayed on the Website and attached in the Order
													is inclusive of taxes that shouldbe paid by the Customer in
													accordance with the prevailing laws and regulations. Please note
													thatthe Customer will be charged for shipping and additional fees
													as applicable in which the additional cost is not included in
													Product Price and will be clearly displayed and included in ‘Total
													Amount’of price and fees that the Customer pay for your Order
													("Payment Price").
												</li>
												<li>
													The Customer can choose one of the following payment method for the
													Ordered Product in the PObetween customer and BEB.
													<ol>
														<li>
															<b>Advance Payment</b>
															<br /> The Customer is required to make payments in accordance
															with the Payment Price within 3 x24 hours or 72 hours after the
															receipt PO between Customer and BEB. In the event that
															theCustomer does not make any payment within the stipulated time,
															the PO between Customerand BEB will be deemed to be cancelled.
															Within 2 x 24 hours of Business Day after thepayment of Ordered
															Product is received on our account, We shall provide Payment
															Confirmation to You.
														</li>
														<li>
															<b>Term of Payment</b>
															<p>
																This method is selected in the event that the Customer make
																payments to Us after theCustomer has received the Ordered
																Product. The period of such payments will be based on thePO
																between Customer and BEB, and Order Confirmation. Particularly
																for the Term ofPayment, We at Our discretion, shall have the
																right to refuse PO between customer andBEB.If the Customer had
																chosen any Term of Payment method, the Customer have agreed
																topay a penalty of 3% (three percent) per month of the total
																invoice in the Order Confirmationfor each delay in making
																payments on time at the time agreed in the Order
																Confirmation,based on a pro rate calculation.
															</p>
														</li>
													</ol>
												</li>
												<li>
													Every payment of the Ordered Product will be based on one of two
													payment method provided,.Therefore the Customer declare and state
													that the Customer have sufficient fund to carry outpayment for such
													Order.
												</li>
												<li>
													The right of ownership over the Ordered Product will be transferred
													from Us to the Customerwhen the Ordered Product has been received
													by the Costumer with condition that the Customer isobliged to fully
													pay the Ordered Product to Us. In the case where the Customer do
													not fully paythe Ordered Product on time, the Customer is obliged
													to pay the penalty as stipulated in Article 6.3.3.3 above.
												</li>
												<li>
													For each payment method as stipulated above, the Customer can
													select to execute payment foreach order, namely (i) utilizing the
													service provided by one of our Partner registered on theWebsite; or
													(ii) using cash with the applicable remittance/transfer method or
													(iii) You can alsoconduct the overall or partial payment of Your
													Order price using the promotional voucher issuedofficially by Us
													based on the applicable Terms and Conditions.
												</li>
											</ol>
										</li>
										<li>
											User ID and Password
											<br /> Your User ID and password are confidential codes and You shall
											have the full authority. Any misuse of the password and user ID is
											Your full responsibility. You hereby waive and release Us from all
											charges, claims and/or accusations in any form and by any means
											possible, either from the third parties or from You as a result of
											misuse of User ID and / or password, unless that misuse is caused by
											our mistake or negligence in real.
										</li>
										<li>
											Rejection and Cancellation of Order
											<br /> We will use our best efforts to process every PO between the
											Customer and the BEB as long as theOrder has met all Terms and
											Conditions and Miscellaneous Provisions. However, under
											certaincircumstances, We may not be able to accept or further process
											Your Order even though We have sentthe Order Confirmation in
											accordance with our policies, but We will inform You of such
											occurrences.You hereby state your approval and adherence to Our Order
											rejection policy.
										</li>
										<li>
											Limitation
											<br /> From time to time, We reserve the right to impose limits on
											your order in a Transaction or accumulativeTransaction, within the
											same day, a certain time span and/or a certain period of time. This
											OrderLimitation will be based on Our decisions and considerations.
											The Customer hereby state agree toadhere to Our Limitation of order
											policy.
										</li>
										<li>
											Cancellation of Order
											<br /> The provisions on the cancellation of an Order will be set out
											in&nbsp;
											<a
												href={`${window.location.origin}/pages/en/complaint`}
												className='cBlue'
												target='blank'
											>
												<span style={{ color: '#1a9aed' }}>Complaint Policy</span>
											</a>{' '}
											which is an integral part of this Terms and Conditions.
										</li>
									</ol>
								</li>
								<li>
									<b>DELIVERY</b>
									<br />
									<ol>
										<li>
											We will deliver every Ordered Product upon Our issuance of the Order
											Confirmation.
										</li>
										<li>
											We will deliver the Ordered Product to the Customer to the designated
											Point of Delivery contained inthe PO between Customers and BEB. In
											the meantime, the estimated shipping time can be seen at thetime of
											the Order Confirmation is issued. Please note that when your Customer
											order covers more thanone product category, such Ordered Products can
											be sent to the Customer in a separate package and atseparate times.
										</li>
										<li>
											The shipping cost of the Ordered Product shall be borne by the
											Customer in accordance with thecalculations provided at time of
											Order. The calculation of shipping costs will be adjusted with
											thefollowing elements:
											<ol>
												<li>
													<b>Type :</b> some Products will be delivered by means of
													transportation in accordance with theapplicable delivery
													regulation.
												</li>
												<li>
													<b>Weight / Dimensions:</b> weight imposed on the shipping service
													will be the highest figure amongthe actual weight or dimension
													(having been converted form the unit weight as determined by
													theapplicable standards).
												</li>
												<li>
													<b>Insurance :</b>Products ordered through the Website will be
													automatically insured to ensure that thesafety of shipping is
													covered.
												</li>
												<li>
													<b>Delivery Area :</b> the price of delivery will depend on the
													origin of the Product and the destinationof which such Product will
													be received.
												</li>
												<li>
													<b>Type of delivery service selected :</b> the price and time of
													delivery will be adjusted according to theservice selected by
													Customer.
												</li>
											</ol>
										</li>
										<li>
											We will ensure that Ordered Products arrive on the estimated time
											notified by Us,however, the Customer hereby understands and agrees
											that the estimated delivery time of OrderedProduct shall be adjusted
											in certain situations, types of products purchased, the shipping area
											as well asunforeseen force majeure situation and/or the scheduling of
											Our logistic courier partner. You herebyrelease Us for any loss,
											liability, damage costs or other costs arising from the delay in
											delivery of theOrdered Product, as long as the delay is not caused by
											our mistake or negligence
										</li>
										<li>
											Upon receipt of the Ordered Product, the customer must sign a proof
											of delivery andreceipt of Ordered Product ("Delivery Note"). We would
											expect you to carefully check the OrderedProduct upon receipt, prior
											to signing the Delivery Note.
										</li>
										<li>
											In certain circumstances, we may not be able to successfully deliver
											the OrderedProduct to the Point of Delivery. In this case, we will
											inform the Customer through the contact detailsthe Customer provided
											to Us upon creating the PO between Customer and BEB. In the case
											wherethere are no recipient of the Ordered Product, it will be
											returned to Us. If the Customer request aresend, the Customer agree
											that the Customer will be required to repay the shipping fee.
										</li>
										<li>
											All risks associated with the Ordered Product will be transferred to
											you upon deliveryof the Ordered Product to the address stated in the
											Confirmation Order and received by the Customer.We have no
											responsibility whatsoever for the Ordered Product, including for the
											loss, defect and/ordamage of Ordered Product that the Customer have
											received as a result of the use of the OrderedProduct which is not in
											accordance with the user’s manual. We reserve the right to prohibit
											or restrictYour future orders, if the Customer have breached their
											obligations and the Terms and Conditions,during Your previous Order.
										</li>
										<li>
											If the Customer are not at the Point of Delivery during the delivery
											of the OrderedProduct, We and/or other parties appointed by Us can
											leave a card with instructions regarding the re-delivery or
											collection of the Ordered Product from Our courier and/or third party
											sender.
										</li>
									</ol>
								</li>
								<li>
									<b>RETURNED PRODUCT </b>
									<br /> The provisions of the Returned Product will be set out in{' '}
									<a href='https://www.mbiz.co.id' target='blank'>
										www.mbiz.co.id
									</a>{' '}
									on
									<a
										target='blank'
										href={`${window.location.origin}/pages/en/complaint`}
										className='cBlue'
									>
										<span style={{ color: '#1a9aed' }}>Complaint Policy</span>
									</a>
									which forms an integral part of this Terms and Conditions.
								</li>
								<li>
									<b>PRODUCT REFUND POLICY AND REFUND</b>
									<br /> The provisions concerning product returns and refund will be set
									in
									<a
										href={`${window.location.origin}/pages/en/complaint`}
										className='cBlue'
									>
										<span style={{ color: '#1a9aed' }}>Complaint Policy</span>
									</a>
									which is an integral part of these Terms and Conditions.
								</li>
								<li>
									<b> VOUCHER </b>
									<ol>
										<li>
											The Customer may use the voucher (either a gift voucher or promotion
											voucher),officially issued or distributed by Us, at the time of
											payment for the Product ordered on the Website bythe Customer.
										</li>
										<li>
											To redeem the voucher in the Customer’s account, the Customer can
											visit the "UserAccount" on the Website. We are not responsible for
											any mistakes on the voucher recipient's email address.
										</li>
										<li>
											If the Customer has a voucher that is officially issued or
											distributed by Us, thevoucher can be transferred to another party who
											also have an Account on the Website.
										</li>
										<li>
											In case of any fraud attempt or any suspicions on illegal activities
											related to theredemption of the voucher on the Website, We reserve
											the right to close the Customer’s Account at anytime and/or to
											request the Customer to use a different payment method.
										</li>
										<li>
											We are not responsible for any loss, theft or invalid voucher.
										</li>
										<li>
											For various reasons, including to stop the misuse of voucher and
											insofar as it is not incontrary to applicable law and regulations, We
											may at any time terminate the use of the voucher. Weare not
											responsible for any loss which arises from the discontinuation of the
											use of the voucher. Eachone (1) voucher that we issue can only be
											used once. We may cancel the use of the voucher if there issubsequent
											uses of one voucher.
										</li>
										<li>
											Conditionstoredeempromotionalvouchers:From time to time we may be
											able to distribute promotional vouchers that can be used only on
											OurWebsite, in which we will send to the Customer either by email or
											post:
											<ol>
												<li>
													{' '}
													promotional voucher is only valid for a certain period of time as
													indicated in the voucher, it canonly be redeemed or used once and
													can not be used in conjunction with other promotional voucheror
													discount products. We may exclude the use of promotional voucher on
													specific brands inaccordance with Our sales strategy
												</li>
												<li>
													{' '}
													If the Customer place an order for a Product with a value of less
													than the promotional voucher, norefund or remaining credit will be
													returned to the Customer.
												</li>
												<li>
													Promotional voucher credit do not generate interest and have no
													cash value;
												</li>
												<li>
													if a promotional voucher credit is not sufficient for the
													Customer’s order, the Customer can use oneof the acceptable payment
													methods to cover the remaining;
												</li>
												<li> Voucher will reduce the purchase price before taxes.</li>
											</ol>
										</li>
										<li>
											We shall not be responsible for any effect that occurs to the
											promotional voucherduring and after the transaction, including in the
											event of physical damage to the voucher, incompleteparts, decrease in
											the value of Product and any legal consequences that occur due to the
											transaction.
										</li>
									</ol>
								</li>
								<li>
									<b>RESPONSIBILITIES</b>
									<br />
									<ol>
										<li>
											We are not liable for any costs, loss or damage of any kind,
											including but not limitedto
											<ol>
												<li>any direct, indirect, special and/or consequential damages,</li>
												<li> damages for loss of use, profits, data or any other form,</li>
												<li> defamation or damage of reputation, or</li>
												<li>
													the cost of procurement of substitute products, each arise from or
													related to the use,inability to use, performance or failures of the
													Website and/or any content, content, materials, data,descriptions,
													images and information displayed on the Website or placed or
													contained in theWebsite.
												</li>
											</ol>{' '}
											As long as the costs, loss, or damage are not caused by Our mistake
											and/or negligence that can beproven significantly. We hereby state
											that we make no statement and/or warranty that the Website isfree of
											viruses and/or other malicious materials on the Website which can
											lead to the damage of themedia, network, systems and/or equipment
											that You use to access this Website. You hereby agree andcommit to
											free and release Us from any claims, demands and/or accusation from
											any loss that Youexperience in relation with that matter
										</li>
										<li>
											We are not responsible to You or to any other party for any loss
											suffered by Youand/or any party which are caused by failures, delays
											and/or interruption of service. In regards with theaforementioned
											matter, Customer agrees that Customer is unable to file a claim, a
											charge and/or anaccusation of any form to us for any loss or damage
											arise.
										</li>
										<li>
											You release Us from all lawsuits and / or liability in the form of
											compensation or other forms in casethere is a direct violation of the
											law committed by both you and the seller, including but not limited
											tocriminal acts of corruption, tender conspiracy, fraud, forgery and
											others - other.
										</li>
										<li>
											You hereby state and agree that the use of the Website by You is at
											your own riskbased on Your own initiative without any force from Us.
											This Terms and Conditions and theMiscellaneous Provisions shall not
											be considered, construed or interpreted as a guarantee by Us
											<ol>
												<li>
													over the compatibility of the Website with media, networks, systems
													and/or equipment that Youuse to access his Website,
												</li>
												<li>
													that this Website is free from interruptions, viruses and/or bugs,
												</li>
												<li>that the Website is without flaws</li>
												<li>
													that the results of the process of Order through the Website is
													always accurate and reliable, and
												</li>
												<li>
													Any shortcoming of the Website, whether conveyed by You or that We
													find out will be fixed by Us.
												</li>
											</ol>
										</li>
									</ol>
								</li>
								<li>
									<b>COMPENSATIONS</b>
									<br /> You are obliged and agree to remedy all the loss suffered by Us,
									as well as to defend, free and release Usand Our directors, officers,
									employees, consultants, agents, and Our affiliations from any and all
									<ol>
										<li>
											claims, reprimands, demands and/or claims of any party in any form,
										</li>
										<li>
											liabilities, damages, expenses and/or costs (including but not
											limited to, legal fees) incurred orexperienced
										</li>
										in connection with or as a result of the use of this Website by You or
										Your violation of this Terms andConditions and Other Terms and
										applicable laws and regulations in relation to the usage of this
										Website.
									</ol>
								</li>
								<li>
									<b>MANAGEMENT COMPLAINT</b>
									<br />
									<ol>
										<li>
											We will carry out Our obligations in accordance with this Terms and
											Conditions andthe Miscellaneous Provisions.
										</li>
										<li>
											We place a great deal on Your satisfaction. You may contact Us at any
											time at theaddress as stated on the Website. We will ensure that we
											provide solution to your complaintsconcerning the use of the Website
											as soon as possible and will contact You to examine suchcomplaints.
										</li>
										<li>
											You understand and aware that Your mail may be filtered by Our spam
											filters orundelivered to Us, or Our correspondence to You may not
											reach or received by You. If you do notreceive a response from Us
											within 3 (three) Business Days following Your complaint email, you
											mayreach Us again.
										</li>
									</ol>
								</li>
								<li>
									<b>PERSONAL DATA PROTECTION</b>
									<p>
										{' '}
										Please refer to
										<a
											target='blank'
											href={`${window.location.origin}/pages/en/privacy-policy`}
											className='cBlue'
										>
											<span style={{ color: '#1a9aed' }}>Our Privacy Policy</span>
										</a>
										, which constitutes an integral part of this Terms and Conditions.
									</p>
								</li>
								<li>
									<b>FORCE MAJEURE</b>
									<br />
									<ol>
										<li>
											We are not responsible to You for any breach, disruptions or delays
											in the executionof the Transaction or this Terms and Conditions
											caused by force majeure or any circumstances orconditions beyond our
											control, including but not limited to:
											<ol>
												<li> Strike, lock-out or other industrial actions;</li>
												<li>
													Civil commotion, riot, invasion, terrorist attack or threat of
													terrorist attack, war (whetherdeclared or not) or threat or
													preparation for war;
												</li>
												<li>
													Fire, explosion, storm, flood, earthquake, landslides, epidemic of
													disease or other naturaldisasters.
												</li>
												<li>
													Impossibility for the use of trains, cargo airplane, motorized
													transport or other means oftransport, both public and private;
												</li>
												<li>
													Impossibility of the use of public or private telecommunications
													networks;
												</li>
												<li>
													Actions, decisions, laws, regulations or government restrictions,
													shipping, postal or othertransportation-related strikes, failure or
													accident;
												</li>
												<li>
													Damage to the network or computer system or Internet system on Our
													Website.
												</li>
											</ol>
										</li>
										<li>
											In the case where such events referred to in Article 16.1 above last
											for more than aweek, either the Customer or Us may immediately
											terminate the Transaction by providing a writtennotice and We have no
											obligation other than to deliver the Product that the Customer have
											ordered andand products in which the Customer have paid but has not
											been delivered by Us.
										</li>
										<li>
											If we have given the transaction to provide Products that are
											identical or similar tomore than one Customer, yet We can not meet
											our obligations to the Customer due to the occurrence ofone or more
											events referred in Article 16.1 above, we reserve the right to decide
											which Transactionsremain to be valid and which Transactions to be
											terminated.
										</li>
										<li>
											We have the absolute discretion to determine which solution We will
											undertake inorder to fulfil Our obligations based on the Transaction
											in case the events referred to in Article 16.1above occurs.
										</li>
									</ol>
								</li>
								<li>
									<b>NOTIFICATION</b>
									<br />
									<ol>
										<li>
											Any notice to the Customer with respect to the Transaction will be
											provided in writing, either by letteror email to the parties
											concerned at the latest identified address or e-mail.
										</li>
										<li>
											The notification sent via post must be deemed to have been received
											by the person concerned withintwo (2) Business Days from the date of
											delivery, if the delivery to the recipient's address in Indonesia.
										</li>
										<li>
											A notice delivered via email shall be deemed received by the person
											concerned within two (2) calendardays from the date the email was
											sent.
										</li>
										<li>
											In proving the delivery notification, written or electronic evidence
											are sufficient to prove that the letteror email was sent.
										</li>
									</ol>
								</li>
								<li>
									<b>INTELLECTUAL PROPERTY RIGHTS</b>
									<ol>
										<li>
											All substance, content, materials, data, descriptions, images and
											information displayed on theWebsite or placed or contained in the
											Website, including but not limited to Our text, graphics,
											logos,button icons, images, audio clips, digital downloads, campaign
											title and/or compilation of data, and theadvertisement (but not
											including Product Information as well as brands, logos, pictures,
											symbols and/orother visual forms related to the Product) belong to Us
											or We have acquired the rights from its rights orlicense holders
											which is protected by the applicable laws and regulations. You are
											not permitted topublish, manipulate, distribute, copy, disseminate
											and/or reproduce in whatsoever ways or formats, oneor more substance,
											content, materials, data, descriptions, images and information
											displayed on theWebsite (including Product Information) or the copy
											for any purpose including but not limited to Yourbusiness needs as
											well as in the framework of cooperation with any other party.
										</li>
										<li>
											Each graphics, logos, page titles, icons buttons, text, or service
											names included in or availablethrough the Website (but not including
											Product Information as well as brands, logos, pictures, symbolsand/or
											other visual forms associated with the product) is Our trademark or
											license holder, protected bythe Copyright Law in Indonesia and apply
											in other jurisdictions.{' '}
											<a href='https://www.mbiz.co.id' target='blank'>
												Mbiz.co.id
											</a>{' '}
											trademark can not be inany way and under any circumstances be used in
											relation to goods, services, products and/or otherthings that are not
											available on the Website, including in any way or is likely to cause
											confusion amongthe public as well as Customers or in any manner that
											undermine or discredits Us or the Website. Allother trademarks or
											other intellectual property rights that are not owned by Us that
											appear on theWebsite are the property of their respective owners.
										</li>
										<li>
											You may not use any part of Our Website or any or all of the
											substances, contents, materials,data, descriptions, images and
											information displayed on the Website or placed or contained on
											OurWebsite for any purpose whatsoever, including but not limited to
											commercial purposes withoutobtaining prior direct and official
											written license from Us or the concerned license holder. In the
											casewhere we are aware of any violation of intellectual property
											rights, we may take legal action inaccordance with the applicable
											laws and regulations.
										</li>
										<li>
											If you print, copy or download any part of the Website but infringe
											one or more clauses of thisTerms and Conditions, your right to use
											the Website will be immediately terminated without priornotice and
											without the necessary consent from You and you are obliged to,
											according to Our decisionor policy, return or destroy any copies of
											substance, content, materials, data, descriptions, images
											andinformation displayed on the Website or placed or contained within
											our Website that you have made or accessed or owned. You are
											prohibited in any way to modify, translate, decipher, disassemble or
											createderivative works based upon the software or any accompanying
											documentation provided by Us or bythe license holder concerned.
										</li>
									</ol>
								</li>
								<li>
									<b>ON SITE ADVERTISEMENT </b>
									<br /> We will do Our best efforts to comply with any laws and
									regulations relating to the management of theWebsite. However, there
									are conditions in which the advertisers on the Website act beyond Our
									control andability, which may violate the prevailing laws. In such
									circumstances, the responsibility for this violation isthe sole
									responsibility of the advertisers in the Website and You agree to waive
									and release Us from suchresponsibility.
								</li>
								<li>
									<b>LINKS TO THE WEBSITE</b>
									<ol>
										<li>
											You are prohibited from creating a link in such a way that show an
											association,approval or endorsement from Us without Our Consent.
										</li>
										<li>
											You are not allowed to create a link from any website that is not
											owned by You.
										</li>
										<li>
											The Website must not be framed on any other website, and You are
											restricted tocreate a link to your website in Website page, and you
											are not allowed to create a link to any part ofthis Website other
											than the front page. We reserve the right to withdraw the link permit
											without anotification to you and without Your consent.
										</li>
										<li>
											We are not responsible for the content and link content outside the
											Website orwebsites provided or posted by third parties, either
											entered by an anonymous customer or partner of{' '}
											<a href='https://www.mbiz.co.id' target='blank'>
												Mbiz.co.id
											</a>{' '}
											or by any party. With the display of links, sites or third-party
											content in the Website, it cannot be interpreted or considered or
											assumed that We or affiliated parties, officials, officers
											oremployees have a cooperation with such third party.
										</li>
									</ol>
								</li>
								<li>
									<b>ANTI-BRIBERY AND ANTI-CORRUPTION</b>
									<br />
									<ol>
										<li>
											You represents and warrants that the You HAVE known, read, and
											understoodcompletely the prevailing law and regulations in Indonesia
											relating to anti-bribery and anti-corruption, including the Penal
											Code, Bribery Crime Act (Act No. 11 of 1980), CorruptionErradicaton
											Act (Act No. 31 of 1999 jo. Act No. 20 of 2001), along with their
											ammendments,addendums, or implementing provisions (“
											<strong>Regulations on Anti-Bribery and Anti-Corruption</strong>”)
											inevery business transactions and activities conducted in relation to
											Us, and will not commit any actionthat would or might violate the
											Regulations on Anti-Bribery and Anti-Corruption. Therefore, Youhereby
											agrees that:
											<ol>
												<li>
													You shall not employ and maintain any individual who is a
													government official or employee,including employees from
													corporations, agents, or bodies owned or controlled by
													thegovernment, with exceptions to the case where the official
													maintained is working as a consultant– and if necessary – that the
													official maintained has obtained an approval by the work
													authorityor employer competent in the related consulting services.
												</li>
												<li>
													You shall not, directly nor indirectly, pay for, offer, promise,
													allow to pay for, or divert anyamount of money or any other
													valuables, including gifts, political donations,
													facilitationpayments, or charity, to any party, including private
													or government official or employee, or topolitical parties or
													candidates in exchange for a political office, with the aims of
													influencingdecision-making in favor of Your or Our that is against
													the Regulations on Anti-Bribery andAnti-Corruption
												</li>
												<li>
													You shall fulfill and ensure that each leaders, owners,
													shareholders, officials, directors,employees, and agents comply
													with all prevailing Regulations on Anti-Bribery and Anti-Corruption
													in every business transactions and activities conducted in
													connection to Us.
												</li>
												<li>
													You will always take reasonable measures to ensure that every
													individual and bodies employedor associated with the You in
													connection with Our business shall not violate this provision
													northe Regulations on Anti-Bribery and Anti-Corruption.
												</li>
												<li>
													You shall write to Us in the case if any individual employed or
													associated with the You emergesto be a government official,
													political official or candidate, with exceptions to the case where
													theofficial maintained is working as a consultant – and if
													necessary – that the official maintainedhas obtained an approval by
													the work authority or employer competent in the related
													consultingservices.
												</li>
												<li>
													You shall keep correct and accurate record necessary to demonstrate
													compliance to thisAgreement and shall provide Us with a written
													ceritification of compliance based on request.
												</li>
												<li>
													As long as necessary in the event of a dispute related to
													compliance with Anti-Bribery and Anti-Corruption Regulations
													involving Customers in connection with this cooperation. We have
													theright to request all relevant customer information,
													correspondence, documentation, books andrecords for the purpose of
													assessing compliance with such regulation.
												</li>
											</ol>
										</li>
										<li>
											You are required to write immediately to Us in the case where our
											employee is foundto violate or reasonably suspected of violating one
											or several of these provisions, including theRegulations on
											Anti-Bribery and Anti-Corruption, in carrying out his/her duties with
											You.
										</li>
										<li>
											You shall waive, release, and indemnify Us from any form of Wive andi
											release aswell as indemnify Us from any form of legal claim and/or
											compensation arising from other partiesrelated to Your violation
											and/or negligence or several of these provision and the Regulation on
											Anti-Bribery and Anti-Corruption, including Your negligence in
											carrying out duties according to Article 2.Any form of
											representation, action, and promises made or conducted by You towards
											a third partythat violates these provision and the Regulation on
											Anti-Bribery and Anti-Corruption is arepresentation, action, and
											promises made and liable to Your own accord, and does not represent
											Us.
										</li>
									</ol>
								</li>
								<li>
									<b>WAIVER</b>
									<br /> The Website Content and all information in it are provided “as
									it is” and “as provided” without warranty ofany kind, either express or
									implied, and You agree to use this Website and its content as it is at
									your ownrisk. We make no representations, warranties, nor guarantees in
									any form, either express or implied, orotherwise, written or verbal,
									direct or indirect, including but not limited to, any warranties,
									guarantee, orwarranties or merchantability, fitness for a particular
									purpose or a purpose, of a non-infringement, the accuracy and
									completeness of answer, result, or omissions of the virus, in relation
									to the Website andthe Website’s content, or other things in relation to
									the Website.
								</li>
								<li>
									<b>SEPARATION</b>
									<br /> If any clause in this Terms and Conditions becomes or is
									declared invalid, illegal or unenforceable underthe applicable law or
									by the order of an authorized court, it will not affect the provision,
									article, or anyother part of this Terms and Conditions, and all will
									remain valid and legally binding.
								</li>
								<li>
									<b>RELATIONSHIP</b>
									<br /> No provision of the Terms and Conditions which create a
									partnership, agency or employer and employeerelationship between You
									and Us.
								</li>
								<li>
									<b>THIRD PARTY RIGHTS</b>
									<br /> No person or any party other than You and Us have the right or
									entitled to benefit from the contract between You and Us as well as
									from this Terms and Conditions.
								</li>
								<li>
									<b>APPLICABLE LAW</b>
									<br /> The Terms and Conditions and all contractual obligations arising
									from or related with this Terms and Conditions as well Other terms are
									regulated and construed in accordance with the laws of Indonesia. In
									the event that there is a conflict arising from the Terms and
									Conditions, and other Terms as well as other contractual relations, We
									and You agree to resolve the conflict in South Jakarta District Court.
									All transactions, correspondence and contact between Us and You will be
									made or done in Indonesia or the Indonesian language along with the
									English translations.
								</li>
								<li>
									<b>AMENDMENT</b>
									<br />
									<p>
										We reserve the right to amend this Terms and Conditions at any time by
										displaying on the Website. Youhereby agree that you are required to
										view and read the updates. If you disagree, not consent, or object
										suchamendment, You are requested to immediately stop using and/or
										accessing the Website, and deactivate Youraccount. If You do not
										perform such actions, You are deemed to have consented, agreed and
										accepted theamendment of the Terms and Conditions. Any use of the
										Website after such update was displayed onlinewill be considered as an
										acceptance of and adherence by You to the amendment of the Terms
										andConditions applicable at that time. For every placement made on the
										Website, You agree to be subject to theprevailing Terms and Conditions
										applied on the date You place Your Order. You are obliged and
										responsibleto read, review and understand the latest Terms and
										Conditions every time you place Your Order
									</p>
									<p>
										We and You hereby agree to waive the provisions of sub-paragraph 2 and
										3 of Article 1266 of the IndonesianCivil Code, to the extent that the
										court’s decision is required to terminate this Terms and Conditions.
									</p>
									<p>
										<i>Terms and Conditions was lastly updated 8 September 2020</i>
									</p>
								</li>
							</ol>
						</Box>
					</Box>
				</Container>
			</LayoutComponent>
		</>
	)
}

export default TermAndConditions
