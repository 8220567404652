/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import CustomModal from 'Components/modal'
import { Box, Button, Typography } from '@material-ui/core'
import { useStyle } from './styles'

const ErrorPage = ({ visible, errorTitle, errorContent, onDismiss }) => {
	const classes = useStyle()
	const [openModal, setOpenModal] = useState(visible)
	useEffect(() => {
		setOpenModal(visible)
	}, [visible])

	return (
		<CustomModal icon='error' open={openModal} onClose={onDismiss}>
			<Box width={250}>
				<Typography variant='body1'>
					<strong>{errorTitle}</strong>
				</Typography>
				<Box mb={1}>
					<Typography variant='body2'>{errorContent}</Typography>
				</Box>
				<Button
					size='small'
					onClick={onDismiss}
					className={classes.button}
					variant='contained'
					color='secondary'
				>
					Ok
				</Button>
			</Box>
		</CustomModal>
	)
}

export default ErrorPage
