import React from 'react'
import { Paper, Grid, Box, Typography } from '@material-ui/core'
import LayoutComponent from '../layout'
// import { navigate } from '@reach/router'
import byMbiz from '../../assets/images/svgs/logo.svg'
import logoCombiphar from '../../assets/images/whitelabel/logocombiphar.png'
import PropTypes from 'prop-types'

// const handleNavigate = path => {
// 	navigate(path)
// }

const LoginLayout = ({ children, classes, type = 'form' }) => {
	const classes_loginStyle = classes
	return (
		<LayoutComponent
			header={false}
			newsletter={false}
			className={classes_loginStyle.login_background}
			isFooter={false}
		>
			{type == 'form' ? (
				<>
					<Box display='inline-flex' position='absolute' style={{ top: '7%' }}>
						<img
							src={logoCombiphar}
							style={{ width: '100%', transform: 'translate(18%, -20%)' }}
							alt='mbiz.co.id'
						/>
					</Box>
					<Grid className={classes_loginStyle.form_login} container justify='center'>
						<Grid item xs={12} sm={4} className={classes_loginStyle.container_field}>
							{children}
						</Grid>
					</Grid>
					<Box
						display='inline-flex'
						position='absolute'
						style={{ bottom: '17%', transform: 'translate(-100%, 0)' }}
					>
						<Typography variant='subtitle2'> Powered by</Typography>
						<img src={byMbiz} style={{ width: 130 }} alt='mbiz.co.id' />
					</Box>
				</>
			) : type == 'message' ? (
				<Paper className={classes_loginStyle.message_container} variant='outlined'>
					{children}
				</Paper>
			) : null}
		</LayoutComponent>
	)
}

LoginLayout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	classes: PropTypes.object,
	type: PropTypes.string,
}
export default LoginLayout
