import { SHIPPING_TRACKING } from '../../../types'
import API from '../../../../utils/api'
import { success, failure, loading } from '../../../commonActions'

export const requestShippingTracking = (noAWB) => {
	return async (dispatch) => {
		dispatch(loading(SHIPPING_TRACKING))
		try {
			const response = await API({
				url: `/shipping/track/awb?awb_no=${noAWB}`,
			})
			if (response.data) {
				if ('error_backend' in response.data) {
					dispatch(failure(SHIPPING_TRACKING))
				} else {
					dispatch(success(SHIPPING_TRACKING, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(SHIPPING_TRACKING))
		}
	}
}
