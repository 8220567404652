import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	inputSelect: ({ error }) => ({
		padding: theme.spacing(0.1),
		border: error ? '1px solid red' : '1px solid #bbb',
		borderTopLeftRadius: 2,
		borderBottomLeftRadius: 2,
		textAlign: 'right',
		paddingRight: theme.spacing(2),
		width: '100%',
		color: theme.palette.common.black,
		textShadow: '0 0 0 black',
		cursor: 'pointer',
	}),
	inputSelectRead: {
		padding: theme.spacing(0.1),
		border: '1px solid #bbb',
		borderTopLeftRadius: 2,
		borderBottomLeftRadius: 2,
		textAlign: 'right',
		paddingRight: theme.spacing(2),
		width: 125,
		color: theme.palette.common.black,
		textShadow: '0 0 0 black',
		cursor: 'pointer',
	},
	dropdownButton: {
		backgroundColor: theme.palette.secondary.main,
		display: 'flex',
		alignItems: 'center',
		color: theme.palette.common.white,
		borderTopRightRadius: 2,
		borderBottomRightRadius: 2,
		cursor: 'pointer',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	textDropdown: {
		marginRight: theme.spacing(2),
	},
	dropdownList: {
		width: '100%',
		position: 'absolute',
		border: '1px solid rgba(4,4,4,.1)',
		borderRadius: 5,
		marginTop: theme.spacing(0.6),
		backgroundColor: theme.palette.common.white,
	},
	selected: {
		backgroundColor: 'white!important',
	},
	itemList: {
		backgroundColor: 'white!important',
		'&:hover': {
			backgroundColor: 'white!important',
		},
		'&$selected': {
			color: 'inherit',
		},
	},
	selectColumns: {
		width: '100%',
		borderRadius: 5,
		overflow: 'hidden',
	},
	headerSelect: {
		position: 'sticky',
		zIndex: 2,
		top: 0,
		backgroundColor: 'white!important',
		'&:hover': {
			backgroundColor: 'white!important',
		},
	},
	muiSelect: {
		paddingBottom: 8,
		'&:focus': {
			backgroundColor: 'white!important',
		},
	},
	icon: {
		backgroundColor: theme.palette.secondary['main'],
		color: theme.palette.secondary['contrastText'],
		top: 0,
		right: 0,
		height: 38,
		width: 37,
	},
	labelHeader: {
		fontSize: 14,
	},
	paperSelect: {
		maxHeight: 330,
	},
	textField: {
		width: '250px !important',
	},
	textFieldFull: {
		width: '100% !important',
	},
	selectInput: {
		padding: '9px 0 8px 0 !important',
	},
	inputRoot: {
		paddingRight: '0 !important',
		backgroundColor: theme.palette.common.white,
		borderRadius: theme.spacing(0.5),
		paddingLeft: theme.spacing(1.5),
		paddingBottom: '0 !important',
	},
	endAdornment: {
		top: 0,
		right: 0,
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		padding: '4px 6.7px',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	option: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	tagRenderer: {
		maxWidth: '70%',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}))

export default styles
