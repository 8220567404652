import { makeStyles } from '@material-ui/core/styles'

export const TermAndConditionsStyle = makeStyles((theme) => ({
	contentList: {
		fontSize: theme.typography.body1.fontSize,
		counterReset: 'item',
		listStyleType: 'none',
		padding: 0,
		textAlign: 'justify',

		'& > li': {
			margin: '10px 0 10px 30px',
			position: 'relative',
			counterIncrement: 'item',
			listStyle: 'none',

			'&::before': {
				content: 'counters(item, ".") "."',
				position: 'absolute',
				marginRight: '100%',
				right: '10px',
				fontWeight: 'bold',
			},

			'& > ol': {
				counterReset: 'item',
				paddingLeft: '20px',

				'& > li': {
					counterIncrement: 'item',
					margin: '10px 0 10px 30px',
					position: 'relative',
					listStyle: 'none',

					'&::before': {
						content: 'counters(item, ".")',
						position: 'absolute',
						marginRight: '100%',
						right: '10px',
						fontWeight: 'bold',
					},

					'& > ol': {
						counterReset: 'item',
						paddingLeft: '20px',

						'& > li': {
							counterIncrement: 'item',
							margin: '10px 0 10px 30px',
							position: 'relative',
							listStyle: 'none',

							'&::before': {
								content: 'counters(item, ".")',
								position: 'absolute',
								marginRight: '100%',
								right: '10px',
								fontWeight: 'bold',
							},

							'& > ol': {
								counterReset: 'item',
								paddingLeft: '20px',

								'& > li': {
									counterIncrement: 'item',
									margin: '10px 0 10px 30px',
									position: 'relative',
									listStyle: 'none',

									'&::before': {
										content: 'counters(item, ".")',
										position: 'absolute',
										marginRight: '100%',
										right: '10px',
										fontWeight: 'bold',
									},
								},
							},
						},
					},
				},
			},
		},
	},

	returnTable: {
		'&>p': {
			textAlign: 'center',
			marginBottom: 10,
		},
		'&>table': {
			border: '1px solid #ddd',
			maxWidth: '100%',
			width: '100%',
			marginBottom: 20,
			borderCollapse: 'collapse',
			borderSpacing: 0,
			'&>thead': {
				backgroundColor: ' #264f6b',
				color: '#ffffff',
				fontSize: '13px',
				fontWeight: 'bold',
				textAlign: 'center',

				'&>tr>th': {
					padding: 8,

					'&:first-child': {
						textAlign: 'left',
					},
				},
			},
			'&>tbody>tr>td': {
				padding: 8,
				verticalAlign: 'middle',
				textAlign: 'center',

				'&:first-child': {
					textAlign: 'left',
				},
			},
		},
	},
	linkColor: {
		color: theme.palette.secondary.main,
	},
}))
