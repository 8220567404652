import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dashboard,
	},
	containerRoot: {
		padding: theme.spacing(3, 12),
	},
	datepicker: {
		width: 227,
	},
	tableRoot: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
	},
	totalPrice: {
		padding: theme.spacing(1, 3),
		justifyContent: 'space-between',
		display: 'flex',
		flex: '1',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	textareaRoot: {
		width: 380,
	},
	addressValue: {
		width: 290,
	},
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalFade: {
		minWidth: 850,
	},
	modalCloseIcon: {
		alignSelf: 'end',
	},
	containerField: {
		alignItems: 'center',
		justifyContent: 'space-around',
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(2),
	},
	outlinedInputRoot: {
		overflow: 'hidden',
		paddingRight: 0,
		marginTop: 4,
	},
	outlinedInputadornedEnd: {
		backgroundColor: theme.palette.secondary,
	},
	autocompleteRoot: {
		width: 250,
	},
	buttonDanger: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	buttonWidth: {
		minWidth: 120,
	},
	containerButton: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	kembali: {
		marginRight: theme.spacing(2),
	},
	textCenter: {
		textAlign: 'center',
	},
	paddingBottom: {
		paddingBottom: theme.spacing(2),
	},
	fontBold: {
		fontWeight: 700,
	},
	image: {
		width: 150,
		height: 150,
	},
	textDokumen: {
		marginTop: '6px',
		textDecoration: 'none',
		fontSize: '30px',
		color: '#C4C4C4',
		opacity: 0.4,
	},
	fileThumb: {
		width: '150px',
		height: '150px',
	},
	blankFileThumb: {
		width: '150px',
		height: '150px',
	},
	fileName: {
		color: '#007BFF',
		'&:hover': {
			cursor: 'pointer',
		},
		wordWrap: 'break-word',
		marginTop: '10px',
	},
	margintumbhnail: {
		marginBottom: '20px',
		margin: '14px',
	},
}))

export default styles
