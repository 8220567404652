import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	categoryContainer: {
		display: 'grid',
		// gridTemplateColumns: 'repeat(11, 1fr)',
		gridAutoFlow: 'column',
		gridAutoColumns: '109.45px',
		overflow: 'auto',
		gridRowGap: theme.spacing(1),
		padding: theme.spacing(1, 0),
		marginTop: theme.spacing(2.5),
		borderRadius: 10,
		gridColumnGap: 6,
		boxShadow: '1px 1px 4px gray',
	},
	categoryItem: {
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		'& p': {
			fontSize: '0.775rem',
		},
	},
	categoryIcon: {
		background: theme.palette.primary.main,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		borderRadius: 10,
		position: 'relative',
		overflow: 'hidden',
		'&:hover': {
			background: '#0E2C58',
		},
		'&:active': {
			background: '#0E2C58',
		},
	},
	categoryTitle: {
		marginTop: 8,
	},
	icon: {
		stroke: '#ffff',
		fill: 'none',
		strokeLinecap: 'round',
		strokeWidth: '2.5027',
		strokeMiterlimit: 10,
		strokeLinejoin: 'round',
	},
}))

export default styles
