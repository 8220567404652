import React from 'react'
import Modal from '@material-ui/core/Modal'
import styles from './styles'
import PropTypes from 'prop-types'
import { QuestionMark, SuccessCircle, TimesCircle } from 'Components/svgs'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

function getModalStyle() {
	const position = 50

	return {
		top: `${position}%`,
		left: `${position}%`,
		transform: `translate(-${position}%, -${position}%)`,
		outline: 'none',
	}
}

const Component = (props) => {
	const classes = styles()
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle)

	const generateIcon = (icon) => {
		switch (icon) {
			case 'question':
				return (
					<div className={classes.iconContent}>
						<QuestionMark />
					</div>
				)
			case 'success':
				return (
					<div
						className={
							props.isNeedMarginBottom ? classes.iconContent : classes.iconContent10
						}
					>
						<SuccessCircle />
					</div>
				)
			case 'error':
				return (
					<div
						className={
							props.isNeedMarginBottom ? classes.iconContent : classes.iconContent10
						}
					>
						<TimesCircle />
					</div>
				)
			case 'warning':
				return (
					<div className={classes.iconContent}>
						<ErrorOutlineIcon className={classes.iconWarning} />
					</div>
				)
			default:
				return ''
		}
	}

	return (
		<div>
			<Modal
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
				open={props.open}
				onClose={props.onClose}
				{...props}
			>
				<div style={modalStyle} className={classes.paper}>
					{generateIcon(props.icon)}
					{props.children}
				</div>
			</Modal>
		</div>
	)
}

Component.propTypes = {
	open: PropTypes.bool,
	icon: PropTypes.oneOf(['question', 'success', 'error', 'none', 'warning']),
	onClose: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
	isNeedMarginBottom: PropTypes.bool,
	// onOpen: PropTypes.func
}

Component.defaultProps = {
	open: false,
	icon: 'question',
	isNeedMarginBottom: true,
	// onClose: () => {},
	// onOpen: () => {}
}

export default Component
