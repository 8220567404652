import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
	buttonGroupPreppend: {
		width: 30,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		minWidth: 0,
	},
	buttonGroupAppend: {
		width: 30,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		minWidth: 0,
	},
	buttonGroupInput: {
		width: 60,
		textAlign: 'center',
		WebkitAppearance: 'textfield',
		MozAppearance: 'textfield',
		appearance: 'textfield',
		border: 'solid 1px #ccc',
		borderStyle: 'solid none',
		height: '100%',
	},
}))

export default styles
