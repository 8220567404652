import React, { useEffect, useState, useRef, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import LayoutDashboard from 'Components/layoutDashboard'
import API from '../../../utils/api'
import {
	requestDetailCompany,
	updateCompanyProfile,
	getContactList,
} from 'States/actions/CompanyProfile/profileAction'
import Modal from 'Components/modal'
import { getLocation, resetLocationAction } from 'States/actions/Master'
import Image from 'material-ui-image'
import {
	Box,
	Grid,
	Typography,
	Paper,
	Divider,
	Button,
	TextField,
	Card,
	CardContent,
	IconButton,
	Tooltip,
	CircularProgress,
} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Skeleton } from '@material-ui/lab'
import { withStyles } from '@material-ui/styles'
import { EditCompanyProfileStyle } from './styles'
import { Formik, Form, ErrorMessage, Field, FieldArray } from 'formik'
import validationSchema from './schema'
import Breadcrumbs from 'Components/breadcrumbs'
import { getInitialValue } from './utils'
import { getFileThumb } from '../../../utils/fileThumbnail'

const EditCompanyProfile = ({ navigate }) => {
	const inputRef = useRef()
	const classes = EditCompanyProfileStyle()
	const dispatch = useDispatch()
	const [locationLoading, setLocationLoading] = useState(true)
	const [locationLoadingWording, setLocationLoadingWording] = useState(
		'Masukkan minimal 3 karakter'
	)
	const [submitModal, setSubmitModal] = useState(false)
	const [image, setImage] = useState('')
	const [deletePicModal, setDeletePicModal] = useState({ id: null })
	const [arrDeletePic, setArrDeletePic] = useState([])

	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Profil Perusahaan',
			link: '/dashboard/company-profile/',
		},
		{
			segment: 'Ubah Profil Perusahaan',
			link: '#',
		},
	]

	const uploadImage = (actions) => {
		let data = new FormData()
		let file = inputRef.current.files[0]

		data.append('files[]', file, file.name)
		data.append('types', 'res_partner')
		API({
			method: 'POST',
			url: '/storages/upload',
			data: data,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then((res) => {
				setImage(res.data.data.results[0].file_location_encrypt)
				actions.setFieldValue('storage_id', res.data.data.results[0].id)
			})
			.catch(() => alert('failed upload image'))
	}

	// get detail company
	useEffect(() => {
		dispatch(requestDetailCompany())
		dispatch(getContactList())
	}, [])

	// detail company data
	const companyDetail = useSelector((state) => state.companyProfile.company)
	const companyDetailUpdate = useSelector((state) => state.companyProfileUpdate)
	const contactList = useSelector((state) => state.companyProfile.contacts)
	const dataLocation = useSelector((state) => state.location.location)

	const [docNameOverflow, setDocNameOverflow] = useState([])
	const [docNumOverflow, setDocNumOverflow] = useState([])
	const [docTypeOverflow, setDocTypeOverflow] = useState([])

	let docNameRef = useRef([])
	let docNumberRef = useRef([])
	let docTypeRef = useRef([])

	useEffect(() => {
		if (companyDetail.data?.company_details) {
			setImage(companyDetail.data.company_details.image_512_filestore)
		}
	}, [companyDetail.data])

	useEffect(() => {
		if (docNameRef.current.length > 0) {
			const nextDocNameOverflow = docNameRef.current.map((ref) =>
				ref.current ? isOverflowActive(ref.current) : false
			)
			setDocNameOverflow(nextDocNameOverflow)
		}
		if (docNumberRef.current.length > 0) {
			const nextdocNumOverflow = docNumberRef.current.map((ref) =>
				ref.current ? isOverflowActive(ref.current) : false
			)
			setDocNumOverflow(nextdocNumOverflow)
		}
		if (docTypeRef.current.length > 0) {
			const nextdocTypeOverflow = docTypeRef.current.map((ref) =>
				ref.current ? isOverflowActive(ref.current) : false
			)
			setDocTypeOverflow(nextdocTypeOverflow)
		}
	}, [docNameRef.current, docNumberRef.current, docTypeRef.current])

	const isOverflowActive = (e) => {
		return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth
	}

	const handleSearchLocation = (_e, val) => {
		if (val.length >= 3) {
			setLocationLoading(false)
			setLocationLoadingWording('Loading')
			dispatch(getLocation(val))
		} else {
			setLocationLoading(true)
			setLocationLoadingWording('Masukkan minimal 3 karakter')
			dispatch(resetLocationAction())
		}
	}

	const handleRenderOption = (option) => {
		return (
			<div>
				<span key={option.id}>{option.complete_name}</span>
			</div>
		)
	}

	const handleDeletePic = async (helperArr, action, index) => {
		const selectedPic = helperArr.form.values.child_ids[index]
		if (selectedPic.action === 'updated') {
			const deletedPic = { ...selectedPic }
			deletedPic.action = 'deleted'
			setArrDeletePic([...arrDeletePic, deletedPic])
		}
		await helperArr.remove(index)
		setDeletePicModal({ id: null })
		handleValidateForm(action)
	}

	const handleDeleteModal = (id = null) => {
		setDeletePicModal({ id: id })
	}

	const handleSubmitForm = (values) => {
		values.child_ids = [...values.child_ids, ...arrDeletePic]
		setSubmitModal(false)
		dispatch(updateCompanyProfile(values))
	}

	const handleValidateBeforeSubmit = async (action) => {
		const error = await handleValidateForm(action)
		if (error.length === 0) {
			setSubmitModal(true)
		}
	}

	const handleValidateForm = async (action) => {
		const error = await action.validateForm()
		const currentErrorKeys = Object.keys(error)
		if (currentErrorKeys.length) {
			currentErrorKeys.map((item) => {
				if (Array.isArray(error[item])) {
					error[item].map((item1, index) => {
						if (typeof item1 !== 'undefined') {
							const ArrKeys = Object.keys(item1)
							ArrKeys.map((item2) => {
								action.setFieldTouched(`${item}.${index}.${item2}`, true)
							})
						}
					})
				} else {
					action.setFieldTouched(item, true)
				}
			})
		}
		return currentErrorKeys
	}

	const handleAddPic = async (helperArr, action) => {
		const newValue = {
			id: new Date().getTime(),
			name: '',
			email: '',
			jabatan: '',
			mobile: '',
			phone: '',
			action: 'new',
			type: 'contact',
		}
		await helperArr.push(newValue)
		handleValidateForm(action)
	}

	const imageStorage = process.env.GATSBY_IMAGE_URL

	const StyleTooltip = withStyles(() => ({
		tooltip: {
			marginTop: 5,
			marginBottom: 5,
			maxWidth: 'none',
		},
	}))(Tooltip)

	return (
		<LayoutDashboard>
			<Box height={70} alignItems='center' display='flex'>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Box>
			<Formik
				onSubmit={(values) => handleSubmitForm(values)}
				validateOnChange={false}
				validationSchema={validationSchema}
				initialValues={getInitialValue(companyDetail, contactList)}
			>
				{({ values, errors, touched, ...prop }) => (
					<Form method='post'>
						<Box p={(1, 0)}>
							<Typography variant='h6'>Profil Perusahaan</Typography>
							<Paper variant='outlined' className={classes.boxSpacing}>
								<Box p={2}>
									<Typography variant='h6'>Informasi Profil</Typography>
									<Divider className={classes.marginTB1} />
									<Grid container spacing={3}>
										<Grid item xs={12} sm={6}>
											<Grid container spacing={3}>
												<Grid item xs={12}>
													<Grid container alignItems='flex-start'>
														<Grid item xs={4} className={classes.formLabel}>
															<Typography
																variant='subtitle1'
																className={classes.labelRequired}
															>
																Logo
															</Typography>
														</Grid>
														<Grid item xs={6}>
															<Box mb={2} className={classes.image}>
																{!companyDetail.data ? null : (
																	<Image
																		src={`${imageStorage}/180x180/erp/${image}`}
																		alt='Profile image'
																	/>
																)}
															</Box>
															<Grid container direction='column'>
																<Grid item xs={12} className={classes.formLabel}>
																	<input
																		type='file'
																		accept='image/*'
																		ref={inputRef}
																		onChange={() => uploadImage(prop)}
																		style={{ width: '90px' }}
																	/>
																</Grid>
																<Grid item xs={12}>
																	<Typography variant='caption'>
																		Ukuran berkas maksimal adalah 5MB
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
													<Grid container alignItems='flex-start'>
														<Grid item xs={4} className={classes.formLabel}>
															<Typography
																variant='subtitle1'
																className={classes.labelRequired}
															>
																Nama Pembeli
															</Typography>
														</Grid>
														<Grid item xs={6}>
															<Field
																as={TextField}
																name='namaPembeli'
																fullWidth
																error={errors.namaPembeli && touched.namaPembeli}
																variant='outlined'
																margin='dense'
																placeholder='Nama Pembeli'
															/>
															<ErrorMessage name='namaPembeli'>
																{(msg) => (
																	<Typography variant='caption' color='error'>
																		{msg}
																	</Typography>
																)}
															</ErrorMessage>
														</Grid>
													</Grid>
													<Grid container alignItems='flex-start'>
														<Grid item xs={4}>
															<Typography
																variant='subtitle1'
																className={classes.labelRequired}
															>
																Latar Belakang
															</Typography>
														</Grid>
														<Grid item xs={6}>
															<Field
																as={TextField}
																name='latarBelakang'
																multiline
																fullWidth
																rows={4}
																variant='outlined'
																margin='dense'
																aria-label='latar belakang'
																error={errors.latarBelakang && touched.latarBelakang}
																placeholder='Latar Belakang'
															/>
															<ErrorMessage name='latarBelakang'>
																{(msg) => (
																	<Typography variant='caption' color='error'>
																		{msg}
																	</Typography>
																)}
															</ErrorMessage>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Grid container spacing={3}>
												<Grid item xs={12}>
													<Grid container alignItems='flex-start'>
														<Grid item xs={6} className={classes.formLabel}>
															<Typography
																variant='subtitle1'
																className={classes.labelRequired}
															>
																Alamat Lengkap
															</Typography>
														</Grid>
														<Grid item xs={6}>
															<Field
																as={TextField}
																name='alamat'
																error={errors.alamat && touched.alamat}
																fullWidth
																multiline
																rows={4}
																variant='outlined'
																margin='dense'
																placeholder='Alamat Lengkap'
															/>
															<ErrorMessage name='alamat'>
																{(msg) => (
																	<Typography variant='caption' color='error'>
																		{msg}
																	</Typography>
																)}
															</ErrorMessage>
														</Grid>
													</Grid>
													<Grid container alignItems='flex-start'>
														<Grid item xs={6} className={classes.formLabel}>
															<Typography
																variant='subtitle1'
																className={classes.labelRequired}
															>
																Lokasi
															</Typography>
														</Grid>
														<Grid item xs={6}>
															<Field
																as={Autocomplete}
																name='location'
																onInputChange={(_e, val) => handleSearchLocation(_e, val)}
																onChange={(_opt, val) => {
																	prop.setValues({
																		...values,
																		location: val
																			? {
																				id: val.id,
																				complete_name: val.complete_name,
																				level: val.level,
																			}
																			: null,
																	})
																}}
																value={values.location}
																getOptionLabel={(option) => option.complete_name || ''}
																renderOption={(option) => handleRenderOption(option)}
																getOptionSelected={(opt, value) =>
																	opt.complete_name == value.complete_name
																}
																loading={dataLocation.isLoading || locationLoading}
																options={dataLocation.data}
																renderInput={(params) => (
																	<Tooltip
																		title={values?.location?.complete_name || ''}
																		placement='bottom'
																		classes={{
																			tooltip: classes.tooltip,
																			tooltipPlacementBottom: classes.tooltipPlacementBottom,
																		}}
																	>
																		<TextField
																			{...params}
																			variant='outlined'
																			placeholder='Provinsi, Kota, Kecamatan, Kelurahan, Kode Pos'
																			margin='dense'
																			name='location'
																			error={errors.location && touched.location}
																		/>
																	</Tooltip>
																)}
																noOptionsText={<i>Maaf, kata kunci tidak ditemukan</i>}
																loadingText={<i>{locationLoadingWording}</i>}
																ListboxProps={{
																	style: {
																		maxHeight: '370px',
																	},
																}}
																classes={{
																	noOptions: classes.noOptionText,
																	loading: classes.loadingText,
																}}
															/>
															<ErrorMessage name='location'>
																{(msg) => (
																	<Typography variant='caption' color='error'>
																		{msg}
																	</Typography>
																)}
															</ErrorMessage>
														</Grid>
													</Grid>
													<Grid container alignItems='flex-start'>
														<Grid item xs={6} className={classes.formLabel}>
															<Typography
																variant='subtitle1'
																className={classes.labelRequired}
															>
																No. Telepon
															</Typography>
														</Grid>
														<Grid item xs={6}>
															<Field
																as={TextField}
																name='noTelp'
																fullWidth
																variant='outlined'
																margin='dense'
																placeholder='No. Telepon'
																error={errors.noTelp && touched.noTelp}
															/>
															<ErrorMessage name='noTelp'>
																{(msg) => (
																	<Typography variant='caption' color='error'>
																		{msg}
																	</Typography>
																)}
															</ErrorMessage>
														</Grid>
													</Grid>
													<Grid container alignItems='flex-start'>
														<Grid item xs={6} className={classes.formLabel}>
															<Typography variant='subtitle1'>No. Ponsel</Typography>
														</Grid>
														<Grid item xs={6}>
															<Field
																name='noPonsel'
																as={TextField}
																fullWidth
																variant='outlined'
																margin='dense'
																placeholder='No. Ponsel'
															/>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Paper>
							<Paper variant='outlined' className={classes.boxSpacing}>
								<Box p={2}>
									<Grid container spacing={1}>
										<Grid
											container
											item
											xs={8}
											justifyContent='flex-start'
											alignItems='center'
										>
											<Typography variant='h6'>Kontak PIC</Typography>
										</Grid>
										<Grid
											container
											item
											xs={4}
											justifyContent='flex-end'
											alignItems='center'
										>
											<FieldArray
												name='child_ids'
												render={(helperArr) => (
													<Typography variant='subtitle1'>
														<a
															className={
																typeof errors.child_ids === 'undefined'
																	? classes.link
																	: classes.linkDisabled
															}
															{...(typeof errors.child_ids === 'undefined' && {
																onClick: () => handleAddPic(helperArr, prop),
															})}
														>
															+ Tambah Kontak PIC
														</a>
													</Typography>
												)}
											/>
										</Grid>
									</Grid>
									<Divider className={classes.marginTB1} />
									<Box my={2}>
										<Grid container spacing={3}>
											<FieldArray
												name='child_ids'
												render={(helperArr) =>
													values.child_ids && values.child_ids.length ? (
														values.child_ids
															.filter((item) => item.action !== 'deleted')
															.map((pic, index) => (
																<Grid key={index} item xs={3}>
																	<Card variant='outlined' className={classes.cardRelative}>
																		<IconButton
																			color='primary'
																			edge='start'
																			className={classes.deleteButton}
																			onClick={() => handleDeleteModal(pic.id)}
																		>
																			<HighlightOffIcon fontSize='medium' />
																		</IconButton>
																		<CardContent
																			classes={{
																				root: classes.cardContent,
																			}}
																		>
																			<Grid container>
																				<Grid item xs={5} className={classes.formLabel}>
																					<Typography
																						variant='subtitle1'
																						className={classes.labelRequired}
																					>
																						Nama
																					</Typography>
																				</Grid>
																				<Grid item xs={7}>
																					<Field
																						as={TextField}
																						name={`child_ids.${index}.name`}
																						error={
																							errors.child_ids &&
																							touched.child_ids &&
																							touched.child_ids[index]?.name &&
																							Boolean(errors.child_ids[index]?.name)
																						}
																						fullWidth
																						variant='outlined'
																						margin='dense'
																						placeholder='Nama'
																					/>
																					<ErrorMessage name={`child_ids.${index}.name`}>
																						{(msg) => (
																							<Typography variant='caption' color='error'>
																								{msg}
																							</Typography>
																						)}
																					</ErrorMessage>
																				</Grid>
																				<Grid item xs={5} className={classes.formLabel}>
																					<Typography variant='subtitle1'>Jabatan</Typography>
																				</Grid>
																				<Grid item xs={7}>
																					<Field
																						as={TextField}
																						name={`child_ids.${index}.jabatan`}
																						fullWidth
																						variant='outlined'
																						margin='dense'
																						placeholder='Jabatan'
																					/>
																				</Grid>
																				<Grid item xs={5} className={classes.formLabel}>
																					<Typography variant='subtitle1'>Email</Typography>
																				</Grid>
																				<Grid item xs={7}>
																					<Field
																						as={TextField}
																						name={`child_ids.${index}.email`}
																						error={
																							errors.child_ids &&
																							touched.child_ids &&
																							touched.child_ids[index]?.email &&
																							Boolean(errors.child_ids[index]?.email)
																						}
																						fullWidth
																						variant='outlined'
																						margin='dense'
																						placeholder='Email'
																					/>
																					<ErrorMessage name={`child_ids.${index}.email`}>
																						{(msg) => (
																							<Typography variant='caption' color='error'>
																								{msg}
																							</Typography>
																						)}
																					</ErrorMessage>
																				</Grid>
																				<Grid item xs={5} className={classes.formLabel}>
																					<Typography
																						variant='subtitle1'
																						className={classes.labelRequired}
																					>
																						No.Ponsel
																					</Typography>
																				</Grid>
																				<Grid item xs={7}>
																					<Field
																						as={TextField}
																						name={`child_ids.${index}.mobile`}
																						error={
																							errors.child_ids &&
																							touched.child_ids &&
																							touched.child_ids[index]?.mobile &&
																							Boolean(errors.child_ids[index]?.mobile)
																						}
																						fullWidth
																						variant='outlined'
																						margin='dense'
																						placeholder='No. Ponsel'
																					/>
																					<ErrorMessage name={`child_ids.${index}.mobile`}>
																						{(msg) => (
																							<Typography variant='caption' color='error'>
																								{msg}
																							</Typography>
																						)}
																					</ErrorMessage>
																				</Grid>
																				<Grid item xs={5} className={classes.formLabel}>
																					<Typography variant='subtitle1'>No.Telp</Typography>
																				</Grid>
																				<Grid item xs={7}>
																					<Field
																						as={TextField}
																						name={`child_ids.${index}.phone`}
																						fullWidth
																						variant='outlined'
																						margin='dense'
																						placeholder='No. Telepon'
																					/>
																				</Grid>
																			</Grid>
																		</CardContent>
																	</Card>
																	<Modal
																		icon='warning'
																		open={pic.id === deletePicModal.id}
																		onClose={() => handleDeleteModal()}
																	>
																		<Typography variant='body1' className={classes.modalTitle}>
																			<strong>
																				Apakah Anda yakin ingin menghapus Kontak PIC ini?
																			</strong>
																		</Typography>
																		<Box
																			mt={1}
																			display='flex'
																			justifyContent='space-evenly'
																			alignItems='center'
																		>
																			<Button
																				onClick={() => handleDeleteModal()}
																				variant='outlined'
																				color='secondary'
																				className={classes.buttonWidth}
																			>
																				Batal
																			</Button>
																			<Button
																				onClick={() => handleDeletePic(helperArr, prop, index)}
																				className={`${classes.buttonDanger} ${classes.buttonWidth}`}
																			>
																				Ya, Hapus
																			</Button>
																		</Box>
																	</Modal>
																</Grid>
															))
													) : (
														<Grid
															container
															justifyContent='center'
															alignItems='center'
															item
															xs={12}
														>
															<Box
																pt={2}
																display='flex'
																justifyContent='center'
																alignItems='center'
															>
																<Typography
																	variant='body1'
																	classes={{ root: classes.emptyPic }}
																>
																	Tidak ada data kontak PIC yang terdaftar
																</Typography>
															</Box>
														</Grid>
													)
												}
											/>
										</Grid>
									</Box>
								</Box>
							</Paper>
							<Paper variant='outlined' className={classes.boxSpacing}>
								<Box p={2}>
									<Typography variant='h6'>Informasi Tambahan</Typography>
									<Divider className={classes.marginTB1} />
									<Grid container>
										<Grid container item xs={6}>
											<Grid item xs={6} className={classes.formLabel}>
												<Typography variant='subtitle1'>Term Of Payment</Typography>
											</Grid>
											<Grid item xs={6} className={classes.formLabel}>
												<Typography variant='body1'>
													{companyDetail.data?.company_details?.property_payment_term_id
														? String(
															companyDetail.data.company_details.property_payment_term_id.name
														).replace('Days', 'hari')
														: ''}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item xs={6}>
											<Grid item xs={6} className={classes.formLabel}>
												<Typography variant='subtitle1'>
													Status Manajemen Persetujuan
												</Typography>
											</Grid>
											<Grid item xs={6} className={classes.formLabel}>
												{companyDetail.data?.is_pr_active ? (
													<Typography variant='body1'>Aktif</Typography>
												) : (
													<Typography variant='body1'>Tidak Aktif</Typography>
												)}
											</Grid>
											<Grid container>
												<Grid item xs={12}>
													<a
														href={`${window.location.origin}/dashboard/approval/configure`}
														target='blank'
													>
														Klik disini
													</a>{' '}
													untuk pengaturan manajemen persetujuan
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Paper>
							<Paper variant='outlined' className={classes.boxSpacing}>
								<Box p={2} pb={5}>
									<Typography variant='h6'>Dokumentasi</Typography>
									<Divider className={classes.marginTB1} />
									<Box className={classes.boxSpacing}>
										<Grid container spacing={4}>
											{companyDetail.isLoading ? (
												Array.from(Array(2)).map((_, i) => (
													<Grid item key={i} xs={12} lg={6} className={classes.boxSpacing}>
														<Grid container spacing={2} alignItems='center'>
															<Grid item xs={3}>
																<Skeleton variant='rect' width={100} height={100} />
															</Grid>
															<Grid item xs={9}>
																<Box mb={1}>
																	<Skeleton variant='text' width={100} />
																</Box>
																<Box mb={1}>
																	<Skeleton variant='text' width={250} />
																</Box>
																<Box mb={1}>
																	<Skeleton variant='text' width={250} />
																</Box>
															</Grid>
														</Grid>
													</Grid>
												))
											) : companyDetail.data?.company_details?.res_partner_document_ids
												.length == 0 ? (
												<Grid container justify='content-center'>
													<Grid xs={12}>
														<Box mt={5} mb={2}>
															<Typography
																varian
																style={{ textAlign: 'center', color: '#a2a4a7' }}
															>
																Tidak ada dokumen yang terlampir
															</Typography>
														</Box>
													</Grid>
												</Grid>
											) : (
												companyDetail.data?.company_details?.res_partner_document_ids.map(
													(item, index) => (
														<Grid
															item
															xs={12}
															lg={6}
															key={item.id}
															className={classes.boxSpacing}
														>
															<Grid container spacing={2} alignItems='center'>
																<Grid item xs={3}>
																	<Box className={classes.docImage}>
																		{getFileThumb(
																			item.file_name,
																			item.file_store_mimetype,
																			item.file_store_name
																		)}
																	</Box>
																</Grid>
																<Grid item xs={9}>
																	<Box>
																		<Grid container justify='flex-start'>
																			<Grid item xs={2}>
																				<Typography variant='subtitle1'>Nama</Typography>
																			</Grid>
																			<Grid item xs={10} lg={7}>
																				<StyleTooltip
																					placement='bottom-start'
																					disableHoverListener={!docNameOverflow[index]}
																					title={
																						<Typography variant='body1' style={{ fontSize: 13 }}>
																							{item.name}
																						</Typography>
																					}
																				>
																					<Typography
																						variant='body1'
																						className={classes.ellipsisText}
																						ref={(el) => (docNameRef.current[index] = el)}
																					>
																						{item.name}
																					</Typography>
																				</StyleTooltip>
																			</Grid>
																		</Grid>
																		<Grid container justify='flex-start'>
																			<Grid item xs={2}>
																				<Typography variant='subtitle1'>Nomor</Typography>
																			</Grid>
																			<Grid item xs={10} lg={7}>
																				<StyleTooltip
																					placement='bottom-start'
																					disableHoverListener={!docNumOverflow[index]}
																					title={
																						<Typography variant='body1' style={{ fontSize: 13 }}>
																							{item.document_number}
																						</Typography>
																					}
																				>
																					<Typography
																						variant='body1'
																						className={classes.ellipsisText}
																						ref={(el) => (docNumberRef.current[index] = el)}
																					>
																						{item.document_number}
																					</Typography>
																				</StyleTooltip>
																			</Grid>
																		</Grid>
																		<Grid container justify='flex-start'>
																			<Grid item xs={2}>
																				<Typography variant='subtitle1'>Tipe</Typography>
																			</Grid>
																			<Grid item xs={10} lg={7}>
																				<StyleTooltip
																					placement='bottom-start'
																					disableHoverListener={!docTypeOverflow[index]}
																					title={
																						<Typography variant='body1' style={{ fontSize: 13 }}>
																							{item.legality_document_id.name}
																						</Typography>
																					}
																				>
																					<Typography
																						variant='body1'
																						className={classes.ellipsisText}
																						ref={(el) => (docTypeRef.current[index] = el)}
																					>
																						{item.legality_document_id.name}
																					</Typography>
																				</StyleTooltip>
																			</Grid>
																		</Grid>
																	</Box>
																</Grid>
															</Grid>
														</Grid>
													)
												)
											)}
										</Grid>
									</Box>
								</Box>
							</Paper>
							<Grid
								container
								spacing={1}
								justify='flex-end'
								className={classes.boxSpacing}
							>
								<Grid item>
									<Button
										color='primary'
										onClick={() => navigate('/dashboard/company-profile')}
										className={`${classes.buttonDanger} ${classes.buttonWidth}`}
									>
										Batal
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant='contained'
										color='secondary'
										onClick={() => handleValidateBeforeSubmit(prop)}
										className={classes.buttonWidth}
										disabled={companyDetailUpdate.isLoading}
									>
										Simpan{' '}
										{companyDetailUpdate.isLoading && (
											<CircularProgress size={14} style={{ marginLeft: '5px' }} />
										)}
									</Button>
								</Grid>
							</Grid>
						</Box>

						<Modal
							open={submitModal}
							onClose={() => setSubmitModal(false)}
							icon='question'
						>
							<Box>
								<Typography variant='body1'>
									Apakah anda yakin ingin melakukan perubahan ?
								</Typography>
								<Box
									mt={1}
									display='flex'
									justifyContent='space-evenly'
									alignItems='center'
								>
									<Button
										onClick={() => setSubmitModal(false)}
										className={`${classes.buttonDanger} ${classes.buttonWidth}`}
									>
										Batal
									</Button>
									<Button
										variant='contained'
										color='secondary'
										className={classes.buttonWidth}
										onClick={() => prop.submitForm()}
									>
										Ya, Ubah
									</Button>
								</Box>
							</Box>
						</Modal>
					</Form>
				)}
			</Formik>
		</LayoutDashboard>
	)
}

EditCompanyProfile.propTypes = {
	navigate: PropTypes.func,
}

export default EditCompanyProfile
