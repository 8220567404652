/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import API from '../../../utils/api'
import {
	Box,
	Typography,
	Grid,
	Paper,
	Container,
	Button,
	TextField,
	CircularProgress,
} from '@material-ui/core'
import Image from 'material-ui-image'
import Breadcrumbs from 'Components/breadcrumbs'
import CustomModal from 'Components/modal'
import { ProfileEditStyles } from './styles'
import { navigate } from 'gatsby'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import defaultImageProfile from '../../../assets/images/icons/profile.png'
import schema from './schema'
import { requestUserAccountUpdate } from 'States/actions/UsersAccount/updateAction'
import HelpIcon from '@material-ui/icons/Help'
import { RolesDialog, DepartmentDialog } from 'Components/dialogs'
import { capitalizeFirst } from '../../../utils/globalUtils'

const imageStorage = process.env.GATSBY_IMAGE_URL
const breadcrums = [
	{
		segment: 'Home',
		link: '/dashboard',
	},
	{
		segment: 'Profil Saya',
		link: '/dashboard/profile',
	},
	{
		segment: 'Ubah Profil',
		link: '/dashboard/profile/edit',
	},
]

const initDataProfile = {
	name: '',
	email: '',
	mobile: '',
	jobTitle: '',
	contactDetails: '',
}

const ProfileEdit = () => {
	const inputRef = useRef()
	const [profileImage, setProfileImage] = useState('')
	const classes = ProfileEditStyles()
	const auth = useSelector((state) => state.auth)
	const userProfile = useSelector((state) => state.userProfileReducer)
	const [dataProfile, setDataProfile] = useState(initDataProfile)
	const user_buyer_roles = auth.data.data.user_buyer_roles
		? auth.data.data.user_buyer_roles
		: []
	const roles = user_buyer_roles
		.map((item) =>
			item.user_roles.role_name == 'staff'
				? 'Pemohon'
				: item.user_roles.role_name == 'manager'
				? 'Penyetuju'
				: capitalizeFirst(item.user_roles.role_name)
		)
		.join(', ')
	const [modalNotif, setModalNotif] = useState(false)
	const [notifSuccess, setNotifSuccess] = useState(false)
	const [open, setOpen] = useState(false)
	const [open2, setOpen2] = useState(false)
	const [scroll, setScroll] = useState('paper')
	const [showDepartemen, setShowDepartemen] = useState(false)
	const responseData = useSelector((state) => state.UsersAccountUpdateReducer)
	const dispatch = useDispatch()

	useEffect(() => {
		if (userProfile.data != null && userProfile.isLoading == false) {
			let dataTemp = dataProfile
			dataTemp.name = userProfile.data.name
			dataTemp.email = userProfile.data.email
			dataTemp.mobile = userProfile.data.mobile
			dataTemp.jobTitle = userProfile.data.job_title
			dataTemp.contactDetails = userProfile.data.contact_details
			setDataProfile(dataTemp)
		}
	}, [userProfile.data, userProfile.isLoading])

	useEffect(() => {
		dispatch(requestUserAccountUpdate({}, auth, 'RESET'))
	}, [])

	useEffect(() => {
		if (roles.length && roles.includes('Pemohon')) setShowDepartemen(true)
		else setShowDepartemen(false)
	}, [roles])

	useEffect(() => {
		// console.log(userProfile)
		if (userProfile.data) {
			setProfileImage(userProfile.data.contact_details.image_512_filestore)
		}
	}, [userProfile])

	useEffect(() => {
		if (responseData.error) {
			// if (responseData.error.http_body) {
			// 	let http_body = responseData.error.http_body
			// 	if (http_body) {
			// 		let body_obj = JSON.parse(http_body)
			// 		setErrorBackend(body_obj)
			// 	}
			// }
			setNotifSuccess(false)
		}

		// set notif success
		if (
			!responseData.isError &&
			responseData.data &&
			responseData.data.message != ''
		) {
			setNotifSuccess(true)
		}

		// show modal
		if (responseData.data.message != '') {
			setModalNotif(true)
		}
	}, [responseData])

	const handleSubmit = (formdata) => {
		formdata.email = auth.data.data.email
		formdata.username = auth.data.data.username
		formdata.status = 99
		dispatch(
			requestUserAccountUpdate(
				formdata,
				auth.data.data.id,
				'default',
				'current_user',
				auth
			)
		)
	}

	const handleModalOK = () => {
		setModalNotif(false)
		if (notifSuccess) {
			dispatch(requestUserAccountUpdate({}, auth, 'RESET'))
			navigate('/dashboard/profile')
		}
	}

	const uploadImage = (actions) => {
		let data = new FormData()
		let file = inputRef.current.files[0]

		data.append('files[]', file, file.name)
		data.append('types', 'res_partner')
		API({
			method: 'POST',
			url: '/storages/upload',
			data: data,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then((res) => {
				setProfileImage(res.data.data.results[0].file_location_encrypt)
				actions.setFieldValue('storage_id', res.data.data.results[0].id)
			})
			.catch(() => alert('failed upload image'))
	}

	const handleClickOpen = (scrollType) => () => {
		setOpen(true)
		setScroll(scrollType)
	}

	const handleClickOpen2 = (scrollType) => () => {
		setOpen2(true)
		setScroll(scrollType)
	}

	return (
		<DashboardLayout>
			<Container>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>
				<Box mt={2}>
					<Typography variant='h6'>Profil Saya</Typography>
				</Box>
				{userProfile.isLoading == false ? (
					<Formik
						enableReinitialize
						initialValues={{
							name: dataProfile.name,
							mobile: dataProfile.mobile,
						}}
						validationSchema={schema}
						onSubmit={(values) => handleSubmit(values)}
					>
						{({ errors, handleSubmit, touched, ...prop }) => {
							return (
								<Form
									onSubmit={handleSubmit}
									method='post'
									className={[classes.form, classes.p_4]}
								>
									<Box mt={2}>
										<Paper>
											<Box p={1}>
												<Grid container spacing={2}>
													<Grid container item xs={2} alignItems='center'>
														<Grid item>
															<Box className={classes.image} mb={1}>
																{profileImage && profileImage != '' ? (
																	<Image
																		src={`${imageStorage}/180x180/erp/${profileImage}`}
																		errorIcon={<img src={defaultImageProfile} />}
																		style={{
																			objectFit: 'contain',
																			width: '100%',
																			height: '100%',
																			zIndex: 1,
																		}}
																	/>
																) : (
																	<img
																		alt='123'
																		src={defaultImageProfile}
																		style={{
																			width: 'auto',
																			height: '100%',
																		}}
																	/>
																)}
															</Box>
															<input
																ref={inputRef}
																type='file'
																accept='image/*'
																onChange={() => uploadImage(prop)}
															/>
														</Grid>
													</Grid>
													<Grid container item xs={4} alignItems='center'>
														<Grid container item xs={4} alignItems='center'>
															<Typography variant='subtitle1'>Nama Lengkap</Typography>
														</Grid>
														<Grid container item xs={7} alignItems='center'>
															<Field name='name'>
																{({
																	field, // { name, value, onChange, onBlur }
																	// form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
																}) => (
																	<TextField
																		variant='outlined'
																		fullWidth
																		margin='dense'
																		placeholder='Masukan Nama Lengkap Anda'
																		type='input'
																		value={dataProfile.name}
																		error={errors.fullname && touched.fullname}
																		{...field}
																	/>
																)}
															</Field>
															<span style={{ color: 'red' }}>
																<ErrorMessage name='name' />
															</span>
														</Grid>
														<Grid container item xs={4} alignItems='center'>
															<Typography variant='subtitle1'>Email</Typography>
														</Grid>
														<Grid container item xs={7} alignItems='center'>
															<Typography variant='body1'>{auth.data.data.email}</Typography>
														</Grid>
														<Grid container item xs={4} alignItems='center'>
															<Typography variant='subtitle1'>No.Ponsel</Typography>
														</Grid>
														<Grid container item xs={7} alignItems='center'>
															<Field name='mobile'>
																{({
																	field, // { name, value, onChange, onBlur }
																	// form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
																}) => (
																	<TextField
																		variant='outlined'
																		fullWidth
																		margin='dense'
																		placeholder='Masukan No Handphone Anda'
																		type='input'
																		value={dataProfile.mobile}
																		error={errors.mobile && touched.mobile}
																		{...field}
																	/>
																)}
															</Field>
															<span style={{ color: 'red' }}>
																<ErrorMessage name='mobile' />
															</span>
														</Grid>
													</Grid>
													<Grid container item xs={6} alignItems='center'>
														<Grid container item xs={6} alignItems='center'>
															<Typography variant='subtitle1'>Jabatan</Typography>
														</Grid>
														<Grid container item xs={6} alignItems='center'>
															<Typography variant='body1'>
																{auth.data.data.job_title}
															</Typography>
														</Grid>
														<Grid container item xs={6} alignItems='center'>
															<Typography component='span' variant='subtitle1'>
																Role(s)
																<Button
																	onClick={handleClickOpen('paper')}
																	size='small'
																	className={classes.customTooltip}
																>
																	<HelpIcon className={classes.customIcon} />
																</Button>
																<RolesDialog
																	open={open}
																	handleClose={() => setOpen(false)}
																	scroll={scroll}
																/>
															</Typography>
														</Grid>
														<Grid container item xs={6} alignItems='center'>
															<Typography variant='body1'>{roles}</Typography>
														</Grid>
														{!showDepartemen ? (
															<Grid container item xs={6} alignItems='center'>
																<Typography variant='subtitle1'>&nbsp;</Typography>
															</Grid>
														) : (
															<>
																<Grid container item xs={6} alignItems='center'>
																	<Typography component='span' variant='subtitle1'>
																		Departemen Untuk Pembelian
																		<Button
																			onClick={handleClickOpen2('paper')}
																			size='small'
																			className={classes.customTooltip}
																		>
																			<HelpIcon className={classes.customIcon} />
																		</Button>
																		<DepartmentDialog
																			open={open2}
																			handleClose={() => setOpen2(false)}
																		/>
																	</Typography>
																</Grid>
																<Grid container item xs={6} alignItems='center'>
																	<Typography variant='body1'>
																		{auth.data.data.user_department.company_departments.name &&
																		auth.data.data.user_department.company_departments.name != ''
																			? auth.data.data.user_department.company_departments.name
																			: '-'}
																	</Typography>
																</Grid>
															</>
														)}
													</Grid>
												</Grid>
											</Box>
										</Paper>
									</Box>
									<Box mt={2}>
										<Grid container justify='flex-end'>
											<Box>
												<Button
													className={`${classes.buttonAction} ${classes.btnCancel}`}
													onClick={(event) => {
														event.preventDefault()
														navigate('/dashboard/profile'),
															{
																replace: true,
															}
													}}
												>
													Batal
												</Button>
												<Button
													type='submit'
													className={`${classes.buttonAction} ${classes.btnSave}`}
												>
													{responseData.isLoading ? (
														<CircularProgress size={28} />
													) : (
														'Simpan'
													)}
												</Button>
											</Box>
										</Grid>
									</Box>
								</Form>
							)
						}}
					</Formik>
				) : null}

				<CustomModal
					icon={notifSuccess ? 'success' : 'error'}
					open={modalNotif}
					onClose={() => setModalNotif(false)}
				>
					<Typography variant='h6'>
						{notifSuccess ? (
							<strong>Perbarui Akun Pengguna Berhasil</strong>
						) : (
							<strong>Perbarui Akun Pengguna Gagal</strong>
						)}
					</Typography>
					<Box
						display='flex'
						flexDirection='row'
						mt={3}
						justifyContent='center'
						// width={300}
					>
						<Button
							className={classes.buttonWidth}
							size='small'
							classes={{
								contained: classes.buttonDanger,
							}}
							variant='contained'
							onClick={() => handleModalOK()}
						>
							{' '}
							OK{' '}
						</Button>
					</Box>
				</CustomModal>
			</Container>
		</DashboardLayout>
	)
}

export default ProfileEdit
