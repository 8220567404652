import React from 'react'

import { Formik, Form, Field } from 'formik'
import {
	Typography,
	TextField,
	Button,
	FormControl,
	InputAdornment,
	IconButton,
	OutlinedInput,
	Grid,
	Collapse,
	Link,
	CircularProgress,
	Box,
} from '@material-ui/core'
import {
	Visibility,
	VisibilityOff,
	// HighlightOffOutlined,
} from '@material-ui/icons'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import schema from '../loginForm/schema'
import Alert from '../../alert'
import { navigate } from '@reach/router'
import { loginAction } from 'States/actions/authAction'
import Logounilever from '../../../assets/images/whitelabel/logo-unilever.png'
import padlock from '../../../assets/images/whitelabel/padlock2.svg'
import byMbiz from '../../../assets/images/svgs/logo.svg'

const handleNavigate = (path) => {
	navigate(path)
}

const PartnerLoginForm = ({ classes, poweredBy, formTitle, formTitleIcon, formLogo, errorPosition }) => {
	const dispatch = useDispatch()
	const [showPassword, setShowPassword] = React.useState(false)
	const data = useSelector((state) => state.auth, shallowEqual)

	const callback = React.useCallback(() => {
		setShowPassword(!showPassword)
	}, [showPassword])

	const handleSubmitLogin = (val) => {
		const myState = {
			user: val.username,
			pass: val.password,
			redirect: data.redirect,
		}
		dispatch(loginAction({ ...myState }))
	}

	return (
		<Formik
			enableReinitialize
			initialValues={{ username: '', password: '' }}
			validationSchema={schema}
			onSubmit={(values) => handleSubmitLogin(values)}
		>
			{({ errors, handleSubmit, touched }) => {
				// console.log(errors)
				return (
					<Form
						onSubmit={handleSubmit}
						method='post'
						className={[classes.form, classes.p_4]}
					>

						{formLogo &&
							<Box display='flex' justifyContent='center' mb={2}>
								<img src={formLogo} style={{ width: '30%' }}></img>
							</Box>
						}

						{formTitle ?
							<Box textAlign='left'>
								<Box display='inline-flex' alignItems='center'>
									{formTitleIcon &&
										<Box
											display='inline-flex'
											alignItems='center'
											className={classes.padlockCircle}
										>
											<img src={formTitleIcon} style={{ width: 30, height: 30 }}></img>
										</Box>
									}
									<Typography variant='subtitle1' className={classes.memberLogin}>
										{formTitle}
									</Typography>
								</Box>
							</Box>
							:
							formTitleIcon &&
							<Box
								display='inline-flex'
								alignItems='center'
								className={classes.padlockCircle}
							>
								<img src={formTitleIcon} style={{ width: 30, height: 30 }}></img>
							</Box>
						}
						{errorPosition == 'top' &&
							<Collapse
								in={
									(errors.username && touched.username) ||
									(errors.password && touched.password) ||
									data.isError
								}
							>
								<Alert variant={'alert'}>
									<Typography variant='body1' className={classes.alertText}>
										{(errors.username && touched.username && errors.username) ||
											(errors.password && touched.password && errors.password)}
									</Typography>
									<Typography variant='body1' className={classes.alertText}>
										{data.isError && 'Username atau Kata Sandi Salah'}
									</Typography>
								</Alert>
							</Collapse>
						}
						<Field name='username'>
							{({
								field, // { name, value, onChange, onBlur }
								// form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
							}) => (
								<TextField
									variant='outlined'
									fullWidth
									size='small'
									margin='dense'
									placeholder='Username'
									type='input'
									{...field}
									className={classes.form_input_shadow}
								/>
							)}
						</Field>

						<Field name='password'>
							{({ field }) => (
								<FormControl className={classes.password} variant='outlined'>
									<OutlinedInput
										id='outlined-adornment-password'
										type={showPassword ? 'text' : 'password'}
										placeholder='Kata Sandi'
										{...field}
										className={classes.form_input_shadow}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													edge='end'
													onClick={() => callback()}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							)}
						</Field>

						{errorPosition == 'bottom' &&
							<Box mt={2}>
								<Collapse
									in={
										(errors.username && touched.username) ||
										(errors.password && touched.password) ||
										data.isError
									}
								>
									<Alert variant={'alert'}>
										<Typography variant='body1' className={classes.alertText}>
											{(errors.username && touched.username && errors.username) ||
												(errors.password && touched.password && errors.password)}
										</Typography>
										<Typography variant='body1' className={classes.alertText}>
											{data.isError && 'Username atau Kata Sandi Salah'}
										</Typography>
									</Alert>
								</Collapse>
							</Box>
						}

						<Box mt={3}>
							<Grid container justify='space-between'>
								<Grid container item alignItems='flex-end' xs={6}>
									<Grid container justify='flex-start'>
										<Typography className={classes.forgotpass}>
											<Link
												className={classes.uri}
												onClick={() => handleNavigate('/forgot-password')}
											>
												Lupa Password?
											</Link>
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={3}>
									<Grid container justify='flex-end'>
										<Button
											size='large'
											type='submit'
											variant='contained'
											color='secondary'
											className={classes.formButton}
											disabled={data.isLoading}
										>
											{data.isLoading ? <CircularProgress /> : 'Login'}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Box>
						{poweredBy &&
							<Box className={classes.powered_by}>
								<Box mt={5} display='inline-flex'>
									<Typography variant='subtitle2'> Powered by</Typography>
									<img src={byMbiz} style={{ width: 130 }} alt='mbiz.co.id' />
								</Box>
							</Box>
						}
					</Form>
				)
			}}
		</Formik>
	)
}

PartnerLoginForm.propTypes = {
	classes: PropTypes.object,
}

export default PartnerLoginForm
