// React import
import React, { useEffect, useState } from 'react'

// External
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Pagination from '@material-ui/lab/Pagination'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

// Internal
import {
	requestBudgetHistory,
	resetAction as resetActionHistory,
} from 'States/actions/Budget/historyAction'

// Parent and sibling
import { detailBudgetStyle } from './styles'

const replaceLink = (message) => {
	message = message
		.replace('[[Quotation-LINK]]', '/dashboard/quotation')
		.replace('[[PR-LINK]]', '/dashboard/purchase-request')
		.replace('[[PO-LINK]]', '/dashboard/purchase-order')
	return message
}

const initDataHistory = {
	items: [],
	pagination: null,
}

const HistoryPagination = ({ budgetID }) => {
	const classes = detailBudgetStyle()
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const budgetHistory = useSelector((state) => state.budgetHistoryReducer)
	const [dataHistory, setDataHistory] = useState(initDataHistory)

	useEffect(() => {
		dispatch(requestBudgetHistory(budgetID, page))
		return () => {
			dispatch(resetActionHistory())
		}
	}, [page])

	useEffect(() => {
		if (budgetHistory.data) {
			let dataTemp = dataHistory
			dataTemp.items = budgetHistory.data.items || []
			dataTemp.pagination = budgetHistory.data.pagination
			setDataHistory(dataTemp)
		} else {
			setDataHistory({ ...initDataHistory })
			dispatch(resetActionHistory())
		}
	}, [budgetHistory.data])

	const handlePageChange = (event, value) => {
		setPage(value)
	}

	const printTableBody = () => {
		return dataHistory.items.map((item) => {
			return (
				<Box
					className={`${classes.detailBox} ${classes.historyBody}`}
					key={item.id}
				>
					<Box className={classes.rowBox}>
						<Typography className={classes.fontSize14}>
							{moment.utc(item.created_at).local().format('DD MMMM YYYY')}&emsp;
							{moment.utc(item.created_at).local().format('HH:mm')} WIB
						</Typography>
					</Box>
					<Box
						className={classes.historyDesc}
						dangerouslySetInnerHTML={{ __html: replaceLink(item.message) }}
					/>
				</Box>
			)
		})
	}

	return (
		<div>
			{/* History Section */}
			<Box my={2}>
				<Typography variant='h6'>Riwayat Anggaran</Typography>
			</Box>
			{/* List of History Section */}
			<Box my={2}>
				<Paper>
					<Box className={classes.historyBox}>
						<Box className={classes.historyHead}>
							<Box className={classes.rowBox}>
								<Typography variant='subtitle2'>Tanggal</Typography>
							</Box>
							<Box>
								<Typography variant='subtitle2'>Deskripsi</Typography>
							</Box>
						</Box>

						{budgetHistory.isLoading ? (
							<Box className={classes.historyHead}>
								<Box className={classes.rowBox}>
									<Typography variant='subtitle2'>
										<Skeleton variant='text' width={200} />
									</Typography>
								</Box>
								<Box>
									<Typography variant='subtitle2'>
										<Skeleton variant='text' width={500} />
									</Typography>
								</Box>
							</Box>
						) : printTableBody()}

						{!dataHistory.pagination ? null : dataHistory.pagination.count > 5 ? (
							<Box mt={2}>
								<Grid container justify='flex-end'>
									<Grid item>
										<Box className={classes.paginationBox}>
											<Typography className={classes.paginationWord}>
												Menampilkan {dataHistory.pagination.current * 5 - 5 + 1} -{' '}
												{dataHistory.pagination.total_records -
													dataHistory.pagination.current * 5 >
												0
													? dataHistory.pagination.current * 5
													: dataHistory.pagination.total_records}{' '}
												data dari {dataHistory.pagination.total_records}
											</Typography>
											<Pagination
												count={dataHistory.pagination.total_page}
												page={page}
												shape='rounded'
												color='secondary'
												variant='outlined'
												onChange={handlePageChange}
											/>
										</Box>
									</Grid>
								</Grid>
							</Box>
						) : null}
					</Box>
				</Paper>
			</Box>
		</div>
	)
}

HistoryPagination.propTypes = {
	budgetID: PropTypes.string,
}

export default HistoryPagination
