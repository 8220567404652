import * as Yup from 'yup'

const schema = Yup.object().shape({
	namaPembeli: Yup.string().required('Wajib diisi'),
	latarBelakang: Yup.string().required('Wajib diisi'),
	alamat: Yup.string().required('Wajib diisi'),
	location: Yup.object()
		.shape({
			id: Yup.number(),
			complete_name: Yup.string(),
			level: Yup.string(),
		})
		.required('Wajib diisi')
		.test(
			'is-location-correct',
			'Format data belum sesuai',
			(value) => value?.level === '4',
		)
		.nullable(),
	noTelp: Yup.string().required('Wajib diisi'),
	noPonsel: Yup.string(),
	child_ids: Yup.array().of(
		Yup.object().shape({
			id: Yup.number(),
			email: Yup.string().email('Email yang anda masukkan tidak sesuai'),
			jabatan: Yup.string(),
			name: Yup.string().required('Wajib diisi'),
			mobile: Yup.string().required('Wajib diisi'),
			phone: Yup.string(),
			action: Yup.string(),
		})
	),
})

export default schema
