import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import LayoutComponent from '../layout'
// import { navigate } from '@reach/router'
import Logo from '../../assets/images/whitelabel/logo-apl.png'
import Logomask from '../../assets/images/whitelabel/mask-apl.png'
import PropTypes from 'prop-types'

// const handleNavigate = path => {
// 	navigate(path)
// }

const LoginLayout = ({ children, classes, type = 'form' }) => {
	const classes_loginStyle = classes
	return (
		<LayoutComponent
			header={false}
			newsletter={false}
			className={classes_loginStyle.login_background}
			isFooter={false}
		>
			{type == 'form' ? (
				<Grid className={classes_loginStyle.form_login} container justify='center'>
					<Grid
						item
						xs={12}
						sm={4}
						className={`${classes_loginStyle.container_field} ${classes_loginStyle.radiusLeft}`}
					>
						{children}
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						className={`${classes_loginStyle.container_field} ${classes_loginStyle.radiusRight}`}
					>
						<div className={classes_loginStyle.container_content}>
							<img src={Logo} className={classes_loginStyle.apl_image} />
						</div>
						<div className={classes_loginStyle.container_content}>
							<img src={Logomask} className={classes_loginStyle.apl_image_mask} />
						</div>
					</Grid>
				</Grid>
			) : type == 'message' ? (
				<Paper className={classes_loginStyle.message_container} variant='outlined'>
					{children}
				</Paper>
			) : null}
		</LayoutComponent>
	)
}

LoginLayout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	classes: PropTypes.object,
	type: PropTypes.string,
}
export default LoginLayout
