import React from 'react'
import { Box, ClickAwayListener, Input } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ArrowDropDown } from '@material-ui/icons'
import Styles from './styles'

const Component = ({
	visible,
	value,
	children,
	onClick,
	onClickAway,
	error,
	onChange,
	inputName,
	placeholder,
	isReadOnly,
}) => {
	const classes = Styles({ error })
	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<Box position='relative'>
				<Box overflow='hidden' display='flex'>
					<Input
						name={inputName}
						fullWidth
						size='small'
						value={value}
						onChange={onChange}
						disableUnderline
						className={isReadOnly ? classes.inputSelectRead : classes.inputSelect}
						onClick={onClick}
						error={error}
						placeholder={placeholder}
						readOnly={isReadOnly ? true : false}
					/>
					<span className={classes.dropdownButton} onClick={onClick}>
						<ArrowDropDown />
					</span>
				</Box>
				<Box
					className={classes.dropdownList}
					display={visible ? 'block' : 'none'}
					zIndex={2}
				>
					{children}
				</Box>
			</Box>
		</ClickAwayListener>
	)
}

Component.propTypes = {
	value: PropTypes.string.isRequired,
	children: PropTypes.arrayOf(PropTypes.element),
	visible: PropTypes.bool.isRequired,
	onClick: PropTypes.func,
	onClickAway: PropTypes.func.isRequired,
	error: PropTypes.bool,
	onChange: PropTypes.func,
	inputName: PropTypes.string,
	placeholder: PropTypes.string,
	isReadOnly: PropTypes.bool,
}

Component.defaultProps = {
	error: false,
	placeholder: '',
	isReadOnly: false,
}
export default Component
