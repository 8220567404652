import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { checkIsAvetti } from 'Utilities/globalUtils'
import DashboardLayout from 'Components/dashboardLayout'
import IconButton from 'Components/iconButton'
import { decryptAES } from 'Utilities/cryptojs'
import {
	Box,
	Typography,
	Grid,
	Checkbox,
	Container,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	Button,
	Link,
} from '@material-ui/core'
import { getPrice } from 'States/actions/detailProductAction'
import { updateQuantity } from 'States/actions/detailProductAction'
import { cartStyles } from './styles'
import DeleteIcon from '@material-ui/icons/Delete'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import QuantityInput from 'Components/quantityInput'
import Modal from 'Components/modal'
import { Boxes, Handshake } from 'Components/svgs'
import { formatMoney } from '../../utils/formatMoney'
import Image from 'material-ui-image'
import {
	requestListCartItem,
	removeFromCartAction,
	updateCartAction,
	deleteAllItem,
} from 'States/actions/cartAction'
import CircularProgress from '@material-ui/core/CircularProgress'
import Breadcrumbs from 'Components/breadcrumbs'
import Slugify from 'slugify'
import Skeleton from '@material-ui/lab/Skeleton'

const imageStorage = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'

const maxValue = 9999
const minValue = 1

const Cart = () => {
	const dispatch = useDispatch()
	const classes = cartStyles()
	const [datas, setData] = useState([])
	const auth = useSelector((state) => state.auth)
	const [open, setOpen] = useState(false)
	const [quantityWarning, setQuantityWarning] = useState(false)
	const [checkedAll, setCheckedAll] = useState(true)
	const [confirmation, setConfirmation] = useState(false)
	const [indeterminate, setIndeterminate] = useState(false)
	const [grandTotal, setGrandTotal] = useState(0)
	const [totalChecked, setTotalChecked] = useState(0)
	const [deleteKey, setDeleteKey] = useState(0)
	const [submitDisabled, setSubmitDisabled] = useState(false)
	const [loadingData, setLoadingData] = useState(true)
	const [avettiItems, setAvettiItems] = useState([])
	const [callbackUrl, setCallbackUrl] = useState('')

	const isEmbeded = checkIsAvetti()

	React.useEffect(() => {
		dispatch(requestListCartItem())
	}, [])

	const handleCheckedAll = () => {
		let raw = datas
		raw.map((row) => {
			if (!row.has_been_edited) return (row.checked = !checkedAll)
		})
		setSubmitDisabled(checkedAll)
		setCheckedAll(!checkedAll)
		setIndeterminate(false)
		setData([...raw])
		// reCalculateTotal()
	}

	const handleChecked = (index) => {
		let raw = datas
		raw[index].checked = !raw[index].checked

		setData([...raw])
		checkChecked()
		// reCalculateTotal()
	}

	const checkChecked = () => {
		let countingChecked = 0
		datas.map((row) => {
			if (row.checked) {
				countingChecked++
			}
		})

		if (countingChecked == datas.length) {
			setCheckedAll(true)
			setIndeterminate(false)
			setSubmitDisabled(false)
		} else if (countingChecked > 0) {
			setCheckedAll(false)
			setIndeterminate(false)
			setSubmitDisabled(false)
		} else {
			setCheckedAll(false)
			setIndeterminate(false)
			setSubmitDisabled(true)
		}
	}

	const handleIncrease = (index, row) => {
		let raw = datas
		let newQuantity = raw[index].quantity + 1
		newQuantity = newQuantity || 1
		if (newQuantity > maxValue) {
			newQuantity = maxValue
		}
		reCalculate(index, newQuantity, row)
	}

	const handleDecrease = (index, row) => {
		let raw = datas
		let newQuantity = raw[index].quantity - 1
		newQuantity = newQuantity || 1
		if (newQuantity < minValue) {
			newQuantity = minValue
		}
		reCalculate(index, newQuantity, row)
	}

	const handleKey = (value, index, row) => {
		let raw = datas
		let newQuantity = value || raw[index].quantity
		newQuantity = parseInt(newQuantity)
		reCalculate(index, newQuantity, row)
	}

	const reCalculate = (index, newQuantity, row) => {
		let raw = datas
		let price = 0
		let error = {
			updateQty: null,
		}

		raw[index].quantity = newQuantity

		updateQuantity(
			parseInt(row.cart_id),
			parseInt(row.id),
			parseInt(newQuantity)
		).catch((err) => {
			error.updateQty = err.response
		})

		getPrice(
			parseInt(row.product_id),
			parseInt(auth.data.data.companies.partner_id),
			parseInt(row.variant_id),
			0,
			parseInt(newQuantity)
		).then((res) => {
			price = res.data.data.result.price
			price = price == 0 ? raw[index].price : price
			raw[index].price = price
			raw[index].total = price * (raw[index].quantity || 1)
			setData([...raw])
		})
		setData([...raw])
	}

	const reCalculateTotal = () => {
		let totalPrice = 0
		let totalCheck = 0
		datas.map((row) => {
			if (row.checked) {
				totalPrice += row.total
				totalCheck += 1
			}
		})
		setGrandTotal(totalPrice)
		setTotalChecked(totalCheck)
	}

	const buildData = (item) => {
		let has_been_edited =
			item.is_price_updated || item.is_product_deleted || item.is_stock_empty

		let varian = ''
		item.variant.product_template_attribute_value_ids.map(
			(attribute) =>
				(varian += varian == '' ? `${attribute.name}` : `, ${attribute.name}`)
		)

		let dataCreated = {
			checked: !has_been_edited,
			cart_id: item.cart_id,
			id: item.id,
			variant_id: item.sku_id,
			product_id: item.product_id,
			product_name: item.variant.name,
			product_variant: varian,
			product_sku_no: item.variant.sku_no,
			product_image: imageStorage + item.image_512_filestore,
			unit_of_measurement: item.measureType,
			product_price: item.variant.list_price,
			price: item.newPrice,
			quantity: item.qty,
			total: item.newPrice * item.qty,
			has_been_edited: has_been_edited,
			additional_style: has_been_edited ? classes.bgRed : [],
		}
		if (
			item.variant.image_512_filestore_variant != null &&
			item.variant.image_512_filestore_variant != false
		) {
			dataCreated.product_image =
				imageStorage + item.variant.image_512_filestore_variant
		}
		return dataCreated
	}

	const handleClose = () => {
		setOpen(false)
	}

	const confirmOpen = (index) => {
		setConfirmation(true)
		setDeleteKey(index)
	}

	const confirmClose = () => {
		setConfirmation(false)
	}

	const navigatePDP = (product_name, id, variant_id) => {
		const slug = Slugify(`${product_name} ${id} ${variant_id} `)
		navigate('/catalog/detail/' + slug)
	}

	const deleteItem = () => {
		setConfirmation(false)
		let raw = datas
		let id_to_delete = raw[deleteKey].id
		const filterDeleted = raw.filter((data) => data.id != raw[deleteKey].id)
		dispatch(removeFromCartAction(id_to_delete))
		setData(filterDeleted)
	}

	const handleSubmitAvetti = async (e) => {
		e.preventDefault()
		e.persist()
		e.target.action = callbackUrl
		let id_cart = listCartItem.filteredItems[0].cart_id
		let item_to_send = []
		let _avettiItems = []

		datas.map((row) => {
			if (row.checked) {
				item_to_send.push({
					id: row.id,
					qty: row.quantity,
				})
				_avettiItems.push(row)
			}
		})
		setAvettiItems(_avettiItems)
		await dispatch(updateCartAction(item_to_send, id_cart))
		await dispatch(deleteAllItem(id_cart))
		localStorage.removeItem('avetti')
		localStorage.removeItem('avetti-token')
		e.target.submit()
	}

	const updatingData = (byPass = false) => {
		let item_to_send = []
		let id_cart = listCartItem.filteredItems[0].cart_id
		let haveUpdated = false
		let haveZeroQuantity = false
		let _avettiItems = []
		datas.map((row) => {
			if (row.checked) {
				item_to_send.push({
					id: row.id,
					qty: row.quantity,
				})
				_avettiItems.push(row)
			}

			if (isNaN(row.quantity)) {
				haveZeroQuantity = true
			}

			if (row.has_been_edited) {
				haveUpdated = row.has_been_edited
			}
		})

		setAvettiItems(_avettiItems)

		if (haveZeroQuantity) {
			setQuantityWarning(true)
			// navigate('/dashboard/rfq')
		} else if (!haveUpdated || byPass) {
			dispatch(updateCartAction(item_to_send, id_cart))
			// navigate('/dashboard/rfq')
		} else {
			setOpen(true)
		}
	}

	const listCartItem = useSelector((state) => state.cart.listCart)
	const countCart = useSelector((state) => state.cart.countCart)

	React.useEffect(() => {
		if (datas.length > 0) {
			reCalculateTotal()
		}
	}, [datas])

	const generateData = async (data) => {
		let promises = data.filteredItems.map(async (row) => {
			//check if value not exist
			setLoadingData(true)
			const prices = await getPrice(
				parseInt(row.product_id),
				parseInt(auth.data.data.companies.partner_id),
				parseInt(row.variant.id),
				0,
				parseInt(row.qty)
			)
			let newRow = row
			if ('error_backend' in prices.data) {
				newRow.newPrice = 0
			} else {
				newRow.newPrice = prices.data.data.result.price
			}
			let dataCreated = buildData(newRow)
			return dataCreated
		})
		const getPrices = await Promise.all(promises)
		return getPrices
	}

	React.useEffect(() => {
		if (listCartItem.filteredItems.length) {
			generateData(listCartItem)
				.then((res) => {
					setData(res.filter((item) => item.price > 0))
					setLoadingData(false)
				})
				.catch(() => {
					setLoadingData(false)
					throw 'failed load data'
				})
		} else {
			setData([])
			setLoadingData(false)
		}
	}, [listCartItem.filteredItems])

	React.useEffect(() => {
		if (isEmbeded) {
			const avettiData = JSON.parse(decryptAES(localStorage.getItem('avetti')))
			const callbackurl = avettiData.callbackURL || avettiData.callbackUrl
			setCallbackUrl(callbackurl)
		}
	}, [isEmbeded])

	const loadContent = () => (
		<TableContainer>
			<Table>
				<TableHead className={classes.tableHead}>
					<TableRow>
						<TableCell align='center' className={classes.paddingTableHead}>
							<Checkbox
								onClick={() => handleCheckedAll()}
								checked={checkedAll}
								indeterminate={indeterminate}
								value='secondary'
								color='secondary'
								classes={{
									root: classes.checkboxRoot,
								}}
							/>
						</TableCell>
						<TableCell align='center' className={classes.paddingTableHead}>
							<Typography>
								<strong>No</strong>
							</Typography>
						</TableCell>
						<TableCell align='left' className={classes.paddingTableHead}>
							&nbsp;
						</TableCell>
						<TableCell align='left' className={classes.paddingTableHead}>
							<Typography>
								<strong>Produk</strong>
							</Typography>
						</TableCell>
						<TableCell align='center' className={classes.paddingTableHead}>
							<Typography>
								<strong>Unit</strong>
							</Typography>
						</TableCell>
						<TableCell align='left' className={classes.paddingTableHead}>
							<Typography>
								<strong>Harga</strong>
							</Typography>
						</TableCell>
						<TableCell align='center' className={classes.paddingTableHead}>
							<Typography>
								<strong>Kuantitas</strong>
							</Typography>
						</TableCell>
						<TableCell align='left' className={classes.paddingTableHead}>
							<Typography>
								<strong>Total Harga</strong>
							</Typography>
						</TableCell>
						<TableCell align='center' className={classes.paddingTableHead}>
							&nbsp;
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className={classes.tableBody}>
					{datas.map((row, key) => (
						<TableRow key={row.id} className={row.additional_style}>
							<TableCell
								className={classes.tableBodyCell}
								padding='checkbox'
								align='center'
							>
								<Checkbox
									disabled={row.has_been_edited}
									onClick={() => handleChecked(key)}
									checked={row.checked}
									value='secondary'
									color='secondary'
									classes={{
										root: classes.checkboxRoot,
									}}
								/>
							</TableCell>
							<TableCell className={classes.tableBodyCell} align='center'>
								<Typography>{key + 1}.</Typography>
							</TableCell>
							<TableCell className={classes.tableBodyCellImage} align='center'>
								<Image
									style={{
										paddingTop: 'calc(100%)',
										width: '60px',
										position: 'relative',
										display: 'inline-grid',
										backgroundColor: 'transparent',
									}}
									imageStyle={{
										maxWidth: '60px',
										maxHeight: '60px',
										width: 'auto',
										height: 'auto',
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
									}}
									src={row.product_image}
								/>
							</TableCell>
							<TableCell className={classes.tableBodyCellRelative} align='left'>
								<Box
									display='flex'
									flexDirection='column'
									justifyContent='space-between'
								>
									<Box>
										<Typography variant='body2'>{row.product_sku_no}</Typography>
										<Typography variant='caption'>
											<Link
												color='secondary'
												onClick={() =>
													navigatePDP(row.product_name, row.product_id, row.variant_id)
												}
											>
												{row.product_name}
											</Link>
										</Typography>
									</Box>
									<Typography variant='overline'>
										{row.product_variant
											? `varian: ${row.product_variant}`
											: ` ${'\u00A0'}`}
									</Typography>
									{row.has_been_edited ? (
										<Box className={classes.bottomFloat}>
											<Typography variant='overline' className={classes.typoSetting}>
												<ErrorOutlineIcon className={classes.floatingIcon} />
												&nbsp;&nbsp;Produk tidak tersedia
											</Typography>
										</Box>
									) : (
										''
									)}
								</Box>
							</TableCell>
							<TableCell className={classes.tableBodyCell} align='center'>
								<Typography>{row.unit_of_measurement}</Typography>
							</TableCell>
							<TableCell className={classes.tableBodyCell} align='left'>
								<Typography>{formatMoney(row.price)}</Typography>
							</TableCell>
							<TableCell className={classes.tableBodyCellRight} align='center'>
								<QuantityInput
									onDecrease={() => handleDecrease(key, row)}
									onIncrease={() => handleIncrease(key, row)}
									onInputChange={(value) => handleKey(value, key, row)}
									value={row.quantity}
								></QuantityInput>
							</TableCell>
							<TableCell className={classes.tableBodyCellRight} align='left'>
								<Typography>{formatMoney(row.total)}</Typography>
							</TableCell>
							<TableCell
								className={classes.tableBodyCell}
								padding='checkbox'
								align='center'
							>
								<IconButton
									variant='error'
									title='Hapus'
									size='small'
									onClick={() => confirmOpen(key)}
								>
									<DeleteIcon fontSize='small' />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter className={classes.tableFooter}>
					<TableRow>
						<TableCell colSpan={7} align='right'>
							<Typography variant='body1'>
								<strong>TOTAL</strong>
							</Typography>
							<br />
							<Box component='span' className={classes.noPaddingTop}>
								<Typography variant='body2'>({totalChecked} item dipilih)</Typography>
							</Box>
						</TableCell>
						<TableCell colSpan={2}>
							<Typography variant='body1'>
								<strong>{formatMoney(grandTotal)}</strong>
							</Typography>
							<br />
							<Box component='span' className={classes.noPaddingTop}>
								&nbsp;
							</Box>
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
			<form method='POST' onSubmit={handleSubmitAvetti}>
				{avettiItems.map((data, key) => (
					<>
						<input
							type='hidden'
							name={`items[${key}].itemCode`}
							value={data.product_sku_no}
						/>
						<input
							type='hidden'
							name={`items[${key}].itemQuantity`}
							value={data.quantity}
						/>
						<input
							type='hidden'
							name={`items[${key}].unitPrice`}
							value={data.price}
						/>
						<input
							type='hidden'
							name={`items[${key}].itemDescription`}
							value={data.product_name}
						/>
						<input
							type='hidden'
							name={`items[${key}].descriptionLanguage`}
							value='ID'
						/>
						<input type='hidden' name={`items[${key}].currencyName`} value='IDR' />
						<input type='hidden' name={`items[${key}].unitOfMeasure`} value='EA' />
						<input
							type='hidden'
							name={`items[${key}].supplierBasketLineNumber`}
							value={key}
						/>
					</>
				))}
				<Box display='flex' flexDirection='column' justifyContent='flex-end'>
					<Box textAlign='right' pb={2}>
						<Typography variant='caption'>
							*Harga yang tertera belum termasuk pajak
						</Typography>
					</Box>
					<Box textAlign='right'>
						<Button
							id='btn-create-rfq'
							disabled={submitDisabled}
							onClick={() => (!isEmbeded ? updatingData() : null)}
							variant='contained'
							color='secondary'
							size='small'
							type={isEmbeded ? 'submit' : 'button'}
						>
							{isEmbeded ? 'Submit' : 'Buat RFQ'} &nbsp;&nbsp;
							<Handshake />
						</Button>
					</Box>
				</Box>
			</form>
		</TableContainer>
	)

	const loadingSkelton = () => (
		<TableContainer>
			<Table>
				<TableHead className={classes.tableHead}>
					<TableRow>
						<TableCell align='center' className={classes.paddingTableHead}>
							<Checkbox
								onClick={() => handleCheckedAll()}
								checked={checkedAll}
								indeterminate={indeterminate}
								value='secondary'
								color='secondary'
								classes={{
									root: classes.checkboxRoot,
								}}
							/>
						</TableCell>
						<TableCell align='center' className={classes.paddingTableHead}>
							<Typography>
								<strong>No</strong>
							</Typography>
						</TableCell>
						<TableCell align='left' className={classes.paddingTableHead}>
							&nbsp;
						</TableCell>
						<TableCell align='left' className={classes.paddingTableHead}>
							<Typography>
								<strong>Produk</strong>
							</Typography>
						</TableCell>
						<TableCell align='center' className={classes.paddingTableHead}>
							<Typography>
								<strong>Unit</strong>
							</Typography>
						</TableCell>
						<TableCell align='left' className={classes.paddingTableHead}>
							<Typography>
								<strong>Harga</strong>
							</Typography>
						</TableCell>
						<TableCell align='center' className={classes.paddingTableHead}>
							<Typography>
								<strong>Kuantitas</strong>
							</Typography>
						</TableCell>
						<TableCell align='left' className={classes.paddingTableHead}>
							<Typography>
								<strong>Total Harga</strong>
							</Typography>
						</TableCell>
						<TableCell align='center' className={classes.paddingTableHead}>
							&nbsp;
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className={classes.tableBody}>
					<TableRow>
						<TableCell
							className={classes.tableBodyCell}
							padding='checkbox'
							align='center'
						>
							<Skeleton variant='text' />
						</TableCell>
						<TableCell className={classes.tableBodyCell} align='center'>
							<Skeleton variant='text' />
						</TableCell>
						<TableCell className={classes.tableBodyCellImage} align='center'>
							<Box display='flex' flexDirection='row'>
								<Skeleton variant='rect' width={60} height={60} />
							</Box>
						</TableCell>
						<TableCell className={classes.tableBodyCellImage} align='center'>
							<Box
								ml={1}
								display='flex'
								flexDirection='column'
								justifyContent='space-between'
								style={{ width: '100%' }}
							>
								<Box>
									<Typography variant='body2'>
										<Skeleton variant='text' />
									</Typography>
									<Typography variant='caption'>
										<Skeleton variant='text' />
									</Typography>
								</Box>
								<Typography variant='caption'>
									<Skeleton variant='text' />
								</Typography>
							</Box>
						</TableCell>
						<TableCell className={classes.tableBodyCell} align='center'>
							<Skeleton variant='text' />
						</TableCell>
						<TableCell className={classes.tableBodyCell} align='left'>
							<Skeleton variant='text' />
						</TableCell>
						<TableCell className={classes.tableBodyCellRight} align='center'>
							<Skeleton variant='text' />
						</TableCell>
						<TableCell className={classes.tableBodyCellRight} align='left'>
							<Skeleton variant='text' />
						</TableCell>
					</TableRow>
				</TableBody>
				<TableFooter className={classes.tableFooter}>
					<TableRow>
						<TableCell colSpan={7} align='right'>
							<Typography variant='body1'>
								<strong>TOTAL</strong>
							</Typography>
							<br />
							<Box component='span' className={classes.noPaddingTop}>
								<Skeleton variant='text' />
							</Box>
						</TableCell>
						<TableCell colSpan={2}>
							<Skeleton variant='text' />
							<br />
							<Box component='span' className={classes.noPaddingTop}>
								&nbsp;
							</Box>
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)

	const noContent = () => (
		<Box className={classes.textCenter} py={10}>
			<Boxes />
			<Typography variant='h6' id='label-cart-title'>
				Keranjang Kosong
			</Typography>
		</Box>
	)

	const isHaveData = () => {
		if (datas.length > 0) {
			return loadContent()
		} else {
			return noContent()
		}
	}
	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Keranjang Belanja',
			link: '#',
		},
	]

	return (
		<DashboardLayout>
			<Container styles='margin: 0px;'>
				<Box className={classes.paddingBottom1}>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>
				<Box className={classes.paddingBottom2}>
					<Typography variant='h6'>
						Keranjang Belanja ({countCart.cartQty})
					</Typography>
				</Box>

				<Modal
					open={open}
					onClose={() => handleClose()}
					icon='question'
					disableBackdropClick
				>
					<Typography variant='h6'>
						Terdapat produk yang tidak tersedia pada keranjang ini,
						<br />
						lanjutkan pembuatan RFQ ?
					</Typography>
					<Typography variant='body1'>
						Produk yang tidak tersedia tidak akan diproses menjadi RFQ
					</Typography>
					<Grid className={classes.paddingTop}>
						<Button
							className={classes.buttonRed}
							onClick={() => handleClose()}
							variant='contained'
							size='small'
						>
							Batal
						</Button>
						&nbsp;&nbsp;&nbsp;
						<Button
							color='secondary'
							onClick={() => updatingData(true)}
							variant='contained'
							size='small'
						>
							Ya, Lanjut
						</Button>
					</Grid>
				</Modal>
				<Modal open={listCartItem.showModal} icon='none' disableBackdropClick>
					<Grid>
						<CircularProgress color='secondary' />
						<Typography>Mohon Tunggu</Typography>
					</Grid>
				</Modal>
				<Modal
					open={confirmation}
					onClose={() => confirmClose()}
					icon='question'
					disableBackdropClick
				>
					<Typography variant='h6'>
						Apakah anda yakin akan menghapus item ini?
					</Typography>
					<Grid className={classes.paddingTop}>
						<Button
							onClick={() => confirmClose()}
							color='secondary'
							variant='outlined'
							size='small'
						>
							Batal
						</Button>
						&nbsp;&nbsp;&nbsp;
						<Button
							onClick={() => deleteItem()}
							className={classes.buttonRed}
							variant='contained'
							size='small'
						>
							Ya, Hapus
						</Button>
					</Grid>
				</Modal>
				<Modal open={quantityWarning} icon='warning' disableBackdropClick>
					<Typography variant='h6'>
						Terdapat produk yang tidak memiliki kuantitas, <br />
						silakan isi kuantitas terlebih dahulu.
					</Typography>
					<Grid className={classes.paddingTop}>
						<Button
							onClick={() => {
								setQuantityWarning(false)
							}}
							color='secondary'
							variant='outlined'
							size='small'
						>
							Tutup
						</Button>
					</Grid>
				</Modal>
				<Box className={classes.cardWhite}>
					{loadingData ? loadingSkelton() : isHaveData()}
				</Box>
			</Container>
		</DashboardLayout>
	)
}

export default Cart
