import { BANK_UPDATE } from '../../types'
import API from '../../../utils/api'
import { navigate } from 'gatsby'
import { clearDeleteBank } from './deleteAction'
import { clearCreateBank } from './createAction'

export const resetAction = () => {
	return {
		type: `${BANK_UPDATE}_RESET`,
	}
}

const loading = () => {
	return {
		type: `${BANK_UPDATE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${BANK_UPDATE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${BANK_UPDATE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const loadingData = () => {
	return {
		type: `${BANK_UPDATE}_LOAD_DATA`,
	}
}

const successGetData = (data) => {
	return {
		type: `${BANK_UPDATE}_DATA`,
		payload: {
			...data,
		},
	}
}

export const clearUpdateBank = () => {
	return {
		type: `${BANK_UPDATE}_CLEAR`,
	}
}

export const updateBank = ({
	id,
	acc_number,
	bank_id,
	branch,
	acc_holder_name,
}) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			const response = await API({
				method: 'PUT',
				url: `/master/bank/update/${id}`,
				data: { acc_number, bank_id, branch, acc_holder_name },
			})

			if ('error_backend' in response.data) {
				dispatch(failure(response.data.error_backend.http_body))
			} else {
				dispatch(success(response.data))
				dispatch(clearDeleteBank())
				dispatch(clearCreateBank())
				navigate('/dashboard/bank-account')
			}
		} catch (err) {
			dispatch(failure(err))
		}
	}
}

export const detailBank = (id) => {
	return async (dispatch) => {
		dispatch(loadingData())
		try {
			const response = await API({
				method: 'GET',
				url: `/master/bank/detail/${id}`,
			})

			dispatch(successGetData(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
