import React from 'react'
import LayoutErrorPage from '../../../components/layoutErrorPage'
import LinkExpired from '../../../assets/images/content/TautanSudahTidakTersedia.png'
import { Typography, Box } from '@material-ui/core'

const LinkNotAvailable = () => {
	return (
		<LayoutErrorPage variant='light'>
			<Box>
				<img src={LinkExpired} style={{ width: '40%' }} />
				<Box mt={2} mb={5}>
					<Typography variant='h6'>
						<b>Tautan yang Anda tuju sudah tidak tersedia</b>
					</Typography>
					<Typography variant='body1' style={{ cursor: 'pointer' }}>
						Silakan cek kotak masuk pada email Anda dan pilih tautan terbaru yang Anda
						terima.
					</Typography>
				</Box>
			</Box>
		</LayoutErrorPage>
	)
}

export default LinkNotAvailable
