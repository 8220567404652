import { DETAIL_PRODUCT, DETAIL_PRICE } from '../types'
import { success, failure, loading } from '../commonActions'
import API from '../../utils/api'
import { navigate } from 'gatsby'

export const resetAction = () => {
	return {
		type: `${DETAIL_PRODUCT}_RESET`,
	}
}

const loadPrice = (idProduct, idVariant, qty) => {
	let variant_id = idVariant
	if (!variant_id) {
		variant_id = 0
	}
	return async (dispatch, getState) => {
		const { data } = getState().auth
		if (!data) {
			dispatch(loading(DETAIL_PRICE))
			try {
				const res = await getPrice(
					parseInt(idProduct),
					0,
					parseInt(variant_id),
					0,
					qty
				)
				dispatch(success(DETAIL_PRICE, res.data))
			} catch (err) {
				dispatch(failure(DETAIL_PRICE, err))
			}
		} else {
			dispatch(loading(DETAIL_PRICE))
			try {
				const res = await getPrice(
					parseInt(idProduct),
					parseInt(data.data.companies.partner_id),
					parseInt(variant_id),
					0,
					parseInt(qty)
				)
				dispatch(success(DETAIL_PRICE, res.data))
			} catch (err) {
				dispatch(failure(DETAIL_PRICE, err))
			}
		}
	}
}

export const getPrice = (
	product_template_id,
	partner_id = 0,
	product_id = 0,
	pricelist_id = 0,
	add_qty = 1
) => {
	return API({
		method: 'POST',
		url: '/price-list/product',
		data: {
			partner_id,
			pricelist_id,
			add_qty,
			product_id,
			product_template_id,
		},
	})
}

export const requestDetailProduct = (idProduct) => {
	return async (dispatch) => {
		dispatch(loading(DETAIL_PRODUCT))
		try {
			const response = await API({
				method: 'GET',
				url: `/catalogs/details/${idProduct}?ts=${new Date().getTime()}`,
			})
			if (response.data && response.data.error_backend) {
				navigate('/404')
			} else if (
				response.data &&
				(response.data.message || response.data.data == null)
			) {
				navigate('/404')
			} else dispatch(success(DETAIL_PRODUCT, response))
			// dispatch(loadPrice(idProduct, idVariant, 1))
		} catch (err) {
			dispatch(failure(DETAIL_PRODUCT))
		}
	}
}

export const calculatePdpPrice = (idProduct, idVariant, qty) =>
	loadPrice(idProduct, idVariant, qty)

export const updateQuantity = (idCart, idCartItem, qty) => {
	return API({
		method: 'PUT',
		url: `/carts/update/${idCart}/${idCartItem}`,
		data: {
			qty
		},
	})
}