import { CONTACT_US } from '../../types'
import API from '../../../utils/api'
import { success, failure, loading } from '../../commonActions'

export const resetAction = () => {
	return {
		type: `${CONTACT_US}_RESET`,
	}
}

export const contactUsMailAction = ({
	email,
	message_content,
	message_title,
	name,
}) => {
	return async (dispatch) => {
		dispatch(loading(CONTACT_US))
		try {
			const response = await API({
				method: 'POST',
				url: '/contact-us',
				data: {
					email,
					message_content,
					message_title,
					name,
				},
			})
			if (response) {
				if (response.data.error_backend) {
					let error = {}
					error.err = response.data.error_backend
					dispatch(failure(CONTACT_US, error))
				} else {
					dispatch(success(CONTACT_US, response.data))
				}
			}
		} catch (err) {
			err.http_body
				? dispatch(failure(CONTACT_US, err.http_body))
				: dispatch(failure(CONTACT_US, err.http_body))
		}
	}
}
