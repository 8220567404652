import React from 'react'
import PropTypes from 'prop-types'

const ProgressBar = ({ progress, bgcolor, height, isUseText }) => {
	const containerStyles = {
		height: height ? height : 10,
		width: '100%',
		backgroundColor: '#ffffff',
		borderRadius: 2,
		border: '1px solid #BBBBBB',
	}

	const fillerStyles = {
		height: '100%',
		width: `${progress ? progress : 0}%`,
		backgroundColor: bgcolor ? bgcolor : '#009000',
		borderRadius: 'inherit',
		textAlign: 'right',
	}

	const labelStyles = {
		padding: 5,
		color: 'white',
		fontWeight: 'bold',
		fontSize: '10px',
	}

	return (
		<div style={containerStyles}>
			<div style={fillerStyles}>
				{isUseText ? (
					<span style={labelStyles}>{`${Math.round(progress, 2)}%`}</span>
				) : null}
			</div>
		</div>
	)
}

ProgressBar.propTypes = {
	progress: PropTypes.number,
	bgcolor: PropTypes.string,
	height: PropTypes.number,
	isUseText: PropTypes.bool,
}

export default ProgressBar
