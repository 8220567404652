/* eslint-disable react/display-name */
import React from 'react'
import LayoutComponent from 'Components/layout'
import MenuCategory from 'Components/menu_category'
import { homepageStyle } from './styles'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import {
	Bars,
	Refresh,
	Account,
	AccountLogin,
	BusinessTime,
	Search,
} from 'Components/svgs'
import { Box, Typography, Container, Grid } from '@material-ui/core'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { withStyles } from '@material-ui/styles'
import useScript from '../../hooks/script'

const GlobalCss = withStyles({
	'@global': {
		'.sliderContainer .image-gallery-content .image-gallery-slide .image-gallery-image':
		{
			maxHeight: '400px',
		},
	},
})(() => null)

const sliderOpts = {
	dots: false,
	infinite: false,
	slidesToShow: 6,
	slidesToScroll: 2,
	nextArrow: <Arrow />,
	prevArrow: <Arrow />,
	responsive: [
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
}

const mainSliderOpts = {
	dots: true,
	infinite: true,
	arrows: true,
	speed: 1000,
	autoplay: true,
	autoplaySpeed: 5000,
	slidesToShow: 1,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
	appendDots: (dots) => <ul className='yoman'>{dots}</ul>,
	customPaging: () => <div className='custom-dots'></div>,
}

function Arrow(props) {
	// eslint-disable-next-line react/prop-types
	const { className, style, onClick } = props
	return (
		<div
			className={className}
			style={{
				...style,
				boxShadow: '0.5px 1px 2px rgba(0,0,0, 0.2)',
				borderRadius: 50,
				padding: 5,
				width: 'auto',
				height: 'auto',
				color: '#343A40',
			}}
			onClick={onClick}
		/>
	)
}

const HomePage = () => {
	// on whitelabel sudomain, if not logged in then redirect to login page
	const isLoggedIn = useSelector((state) => state.auth.loggedIn)
	let arrDomain =
		typeof window !== 'undefined' ? window.location.hostname.split('.') : ''
	const subdomains = ['apl', 'combiphar', 'sampoerna', 'unilever']
	if (subdomains.includes(arrDomain[0]) && !isLoggedIn) {
		navigate('/login')
		return null
	}
	const classes = homepageStyle()
	const listCategory = useSelector((state) => state.listFilterCategoryReducer)
	useScript('/scripts/pugna.js')

	return (
		<LayoutComponent withMedia>
			<GlobalCss />
			<div className={classes.banner}>
				<div className={classes.bannerSlider}>
					<Grid container>
						<Grid item sm={12} md={12} xs={12}>
							<Slider {...mainSliderOpts}>
								<div className={classes.sliderItem}>
									<ins
										data-revive-zoneid='151'
										data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
									></ins>
								</div>
								<div className={classes.sliderItem}>
									<ins
										data-revive-zoneid='152'
										data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
									></ins>
								</div>
								<div className={classes.sliderItem}>
									<ins
										data-revive-zoneid='153'
										data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
									></ins>
								</div>
								<div className={classes.sliderItem}>
									<ins
										data-revive-zoneid='154'
										data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
									></ins>
								</div>
								<div className={classes.sliderItem}>
									<ins
										data-revive-zoneid='155'
										data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
									></ins>
								</div>
								<div className={classes.sliderItem}>
									<ins
										data-revive-zoneid='156'
										data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
									></ins>
								</div>
							</Slider>
						</Grid>
					</Grid>
				</div>
				<div className={classes.bannerSmall}>
					<ins
						data-revive-zoneid='157'
						data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
					></ins>
				</div>
				<div className={classes.bannerSmall}>
					<ins
						data-revive-zoneid='158'
						data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
					></ins>
				</div>
			</div>
			{listCategory.isLoading == false && listCategory.data ? (
				<MenuCategory categories={listCategory.data ?? []} />
			) : null}

			{/* promo */}
			<Box className={classes.promoContainer}>
				<div className={classes.promoImageContainer}>
					<ins
						data-revive-zoneid='159'
						data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
					></ins>
				</div>
				<div className={classes.promoImageContainer}>
					<ins
						data-revive-zoneid='160'
						data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
					></ins>
				</div>
				<div className={classes.promoImageContainer}>
					<ins
						data-revive-zoneid='161'
						data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
					></ins>
				</div>
			</Box>
			{/* end promo */}

			{/* partner */}
			<Box marginTop={2.5} className={classes.partnerContainer}>
				<Typography variant='h6' align='center' className={classes.titlePartner}>
					Mitra Yang Sudah Bergabung
				</Typography>
				<Container>
					<Slider {...sliderOpts}>
						<div className={classes.sliderItem}>
							<ins
								data-revive-zoneid='162'
								data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
							></ins>
						</div>
						<div className={classes.sliderItem}>
							<ins
								data-revive-zoneid='163'
								data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
							></ins>
						</div>
						<div className={classes.sliderItem}>
							<ins
								data-revive-zoneid='164'
								data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
							></ins>
						</div>
						<div className={classes.sliderItem}>
							<ins
								data-revive-zoneid='165'
								data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
							></ins>
						</div>
						<div className={classes.sliderItem}>
							<ins
								data-revive-zoneid='166'
								data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
							></ins>
						</div>
						<div className={classes.sliderItem}>
							<ins
								data-revive-zoneid='167'
								data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
							></ins>
						</div>
						<div className={classes.sliderItem}>
							<ins
								data-revive-zoneid='168'
								data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
							></ins>
						</div>
						<div className={classes.sliderItem}>
							<ins
								data-revive-zoneid='169'
								data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
							></ins>
						</div>
					</Slider>
				</Container>
			</Box>
			{/* end partner */}

			{/* visi misi */}
			<Box marginTop={2.5} padding={2} style={{ backgroundColor: '#eee' }}>
				<Typography variant='h6' align='center'>
					Mengapa Menggunakan Mbiz.co.id
				</Typography>
				<Grid
					className={classes.containerVisi}
					container
					alignItems='center'
					justify='center'
					item
					lg={9}
				>
					<Grid
						item
						container
						className='item'
						alignItems='center'
						justify='center'
						lg={4}
						direction='column'
					>
						<div className={classes.iconVisi}>
							<BusinessTime width={70} height={50} />
						</div>
						<Typography variant='body1' align='center'>
							<strong>Efektif dan Efisien</strong>
						</Typography>
						<Typography variant='caption' className={classes.visiText}>
							Satu platform untuk proses pengadaan menyeluruh. Menghemat waktu, biaya
							dan tenaga.
						</Typography>
					</Grid>
					<Grid
						item
						container
						className='item'
						alignItems='center'
						justify='center'
						lg={4}
						direction='column'
					>
						<div className={classes.iconVisi}>
							<Bars width={70} height={50} />
						</div>
						<Typography variant='body1' align='center'>
							<strong>Terlengkapi</strong>
						</Typography>
						<Typography variant='caption' className={classes.visiText}>
							Tersedia lebih dari 100.000 barang atau jasa dengan penawaran
							perbandingan harga kompetitif.
						</Typography>
					</Grid>
					<Grid
						item
						container
						className='item'
						alignItems='center'
						justify='center'
						lg={4}
						direction='column'
					>
						<div className={classes.iconVisi}>
							<Search width={70} height={50} />
						</div>
						<Typography variant='body1' align='center'>
							<strong>Transparan</strong>
						</Typography>
						<Typography variant='caption' className={classes.visiText}>
							Semua transaksi tercatat didalam sistem dan dapat ditarik kapanpun.
						</Typography>
					</Grid>
					<Grid
						item
						container
						className='item'
						alignItems='center'
						justify='center'
						lg={4}
						direction='column'
					>
						<div className={classes.iconVisi}>
							<AccountLogin width={70} height={50} />
						</div>
						<Typography variant='body1' align='center'>
							<strong>Mudah</strong>
						</Typography>
						<Typography variant='caption' className={classes.visiText}>
							Tanpa instalasi software dan dapat diakses dimanapun dan kapanpun.
						</Typography>
					</Grid>
					<Grid
						item
						container
						className='item'
						alignItems='center'
						justify='center'
						lg={4}
						direction='column'
					>
						<div className={classes.iconVisi}>
							<Refresh width={70} height={70} />
						</div>
						<Typography variant='body1' align='center'>
							<strong>Terpadu & Terintegrasi</strong>
						</Typography>
						<Typography variant='caption' className={classes.visiText}>
							Alur pengadaan sesuai dengan alur bisnis anda
						</Typography>
					</Grid>
					<Grid
						item
						container
						className='item'
						alignItems='center'
						justify='center'
						lg={4}
						direction='column'
					>
						<div className={classes.iconVisi}>
							<Account width={70} height={50} />
						</div>
						<Typography variant='body1' align='center'>
							<strong>Terkontrol</strong>
						</Typography>
						<Typography variant='caption' className={classes.visiText}>
							Pilihan pembayaran berjangka, kontrol anggaran belanja dan akses pinjaman
							modal*
						</Typography>
					</Grid>
				</Grid>
			</Box>
			{/* end visi misi */}

			{/* How to */}
			<Box display='grid' marginY={2.5}>
				<Grid container spacing={1}>
					<Grid item lg={6} md={12} sm={12} xs={12} className={classes.containerImg}>
						<ins
							data-revive-zoneid='175'
							data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
						></ins>
					</Grid>
					<Grid item lg={3} md={6} sm={6} xs={6} className={classes.containerImg}>
						<ins
							data-revive-zoneid='176'
							data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
						></ins>
					</Grid>
					<Grid item lg={3} md={6} sm={6} xs={6} className={classes.containerImg}>
						<ins
							data-revive-zoneid='177'
							data-revive-id='234a1ee0e0cd10b0d31a71c3336d92ea'
						></ins>
					</Grid>
				</Grid>
			</Box>
			{/* How to end */}
		</LayoutComponent>
	)
}

export default HomePage
