import React from 'react'
import { Breadcrumbs, Link } from '@material-ui/core'
import { House } from '@material-ui/icons'
import styles from './styles'
import PropTypes from 'prop-types'

const Component = ({ breadcrumbs }) => {
	const classes = styles()
	return (
		<Breadcrumbs
			maxItems={10}
			className={classes.container}
			aria-label='breadcrumb'
		>
			{breadcrumbs.map((value, i) => {
				return (
					<Link href={value.link} className={classes.link} key={i}>
						{i == 0 && value.segment == 'Home' ? (
							<House className={classes.icon} />
						) : (
							''
						)}
						{i != 0 && value.segment != '' ? value.segment : ''}
					</Link>
				)
			})}
		</Breadcrumbs>
	)
}

Component.propTypes = {
	breadcrumbs: PropTypes.arrayOf(
		PropTypes.shape({
			segment: PropTypes.string,
			icon: PropTypes.string,
			link: PropTypes.string,
		})
	),
}

Component.defaultProps = {
	breadcrumbs: [
		{
			segment: 'Home',
			link: '/',
		},
	],
}

export default Component
