import { REGISTER, REGISTER_NEW, LOGOUT } from '../types'
import { navigate } from '@reach/router'
import API from '../../utils/api'

export const registerAction = (data) => {
	return async (dispatch) => {
		try {
			const response = await dispatch({
				type: REGISTER,
				payload: API({
					method: 'POST',
					url: '/users/create',
					data: { ...data },
				}),
			})
			if (response) {
				if (response.value.data.error_backend) {
					let errMsg = response.value.data.error_backend.http_body
					let errObj = JSON.parse(errMsg)
					throw new Error(errObj.error.message)
				}
				navigate('/login')
			}
		} catch (err) {
			dispatch({
				type: `${REGISTER}_FAILED`,
				err,
			})
		}
	}
}

export const registerNewUserAction = ({
	username,
	password,
	confirm_password,
	email,
	token_confirmation,
	contact_id,
}) => {
	return async (dispatch) => {
		try {
			const response = await dispatch({
				type: REGISTER_NEW,
				payload: API({
					method: 'POST',
					url: '/users/create',
					data: {
						username,
						password,
						confirm_password,
						email,
						token_confirmation,
						contact_id,
					},
				}),
			})
			if (response) {
				navigate('/login')
			}
		} catch (err) {
			dispatch({
				type: `${REGISTER_NEW}_FAILED`,
				err,
			})
		}
	}
}

export const logoutAction = () => {
	return (dispatch) => {
		navigate('/login')
		dispatch({
			type: LOGOUT,
		})
	}
}
