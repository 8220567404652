import { PURCHASE_ORDER_LIST } from '../../../types'
import API from '../../../../utils/api'
import { newUriAPI } from '../../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${PURCHASE_ORDER_LIST}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${PURCHASE_ORDER_LIST}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${PURCHASE_ORDER_LIST}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestPOList = ({ queryString }) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			let url = ''
			if (queryString) url = newUriAPI(queryString)

			let response = await API({
				method: 'GET',
				url: '/purchase-orders/purchase-orders/list' + (url != '' ? '?' + url : ''),
			})

			dispatch(success(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
