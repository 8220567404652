import React from 'react'
import { elipsisString } from './generaHelper'
import { sortData } from '../../../../utils/globalUtils'
import { Tooltip } from '@material-ui/core'

export const dataTable = {
	name: '',
	selector: '',
	sortable: false,
	key: '',
	format: '',
	label: '',
}

export const loadDataColumnTable = (header) => {
	let columns = []
	header = sortData(header)
	header && header.length != 0
		? header.forEach((temp) => {
				let tempDataTable = { ...dataTable }
				tempDataTable.name = temp.label
				tempDataTable.selector = temp.value
				tempDataTable.key = temp.value
				tempDataTable.minWidth = '200px'
				tempDataTable.format = (row) => elipsisString(row[temp.value], 25)
				tempDataTable.cell = (row) =>
					row[temp.value]?.length > 25 ? (
						<Tooltip title={row[temp.value]} placement='bottom'>
							<div>{elipsisString(row[temp.value], 25)}</div>
						</Tooltip>
					) : (
						<div>{row[temp.value]}</div>
					)
				columns.push(tempDataTable)
		  })
		: null
	return columns
}

export const loadNameFile = (query) => {
	let res = ''
	if (query == 'rfqs') {
		res = 'Daftar RFQ'
	} else if (query == 'quotations') {
		res = 'Daftar Quotation'
	} else if (query == 'purchase-requests') {
		res = 'Daftar Purchase Request'
	} else if (query == 'purchase-orders') {
		res = 'Daftar Purchase Order'
	} else if (query == 'invoices') {
		res = 'Daftar Tagihan'
	} else if (query == 'payments') {
		res = 'Daftar Pembayaran'
	}
	return res
}

export const defaultMandatorySelect = (query) => {
	switch (query) {
		case 'rfqs':
			return [
				{
					label: 'No. RFQ',
					value: 'rfq_no',
					format: '',
					depth: false,
					index: 1,
				},
				{
					label: 'Nomor SKU',
					value: 'sku_no',
					format: '',
					depth: false,
					index: 15,
				},
			]
		case 'quotations':
			return [
				{
					label: 'No. Quotation',
					value: 'quotation_no',
					format: '',
					depth: false,
					index: 1,
				},
				{
					label: 'Nomor SKU',
					value: 'sku_no',
					format: '',
					depth: false,
					index: 15,
				},
			]
		case 'purchase-requests':
			return [
				{
					label: 'No. Purchase Request',
					value: 'purchase_request_no',
					format: '',
					depth: false,
					index: 1,
				},
				{
					label: 'Nomor SKU',
					value: 'sku_no',
					format: '',
					depth: false,
					index: 15,
				},
			]
		case 'purchase-orders':
			return [
				{
					label: 'No. Purchase Order',
					value: 'purchase_order_no',
					format: '',
					depth: false,
					index: 1,
				},
				{
					label: 'Nomor SKU',
					value: 'sku_no',
					format: '',
					depth: false,
					index: 16,
				},
			]
		case 'invoices':
			return [
				{
					label: 'No. Tagihan',
					value: 'invoice_no',
					format: '',
					depth: false,
					index: 1,
				},
				{
					label: 'Nomor SKU',
					value: 'sku_no',
					format: '',
					depth: false,
					index: 6,
				},
			]
		case 'payments':
			return [
				{
					label: 'No. Pembayaran',
					value: 'payment_no',
					format: '',
					depth: false,
					index: 1,
				},
			]
		default:
			return []
	}
}
