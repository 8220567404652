import * as Yup from 'yup'

export const paymentConfirmationSchema = Yup.object().shape({
	jumlahPembayaran: Yup.number()
		.typeError('Input harus angka')
		.required('Jumlah Pembayaran Harus Diisi'),
	patrnerLabel: Yup.string().required('Transfer Dari Harus Diisi'),
	mbizLabel: Yup.string().required('Transfer Ke Harus Diisi'),
	transferDate: Yup.mixed().nullable().required('Tanggal Transfer Harus Diisi'),
	img1: Yup.mixed().when(['img2', 'img3'], {
		is: (img2, img3) => img2 || img3,
		then: Yup.mixed().notRequired(),
		otherwise: Yup.mixed().required().nullable(),
	}),
	img2: Yup.mixed().nullable(),
	img3: Yup.mixed().nullable(),
})
