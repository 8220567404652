import { makeStyles } from '@material-ui/core/styles'

export const DetailuserAccountStyle = makeStyles((theme) => ({
	container: {
		overflow: 'hidden',
	},
	gridSearch: {
		marginTop: '32px',
		marginBottom: '15px',
	},
	customField: {
		width: '275px',
	},
	boxSpacing: {
		marginTop: '15px',
	},
	buttonAdd: {
		backgroundColor: theme.palette.warning.dark,
	},
	bcheckBox: {
		color: theme.palette.primary.main,
	},
	gridListButton: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	customButton: {
		fontSize: '12px',
	},
	buttonNegative: {
		backgroundColor: theme.palette.error.dark,
		color: theme.palette.common.white,
	},
	customTooltip: {
		position: 'absolute',
		marginTop: '6px',
		padding: '0',
		minWidth: '0',
	},
	customIcon: {
		fontSize: '14px',
		marginLeft: '3px',
	},
	formLabel: {
		margin: '17px 0',
	},
	labelRequired: {
		position: 'relative',
		'&:after': {
			content: '"*"',
			position: 'absolute',
			color: theme.palette.error.dark,
		},
	},
	textDialog: {
		margin: '15px 0',
	},
	formTitle: {
		fontSize: '16px',
		fontWeight: 'bold',
	},
	formField: {
		paddingLeft: '50px',
		paddingRight: '50px',
	},
}))
