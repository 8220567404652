export const RfqcancelReason = [
	{
		value: 'Ingin mengubah pesanan/jumlah pesanan',
		label: 'Ingin mengubah pesanan/jumlah pesanan',
	},
	{
		value: 'Respon terlalu lama',
		label: 'Respon terlalu lama',
	},
	{
		value: 'Tidak jadi melakukan pemesanan',
		label: 'Tidak jadi melakukan pemesanan',
	},
	{
		value: 'Lainnya',
		label: 'Lainnya',
	},
]

export const QnCancelReason = [
	{
		value: 'Ingin membatalkan pesanan',
		label: 'Ingin membatalkan pesanan',
	},
	{
		value: 'Harga ongkos kirim yang tinggi',
		label: 'Harga ongkos kirim yang tinggi',
	},
	{
		value: 'Harga pesanan yang tinggi',
		label: 'Harga pesanan yang tinggi',
	},
	{
		value: 'Lainnya',
		label: 'Lainnya',
	},
]
