/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	TextField,
	IconButton,
	Button,
	Container,
	Select,
	MenuItem,
	Collapse,
} from '@material-ui/core'
import { SearchRounded, Edit, Delete } from '@material-ui/icons'
import { generateAddressType, newUri } from '../../../utils/globalUtils'
import { requestAddress } from 'States/actions/Address/Address/listAction'
import { navigate } from 'gatsby'
import Breadcrumbs from 'Components/breadcrumbs'
import { Styles } from './styles'
import * as qs from 'query-string'
import TableData from 'Components/tableData'
import { Autocomplete } from '@material-ui/lab'
import { addressTypesList, emptyDropdown } from '../../../constants/text'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import { clearCreateAddress } from 'States/actions/Address/Address/createAction'
import {
	deleteAddress,
	clearDeleteAddress,
} from 'States/actions/Address/Address/deleteAction'
import Modal from 'Components/modal'

const breadcrums = [
	{
		segment: 'Home',
		link: '/dashboard/',
	},
	{
		segment: 'Perusahaan',
		link: '#',
	},
	{
		segment: 'Daftar Alamat',
		link: '#',
	},
]

const Address = (props) => {
	const classes = Styles()
	const dispatch = useDispatch()
	const queryString = qs.parse(props.location.search)
	const [location, setLocation] = useState(
		queryString.region ? queryString.region : ''
	)
	const [addressLabel, setAddresslabel] = useState(
		queryString.name ? queryString.name : ''
	)
	const [addressType, setAddressType] = useState(
		queryString.name ? queryString.name : ''
	)
	const [perPage, setPerPage] = useState(
		queryString.per_page ? queryString.per_page : 10
	)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)

	const list = useSelector((state) => state.addressList)

	queryString.per_page = perPage
	queryString.page = page
	queryString.region = location.length > 2 ? location : ''
	queryString.name = addressLabel.length > 2 ? addressLabel : ''
	queryString.type = addressType ? addressType : ''

	const limitValue = [10, 25, 50, 100]

	const buildDropdownLimit = (items) => {
		return (
			<Grid container justify='flex-end' alignItems='center'>
				<Typography className={classes.typoPage2}>
					<strong>Tampilkan </strong>
				</Typography>
				<Select
					classes={{ root: classes.selectContainer, icon: classes.selectIcon }}
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={perPage}
					onChange={handlePerRowsChange}
					style={{ marginRight: '4px' }}
				>
					{items.map((row, index) => {
						return (
							<MenuItem key={index} value={row}>
								{row}
							</MenuItem>
						)
					})}
				</Select>
			</Grid>
		)
	}

	const columns = [
		{
			name: 'LABEL ALAMAT',
			selector: 'name',
			sortable: true,
			cell: (row) => row.name || '-',
			grow: 2,
		},
		{
			name: 'LOKASI',
			selector: 'location',
			sortable: true,
			cell: (row) => row.region_id.complete_name || '-',
			grow: 5,
		},
		{
			name: 'PIC',
			selector: 'pic',
			sortable: true,
			cell: (row) => row.pic || '-',
			grow: 2,
		},
		{
			name: 'NO. PONSEL',
			selector: 'mobile',
			sortable: true,
			cell: (row) => row.mobile || '-',
			grow: 2,
		},
		{
			name: 'TIPE ALAMAT',
			selector: 'type',
			sortable: true,
			cell: (row) => generateAddressType(row.type) || '-',
			grow: 2,
		},
		{
			name: 'AKSI',
			selector: 'aksi',
			center: true,
			hide: 'md',
			grow: 1,
			cell: (row) => buttonGenerator(row),
		},
	]

	const buttonGenerator = (row) => (
		<>
			<Button
				variant='contained'
				size='small'
				className={`${classes.buttonAction} ${classes.btnEdit}`}
				onClick={() => {
					handleClick(row.id, 'edit')
				}}
			>
				<Edit fontSize='small' />
			</Button>
			<Button
				variant='contained'
				size='small'
				className={`${classes.buttonAction} ${classes.btnDelete}`}
				onClick={() => {
					handleClick(row.id, 'delete')
				}}
			>
				<Delete fontSize='small' />
			</Button>
		</>
	)

	const handleClick = (id, action) => {
		if (action == 'edit') {
			navigate(`/dashboard/company-address/edit/${id}`)
		} else {
			setConfirm(true)
			setItem(id)
		}
	}

	// Delete address
	const [confirm, setConfirm] = useState(false)
	const [item, setItem] = useState(false)
	const deletedState = useSelector((state) => state.addressDelete)

	const closeModalDelete = () => {
		setConfirm(false)
	}

	const clearNotification = () => {
		dispatch(clearCreateAddress())
		dispatch(clearDeleteAddress())
	}

	const deleteAddressHandler = () => {
		clearNotification()
		dispatch(deleteAddress(item))
		setConfirm(false)
	}

	const alertText = () => {
		if (deletedState.isError) {
			const errorResponse = JSON.parse(deletedState.error.http_body)
			return `Alamat ${errorResponse?.error?.type == 'delivery' ? 'Pengiriman' : 'Tagihan'
				} tidak dapat dihapus karena hanya satu yang terdaftar pada Perusahaan`
		} else {
			if (deletedState.data != null) {
				return `Alamat ${deletedState.data.type == 'delivery'
						? 'Pengiriman'
						: deletedState.data.type == 'invoice'
							? 'Tagihan'
							: 'Pengiriman & Tagihan'
					} berhasil dihapus`
			}
		}
	}

	//End Delete address

	useEffect(() => {
		dispatch(requestAddress({ queryString }))
	}, [])

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		requestData()
	}

	const handlePerRowsChange = (event) => {
		setPerPage(event.target.value)
		queryString.per_page = event.target.value
		let maxPage = Math.ceil(
			list.data.pagination.total_records / queryString.per_page
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		requestData()
	}

	const handleLabelSearch = () => {
		queryString.name = addressLabel
		queryString.region = location
		setPage(1)
		queryString.page = 1
		requestData()
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => {
			requestData()
		}, 1000)
		return () => clearTimeout(timeOutId)
	}, [location, addressLabel, addressType])

	const handleSearch = (type, value) => {
		switch (type) {
			case 'location':
				queryString.region = value
				setLocation(value)
				break
			case 'name':
				queryString.name = value
				setAddresslabel(value)
				break
			case 'type':
				queryString.type = value
				setAddressType(value)
				break
			default:
				break
		}
	}

	const requestData = () => {
		let query = newUri(queryString)
		queryString.region = queryString.region.length > 2 ? queryString.region : ''
		queryString.name = queryString.name.length > 2 ? queryString.name : ''
		queryString.type = queryString.type ? queryString.type : ''
		dispatch(requestAddress({ queryString }))
		propsNavigate(query)
	}

	const propsNavigate = (query) => {
		props.navigate(`/dashboard/company-address?${query}`)
	}

	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>
				<Box my={2}>
					<Grid container justify='space-between'>
						<Grid container item md={8} justify='flex-start'>
							<Typography variant='h6'>Daftar Alamat</Typography>
						</Grid>
						<Grid container item md={4} justify='flex-end'>
							<Button
								variant='contained'
								color='secondary'
								size='small'
								onClick={(event) => {
									event.preventDefault()
									navigate('/dashboard/company-address/new', {
										replace: true,
									})
								}}
							>
								Tambah Alamat
							</Button>
						</Grid>
					</Grid>
				</Box>
				<Box mt={1}>
					<Grid container justify='space-between'>
						<Grid container item xs={10} justify='flex-start'>
							<Grid item xs={3}>
								<Grid item>
									<Autocomplete
										id='addressType'
										options={addressTypesList.data}
										noOptionsText={emptyDropdown}
										getOptionLabel={(option) => option.name}
										getOptionSelected={(opt, value) => opt.name == value.name}
										classes={{
											input: classes.selectInput,
											inputRoot: classes.inputRoot,
											endAdornment: classes.endAdornment,
											popupIndicator: classes.selectIcon,
											noOptions: classes.noOptionText,
										}}
										renderInput={(params) => (
											<TextField {...params} placeholder='Tipe Alamat' />
										)}
										onChange={(e, selectedOptions) => {
											handleSearch('type', selectedOptions?.type)
										}}
									/>
								</Grid>
							</Grid>
							<Grid item xs={5}>
								<Grid item>
									<TextField
										placeholder={'Cari Provinsi, Kota, Kecamatan, Kelurahan, Kode Pos'}
										className={classes.gridSearch}
										InputProps={{
											disableUnderline: false,
										}}
										style={{ width: 'calc(100% - 20px)' }}
										value={location}
										onChange={(e) => {
											handleSearch('location', e.target.value)
										}}
									/>
								</Grid>
							</Grid>
							<Grid item xs={3}>
								<TextField
									placeholder={'Cari Label Alamat'}
									type='search'
									className={classes.gridSearch}
									InputProps={{
										disableUnderline: false,
										endAdornment: (
											<IconButton
												variant='contained'
												color='primary'
												className={classes.buttonSearch}
												onClick={handleLabelSearch}
											>
												<SearchRounded fontSize='small' />
											</IconButton>
										),
									}}
									value={addressLabel}
									onChange={(e) => {
										handleSearch('name', e.target.value)
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<Box mt={1}>
					<Grid container item justify='flex-end'>
						{buildDropdownLimit(limitValue)}
					</Grid>
				</Box>

				<Collapse in={deletedState.alert} style={{ paddingTop: '16px' }}>
					<Alert
						severity={deletedState.isError ? 'error' : 'info'}
						classes={deletedState.isError ? { message: classes.alertMessage } : {}}
						action={
							<IconButton
								aria-label='close'
								color='inherit'
								size='small'
								onClick={() => {
									clearNotification()
								}}
							>
								<CloseIcon fontSize='inherit' />
							</IconButton>
						}
					>
						{alertText()}
					</Alert>
				</Collapse>
				<Box mt={3}>
					<TableData
						perPage={perPage}
						limitChange={handlePerRowsChange}
						columns={columns}
						isLoading={list.isLoading}
						pagination={list.data ? list.data.pagination : {}}
						data={list.data ? list.data.result : []}
						name='alamat'
						paginationPageChange={handlePageChange}
						pageNow={parseInt(queryString.page)}
					/>
				</Box>
				<Modal open={confirm}>
					<Typography>
						Apakah anda yakin
						<br />
						ingin menghapus Alamat ini ?
					</Typography>
					<Box mt={2} className={classes.paddingTop}>
						<Button
							onClick={() => closeModalDelete()}
							color='secondary'
							variant='outlined'
							size='small'
						>
							Batal
						</Button>
						&nbsp;&nbsp;&nbsp;
						<Button
							onClick={() => {
								deleteAddressHandler()
							}}
							className={classes.buttonRed}
							variant='contained'
							size='small'
						>
							Ya, Hapus
						</Button>
					</Box>
				</Modal>
			</Container>
		</DashboardLayout>
	)
}

Address.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default Address
