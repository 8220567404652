/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import styles from './styles'
import PropTypes from 'prop-types'
import Status from 'Components/status'
import { useSelector, useDispatch } from 'react-redux'
import { cancelRfq } from 'States/actions/Transactions/RFQ/detailRfqAction'
import {
	requestDetailQuotation,
	resetAction as resetActionQuotation,
} from 'States/actions/Transactions/Quotation/detailQuotation'
import { withStyles } from '@material-ui/core/styles'
import {
	camelCaseToSnakeCase,
	dateFormatFromOdoo,
	generateAddress,
	isAlphabetPuctuationRegex,
	isCommonButton,
	isNumberPuctuationRegex,
} from '../../../../utils/globalUtils'
import { QnCancelReason } from '../../../../utils/cancelReason'
import Modal from 'Components/modal'
import Skeleton from '@material-ui/lab/Skeleton'
import {
	requestDetailCompany,
	resetAction as resetActionCompany,
} from 'States/actions/CompanyProfile/profileAction'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import {
	Box,
	Button,
	Container,
	Paper,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	MenuItem,
	TextField,
	CircularProgress,
	IconButton,
} from '@material-ui/core'
import Layout from 'Components/layout'
import Breadcrumbs from 'Components/breadcrumbs'
import { Dropdown } from '../../../../components/dropdown'
import Image from 'material-ui-image'
import ModalCreatePo from './modalCreatePo'
import ModalCreatePr from './modalCreatePr'
import { numeralFormat } from '../../../../utils/formatMoney'
import { rolePemohon, checkRole, roleAdmin } from '../../../../utils/acl'
import ScrollToTop from 'Components/scrollToTop'
import Timeline from 'Components/timeline'
import PrintReport from 'Components/buttonPrintReport'
import {
	getTimeline,
	resetAction,
} from 'States/actions/Transactions/Timeline/timelineAction'
import Label from 'Components/label'
//budget
import { requestBudgetList } from '../../../../state/actions/Budget/listValidAction'
import { dataTransactions } from '../../../../utils/bodyDataObject'
import { updateDetailQuotation } from '../../../../state/actions/Transactions/Quotation/detailQuotation'
import { trim } from 'lodash'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)

const BorderedCell = withStyles(() => ({
	root: {
		borderTop: '1px solid #ddd',
	},
}))(TableCell)

const Component = ({ saleOrderId, navigate }) => {
	const classes = styles()
	const dispatch = useDispatch()
	const [breadcrums, setBreadcrums] = useState([])
	const [modalPo, setModalPo] = useState(false)
	const [modalPr, setModalPr] = useState(false)
	const [modalCancel, setModalCancel] = useState(false)
	const [optionState, setOptionState] = useState(false)
	const [btnType, setBtnType] = useState(null)
	const [alertPr, setAlertPr] = useState(false)
	const [alertPo, setAlertPo] = useState(false)
	const [dropdownList, setDropdownList] = useState(false)
	const [errorCancelDetail, setErorCancelDetail] = useState(false)
	const [cancel, setCancel] = useState({
		cancelled_reason: '',
		cancelled_reason_detail: '',
	})
	const [disableCreatePr, setDisableCreatePr] = useState(false)
	const [editableData, setEditableData] = useState({
		id: '',
		receiverName: '',
		receiverPhone: '',
	})

	const [editableStatus, setEditableStatus] = useState({
		receiverName: false,
		receiverPhone: false,
	})

	const [editableLoading, setEditableLoading] = useState({
		receiverName: false,
		receiverPhone: false,
	})

	const imageStorage = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'
	const detailQuotation = useSelector((state) => state.detailQuotation)
	const company_profile = useSelector((state) => state.companyProfile.company)
	const allowedPemohon = checkRole(`${rolePemohon}`, false)
	const allowedAdmin = checkRole(`${roleAdmin}`, false)
	const timelineData = useSelector((state) => state.timelineReducer)
	//untuk field anggaran
	const budgetList = useSelector((state) => state.budgetListValidReducer)
	// data detail state
	const [dataQuotation, setDataQuotation] = useState({ ...dataTransactions })

	useEffect(() => {
		dispatch(requestDetailQuotation(saleOrderId))
		dispatch(requestDetailCompany())
		return () => {
			dispatch(resetActionQuotation())
			dispatch(resetActionCompany())
		}
	}, [saleOrderId])

	useEffect(() => {
		if (
			detailQuotation.data != null &&
			detailQuotation.isLoading == false &&
			detailQuotation.data.quotation_no == saleOrderId &&
			detailQuotation.data.transaction_id &&
			detailQuotation.data.transaction_id[0]
		) {
			let dataTemp = dataQuotation
			dataTemp.noRFQ = detailQuotation.data.rfq_no
			dataTemp.noQuotation = detailQuotation.data.quotation_no
			dataTemp.NoRefPO = detailQuotation.data.client_order_ref
			dataTemp.timeCreate = detailQuotation.data.create_date
			dataTemp.expiredDate = detailQuotation.data.validity_date
			dataTemp.requestor = detailQuotation.data.requestor_name
			dataTemp.deparRequestor = detailQuotation.data.requestor_department
			dataTemp.internalNotes = detailQuotation.data.customer_internal_notes
			dataTemp.shippingAdd = detailQuotation.data.partner_shipping_id.street
			dataTemp.shippingAddLocation = detailQuotation.data.partner_shipping_id.region_id.complete_name
			dataTemp.billingAdd = detailQuotation.data.partner_invoice_id.street
			dataTemp.billingAddLocation = detailQuotation.data.partner_invoice_id.region_id.complete_name
			dataTemp.expectedDelivDate = detailQuotation.data.expected_delivery_date
			dataTemp.receiverName = detailQuotation.data.receiver_name
			dataTemp.receiverPhone = detailQuotation.data.receiver_phone
			dataTemp.state = detailQuotation.data.state
			dataTemp.note = detailQuotation.data.note
			dataTemp.amountUntax = detailQuotation.data.amount_untaxed
			dataTemp.amountTotal = detailQuotation.data.amount_total
			dataTemp.amountGroup = detailQuotation.data.amount_by_group
			dataTemp.orderLine = detailQuotation.data.order_line
			dataTemp.status = detailQuotation.data.quotation_status
			dataTemp.id = detailQuotation.data.id
			dataTemp.shippingVendor = detailQuotation.data.shipping_vendor
			setDataQuotation(dataTemp)
			dispatch(getTimeline(detailQuotation.data.transaction_id[0].id))
			dispatch(requestBudgetList(detailQuotation.data.requestor_id))
			setEditableData({
				id: detailQuotation.data.id,
				receiverName: detailQuotation.data.receiver_name,
				receiverPhone: detailQuotation.data.receiver_phone,
			})
		} else {
			setDataQuotation({ ...dataTransactions })
			dispatch(resetAction())
		}
	}, [detailQuotation.data, detailQuotation.isLoading, saleOrderId])

	const handleCancel = () => {
		let data = {}
		data.id = detailQuotation.data.id
		data.cancelled_reason = cancel.cancelled_reason
		const valueCancelReasonDetal = cancel.cancelled_reason_detail

		if (cancel.cancelled_reason === '') {
			setOptionState(true)
			return false
		}
		if (!valueCancelReasonDetal.length && cancel.cancelled_reason === 'Lainnya') {
			setErorCancelDetail(true)
			return false
		}

		if (cancel.cancelled_reason === 'Lainnya') {
			data.cancelled_reason = cancel.cancelled_reason_detail
		}

		cancelRfq(data).then(() => {
			navigate('/dashboard/quotation')
			setModalCancel(false)
		})
	}

	const selectCancelReason = (item) => {
		setDropdownList(false)
		setOptionState(false)
		setCancel({ ...cancel, cancelled_reason: item.value })
	}

	const splitVariant = (str) => {
		const arr = str.match(/\(.+?\)/g)
		let joinVar = '-'
		if (arr) {
			const variant = arr.map((item) => item.slice(1, -1))
			joinVar = variant
		}

		return joinVar
	}

	useEffect(() => {
		if (detailQuotation.data) {
			handleSetBreadcrums(detailQuotation.data.quotation_no)
		}
	}, [detailQuotation.data])

	// breadcrums function
	const handleSetBreadcrums = (value) => {
		let tmp_breadcrums = [
			{
				segment: 'Home',
				link: '/dashboard/',
			},
			{
				segment: 'Transaksi',
				link: '/dashboard',
			},
			{
				segment: 'Quotation',
				link: '/dashboard/quotation',
			},
		]
		setBreadcrums([
			...tmp_breadcrums,
			{
				segment: value,
				link: '#',
			},
		])
	}

	useEffect(() => {
		if (company_profile.data) {
			if (company_profile.data.is_pr_active === 1 && btnType === 1) {
				setModalPr(true)
			} else if (company_profile.data.is_pr_active === 1 && btnType === 0) {
				setAlertPo(true)
			} else if (company_profile.data.is_pr_active === 0 && btnType === 1) {
				setAlertPr(true)
			} else if (company_profile.data.is_pr_active === 0 && btnType === 0) {
				setModalPo(true)
			}
			if (btnType === 0) {
				setModalPo(true)
			}
		}
	}, [company_profile.data])

	const createAction = (btnType) => {
		setBtnType(btnType)
		dispatch(requestDetailCompany())
	}

	const renderStatus = (status) => {
		if (status == 'done') {
			return 'sale_done'
		}

		return status
	}

	const handleChangeCancelReasonDetail = (e) => {
		if (e.target.value.length) setErorCancelDetail(false)
		setCancel({ ...cancel, cancelled_reason_detail: e.target.value })
	}

	// editable receiver name and phone start

	useEffect(() => {
		let isActive = false
		let keys = Object.keys(editableStatus)
		keys.forEach((key) => {
			if (editableStatus[key]) {
				isActive = true
			}
		})
		if (!isActive) {
			setDisableCreatePr(false)
		} else {
			setDisableCreatePr(true)
		}
	}, [editableStatus])

	const checkSameValue = () => {
		let isSame = true
		let keys = Object.keys(editableData)
		keys.forEach((key) => {
			if (editableData[key] != dataQuotation[key]) {
				isSame = false
			}
		})
		return isSame
	}

	const editableOnChange = (propertyName, value) => {
		setEditableData({
			...editableData,
			[propertyName]: value,
		})
	}

	const editableOnClose = (propertyName) => {
		setEditableStatus({
			...editableStatus,
			[propertyName]: !editableStatus[propertyName],
		})
		setEditableData({
			...editableData,
			[propertyName]: trim(dataQuotation[propertyName]),
		})
	}

	const editableOnConfirm = (propertyName) => {
		setEditableData({
			...editableData,
			[propertyName]: trim(editableData[propertyName]),
		})
		setEditableLoading({
			...editableLoading,
			[propertyName]: !editableLoading[propertyName],
		})
		setEditableStatus({
			...editableStatus,
			[propertyName]: !editableStatus[propertyName],
		})
		if (!checkSameValue()) {
			let underscored = camelCaseToSnakeCase(propertyName)
			let data = {
				[underscored]: editableData[propertyName],
			}
			dispatch(updateDetailQuotation(editableData['id'], data)).then((res) => {
				// all editable state use prev state in this function to prevent state race
				if (res.success) {
					setDataQuotation((prevDataQuotation) => ({
						...prevDataQuotation,
						[propertyName]: editableData[propertyName],
					}))
				} else {
					setEditableData((prevEditableData) => ({
						...prevEditableData,
						[propertyName]: dataQuotation[propertyName],
					}))
				}
				setEditableLoading((prevData) => ({ ...prevData, [propertyName]: false }))
			})
		} else {
			setEditableData((prevEditableData) => ({
				...prevEditableData,
				[propertyName]: dataQuotation[propertyName],
			}))
			setEditableLoading((prevData) => ({ ...prevData, [propertyName]: false }))
		}
	}

	const editableOnEdit = (propertyName) => {
		setEditableStatus({
			...editableStatus,
			[propertyName]: !editableStatus[propertyName],
		})
	}

	const keyDownReceiverName = (event) => {
		if (!isAlphabetPuctuationRegex(event.key)) {
			event.preventDefault()
		}
	}

	const keyDownReceiverPhone = (event) => {
		if (isCommonButton(event, window)) {
			return
		}
		if (!isNumberPuctuationRegex(event.key)) {
			event.preventDefault()
		}
	}
	const onPasteReceiverName = (event) => {
		let pasteData = event.clipboardData.getData('Text')
		if (!isAlphabetPuctuationRegex(pasteData)) {
			event.preventDefault()
		}
	}
	const onPasteReceiverPhone = (event) => {
		let pasteData = event.clipboardData.getData('Text')
		if (!isNumberPuctuationRegex(pasteData)) {
			event.preventDefault()
		}
	}

	// editable receiver name and phone end

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>

				<Box mb={3}>
					<Grid style={{ display: 'flex' }}>
						{dataQuotation.id && (
							<PrintReport ext='pdf' reportType='sale_order' reportID={dataQuotation.id} />
						)}
						<Typography variant='h6'>Rincian Penawaran (Quotation)</Typography>
					</Grid>
					<Paper className={classes.formRfqTop}>
						<Box py={2} px={3}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Quotation</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.noQuotation
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. RFQ</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															<a
																href={`${window.location.origin}/dashboard/rfq/${dataQuotation.noRFQ}`}
																target='blank'
															>
																{dataQuotation.noRFQ}
															</a>
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Referensi PO</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.NoRefPO
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Tahap</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Box width={120}>
														{detailQuotation.isLoading ? (
															<Skeleton variant='rect' width={120} height={26} />
														) : (
															<Status status={renderStatus(dataQuotation.state)} />
														)}
													</Box>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Status</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.status
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Waktu Quotation Dibuat</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dateFormatFromOdoo(dataQuotation.timeCreate, 'dd MMMM yyyy')
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Batas Membuat PO</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dateFormatFromOdoo(dataQuotation.expiredDate, 'dd MMMM yyyy')
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Pemohon</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.requestor
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Departemen Pemohon</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.deparRequestor
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Untuk Keperluan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.internalNotes || '-'
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Ekspektasi Pesanan Diterima</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dateFormatFromOdoo(
																dataQuotation.expectedDelivDate,
																'dd MMMM yyyy'
															) || '-'
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Pengiriman</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.shippingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.shippingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Nama Penerima</strong>
													</Typography>
												</Grid>
												<Grid
													item
													xs={6}
													container
													alignItems='center'
													style={{ paddingRight: 0 }}
												>
													{detailQuotation.isLoading ? (
														<Box width='100%' pr='16px'>
															<Skeleton variant='text' />
														</Box>
													) : editableStatus.receiverName ? (
														<>
															<Grid item lg={8}>
																<TextField
																	size='small'
																	variant='outlined'
																	inputProps={{
																		maxLength: 100,
																	}}
																	error={editableData.receiverName.length == 0}
																	onPaste={(e) => {
																		onPasteReceiverName(e)
																	}}
																	onKeyDown={(e) => {
																		keyDownReceiverName(e)
																	}}
																	defaultValue={editableData.receiverName}
																	onChange={(e) => {
																		editableOnChange('receiverName', e.target.value)
																	}}
																/>
															</Grid>
															<Grid item lg={4} style={{ textAlign: 'center' }}>
																<IconButton
																	size='small'
																	disabled={editableData.receiverName.length == 0}
																>
																	<CheckIcon
																		onClick={() => {
																			editableOnConfirm('receiverName')
																		}}
																	/>
																</IconButton>
																<IconButton size='small'>
																	<CloseIcon
																		onClick={() => {
																			editableOnClose('receiverName')
																		}}
																	/>
																</IconButton>
															</Grid>
															<Grid item lg={12}>
																{editableData.receiverName.length != 0 ? null : (
																	<Typography variant='caption' color='error'>
																		Nama Penerima tidak boleh kosong
																	</Typography>
																)}
															</Grid>
														</>
													) : (
														<>
															<Grid item lg={8}>
																{editableLoading.receiverName ? (
																	<Skeleton variant='text' />
																) : (
																	<p
																		style={{
																			wordBreak: 'break-word',
																			margin: 0,
																		}}
																	>
																		{dataQuotation.receiverName}
																	</p>
																)}
															</Grid>
															<Grid item lg={4} style={{ textAlign: 'center' }}>
																{dataQuotation.state == 'sent' &&
																	(allowedAdmin || allowedPemohon) && (
																		<EditIcon
																			fontSize={'small'}
																			onClick={() => {
																				editableOnEdit('receiverName')
																			}}
																		/>
																	)}
															</Grid>
														</>
													)}
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Telepon Penerima</strong>
													</Typography>
												</Grid>
												<Grid
													item
													xs={6}
													container
													alignItems='center'
													style={{ paddingRight: 0 }}
												>
													{detailQuotation.isLoading ? (
														<Box width='100%' pr='16px'>
															<Skeleton variant='text' />
														</Box>
													) : editableStatus.receiverPhone ? (
														<>
															<Grid item lg={8}>
																<TextField
																	size='small'
																	variant='outlined'
																	inputProps={{
																		maxLength: 50,
																	}}
																	error={editableData.receiverPhone.length == 0}
																	onKeyDown={(e) => {
																		keyDownReceiverPhone(e)
																	}}
																	onPaste={(e) => {
																		onPasteReceiverPhone(e)
																	}}
																	defaultValue={editableData.receiverPhone}
																	onChange={(e) => {
																		editableOnChange('receiverPhone', e.target.value)
																	}}
																/>
															</Grid>
															<Grid item lg={4} style={{ textAlign: 'center' }}>
																<IconButton
																	size='small'
																	disabled={editableData.receiverPhone.length == 0}
																>
																	<CheckIcon
																		onClick={() => {
																			editableOnConfirm('receiverPhone')
																		}}
																	/>
																</IconButton>
																<IconButton size='small'>
																	<CloseIcon
																		onClick={() => {
																			editableOnClose('receiverPhone')
																		}}
																	/>
																</IconButton>
															</Grid>
															<Grid item lg={12}>
																{editableData.receiverPhone.length != 0 ? null : (
																	<Typography variant='caption' color='error'>
																		No. Telepon Penerima tidak boleh kosong
																	</Typography>
																)}
															</Grid>
														</>
													) : (
														<>
															<Grid item lg={8}>
																{editableLoading.receiverPhone ? (
																	<Skeleton variant='text' />
																) : (
																	<p
																		style={{
																			wordBreak: 'break-word',
																			margin: 0,
																		}}
																	>
																		{dataQuotation.receiverPhone}
																	</p>
																)}
															</Grid>
															<Grid item lg={4} style={{ textAlign: 'center' }}>
																{dataQuotation.state == 'sent' &&
																	(allowedAdmin || allowedPemohon) && (
																		<EditIcon
																			fontSize={'small'}
																			onClick={() => {
																				editableOnEdit('receiverPhone')
																			}}
																		/>
																	)}
															</Grid>
														</>
													)}
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.billingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{detailQuotation.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataQuotation.billingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Box>

				{/* bottom form */}
				<Box>
					<Paper>
						<Box py={2} px={3}>
							{/* table */}
							<Box>
								<Table classes={{ root: classes.tableRoot }}>
									<TableHead>
										<TableRow>
											<TableCell align='center'>
												<strong>No</strong>
											</TableCell>
											<TableCell align='center'>
												<strong>Produk</strong>
											</TableCell>
											<TableCell align='center'>
												<strong>Unit</strong>
											</TableCell>
											<TableCell align='center'>
												<strong>Kuantitas</strong>
											</TableCell>
											<TableCell align='center'>
												<strong>Harga / Unit</strong>
											</TableCell>
											<TableCell align='center'>
												<strong>Total Harga</strong>
											</TableCell>
											<TableCell align='center'>
												<strong>Pajak</strong>
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{detailQuotation.isLoading ? (
											<TableRow key={'skeleton'}>
												<CustomCell>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell>
													<Box display='flex' flexDirection='row'>
														<Box overflow='hidden' width={60} height={60}>
															<Skeleton variant='rect' width={60} height={60} />
														</Box>
														<Box
															ml={1}
															display='flex'
															flexDirection='column'
															justifyContent='space-between'
															style={{ width: '100%' }}
														>
															<Box>
																<Typography variant='body2'>
																	<Skeleton variant='text' />
																</Typography>
																<Typography variant='caption'>
																	<Skeleton variant='text' />
																</Typography>
															</Box>
															<Typography variant='caption'>
																<Skeleton variant='text' />
															</Typography>
															<Typography variant='caption'>
																<Skeleton variant='text' />
															</Typography>
														</Box>
													</Box>
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell
													align='center'
													style={{
														maxWidth: 120,
													}}
												>
													<Skeleton variant='text' />
												</CustomCell>
											</TableRow>
										) : (
											dataQuotation.orderLine.map((item, index) => (
												<TableRow key={item.id}>
													<CustomCell>{index + 1}</CustomCell>
													<CustomCell>
														<Box display='flex' flexDirection='row'>
															<Box>
																<Image
																	src={
																		imageStorage +
																		item.product_id.product_tmpl_id.image_128_filestore
																	}
																	style={{ width: 60, height: 60 }}
																/>
															</Box>
															<Box
																ml={1}
																display='flex'
																flexDirection='column'
																justifyContent='space-between'
															>
																<Box>
																	<Typography variant='body2'>
																		{item.product_id.sku_no}
																	</Typography>
																	<Typography variant='caption'>{item.name}</Typography>
																</Box>
																<Typography variant='caption'>
																	varian: {splitVariant(item.name)}
																</Typography>
																{item.eta_lead_time > 0 && (
																	<Label type='success'>
																		<Typography
																			variant='caption'
																			style={{
																				fontWeight: '500',
																			}}
																		>
																			Estimasi diterima {item.eta_lead_time} hari kerja setelah PO
																			terbuat
																		</Typography>
																	</Label>
																)}
															</Box>
														</Box>
													</CustomCell>
													<CustomCell align='center'>{item.product_uom.name}</CustomCell>
													<CustomCell align='center'>{item.product_uom_qty}</CustomCell>
													<CustomCell align='center'>
														{numeralFormat(item.price_unit)}
													</CustomCell>
													<CustomCell align='center'>
														{numeralFormat(item.price_subtotal)}
													</CustomCell>
													<CustomCell
														align='center'
														style={{
															maxWidth: 120,
														}}
													>
														{item.tax_id.map((tax) => `${tax.name} `)}
													</CustomCell>
												</TableRow>
											))
										)}
										{dataQuotation.shippingVendor && (
											<>
												<TableRow>
													<BorderedCell colSpan={7}>
														<Typography variant='body1'>
															<strong>Rincian Pengiriman</strong>
														</Typography>
													</BorderedCell>
												</TableRow>
												<TableRow>
													<BorderedCell />
													<BorderedCell colSpan={4}>
														<Typography variant='body1'>
															<strong>Ekspedisi</strong>
														</Typography>
													</BorderedCell>
													<BorderedCell align='center'>
														<Typography variant='body1'>
															<strong>Biaya Pengiriman</strong>
														</Typography>
													</BorderedCell>
													<BorderedCell align='center'>
														<Typography variant='body1'>
															<strong>Pajak</strong>
														</Typography>
													</BorderedCell>
												</TableRow>
												{dataQuotation.shippingVendor.map((item, index) => (
													<TableRow key={index}>
														<CustomCell />
														<CustomCell colSpan={4}>
															<Box display='flex' flexDirection='row'>
																<Box overflow='hidden' width={60} height={60}>
																	<Image
																		src={
																			imageStorage +
																			item.product_id.product_tmpl_id.image_128_filestore
																		}
																	/>
																</Box>
																<Box ml={1} display='flex' alignItems='center'>
																	<Box>
																		<Typography variant='body2'>{item.name}</Typography>
																	</Box>
																</Box>
															</Box>
														</CustomCell>
														<CustomCell align='center'>
															{numeralFormat(item.price_unit)}
														</CustomCell>
														<CustomCell
															align='center'
															style={{
																maxWidth: 120,
															}}
														>
															{item.tax_id.map((tax) => `${tax.name} `)}
														</CustomCell>
													</TableRow>
												))}
											</>
										)}
									</TableBody>
								</Table>
							</Box>
							{/* end of table */}

							{/* total */}
							<Box mt={2}>
								<Box display='flex' flex='1' justifyContent='space-between' mb={2}>
									<Box>
										<Typography variant='body1' className={classes.font14}>
											<strong>Catatan Permintaan Khusus</strong>
										</Typography>
										<Typography variant='body1' className={classes.font14}>
											{detailQuotation.isLoading ? null : dataQuotation.note}
										</Typography>
									</Box>
									<Box display='flex' minWidth={400} flexDirection='column'>
										<Box
											px={3}
											pb={1}
											display='flex'
											flex='1'
											justifyContent='space-between'
										>
											<Typography variant='body1'>Subtotal</Typography>
											<Typography variant='body1'>
												{detailQuotation.isLoading ? (
													<Skeleton variant='text' width={200} />
												) : (
													numeralFormat(dataQuotation.amountUntax)
												)}
											</Typography>
										</Box>
										{detailQuotation.isLoading
											? null
											: dataQuotation.amountGroup.length != 0 &&
											dataQuotation.amountGroup.map((tax, index) => (
												<Box
													key={index}
													px={3}
													pb={1}
													display='flex'
													flex='1'
													justifyContent='space-between'
												>
													<Typography variant='body1'>{tax[0]}</Typography>
													<Typography variant='body1'>{numeralFormat(tax[1])}</Typography>
												</Box>
											))}
										<Box className={classes.totalPrice}>
											<Typography variant='body1'>
												<strong>TOTAL</strong>
											</Typography>
											<Typography variant='body1'>
												{detailQuotation.isLoading ? (
													<Skeleton variant='text' width={200} />
												) : (
													numeralFormat(dataQuotation.amountTotal)
												)}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							{/* end of total */}
						</Box>
					</Paper>
				</Box>
				<Box className={classes.containerButton} mt={2}>
					{detailQuotation.isLoading ? null : budgetList.isLoading ? null : dataQuotation.state ===
						'sale' ||
						dataQuotation.state === 'cancel' ||
						detailQuotation.data?.purchase_request_no ? (
						<Button
							className={[classes.buttonWidth, classes.kembali, classes.btnWhite].join(
								' '
							)}
							variant='outlined'
							color='secondary'
							onClick={() => navigate('/dashboard/quotation')}
							size='small'
						>
							Kembali
						</Button>
					) : (
						<>
							{company_profile.isLoading ? null : dataQuotation.state ? (
								allowedPemohon &&
									company_profile.data?.is_pr_active === 1 &&
									dataQuotation.state != 'expired' &&
									dataQuotation.state != 'revised' ? (
									<Button
										id='btn-create-pr'
										className={classes.buttonWidth}
										variant='contained'
										onClick={() => createAction(1)}
										size='small'
										color='secondary'
										disabled={disableCreatePr}
									>
										Buat Purchase Request
									</Button>
								) : allowedPemohon &&
									dataQuotation.state != 'revised' &&
									dataQuotation.state != 'expired' ? (
									<Button
										id='btn-create-po'
										className={classes.buttonWidth}
										variant='contained'
										onClick={() => createAction(0)}
										size='small'
										color='secondary'
										disabled={disableCreatePr}
									>
										Buat Purchase Order
									</Button>
								) : null
							) : null}
							{/* <Button
									className={[classes.buttonWidth, classes.kembali].join(' ')}
									variant='contained'
									onClick={() => alert(1)}
									size='small'
									color='secondary'
									disabled
								>
									Minta Penawaran Baru
								</Button> */}
							{dataQuotation.state ? (
								allowedPemohon &&
									dataQuotation.state != 'revised' &&
									dataQuotation.state != 'expired' ? (
									<Button
										id='btn-reject-qn'
										className={`${classes.buttonWidth} ${classes.kembali}  ${classes.buttonDanger}`}
										variant='contained'
										onClick={() => setModalCancel(true)}
										size='small'
									>
										Tolak Quotation
									</Button>
								) : null
							) : null}
							<Button
								id='btn-back'
								className={[
									classes.buttonWidth,
									classes.kembali,
									classes.btnWhite,
								].join(' ')}
								variant='outlined'
								color='secondary'
								onClick={() => navigate('/dashboard/quotation')}
								size='small'
							>
								Kembali
							</Button>
						</>
					)}
				</Box>

				{/* Timeline */}
				{timelineData.isLoading ? (
					<Box style={{ textAlign: 'center' }} mt={2}>
						<CircularProgress size={20} />
					</Box>
				) : timelineData.isLoading == false &&
					detailQuotation.isLoading == false &&
					timelineData.data &&
					timelineData.data.length > 0 &&
					dataQuotation.id ? (
					<>
						<Box mt={0}>
							<Typography variant='h6'>Timeline</Typography>
						</Box>
						<Box mt={'10px'}>
							<Paper elevation={2}>
								<Timeline data={timelineData.data} id={dataQuotation.id} />
							</Paper>
						</Box>
					</>
				) : null}
				{/*End Timeline */}
			</Container>

			<ModalCreatePo
				id={dataQuotation.id}
				open={modalPo}
				ref_po={dataQuotation.NoRefPO}
				onClose={() => setModalPo(false)}
				requestor_id={
					detailQuotation && detailQuotation.data
						? detailQuotation.data.requestor_id
						: ''
				}
			/>
			<ModalCreatePr
				id={dataQuotation.id}
				open={modalPr}
				onClose={() => setModalPr(false)}
				requestor_id={
					detailQuotation && detailQuotation.data
						? detailQuotation.data.requestor_id
						: ''
				}
			/>

			{/* ================== Modal ================= */}
			<Modal
				icon='question'
				open={modalCancel}
				onClose={() => setModalCancel(false)}
			>
				<Typography variant='body1' className={classes.font19}>
					<strong>Apakah Anda yakin ingin menolak Quotation ?</strong>
				</Typography>
				<Typography variant='body1'>
					Quotation yang telah ditolak tidak dapat diproses kembali.
				</Typography>
				<Box mt={3}>
					<Dropdown
						onClick={() => setDropdownList(true)}
						value={cancel.cancelled_reason}
						onClickAway={() => setDropdownList(false)}
						visible={dropdownList}
						error={optionState}
						placeholder={'Pilih Alasan Penolakan'}
					>
						{QnCancelReason.map((item, index) => (
							<MenuItem onClick={() => selectCancelReason(item)} key={index}>
								{item.label}
							</MenuItem>
						))}
					</Dropdown>
					<Typography className={classes.errorText} variant='caption'>
						{optionState ? 'Silakan pilih alasan penolakan' : null}
					</Typography>
					<Box mt={1.5}>
						<TextField
							className={classes.textArea}
							multiline
							onChange={handleChangeCancelReasonDetail}
							rows={4}
							rowsMax={8}
							variant='outlined'
							label='Tuliskan alasan Anda secara rinci'
							required
							error={errorCancelDetail}
							fullWidth
							disabled={
								QnCancelReason[QnCancelReason.length - 1].value !==
								cancel.cancelled_reason
							}
							InputProps={{
								classes: {
									root: classes.textFieldRoot,
								},
							}}
							inputProps={{
								maxLength: 255,
							}}
						/>
					</Box>
					<Box mt={2} display='flex' justifyContent='space-evenly'>
						{allowedPemohon ? (
							<Button
								className={`${classes.buttonDanger} ${classes.buttonWidth}`}
								onClick={() => handleCancel()}
							>
								Tolak Quotation
							</Button>
						) : null}

						<Button
							variant='contained'
							color='secondary'
							className={`${classes.buttonWidth}`}
							onClick={() => setModalCancel(false)}
						>
							Kembali
						</Button>
					</Box>
				</Box>
			</Modal>
			{/* ============= end of modal ======================= */}

			{/* alert Modal */}
			<Modal open={alertPo} icon='warning' onClose={() => setAlertPo(false)}>
				<Typography variant='body1'>
					<strong>
						Terdapat pengaktifan manajemen persetujuan di perusahaan Anda
					</strong>
				</Typography>
				<Box>
					<Typography variant='body1'>
						Quotation ini akan membutuhkan proses persetujuan untuk dapat dijadikan
						Purchase Order.
					</Typography>
				</Box>
				<Box mt={1.5}>
					<Button
						variant='contained'
						color='secondary'
						size='small'
						onClick={() => setAlertPo(false)}
						className={classes.buttonWidth}
					>
						Ok
					</Button>
				</Box>
			</Modal>

			<Modal open={alertPr} icon='warning' onClose={() => alertPr(false)}>
				<Typography variant='body1'>
					<strong>
						Terdapat penonaktifan manajemen persetujuan di perusahaan Anda.
					</strong>
				</Typography>
				<Typography variant='body2'>
					Quotation tidak lagi membutuhkan proses persetujuan untuk dijadikan
					Purchase Order.
				</Typography>
				<Box mt={1.5}>
					<Button
						variant='contained'
						color='secondary'
						size='small'
						onClick={() => setAlertPr(false)}
						className={classes.buttonWidth}
					>
						Ok
					</Button>
				</Box>
			</Modal>
			{/* end alert modal */}
			<ScrollToTop />
		</Layout>
	)
}

Component.propTypes = {
	saleOrderId: PropTypes.string,
	navigate: PropTypes.func,
}

export default React.memo(Component)
