/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import { Department, DepartmentAdd } from '../routes'
import { rolesText } from '../constants/text'

export const DepartmentNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard/department'
				roles={rolesText('department')}
				Component={Department}
			/>
			<PrivateRoute
				path='/dashboard/department/new'
				roles={rolesText('admin')}
				Component={DepartmentAdd}
			/>
			<PrivateRoute
				path='/dashboard/department/edit/:departmentID'
				roles={rolesText('admin')}
				Component={DepartmentAdd}
			/>
		</>
	)
}
