import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dashboard,
	},
	containerRoot: {
		padding: theme.spacing(3, 12),
	},
	datepicker: {
		width: 227,
	},
	tableRoot: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},
	totalPrice: {
		padding: theme.spacing(1, 3),
		justifyContent: 'space-between',
		display: 'flex',
		flex: '1',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	textareaRoot: {
		width: 380,
	},
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalFade: {
		width: 720,
	},
	modalCloseIcon: {
		alignSelf: 'end',
	},
	containerField: {
		alignItems: 'center',
		justifyContent: 'space-around',
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(2),
	},
	outlinedInputRoot: {
		overflow: 'hidden',
		paddingRight: 0,
		marginTop: 4,
	},
	outlinedInputadornedEnd: {
		backgroundColor: theme.palette.secondary,
	},
	autocompleteRoot: {
		width: 250,
	},
	buttonDanger: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	buttonWidth: {
		minWidth: 120,
	},
	containerButton: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	kembali: {
		marginRight: theme.spacing(2),
	},
	tableBorder: {
		border: '1px solid black',
	},
	modalContent: {
		padding: theme.spacing(3),
	},
	modalContainer: {
		minHeight: '40vh',
		maxHeight: '80vh',
		height: '100%',
	},
	detailShipping: {
		height: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	divider: {
		margin: theme.spacing(1, 0),
	},
	inputLabelModalConfirm: {
		minWidth: 250,
	},
	containerTable: {
		maxHeight: 304,
	},
	textDokumen: {
		marginTop: '6px',
		fontStyle: 'italic',
		textDecoration: 'none',
		fontSize: '16px',
		color: '#343A40',
		opacity: 0.4,
	},
	tooltip: {
		maxWidth: 460,
		fontSize: 12,
	},
	customTable: {
		paddingBottom: 10,
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: 14,
		},
		'&::-webkit-scrollbar-track': {
			marginTop: 62,
		},
		'&::-webkit-scrollbar-thumb': {
			border: '5px solid rgba(0,0,0,0)',
			backgroundClip: 'padding-box',
			borderRadius: '9999px',
			backgroundColor: '#d0d0d0',
		},
	},
	trackingLine: {
		paddingTop: 10,
		paddingBottom: 10,
		marginTop: 30,
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: 14,
		},
		'&::-webkit-scrollbar-thumb': {
			border: '5px solid rgba(0,0,0,0)',
			backgroundClip: 'padding-box',
			borderRadius: '9999px',
			backgroundColor: '#d0d0d0',
		},
	},
	arrow: {
		color: theme.palette.common.black,
	},
	tooltipContainer: {
		maxWidth: 272,
		backgroundColor: theme.palette.common.black,
		boxShadow: theme.shadows[2],
		minHeight: 48,
		maxHeight: 300,
		opacity: 1,
		padding: '12px',
		margin: '2px 0',
		marginRight: '-30px',
	},
	styleIconbutton: {
		color: 'black',
		padding: '1px',
		marginLeft: '2px',
		marginTop: '-2px',
	},
	smallICon: {
		width: 16,
		height: 16,
	},
	tooltipContainerShipping: {
		maxWidth: 272,
		backgroundColor: theme.palette.common.black,
		boxShadow: theme.shadows[2],
		heigth: 30,
		maxHeight: 300,
		opacity: 1,
		padding: '5px',
		margin: '10px',
	},
	buttonShipping: {
		height: '30px',
	},
	styleReceivedShipping: {
		color: '#05A549',
		height: 18,
		width: 18,
	},
	textReceivedShipping: {
		color: '#05A549',
		fontSize: 14,
		marginRight: 15,
	},
	textLocation: {
		color: theme.palette.grey[600],
		marginTop: '4px',
	},
}))

export default styles
