import React, { useState } from 'react'
import LayoutComponent from 'Components/layout'
import { ContactForm } from 'Components/forms'
import { Box, Grid, Typography, Paper } from '@material-ui/core'
import { contactStyle } from './styles'
import Breadcrumbs from 'Components/breadcrumbs'

const Contact = () => {
	const classes = contactStyle()
	const [breadcrumbs] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Hubungi Kami',
			link: '/contact-us',
		},
	])
	return (
		<LayoutComponent>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<Paper className={classes.container}>
				<Grid container>
					<Grid item lg={4} md={4} sm={4} xs={12} className={classes.detailWrapper}>
						<Box className={classes.detail}>
							<Box marginBottom={4}>
								<Typography variant='body1' className={classes.detailTitle}>
									Kami Siap Melayani Anda
								</Typography>
								<Typography variant='body2'>
									Untuk layanan bantuan dan infromasi, <br />
									silakan hubungi:
								</Typography>
							</Box>
							<Box marginBottom={4}>
								<Typography variant='body1' className={classes.detailTitle}>
									Layanan Pelanggan
								</Typography>
								<Typography variant='body2'>Email : help@mbiz.co.id</Typography>
								<Typography variant='body2'>Telp : 021 400 01 036</Typography>
							</Box>
							<Box marginBottom={4}>
								<Typography variant='body1' className={classes.detailTitle}>
									Jadwal Operasional
								</Typography>
								<Typography variant='body2'>
									Senin - Jumat (Kecuali hari libur atau cuti bersama)
								</Typography>
								<Typography variant='body2'>09.00 - 18.00 WIB</Typography>
							</Box>
							<Box marginBottom={4}>
								<Typography variant='body1' className={classes.detailTitle}>
									Mbiz
								</Typography>
								{/* <address> */}
								<Typography variant='body2'>PT Brilliant eCommerce Berjaya</Typography>
								<Typography variant='body2'>Gedung Lippo Kuningan lantai 21</Typography>
								<Typography variant='body2'>Jl. HR. Rasuna Said kav.B-12</Typography>
								<Typography variant='body2'>Setiabudi, Jakarta 12940</Typography>
								<Typography variant='body2'>Indonesia</Typography>
								{/* </address> */}
							</Box>
							<Box marginBottom={4}>
								<Typography variant='body1' className={classes.detailTitle}>
									Karir Bersama Mbiz
								</Typography>
								<Typography variant='body2'>
									Kirim resume anda ke recruitment@mbiz.co.id
								</Typography>
							</Box>
							<Box marginBottom={4}>
								<Typography variant='body1' className={classes.detailTitle}>
									Layanan Pengaduan Konsumen
								</Typography>
								<Typography variant='body2'>
									Silakan menghubungi Direktorat Jenderal Perlindungan Konsumen dan
									Tertib Niaga Kementerian Perdagangan Republik Indonesia (Ditjen PKTN)
									pada nomor 0853 1111 1010
								</Typography>
							</Box>
						</Box>
					</Grid>
					<Grid item lg={8} md={8} sm={8} xs={12} className={classes.formWrapper}>
						<ContactForm classes={classes} />
					</Grid>
				</Grid>
			</Paper>
		</LayoutComponent>
	)
}

export default Contact
