import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as qs from 'query-string'
import {
	Typography,
	TextField,
	Button,
	Collapse,
	CircularProgress,
	Box,
} from '@material-ui/core'
// import { HighlightOffOutlined } from '@material-ui/icons'
import PropTypes from 'prop-types'
import schema from './schema'
import Alert from '../../alert'
import { useDispatch, useSelector } from 'react-redux'
import {
	getUserDetailAction,
	resetPasswordAction,
	checkToken,
} from 'States/actions/UsersAccount/resetPasswordAction'
import Modal from '../../modal'
import { navigate } from 'gatsby'

const ResetPasswordForm = ({ classes }) => {
	const queryString = qs.parse(location.search)
	const dispatch = useDispatch()
	const [alertError, setAlerError] = useState(false)
	const [loading, setLoading] = useState(true)
	const resetPasswordReducer = useSelector((state) => state.resetPasswordReducer)
	const auth = useSelector((state) => state.auth)
	const errResetPass =
		resetPasswordReducer.resetPassword.isLoading == false &&
		resetPasswordReducer.resetPassword.isError &&
		resetPasswordReducer.resetPassword.error
			? JSON.parse(resetPasswordReducer.resetPassword.error.http_body)
			: null

	const dataParsed =
		queryString && queryString.data ? JSON.parse(atob(queryString.data)) : null

	useEffect(() => {
		dataParsed && dataParsed.contact_id
			? dispatch(getUserDetailAction(dataParsed.contact_id))
			: navigate('/404')
	}, [])

	useEffect(() => {
		resetPasswordReducer.userDetail.isLoading == false &&
		(resetPasswordReducer.userDetail.isError ||
			resetPasswordReducer.userDetail.data.message)
			? // ? navigate('/')
			  (window.location.href = '/')
			: null
	}, [resetPasswordReducer])

	useEffect(() => {
		if (resetPasswordReducer.userDetail?.data?.id) {
			const data = {
				id: resetPasswordReducer.userDetail.data.id,
				token_reset_password: queryString.token,
			}
			checkToken({ data, auth })
				.then(() => {
					setLoading(false)
				})
				.catch(() => {
					setLoading(false)
					navigate('/404')
				})
		}
	}, [resetPasswordReducer.userDetail.data.id])

	const handleSubmitResetPassword = (val) => {
		const myState = {
			id: resetPasswordReducer.userDetail.data.id,
			name: resetPasswordReducer.userDetail.data.name,
			username: resetPasswordReducer.userDetail.data.username,
			email: resetPasswordReducer.userDetail.data.email,
			password: val.newPassword,
			confirm_password: val.newConfPassword,
			token_reset_password: queryString.token,
		}
		dispatch(resetPasswordAction({ ...myState }))
	}

	const navigateToLogin = () => {
		setAlerError(false)
		setLoading(false)
		navigate('/login')
	}

	return loading ? (
		<Box my={12}>
			<CircularProgress />
		</Box>
	) : (
		<>
			<Formik
				enableReinitialize
				initialValues={{ newPassword: '', newConfPassword: '' }}
				validationSchema={schema}
				onSubmit={(values) => handleSubmitResetPassword(values)}
			>
				{({ errors, handleSubmit, touched }) => {
					// console.log(errors)
					return (
						<Form
							onSubmit={handleSubmit}
							method='post'
							className={[classes.form, classes.p_4]}
						>
							<Typography
								className={[classes.title, classes.mb_3].join(' ')}
								text-align='center'
								variant='h3'
							>
								Reset Kata Sandi
							</Typography>
							<Typography className={[classes.subtitle, classes.textLeft].join(' ')}>
								Masukkan kata sandi baru Anda.
							</Typography>
							<Collapse
								in={
									(errors.newPassword && touched.newPassword) ||
									(errors.newConfPassword && touched.newConfPassword) ||
									(resetPasswordReducer.resetPassword.isLoading == false &&
										resetPasswordReducer.resetPassword.isError)
								}
							>
								<Alert variant={'alert'}>
									<Typography variant='body1' className={classes.alertText}>
										{(errors.newPassword && touched.newPassword && errors.newPassword) ||
											(errors.newConfPassword &&
												touched.newConfPassword &&
												errors.newConfPassword)}
									</Typography>
									<Typography>
										{resetPasswordReducer.resetPassword.isLoading == false &&
											resetPasswordReducer.resetPassword.isError &&
											resetPasswordReducer.resetPassword.error &&
											errResetPass.error.message}
									</Typography>
									<Typography>
										{resetPasswordReducer.resetPassword.isLoading == false &&
											resetPasswordReducer.resetPassword.isError &&
											resetPasswordReducer.resetPassword.error &&
											errResetPass.message}
									</Typography>
								</Alert>
							</Collapse>
							<Field name='newPassword'>
								{({
									field, // { name, value, onChange, onBlur }
									// form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
								}) => (
									<TextField
										variant='outlined'
										fullWidth
										size='small'
										margin='dense'
										placeholder='Kata sandi baru'
										type='password'
										{...field}
									/>
								)}
							</Field>
							<Field name='newConfPassword'>
								{({
									field, // { name, value, onChange, onBlur }
									// form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
								}) => (
									<TextField
										variant='outlined'
										fullWidth
										size='small'
										margin='dense'
										placeholder='Konfirmasi kata sandi baru'
										type='password'
										{...field}
									/>
								)}
							</Field>

							<Button
								fullWidth
								size='large'
								type='submit'
								variant='contained'
								color='secondary'
								className={classes.formButton}
								disabled={resetPasswordReducer.resetPassword.isLoading}
								style={{ marginTop: '8px' }}
							>
								{resetPasswordReducer.resetPassword.isLoading ? (
									<CircularProgress />
								) : (
									'Submit'
								)}
							</Button>
						</Form>
					)
				}}
			</Formik>
			<Modal open={alertError} icon='error'>
				<Typography>
					<strong>URL telah kedaluwarsa</strong>
				</Typography>
				<Box mt={2}>
					<Button
						onClick={() => navigateToLogin()}
						color='secondary'
						variant='contained'
						size='small'
					>
						OK
					</Button>
				</Box>
			</Modal>
		</>
	)
}

ResetPasswordForm.propTypes = {
	classes: PropTypes.object,
}

export default ResetPasswordForm
