import React from 'react'
import clsx from 'clsx'
import {
	Typography,
	Box,
	Stepper,
	Step,
	StepContent,
	StepConnector,
	StepLabel,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core'
import {
	Menyetujui,
	Menunggu,
	MenungguGiliran,
	Ditolak,
	Skip,
} from 'Components/svgs'
import { PurchaseRequestDetailStyles } from './styles'
import Status from 'Components/status'
import { styled, withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none!important',
		color: 'inherit!important',
		paddingTop: '5px!important',
		paddingBottom: '5px!important',
		paddingRight: '16px!important',
		paddingLeft: '16px!important',
	},
}))(TableCell)

const ColorlibConnector = withStyles({
	line: {
		borderColor: '#BDBDBD!important',
		borderLeftWidth: '2px!important',
		transform: 'translateX(14px)',
		display: 'none!important',
	},
})(StepConnector)

const CustomContent = withStyles({
	root: {
		borderColor: '#BDBDBD!important',
		borderLeftWidth: '2px!important',
		transform: 'translateX(5px)',
		paddingBottom: '20px!important',
		paddingLeft: '40px',
	},
})(StepContent)

const IconContainer = styled('div')((props) => ({
	backgroundColor: props.bgColor,
	width: 35,
	height: 35,
	borderRadius: 35 / 2,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	boxShadow: '0px 3px 6px #00000029',
}))

const customIcon = (props, data, status) => {
	const icons = {
		1: <Menunggu />,
		2: <MenungguGiliran />,
		4: <Skip />,
		5: <Ditolak />,
		6: <Menyetujui />,
	}

	if (!data.status_group && (status == 3 || status == 6 || status == 7)) {
		return <IconContainer bgcolor='red'>{icons[String(5)]}</IconContainer>
	}

	if (data.is_current_approval && status != 4 && status != 5) {
		return <IconContainer bgcolor='yellow'>{icons[String(1)]}</IconContainer>
	}

	if (data.status_group) {
		return <IconContainer bgcolor='green'>{icons[String(6)]}</IconContainer>
	}

	if (
		(!data.status_group && status == 5) ||
		(data.is_current_approval && status == 5)
	) {
		return <IconContainer bgcolor='green'>{icons[String(4)]}</IconContainer>
	}

	return <IconContainer bgcolor='#bcbcbc'>{icons[String(2)]}</IconContainer>
}

const generateStatusGroupCategory = (status_group) => {
	if (!status_group.includes(false)) {
		return true
	}
	return false
}

const generateStatusGroup = (data) => {
	return data.status_group
}

const getAprrovalGroup = (data) => {
	const group = []
	if (data && Object.prototype.hasOwnProperty.call(data, 'categories'))
		group.push({
			title: 'Penyetuju Kategori',
			list: data.categories.map((e) => e),
			showLabel: true,
			is_current_approval: data.categories[0].is_current_approval,
			status_group: generateStatusGroupCategory(
				data.categories.map((e) => e.status_group)
			),
		})
	if (data && Object.prototype.hasOwnProperty.call(data, 'department')) {
		data.department.forEach((e) =>
			group.push({
				title: `Penyetuju Departemen Level ${e.level}`,
				list: data.department.filter((item) => item.level == e.level),
				showLabel: false,
				is_current_approval: data.department.find((item) => item.level == e.level)
					.is_current_approval,
				status_group: generateStatusGroup(
					data.department.find((item) => item.level == e.level)
				),
			})
		)
	}
	if (data && Object.prototype.hasOwnProperty.call(data, 'eproc')) {
		data.eproc.forEach((e) =>
			group.push({
				title: `Penyetuju E-Procurement Level ${e.level}`,
				list: data.eproc.filter((item) => item.level == e.level),
				showLabel: false,
				is_current_approval: data.eproc.find((item) => item.level == e.level)
					.is_current_approval,
				status_group: generateStatusGroup(
					data.eproc.find((item) => item.level == e.level)
				),
			})
		)
	}

	return group
}

const renderStatus = (data, status, groupData) => {
	if (data.status_approval_approved == 3 && data.approval_approved_at !== null) {
		return <Status status='3' />
	}
	if (data.status_approval_approved == 6 || data.status_approval_approved == 7) {
		return '-'
	}

	if (data.status_approval_approved == 2 && data.approval_approved_at !== null) {
		return <Status status='2' />
	}

	if (status == 4 && !groupData.status_group) {
		return <Status status='4' />
	}

	if (
		status == 5 &&
		data.status_approval_approved == 5 &&
		!groupData.status_group
	) {
		return <Status status='5' />
	}

	if (status == 3 && !groupData.status_group) {
		return '-'
	}

	if (
		data.status_approval_approved == 0 &&
		data.approval_approved_at === null &&
		data.user.status !== 98
	) {
		return <Status status='0' />
	}
	return '-'
}

const renderApproverDetail = (approver) => {
	let data = {
		name: approver.user.name,
		email: approver.user.email,
		job_title: approver.user.job_title,
	}
	if (approver.approval_logs) {
		data = {
			name: approver.approval_logs.approver_name,
			email: approver.approval_logs.approver_email,
			job_title: approver.approval_logs.approver_title,
		}
	}

	return data
}

function ApprovalDetails(props) {
	const classes = PurchaseRequestDetailStyles()
	const steps = getAprrovalGroup(props.data.grouped_approvals)
	return (
		<Stepper orientation='vertical' connector={<ColorlibConnector />}>
			{steps.map((item, index) => (
				<Step expanded key={index}>
					<StepLabel
						StepIconComponent={(p) => customIcon(p, item, props.data.status)}
					>
						<Typography
							variant='subtitle2'
							style={{ color: '#000', paddingLeft: '14px' }}
						>
							{item.title}
						</Typography>
					</StepLabel>
					<CustomContent>
						{item.list.map((val, i) => (
							<React.Fragment key={i}>
								{!item.showLabel ? null : (
									<Typography
										variant='subtitle2'
										className={clsx({ [classes.spacing]: index === 0 })}
									>
										{val.name}
									</Typography>
								)}
								<Box mb={2}>
									<Table
										style={{
											borderTop: '1px solid #dedede',
											borderBottom: '1px solid #dedede',
											marginTop: 10,
										}}
									>
										<TableHead>
											<TableRow>
												<TableCell
													style={{ width: 260, paddingTop: '5px', paddingBottom: '5px' }}
												>
													<Typography variant='subtitle2'>Nama</Typography>
												</TableCell>
												<TableCell
													style={{ width: 180, paddingTop: '5px', paddingBottom: '5px' }}
												>
													<Typography variant='subtitle2'>Jabatan</Typography>
												</TableCell>
												<TableCell style={{ paddingTop: '5px', paddingBottom: '5px' }}>
													<Typography variant='subtitle2'>Email</Typography>
												</TableCell>
												<TableCell
													style={{ width: 190, paddingTop: '5px', paddingBottom: '5px' }}
												>
													<Typography variant='subtitle2'>Status</Typography>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{val.approvers.map((e, idx) => (
												<TableRow
													key={idx}
													className={clsx({
														[classes.colorSecondary]:
															renderStatus(e, props.data.status, steps) === '-',
													})}
												>
													<CustomCell>
														{renderApproverDetail(e).name}
														{e.user.status == 98 &&
															e.approval_approved_at === null &&
															!item.status_group &&
															(props.data.status == 0 || props.data.status == 1) && (
																<Box
																	bgcolor='#DC3545'
																	textAlign='center'
																	fontWeight='600'
																	color='#fff'
																	px={1}
																	py={0.2}
																	width={106}
																	fontSize={12}
																>
																	Akun Tidak Aktif
																</Box>
															)}
													</CustomCell>
													<CustomCell>
														{renderApproverDetail(e).job_title}
														{e.user.status == 98 &&
															e.approval_approved_at === null &&
															!item.status_group &&
															(props.data.status == 0 || props.data.status == 1) && (
																<Box height={20} width={10} />
															)}
													</CustomCell>
													<CustomCell>
														{renderApproverDetail(e).email}
														{e.user.status == 98 &&
															e.approval_approved_at === null &&
															!item.status_group &&
															(props.data.status == 0 || props.data.status == 1) && (
																<Box height={20} width={10} />
															)}
													</CustomCell>
													<CustomCell>
														{renderStatus(e, props.data.status, steps)}
														{e.user.status == 98 &&
															item.is_current_approval &&
															e.approval_approved_at === null &&
															!item.status_group &&
															(props.data.status == 0 || props.data.status == 1) && (
																<Box height={20} width={10} />
															)}
													</CustomCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Box>
							</React.Fragment>
						))}
					</CustomContent>
				</Step>
			))}
		</Stepper>
	)
}

ApprovalDetails.propTypes = {
	data: PropTypes.object.isRequired,
}

export default React.memo(ApprovalDetails)
