import { BANK_LIST } from '../../types'
import API from '../../../utils/api'
import { newUriAPI } from '../../../utils/globalUtils'

const loading = (suffix) => {
	return {
		type: `${BANK_LIST}${suffix}_PENDING`,
	}
}

const failure = (suffix, error) => {
	return {
		type: `${BANK_LIST}${suffix}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (suffix, data) => {
	return {
		type: `${BANK_LIST}${suffix}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestBankList = ({ queryString }) => {
	return async (dispatch) => {
		dispatch(loading(''))
		try {
			let url = ''
			if (queryString) url = newUriAPI(queryString)

			let response = await API({
				method: 'GET',
				url: '/master/bank/list' + (url != '' ? '?' + url : ''),
			})

			dispatch(success('', response))
		} catch (err) {
			dispatch(failure('', err))
		}
	}
}

export const requestBankMaster = () => {
	return async (dispatch) => {
		dispatch(loading('_MASTER'))
		try {
			let response = await API({
				method: 'GET',
				url: '/master/bank',
			})

			dispatch(success('_MASTER', response))
			// dispatch(success(response.data))
		} catch (err) {
			dispatch(failure('_MASTER', err))
		}
	}
}
