import {
	columnBank,
	columnAlamat,
	columnPengguna,
	columnDepartemen,
	columnPenyetujuKategori,
	columnPenyetujuDepartemen,
	columnEProc,
} from './column'

import { acl } from 'Utilities/acl'
import { navigate } from 'gatsby'

export const breadcrums = [
	{
		segment: 'Home',
		link: '/dashboard/',
	},
	{
		segment: 'Pusat Unduh Data',
		link: '#',
	},
	{
		segment: 'Unduh Data Perusahaan',
		link: '#',
	},
]

export const dataDummy = [
	{
		code: 'AC',
		country_id: 100,
		id: 1709,
		name: 'Aceh',
	},
	{
		code: 'PLM',
		country_id: 100,
		id: 1809,
		name: 'Palembang',
	},
]

export const databuttonStatus = [
	{
		status: 'Akun Bank',
		state: false,
		query: 'bank-account',
	},
	{
		status: 'Alamat',
		state: false,
		query: 'address',
	},
	{
		status: 'Akun Pengguna',
		state: false,
		query: 'user-account',
	},
	{
		status: 'Departemen',
		state: false,
		query: 'department',
	},
	{
		status: 'Penyetuju Kategori',
		state: false,
		query: 'approval-category',
	},
	{
		status: 'Penyetuju Departemen',
		state: false,
		query: 'approval-department',
	},
	{
		status: 'E-procurement',
		state: false,
		query: 'approval-eprocurement',
	},
]

export const changeData = (query, userRoles) => {
	let temp = []
	let access = ''
	let _query = query
	if (query == 'bank-account') {
		access = 'admin,finance'
		if (!acl(userRoles, access)) {
			navigate('/401', { replace: true })
			localStorage.setItem('lastQueryReportCompany', JSON.stringify(''))
			_query = ''
		}
		temp = columnBank
	} else if (query == 'address') {
		access = 'admin,finance'
		if (!acl(userRoles, access)) {
			navigate('/401', { replace: true })
			localStorage.setItem('lastQueryReportCompany', JSON.stringify(''))
			_query = ''
		}
		temp = columnAlamat
	} else if (query == 'user-account') {
		access = 'admin'
		if (!acl(userRoles, access)) {
			navigate('/401', { replace: true })
			localStorage.setItem('lastQueryReportCompany', JSON.stringify(''))
			_query = ''
		}
		temp = columnPengguna
	} else if (query == 'department') {
		access = 'admin,manager'
		if (!acl(userRoles, access)) {
			navigate('/401', { replace: true })
			localStorage.setItem('lastQueryReportCompany', JSON.stringify(''))
			_query = ''
		}
		temp = columnDepartemen
	} else if (query == 'approval-category') {
		access = 'admin'
		if (!acl(userRoles, access)) {
			navigate('/401', { replace: true })
			localStorage.setItem('lastQueryReportCompany', JSON.stringify(''))
			_query = ''
		}
		temp = columnPenyetujuKategori
	} else if (query == 'approval-department') {
		access = 'admin'
		if (!acl(userRoles, access)) {
			navigate('/401', { replace: true })
			localStorage.setItem('lastQueryReportCompany', JSON.stringify(''))
			_query = ''
		}
		temp = columnPenyetujuDepartemen
	} else if (query == 'approval-eprocurement') {
		access = 'admin'
		if (!acl(userRoles, access)) {
			navigate('/401', { replace: true })
			localStorage.setItem('lastQueryReportCompany', JSON.stringify(''))
			_query = ''
		}
		temp = columnEProc
	}

	return { temp, _query }
}

export const findIndexData = (data) => {
	let res =
		data && data.length != 0
			? data.findIndex((element) => element.state == true)
			: -1
	let tempData = res != -1 ? changeData(data[res].status) : []
	return tempData
}

export const changeDataButton = (data, index) => {
	let res = {
		buttonStatus: [],
		dataArrayKolom: [],
	}
	data.map((temp, i) => {
		if (index == i) {
			temp.state = !temp.state
			if (temp.state == true) {
				res.dataArrayKolom = changeData(temp.status)
			}
		} else {
			temp.state = false
		}
		res.buttonStatus.push(temp)
	})
	return res
}

export const stateReplacer = (query = '') => {
	window.history.replaceState(
		null,
		'reports company',
		`/dashboard/download/company?type=${query}`
	)
}

export const findSelectedColumn = (query, index) => {
	let temp = []
	index = index.split(',').map((x) => +x)
	switch (query) {
		case 'bank-account':
			temp = columnBank
			break
		case 'address':
			temp = columnAlamat
			break
		case 'user-account':
			temp = columnPengguna
			break
		case 'department':
			temp = columnDepartemen
			break
		case 'approval-category':
			temp = columnPenyetujuKategori
			break
		case 'approval-department':
			temp = columnPenyetujuDepartemen
			break
		case 'approval-eprocurement':
			temp = columnEProc
			break
		default:
			temp
			break
	}
	const selectedColumn = index.length
		? temp.filter((val) => index.indexOf(parseInt(val.index)) > -1)
		: []
	return selectedColumn
}
