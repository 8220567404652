import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

export default useStyles
