export const breadcrums = [
	{
		segment: 'Home',
		link: '/dashboard/',
	},
	{
		segment: 'Pusat Unduh Data',
		link: '#',
	},
	{
		segment: 'Unduh Data MOU',
		link: '#',
	},
]

export const mou_columns = [
	{
		label: 'No. MOU',
		value: 'name',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Judul MOU',
		value: 'agreement_title',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Deskripsi MOU',
		value: 'agreement_description',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'MOU Terbuat',
		value: 'create_date',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'MOU Dimulai',
		value: 'start_date',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'Berlaku Hingga',
		value: 'validity_date',
		format: '',
		depth: false,
		index: 6,
	},
	{
		label: 'Tanggal Aktif',
		value: 'actived_date',
		format: '',
		depth: false,
		index: 7,
	},
	{
		label: 'Tanggal Tidak Aktif',
		value: 'inactived_date',
		format: '',
		depth: false,
		index: 8,
	},
	{
		label: 'Total Nilai MOU',
		value: 'amount_untaxed',
		format: '',
		depth: false,
		index: 9,
	},
	{
		label: 'Status',
		value: 'state',
		format: '',
		depth: false,
		index: 10,
	},
]

export const defaultMandatory = [
	{
		label: 'No. MOU',
		value: 'name',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Judul MOU',
		value: 'agreement_title',
		format: '',
		depth: false,
		index: 2,
	},
]

export const findSelectedColumn = (index) => {
	index = index.split(',').map(x=>+x)
	const selectedColumn = index.length ? mou_columns.filter((val) => index.indexOf(parseInt(val.index)) > -1) : []
	return selectedColumn
}