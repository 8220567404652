import { makeStyles } from '@material-ui/core/styles'

export const searchStyle = makeStyles((theme) => ({
	alignRight: {
		textAlign: 'right',
	},
	price: {
		'& input.MuiOutlinedInput-inputAdornedStart[type=text]': {
			textAlign: 'right',
			'-webkit-appearance': 'textfield',
			'moz-appearance': 'textfield',
		},
		'& input.MuiOutlinedInput-inputAdornedStart[type=text]::-webkit-inner-spin-button, input.MuiOutlinedInput-inputAdornedStart[type=text]::-webkit-outer-spin-button':
			{
				'-webkit-appearance': 'none',
				'moz-appearance': 'none',
				margin: 0,
			},
	},
	lblGrey: {
		color: '#929aa7',
		'& .MuiTypography-colorTextSecondary': {
			color: '#929aa7',
		},
	},
	mb_3: {
		marginBottom: '1rem!important',
	},
	categoryContainer: {},
	listContainer: {
		paddingLeft: 25,
	},
	gridContainer: {
		marginTop: theme.spacing(2),
	},
	titlePrice: {
		color: theme.palette.secondary.main,
		fontSize: '0.813',
	},
	priceRange: {
		color: theme.palette.alert.dark,
		fontWeight: 900,
	},
	variantAlert: {
		backgroundColor: '#fef2cd',
		display: 'flex',
		marginTop: theme.spacing(1),
		borderRadius: 5,
		padding: theme.spacing(1),
		justifyContent: 'center',
		fontSize: '0.69rem',
	},
	nested: {
		paddingLeft: theme.spacing(1),
	},
	cardImageContainer: {
		height: 200,
		overflow: 'hidden',
	},
	cardTitle: {
		fontSize: '0.87rem',
		textAlign: 'center',
	},
	cardTitleWrapper: {
		overflow: 'hidden',
		overflowWrap: 'break-word',
		height: 45,
	},
	cardContent: {
		padding: 8,
	},
	cardLoader: {
		position: 'relative',
		backgroundColor: 'rgba(191, 186, 186, 0.2)',
		height: 378,
		'&::after': {
			content: '""',
			background: 'rgba(191, 186, 186, 0.2)',
			right: 0,
			bottom: 0,
			left: 0,
			top: 0,
			position: 'absolute',
			animation: '$slideRight .8s cubic-bezier(.66,.12,.36,.93) infinite',
		},
	},
	gridList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 1fr)',
		gridGap: 10,
		marginTop: 2,
	},
	'@keyframes slideRight': {
		from: {
			left: 0,
		},
		to: {
			left: '100%',
		},
	},
	uri: {
		color: 'blue',
	},
}))
