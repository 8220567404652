import React from 'react'

import { Formik, Form, Field } from 'formik'
import {
	Typography,
	TextField,
	Button,
	FormControl,
	// FormControlLabel,
	// Checkbox,
	InputAdornment,
	IconButton,
	OutlinedInput,
	Grid,
	Collapse,
	// Link,
	CircularProgress,
	Box,
} from '@material-ui/core'
import {
	Visibility,
	VisibilityOff,
	HighlightOffOutlined,
} from '@material-ui/icons'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import schema from './schema'
import Alert from '../../alert'
import Modal from '../../modal'
import { registerAction } from 'States/actions/usersAction'
import { requestUserAccountUpdateNewUser } from 'States/actions/UsersAccount/updateNewUserAction'
import queryString from 'query-string'
import API from '../../../utils/api'
import { navigate } from 'gatsby'

// const handleNavigate = path => {
// 	navigate(path)
// }

const AccountForm = ({ classes }) => {
	//verify token, if valid then show form, if invalid then redirect to homepage
	const [fullName, setFullName] = React.useState('Nama')
	const [email, setEmail] = React.useState('')
	const auth = useSelector((state) => state.auth)

	const parsed = queryString.parse(location.search)
	const dataParsed = parsed && parsed.data ? JSON.parse(atob(parsed.data)) : null

	// for is_company == false
	const [userID, setUserID] = React.useState('')
	const [username, setUsername] = React.useState('')
	const [isNewCompany, setIsNewCompany] = React.useState(true)
	const [loading, setLoading] = React.useState(true)
	const dispatch = useDispatch()
	const [showPassword, setShowPassword] = React.useState(false)
	const [showConfPassword, setConfShowPassword] = React.useState(false)
	const [visibleModal, setVisibleModal] = React.useState(false)
	const data = useSelector((state) => state.usersReducer, shallowEqual)
	const dataUpdate = useSelector(
		(state) => state.UsersAccountUpdateNewUserReducer
	)

	React.useEffect(() => {
		dataParsed
			? API({
					method: 'POST',
					url: '/users/verify/' + dataParsed.partner_id,
					data: {
						token: parsed.token,
					},
			  })
					.then((response) => {
						setTimeout(() => {
							setLoading(false)
							if (response.data && response.data.error_backend) {
								// setVisibleModal(true)
								const http_body = JSON.parse(response.data.error_backend.http_body)
								const error = http_body.errors.message
								if (error.includes('Expired token')) {
									navigate('/onboarding-link-expired')
								} else if (error.includes('Invalid token')) {
									navigate('/link-not-available')
								} else if (error.includes('already confirmed')) {
									if (auth.loggedIn) {
										navigate('/dashboard')
									} else {
										navigate('/login')
									}
								} else {
									navigate('/link-not-found')
								}
							} else {
								setFullName(response.data.data.result.name)
								setEmail(response.data.data.result.email)
							}
						}, 100)
					})
					.catch(() => {
						// alert('Terjadi kesalahan! User ini sudah terdaftar di sistem Mbiz.')
						navigate('/404')
					})
			: navigate('/404')
	}, [])

	// check new company
	React.useEffect(() => {
		dataParsed
			? API({
					method: 'GET',
					url: '/users/temp/detail/' + dataParsed.partner_id,
			  })
					.then((response) => {
						setTimeout(() => {
							setLoading(false)
							if (response.status === 200) {
								if (response.data.data.is_new_company == false) {
									setUserID(response.data.data.id)
									setIsNewCompany(response.data.data.is_new_company)
									setUsername(response.data.data.username)
								}
							}
						}, 500)
					})
					.catch(() => {
						// alert('Terjadi kesalahan!')
						navigate('/404')
					})
			: navigate('/404')
	}, [])

	// checking after update
	React.useEffect(() => {
		if (!dataUpdate.isError && dataUpdate.data) {
			navigate('/login')
		}
	}, [dataUpdate])

	const callback = React.useCallback(() => {
		setShowPassword(!showPassword)
	}, [showPassword])

	const callback2 = React.useCallback(() => {
		setConfShowPassword(!showConfPassword)
	}, [showConfPassword])

	const handleSubmitRegister = (val) => {
		const dataCreate = {
			username: val.username,
			password: val.password,
			confirm_password: val.password,
			email: email,
			token_confirmation: parsed.token,
			contact_id: Number(dataParsed.partner_id),
			company_buyer_id: Number(dataParsed.company_buyer_id),
		}
		if (isNewCompany) {
			dispatch(registerAction(dataCreate))
		} else {
			const dataUpdate = {
				password: val.password,
				confirm_password: val.password,
				token_confirmation: parsed.token,
				contact_id: Number(dataParsed.partner_id),
				company_buyer_id: Number(dataParsed.company_buyer_id),
			}
			dispatch(requestUserAccountUpdateNewUser(dataUpdate, userID))
		}
	}

	const navigateToLogin = () => {
		setVisibleModal(false)
		navigate('/login')
	}

	return loading ? (
		<Box my={20}>
			<CircularProgress />
		</Box>
	) : (
		<>
			<Formik
				enableReinitialize
				initialValues={{ username: username, password: '', confirmpassword: '' }}
				validationSchema={schema}
				onSubmit={(values) => handleSubmitRegister(values)}
			>
				{({ errors, handleSubmit, touched }) => {
					return (
						<Form
							onSubmit={handleSubmit}
							method='post'
							className={[classes.form, classes.p_4]}
						>
							<Typography
								className={[classes.title, classes.mb_3].join(' ')}
								text-align='center'
								variant='h3'
							>
								Buat Akun
							</Typography>
							<Typography className={[classes.subtitle, classes.textLeft].join(' ')}>
								Masukkan username dan kata sandi untuk membuat akun.
							</Typography>
							<Collapse
								in={
									(errors.username && touched.username) ||
									(errors.password && touched.password) ||
									(errors.confirmpassword && touched.confirmpassword) ||
									data.isError ||
									dataUpdate.isError
								}
							>
								<Alert iconClose={<HighlightOffOutlined />} variant={'alert'}>
									<Typography variant='body1'>
										{errors.username && touched.username && errors.username}
									</Typography>
									<Typography variant='body1'>
										{errors.password && touched.password && errors.password}
									</Typography>
									<Typography variant='body1'>
										{errors.confirmpassword &&
											touched.confirmpassword &&
											errors.confirmpassword}
									</Typography>
									<Typography variant='body1'>
										{data.isError &&
											// 'Mohon maaf terjadi kesalahan. Silakan coba lagi atau hubungi kami.'}
											data.err}
									</Typography>
								</Alert>
							</Collapse>
							<Field name='fullname'>
								{({ field }) => (
									<TextField
										variant='outlined'
										fullWidth
										size='small'
										margin='dense'
										placeholder={fullName}
										type='input'
										disabled={true}
										{...field}
									/>
								)}
							</Field>

							<Field name='username'>
								{({
									field, // { name, value, onChange, onBlur }
									// form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
								}) => (
									<TextField
										variant='outlined'
										fullWidth
										size='small'
										margin='dense'
										placeholder='Username'
										type='input'
										disabled={!isNewCompany}
										{...field}
									/>
								)}
							</Field>

							<Field name='password'>
								{({ field }) => (
									<FormControl
										className={classes.password}
										variant='outlined'
										margin='dense'
									>
										<OutlinedInput
											id='outlined-adornment-password'
											type={showPassword ? 'text' : 'password'}
											placeholder='Kata Sandi'
											{...field}
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														edge='end'
														onClick={() => callback()}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
								)}
							</Field>

							<Field name='confirmpassword'>
								{({ field }) => (
									<FormControl
										className={classes.password}
										variant='outlined'
										margin='dense'
									>
										<OutlinedInput
											id='outlined-adornment-confirmpassword'
											type={showConfPassword ? 'text' : 'password'}
											placeholder='Konfirmasi Kata Sandi'
											{...field}
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														edge='end'
														onClick={() => callback2()}
													>
														{showConfPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
								)}
							</Field>

							<Grid container>
								<Grid item xs={12} sm={12} className={classes.mb_3}>
									<Typography className={classes.suggestpass}>
										Gunakan kata sandi sekurangnya 6 karakter.
									</Typography>
								</Grid>
							</Grid>

							<Button
								fullWidth
								size='large'
								type='submit'
								variant='contained'
								color='secondary'
								className={classes.formButton}
								disabled={data.isLoading}
							>
								{data.isLoading ? <CircularProgress /> : 'Buat Akun'}
							</Button>
						</Form>
					)
				}}
			</Formik>
			<Modal open={visibleModal} icon='error'>
				<Typography>
					<strong>URL telah kedaluwarsa</strong>
				</Typography>
				<Box mt={2}>
					<Button
						onClick={() => navigateToLogin()}
						color='secondary'
						variant='contained'
						size='small'
					>
						OK
					</Button>
				</Box>
			</Modal>
		</>
	)
}

AccountForm.propTypes = {
	classes: PropTypes.object,
}

export default AccountForm
