import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
	scrollToTop: {
		position: 'fixed',
		bottom: '20px',
		right: '2%',
		width: '35px',
		height: '35px',
		borderRadius: '100%',
		background: '#6C757E',
		color: '#fff',
		zIndex: '1',
		paddingTop: '3px',
		textAlign: 'center',
		cursor: 'pointer',
	},
}))
export default styles
