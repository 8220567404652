const rfq_columns = [
	{
		label: 'No. RFQ',
		value: 'rfq_no',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'No. Quotation',
		value: 'quotation_no',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'No. Referensi PO',
		value: 'client_order_ref',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'Tanggal RFQ Dibuat',
		value: 'rfq_create_date',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'Batas Membuat Quotation',
		value: 'rfq_expiry_date',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'Pemohon',
		value: 'requestor_name',
		format: '',
		depth: false,
		index: 6,
	},
	{
		label: 'Departemen Pemohon',
		value: 'requestor_department',
		format: '',
		depth: false,
		index: 7,
	},
	{
		label: 'Untuk Keperluan',
		value: 'customer_internal_notes',
		format: '',
		depth: false,
		index: 8,
	},
	{
		label: 'Alamat Pengiriman',
		value: 'shipping_address',
		format: '',
		depth: false,
		index: 9,
	},
	{
		label: 'Alamat Tagihan',
		value: 'invoice_address',
		format: '',
		depth: false,
		index: 10,
	},
	{
		label: 'Ekspektasi Pesanan Diterima',
		value: 'expected_delivery_date',
		format: '',
		depth: false,
		index: 11,
	},
	{
		label: 'Nama Penerima',
		value: 'receiver_name',
		format: '',
		depth: false,
		index: 12,
	},
	{
		label: 'No. Telepon Penerima',
		value: 'receiver_phone',
		format: '',
		depth: false,
		index: 13,
	},
	{
		label: 'Catatan Permintaan Khusus',
		value: 'note',
		format: '',
		depth: false,
		index: 14,
	},
	{
		label: 'Nomor SKU',
		value: 'sku_no',
		format: '',
		depth: false,
		index: 15,
	},
	{
		label: 'Produk',
		value: 'sku_name',
		format: '',
		depth: false,
		index: 16,
	},
	{
		label: 'Kuantitas',
		value: 'product_uom_qty',
		format: '',
		depth: false,
		index: 17,
	},
	{
		label: 'Harga/Unit',
		value: 'price_unit',
		format: '',
		depth: false,
		index: 18,
	},
	{
		label: 'Pajak',
		value: 'tax_name',
		format: '',
		depth: false,
		index: 19,
	},
	// {
	// 	label: 'Is Shipping',
	// 	value: 'is_delivery',
	// 	format: '',
	// 	depth: false,
	// 	index: 20,
	// },
	{
		label: 'Total Transaksi',
		value: 'amount_total',
		format: '',
		depth: false,
		index: 21,
	},
	{
		label: 'Alasan Pembatalan',
		value: 'cancelled_reason',
		format: '',
		depth: false,
		index: 22,
	},
]

const qn_columns = [
	{
		label: 'No. Quotation',
		value: 'quotation_no',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'No. RFQ',
		value: 'rfq_no',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'No. Referensi PO',
		value: 'client_order_ref',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'Tanggal Quotation Dibuat',
		value: 'quotation_create_date',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'Batas Membuat PO',
		value: 'validity_date',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'Pemohon',
		value: 'requestor_name',
		format: '',
		depth: false,
		index: 6,
	},
	{
		label: 'Departemen Pemohon',
		value: 'requestor_department',
		format: '',
		depth: false,
		index: 7,
	},
	{
		label: 'Untuk Keperluan',
		value: 'customer_internal_notes',
		format: '',
		depth: false,
		index: 8,
	},
	{
		label: 'Alamat Pengiriman',
		value: 'shipping_address',
		format: '',
		depth: false,
		index: 9,
	},
	{
		label: 'Alamat Tagihan',
		value: 'invoice_address',
		format: '',
		depth: false,
		index: 10,
	},
	{
		label: 'Ekspektasi Pesanan Diterima',
		value: 'expected_delivery_date',
		format: '',
		depth: false,
		index: 11,
	},
	{
		label: 'Nama Penerima',
		value: 'receiver_name',
		format: '',
		depth: false,
		index: 12,
	},
	{
		label: 'No. Telepon Penerima',
		value: 'receiver_phone',
		format: '',
		depth: false,
		index: 13,
	},
	{
		label: 'Catatan Permintaan Khusus',
		value: 'note',
		format: '',
		depth: false,
		index: 14,
	},
	{
		label: 'Nomor SKU',
		value: 'sku_no',
		format: '',
		depth: false,
		index: 15,
	},
	{
		label: 'Produk',
		value: 'sku_name',
		format: '',
		depth: false,
		index: 16,
	},
	{
		label: 'Kuantitas',
		value: 'product_uom_qty',
		format: '',
		depth: false,
		index: 17,
	},
	{
		label: 'Harga/Unit',
		value: 'price_unit',
		format: '',
		depth: false,
		index: 18,
	},
	{
		label: 'Pajak',
		value: 'tax_name',
		format: '',
		depth: false,
		index: 19,
	},
	{
		label: 'Is Shipping',
		value: 'is_delivery',
		format: '',
		depth: false,
		index: 20,
	},
	{
		label: 'Total Transaksi',
		value: 'amount_total',
		format: '',
		depth: false,
		index: 21,
	},
	{
		label: 'Tahap',
		value: 'state',
		format: '',
		depth: false,
		index: 22,
	},
	{
		label: 'Status',
		value: 'quotation_status',
		format: '',
		depth: false,
		index: 23,
	},
	{
		label: 'Alasan Pembatalan',
		value: 'cancelled_reason',
		format: '',
		depth: false,
		index: 24,
	},
]

const pr_columns = [
	{
		label: 'No. Purchase Request',
		value: 'purchase_request_no',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'No. Quotation',
		value: 'quotation_no',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'No. Referensi PO',
		value: 'client_order_ref',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'Waktu PR Dibuat',
		value: 'create_at',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'Batas Membuat PO',
		value: 'expire_at',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'Pemohon',
		value: 'requestor_name',
		format: '',
		depth: false,
		index: 6,
	},
	{
		label: 'Departemen Pemohon',
		value: 'requestor_department',
		format: '',
		depth: false,
		index: 7,
	},
	{
		label: 'Untuk Keperluan',
		value: 'customer_internal_notes',
		format: '',
		depth: false,
		index: 8,
	},
	{
		label: 'Alamat Pengiriman',
		value: 'shipping_address',
		format: '',
		depth: false,
		index: 9,
	},
	{
		label: 'Alamat Tagihan',
		value: 'invoice_address',
		format: '',
		depth: false,
		index: 10,
	},
	{
		label: 'Nama Penerima',
		value: 'receiver_name',
		format: '',
		depth: false,
		index: 11,
	},
	{
		label: 'No. Telepon Penerima',
		value: 'receiver_phone',
		format: '',
		depth: false,
		index: 12,
	},
	{
		label: 'Catatan Untuk Manajerial',
		value: 'note',
		format: '',
		depth: false,
		index: 13,
	},
	{
		label: 'Catatan Permintaan Khusus',
		value: 'term_note',
		format: '',
		depth: false,
		index: 14,
	},
	{
		label: 'Nomor SKU',
		value: 'sku_no',
		format: '',
		depth: false,
		index: 15,
	},
	{
		label: 'Produk',
		value: 'sku_name',
		format: '',
		depth: false,
		index: 16,
	},
	{
		label: 'Kuantitas',
		value: 'product_uom_qty',
		format: '',
		depth: false,
		index: 17,
	},
	{
		label: 'Harga/Unit',
		value: 'price_unit',
		format: '',
		depth: false,
		index: 18,
	},
	{
		label: 'Pajak',
		value: 'tax_name',
		format: '',
		depth: false,
		index: 19,
	},
	{
		label: 'Is Shipping',
		value: 'is_delivery',
		format: '',
		depth: false,
		index: 20,
	},
	{
		label: 'Total Transaksi',
		value: 'amount_total',
		format: '',
		depth: false,
		index: 21,
	},
	{
		label: 'Tahap',
		value: 'state_label',
		format: '',
		depth: false,
		index: 22,
	},
	{
		label: 'Status',
		value: 'status_label',
		format: '',
		depth: false,
		index: 23,
	},
	{
		label: 'Alasan Pembatalan',
		value: 'status_reason',
		format: '',
		depth: false,
		index: 24,
	},
]

const po_columns = [
	{
		label: 'No. Purchase Order',
		value: 'purchase_order_no',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'No. Purchase Request',
		value: 'purchase_request_no',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'No. Quotation',
		value: 'quotation_no',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'No. RFQ',
		value: 'rfq_no',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'No. Referensi PO',
		value: 'client_order_ref',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'Tanggal PO Dibuat',
		value: 'date_order',
		format: '',
		depth: false,
		index: 6,
	},
	{
		label: 'Pemohon',
		value: 'requestor_name',
		format: '',
		depth: false,
		index: 7,
	},
	{
		label: 'Departemen Pemohon',
		value: 'requestor_department',
		format: '',
		depth: false,
		index: 8,
	},
	{
		label: 'Untuk Keperluan',
		value: 'customer_internal_notes',
		format: '',
		depth: false,
		index: 9,
	},
	{
		label: 'Alamat Pengiriman',
		value: 'shipping_address',
		format: '',
		depth: false,
		index: 10,
	},
	{
		label: 'Alamat Tagihan',
		value: 'invoice_address',
		format: '',
		depth: false,
		index: 11,
	},
	{
		label: 'Ekspektasi Pesanan Diterima',
		value: 'expected_delivery_date',
		format: '',
		depth: false,
		index: 12,
	},
	{
		label: 'Nama Penerima',
		value: 'receiver_name',
		format: '',
		depth: false,
		index: 13,
	},
	{
		label: 'No. Telepon Penerima',
		value: 'receiver_phone',
		format: '',
		depth: false,
		index: 14,
	},
	{
		label: 'Catatan Permintaan Khusus',
		value: 'note',
		format: '',
		depth: false,
		index: 15,
	},
	{
		label: 'Nomor SKU',
		value: 'sku_no',
		format: '',
		depth: false,
		index: 16,
	},
	{
		label: 'Produk',
		value: 'sku_name',
		format: '',
		depth: false,
		index: 17,
	},
	{
		label: 'Kuantitas',
		value: 'product_uom_qty',
		format: '',
		depth: false,
		index: 18,
	},
	{
		label: 'Harga/Unit',
		value: 'price_unit',
		format: '',
		depth: false,
		index: 19,
	},
	{
		label: 'Pajak',
		value: 'tax_name',
		format: '',
		depth: false,
		index: 20,
	},
	{
		label: 'Is Shipping',
		value: 'is_delivery',
		format: '',
		depth: false,
		index: 21,
	},
	{
		label: 'Total Transaksi',
		value: 'amount_total',
		format: '',
		depth: false,
		index: 22,
	},
	{
		label: 'Tahap',
		value: 'state',
		format: '',
		depth: false,
		index: 23,
	},
	{
		label: 'Status Pengiriman',
		value: 'shipping_status',
		format: '',
		depth: false,
		index: 24,
	},
	{
		label: 'Alasan Pembatalan',
		value: 'cancelled_reason',
		format: '',
		depth: false,
		index: 25,
	},
]

const invoices_column = [
	{
		label: 'No. Tagihan',
		value: 'invoice_no',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Tanggal Tagihan Dibuat',
		value: 'create_date',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Tanggal Tagihan Aktif',
		value: 'finance_created_date',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'No. Purchase Order',
		value: 'purchase_order_no',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'No. DN/BAST',
		value: 'shipping_number',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'No. Referensi PO',
		value: 'purchase_order_ref',
		format: '',
		depth: false,
		index: 6,
	},
	{
		label: 'Nomor SKU',
		value: 'sku_no',
		format: '',
		depth: false,
		index: 7,
	},
	{
		label: 'Produk',
		value: 'sku_name',
		format: '',
		depth: false,
		index: 8,
	},
	{
		label: 'Kuantitas',
		value: 'product_uom_qty',
		format: '',
		depth: false,
		index: 9,
	},
	{
		label: 'Harga/Unit',
		value: 'price_unit',
		format: '',
		depth: false,
		index: 10,
	},
	{
		label: 'Pajak',
		value: 'tax_name',
		format: '',
		depth: false,
		index: 11,
	},
	{
		label: 'Is Shipping',
		value: 'is_delivery',
		format: '',
		depth: false,
		index: 12,
	},
	{
		label: 'Total Tagihan',
		value: 'invoice_total',
		format: '',
		depth: false,
		index: 13,
	},
	{
		label: 'Sisa Pembayaran',
		value: 'payment_due_total',
		format: '',
		depth: false,
		index: 14,
	},
	{
		label: 'Jangka Waktu',
		value: 'payment_term',
		format: '',
		depth: false,
		index: 15,
	},
	{
		label: 'Tanggal Jatuh Tempo',
		value: 'invoice_due_date',
		format: '',
		depth: false,
		index: 16,
	},
	{
		label: 'Alamat Pengiriman',
		value: 'shipping_address',
		format: '',
		depth: false,
		index: 17,
	},
	{
		label: 'Alamat Tagihan',
		value: 'invoice_address',
		format: '',
		depth: false,
		index: 18,
	},
	{
		label: 'Tipe',
		value: 'state',
		format: '',
		depth: false,
		index: 19,
	},
	{
		label: 'Status Pembayaran',
		value: 'payment_state',
		format: '',
		depth: false,
		index: 20,
	},
]

const payment_colums = [
	{
		label: 'No. Pembayaran',
		value: 'payment_no',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'No. Tagihan',
		value: 'invoice_no',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Tanggal Transfer',
		value: 'payment_date',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'Tanggal Konfirmasi',
		value: 'confirmation_date',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'Transfer Dari',
		value: 'partner_bank_account',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'Transfer Ke',
		value: 'mbiz_bank_account',
		format: '',
		depth: false,
		index: 6,
	},
	{
		label: 'Total Tagihan',
		value: 'amount',
		format: '',
		depth: false,
		index: 7,
	},
	{
		label: 'Jumlah Pembayaran',
		value: 'payment_total',
		format: '',
		depth: false,
		index: 8,
	},
	{
		label: 'Sisa Pembayaran',
		value: 'amount_residual',
		format: '',
		depth: false,
		index: 9,
	},
	{
		label: 'Status',
		value: 'state',
		format: '',
		depth: false,
		index: 10,
	},
]

export {
	rfq_columns,
	qn_columns,
	po_columns,
	pr_columns,
	payment_colums,
	invoices_column,
}
