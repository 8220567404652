import { DETAIL_RFQ } from '../../../types'
import { success, failure, loading } from '../../../commonActions'
import API from '../../../../utils/api'
import { navigate } from 'gatsby'

export const requestDetailRfq = (rfqId) => {
	return async (dispatch) => {
		dispatch(loading(DETAIL_RFQ))
		try {
			const response = await API({
				method: 'GET',
				url: `/transactions/rfqs/detail/${rfqId}`,
			})
			if (response.data) {
				if ('error_backend' in response.data) {
					navigate(`/${response.data.error_backend.http_status_code}`)
				} else {
					dispatch(success(DETAIL_RFQ, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(DETAIL_RFQ, err))
		}
	}
}

export const cancelRfq = (data) => {
	return API({
		method: 'PUT',
		url: `/transactions/sale-order/cancel/state/${data.id}`,
		data: {
			cancelled_reason: data.cancelled_reason,
		},
	})
}

export const resetAction = () => {
	return {
		type: `${DETAIL_RFQ}_RESET`,
	}
}