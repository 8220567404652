import React, { useState, useEffect, useMemo } from 'react'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	Paper,
	Container,
	Button,
} from '@material-ui/core'
import Breadcrumbs from 'Components/breadcrumbs'
import { ProfileDetailStyles } from './styles'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import HelpIcon from '@material-ui/icons/Help'
import { RolesDialog, DepartmentDialog } from 'Components/dialogs'
import Image from 'material-ui-image'
import { capitalizeFirst } from '../../../utils/globalUtils'
import profileImg from '../../../assets/images/icons/profile.png'

const imageStorage = process.env.GATSBY_IMAGE_URL
const breadcrums = [
	{
		segment: 'Home',
		link: '/dashboard',
	},
	{
		segment: 'Profil Saya',
		link: '/dashboard/profile',
	},
]

const initDataProfile = {
	name: '',
	email: '',
	mobile: '',
	jobTitle: '',
	contactDetails: null,
}

const ProfileDetail = () => {
	// const dispatch = useDispatch()
	const classes = ProfileDetailStyles()
	const auth = useSelector((state) => state.auth.data)
	const userProfile = useSelector((state) => state.userProfileReducer)
	const [dataProfile, setDataProfile] = useState(initDataProfile)
	const [open, setOpen] = useState(false)
	const [open2, setOpen2] = useState(false)
	const [scroll, setScroll] = useState('paper')
	const [showDepartemen, setShowDepartemen] = useState(false)
	const user_buyer_roles = auth.data.user_buyer_roles
		? auth.data.user_buyer_roles
		: []
	const roles = user_buyer_roles
		.map((item) =>
			item.user_roles.role_name == 'staff'
				? 'Pemohon'
				: item.user_roles.role_name == 'manager'
				? 'Penyetuju'
				: capitalizeFirst(item.user_roles.role_name)
		)
		.join(', ')

	useMemo(() => {
		if (userProfile.data != null && userProfile.isLoading == false) {
			let dataTemp = dataProfile
			;(dataTemp.name = userProfile.data.name),
				(dataTemp.email = userProfile.data.email),
				(dataTemp.mobile = userProfile.data.mobile),
				(dataTemp.jobTitle = userProfile.data.job_title),
				(dataTemp.contactDetails = userProfile.data.contact_details),
				setDataProfile(dataTemp)
		}
	}, [userProfile.data, userProfile.isLoading])

	useEffect(() => {
		if (roles.length && roles.includes('Pemohon')) setShowDepartemen(true)
		else setShowDepartemen(false)
	}, [roles])

	const handleClickOpen = (scrollType) => () => {
		setOpen(true)
		setScroll(scrollType)
	}

	const handleClickOpen2 = (scrollType) => () => {
		setOpen2(true)
		setScroll(scrollType)
	}

	return (
		<DashboardLayout>
			<Container>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>
				<Box mt={2}>
					<Typography variant='h6'>Profil Saya</Typography>
				</Box>

				<Box mt={2}>
					<Paper>
						<Box p={1}>
							<Grid container spacing={2}>
								<Grid container item xs={2} alignItems='center' justify='center'>
									<Grid item>
										<Box className={classes.image}>
											{userProfile.isLoading &&
											!dataProfile.contactDetails?.image_512_filestore ? (
												<img
													alt='default-avatar'
													src={profileImg}
													style={{
														width: 'auto',
														height: '100%',
													}}
												/>
											) : (
												<Image
													src={`${imageStorage}/180x180/erp/${dataProfile.contactDetails?.image_512_filestore}`}
													style={{
														objectFit: 'contain',
														width: '100%',
														height: '100%',
														zIndex: 1,
													}}
												/>
											)}
										</Box>
									</Grid>
								</Grid>
								<Grid container item xs={4} alignItems='center'>
									<Grid container item xs={5} alignItems='center'>
										<Typography variant='subtitle1'>Nama Lengkap</Typography>
									</Grid>
									<Grid container item xs={7} alignItems='center'>
										<Typography variant='body1'>
											{userProfile.isLoading == false && dataProfile.name}
										</Typography>
									</Grid>
									<Grid container item xs={5} alignItems='center'>
										<Typography variant='subtitle1'>Email</Typography>
									</Grid>
									<Grid container item xs={7} alignItems='center'>
										<Typography variant='body1'>
											{userProfile.isLoading == false && dataProfile.email}
										</Typography>
									</Grid>
									<Grid container item xs={5} alignItems='center'>
										<Typography variant='subtitle1'>No.Ponsel</Typography>
									</Grid>
									<Grid container item xs={7} alignItems='center'>
										<Typography variant='body1'>
											{(userProfile.isLoading == false && dataProfile.mobile) || '-'}
										</Typography>
									</Grid>
								</Grid>
								<Grid container item xs={6}>
									<Grid container item xs={6} alignItems='center'>
										<Typography variant='subtitle1'>Jabatan</Typography>
									</Grid>
									<Grid container item xs={6} alignItems='center'>
										<Typography variant='body1'>
											{(userProfile.isLoading == false && dataProfile.jobTitle) || '-'}
										</Typography>
									</Grid>
									<Grid container item xs={6} alignItems='center'>
										<Typography variant='subtitle1'>Role(s)</Typography>
										<Button
											onClick={handleClickOpen('paper')}
											size='small'
											className={classes.customTooltip}
										>
											<HelpIcon className={classes.customIcon} />
										</Button>
										<RolesDialog
											open={open}
											handleClose={() => setOpen(false)}
											scroll={scroll}
										/>
									</Grid>
									<Grid container item xs={6} alignItems='center'>
										<Typography variant='body1'>{roles}</Typography>
									</Grid>
									{!showDepartemen ? (
										<Grid container item xs={6} alignItems='center'>
											<Typography variant='subtitle1'>&nbsp;</Typography>
										</Grid>
									) : (
										<>
											<Grid container item xs={6} alignItems='center'>
												<Typography component='span' variant='subtitle1'>
													Departemen Untuk Pembelian
													<Button
														onClick={handleClickOpen2('paper')}
														size='small'
														className={classes.customTooltip}
													>
														<HelpIcon className={classes.customIcon} />
													</Button>
													<DepartmentDialog
														open={open2}
														handleClose={() => setOpen2(false)}
													/>
												</Typography>
											</Grid>
											<Grid container item xs={6} alignItems='center'>
												<Typography variant='body1'>
													{auth.data.user_department.company_departments.name &&
													auth.data.user_department.company_departments.name != ''
														? auth.data.user_department.company_departments.name
														: '-'}
												</Typography>
											</Grid>
										</>
									)}
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Box>

				<Box mt={2}>
					<Grid container justify='flex-end'>
						<Box>
							<Button
								variant='outlined'
								color='primary'
								onClick={(event) => {
									event.preventDefault()
									navigate('/dashboard'),
										{
											replace: true,
										}
								}}
							>
								Kembali
							</Button>
							<Button
								variant='contained'
								color='secondary'
								style={{ marginLeft: 5 }}
								onClick={(event) => {
									event.preventDefault()
									navigate('/dashboard/profile/edit'),
										{
											replace: true,
										}
								}}
							>
								Edit
							</Button>
						</Box>
					</Grid>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

export default ProfileDetail
