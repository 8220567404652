import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import Modal from 'Components/modal'
import { Styles } from './styles'

const Component = (props) => {
	const classes = Styles()
	return (
		<Modal
			open={props.succesModal}
			icon={props.modalStatus}
			isNeedMarginBottom={false}
		>
			<Box mt={0}>
				<Typography variant='body1' sx={{ marginTop: -10 }}>
					<strong>
						{props.modalStatus == 'success'
							? props.isUpdate
								? 'Anggaran berhasil diperbarui'
								: 'Anggaran berhasil disimpan'
							: props.isUpdate
							? 'Anggaran gagal diperbarui'
							: 'Anggaran gagal disimpan'}
					</strong>
				</Typography>
				{props.modalStatus == 'success' ? (
					<Button
						variant='contained'
						color='default'
						size='small'
						onClick={() => props.handleSuccessCreate()}
						className={classes.marginTop15}
					>
						OK
					</Button>
				) : (
					<Button
						variant='outlined'
						size='small'
						onClick={() => props.onClose()}
						className={classes.button}
					>
						Kembali
					</Button>
				)}
			</Box>
		</Modal>
	)
}

Component.propTypes = {
	succesModal: PropTypes.bool,
	modalStatus: PropTypes.string,
	onClose: PropTypes.func,
	handleSuccessCreate: PropTypes.func,
	isUpdate: PropTypes.bool,
}

export default Component
