import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
	uri: {
		color: '#357ab6',
		cursor: 'pointer',
		textDecoration: 'underline',
	},
}))

export default useStyles
