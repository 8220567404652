/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
	Box,
	Container,
	Typography,
	Button,
	Grid,
	TextField,
	IconButton,
	Link,
} from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'
import useStyles from './styles'
import { navigate } from '@reach/router'
import { requestRFQList } from 'States/actions/Transactions/RFQ/RFQListAction'
import { format } from 'date-fns'
import * as qs from 'query-string'
import {
	newUri,
	dateFormat,
	dateFormatFromOdoo,
	isValidDate,
} from '../../../../utils/globalUtils'
import { formatMoney } from '../../../../utils/formatMoney'
import Breadcrumbs from 'Components/breadcrumbs'
import TableData from 'Components/tableData'
import DashboardLayout from 'Components/dashboardLayout'
import ListIcon from '@material-ui/icons/List'
import DatePickerRange from 'Components/DateRange'

const TransactionsRFQList = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const rfqList = useSelector((state) => state.RFQListReducer)

	const [range, setRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	])
	const [dataRFQ, setDataRFQ] = useState([])

	const queryString = qs.parse(props.location.search)

	const [perPage, setPerPage] = useState(
		queryString.per_page ? queryString.per_page : 10
	)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [transactionNo, setTransactionNo] = useState(
		queryString.transaction_no ? queryString.transaction_no : ''
	)
	const [startDate, setStartDate] = useState(
		queryString.start_date ? queryString.start_date : ''
	)
	const [endDate, setEndDate] = useState(
		queryString.end_date ? queryString.end_date : ''
	)
	const [state, setState] = useState(queryString.state ? queryString.state : '')

	queryString.per_page = perPage
	queryString.page = page
	queryString.transaction_no = transactionNo
	queryString.start_date = startDate
	queryString.end_date = endDate
	queryString.state = state

	const joinDate = (startDate, endDate) => `${startDate} ~ ${endDate}`

	const [placeHolder, setPlaceHolder] = useState(
		startDate && endDate ? joinDate(startDate, endDate) : 'Rentang Tanggal'
	)
	const [color, setColor] = useState(
		isValidDate(startDate) && isValidDate(startDate) ? '#007BFF' : '#a9acaf'
	)

	const handleOnChange = (item) => {
		const str = joinDate(
			format(item.selection.startDate, 'yyyy-MM-dd'),
			format(item.selection.endDate, 'yyyy-MM-dd')
		)
		setPlaceHolder(str)
		setRange([item.selection])
		setColor('#007BFF')
		setStartDate(dateFormat(item.selection.startDate, 'yyyy-MM-dd'))
		setEndDate(dateFormat(item.selection.endDate, 'yyyy-MM-dd'))
		queryString.start_date = dateFormat(item.selection.startDate, 'yyyy-MM-dd')
		queryString.end_date = dateFormat(item.selection.endDate, 'yyyy-MM-dd')
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleOnClear = () => {
		setPlaceHolder('Rentang Tanggal')
		setColor('#a9acaf')
		setRange([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		])
		setStartDate('')
		setEndDate('')
		queryString.start_date = ''
		queryString.end_date = ''
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	useEffect(() => {
		dispatch(requestRFQList({ queryString }))
	}, [])

	// hooks for set default state
	useEffect(() => {
		// success
		if (rfqList.data && rfqList.data.result) {
			let result = rfqList.data.result
			if (result) {
				setDataRFQ(result)
			}
		} else {
			setDataRFQ([])
		}
	}, [rfqList])

	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Transaksi',
			link: '#',
		},
		{
			segment: 'RFQ',
			link: '#',
		},
	]

	const handleClickDetail = (id) => {
		navigate(`/dashboard/rfq/${id}`)
	}

	const columns = [
		{
			name: 'NO. RFQ',
			selector: 'rfq_no',
			sortable: true,
			hide: 'md',
			grow: 3,
			cell: (row) => (
				<Link
					href={`/dashboard/rfq/${row.rfq_no}`}
					color='secondary'
					target='_blank'
				>
					{row.rfq_no}
				</Link>
			),
		},
		{
			name: 'TGL RFQ DIBUAT',
			selector: 'create_date',
			sortable: true,
			cell: (row) => dateFormatFromOdoo(row.create_date, 'dd-MMM-yyyy'),
			grow: 2,
		},
		{
			name: 'BATAS MEMBUAT QUOTATION',
			selector: 'rfq_expiry_date',
			sortable: true,
			cell: (row) => dateFormatFromOdoo(row.rfq_expiry_date, 'dd-MMM-yyyy'),
			grow: 2,
		},
		{
			name: 'PEMOHON',
			selector: 'requestor_name',
			sortable: true,
			grow: 2,
		},
		{
			name: 'TOTAL TRANSAKSI',
			selector: 'amount_total',
			sortable: true,
			cell: (row) => formatMoney(Math.round(row.amount_total)),
			grow: 2,
		},
		// Commented until implemented again
		// {
		// 	name: 'TAHAP',
		// 	selector: 'state',
		// 	sortable: true,
		// 	cell: row => <Status status={row.state} />,
		// 	grow: 2,
		// },
		{
			name: 'AKSI',
			selector: 'aksi',
			sortable: true,
			hide: 'md',
			cell: (row) => (
				<Button
					id='btn-detail-rfq'
					onClick={() => handleClickDetail(row.rfq_no)}
					className={classes.buttonAction}
					variant='contained'
					color='secondary'
					size='small'
				>
					<ListIcon className={classes.iconAction} />
				</Button>
			),
		},
	]

	const limitValue = [10, 25, 50, 100]

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setPerPage(event.target.value)
		queryString.per_page = event.target.value
		let maxPage = Math.ceil(
			rfqList.data.pagination.total_records / queryString.per_page
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		submitSearch()
	}

	const handleChangeSearchRFQ = (e) => {
		let value = e.target.value.trim()
		setTransactionNo(value)
		setPage(1)
		queryString.transaction_no = value
		queryString.page = 1
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => submitSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [transactionNo])

	function submitSearch() {
		let query = newUri(queryString)
		dispatch(requestRFQList({ queryString }))
		propsNavigate(query)
	}

	const handleButtonState = (event, btnState) => {
		setState(btnState)
		setPage(1)
		queryString.state = btnState
		queryString.page = 1
		submitSearch()
	}

	const handleSearchRFQ = () => {
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleEnterSearchRFQ = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>
				<Box className={'searchContainer'}>
					<Typography variant='h6'>Daftar Permintaan RFQ</Typography>
					<br />
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<DatePickerRange
								ranges={range}
								color={color}
								onSelectDate={(item) => handleOnChange(item)}
								placeholder={placeHolder}
								onClear={() => handleOnClear()}
							/>
						</Grid>
						<Grid item xs={3}>
							<Grid item className={classes.gridSearch}>
								<TextField
									id='field-search-rfq'
									placeholder='Cari No RFQ'
									type='search'
									className={'nameSearch'}
									InputProps={{
										disableUnderline: false,
									}}
									onChange={(e) => handleChangeSearchRFQ(e)}
									onKeyDown={(e) => handleEnterSearchRFQ(e)}
									// onBlur={e => handleChangeSearchRFQ(e)}
									defaultValue={queryString.transaction_no}
								/>
								<IconButton
									className={classes.buttonSearch}
									variant='contained'
									color='primary'
									onClick={handleSearchRFQ}
								>
									<SearchRounded fontSize='small' />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<br />
				<TableData
					// buttonStatus={buttonStatus}
					buttonStatusClick={handleButtonState}
					limitValue={limitValue}
					perPage={perPage}
					limitChange={handlePerRowsChange}
					columns={columns}
					isLoading={rfqList.isLoading}
					pagination={
						!rfqList.isLoading && rfqList.data ? rfqList.data.pagination : false
					}
					data={dataRFQ}
					name={'RFQ'}
					paginationPageChange={handlePageChange}
					pageNow={parseInt(queryString.page)}
				/>
			</Container>
		</DashboardLayout>
	)
}

TransactionsRFQList.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default TransactionsRFQList
