/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import LayoutComponent from 'Components/layout'
import { navigate } from '@reach/router'
import {
	Box,
	Typography,
	Grid,
	Button,
	CircularProgress,
	MenuItem,
} from '@material-ui/core'
import Slugify from 'slugify'
import { searchStyle } from './styles'
import Pagination from 'rc-pagination'
import localeInfo from 'rc-pagination/lib/locale/id_ID'
import 'rc-pagination/assets/index.css'
import * as qs from 'query-string'
import { Dropdown } from '../../components/dropdown'
import { HighlightOff } from '@material-ui/icons'
import CategoryList from 'Components/category'
import Alert from 'Components/alert'
import Breadcrumbs from 'Components/breadcrumbs'
import CardListing from 'Components/cardListing'
import PropTypes from 'prop-types'
import { FilterIcon, SuccessCircle } from 'Components/svgs'
import { useDispatch, useSelector } from 'react-redux'
import {
	requestCatalog,
	getDetailCategoriesSlug,
} from 'States/actions/catalogAction'
import { formatMoney } from '../../utils/formatMoney'
import {
	getAggsDataFilter,
	getAggsDataId,
	getParentPath,
	getChildFilterData,
} from '../../utils/category'
import { handleBreadCrumbs } from '../../utils/breadcrumb'
import image_not_found from '../../assets/images/content/image_not_found.png'
import xsearchProductImg from '../../assets/images/background/xsearch_product.png'
import xsearchImg from '../../assets/images/background/xsearch.png'

const menuData = [
	{ label: 25, value: 25 },
	{ label: 50, value: 50 },
	{ label: 75, value: 75 },
	{ label: 100, value: 100 },
]

const handleNavHome = () => {
	window.location.href = '/'
}

const renderContent = (props, err, errSearch, catalog) => {
	const queryString = qs.parse(props.location.search)
	return (
		<Box
			display='flex'
			justifyContent='center'
			marginTop={3}
			marginBottom={5}
			alignItems='center'
			flexDirection='column'
		>
			{err && catalog.isLoading == false ? (
				<Box display='flex' width='50%' marginBottom={1}>
					<img src={xsearchImg} alt='error' width='100%' height='100%' />
				</Box>
			) : errSearch && catalog.isLoading == false ? (
				<Box display='flex' width='50%' marginBottom={1}>
					<img src={xsearchProductImg} alt='error' width='100%' height='100%' />
				</Box>
			) : (
				<CircularProgress value={50} size={60} thickness={4} />
			)}

			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
			>
				{err ? (
					<Typography variant='body1'>
						<strong>{`Mohon maaf, hasil pencarian kata kunci "${queryString.q}" tidak ditemukan.`}</strong>
					</Typography>
				) : errSearch ? (
					<Typography variant='body1'>
						<strong>{'Mohon maaf, produk yang anda cari tidak ditemukan.'}</strong>
					</Typography>
				) : (
					<CircularProgress />
				)}

				{err ? (
					<Typography variant='body2'>
						Silakan coba kata kunci lain atau kembali ke
						<Button
							onClick={handleNavHome}
							style={{
								textTransform: 'none',
								backgroundColor: 'transparent',
								fontSize: '14px',
								padding: '0',
							}}
							size='small'
							color='secondary'
						>
							Beranda.
						</Button>
					</Typography>
				) : errSearch ? (
					<Typography variant='body2'>Silakan melakukan pencarian ulang</Typography>
				) : null}
			</Box>
		</Box>
	)
}

const Component = (props) => {
	const classes = searchStyle()
	const apiUrl = process.env.GATSBY_IMAGE_URL

	const [err, setErr] = useState(false)
	const [errSearch, setErrSearch] = useState(false)
	const [min_price, setMinPrice] = useState('')
	const [max_price, setMaxPrice] = useState('')
	const [filterCatData, setFilterCatData] = useState([])
	const [globalAllParentId, setGlobalAllParentId] = useState([])
	const [dropdownList, setDropdownList] = useState(false)

	const [breadCrumbs, setBreadCrumbs] = useState([
		{ segment: 'Home', link: '/' },
		{ segment: 'Semua Kategori', link: '/catalog/search?q=' },
	])

	const customeLocalInfo = localeInfo
		? ((localeInfo.jump_to = 'Menuju halaman ke'), localeInfo)
		: null

	const handleChangePrice = (e, type) => {
		if (type == 'min_price') {
			let numOnly = e.target.value.match('^[0-9]*$') ? e.target.value : min_price
			setMinPrice(numOnly)
		} else {
			let numOnly = e.target.value.match('^[0-9]*$') ? e.target.value : max_price
			setMaxPrice(numOnly)
		}
	}

	const dispatch = useDispatch()
	const catalog = useSelector((state) => state.catalogReducer.catalog)
	const catalogPrice = useSelector((state) => state.catalogReducer.catalogPrice)
	const listFilterCat = useSelector((state) => state.listFilterCategoryReducer)
	const categorySlugs = useSelector((state) => state.categorySlug)

	const auth = useSelector((state) => state.auth)

	let query = qs.parse(window.location.search)

	useEffect(() => {
		let queryString = qs.parse(props.location.search)
		let total_record = catalog.data.pagination
			? catalog.data.pagination.total_record
			: 0
		let maxPage = 0
		if (total_record > 0) {
			maxPage = Math.ceil(total_record / queryString.limit)
		}
		if (props.slug != 'search') {
			dispatch(getDetailCategoriesSlug(props.slug))
		}
		dispatch(requestCatalog({ slug: props.slug, queryString, auth }))
	}, [props.slug])

	useEffect(() => {
		if (!categorySlugs.isLoading && categorySlugs.data) {
			let temp_breadcrumbs = [{ segment: 'Home', link: '/' }]
			categorySlugs.data && categorySlugs.data.slug_compile
				? categorySlugs.data.slug_compile.forEach((item) => {
						temp_breadcrumbs.push({
							segment: item.name,
							link: item.slug,
						})
				  })
				: null
			setBreadCrumbs([...temp_breadcrumbs])
		}
	}, [categorySlugs.isLoading, categorySlugs.data])

	useEffect(() => {
		let queryString = qs.parse(props.location.search)
		if (
			(catalog.data && !catalog.data) ||
			(catalog.data && catalog.data.items == null)
		) {
			props.slug && props.slug != 'search'
				? (setErrSearch(true), errSearch ? setErr(false) : null)
				: (setErr(true), err ? setErrSearch(false) : null)
		} else {
			props.slug && props.slug != 'search'
				? (setErrSearch(false), errSearch ? setErr(false) : null)
				: (setErr(false), err ? setErrSearch(false) : null)
		}

		if (
			catalog.isLoading == false &&
			listFilterCat.isLoading == false &&
			catalog.data &&
			catalog.data.aggs_catalog &&
			props.slug == 'search'
		) {
			let result = []
			let allParentId = []
			allParentId = getAggsDataId(
				catalog.data.aggs_catalog,
				listFilterCat.data,
				null,
				'id'
			)
			setGlobalAllParentId([...allParentId])

			if (queryString.q == '') {
				result = listFilterCat.data
			} else {
				result = getAggsDataFilter(listFilterCat.data, allParentId)
			}

			setFilterCatData([...result])

			setBreadCrumbs([
				{ segment: 'Home', link: '/' },
				{ segment: 'Semua Kategori', link: '/catalog/search?q=' },
			])
		} else {
			setGlobalAllParentId([])
			setFilterCatData([...listFilterCat.data])
		}
	}, [catalog, listFilterCat])

	const handleNavigate = (path, productId, productName) => {
		const slug = Slugify(`${productName} ${productId} ${productId} `)
		// window.location.href = `${path}/${slug}`
		props.navigate(`${path}/${slug}`)
	}

	const generateLink = (path, productId, productName) => {
		const slug = Slugify(`${productName} ${productId} ${productId} `)
		return `${path}/${slug}`
	}

	const handlePrice = (index, id) => {
		let price = ''
		catalogPrice.data.map((val) => {
			if (val.result.product_template_id == id) {
				price = formatMoney(val.result.lowest_price)
			}
		})
		return price
	}

	const handleChangePagination = (e) => {
		let queryString = qs.parse(props.location.search)
		queryString.page = e
		let newUri = Object.keys(queryString)
			.map((key) => key + '=' + queryString[key])
			.join('&')
		window.history.replaceState(null, '', `${props.slug}?${newUri}`)
		dispatch(requestCatalog({ slug: props.slug, queryString, auth }))
	}

	const handleSubmitFilter = () => {
		let queryString = qs.parse(props.location.search)
		if (min_price != '' && min_price > 0) {
			queryString.min_price = min_price
		} else {
			queryString.min_price ? delete queryString.min_price : null
		}
		if (max_price != '' && max_price > 0) {
			queryString.max_price = max_price
		} else {
			queryString.max_price ? delete queryString.max_price : null
		}

		let newUri = Object.keys(queryString)
			.map((key) => key + '=' + queryString[key])
			.join('&')
		props.navigate(`/catalog/search?${newUri}`)
	}

	const generateQs = (type) => {
		let queryString = qs.parse(props.location.search)
		if (type == 'keyword') {
			if ((queryString.q == '' || queryString.q == null) && props.slug == 'search')
				return 'Semua Produk'
			else if (
				props.slug != 'search' &&
				(queryString.q == '' || queryString.q == null)
			)
				return props.slug
			else return queryString.q
		}

		if ((queryString.q == '' || queryString.q == null) && props.slug == 'search')
			return 'Semua Produk'
		else if (
			props.slug != 'search' &&
			(queryString.q == '' || queryString.q == null)
		)
			return breadCrumbs[breadCrumbs.length - 1].segment
		else return queryString.q
	}

	const generateCategory = () => {
		let queryString = qs.parse(props.location.search)
		queryString.page = 1
		queryString.q = ''
		if (!listFilterCat.isLoading && !catalog.isLoading)
			return (
				<CategoryList
					data={filterCatData}
					uri={queryString}
					parentId={props.slug != 'search' ? globalAllParentId : []}
				/>
			)
		else return null
	}

	const handleChangeSelect = (value, uriSlug) => {
		let queryString = qs.parse(props.location.search)
		queryString.limit = value
		let newUri = Object.keys(queryString)
			.map((key) => key + '=' + queryString[key])
			.join('&')
		// if (queryString.page) {
		// 	delete queryString.page
		// }
		setDropdownList(!dropdownList)
		window.history.replaceState(null, null, `${props.slug}?${newUri}`)
		dispatch(requestCatalog({ slug: uriSlug, queryString, auth }))
	}

	return (
		<LayoutComponent>
			<Breadcrumbs
				breadcrumbs={
					listFilterCat.isLoading == false && catalog.isLoading == false
						? breadCrumbs
						: props.breadcrumbs
				}
			/>
			{catalog.isLoading == false ? (
				<Alert
					icon={
						err || errSearch ? (
							<HighlightOff />
						) : (
							<SuccessCircle width={'20px'} height={'20px'} />
						)
					}
					variant={err || errSearch ? 'alert' : 'success'}
				>
					{errSearch ? (
						<Typography variant='body1'>
							Produk yang Anda cari tidak ditemukan
						</Typography>
					) : err ? (
						<Typography variant='body1'>
							Hasil pencarian kata kunci &nbsp;{' '}
							<strong>
								&quot;
								{generateQs('keyword')}
								&quot;
							</strong>
							&nbsp; tidak ditemukan
						</Typography>
					) : (
						<Typography variant='body1'>
							Menampilkan{' '}
							{catalog.isLoading === false && catalog.data
								? catalog.data.pagination.total_record == 10000
									? catalog.data.pagination.total_record + '+'
									: catalog.data.pagination.total_record
								: ''}{' '}
							produk untuk pencarian &nbsp;
							<strong>
								&quot;
								{generateQs('category')}
								&quot;
							</strong>
							{query && query.category_name ? (
								<span> dalam {query.category_name}</span>
							) : null}
						</Typography>
					)}
				</Alert>
			) : null}

			{err && catalog.isLoading == false ? (
				renderContent(props, err, errSearch, catalog)
			) : (
				<Grid container className={classes.gridContainer}>
					<Grid item lg={2} className={classes.categoryContainer}>
						<Box
							bgcolor='#efefef'
							display='flex'
							justifyContent='center'
							alignItems='center'
							p={1}
						>
							<FilterIcon color='#147af6' height='12px' />
							<Typography align='center' variant='body2'>
								<strong>FILTER</strong>
							</Typography>
						</Box>
						<Box className={classes.mb_3}>{generateCategory()}</Box>
					</Grid>
					<Grid item lg={10} className={classes.listContainer}>
						{catalog.isLoading == false && catalog.data && catalog.data.items ? (
							<Box
								py={0.25}
								display='flex'
								justifyContent='flex-end'
								alignItems='center'
							>
								<Typography variant='body2' style={{ marginRight: 8 }}>
									TAMPILKAN
								</Typography>
								<Dropdown
									isReadOnly={true}
									onClick={() => setDropdownList(true)}
									onClickAway={() => setDropdownList(false)}
									visible={dropdownList}
									value={query.limit ? query.limit : 25}
								>
									{menuData.map((item, index) => (
										<MenuItem
											onClick={(e) => handleChangeSelect(item.value, props.slug)}
											key={index}
										>
											{item.label}
										</MenuItem>
									))}
								</Dropdown>
							</Box>
						) : null}

						{catalog.isLoading == false && catalog.data && catalog.data.items ? (
							<Box className={classes.gridList}>
								{catalog.data.items.map((val, index) =>
									val.can_be_sale && val.active ? (
										<CardListing
											// onClick={() => handleNavigate('/catalog/detail', val.id, val.name)}
											href={generateLink('/catalog/detail', val.id, val.name)}
											key={index}
											price={
												catalogPrice.isLoading == false ? (
													handlePrice(index, val.id)
												) : (
													<CircularProgress />
												)
											}
											alert={val.variant_list.length > 1 ? true : false}
											alertText='Produk tersedia dengan pilihan'
											title={val.name}
											media={
												val.image != ''
													? `${apiUrl}/180x180/erp/${val.image}`
													: image_not_found
											}
										/>
									) : null
								)}
							</Box>
						) : catalog.isLoading == false ? (
							renderContent(props, err, errSearch, catalog)
						) : null}
					</Grid>
				</Grid>
			)}
			{catalog.isLoading == false &&
			catalog.data &&
			catalog.data.items &&
			catalog.data.pagination ? (
				<Box
					style={{
						display: 'flex',
						marginTop: 10,
						justifyContent: 'flex-end',
						marginBottom: 16,
					}}
				>
					<Pagination
						current={catalog.data.pagination.page}
						total={catalog.data.pagination.total_record}
						pageSize={catalog.data.pagination.limit}
						showPrevNextJumpers
						showQuickJumper
						locale={customeLocalInfo}
						onChange={(e) => handleChangePagination(e)}
					/>
				</Box>
			) : null}
		</LayoutComponent>
	)
}

Component.propTypes = {
	breadcrumbs: PropTypes.arrayOf(
		PropTypes.shape({
			segment: PropTypes.string,
			icon: PropTypes.string,
			link: PropTypes.string,
		})
	),
	slug: PropTypes.string,
}

Component.defaultProps = {
	breadcrumbs: [
		{ segment: 'Home', link: '/' },
		{ segment: 'Semua Kategori', link: '/catalog/search?q=' },
		// {segment:'Macbook Air', link:'/'},
	],
}

export default Component
