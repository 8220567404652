import { makeStyles } from '@material-ui/styles'

const generateColor = (type) => {
	switch (type) {
		case 'error':
			return {
				background: '#f8d8da',
				text: '#b74956',
			}
		case 'success':
			return {
				background: '#D5FADE',
				text: '#28A745',
			}
		case 'grey':
			return {
				background: '#f0f0f0',
				text: '##343A40',
			}
		default:
			return {
				background: '#f8d8da',
				text: '#b74956',
			}
	}
}

const styles = makeStyles(() => ({
	container: ({ type, size }) => ({
		backgroundColor: generateColor(type).background,
		color: generateColor(type).text,
		display: 'inline-block',
		padding: size === 'sm' ? '2px 8px' : size === 'md' ? '8px 12px' : '12px 16px',
		borderRadius: size === 'sm' ? 2 : size === 'md' ? 4 : 8,
	}),
}))

export default styles
