export const titleTagPromotion = (tagName) => {
	let res = {
		title: '',
		description: '',
	}
	if (tagName == 'belanja-kertas-ongkir-bebas') {
		res.title = 'Belanja Kertas,Ongkir Bebas!'
		res.description =
			'Beli kertas pakai sistem kontraktual! Gak perlu repot beli lagi. Sekali kontrak bisa untuk sepanjang tahun. Gratis ongkir*.'
	} else {
		res
	}
	return res
}

export const searchNameProduct = (data, val) => {
	let res =
		data && data.length != 0
			? data.filter((search) => {
					return search.name.toLowerCase().indexOf(val.toLowerCase()) >= 0
			  })
			: []
	return res
}
