import { makeStyles } from '@material-ui/core/styles'

export const Styles = makeStyles((theme) => ({
	buttonAction: {
		marginLeft: '5px',
	},
	btnSave: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnCancel: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	labelRequired: {
		position: 'relative',
		'&:after': {
			content: '"*"',
			position: 'absolute',
			color: theme.palette.error.dark,
		},
	},
	selectInput: {
		padding: '10px 0 9px 12px !important',
	},
	padTop: {
		paddingTop: '14px',
	},
	menuStyle: {
		minHeight: '80px',
		borderRadius: '5px',
		position: 'relative',
		padding: '6px 0 0 6px',
		border: '1px solid #d1d1d1',
		fontSize: '14px',
		lineHeight: 1.2,
		cursor: 'text',
	},
	rootCheckBox: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		paddingRight: 5,
		paddingLeft: 0,
		paddingBottom: 0,
		paddingTop: 0,
		margin: 0,
	},
	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow:
			'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow:
			'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				// eslint-disable-next-line prettier/prettier
				'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
				// eslint-disable-next-line prettier/prettier
				' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
				// eslint-disable-next-line prettier/prettier
				'1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'black\'/%3E%3C/svg%3E")',
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	formLabel: {
		fontSize: 14,
	},
	rootFormLabel: {
		marginLeft: 0,
		marginTop: 10,
	},
	rootAdorment: {
		paddingLeft: 8,
		color: 'black',
		width: '35px',
		height: '0.01em',
		display: 'flex',
		maxHeight: '2em',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		marginTop: '0px!important',
	},
	adornedStart: {
		backgroundColor: '#EAEAEA',
		padding: 0,
	},
	cardWarningBudget: {
		height: '50px',
		background: '#FFF3CD 0% 0% no-repeat padding-box',
		borderRadius: '5px',
		opacity: 1,
		padding: 8,
		marginTop: 20,
	},
	labelPlacementStart: { marginLeft: -10 },
	tooltipContainer: {
		maxWidth: 300,
		width: 180,
		backgroundColor: theme.palette.common.black,
		boxShadow: theme.shadows[2],
		minHeight: 48,
		maxHeight: 300,
		opacity: 1,
	},
	labelInput: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	},
	inputAnggaran: {
		font: 'inherit',
		color: 'black',
		width: ' 100%',
		border: 'none',
		height: '1.1876em',
		margin: 0,
		display: 'block',
		minwidth: 0,
		background: 'none',
		boxSizing: 'content-box',
		borderRadius: '5px',
		letterSpacing: 'inherit',
		backgroundColor: '#fff',
		animationDuration: '10ms',
		textAlign: 'right',
		fontSize: 14,
	},
	styleIconbutton: {
		paddingTop: '14px',
		paddingLeft: 8,
		color: 'black',
	},
	smallICon: {
		width: 16,
		height: 16,
	},
	arrow: {
		color: theme.palette.common.black,
	},
	button: {
		marginTop: 15,
		color: theme.palette.secondary.main,
		borderColor: theme.palette.secondary.main,
	},
	marginTop15: {
		marginTop: 15,
	},
	gridStyleMargin: {
		marginTop: 10,
	},
	colorAnggaranBaru: {
		color: '#6F757C',
	},
	fontSize12: {
		fontSize: 12,
	},
	fontSize14: {
		fontSize: 14,
	},
	styleInput: {
		color: '#343A40',
		cursor: 'text',
		display: 'flex',
		fontSize: 14,
		alignItems: 'center',
		fontWeight: 400,
		lineHeight: '1.1876em',
	},
}))
