import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import useStyles from './styles'
import { Container, Box } from '@material-ui/core'
import { LogoWhite } from '../svgs'
import SEO from '../seo'
import Footer from '../footer'

const Header = () => {
	const classes = useStyles()

	const handleRedirect = (path) => {
		navigate(path)
	}

	return (
		<Box className={classes.container}>
			<Container className={`${classes.headerItem}`}>
				<Box onClick={() => handleRedirect('/')} style={{ cursor: 'pointer' }}>
					<LogoWhite />
				</Box>
			</Container>
		</Box>
	)
}

const LayoutErrorPage = ({ children, header, variant, isFooter }) => {
	const classes = useStyles()
	return (
		<Box>
			<SEO />
			{header && <Header />}
			<Box
				p={5}
				textAlign='center'
				className={classes.verticalCenter}
				style={{
					minHeight: 'calc(100vh - 130px)',
					borderBottom: '1px solid #e7eaf2',
				}}
			>
				{children}
			</Box>
			{isFooter ? <Footer variant={variant} className={classes.footers} /> : null}
		</Box>
	)
}

LayoutErrorPage.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	header: PropTypes.bool,
	variant: PropTypes.string,
	isFooter: PropTypes.bool,
}

LayoutErrorPage.defaultProps = {
	header: true,
	variant: 'dark',
	isFooter: true,
}

export default LayoutErrorPage
