import { makeStyles } from '@material-ui/core/styles'

export const DepartmentAddStyles = makeStyles((theme) => ({
	buttonSearch: {
		padding: '4.5px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: 0,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	gridSearch: {
		width: '85%',
	},
	buttonExport: {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
	},
	buttonAction: {
		marginLeft: '5px',
	},
	btnSave: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnCancel: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	labelRequired: {
		position: 'relative',
		'&:after': {
			content: '"*"',
			position: 'absolute',
			color: theme.palette.error.dark,
		},
	},
	btnDisable: {
		backgroundColor: '#9aa1a9',
		color: '#ffffff !important',
	},
}))
