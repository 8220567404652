import React, { useState } from 'react'
import LayoutComponent from 'Components/layout'
import {
	Container,
	Box,
	Grid,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputBase,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Breadcrumbs from 'Components/breadcrumbs'
import { navigate } from 'gatsby'
import { PrivacyPolicyStyle } from './styles'

const PrivacyPolicy = () => {
	const classes = PrivacyPolicyStyle()

	const CustomInput = withStyles((theme) => ({
		root: {
			'label + &': {
				marginTop: theme.spacing(3),
			},
		},
		input: {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'&#34;Segoe UI&#34;',
				'Roboto',
				'&#34;Helvetica Neue&#34;',
				'Arial',
				'sans-serif',
				'&#34;Apple Color Emoji&#34;',
				'&#34;Segoe UI Emoji&#34;',
				'&#34;Segoe UI Symbol&#34;',
			].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
			},
		},
	}))(InputBase)

	const lang = {
		id: '/pages/id/privacy-policy',
		en: '/pages/en/privacy-policy',
	}
	const activeLink = window.location.pathname

	const handleChange = (event) => {
		navigate(event.target.value)
	}

	const [breadcrumbs] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Privacy Policy',
			link: '#',
		},
	])

	return (
		<>
			<LayoutComponent>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrumbs} />
					</Box>
					<Box>
						<Grid container justify='space-between'>
							<Grid item xs={6}>
								<Typography variant='h6'>Privacy Policy</Typography>
							</Grid>
							<Grid container item justify='flex-end' alignItems='center' xs={3}>
								<FormControl>
									<Select
										id='lang-product-return'
										value={activeLink}
										onChange={handleChange}
										input={<CustomInput fullWidth variant='outlined' color='primary' />}
									>
										<MenuItem value={lang['id']}>Bahasa Indonesia</MenuItem>
										<MenuItem value={lang['en']}>Bahasa Inggris</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box mt={2}>
						<Box mb={1} textAlign='justify'>
							<Typography variant='body1'>
								Welcome MBiz.co.id. Thank you for visiting Our website. This page
								displays the Privacy Policy (&quot;<b>Policy</b>&quot;), where matters
								of privacy and personal data will be stipulated. If you do not agree or
								have reservations on matters set out in this Policy, we request you to
								immediately stop using Our service. As a user of Our services, you are
								required to participate and be subject to this Policy.
							</Typography>
						</Box>
						<Box mb={1} textAlign='justify'>
							<Typography variant='body1'>
								We, PT Brilliant Ecommerce Berjaya (BEB), a company that carries out
								business through the web site www.mbiz.co.id (&quot;
								<b>www.mbiz.co.id</b>&quot;, &quot;<b>We</b>&quot;, &quot;<b>Us</b>
								&quot; or &quot;<b>Our</b>&quot;), understands the importance of privacy
								and personal data and we are fully committed to protecting your privacy
								and personal data. We urge you not to access www.mbiz.co.id or provide
								personal data or any data to Us before you read, understand and agree to
								this Policy as a whole.
							</Typography>
						</Box>
						<Box mb={1} textAlign='justify'>
							<Typography variant='body1'>
								Please note that this Privacy Policy applies only to users of Our
								service.
							</Typography>
						</Box>
						<Box mb={1} textAlign='justify'>
							<Typography variant='body1'>
								This Policy explains Our handling of personal and other data and how We
								are committed to protecting your privacy. You understand that We may
								provide additional information that is specific to a product or service
								as a supplement to this Policy in order to always be able to give you
								information about other data collection purposes.
							</Typography>
						</Box>
						<Box mb={1} textAlign='justify'>
							<Typography variant='body1'>
								We also encourage you to visit this page and read this Policy
								periodically.
							</Typography>
						</Box>

						<Box mt={2}>
							<ol className={classes.contentList}>
								<li>
									<b>The use of your personal data</b>
									<ol>
										<li>
											<span>(i)</span>
											<b>Collection and utilization of your data</b>
											<b>
												Creating an account, and the purchase or sale or registration of
												products or services
											</b>
											<p>
												If you submit a request to create an account or to buy products on
												www.mbiz.co.id or use Our services, we may ask you to provide
												information, such as your company&apos;s name, your company&apos;s
												articles of association, the names that are authorized by your
												company including the identity cards belonging to those persons,
												email addresses, shipping addresses, phone numbers, the required
												product information, and licenses obtained by your company,
												including but not limited to Certificate of Domicile (Surat
												Keterangan Domisili Perusahaan), Taxpayer Registration Number (Nomor
												Pokok Wajib Pajak/NPWP), Certificate of Taxable Entrepreneur (Surat
												Keterangan Pengusaha Kena Pajak), Company Registration (Tanda Daftar
												Perusahaan), Trade Business License (Surat Izin Usaha Perdagangan)
												and/or other business licenses (&quot;Your Data&quot;), with the aim
												to implement the purchase of your products, provide services to you,
												create alterations to your request, provide technical support or
												authorized updates, and more.
											</p>
											<p>
												On the conditions stated above, you hereby agree to provide Your
												Data and/or other data to Us for further use in accordance with this
												Policy.
											</p>
											<b>Promotion or survey</b>
											<p>
												You agree that We may ask you to provide relevant information, such
												as phone numbers and email addresses, to be able to contact you,
												send information about products and services that might interest
												you, or invite you to promotions or surveys conducted by Us. You may
												ask Us to not use Your Data for promotional or survey purposes by
												contacting Customer Care.
											</p>
											<b>Statistic</b>
											<p>
												We may collect certain information for analysis, including the
												device name, device version, the identification number of devices,
												information approximate geographical location (such as the ID area
												of your device), ID service provider&apos;s network, the way you use
												the site, and the IP address that you use to access the Internet. We
												collect and process this information to give you more personalized
												user experience and to customize the content, improve our products,
												analyze the efficiency of our business operations, analyze our
												market share, enhance the security of Our network and services, and
												enable the service to you.
											</p>
											<b>Issue</b>
											<p>
												If you experience any issues with your account, you can choose to
												send information to Us and We and/or Our partners can assist you.
												You agree that we may collect relevant information on the matter.{' '}
											</p>
											<b>Data storage</b>
											<p>
												Some of the services that we provide allow you to store data. We may
												collect and store the data you upload or download and data required
												to implement the uploads and downloads.
											</p>
										</li>
										<li>
											<span>(ii)</span>
											<b>Location</b>
											<p>
												When you access the service or search for information based on a
												specific location, We may collect, use, and process information on
												the specific or approximate location of your device. This allows Us
												to provide a better service to you. Information gathered may include
												device identification number, type, model, and real-time location
												information (including those obtained through GPS, Wi-Fi, and your
												service provider&apos;s network ID). We collect anonymous location
												information to provide and improve location-based products and
												services mentioned.
											</p>
										</li>
										<li>
											<span>(iii)</span>
											<b>Collection and use of other information</b>
											<p>
												We may collect aggregate statistical information, such as the number
												of visitors to our website. We collect this information in order to
												understand how people use our website, products, or services. In
												this way, We can improve and streamline our services to better meet
												the needs of customers. We may, in our sole discretion, collect,
												use, transfer, or disclose non-personal information for any other
												purpose.
											</p>
										</li>
									</ol>
								</li>
								<li>
									<b>The Use of Cookies</b>
									<p>
										To ensure that our website works properly, we sometimes put a small
										data file on your computer or mobile device, known as a cookie. A
										cookie is a simple text file that is stored on your computer or mobile
										device by a website server, and only that server that will be able to
										take or read the content of the cookie. Each cookie is unique to your
										web browser. Cookies contain anonymous information, such as a unique
										identifier, the site name, numbers, and characters. Cookies allow
										websites to remember information, such as your preferences or
										what&apos;s in your shopping cart.
									</p>
									<p>
										Most major websites or Internet service providers do this in order to
										improve the users experience by allowing the website to
										&quot;remember&quot; you, either from a single visit (using a
										&quot;session cookie&quot;) or for repeat visits (using
										&quot;persistent cookies&quot;). Cookies help websites remember
										settings - language, font size, and other preferences that you use to
										view the website on a computer or mobile device. This way, you do not
										need to re-enter these settings whenever you re-visit our web site. If
										a website does not use cookies, the website will treat you as a new
										visitor every time you open a new page in the website. For example,
										when you enter your login details and move to another page, the
										website will not recognize you, and will not be able to keep you
										logged into the site. Our cookies are not used to identify you
										personally. They are only used to make the website serve you better.
									</p>
									<p>
										You can delete all the cookies that already exist on your computer and
										set most browsers to block cookies. However, if you do, you may have
										to adjust some preference settings manually each time you visit our
										web site. We do not use cookies for purposes other than those
										mentioned herein and do not collect any personal data for other
										purposes.
									</p>
								</li>
								<li>
									<b>Disclosure of Your Data</b>
									<p>
										We will never sell Your Data to third parties without your knowledge.
										However, we may disclose Your Data to:
									</p>
									<ol>
										<li>
											<span>(i)</span>
											<b>
												<u>
													Our affiliates or third parties that officially cooperate with Us.
												</u>
											</b>
											<p>
												We may need to provide certain services to you through some of the
												parties that cooperate with Us. Therefore, we may share some of your
												personal information to the parties that cooperate with Us in order
												to enrich our services and improve the overall experience of your
												usage. For example, when you decide to buy a product on Our website,
												We will need to send information of Your Data to those party who
												work with us to allow them to send the product to you, or to allow
												them to provide other services to you. We forbid all our affiliates
												or authorized third parties to use the personal information shared
												for other purposes.
											</p>
										</li>
										<li>
											<span>(ii)</span>
											<b>
												<u>Government Agency under the provisions of the law</u>
											</b>
											<p>
												We may disclose Your Data when required by law, legal action,
												litigation or government authority, and public authority. Your Data
												information may also be disclosed to the parties to a transaction in
												a state in which we are involved in the reorganization, merger, or
												bankruptcy liquidation.
											</p>
										</li>
										<li>
											<span>(iii)</span>
											<b>
												<u>
													Another company after you have explicitly approved such disclosure
												</u>
											</b>
											<p>We may share Your Data with any other company.</p>
										</li>
									</ol>
								</li>
								<li>
									<b>Changes to Your Data</b>
									<p>
										You will be responsible for the accuracy of the information you submit
										in Your Data. We strive to maintain the accuracy of Your Data
										information, and We will remove or modify such information at your
										request. You can contact us by sending an email to Customer Care. In
										accordance with Our terms and conditions, you can manage Your Data at
										any time, such as your personal account registration information.
									</p>
								</li>
								<li>
									<b>Protection of Your Data</b>
									<p>
										We undertake prevention efforts, including but not limited to,
										technical and physical measures, and regulations to protect the
										information of Your Data from unauthorized access, disclosure, use,
										alteration, destruction, or loss. Although we make such measures to
										protect Your Data, We do not claim or warrant that the security
										measures that We take is a security effort that is 100% perfect or
										cannot be hacked.
									</p>
								</li>
								<li>
									<b>Updates</b>
									<p>
										Any changes that We make in this Policy will be displayed in the form
										of a notice on Our website, you hereby agree that you are obliged to
										see, read and study the notice. For your convenience, we recommend you
										to visit this page and read this Policy periodically.
									</p>
								</li>
								<li>
									<b>Contact Us</b>
									<p>
										If you have any questions, comments, or suggestions, please send an
										email to Customer Care.
									</p>
								</li>
							</ol>
						</Box>
					</Box>
				</Container>
			</LayoutComponent>
		</>
	)
}

export default PrivacyPolicy
