import { makeStyles } from '@material-ui/core/styles'
import backgroundLogin from '../../assets/images/background/login-backgrounds.jpg'
import backgroundLoginApl from '../../assets/images/background/apl-background.jpg'
import backgroundLoginCombiphar from '../../assets/images/background/combiphar-background.png'
import sampoernabg1 from '../../assets/images/background/sampoerna-bg1.png'
import sampoernabg2 from '../../assets/images/background/sampoerna-bg2.png'
import sampoernalogo from '../../assets/images/whitelabel/logosampoerna.jpg'
import backgroundunilever from '../../assets/images/background/unilever-background.jpg'
import UnileverSide from '../../assets/images/background/unilever-side.png'

export const loginStyle = makeStyles((theme) => ({
	textLeft: {
		textAlign: 'left!important',
	},
	p_4: {
		padding: '1.5rem!important',
	},
	mb_3: {
		marginBottom: '1rem!important',
	},
	small: {
		fontSize: '12px',
		fontWeight: '600!important',
	},
	validationText: {
		height: 25,
		color: theme.palette.error.main,
	},

	hr_line: {
		marginTop: '1rem',
		marginBottom: '1rem',
		border: '0',
		borderTop: '1px solid rgba(255,255,255,.2)',
	},

	login_background: {
		width: '100%',
		height: 'calc( 100vh - 50px )',
		background: `linear-gradient(60deg, rgba(98,141,193,0.75) 0%, rgba(27,42,68,0.75) 15%, rgba(27,42,68,0.85) 40%, rgba(98,141,193,0.25) 90%),url(${backgroundLogin}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		alignContent: 'center',
		textAlign: 'center',
	},

	form_login: {
		margin: '0',
		position: 'absolute',
		top: '47%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
	},

	message_container: {
		margin: 0,
		position: 'absolute',
		top: '47%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
		width: '500px',
		maxHeight: '356px',
		padding: '15px',
		color: '#929aa7',
	},

	container: {
		background: `linear-gradient(rgba(37, 79, 130,.5),rgba(37, 79, 130,.5)),url(${backgroundLogin})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '0% 46%',
		display: 'flex',
		'::before': {
			background: 'rgba(37, 79, 130,.5)',
		},
	},

	container_content: {
		margin: 'auto',
		color: '#fff',
	},

	mbiz_image: {
		width: '50%!important',
		margin: '0',
		cursor: 'pointer',
	},

	text_warning: {
		color: '#ffc107!important',
	},

	container_field: {
		padding: '15px 35px 35px 35px;',
		backgroundColor: '#fff',
		color: '#929aa7',
		fontSize: '15px',
	},

	title: {
		margin: '-2px 0px 8px 0',
		textAlign: 'center',
		fontWeight: '400!important',
		fontFamily: '"Nunito Sans", sans-serif',
		letterSpacing: '-.25px',
		fontSize: '18px',
		color: '#343A40',
	},

	titleTautanEmail: {
		margin: '-2px 0px 8px 0',
		textAlign: 'center',
		fontWeight: '600!important',
		fontFamily: '"Source Sans Pro", sans-serif',
		letterSpacing: '-.25px',
		fontSize: '18px',
		color: '#343A40',
	},

	subtitle: {
		lineHeight: '1.4',
		fontSize: '15px',
		color: '##929aa7',
		marginBottom: '1rem',
		letterSpacing: '.1px',
	},

	forgotpass: {
		float: 'right',
		marginTop: '6px',
		fontSize: '15px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	suggestpass: {
		float: 'left',
		marginTop: '6px',
		fontSize: '13px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	rememberMe: {
		float: 'left',
		fontSize: '15px',
		textDecoration: 'none',
		color: '#b2b2b2',
		'& .MuiCheckbox-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiSvgIcon-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiTypography-body1': {
			fontSize: '14px',
			fontFamily: 'Source Sans Pro',
			fontWeight: '400',
			lineHeight: '1.5',
			fontStyle: 'italic',
			marginLeft: '-8px',
		},
	},

	register: {
		color: '#a2a2a2',
		textAlign: 'left',
		paddingTop: '20px',
		marginBottom: '0.5rem!important',
		position: 'relative',
		fontSize: '12px',
	},

	password: {
		width: '100%',
		'& .MuiOutlinedInput-input': {
			padding: '10.5px 14px',
		},
	},
	uri: {
		color: '#357ab6',
		cursor: 'pointer',
	},
	captchaContainer: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	alertText: {
		color: 'rgb(97, 26, 21)',
		textAlign: 'left',
	},
	textCounter: {
		fontSize: '13px',
		color: '#BABABA',
		paddingRight: 3,
	},
	textTimeCount: {
		color: '#007BFF',
		fontSize: '13px',
		paddingRight: 3,
		fontWeight: 600,
	},
	rowTextTimeCount: {
		flexDirection: 'row',
		flex: 1,
		marginTop: 7,
		marginBottom: 15,
	},
	textCenter: {
		textAlign: 'center!important',
	},
	successForgot: {
		height: '466px',
		width: '500px',
		margin: 0,
		position: 'absolute',
		top: '47%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
		padding: '15px',
		color: '#929aa7',
	},
}))

export const aplstyles = makeStyles((theme) => ({
	textLeft: {
		textAlign: 'left!important',
	},
	p_4: {
		padding: '1.5rem!important',
	},
	mb_3: {
		marginBottom: '1rem!important',
	},
	small: {
		fontSize: '12px',
		fontWeight: '600!important',
	},
	validationText: {
		height: 25,
		color: theme.palette.error.main,
	},

	hr_line: {
		marginTop: '1rem',
		marginBottom: '1rem',
		border: '0',
		borderTop: '1px solid rgba(255,255,255,.2)',
	},

	login_background: {
		width: '100%',
		height: '100vh',
		background: `url(${backgroundLoginApl}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		alignContent: 'center',
		textAlign: 'center',
	},

	form_login: {
		margin: '0',
		position: 'absolute',
		top: '47%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
	},

	form_input_shadow: {
		marginBottom: 16,
		boxShadow: '#aaa 5px 8px 11px 0px',
	},

	powered_by: {
		textAlign: 'left',
		marginTop: '32px',
	},

	message_container: {
		margin: 0,
		position: 'absolute',
		top: '47%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
		width: '650px',
		padding: '15px',
		color: '#929aa7',
	},

	container: {
		background: `linear-gradient(rgba(37, 79, 130,.5),rgba(37, 79, 130,.5)),url(${backgroundLogin})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '0% 46%',
		display: 'flex',
		'::before': {
			background: 'rgba(37, 79, 130,.5)',
		},
	},

	container_content: {
		margin: 'auto',
		color: '#000',
	},

	apl_image: {
		width: '50%!important',
		margin: '0',
	},

	apl_image_mask: {
		width: '111%!important',
		margin: '0',
	},

	memberLogin: {
		color: theme.palette.primary.light,
		marginLeft: 8,
	},

	text_warning: {
		color: '#ffc107!important',
	},

	container_field: {
		padding: 45,
		backgroundColor: '#fff',
		color: '#929aa7',
		fontSize: '15px',
	},

	title: {
		margin: '-2px 0px 8px 0',
		textAlign: 'center',
		color: '#929aa7',
		fontSize: '1.75rem',
		fontWeight: '400!important',
		fontFamily: '"Nunito Sans", sans-serif',
		letterSpacing: '-.25px',
	},

	subtitle: {
		lineHeight: '1.4',
		fontSize: '15px',
		color: '##929aa7',
		marginBottom: '1rem',
		letterSpacing: '.1px',
	},

	forgotpass: {
		float: 'right',
		marginTop: '6px',
		fontSize: '15px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	suggestpass: {
		float: 'left',
		marginTop: '6px',
		fontSize: '13px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	rememberMe: {
		float: 'left',
		fontSize: '15px',
		textDecoration: 'none',
		color: '#b2b2b2',
		'& .MuiCheckbox-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiSvgIcon-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiTypography-body1': {
			fontSize: '14px',
			fontFamily: 'Source Sans Pro',
			fontWeight: '400',
			lineHeight: '1.5',
			fontStyle: 'italic',
			marginLeft: '-8px',
		},
	},

	register: {
		color: '#a2a2a2',
		textAlign: 'left',
		paddingTop: '20px',
		marginBottom: '0.5rem!important',
		position: 'relative',
		fontSize: '12px',
	},

	password: {
		width: '100%',
		'& .MuiOutlinedInput-input': {
			padding: '10.5px 14px',
		},
	},
	uri: {
		color: '#357ab6',
		cursor: 'pointer',
	},
	captchaContainer: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	alertText: {
		color: 'rgb(97, 26, 21)',
		textAlign: 'left',
	},
	radiusLeft: {
		borderTopLeftRadius: 5,
		borderBottomLeftRadius: 5,
	},
	radiusRight: {
		borderTopRightRadius: 5,
		borderBottomRightRadius: 5,
	},
}))

export const combipharstyles = makeStyles((theme) => ({
	textLeft: {
		textAlign: 'left!important',
	},
	p_4: {
		padding: '1.5rem!important',
	},
	mb_3: {
		marginBottom: '1rem!important',
	},
	small: {
		fontSize: '12px',
		fontWeight: '600!important',
	},
	validationText: {
		height: 25,
		color: theme.palette.error.main,
	},

	hr_line: {
		marginTop: '1rem',
		marginBottom: '1rem',
		border: '0',
		borderTop: '1px solid rgba(255,255,255,.2)',
	},

	login_background: {
		width: '100%',
		height: '100vh',
		background: `url(${backgroundLoginCombiphar}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		alignContent: 'center',
		textAlign: 'center',
	},

	form_login: {
		margin: '0',
		position: 'absolute',
		top: '47%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
	},

	form_input_shadow: {
		marginBottom: 16,
		boxShadow: '#aaa 5px 8px 11px 0px',
	},

	message_container: {
		margin: 0,
		position: 'absolute',
		top: '47%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
		width: '650px',
		padding: '15px',
		color: '#929aa7',
	},

	container: {
		background: `linear-gradient(rgba(37, 79, 130,.5),rgba(37, 79, 130,.5)),url(${backgroundLogin})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '0% 46%',
		display: 'flex',
		'::before': {
			background: 'rgba(37, 79, 130,.5)',
		},
	},

	container_content: {
		margin: 'auto',
		color: '#000',
	},

	apl_image: {
		width: '50%!important',
		margin: '0',
	},

	apl_image_mask: {
		width: '111%!important',
		margin: '0',
	},

	memberLogin: {
		color: '#532F90',
		marginBottom: 24,
	},

	text_warning: {
		color: '#ffc107!important',
	},

	container_field: {
		padding: 45,
		backgroundColor: '#fff',
		color: '#929aa7',
		fontSize: '15px',
		boxShadow: '#aaa 5px 5px 20px -5px',
		borderRadius: 15,
	},

	title: {
		margin: '-2px 0px 8px 0',
		textAlign: 'center',
		color: '#929aa7',
		fontSize: '1.75rem',
		fontWeight: '400!important',
		fontFamily: '"Nunito Sans", sans-serif',
		letterSpacing: '-.25px',
	},

	subtitle: {
		lineHeight: '1.4',
		fontSize: '15px',
		color: '##929aa7',
		marginBottom: '1rem',
		letterSpacing: '.1px',
	},

	forgotpass: {
		float: 'right',
		marginTop: '6px',
		fontSize: '15px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	suggestpass: {
		float: 'left',
		marginTop: '6px',
		fontSize: '13px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	rememberMe: {
		float: 'left',
		fontSize: '15px',
		textDecoration: 'none',
		color: '#b2b2b2',
		'& .MuiCheckbox-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiSvgIcon-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiTypography-body1': {
			fontSize: '14px',
			fontFamily: 'Source Sans Pro',
			fontWeight: '400',
			lineHeight: '1.5',
			fontStyle: 'italic',
			marginLeft: '-8px',
		},
	},

	register: {
		color: '#a2a2a2',
		textAlign: 'left',
		paddingTop: '20px',
		marginBottom: '0.5rem!important',
		position: 'relative',
		fontSize: '12px',
	},

	password: {
		width: '100%',
		'& .MuiOutlinedInput-input': {
			padding: '10.5px 14px',
		},
	},
	uri: {
		color: '#357ab6',
		cursor: 'pointer',
	},
	captchaContainer: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	alertText: {
		color: 'rgb(97, 26, 21)',
		textAlign: 'left',
	},
}))

export const sampoernastyles = makeStyles((theme) => ({
	textLeft: {
		textAlign: 'left!important',
	},
	p_4: {
		padding: '1.5rem!important',
	},
	mb_3: {
		marginBottom: '1rem!important',
	},
	small: {
		fontSize: '12px',
		fontWeight: '600!important',
	},
	validationText: {
		height: 25,
		color: theme.palette.error.main,
	},

	hr_line: {
		marginTop: '1rem',
		marginBottom: '1rem',
		border: '0',
		borderTop: '1px solid rgba(255,255,255,.2)',
	},

	login_background: {
		width: '100%',
		height: '100vh',
		background: `url(${sampoernabg2}) no-repeat 30% center fixed, url(${sampoernalogo}) no-repeat 95% 4% fixed, url(${sampoernabg1}) no-repeat center center fixed`,
		backgroundSize: '60%, 20%, cover',
		backgroundRepeat: 'no-repeat, no-repeat ,no-repeat',
		alignContent: 'center',
		textAlign: 'center',
	},

	form_login: {
		margin: '0',
		position: 'absolute',
		right: '15%',
		bottom: 0,
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
	},

	form_input_shadow: {
		marginBottom: 16,
		boxShadow: '#aaa 5px 8px 11px 0px',
	},

	powered_by: {
		textAlign: 'right',
		marginTop: '32px',
	},

	message_container: {
		margin: 0,
		position: 'absolute',
		top: '47%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
		width: '650px',
		padding: '15px',
		color: '#929aa7',
	},

	container: {
		background: `linear-gradient(rgba(37, 79, 130,.5),rgba(37, 79, 130,.5)),url(${backgroundLogin})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '0% 46%',
		display: 'flex',
		'::before': {
			background: 'rgba(37, 79, 130,.5)',
		},
	},

	container_content: {
		margin: 'auto',
		color: '#000',
	},

	apl_image: {
		width: '50%!important',
		margin: '0',
	},

	apl_image_mask: {
		width: '111%!important',
		margin: '0',
	},

	memberLogin: {
		color: theme.palette.primary.light,
		marginLeft: 8,
	},

	text_warning: {
		color: '#ffc107!important',
	},

	container_field: {
		padding: '95px 45px 20px 45px',
		backgroundColor: '#fff',
		color: '#929aa7',
		fontSize: '15px',
		boxShadow: '#aaa 5px 5px 20px -5px',
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
	},
	padlockCircle: {
		padding: '15px',
		background: '#D5AD68',
		borderRadius: '50%',
		position: 'absolute',
		top: '15px',
		right: '15px',
	},

	title: {
		margin: '-2px 0px 8px 0',
		textAlign: 'center',
		color: '#929aa7',
		fontSize: '1.75rem',
		fontWeight: '400!important',
		fontFamily: '"Nunito Sans", sans-serif',
		letterSpacing: '-.25px',
	},

	subtitle: {
		lineHeight: '1.4',
		fontSize: '15px',
		color: '##929aa7',
		marginBottom: '1rem',
		letterSpacing: '.1px',
	},

	forgotpass: {
		float: 'right',
		marginTop: '6px',
		fontSize: '15px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	suggestpass: {
		float: 'left',
		marginTop: '6px',
		fontSize: '13px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	rememberMe: {
		float: 'left',
		fontSize: '15px',
		textDecoration: 'none',
		color: '#b2b2b2',
		'& .MuiCheckbox-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiSvgIcon-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiTypography-body1': {
			fontSize: '14px',
			fontFamily: 'Source Sans Pro',
			fontWeight: '400',
			lineHeight: '1.5',
			fontStyle: 'italic',
			marginLeft: '-8px',
		},
	},

	register: {
		color: '#a2a2a2',
		textAlign: 'left',
		paddingTop: '20px',
		marginBottom: '0.5rem!important',
		position: 'relative',
		fontSize: '12px',
	},

	password: {
		width: '100%',
		'& .MuiOutlinedInput-input': {
			padding: '10.5px 14px',
		},
	},
	uri: {
		color: '#357ab6',
		cursor: 'pointer',
	},
	captchaContainer: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	alertText: {
		color: 'rgb(97, 26, 21)',
		textAlign: 'left',
	},
}))

export const unileverstyles = makeStyles((theme) => ({
	textLeft: {
		textAlign: 'left!important',
	},
	p_4: {
		padding: '1.5rem!important',
	},
	mb_3: {
		marginBottom: '1rem!important',
	},
	small: {
		fontSize: '12px',
		fontWeight: '600!important',
	},
	validationText: {
		height: 25,
		color: theme.palette.error.main,
	},

	hr_line: {
		marginTop: '1rem',
		marginBottom: '1rem',
		border: '0',
		borderTop: '1px solid rgba(255,255,255,.2)',
	},

	login_background: {
		width: '100%',
		height: '100vh',
		background: `url(${backgroundunilever}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		alignContent: 'center',
		textAlign: 'center',
	},
	unileverside: {
		width: '100%',
		height: '100vh',
		background: `url(${UnileverSide}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},

	form_login: {
		margin: '0',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
	},

	message_container: {
		margin: 0,
		position: 'absolute',
		top: '47%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
		fontSize: '15px',
		fontWeight: '400',
		width: '650px',
		padding: '15px',
		color: '#929aa7',
	},

	container: {
		background: `linear-gradient(rgba(37, 79, 130,.5),rgba(37, 79, 130,.5)),url(${backgroundLogin})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '0% 46%',
		display: 'flex',
		'::before': {
			background: 'rgba(37, 79, 130,.5)',
		},
	},

	container_content: {
		margin: 'auto',
		color: '#000',
	},

	unilever_image: {
		width: '50%!important',
		margin: '0',
	},

	apl_image_mask: {
		width: '111%!important',
		margin: '0',
	},

	memberLogin: {
		color: '#0081c2',
		marginLeft: 8,
	},

	text_warning: {
		color: '#ffc107!important',
	},

	container_field: {
		padding: 45,
		backgroundColor: '#fff',
		color: '#929aa7',
		fontSize: '15px',
	},

	title: {
		margin: '-2px 0px 8px 0',
		textAlign: 'center',
		color: '#929aa7',
		fontSize: '1.75rem',
		fontWeight: '400!important',
		fontFamily: '"Nunito Sans", sans-serif',
		letterSpacing: '-.25px',
	},

	subtitle: {
		lineHeight: '1.4',
		fontSize: '15px',
		color: '##929aa7',
		marginBottom: '1rem',
		letterSpacing: '.1px',
	},

	forgotpass: {
		float: 'right',
		marginTop: '6px',
		fontSize: '15px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	suggestpass: {
		float: 'left',
		marginTop: '6px',
		fontSize: '13px',
		fontStyle: 'italic',
		textDecoration: 'none',
	},

	rememberMe: {
		float: 'left',
		fontSize: '15px',
		textDecoration: 'none',
		color: '#b2b2b2',
		'& .MuiCheckbox-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiSvgIcon-root': {
			fontSize: '14px',
			color: '#ccc',
		},

		'& .MuiTypography-body1': {
			fontSize: '14px',
			fontFamily: 'Source Sans Pro',
			fontWeight: '400',
			lineHeight: '1.5',
			fontStyle: 'italic',
			marginLeft: '-8px',
		},
	},

	register: {
		color: '#a2a2a2',
		textAlign: 'left',
		paddingTop: '20px',
		marginBottom: '0.5rem!important',
		position: 'relative',
		fontSize: '12px',
	},

	password: {
		width: '100%',
		'& .MuiOutlinedInput-input': {
			padding: '10.5px 14px',
		},
	},
	uri: {
		color: '#357ab6',
		cursor: 'pointer',
	},
	captchaContainer: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	alertText: {
		color: 'rgb(97, 26, 21)',
		textAlign: 'left',
	},
	radiusLeft: {
		borderTopLeftRadius: 5,
		borderBottomLeftRadius: 5,
	},
	radiusRight: {
		borderTopRightRadius: 5,
		borderBottomRightRadius: 5,
	},
}))
