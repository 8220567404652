import { ADDRESS_DELETE, ADDRESS_LIST } from '../../../types'
import API from '../../../../utils/api'

const loading = () => {
	return {
		type: `${ADDRESS_DELETE}_PENDING`,
	}
}

const failure = (error, message) => {
	return {
		type: `${ADDRESS_DELETE}_REJECTED`,
		payload: {
			error,
			message,
		},
	}
}

const success = (data) => {
	return {
		type: `${ADDRESS_DELETE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const getListAddress = (data) => {
	return {
		type: `${ADDRESS_LIST}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const clearDeleteAddress = () => {
	return {
		type: `${ADDRESS_DELETE}_CLEAR`,
	}
}

export const closeModal = () => {
	return {
		type: `CLOSE_MODAL_${ADDRESS_DELETE}`,
	}
}

export const deleteAddress = (id) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			const response = await API({
				method: 'DELETE',
				url: `/companies/address/${id}`,
			})

			let responseListAddress = await API({
				method: 'GET',
				url: '/companies/address',
			})

			if (response.data && response.data.error_backend) {
				dispatch(failure(response.data.error_backend, ''))
			} else {
				dispatch(getListAddress(responseListAddress.data))
				dispatch(success(response.data))
			}
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
