/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react'
import styles from './styles'
import moment from 'moment'
import { requstBankAccount } from 'States/actions/bankAccountAction'
import { confirmsinglePayment } from 'States/actions/Transactions/Payment/confirmAction'
import { requestDetailInvoice } from 'States/actions/Transactions/Invoice/detailAction'
import { Dropdown } from '../../../../components/dropdown'
import { Formik, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Modal from 'Components/modal'
import PropTypes from 'prop-types'
import {
	Box,
	Button,
	Container,
	Paper,
	Typography,
	Grid,
	TextField,
	ButtonBase,
	MenuItem,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Layout from 'Components/layout'
import Breadcrumbs from 'Components/breadcrumbs'
import { numeralFormat } from '../../../../utils/formatMoney'
import { paymentConfirmationSchema } from './schema'
import API from '../../../../utils/api'
import CustomModal from 'Components/modal'
import { validateUpload } from '../../../../utils/globalUtils'
import { type } from './status'

const thumb = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'

const Component = ({ invoiceId, navigate }) => {
	const classes = styles()
	const inputRef = useRef([])
	const dispatch = useDispatch()

	// ====== local state =======
	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Transaksi',
			link: '/dashboard',
		},
		{
			segment: 'Tagihan',
			link: '/dashboard/invoice',
		},
		{
			segment: 'Rincian Tagihan',
			link: `/dashboard/invoice/${invoiceId}`,
		},
		{
			segment: 'Konfirmasi Pembayaran',
			link: '#',
		},
	])
	const [menuMbiz, setMenuMbiz] = useState(false)
	const [menuPartner, setMenuPartner] = useState(false)
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalSuccess, setModalSuccess] = useState(false)
	const [jumlah, setJumlah] = useState(0)
	const [img, setImg] = useState(['', '', ''])
	const [notifError, setNotifError] = useState(false)
	const [erroString, setErrorString] = useState('')
	// ======= end of local state =========

	// reducer data
	const bankAccount = useSelector((state) => state.bankAccount)
	const invoiceDetail = useSelector((state) => state.invoiceDetailReducer)
	// end of reducer data

	// ========== effect ==============
	useEffect(() => {
		dispatch(requstBankAccount('MBIZ', 'mbiz_bank'))
		dispatch(requstBankAccount('PARTNER', 'partner_bank'))
		dispatch(requestDetailInvoice(invoiceId))
	}, [])
	// ========== end of effect =======

	const handleUploadImage = (index) => {
		inputRef.current[index].click()
	}

	const onChangeInput = (index, action) => {
		let data = new FormData()
		let file = inputRef.current[index].files[0]

		let checkValidate = validateUpload(10485760, type, file)
		if (checkValidate.isError) {
			setNotifError(true)
			setErrorString(checkValidate.textError)
		} else {
			data.append('files[]', file, file.name)
			data.append('types', 'account_payment_document')
			API({
				method: 'POST',
				url: '/storages/upload',
				data: data,
				headers: { 'Content-Type': 'multipart/form-data' },
			}).then((res) => {
				action.setFieldValue(`img${index}`, res.data.data.results[0].id)
				action.setFieldTouched('img1', false)
				let temp = [...img]
				temp[index - 1] = thumb + res.data.data.results[0].file_location_encrypt
				setImg(temp)
			})
		}
	}

	const handleSelectItem = (type, value, prop) => {
		if (type === 'partner') {
			setMenuPartner(false)
			prop.setFieldValue('patrnerLabel', value.display_name)
			prop.setFieldValue('partnerBank', value.id)
			prop.setFieldTouched('patrnerLabel', false)
		} else {
			setMenuMbiz(false)
			prop.setFieldValue('mbizLabel', value.display_name)
			prop.setFieldValue('mbizBank', value.id)
			prop.setFieldTouched('mbizLabel', false)
		}
	}

	const validateForm = async (prop, value) => {
		const error = await prop.validateForm()
		const currentErrorKeys = Object.keys(error)
		if (currentErrorKeys.length) {
			currentErrorKeys.map((item) => {
				prop.setFieldError(item)
				prop.setFieldTouched(item)
			})
		} else {
			setJumlah(value)
			setModalConfirm(true)
		}
	}

	const handleSubmitForm = async (values) => {
		setModalConfirm(false)

		let data = {}
		let storage_ids = [
			{
				id: values.img1,
			},
			{
				id: values.img2,
			},
			{
				id: values.img3,
			},
		]
		data.communication = invoiceDetail.data.name || ''
		data.amount = values.jumlahPembayaran
		data.mbiz_bank_account = values.mbizBank
		data.partner_bank_account = values.partnerBank
		data.payment_date = moment(values.transferDate).format('YYYY-MM-DD')
		data.payment_notes = values.catatan
		data.internal_code = ''
		data.storage_id = storage_ids.filter((storage) => storage.id)

		try {
			const success = await confirmsinglePayment(data, invoiceId)
			if (success) {
				setModalSuccess(true)
				setTimeout(() => {
					setModalSuccess(false)
					navigate(`/dashboard/invoice/${invoiceId}`)
				}, 2000)
			}
		} catch (err) {
			dispatch({ type: 'CONFIRM_PAYMENT_REJECTED', err })
		}
	}
	const renderLabel = (value) => {
		if (!value) {
			return ''
		} else {
			return moment(value).format('DD MMMM YYYY')
		}
	}

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>
				<CustomModal
					icon={'error'}
					open={notifError}
					onClose={() => setNotifError(false)}
				>
					<Typography variant='h6'>
						<strong>{erroString}</strong>
					</Typography>
					<Box display='flex' flexDirection='row' mt={3} justifyContent='center'>
						<Button
							size='small'
							variant='contained'
							onClick={() => setNotifError(false)}
						>
							OK
						</Button>
					</Box>
				</CustomModal>
				{/* top form */}
				<Box mb={3}>
					<Typography variant='h6'>Konfirmasi Pembayaran</Typography>
					<Paper className={classes.formRfqTop}>
						<Box py={2} px={3}>
							{/* ============================================== */}
							<Formik
								enableReinitialize
								initialValues={{
									jumlahPembayaran: '',
									catatan: '',
									transferDate: null,
									patrnerLabel: '',
									mbizLabel: '',
									partnerBank: '',
									mbizBank: '',
									img1: null,
									img2: null,
									img3: null,
								}}
								onSubmit={(values, action) => handleSubmitForm(values, action)}
								validationSchema={paymentConfirmationSchema}
							>
								{({ handleChange, values, errors, touched, ...prop }) => (
									<Form method='post'>
										{/* ===================================== */}
										<Box mb={1}>
											<Grid container alignItems='center'>
												<Grid item lg={2}>
													<Typography variant='body2'>
														<strong>No. Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item lg={3}>
													{invoiceDetail.isLoading ? (
														'-'
													) : (
														<a
															href={`${window.location.origin}/dashboard/invoice/${invoiceDetail.data.name}`}
															target='blank'
														>
															{invoiceDetail.data.name}
														</a>
													)}
												</Grid>
												<Grid item lg={3}>
													<Typography variant='body2'>
														<strong>Transfer Dari</strong>
													</Typography>
												</Grid>
												<Grid item lg={4}>
													<Box>
														<Dropdown
															onClickAway={() => setMenuPartner(false)}
															onClick={() => setMenuPartner(true)}
															visible={menuPartner}
															inputName='patrnerLabel'
															onChange={handleChange}
															value={values.patrnerLabel}
															error={errors.patrnerLabel && touched.patrnerLabel}
														>
															<>
																{bankAccount.partnerAccount.isLoading
																	? null
																	: bankAccount.partnerAccount.data &&
																	  bankAccount.partnerAccount.data.result.length != 0
																	? bankAccount.partnerAccount.data.result.map((item, index) => (
																			<MenuItem
																				key={index}
																				onClick={() => handleSelectItem('partner', item, prop)}
																			>
																				{item.display_name}
																			</MenuItem>
																	  ))
																	: null}
															</>
														</Dropdown>
														<Box>
															<Typography variant='caption' className={classes.textValidation}>
																{errors.patrnerLabel &&
																	touched.patrnerLabel &&
																	errors.patrnerLabel}
															</Typography>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</Box>
										{/* =========================================== */}

										<Box mb={1}>
											<Grid container alignItems='center'>
												<Grid item lg={2}>
													<Typography variant='body2'>
														<strong>Jumlah Pembayaran</strong>
													</Typography>
												</Grid>
												<Grid item lg={3}>
													<Box pr={3}>
														<TextField
															name='jumlahPembayaran'
															size='small'
															error={errors.jumlahPembayaran && touched.jumlahPembayaran}
															fullWidth
															variant='outlined'
															onChange={handleChange}
															value={values.jumlahPembayaran}
														/>
														<Box>
															<Typography variant='caption' className={classes.textValidation}>
																{errors.jumlahPembayaran &&
																	touched.jumlahPembayaran &&
																	errors.jumlahPembayaran}
															</Typography>
														</Box>
													</Box>
												</Grid>
												<Grid item lg={3}>
													<Typography variant='body2'>
														<strong>Transfer Ke</strong>
													</Typography>
												</Grid>
												<Grid item lg={4}>
													<Box>
														<Dropdown
															onClickAway={() => setMenuMbiz(false)}
															onClick={() => setMenuMbiz(true)}
															visible={menuMbiz}
															inputName='mbizLabel'
															onChange={handleChange}
															value={values.mbizLabel}
															error={errors.mbizLabel && touched.mbizLabel}
														>
															<>
																{bankAccount.mbizAccount.isLoading
																	? null
																	: bankAccount.mbizAccount.data &&
																	  bankAccount.mbizAccount.data.result.length != 0
																	? bankAccount.mbizAccount.data.result.map((item, index) => (
																			<MenuItem
																				key={index}
																				onClick={() => handleSelectItem('mbiz', item, prop)}
																			>
																				{item.display_name}
																			</MenuItem>
																	  ))
																	: null}
															</>
														</Dropdown>
														<Box>
															<Typography variant='caption' className={classes.textValidation}>
																{errors.mbizLabel && touched.mbizLabel && errors.mbizLabel}
															</Typography>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</Box>
										{/* =========================================== */}

										<Box mb={1}>
											<Grid container alignItems='center'>
												<Grid item lg={2}>
													<Typography variant='body2'>
														<strong>Sisa Pembayaran</strong>
													</Typography>
												</Grid>
												<Grid item lg={3}>
													<Typography variant='body2'>
														{invoiceDetail.isLoading
															? '-'
															: numeralFormat(invoiceDetail.data.amount_residual_signed) ||
															  '-'}
													</Typography>
												</Grid>
												<Grid item lg={3}>
													<Typography variant='body2'>
														<strong>Tanggal Transfer</strong>
													</Typography>
												</Grid>
												<Grid item lg={4}>
													<Box>
														<KeyboardDatePicker
															fullWidth
															disableFuture
															autoOk
															error={errors.transferDate && touched.transferDate}
															name='transferDate'
															format={'YYYY MMMM DD'}
															onChange={(value) =>
																prop.setFieldValue('transferDate', value, true)
															}
															value={values.transferDate}
															inputVariant='outlined'
															variant='inline'
															labelFunc={renderLabel}
															size='small'
															disableToolbar
														/>
													</Box>
													<Typography variant='caption' className={classes.textValidation}>
														{errors.transferDate &&
															touched.transferDate &&
															errors.transferDate}
													</Typography>
												</Grid>
											</Grid>
										</Box>
										{/* =========================================== */}

										<Box mb={1}>
											<Grid container alignItems='center'>
												<Grid item lg={2}>
													<Typography variant='body2'>
														<strong>Total Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item lg={3}>
													{invoiceDetail.isLoading
														? null
														: numeralFormat(invoiceDetail.data.amount_total_signed) || '-'}
												</Grid>
												<Grid item lg={3}>
													<Typography variant='body2'>
														<strong>Catatan</strong>
													</Typography>
												</Grid>
												<Grid item lg={4}>
													<TextField
														name='catatan'
														value={values.catatan}
														multiline
														rows={4}
														fullWidth
														onChange={handleChange}
														variant='outlined'
														placeholder='Catatan Pembayaran'
													/>
												</Grid>
											</Grid>
										</Box>
										{/* =========================================== */}

										<Box mb={1}>
											<Grid container alignItems='center'>
												<Grid item lg={2}>
													<Typography variant='body2'>
														<strong>Bukti Pembayaran</strong>
													</Typography>
												</Grid>
												<Grid item lg={3}>
													<Box display='flex' flexDirection='row'>
														<Box
															mr={1}
															width={70}
															height={70}
															overflow='hidden'
															display='flex'
															alignItems='center'
															justifyContent='center'
															className={classes.colorImgBox}
														>
															<ButtonBase onClick={() => handleUploadImage(1)} err>
																<input
																	ref={(el) => (inputRef.current[1] = el)}
																	name={'img'}
																	type='file'
																	accept='image/*'
																	style={{ display: 'none' }}
																	onChange={() => onChangeInput(1, prop)}
																/>
																<AddIcon
																	fontSize='large'
																	style={{ position: 'absolute' }}
																	className={classes.colorPlus}
																/>
																<img
																	src={img[0]}
																	style={{
																		objectFit: 'fill',
																		width: 70,
																		height: 70,
																		zIndex: 1,
																	}}
																/>
															</ButtonBase>
														</Box>
														<Box
															mr={1}
															width={70}
															height={70}
															overflow='hidden'
															display='flex'
															alignItems='center'
															justifyContent='center'
															className={classes.colorImgBox}
														>
															<ButtonBase onClick={() => handleUploadImage(2)}>
																<input
																	ref={(el) => (inputRef.current[2] = el)}
																	name={'img'}
																	type='file'
																	accept='image/*'
																	style={{ display: 'none' }}
																	onChange={() => onChangeInput(2, prop)}
																/>
																<AddIcon
																	fontSize='large'
																	style={{ position: 'absolute' }}
																	className={classes.colorPlus}
																/>
																<img
																	src={img[1]}
																	style={{
																		objectFit: 'fill',
																		width: 70,
																		height: 70,
																		zIndex: 1,
																	}}
																/>
															</ButtonBase>
														</Box>
														<Box
															mr={1}
															width={70}
															height={70}
															overflow='hidden'
															display='flex'
															alignItems='center'
															justifyContent='center'
															className={classes.colorImgBox}
														>
															<ButtonBase onClick={() => handleUploadImage(3)}>
																<input
																	ref={(el) => (inputRef.current[3] = el)}
																	name={'img'}
																	type='file'
																	accept='image/*'
																	style={{ display: 'none' }}
																	onChange={() => onChangeInput(3, prop)}
																/>
																<AddIcon
																	fontSize='large'
																	style={{ position: 'absolute' }}
																	className={classes.colorPlus}
																/>
																<img
																	src={img[2]}
																	style={{
																		objectFit: 'fill',
																		width: 70,
																		height: 70,
																		zIndex: 1,
																	}}
																/>
															</ButtonBase>
														</Box>
													</Box>
													<Box mt={1}>
														{errors.img1 && touched.img1 ? (
															<Typography variant='caption' color='error'>
																Bukti pembayaran harus di unggah
															</Typography>
														) : null}
													</Box>
													<Box mt={1}>
														<Typography variant='caption'>Ukuran Maksimal 10MB</Typography>
														<br />
														<Typography variant='caption'>
															Format berkas yang diperbolehkan: JPG, JPEG, PNG
														</Typography>
													</Box>
												</Grid>
											</Grid>
										</Box>
										{/* =========================================== */}

										{/* button action wrapper */}
										<Box className={classes.containerButton} mt={2}>
											<Button
												className={classes.buttonWidth}
												variant='contained'
												size='small'
												color='secondary'
												onClick={() => validateForm(prop, values.jumlahPembayaran)}
											>
												Konfirmasi Pembayaran
											</Button>
											<Button
												className={`${classes.buttonWidth} ${classes.kembali} ${classes.buttonDanger}`}
												variant='contained'
												onClick={() => navigate(`/dashboard/invoice/${invoiceId}`)}
												size='small'
												color='secondary'
											>
												Kembali
											</Button>
										</Box>
										{/* end of button action wrapper */}

										{/* ============ modal ============= */}
										{/* modal confirmation */}
										<Modal
											open={modalConfirm}
											icon='question'
											onClose={() => setModalConfirm(false)}
										>
											<Typography variant='body1'>
												<strong>
													Apakah anda yakin untuk mengkonfirmasi pembayaran berikut ?
												</strong>
											</Typography>
											{invoiceDetail.isLoading ? null : Number(jumlah) <
											  invoiceDetail.data.amount_residual_signed ? (
												<Typography variant='caption'>
													Jumlah pembayaran yang anda masukkan kurang dari total sisa
													pembayaran
												</Typography>
											) : null}

											<Box
												display='flex'
												flexDirection='row'
												alignItems='center'
												justifyContent='space-evenly'
												mt={3}
											>
												<Button
													variant='contained'
													size='small'
													className={`${classes.buttonDanger} ${classes.buttonWidth}`}
													onClick={() => setModalConfirm(false)}
												>
													Kembali
												</Button>
												<Button
													variant='contained'
													size='small'
													onClick={() => prop.submitForm()}
													color='secondary'
												>
													Konfirmasi Pembayaran
												</Button>
											</Box>
										</Modal>
										{/* end of modal confirmation */}

										{/* modal succss */}
										<Modal
											open={modalSuccess}
											icon='success'
											onClose={() => setModalSuccess(false)}
										>
											<Typography variant='body1'>
												<strong>Konfirmasi Pembayaran Sukses</strong>
											</Typography>
										</Modal>
										{/* end of modal success */}
										{/* ============== END OF MODAL ==================== */}
									</Form>
								)}
							</Formik>

							{/* =============================== */}
						</Box>
					</Paper>
				</Box>
				{/* end of top form */}
			</Container>
		</Layout>
	)
}

Component.propTypes = {
	invoiceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	navigate: PropTypes.func,
}

export default React.memo(Component)
