import React from 'react'
import { Paper, Grid, Box } from '@material-ui/core'
import LayoutComponent from '../layout'
// import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import UnileverSide from '../../assets/images/background/unilever-side.png'

// const handleNavigate = path => {
// 	navigate(path)
// }

const LoginLayout = ({ children, classes, type = 'form' }) => {
	const classes_loginStyle = classes
	return (
		<LayoutComponent
			header={false}
			newsletter={false}
			className={classes_loginStyle.login_background}
			isFooter={false}
		>
			{type == 'form' ? (
				<Grid className={classes_loginStyle.form_login} container justify='center'>
					<Grid
						item
						xs={12}
						sm={4}
						className={`${classes_loginStyle.container_field} ${classes_loginStyle.radiusLeft}`}
						style={{ position: 'relative' }}
					>
						<Box position='absolute' style={{ top: 0, left: 0 }}>
							<img src={UnileverSide} style={{ width: '100%' }} />
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						className={`${classes_loginStyle.container_field} ${classes_loginStyle.radiusRight}`}
					>
						{children}
					</Grid>
				</Grid>
			) : type == 'message' ? (
				<Paper className={classes_loginStyle.message_container} variant='outlined'>
					{children}
				</Paper>
			) : null}
		</LayoutComponent>
	)
}

LoginLayout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	classes: PropTypes.object,
	type: PropTypes.string,
}
export default LoginLayout
