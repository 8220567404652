import React from 'react'
import {
	Modal,
	Fade,
	Paper,
	Backdrop,
	Box,
	Typography,
	Divider,
	TextField,
	Button,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { confirmShipping } from 'States/actions/Transactions/PurchaseOrder/shippingConfirmationAction'
import { requestDetailPo } from 'States/actions/Transactions/PurchaseOrder/detailAction'
import { KeyboardDatePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import styles from './styles'

const ModalConfirm = (props) => {
	const classes = styles()
	const dispatch = useDispatch()

	const submitForm = async (values, actions) => {
		const confirm = await dispatch(
			confirmShipping(props.dnid, values, props.idSaleOrder)
		)
		if (confirm) {
			actions.setSubmitting(false)
			props.onClose()
			dispatch(requestDetailPo(props.data.purchase_order_no))
		}
	}
	return (
		<Modal
			className={classes.modal}
			BackdropComponent={Backdrop}
			open={props.open}
			BackdropProps={{
				timeout: 500,
			}}
			onClose={props.onClose}
			{...props}
		>
			<Fade in={props.open} className={classes.modalFade}>
				<Paper>
					<Box
						display='flex'
						flexDirection='column'
						className={classes.modalContent}
					>
						<Typography variant='body1'>
							<strong>
								Konfirmasi Penerimaan Pesanan: {props.data.purchase_order_no}
							</strong>
						</Typography>
						<Divider className={classes.divider} />
						<Typography variant='body2'>
							Pastikan anda sudah menerima pesanan untuk surat jalan: {props.dnNumber}
						</Typography>
						<Formik
							initialValues={{
								name: '',
								date: new Date(),
							}}
							onSubmit={(values, actions) => submitForm(values, actions)}
						>
							{({
								handleSubmit,
								values,
								handleChange,
								setFieldValue,
								isSubmitting,
							}) => (
								<Form onSubmit={handleSubmit}>
									<Box display='flex' flexDirection='row' mt={3}>
										<Typography
											className={classes.inputLabelModalConfirm}
											variant='body1'
										>
											<strong>Nama Penerima</strong>
										</Typography>
										<TextField
											fullWidth
											disabled={isSubmitting}
											onChange={handleChange}
											value={values.name}
											size='small'
											variant='outlined'
											name='name'
											placeholder='Masukkan Nama Penerima'
										/>
									</Box>
									<Box display='flex' flexDirection='row' mt={2}>
										<Typography
											className={classes.inputLabelModalConfirm}
											variant='body1'
										>
											<strong>Tanggal Pesanan Diterima</strong>
										</Typography>
										<KeyboardDatePicker
											// minDate={new Date()}
											disableFuture
											autoOk
											disabled={isSubmitting}
											onChange={(value) => setFieldValue('date', value)}
											value={values.date}
											disableToolbar
											name='date'
											fullWidth
											format={'DD MMMM YYYY'}
											size='small'
											inputVariant='outlined'
										/>
									</Box>

									<Box mt={3} display='flex' justifyContent='end'>
										<Button
											onClick={props.onClose}
											variant='contained'
											size='small'
											className={[classes.buttonDanger, classes.kembali].join(' ')}
										>
											Batal
										</Button>
										<Button
											type='submit'
											variant='contained'
											size='small'
											color='secondary'
											disabled={isSubmitting}
										>
											Konfirmasi
										</Button>
									</Box>
								</Form>
							)}
						</Formik>
					</Box>
				</Paper>
			</Fade>
		</Modal>
	)
}

ModalConfirm.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	data: PropTypes.object,
	dnid: PropTypes.number,
	dnNumber: PropTypes.string,
	idSaleOrder: PropTypes.number,
}

export default ModalConfirm
