import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Grid,
	Typography,
	Paper,
	Divider,
	Button,
	Container,
} from '@material-ui/core'
import { DetailuserAccountStyle } from './styles'
import {
	requestUserAccountDetail,
	resetAction,
} from 'States/actions/UsersAccount/detailAction'
import { navigate } from 'gatsby'
import { arrObjectToStr } from '../../../utils/globalUtils'
import Breadcrumbs from 'Components/breadcrumbs'
import HelpIcon from '@material-ui/icons/Help'
import { RolesDialog, DepartmentDialog } from 'Components/dialogs'
import PropTypes from 'prop-types'
// import * as qs from 'query-string'

const initDataAccount = {
	name: '',
	username: '',
	email: '',
	mobile: '',
	jobTitle: '',
	status: '',
	departmentName: '',
}

const DetailuserAccount = ({ userID }) => {
	// breadcumbs
	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/dashboard',
		},
		{
			segment: 'Perusahaan',
			link: '/dashboard',
		},
		{
			segment: 'Daftar Akun Pengguna',
			link: '/dashboard/user-account',
		},
		{
			segment: 'Rincian Akun Pengguna',
			link: '#',
		},
	]

	const [open, setOpen] = useState(false)
	const [open2, setOpen2] = useState(false)
	const [scroll, setScroll] = useState('paper')

	const classes = DetailuserAccountStyle()
	const [listRoles, setListRoles] = useState('')
	const [showDepartment, setShowDepartment] = useState(false)
	const dispatch = useDispatch()
	const responseData = useSelector((state) => state.UsersAccountDetailReducer)
	const [dataAccount, setDataAccount] = useState(initDataAccount)

	useEffect(() => {
		setDataAccount({ ...initDataAccount })
		dispatch(requestUserAccountDetail(userID))
	}, [userID])

	//unmount doing reset action
	useEffect(() => {
		return () => {
			dispatch(resetAction())
		}
	}, [])

	useEffect(() => {
		if (responseData.isLoading == false && responseData.data != null) {
			let dataTemp = dataAccount
			dataTemp.name = responseData.data.name
			dataTemp.username = responseData.data.username
			dataTemp.email = responseData.data.email
			dataTemp.mobile = responseData.data.mobile
			dataTemp.jobTitle = responseData.data.job_title
			dataTemp.status = responseData.data.status
			dataTemp.departmentName =
				responseData.data.status == 98 &&
				responseData.data.user_department.company_departments.status == 98
					? '-'
					: responseData.data.user_department.company_departments.name != ''
					? responseData.data.user_department.company_departments.name
					: '-'
			setDataAccount(dataTemp)

			let arrRoles = []
			let user_buyer_roles = responseData.data.user_buyer_roles
			if (user_buyer_roles && user_buyer_roles.length > 0) {
				user_buyer_roles.forEach((items) => {
					arrRoles.push(items.user_roles)
				})
				setListRoles(arrObjectToStr(arrRoles, 'role_name', ', '))
			}
		}
	}, [responseData.isLoading, responseData.data])

	useEffect(() => {
		if (listRoles.length && listRoles.includes('Pemohon')) setShowDepartment(true)
		else setShowDepartment(false)
	}, [listRoles])

	const handleClickOpen = (scrollType) => () => {
		setOpen(true)
		setScroll(scrollType)
	}

	const handleClickOpen2 = (scrollType) => () => {
		setOpen2(true)
		setScroll(scrollType)
	}

	return (
		<DashboardLayout>
			<Container>
				<Box height={70} alignItems='center' display='flex'>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>
				<Box p={1}>
					<Typography variant='h6'>Rincian Akun Pengguna</Typography>
					<Paper variant='outlined' elevation={3} className={classes.boxSpacing}>
						<Box p={2}>
							<Typography className={classes.formTitle}>
								Informasi Dasar Pengguna
							</Typography>
							<Divider />
							<Grid
								container
								spacing={3}
								justify='space-between'
								className={classes.formField}
							>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='subtitle1'>Nama Lengkap</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='body1'>{dataAccount.name}</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='subtitle1'>Username</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='body1'>{dataAccount.username}</Typography>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={3}
								justify='space-between'
								className={classes.formField}
							>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='subtitle1'>Email</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='body1'>{dataAccount.email}</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='subtitle1'>No.Ponsel</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='body1'>{dataAccount.mobile}</Typography>
								</Grid>
							</Grid>
						</Box>
					</Paper>
					<Paper variant='outlined' elevation={3} className={classes.boxSpacing}>
						<Box p={2}>
							<Typography className={classes.formTitle}>
								Informasi Departemen Pengguna
							</Typography>
							<Divider />
							<Grid
								container
								spacing={3}
								justify='space-between'
								className={classes.formField}
							>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='subtitle1'>Jabatan</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='body1'>{dataAccount.jobTitle}</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography component='span' variant='subtitle1'>
										Roles{' '}
										<Button
											onClick={handleClickOpen('paper')}
											size='small'
											className={classes.customTooltip}
										>
											<HelpIcon className={classes.customIcon} />
										</Button>
										<RolesDialog
											open={open}
											handleClose={() => setOpen(false)}
											scroll={scroll}
										/>
									</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='body1'>{listRoles}</Typography>
								</Grid>
							</Grid>
							{showDepartment ? (
								<Grid
									container
									spacing={3}
									justify='space-between'
									className={classes.formField}
								>
									<Grid item xs={3} className={classes.formLabel}></Grid>
									<Grid item xs={3} className={classes.formLabel}></Grid>
									<Grid item xs={3} className={classes.formLabel}>
										<Typography variant='subtitle1'>
											Departemen Untuk Pembelian
											<Button
												onClick={handleClickOpen2('paper')}
												size='small'
												className={classes.customTooltip}
											>
												<HelpIcon className={classes.customIcon} />
											</Button>
											<DepartmentDialog open={open2} handleClose={() => setOpen2(false)} />
										</Typography>
									</Grid>
									<Grid item xs={3} className={classes.formLabel}>
										<Typography variant='body1'>{dataAccount.departmentName}</Typography>
									</Grid>
								</Grid>
							) : null}
						</Box>
					</Paper>
					<Paper variant='outlined' elevation={3} className={classes.boxSpacing}>
						<Box p={2}>
							<Grid container justify='flex-start' className={classes.formField}>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='subtitle1'>Status</Typography>
								</Grid>
								<Grid item xs={3} className={classes.formLabel}>
									<Typography variant='body1'>
										{dataAccount.status && dataAccount.status == 99
											? 'Aktif'
											: 'Tidak Aktif'}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Paper>
					<Grid
						container
						spacing={1}
						justify='flex-end'
						className={classes.boxSpacing}
					>
						<Grid item>
							<Button
								variant='contained'
								onClick={() => navigate('/dashboard/user-account')}
							>
								Kembali
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

DetailuserAccount.propTypes = {
	userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default DetailuserAccount
