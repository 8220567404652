import { makeStyles } from '@material-ui/core/styles'

export const Styles = makeStyles((theme) => ({
	buttonSearch: {
		padding: '4.5px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: theme.shape.borderRadius,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	gridSearch: {
		width: '85%',
	},
	buttonExport: {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
	},
	buttonAction: {
		margin: '0 5px',
		minWidth: 'auto',
		padding: theme.spacing(0.5),
	},
	btnEdit: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnDelete: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	buttonRed: {
		color: theme.palette.text.secondary,
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		width: '32px',
		top: 'inherit',
	},
	selectContainer: {
		borderRadius: theme.shape.borderRadius,
		paddingRight: '45px !important',
		marginRight: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	selectInput: {
		padding: '7px 0 6px 12px !important',
	},
	inputRoot: {
		paddingRight: '0 !important',
		width: 'calc(100% - 20px)',
	},
	endAdornment: {
		top: 0,
	},
}))
