/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import {
	Address,
	AddressAdd,
} from '../routes'
import { rolesText } from '../constants/text'

export const AddressNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard/company-address'
				roles={rolesText('invoice')}
				Component={Address}
			/>
			<PrivateRoute
				path='/dashboard/company-address/new'
				roles={rolesText('invoice')}
				Component={AddressAdd}
			/>
			<PrivateRoute
				path='/dashboard/company-address/edit/:addressID'
				roles={rolesText('invoice')}
				Component={AddressAdd}
			/>
		</>
	)
}
