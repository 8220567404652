/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import styles from './styles'
import { useSelector, useDispatch } from 'react-redux'
import {
	requestDetailRfq,
	cancelRfq,
	resetAction as resetActionRfq,
} from 'States/actions/Transactions/RFQ/detailRfqAction'
import { withStyles } from '@material-ui/core/styles'
import Modal from 'Components/modal'
import {
	Box,
	Button,
	Container,
	Paper,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	MenuItem,
	TextField,
	CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Layout from 'Components/layout'
import Alert from 'Components/alert'
import Breadcrumbs from 'Components/breadcrumbs'
import Image from 'material-ui-image'
import { formatMoney, numeralFormat } from '../../../../utils/formatMoney'
import {
	dateFormatFromOdoo,
	generateAddress,
} from '../../../../utils/globalUtils'
import { Dropdown } from '../../../../components/dropdown'
import { RfqcancelReason } from '../../../../utils/cancelReason'
import { rolePemohon, checkRole } from '../../../../utils/acl'
import Skeleton from '@material-ui/lab/Skeleton'
// import Status from 'Components/status'
import ScrollToTop from 'Components/scrollToTop'
import Timeline from 'Components/timeline'
import {
	getTimeline,
	resetAction,
} from 'States/actions/Transactions/Timeline/timelineAction'
import { dataTransactions } from '../../../../utils/bodyDataObject'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)

const Component = ({ rfqId, navigate }) => {
	const classes = styles()
	const dispatch = useDispatch()
	const [breadcrums, setBreadcrums] = useState([])
	const [modalCancel, setModalCancel] = useState(false)
	const [dropdownList, setDropdownList] = useState(false)
	const [optionState, setOptionState] = useState(false)
	const [errorCancelDetail, setErorCancelDetail] = useState(false)
	const [cancel, setCancel] = useState({
		cancelled_reason: '',
		cancelled_reason_detail: '',
	})
	const detailRfq = useSelector((state) => state.detailRfq)
	const imageStorage = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'
	const allowedToCancel = checkRole(`${rolePemohon}`, false)
	const timelineData = useSelector((state) => state.timelineReducer)
	// data detail state
	const [dataRFQ, setDataRFQ] = useState({ ...dataTransactions })

	useEffect(() => {
		dispatch(requestDetailRfq(rfqId))
		return () => {
			dispatch(resetActionRfq())
		}
	}, [rfqId])

	useEffect(() => {
		if (
			detailRfq.data != null &&
			detailRfq.isLoading == false &&
			detailRfq.data.rfq_no == rfqId &&
			detailRfq.data.transaction_id &&
			detailRfq.data.transaction_id[0]
		) {
			let dataTemp = dataRFQ
			dataTemp.noRFQ = detailRfq.data.rfq_no
			dataTemp.noQuotation = detailRfq.data.parent_saleorder_id?.quotation_no ?? ''
			dataTemp.NoRefPO = detailRfq.data.client_order_ref
			dataTemp.timeCreate = detailRfq.data.create_date
			dataTemp.expiredDate = detailRfq.data.rfq_expiry_date
			dataTemp.requestor = detailRfq.data.requestor_name
			dataTemp.deparRequestor = detailRfq.data.requestor_department
			dataTemp.internalNotes = detailRfq.data.customer_internal_notes
			dataTemp.shippingAdd = detailRfq.data.partner_shipping_id.street
			dataTemp.shippingAddLocation = detailRfq.data.partner_shipping_id.region_id.complete_name
			dataTemp.billingAdd = detailRfq.data.partner_invoice_id.street
			dataTemp.billingAddLocation = detailRfq.data.partner_invoice_id.region_id.complete_name
			dataTemp.expectedDelivDate = detailRfq.data.expected_delivery_date
			dataTemp.receiverName = detailRfq.data.receiver_name
			dataTemp.receiverPhone = detailRfq.data.receiver_phone
			dataTemp.state = detailRfq.data.state ?? ''
			dataTemp.parentState = detailRfq.data.parent_saleorder_id?.state ?? ''
			dataTemp.note = detailRfq.data.note
			dataTemp.amountUntax = detailRfq.data.amount_untaxed
			dataTemp.amountTotal = detailRfq.data.amount_total
			dataTemp.amountGroup = detailRfq.data.amount_by_group
			dataTemp.orderLine = detailRfq.data.order_line
			dataTemp.id = detailRfq.data.id
			setDataRFQ(dataTemp)
			dispatch(getTimeline(detailRfq.data.transaction_id[0].id))
		} else {
			setDataRFQ({ ...dataTransactions })
			dispatch(resetAction())
		}
	}, [detailRfq.data, detailRfq.isLoading, rfqId])

	const splitVariant = (str) => {
		const arr = str.match(/\(.+?\)/g)
		let joinVar = '-'
		if (arr) {
			const variant = arr.map((item) => item.slice(1, -1))
			joinVar = variant
		}

		return joinVar
	}

	useEffect(() => {
		if (detailRfq.data) {
			handleSetBreadcrums(detailRfq.data.rfq_no)
		}
	}, [detailRfq.data])

	// breadcrums function
	const handleSetBreadcrums = (value) => {
		let tmp_breadcrums = [
			{
				segment: 'Home',
				link: '/dashboard/',
			},
			{
				segment: 'Transaksi',
				link: '/dashboard',
			},
			{
				segment: 'RFQ',
				link: '/dashboard/rfq',
			},
		]
		setBreadcrums([
			...tmp_breadcrums,
			{
				segment: value,
				link: '#',
			},
		])
	}

	const handleCancel = () => {
		let data = {}
		data.id = detailRfq.data.id
		data.cancelled_reason = cancel.cancelled_reason
		const valueCancelReasonDetal = cancel.cancelled_reason_detail

		if (cancel.cancelled_reason === '') {
			setOptionState(true)
			return false
		}
		if (!valueCancelReasonDetal.length && cancel.cancelled_reason === 'Lainnya') {
			setErorCancelDetail(true)
			return false
		}

		if (cancel.cancelled_reason === 'Lainnya') {
			data.cancelled_reason = cancel.cancelled_reason_detail
		}

		cancelRfq(data).then(() => {
			navigate('/dashboard/rfq')
			setModalCancel(false)
		})
	}

	const selectCancelReason = (item) => {
		setDropdownList(false)
		setOptionState(false)
		setCancel({ ...cancel, cancelled_reason: item.value })
	}

	const handleChangeCancelReasonDetail = (e) => {
		if (e.target.value.length) setErorCancelDetail(false)
		setCancel({ ...cancel, cancelled_reason_detail: e.target.value })
	}
	const renderButtonCancel = (state, parentState) => {
		if (allowedToCancel && (state == 'rfq' && !parentState) || (state == 'revised' && parentState == 'draft')) {
			return (
				<Button
					className={`${classes.buttonWidth} ${classes.kembali}  ${classes.buttonDanger}`}
					variant='contained'
					onClick={() => setModalCancel(true)}
					size='small'
				>
					Batalkan RFQ
				</Button>
			)
		} else {
			return null
		}
	}

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>

				<Box mb={3}>
					<Typography variant='h6'>Rincian Permintaan (RFQ)</Typography>
					<Paper className={classes.formRfqTop}>
						<Box py={2} px={3}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. RFQ</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															<a
																href={`${window.location.origin}/dashboard/rfq/${dataRFQ.noRFQ}`}
																target='_blank'
															>
																{dataRFQ.noRFQ}
															</a>
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Quotation</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : dataRFQ.noQuotation && dataRFQ.state != 'draft' ? (
															<a
																href={`${window.location.origin}/dashboard/quotation/${dataRFQ.noQuotation}`}
																target='_blank'
																no='noopener'
															>
																{dataRFQ.noQuotation}
															</a>
														) : (
															'-'
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Referensi PO</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? <Skeleton variant='text' /> : dataRFQ.NoRefPO}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Waktu RFQ Dibuat</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dateFormatFromOdoo(dataRFQ.timeCreate, 'dd MMMM yyyy')
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Batas Membuat Quotation</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dateFormatFromOdoo(dataRFQ.expiredDate, 'dd MMMM yyyy')
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Pemohon</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataRFQ.requestor
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Departemen Pemohon</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataRFQ.deparRequestor
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Untuk Keperluan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataRFQ.internalNotes
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Ekspektasi Pesanan Diterima</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dateFormatFromOdoo(dataRFQ.expectedDelivDate, 'dd MMMM yyyy') || '-'
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Pengiriman</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataRFQ.shippingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataRFQ.shippingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Nama Penerima</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															<Typography
																variant="body2"
																style={{
																	wordBreak: 'break-word',
																}}
															>
																{dataRFQ.receiverName}
															</Typography>
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Telepon Penerima</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													{detailRfq.isLoading ? (
														<Skeleton variant='text' />
													) : (
														<Typography
															variant="body2"
															style={{
																wordBreak: 'break-word',
															}}
														>
															{dataRFQ.receiverPhone}
														</Typography>
													)}
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataRFQ.billingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{detailRfq.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataRFQ.billingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Box>

				{/* bottom form */}
				<Box>
					<Paper>
						<Box py={2} px={3}>
							{/* table */}
							<Box>
								<Table classes={{ root: classes.tableRoot }}>
									<TableHead>
										<TableRow>
											<TableCell align='center'>No</TableCell>
											<TableCell align='center'>Produk</TableCell>
											<TableCell align='center'>Unit</TableCell>
											<TableCell align='center'>Kuantitas</TableCell>
											<TableCell align='center'>Harga / Unit</TableCell>
											<TableCell align='center'>Total Harga</TableCell>
											<TableCell align='center'>Pajak</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{detailRfq.isLoading ? (
											<TableRow key={'skeleton'}>
												<CustomCell>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell>
													<Box display='flex' flexDirection='row'>
														<Box overflow='hidden' width={60} height={60}>
															<Skeleton variant='rect' width={60} height={60} />
														</Box>
														<Box
															ml={1}
															display='flex'
															flexDirection='column'
															justifyContent='space-between'
															style={{ width: '100%' }}
														>
															<Box>
																<Typography variant='body2'>
																	<Skeleton variant='text' />
																</Typography>
																<Typography variant='caption'>
																	<Skeleton variant='text' />
																</Typography>
															</Box>
															<Typography variant='caption'>
																<Skeleton variant='text' />
															</Typography>
														</Box>
													</Box>
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell
													align='center'
													style={{
														maxWidth: 120,
													}}
												>
													<Skeleton variant='text' />
												</CustomCell>
											</TableRow>
										) : (
											dataRFQ.orderLine.map((item, index) => (
												<TableRow key={item.id}>
													<CustomCell>{index + 1}</CustomCell>
													<CustomCell>
														<Box display='flex' flexDirection='row'>
															<Box>
																<Image
																	src={
																		imageStorage +
																		item.product_id.product_tmpl_id.image_128_filestore
																	}
																	style={{ width: 60, height: 60 }}
																/>
															</Box>
															<Box
																ml={1}
																display='flex'
																flexDirection='column'
																justifyContent='space-between'
															>
																<Box>
																	<Typography variant='body2'>
																		{item.product_id.sku_no}
																	</Typography>
																	<Typography variant='caption'>{item.name}</Typography>
																</Box>
																<Typography variant='caption'>
																	varian: {splitVariant(item.name)}
																</Typography>
															</Box>
														</Box>
													</CustomCell>
													<CustomCell align='center'>{item.product_uom.name}</CustomCell>
													<CustomCell align='center'>{item.product_uom_qty}</CustomCell>
													<CustomCell align='center'>
														{formatMoney(item.price_unit)}
													</CustomCell>
													<CustomCell align='center'>
														{formatMoney(item.price_subtotal)}
													</CustomCell>
													<CustomCell
														align='center'
														style={{
															maxWidth: 120,
														}}
													>
														{item.tax_id.map((tax) => `${tax.name} `)}
													</CustomCell>
												</TableRow>
											))
										)}
									</TableBody>
								</Table>
							</Box>
							{/* end of table */}

							{/* total */}
							<Box mt={2}>
								<Box display='flex' flex='1' justifyContent='space-between' mb={2}>
									<Box>
										<Typography variant='body1' className={classes.font14}>
											<strong>Catatan Permintaan Khusus</strong>
										</Typography>
										<Typography variant='body1' className={classes.font14}>
											{detailRfq.isLoading ? null : dataRFQ.note}
										</Typography>
									</Box>
									<Box display='flex' minWidth={400} flexDirection='column'>
										<Box px={3} display='flex' flex='1' justifyContent='space-between'>
											<Typography variant='body1'>Subtotal</Typography>
											<Typography variant='body1'>
												{detailRfq.isLoading ? (
													<Skeleton variant='text' width={200} />
												) : (
													numeralFormat(dataRFQ.amountUntax)
												)}
											</Typography>
										</Box>
										{detailRfq.isLoading
											? null
											: dataRFQ.amountGroup.map((tax, index) => (
												<Box
													key={index}
													px={3}
													display='flex'
													flex='1'
													justifyContent='space-between'
												>
													<Typography variant='body1'>{tax[0]}</Typography>
													<Typography variant='body1'>{numeralFormat(tax[1])}</Typography>
												</Box>
											))}
										<Box className={classes.totalPrice}>
											<Typography variant='body1'>
												<strong>TOTAL</strong>
											</Typography>
											<Typography variant='body1'>
												{detailRfq.isLoading ? (
													<Skeleton variant='text' width={200} />
												) : (
													numeralFormat(dataRFQ.amountTotal)
												)}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							{/* end of total */}
							<Alert variant='warning'>
								<Typography variant='caption'>
									*Total harga diatas belum termasuk biaya pengiriman, biaya pengiriman
									akan diinformasikan pada Quotation yang anda terima setelah Mbiz
									mengkonfirmasi permintaan anda
								</Typography>
							</Alert>
						</Box>
					</Paper>
				</Box>
				<Box className={classes.containerButton} mt={2}>
					<Button
						className={[classes.buttonWidth, classes.kembali, classes.btnWhite].join(
							' '
						)}
						variant='outlined'
						onClick={() => navigate('/dashboard/rfq')}
						size='small'
						color='secondary'
					>
						Kembali
					</Button>
					{detailRfq.isLoading ? null : renderButtonCancel(dataRFQ.state, dataRFQ.parentState)}
				</Box>

				{/* Timeline */}
				{timelineData.isLoading ? (
					<Box style={{ textAlign: 'center' }} mt={2}>
						<CircularProgress size={20} />
					</Box>
				) : timelineData.isLoading == false &&
					detailRfq.isLoading == false &&
					timelineData.data &&
					timelineData.data.length > 0 &&
					dataRFQ.id ? (
					<>
						<Box mt={0}>
							<Typography variant='h6'>Timeline</Typography>
						</Box>
						<Box mt={'10px'}>
							<Paper elevation={2}>
								<Timeline data={timelineData.data} id={dataRFQ.id} />
							</Paper>
						</Box>
					</>
				) : null}
				{/* End Timeline */}

				{/* ================== Modal ================= */}
				<Modal
					icon='question'
					open={modalCancel}
					onClose={() => setModalCancel(false)}
				>
					<Typography variant='body1'>
						<strong>Apakah Anda yakin ingin membatalkan RFQ ?</strong>
					</Typography>
					<Typography variant='body1'>
						RFQ yang telah dibatalkan tidak dapat diproses kembali.
					</Typography>
					<Box mt={1}>
						<Dropdown
							onClick={() => setDropdownList(true)}
							value={cancel.cancelled_reason}
							onClickAway={() => setDropdownList(false)}
							visible={dropdownList}
							error={optionState}
							placeholder='Pilih Alasan Pembatalan'
						>
							{RfqcancelReason.map((item, index) => (
								<MenuItem onClick={() => selectCancelReason(item)} key={index}>
									{item.label}
								</MenuItem>
							))}
						</Dropdown>
						<Typography className={classes.errorText} variant='caption'>
							{optionState ? 'Silakan pilih alasan pembatalan' : null}
						</Typography>
						<Box mt={1.5}>
							<TextField
								multiline
								required
								error={errorCancelDetail}
								label='Tuliskan alasan anda secara rinci'
								onChange={handleChangeCancelReasonDetail}
								rows={6}
								variant='outlined'
								fullWidth
								disabled={
									RfqcancelReason[RfqcancelReason.length - 1].value !==
									cancel.cancelled_reason
								}
								InputProps={{
									classes: {
										root: classes.textFieldRoot,
									},
								}}
								inputProps={{
									maxLength: 255,
								}}
							/>
						</Box>
						<Box mt={2} display='flex' justifyContent='space-evenly'>
							<Button
								id='btn-cancel-rfq'
								className={`${classes.buttonDanger} ${classes.buttonWidth}`}
								onClick={() => handleCancel()}
							>
								Batalkan RFQ
							</Button>
							<Button
								variant='outlined'
								color='secondary'
								className={[
									classes.buttonWidth,
									classes.kembali,
									classes.btnWhite,
								].join(' ')}
								onClick={() => setModalCancel(false)}
							>
								Kembali
							</Button>
						</Box>
					</Box>
				</Modal>
				{/* ============= end of modal ======================= */}
			</Container>
			<ScrollToTop />
		</Layout>
	)
}

Component.propTypes = {
	navigate: PropTypes.func,
	rfqId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default React.memo(Component)
