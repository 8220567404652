import React from 'react'
import {
	MenuItem,
	FormControl,
	Input,
	FormControlLabel,
	Select,
	Typography,
	Checkbox,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

const CustomCheckbox = withStyles({
	root: {
		color: '#007BFF',
		'&$checked': {
			color: '#007BFF',
		},
		width: 20,
		height: 20,
		margin: 10,
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} size='small' />)

const renderValue = (values) => {
	if (values.length) {
		return (
			<Typography style={{ color: '#007BFF' }}>
				{values.length} Terpilih
			</Typography>
		)
	}
	return (
		<Typography variant='body2' style={{ color: '#a9acaf' }}>
			Pilih Kolom
		</Typography>
	)
}

const Multiple = ({
	colSelected,
	selectedAll,
	handleSelecAll,
	handleChange,
	indeterminate,
	columns,
	colMandatory,
}) => {
	const classes = styles()
	return (
		<FormControl className={classes.selectColumns}>
			<Select
				multiple
				value={colSelected}
				onChange={(event) => handleChange(event)}
				displayEmpty
				input={<Input />}
				renderValue={(selected) => renderValue(selected)}
				classes={{
					select: classes.muiSelect,
					icon: classes.icon,
				}}
				MenuProps={{
					classes: { paper: classes.paperSelect },
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					getContentAnchorEl: null,
				}}
			>
				<MenuItem
					disableGutters
					dense
					classes={{ root: classes.headerSelect, selected: classes.selected }}
				>
					<FormControlLabel
						style={{ marginLeft: 0, width: '100%', marginRight: 0 }}
						classes={{ label: classes.labelHeader }}
						control={
							<CustomCheckbox
								checked={selectedAll}
								onChange={() => handleSelecAll(selectedAll)}
								indeterminate={indeterminate}
							/>
						}
						label='Pilih Semua'
					/>
				</MenuItem>
				{columns && columns.length != 0
					? columns.map((col, i) => (
							<MenuItem
								dense
								disableGutters
								key={i}
								value={col}
								classes={{ root: classes.itemList, selected: classes.selected }}
								disabled={
									colMandatory && colMandatory.length != 0
										? colMandatory.findIndex((element) => element.value == col.value) !=
										  -1
											? true
											: false
										: false
								}
							>
								<CustomCheckbox
									checked={colSelected.some((item) => item?.value == col.value)}
								/>
								<Typography variant='body2'>{col.label}</Typography>
							</MenuItem>
					  ))
					: null}
			</Select>
		</FormControl>
	)
}

Multiple.propTypes = {
	value: PropTypes.string.isRequired,
	children: PropTypes.arrayOf(PropTypes.element),
	colSelected: PropTypes.array.isRequired,
	handleChange: PropTypes.func.isRequired,
	selectedAll: PropTypes.bool,
	handleSelecAll: PropTypes.func,
	columns: PropTypes.array,
	indeterminate: PropTypes.bool,
	colMandatory: PropTypes.array,
}

Multiple.defaultProps = {
	selectedAll: false,
	colSelected: [],
	colMandatory: [],
}
export default Multiple
