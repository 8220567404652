/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import PropTypes from 'prop-types'
import {
	Box,
	Typography,
	Grid,
	Paper,
	TextField,
	IconButton,
	Button,
	Container,
} from '@material-ui/core'
import { SearchRounded, Delete, People } from '@material-ui/icons'
import { navigate } from 'gatsby'
import Breadcrumbs from 'Components/breadcrumbs'
import { ApprovalStyles } from './styles'
import TableData from 'Components/tableData'
import { requestAEList } from 'States/actions/Approval/Eproc/AEListAction'
import { requestAEDelete } from 'States/actions/Approval/Eproc/AEDeleteAction'
import { requestDetailCompany } from 'States/actions/CompanyProfile/profileAction'
import * as qs from 'query-string'
import { newUri } from '../../../../utils/globalUtils'
import CustomModal from 'Components/modal'

// const Departemen = [{ title: 'Tech' }]

const ApprovalEproc = (props) => {
	const classes = ApprovalStyles()

	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Manajemen Persetujuan',
			link: '#',
		},
		{
			segment: 'e-Procurement',
			link: '#',
		},
	])

	const dispatch = useDispatch()
	const list = useSelector((state) => state.AEListReducer)
	const dataDelete = useSelector((state) => state.AEDeleteReducer)
	const companyDetailReducer = useSelector((state) => state.companyProfile)
	const queryString = qs.parse(props.location.search)

	const [limit, setLimit] = useState(queryString.limit ? queryString.limit : 10)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	// const [totalRows, setTotalRows] = useState(0)
	const [totalEproc, setTotalEproc] = useState(0)
	const [keyword, setKeyword] = useState(
		queryString.keyword ? queryString.keyword : ''
	)
	const state = ''
	const [selectedDataDelete, setSelectedDataDelete] = useState(0)
	const [confirmModalSubmit, setConfirmModalSubmit] = useState(false)
	const [modalNotif, setModalNotif] = useState(false)
	const [statusApproval, setStatusApproval] = useState(0)

	// THIS FOR DATATABLE
	const [dataRow, setDataRow] = useState([])
	queryString.limit = limit
	queryString.page = page
	queryString.keyword = keyword
	queryString.state = state

	useEffect(() => {
		dispatch(requestAEList({ queryString }))
		dispatch(requestDetailCompany())
	}, [])

	// company cek status
	useEffect(() => {
		if (companyDetailReducer.company && companyDetailReducer.company.data) {
			setStatusApproval(companyDetailReducer.company.data.is_pr_active)
		}
	}, [companyDetailReducer])

	// hooks for set default state
	useEffect(() => {
		// success
		if (list.data && list.data.items) {
			let result = list.data.items
			setDataRow(result)
			setTotalEproc(list.data.pagination.total_records)
			// setTotalRows(list.data.pagination.total_records)
		} else {
			setDataRow([])
			// setTotalRows(0)
		}
	}, [list])

	useEffect(() => {
		if (dataDelete.data != null && typeof dataDelete.data == 'string') {
			setModalNotif(true)
		}
	}, [dataDelete])

	// reset
	useEffect(() => {
		dispatch(requestAEDelete('', 'RESET'))
	}, [])

	const columns = [
		{
			name: 'Nama Lengkap',
			selector: 'name',
			sortable: true,
			hide: 'md',
			grow: 2,
		},
		{
			name: 'Username',
			selector: 'username',
			sortable: true,
			grow: 2,
		},
		{
			name: 'Email',
			selector: 'email',
			sortable: true,
			grow: 2,
		},
		{
			name: 'Level',
			selector: 'level',
			sortable: true,
			grow: 2,
		},
		{
			name: 'AKSI',
			selector: 'aksi',
			sortable: true,
			hide: 'md',
			grow: 1,
			cell: (row) => (
				<Box>
					<Button
						variant='contained'
						size='small'
						disabled={dataDelete.isLoading && selectedDataDelete.id == row.id}
						className={`${classes.buttonAction} ${classes.btnDelete}`}
						onClick={() => {
							setSelectedDataDelete(row)
							setConfirmModalSubmit(true)
						}}
					>
						<Delete fontSize='small' />
					</Button>
				</Box>
			),
		},
	]

	const limitValue = [10, 25, 50, 100]

	const propsNavigate = (query) => {
		props.navigate(`/dashboard/approval/eproc?${query}`)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		let query = newUri(queryString)

		dispatch(requestAEList({ queryString }))
		propsNavigate(query)
	}

	const handlePerRowsChange = (event) => {
		setLimit(event.target.value)
		queryString.limit = event.target.value
		let query = newUri(queryString)

		dispatch(requestAEList({ queryString }))
		propsNavigate(query)
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false

			return
		}

		const timeOutId = setTimeout(() => submitSearch(), 1000)

		return () => clearTimeout(timeOutId)
	}, [keyword])

	const handleChangeSearch = (e) => {
		let value = e.target.value
		setKeyword(value)
		setPage(1)
		queryString.page = 1
		queryString.keyword = value
	}

	function submitSearch() {
		queryString.keyword = keyword
		queryString.state = state
		let query = newUri(queryString)

		dispatch(requestAEList({ queryString }))
		propsNavigate(query)
	}

	const handleEnterSearch = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	const handleDelete = () => {
		dispatch(requestAEDelete(selectedDataDelete.id))
		setConfirmModalSubmit(false)
	}

	const handleRefreshData = () => {
		setModalNotif(false)
		dispatch(requestAEList({ queryString }))
	}

	return (
		<>
			<DashboardLayout>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrums} />
					</Box>
					<Box my={2}>
						<Typography variant='h6'>
							Manajemen Persetujuan - Penyetuju e-Procurement
						</Typography>
					</Box>
					<Box mt={1}>
						<Paper variant='outlined'>
							<Box p={1}>
								<Grid container alignItems='center'>
									<Grid item lg={1}>
										<Grid container alignItems='center'>
											<People style={{ fontSize: 70 }} />
										</Grid>
									</Grid>
									<Grid item lg={6}>
										<Typography variant='body1'>
											Manajement persetujuan Anda berstatus{' '}
											<span style={{ color: statusApproval == 1 ? 'green' : 'red' }}>
												{statusApproval == 1 ? 'Aktif' : 'Tidak Aktif'}
											</span>
										</Typography>
										<Typography variant='body1'>
											Klik{' '}
											<a
												href='#'
												onClick={(event) => {
													event.preventDefault()
													navigate('/dashboard/approval/configure', { replace: true })
												}}
											>
												di sini
											</a>{' '}
											untuk pengaturan manajemen persetujuan
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Box>
					<Box mt={3}>
						<Grid container justify='flex-end'>
							<Button
								variant='contained'
								color='secondary'
								size='small'
								onClick={() => {
									// event.preventDefault()
									navigate('/dashboard/approval/eproc/add')
								}}
							>
								Tambah Penyetuju e-Procurement
							</Button>
						</Grid>
					</Box>
					<Box mt={2}>
						<Grid container justify='space-between'>
							{/* <Grid container item xs={4} justify='flex-start'>
							<Grid container item xs={12} alignItems='center'>
								<Autocomplete
									id='combo-box-demo'
									options={Departemen}
									getOptionLabel={option => option.title}
									style={{ width: 300 }}
									renderInput={params => (
										<TextField
											{...params}
											margin='dense'
											variant='outlined'
											placeholder='Semua Departemen'
											style={{ marginTop: 0, marginBottom: 0 }}
										/>
									)}
								/>
							</Grid>
						</Grid> */}
							<Grid container item xs={8} justify='flex-start'>
								<Grid container item xs={8} alignItems='center'>
									<TextField
										placeholder='Nama Lengkap / Email / Username'
										type='search'
										className={classes.searchField}
										InputProps={{
											disableUnderline: false,
										}}
										onChange={handleChangeSearch}
										onKeyDown={(e) => handleEnterSearch(e)}
										onBlur={(e) => handleChangeSearch(e)}
										value={keyword || ''}
									/>
									<IconButton
										variant='contained'
										color='primary'
										className={classes.buttonSearch}
										onClick={submitSearch}
									>
										<SearchRounded fontSize='small' />
									</IconButton>
								</Grid>
							</Grid>

							{/* comment out export to excel button */}
							{/* <Grid container item xs={2} justify='flex-end'>
								<Button
									variant='outlined'
									className={classes.buttonExport}
									size='small'
								>
									Expor Ke Excel <InsertDriveFileOutlined fontSize='small' />
								</Button>
							</Grid> */}
						</Grid>
					</Box>
					<Box mt={2}>
						<TableData
							limitValue={limitValue}
							perPage={limit}
							limitChange={handlePerRowsChange}
							columns={columns}
							isLoading={list.isLoading}
							pagination={list.data ? list.data.pagination : {}}
							data={dataRow}
							name={'Approval Kategori'}
							paginationPageChange={handlePageChange}
							pageNow={parseInt(queryString.page)}
						/>
						<br />
					</Box>
				</Container>
			</DashboardLayout>
			<CustomModal
				icon='question'
				open={confirmModalSubmit}
				onClose={() => setConfirmModalSubmit(false)}
			>
				<Typography variant='h6'>
					{statusApproval != 1 || (statusApproval == 1 && totalEproc > 1) ? (
						<strong>Hapus Penyetuju {selectedDataDelete.name} ?</strong>
					) : (
						<strong>
							Penyetuju tidak bisa dihapus, karena Manajement persetujuan Anda
							berstatus aktif
						</strong>
					)}
				</Typography>
				<Box mt={2}>
					<Button
						className={classes.buttonWidth}
						size='small'
						classes={{
							contained: classes.buttonDanger,
						}}
						color='secondary'
						variant='outlined'
						onClick={() => setConfirmModalSubmit(false)}
					>
						{' '}
						Batal{' '}
					</Button>
					&nbsp;&nbsp;&nbsp;
					{(statusApproval != 1 || (statusApproval == 1 && totalEproc > 1)) && (
						<Button
							className={[classes.buttonWidth, classes.buttonRed]}
							size='small'
							variant='contained'
							onClick={() => handleDelete()}
						>
							{' '}
							Ya, Hapus{' '}
						</Button>
					)}
				</Box>
			</CustomModal>
			<CustomModal
				icon={dataDelete.data && !dataDelete.isError ? 'success' : 'error'}
				open={modalNotif}
				onClose={() => setModalNotif(false)}
			>
				<Typography variant='h6'>
					{dataDelete.data && !dataDelete.isError ? (
						<strong>Penyetuju Berhasil dihapus.</strong>
					) : (
						<strong>Penyetuju Gagal dihapus.</strong>
					)}
				</Typography>
				<Box
					display='flex'
					flexDirection='row'
					mt={3}
					justifyContent='center'
					width={300}
				>
					<Button
						className={classes.buttonWidth}
						size='small'
						classes={{
							contained: classes.buttonDanger,
						}}
						variant='contained'
						onClick={() => handleRefreshData()}
					>
						{' '}
						OK{' '}
					</Button>
				</Box>
			</CustomModal>
		</>
	)
}

ApprovalEproc.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

ApprovalEproc.displayName = 'ApprovalEproc'

export default ApprovalEproc
