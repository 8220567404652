import { makeStyles } from '@material-ui/core/styles'

export const cartStyles = makeStyles((theme) => ({
	cardWhite: {
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 2px 1px -1px rgba(0,0,0,0.02),0px 1px 1px 0px rgba(0,0,0,0.02),0px 1px 3px 0px rgba(0,0,0,0.02)',
		padding: theme.spacing(2),
		maxWidth: '100%',
		overflowX: 'scroll',
	},
	headIconButton: {
		marginRight: '15px',
	},
	tableHead: {
		border: theme.palette.border.complete,
		borderBottom: 'none',
	},
	paddingTableHead: {
		padding: theme.spacing(1, 2),
	},
	tableBody: {
		borderRight: theme.palette.border.complete,
		borderLeft: theme.palette.border.complete,
	},
	tableBodyCell: {
		borderBottom: 'none',
		padding: theme.spacing(1, 2),
	},
	tableBodyCellRelative: {
		borderBottom: 'none',
		padding: theme.spacing(1, 2),
		position: 'relative',
	},
	tableBodyCellImage: {
		borderBottom: 'none',
		padding: theme.spacing(0),
		textAlign: 'right',
	},
	tableBodyCellRight: {
		borderBottom: 'none',
		padding: theme.spacing(1, 2),
		borderRight: theme.palette.border.complete,
	},
	tableFooter: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.text.secondary,
	},
	checkboxRoot: {
		color: theme.palette.secondary.main,
	},
	noPaddingTop: {
		paddingTop: theme.spacing(0),
	},
	ImageStyle: {
		maxWidth: '45px',
		maxHeight: '45px',
		width: 'auto',
		height: 'auto',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	paddingTop: {
		paddingTop: theme.spacing(2),
	},
	paddingBottom1: {
		paddingBottom: theme.spacing(1),
	},
	paddingBottom2: {
		paddingBottom: theme.spacing(2),
	},
	textCenter: {
		textAlign: 'center',
	},
	bgRed: {
		backgroundColor: theme.palette.alert.main,
	},
	bottomFloat: {
		position: 'absolute',
		bottom: 0,
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		left: '1rem',
		right: 0,
	},
	floatingIcon: {
		position: 'absolute',
		left: '4px',
		top: '50%',
		transform: 'translateY(-50%)',
		fontSize: '1rem',
	},
	typoSetting: {
		textTransform: 'none',
		paddingLeft: '1.2rem',
	},
	buttonRed: {
		color: theme.palette.text.secondary,
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
}))
