import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
	danger: {
		backgroundColor: '#F8D7DA',
		border: '1px solid #EFC8C8!important',
		'& .text-danger': {
			color: '#DC3545',
		},
	},
	dividerDanger: {
		backgroundColor: '#EFC8C8',
	},
	warning: {
		backgroundColor: '#FFF3CD',
		border: '1px solid #FAE39D!important',
	},
	dividerWarning: {
		backgroundColor: '#FAE39D',
	},
	title: {
		fontSize: '14px',
	},
	message: {
		fontSize: '14px',
		'& a': {
			color: '#007BFF',
			textDecoration: 'none',
		},
	},
	default: {
		border: '1px solid #E6E6E6',
	},
	actorTitle: {
		fontSize: '14px',
		fontWeight: '600',
	},
	actor: {
		marginRight: '5px',
		color: '#6C757E',
	},
	container: {
		paddingBottom: '7px',
	},
	stepperWrapper: {
		position: 'relative',
		// maxHeight: 540,
		overflow: 'hidden',
	},
	collapseWrapper: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		paddingBottom: 6,
	},
	tableRoot: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
		marginTop: '20px',
	},
	viewMore: {
		backgroundColor: 'white',
		height: 30,
		color: '#007BFF',
		'& span': {
			cursor: 'pointer',
		},
	},
}))

export default styles
