import React, { useState } from 'react'
import LayoutComponent from 'Components/layout'
import {
	Container,
	Box,
	Grid,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputBase,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Breadcrumbs from 'Components/breadcrumbs'
import { TermAndConditionsStyle } from './styles'
import { navigate } from 'gatsby'

const TermAndConditions = () => {
	const classes = TermAndConditionsStyle()

	const CustomInput = withStyles((theme) => ({
		root: {
			'label + &': {
				marginTop: theme.spacing(3),
			},
		},
		input: {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'&#34;Segoe UI&#34;',
				'Roboto',
				'&#34;Helvetica Neue&#34;',
				'Arial',
				'sans-serif',
				'&#34;Apple Color Emoji&#34;',
				'&#34;Segoe UI Emoji&#34;',
				'&#34;Segoe UI Symbol&#34;',
			].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
			},
		},
	}))(InputBase)

	const lang = {
		id: '/pages/id/term-and-condition',
		en: '/pages/en/term-and-condition',
	}
	const activeLink = window.location.pathname

	const handleChange = (event) => {
		navigate(event.target.value)
	}

	// const handleNavigate = path => {
	// 	navigate(path)
	// }

	const [breadcrumbs] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Syarat dan Ketentuan',
			link: '#',
		},
	])

	return (
		<>
			<LayoutComponent>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrumbs} />
					</Box>
					<Box>
						<Grid container justify='space-between'>
							<Grid item xs={6}>
								<Typography variant='h6'>Syarat dan Ketentuan</Typography>
							</Grid>
							<Grid container item justify='flex-end' alignItems='center' xs={3}>
								<FormControl>
									<Select
										id='lang-product-return'
										value={activeLink}
										onChange={handleChange}
										input={<CustomInput fullWidth variant='outlined' color='primary' />}
									>
										<MenuItem value={lang['id']}>Bahasa Indonesia</MenuItem>
										<MenuItem value={lang['en']}>Bahasa Inggris</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box mt={2}>
						<Box mb={1} textAlign='justify'>
							<Typography variant='body1'>
								Selamat datang di Mbiz.co.id. Terima kasih telah mengunjungi situs Kami.
								Halaman ini menampilkan Syarat dan Ketentuan untuk penggunaan situs{' '}
								<a href='https://www.mbiz.co.id' target='blank'>
									www.mbiz.co.id
								</a>
								(“
								<strong>Situs”</strong>). Mohon luangkan waktu Anda untuk membaca Syarat
								dan Ketentuan ini sebelum Anda mengakses Situs Kami lebih lanjut.
							</Typography>
						</Box>
						<Box mb={2} textAlign='justify'>
							<Typography variant='body1'>
								Syarat dan Ketentuan ini dan Ketentuan Lain berlaku dan mengikat seluruh
								pengguna Situs ini, baik Pelanggan maupun Kami. Dengan memiliki akun
								pada Situs Kami, serta mengakses dan menggunakan Situs Kami, Anda
								berarti telah setuju untuk dan terikat serta tunduk secara hukum pada
								Syarat dan Ketentuan ini. Setiap pembaharuan yang Kami buat dalam Syarat
								dan Ketentuan ini akan Kami tampilkan pada Situs. Anda diwajibkan untuk
								melihat, membaca, dan mempelajari pembaharuan tersebut. Untuk kenyamanan
								Anda, Kami menyarankan Anda untuk mengunjungi halaman ini dan membaca
								Syarat dan Ketentuan ini dari waktu ke waktu setiap Anda memasuki Situs
								Kami. Setiap penggunaan Situs yang dilakukan setelah pembaharuan
								tersebut ditampilkan secara online, akan dianggap sebagai penerimaan dan
								penundukan Anda pada Syarat dan Ketentuan yang berlaku pada saat
								tersebut.
							</Typography>
						</Box>
						<Box mt={2}>
							<ol className={classes.contentList}>
								<li>
									<b>TENTANG KAMI</b>
									<br />
									<a
										href='https://www.mbiz.co.id'
										target='blank'
										style={{ color: '#1A9AED' }}
									>
										www.mbiz.co.id
									</a>
									, merupakan sebuah situs yang dibuat, dimiliki, dan dikelola oleh PT
									Brilliant Ecommerce Berjaya <b>(&#34;BEB&#34;)</b>.
									<ol>
										<li>
											BEB merupakan perusahaan afiliasi Lippo Group yang bergerak di bidang
											perdagangan secara elektronik dengan bentuk B2B{' '}
											<i>(Business-to-Business)</i>, yang menyediakan, memasarkan, dan
											mempromosikan barang dan jasa secara online melalui Situs ini.
											Pendirian BEB telah mendapatkan pengesahan dari Menteri Hukum dan Hak
											Asasi Manusia berdasarkan Surat Keputusan Menteri Hukum dan Hak Asasi
											Manusia Nomor AHU-2449494.AH.01.01.Tahun 2015 tertanggal 31 Juli
											2015. BEB beralamat terdaftar di Gedung Lippo Kuningan Lantai 21,
											Jalan H.R. Rasuna Said Blok B-12, Setiabudi, Jakarta Selatan 12940.
										</li>
										<li>
											Syarat dan Ketentuan ini merupakan hak kekayaan intelektual milik
											Kami yang dilindungi hak cipta. Setiap penggunaan Syarat dan
											Ketentuan ini oleh pihak manapun selain Kami, termasuk tetapi tidak
											terbatas pada pembuatan ekstrak atau salinan dari Syarat dan
											Ketentuan maupun penggunaan untuk tujuan lainnya, termasuk antara
											lain untuk tujuan komersial dalam menawarkan barang tanpa
											sepengetahuan dan otorisasi Kami merupakan penggunaan yang dilarang.
											Pelanggaran atas hak atas kekayaan intelektual Kami ini dapat
											dikenakan tindakan atau sanksi berdasarkan ketentuan hukum yang
											berlaku.
										</li>
										<li>
											Kami didukung penuh oleh sejumlah penjual yang mempunyai kapabilitas
											serta mempunyai izin usaha untuk melaksanaan pekerjaan yang dipesan
											oleh Pelanggan melalui Situs.
										</li>
										<li>
											Kami memiliki akses untuk melakukan pembaharuan atas informasi apapun
											terkait produk dan jasa pada Situs. Oleh karenanya, Kami menyarankan
											Anda untuk melakukan pemeriksaan dan meneliti kembali atas informasi
											produk dalam Situs sebelum Anda melakukan transaksi.
										</li>
									</ol>
								</li>
								<li>
									<b>DEFINISI</b>
									<ol>
										<li>
											{' '}
											Dalam Syarat dan Ketentuan ini:
											<br />
											<div>
												&#34;<b>Akun</b>&#34; berarti akun Anda yang terdaftar pada Situs,
												yang Anda gunakan untuk melakukan transaksi di Situs;
											</div>
											<div>
												&#34;<b>Anda</b>&#34; berarti Pengguna Situs, baik yang sudah
												memiliki Akun atau belum memiliki Akun;
											</div>
											<div>
												&#34;<b>Harga Produk</b>&#34; berarti harga jual atas suatu Produk
												sebagaimana yang tertera dalam Situs;
											</div>
											<div>
												&#34;<b>Hari Kerja</b>&#34; berarti hari dalam 1 (satu) minggu
												kalender dimana orang bekerja, di luar hari Sabtu, Minggu dan hari
												libur nasional;
											</div>
											<div>
												&#34;<b>Informasi Produk</b>&#34; berarti gambar, informasi, uraian,
												deskripsi, dan/atau data dalam bentuk apapun mengenai Produk secara
												tepat dan akurat termasuk tetapi tidak terbatas pada daftar harga,
												jumlah, persediaan, penggantian, pemberhentian, garansi, jenis
												dan/atau tipe Produk;
											</div>
											<div>
												&#34;<b>Jumlah Akhir</b>&#34; berarti total jumlah harga yang
												tercatat pada Konfirmasi Pesanan dan akan dibayar oleh Pelanggan,
												jumlah tersebut termasuk di dalamnya Harga Produk, biaya pengiriman
												dan biaya lain-lain termasuk pajak yang berlaku yang mungkin akan
												dibebankan oleh Kami;
											</div>
											<div>
												&#34;<b>Kami</b>&#34; berarti BEB atau Perusahaan;
											</div>
											<div>
												&#34;<b>Ketentuan Lain</b>&#34; berarti ketentuan Kebijakan
												Pembatalan, Penukaran dan Pengembalian Produk dan Kebijakan Privasi,
												yang merupakan satu kesatuan dan bagian yang tidak terpisahkan dari
												Syarat dan Ketentuan ini;
											</div>
											<div>
												&#34;<b>Konfirmasi Pesanan</b>&#34; berarti pemberitahuan kepada
												Pelanggan dimana BEB mengakui dan menyetujui Purchase Order (PO)
												antara Pelanggan dan BEB;
											</div>
											<div>
												&#34;<b>Layanan</b>&#34; berarti informasi, layanan dan/atau fungsi
												yang disediakan di dalam Situs;
											</div>
											<div>
												&#34;<b>Pelanggan</b>&#34; berarti setiap badan usaha atau entitas
												yang memiliki Akun dan melakukan pembelian Produk pada Situs;
											</div>
											<div>
												&#34;<b>Pembayaran Dimuka</b>&#34; berarti salah satu pilihan cara
												pembayaran, dimana Pelanggan diwajibkan untuk melakukan pembayaran
												sesuai dengan Harga Produk dalam waktu 3 x 24 jam terhitung sejak
												diterima PO antara Pelanggan dan BEB;
											</div>
											<div>
												&#34;<b>PO antara Pelanggan dan BEB</b>&#34; berarti dokumen atau
												formulir pemesanan barang yang diajukan oleh Pelanggan kepada BEB;
											</div>
											<div>
												&#34;<b>Produk</b>&#34; berarti barang atau jasa yang dipasarkan,
												dipromosikan dan dijual melalui Situs;
											</div>
											<div>
												&#34;<b>Produk Pesanan</b>&#34; berarti Produk yang dipesan oleh
												Pelanggan;
											</div>
											<div>
												&#34;<b>Produk Retur</b>&#34; berarti Produk Pesanan yang rusak,
												cacat, hancur, pecah, kedaluwarsa, kurang, tidak lengkap, tidak
												berfungsi dan/atau tidak sesuai dengan pemesanan sebagaimana
												tercantum dalam PO antara Pelanggan dan BEB, sebagaimana
												dideskripsikan dan ditetapkan lebih lanjut dalam{' '}
												<a
													href={`${window.location.origin}/pages/id/complaint`}
													className='cBlue'
												>
													Kebijakan Komplain
												</a>
												;
											</div>
											<div>
												&#34;<b>Sistem</b>&#34; berarti (a) sistem, platform, software,
												program, atau jaringan internet baik secara terpisah maupun secara
												bersama-sama dan satu kesatuan yang dikelola dan digunakan pada
												Situs untuk menunjang pengoperasian Situs maupun pelaksanaan
												kegiatan pemasaran, penjualan, pengiriman, dan/atau pembayaran
												Produk melalui Situs serta penyediaan layanan lainnya sebagaimana
												tersedia pada Situs, yang menghubungkan Kami dan Pelanggan, dan (b)
												sistem, prosedur, dan mekanisme manual yang disepakati bersama
												secara tertulis oleh Para Pihak dalam hal sistem, platform,
												software, program, atau jaringan internet sebagaimana dimaksud dalam
												huruf (a) di atas belum atau tidak beroperasi karena kondisi atau
												sebab tertentu (termasuk namun tidak terbatas pada dalam hal terjadi
												kerusakan atau sedang dilakukannya maintenance atas sistem,
												platform, software, program, atau jaringan internet sebagaimana
												dimaksud dalam huruf (a) di atas);
											</div>
											<div>
												&#34;<b>Tempat Pengiriman</b>&#34; berarti tempat pengiriman Produk
												Pesanan sebagaimana dicantumkan oleh Pelanggan dalam PO antara
												Pelanggan dan BEB.
											</div>
											<div>
												&#34;<b>Term of Payment</b>&#34; berarti salah satu pilihan tata
												cara pembayaran yang akan dilakukan oleh Pelanggan setelah Kami
												mengirimkan Produk Pesanan kepada Pelanggan.
											</div>
											<div>
												&#34;<b>Transaksi</b>&#34; berarti kegiatan jual beli antara
												Pelanggan dan Kami melalui Situs.
											</div>
										</li>
										<li>
											Referensi untuk &quot;pasal&quot; atau &quot;klausul&quot; adalah
											pasal atau klausa dalam Syarat dan Ketentuan ini.
										</li>
										<li>
											Judul maupun sub judul dalam Syarat dan Ketentuan ini adalah untuk
											kemudahan referensi saja dan tidak akan mempengaruhi interpretasi
											atau konstruksi dari Syarat dan Ketentuan ini. Kata-kata yang
											menyampaikan bentuk tunggal dianggap juga mencakup bentuk jamak dan
											sebaliknya. Kata-kata menyampaikan gender meliputi setiap jenis
											kelamin dan referensi untuk orang maupun pihak meliputi individu,
											perusahaan, korporasi, perusahaan, atau kemitraan. Referensi untuk
											&quot;termasuk&quot; atau kata-kata serupa atau ungkapan, berarti
											tanpa batasan.
										</li>
									</ol>
								</li>
								<li>
									<b>PERSYARATAN PENDAFTARAN </b>
									<br /> Untuk melakukan pendaftaran dan memiliki Akun pada Situs, Anda
									diwajibkan untuk memenuhi persyaratan pendaftaran kumulatif sebagai
									berikut:
									<ol>
										<li>
											berbentuk badan usaha yang didirikan secara sah berdasarkan hukum
											negara Republik Indonesia dan berstatus sebagai Pengusaha Kena Pajak
											(PKP);{' '}
										</li>
										<li>
											membuka Akun melalui perwakilan Anda baik berdasarkan anggaran dasar
											atau berdasarkan suatu surat kuasa sebagai pihak yang sah dan
											berwenang untuk menandatangani segala dokumen apapun, baik elektronik
											maupun non – elektronik, yang diterbitkan untuk keperluan Transaksi
											di Situs Kami, termasuk untuk memberikan informasi atau instruksi
											yang relevan terkait Transaksi;
										</li>
										<li> mengelola Akun Anda secara mandiri;</li>
										<li>
											menyerahkan kepada Kami pada saat pendaftaran dokumen – dokumen
											sebagai berikut:
											<ol>
												<li>
													akta pendirian dan seluruh perubahan anggaran dasar berikut
													persetujuan atau penerimaan pemberitahuannya dari Kementerian Hukum
													dan Hak Asasi Manusia Republik Indonesia;
												</li>
												<li> Nomor Pokok Wajib Pajak (NPWP);</li>
												<li> Surat Pengukuhan Pengusaha Kena Pajak;</li>
												<li> Surat Izin Usaha Perdagangan atau Izin Usaha;</li>
												<li> Nomor Induk Berusaha;</li>
												<li>
													{' '}
													perizinan dan dokumen lainnya yang wajib Anda miliki untuk
													menjalankan usahanya berdasarkan ketentuan hukum dan peraturan
													perundang-undangan.
												</li>
											</ol>
										</li>
										<li>
											mengisi dan menandatangani Formulir Kepatuhan yang akan Kami sediakan
											kepada Anda pada saat pendaftaran;
										</li>
										<li>
											melengkapi dan menandatangani Non Disclosure Agreement serta
											Kesepakatan Bersama yang akan Kami sediakan kepada Anda pada saat
											pendaftaran.
										</li>
									</ol>
								</li>
								<li>
									<b>LARANGAN</b>
									<br />
									Dalam menggunakan Situs ini, Anda, baik disengaja maupun tidak
									disengaja, dilarang untuk:
									<ol>
										<li>
											{' '}
											menyalahgunakan Situs ini untuk tujuan apapun yang melawan hukum
										</li>
										<li>
											{' '}
											mengirimkan, menyebarkan dan/atau mendistribusikan virus ke dalam
											atau melalui Situs dalam bentuk apapun termasuk namun tidak terbatas
											pada <i>trojan horse</i>, <i>worm</i> dan <i>logic bomb</i>;
										</li>
										<li>
											mengirimkan atau memasukkan materi atau teknologi berbahaya dalam
											bentuk apapun kedalam atau melalui Situs;
										</li>
										<li>
											melakukan tindakan pelanggaran hak apapun atas pihak manapun termasuk
											hak atas kekayaan intelektual Kami atau pihak lainnya;
										</li>
										<li>
											melakukan tindakan berpura-pura atau mengaku sebagai orang atau
											entitas lain atau pihak tertentu, memberikan keterangan, identitas
											atau informasi yang salah, tidak benar atau palsu;
										</li>
										<li>
											dengan cara apapun melakukan tindakan, menuliskan atau menyebarkan
											hal-hal yang bersifat ofensif, melecehkan, melanggar kesusilaan dan
											kesopanan atau menyebabkan gangguan pada ketertiban umum;
										</li>
										<li>
											memasuki atau mendapatkan akses secara tidak sah dan/atau mengganggu
											atau mengacaukan sistem komputer atau jaringan yang menghubungkan
											atau terhubung dengan Layanan dan/atau Situs;
										</li>
										<li> merusak data pada Situs;</li>
										<li> mengganggu pengguna lain;</li>
										<li>
											mengirim iklan atau materi promosi yang tidak diminta ke dalam Situs;
										</li>
										<li>
											mencoba untuk mempengaruhi kinerja atau fungsi dari setiap fasilitas
											komputer atau akses terhadap seluruh Situs; dan/atau
										</li>
										<li>
											{' '}
											mengganggu, melakukan <i>interception</i> dan/atau tindakan-tindakan
											lain yang merugikan atau menyebabkan Situs dan/atau <i>server</i>{' '}
											Situs dan/atau <i>hosting</i> milik Situs menjadi tidak berjalan
											dengan baik.{' '}
										</li>
									</ol>{' '}
									Setiap pelanggaran atas Syarat dan Ketentuan ini merupakan pelanggaran
									atas ketentuan hukum dan dan/atau peraturan perundangan-undangan yang
									berlaku di Republik Indonesia. Dalam hal pelanggaran tersebut terjadi,
									Kami akan mengambil segala tindakan hukum sesuai dengan ketentuan hukum
									yang berlaku.
								</li>
								<li>
									<b>KESELURUHAN PERJANJIAN</b>
									<br />
									<ol>
										<li>
											{' '}
											Syarat dan Ketentuan dan Ketentuan Lain yang diberlakukan oleh Kami
											merupakan keseluruhan perjanjian antara Anda dan Kami terkait dengan
											penggunaan Situs. Setiap pengesampingan satu atau lebih Syarat dan
											Ketentuan dan/atau Ketentuan Lain hanya akan berlaku efektif jika
											dibuat secara tertulis, resmi dan ditandatangani oleh pihak yang
											berwenang untuk bertindak untuk dan atas nama Anda dan Kami.
										</li>
										<li>
											{' '}
											Transaksi yang terjadi antara Pelanggan dan BEB akan didasarkan pada
											kesepakatan para pihak dengan memperhatikan dan menaati Syarat dan
											Ketentuan dan Ketentuan Lain, kecuali dalam hal Kami mengadakan suatu
											perjanjian khusus yang terpisah dengan Pelanggan, hal yang mana
											merupakan satu kesatuan dan bagian yang tidak terpisahkan dari Syarat
											dan Ketentuan ini.
										</li>
									</ol>
								</li>
								<li>
									<b>PERSYARATAN TRANSAKSI ANTARA KAMI DENGAN PELANGGAN</b>
									<br />
									<ol>
										<li>
											{' '}
											<b>Pendaftaran</b>
											<ol>
												<li>
													Untuk dapat menggunakan Situs ini, Anda diwajibkan untuk memenuhi
													Persyaratan Pendaftaran sebagaimana yang ditentukan pada Pasal 3
													Syarat dan Ketentuan ini. Anda wajib memberikan data, informasi
													serta keterangan kepada Kami secara akurat, lengkap,benar dan tidak
													menyesatkan. Pengaturan mengenai data-data Anda yang sehubungan
													dengan data pribadi akan diatur dalam Kebijakan Privasi Kami.
												</li>
												<li>
													Anda wajib untuk melakukan pemberitahuan kepada Kami jika terdapat
													perubahan apapun pada informasi yang telah Anda berikan sebelumnya
													dengan menggunakan atau melalui bagian yang relevan dari Situs.
												</li>
											</ol>
										</li>
										<li>
											{' '}
											<b>Pemesanan</b>
											<ol>
												<li>
													Informasi yang ditetapkan dalam Syarat dan Ketentuan dan Ketentuan
													Lain serta rincian yang terdapat di Situs ini bukan merupakan suatu
													penawaran penjualan. Transaksi baru akan dianggap terjadi atau
													timbul pada saat:
													<ol>
														<li>
															PO antara Pelanggan dan BEB telah diajukan oleh Pelanggan kepada
															BEB;
														</li>
														<li>
															{' '}
															pilihan salah satu dari dua metode cara pembayaran yang
															disediakan, yaitu Pembayaran Dimuka atau Term of Payment telah
															ditetapkan dalam PO antara Pelanggan dan BEB;
														</li>
														<li>
															{' '}
															hal-hal tersebut di atas telah diakui dan disetujui oleh BEB
															sebagaimana disebutkan dalam Konfirmasi Pesanan.
														</li>
													</ol>
												</li>
												<li>
													<div>
														Untuk melakukan pemesanan, Pelanggan dapat memilih Produk yang
														diinginkan di Situs, kemudian melanjutkannya dengan membuat PO
														antara Pelanggan dan BEB pada Situs.Kami berhak dari waktu ke
														waktu menarik setiap Produk dari Situs ini dan/atau
														menghapus,mengganti, memperbaharui, menambah, mengurangi,
														menyesuaikan, mengedit dan/atau mengubah semua materi atau konten
														dalam Situs termasuk Informasi Produk.
													</div>
												</li>
												<li>
													<div>
														Pelanggan diberikan waktu untuk mengajukan PO antara Pelanggan dan
														BEB atas seluruh pemesanan yang dilakukan oleh Pelanggan. Dalam
														hal PO antara Pelanggan dan BEB tidak diterima dalam waktu yang
														ditentukan, maka pemesanan barang akan dibatalkan secara otomatis.
													</div>
												</li>
												<li>
													<div>
														Pelanggan tidak dapat mengajukan pembatalan PO antara Pelanggan
														dan BEB. Apabila terdapat pertanyaan mengenai pembatalan Pelanggan
														dapat menghubungi Kami melalui Customer Service di help@mbiz.co.id
														atau nomor telepon (021) 400 01 036.
													</div>
												</li>
											</ol>
										</li>
										<li>
											{' '}
											<b>Harga dan Pembayaran</b>
											<ol>
												<li>
													Seluruh Informasi Produk yang ditampilkan dalam Situs adalah
													informasi yang disediakan oleh Kami. Harga Produk yang mengikat
													Pelanggan adalah harga Produk yang ditampilkan di Situs pada saat
													Pelanggan mengajukan PO antara Pelanggan dan BEB.
												</li>
												<li>
													Harga Produk yang ditampilkan dalam Situs dan yang tercantum dalam
													Pemesanan sudah termasuk pajak-pajak yang wajib dibayar Pelanggan
													sesuai dengan ketentuan hukum dan peraturan perundang-undangan yang
													berlaku. Mohon diperhatikan bahwa Pelanggan akan dikenakan biaya
													pengiriman serta biaya tambahan sebagaimana berlaku di mana biaya
													tambahan tersebut tidak termasuk Harga Produk dan akan ditampilkan
													dengan jelas dan termasuk dalam &quot;Jumlah Akhir&quot; dari harga
													dan biaya yang harus Pelanggan bayar atas Pemesanan (&quot;
													<strong>Harga Pembayaran</strong>&quot;).
												</li>
												<li>
													Pelanggan dapat memilih salah satu dari metode pembayaran di bawah
													ini atas Produk Pesanan pada PO antara Pelanggan dan BEB.
													<ol>
														<li>
															<b>Pembayaran Dimuka</b>
															<br /> Pelanggan diwajibkan melakukan pembayaran sesuai dengan
															Harga Pembayaran dalam waktu 3 x 24 jam atau 72 jam terhitung
															sejak diterima PO antara Pelanggan dan BEB. Dalam hal Pelanggan
															tidak melakukan pembayaran dalam waktu yang ditentukan tersebut,
															maka PO antara Pelanggan dan BEB akan dianggap dibatalkan dan
															tidak pernah terjadi. Dalam waktu 2 x 24 jam pada Hari Kerja
															setelah diterima pembayaran atas Produk Pesanan di rekening Kami,
															Kami akan memberikan Konfirmasi Pesanan kepada Pelanggan.
														</li>
														<li>
															<b>
																<i>Term of Payment</i>
															</b>
															<br /> Metode ini dipilih dalam hal Pelanggan melakukan
															pembayaran kepada Kami setelah Produk Pesanan diterima oleh
															Pelanggan. Jangka waktu pembayaran tersebut akan dinyatakan pada
															PO antara Pelanggan dan BEB, dan Konfirmasi Pesanan. Khusus untuk{' '}
															<i>Term of Payment</i>, Kami atas dasar keputusan Kami sendiri,
															berhak untuk menolak PO antara Pelanggan dan BEB. Dalam hal
															Pelanggan memilih metode cara pembayaran Term of Payment,
															Pelanggan telah menyanggupi untuk membayar Penalti sebesar 3%
															(tiga persen) per bulan dari total tagihan dalam Konfirmasi
															Pesanan atas setiap keterlambatan dalam melakukan pembayaran
															secara tepat waktu pada waktu yang disepakati dalam Konfirmasi
															Pesanan, dengan berdasarkan pada perhitungan secara prorata.
														</li>
													</ol>
												</li>
												<li>
													Setiap pembayaran atas Produk Pesanan akan didasarkan pada salah
													satu dari dua metode cara pembayaran di atas. Dengan demikian,
													Pelanggan menyatakan dan menyanggupi bahwa Pelanggan memiliki
													ketersediaan dana yang cukup untuk melakukan pembayaran atas
													Pemesanan tersebut.
												</li>
												<li>
													Hak kepemilikan atas Produk Pesanan akan beralih dari Kami kepada
													Pelanggan pada saat Produk Pesanan telah diterima oleh Pelanggan
													dengan ketentuan, Pelanggan diwajibkan untuk membayar lunas Produk
													Pesanan kepada Kami. Dalam hal Pelanggan tidak melakukan pembayaran
													lunas atas Produk Pesanan tepat waktu, maka Pelanggan diwajibkan
													membayar penalti sesuai dengan ketentuan Pasal 6.3.3.2 di atas.
												</li>
												<li>
													Untuk setiap metode cara pembayaran sebagaimana disebutkan di atas,
													Pelanggan dapat memilih untuk melakukan pembayaran atas setiap
													pemesanan: (i) dengan menggunakan layanan yang disediakan oleh
													salah satu mitra Kami yang terdaftar di Situs; (ii) dengan
													menggunakan uang tunai dengan metode pembayaran
													pemindahbukuan/transfer yang berlaku; atau (iii) dengan melakukan
													pembayaran seluruh atau sebagian dari harga pemesanan Pelanggan
													dengan menggunakan voucher promosi yang dikeluarkan secara resmi
													oleh Kami sesuai dengan syarat dan ketentuan yang berlaku.
												</li>
											</ol>
										</li>
										<li>
											{' '}
											<b>User ID dan Password</b>
											<br /> User ID dan password Anda merupakan kode yang bersifat rahasia
											dan kewenangannya ada pada Anda. Segala penyalahgunaan password dan
											User ID merupakan tanggung jawab Anda sepenuhnya. Anda dengan ini
											membebaskan dan melepaskan Kami dari segala tuntutan, klaim, dan/atau
											gugatan dalam bentuk dan dengan cara apapun yang mungkin timbul atau
											diajukan, baik dari pihak ketiga manapun maupun dari Anda sendiri
											sebagai akibat penyalahgunaan User ID dan/atau password Anda, kecuali
											apabila penyalahgunaan tersebut diakibatkan karena kesalahan atau
											kelalaian Kami secara nyata.
										</li>
										<li>
											{' '}
											<b>Penolakan dan Pembatasan Pesanan</b>
											<br /> Kami akan melakukan upaya terbaik untuk memproses setiap PO
											antara Pelanggan dan BEB sepanjang pemesanan tersebut memenuhi
											seluruh Syarat dan Ketentuan dan Ketentuan Lain. Meski demikian,
											dalam kondisi tertentu Kami mungkin tidak dapat menerima atau
											memproses lebih lanjut pemesanan Pelanggan meskipun Kami telah
											mengirimkan Konfirmasi Pesanan sesuai dengan kebijakan Kami di mana
											hal tersebut akan Kami informasikan kepada Anda. Anda dengan ini
											menyatakan setuju untuk tunduk pada penolakan Pemesanan yang
											ditetapkan oleh Kami.
										</li>
										<li>
											{' '}
											<b>Pembatasan</b>
											<br /> Dari waktu ke waktu, Kami berhak untuk menerapkan batasan atas
											pemesanan Pelanggan dalam suatu Transaksi atau Transaksi kumulatif,
											baik dalam hari, rentang waktu, dan/atau periode tertentu. Pembatasan
											pemesanan tersebut akan didasarkan pada keputusan dan pertimbangan
											Kami. Pelanggan dengan ini menyatakan setuju untuk tunduk pada
											pembatasan pemesanan yang ditetapkan oleh Kami.
										</li>
										<li>
											{' '}
											<b>Pembatalan Pemesanan</b>
											<br /> Ketentuan-ketentuan mengenai pembatalan Pemesanan akan diatur
											dalam{' '}
											<a
												href={`${window.origin}/pages/id/complaint`}
												target='blank'
												className='cBlue'
											>
												Kebijakan komplain
											</a>{' '}
											yang merupakan bagian yang tidak terpisahkan dari Syarat dan
											Ketentuan ini.
										</li>
									</ol>
								</li>
								<li>
									<b>PENGIRIMAN</b>
									<br />
									<ol>
										<li>
											{' '}
											Kami akan mengirimkan setiap Produk Pesanan Pelanggan setelah Kami
											mengeluarkan Konfirmasi Pesanan.
										</li>
										<li>
											{' '}
											Kami akan mengirimkan Produk Pesanan kepada Pelanggan ke Tempat
											Pengiriman sebagaimana tercantum dalam PO antara Pelanggan dan BEB.
											Untuk sementara, estimasi waktu pengiriman dapat dilihat pada saat
											Konfirmasi Pesanan dikeluarkan. Mohon diperhatikan bahwa apabila
											pemesanan Pelanggan meliputi pemesanan atas lebih dari satu kategori
											Produk, Produk Pesanan tersebut dapat dikirimkan kepada Pelanggan
											dalam paket terpisah dan pada waktu terpisah.
										</li>
										<li>
											{' '}
											Biaya pengiriman Produk Pesanan ditanggung oleh Pelanggan sesuai
											dengan perhitungan yang diberikan pada saat pemesanan. Perhitungan
											biaya pengiriman akan disesuaikan dengan hal-hal berikut ini:
											<ol>
												<li>
													<b>Jenis:</b> beberapa Produk tertentu akan dikirimkan melalui jasa
													transportasi yang sesuai dengan standar peraturan pengiriman barang
													yang berlaku.
												</li>
												<li>
													<b>Berat/dimensi:</b> berat yang dikenakan di jasa pengiriman akan
													mengambil angka tertinggi antara berat sebenarnya atau dimensi
													(setelah dikonversi mejadi satuan berat sesuai perhitungan standar
													yang berlaku).
												</li>
												<li>
													<b>Asuransi:</b> Produk yang dipesan melalui Situs akan secara
													otomatis diasuransikan demi memastikan bahwa keselamatan pengiriman
													semua ditanggung.
												</li>
												<li>
													<b>Wilayah pengiriman:</b> harga akan berlaku sesuai dengan asal
													dimana Produk dikirimkan dan tujuan dimana Produk akan diterima.
												</li>
												<li>
													<b>Jenis layanan pengiriman yang dipilih:</b> harga serta lama
													pengiriman akan disesuaikan dengan jasa yang dipilih oleh
													Pelanggan.
												</li>
											</ol>
										</li>
										<li>
											{' '}
											Kami akan mengusahakan pengiriman Produk Pesanan tiba sesuai dengan
											estimasi waktu yang diberitahukan oleh Penjual, namun Pelanggan
											dengan ini memahami dan menyetujui bahwa estimasi waktu pengiriman
											Produk Pesanan tersebut dapat berubah dalam situasi tertentu, jenis
											produk yang dipesan, daerah pengiriman serta keadaan{' '}
											<i>force majeure</i> yang tidak diperkirakan sebelumnya dan/atau
											penjadwalan dari mitra logistik Kami. Anda dengan ini mengesampingkan
											tanggung jawab Kami kepada Pelanggan atas kerugian, kewajiban, biaya
											kerusakan, atau biaya lainnya yang timbul dari keterlambatan
											pengiriman Produk Pesanan, sepanjang keterlambatan tersebut tidak
											diakibatkan karena kesalahan atau kelalaian Kami secara nyata.
										</li>
										<li>
											{' '}
											Setiap menerima pengiriman atas Produk Pesanan, Pelanggan
											berkewajiban untuk menandatangani bukti pengiriman dan penerimaan
											Produk Pesanan (&#34;<b>Delivery Note</b>&#34;). Kami mengharapkan
											Anda untuk benar-benar memeriksa Produk Pesanan yang dikirimkan
											kepada Anda sebelum menandatangani Delivery Note tersebut.
										</li>
										<li>
											{' '}
											Dalam kondisi tertentu, Kami mungkin tidak berhasil mengirimkan
											Produk Pesanan ke Tempat Pengiriman. Dalam hal demikian, Kami akan
											menginformasikan hal tersebut kepada Pelanggan dengan menggunakan
											rincian kontak yang Pelanggan berikan kepada Kami ketika Pelanggan
											membuat PO antara Pelanggan dan BEB. Dalam hal tidak ada penerima
											yang menerima Produk Pesanan tersebut, maka Produk Pesanan akan
											dikembalikan kepada Kami. Apabila Pelanggan meminta untuk dilakukan
											pengiriman kembali, Pelanggan setuju bahwa Pelanggan akan diwajibkan
											untuk membayar biaya pengiriman kembali tersebut.
										</li>
										<li>
											{' '}
											Semua risiko atas Produk Pesanan akan berpindah kepada Anda setelah
											Kami mengirimkan Produk Pesanan ke alamat sebagaimana tercantum dalam
											Konfirmasi Pesanan dan diterima oleh Pelanggan. Kami tidak memiliki
											tanggung jawab dalam bentuk apapun atas Produk Pesanan, termasuk atas
											hilangnya, cacat dan/atau rusaknya Produk Pesanan yang telah
											Pelanggan terima, setiap kerusakan, cacat atau tidak berfungsinya
											Produk Pesanan akibat pemakaian Anda atas Produk Pesanan tersebut,
											yang tidak sesuai dengan pedoman pemakaian. Kami berhak untuk
											melarang atau membatasi pemesanan berikutnya di kemudian hari yang
											Pelanggan buat atau lakukan dalam hal terdapat pelanggaran kewajiban
											oleh Pelanggan atas kewajiban Pelanggan dalam Pemesanan sebelumnya
											maupun atas Syarat dan Ketentuan.
										</li>
										<li>
											{' '}
											Jika Pelanggan tidak ada di Tempat Pengiriman saat Produk Pesanan
											dikirimkan, Kami dan/atau pihak lain yang ditunjuk oleh Kami dapat
											meninggalkan kartu yang berisi petunjuk mengenai pengiriman ulang
											atau pengambilan Produk Pesanan dari kurir Kami dan/atau pengirim
											pihak ketiga tersebut.
										</li>
									</ol>
								</li>
								<li>
									<b>PRODUK RETUR</b>
									<br /> Ketentuan-ketentuan mengenai Produk Retur akan diatur dalam{' '}
									<a
										href='https://www.mbiz.co.id'
										target='blank'
										style={{ color: '#1A9AED' }}
									>
										www.Mbiz.co.id
									</a>{' '}
									Kebijakan Komplain Produk yang merupakan bagian yang tidak terpisahkan
									dari Syarat dan Ketentuan ini.
								</li>
								<li>
									<b>KEBIJAKAN PENGEMBALIAN PRODUK DAN PENGEMBALIAN UANG</b>
									<br /> Ketentuan-ketentuan mengenai pengembalian Produk dan
									pengembalian uang akan diatur dalam{' '}
									<a
										href={`${window.location.origin}/pages/id/complaint`}
										target='blank'
									>
										Kebijakan Komplain
									</a>{' '}
									yang merupakan bagian yang tidak terpisahkan dari Syarat dan Ketentuan
									ini.
								</li>
								<li>
									<b> VOUCHER </b>
									<ol>
										<li>
											{' '}
											Pelanggan dapat menggunakan voucher (baik voucher hadiah maupun
											voucher promosi) yang secara resmi Kami terbitkan atau distribusikan
											pada saat Pelanggan melakukan pembayaran atas pemesanan Produk pada
											Situs.
										</li>
										<li>
											{' '}
											Untuk menebus voucher di Akun Pelanggan, Pelanggan dapat mengunjungi
											&#34;Akun Pengguna&#34; di Situs. Kami tidak bertanggung jawab atas
											kesalahan pada alamat email penerima voucher.
										</li>
										<li>
											{' '}
											Jika Pelanggan memiliki voucher yang secara resmi diterbitkan atau
											didistribusikan oleh Kami, voucher tersebut dapat dialihkan kepada
											pihak lain yang juga memilliki Akun dalam Situs.
										</li>
										<li>
											{' '}
											Dalam hal terjadi tindakan atau upaya penipuan atau dalam hal Kami
											memiliki kecurigaan atas adanya atau dugaan adanya kegiatan ilegal
											lainnya yang berhubungan dengan penebusan voucher di Situs, Kami
											berhak untuk sewaktu-waktu menutup Akun Pelanggan dan/atau meminta
											kepada Pelanggan untuk menggunakan alat pembayaran yang berbeda.
										</li>
										<li>
											{' '}
											Kami tidak bertanggung jawab atas kehilangan, pencurian atau tidak
											terbacanya voucher.
										</li>
										<li>
											{' '}
											Karena alasan-alasan tertentu khususnya untuk menghentikan
											penyalahgunaan atas voucher dan sepanjang hal tersebut tidak
											bertentangan dengan ketentuan hukum dan peraturan perundang-undangan
											yang berlaku, Kami dapat sewaktu-waktu menghentikan penggunaan
											voucher. Kami tidak bertanggung jawab atas kerugian yang timbul dari
											dihentikannya penggunaan voucher tersebut. Setiap 1 (satu) voucher
											yang Kami terbitkan hanya dapat digunakan satu kali. Jika terdapat
											lebih dari satu kali pemakaian, Kami dapat membatalkan penggunaan
											voucher tersebut.
										</li>
										<li>
											{' '}
											Kondisi untuk penebusan voucher promosi:
											<br /> Dari waktu ke waktu Kami mungkin dapat mendistribusikan
											voucher promosi yang dapat digunakan hanya pada Situs Kami, di mana
											Kami akan mengirimkan kepada Pelanggan baik melalui email atau pos:
											<ol>
												<li>
													{' '}
													Voucher promosi berlaku untuk jangka waktu tertentu yang tertera
													pada voucher, hanya dapat ditebus atau digunakan sekali dan tidak
													dapat digunakan bersama dengan voucher promosi lainnya maupun untuk
													Produk diskon. Kami mungkin melakukan pengecualian penggunaan
													voucher promosi pada merek Produk tertentu sesuai dengan strategi
													penjualan Kami.
												</li>
												<li>
													{' '}
													Jika Pelanggan menempatkan Pemesanan untuk Produk dengan nilai
													kurang dari voucher promosi, tidak ada pengembalian dana atau
													kredit sisa yang akan dikembalikan kepada Pelanggan.
												</li>
												<li>
													{' '}
													Kredit voucher promosi tidak menghasilkan bunga serta tidak
													memiliki nilai tunai.
												</li>
												<li>
													{' '}
													Jika kredit voucher promosi tidak cukup untuk Pemesanan Pelanggan,
													Pelanggan dapat menggunakan salah satu metode pembayaran yang dapat
													Kami terima untuk menutupi kekurangan tersebut.
												</li>
												<li> Voucher mengurangi harga beli sebelum pajak.</li>
											</ol>
										</li>
										<li>
											{' '}
											Setiap akibat yang terjadi pada voucher promosi pada saat dan setelah
											transaksi bukan merupakan tanggung jawab Kami, termasuk apabila
											terjadi kerusakan pada fisik voucher, bagian-bagian yang tidak
											lengkap, penurunan nilai Produk maupun kewajiban dan konsekuensi
											legal yang terjadi karena transaksi.
										</li>
									</ol>
								</li>
								<li>
									<b>TANGGUNG JAWAB</b>
									<br />
									<ol>
										<li>
											{' '}
											Kami tidak bertanggung jawab atas biaya, kerugian maupun kerusakan
											dalam bentuk apapun, termasuk tidak terbatas pada:
											<ol>
												<li>
													{' '}
													Kerugian langsung, tidak langsung, khusus dan/atau konsekuensial;
												</li>
												<li>
													{' '}
													Kerugian atas kehilangan penggunaan, keuntungan, data atau wujud
													lainnya;
												</li>
												<li> Kerugian atas tercemarnya nama baik atau reputasi, maupun</li>
												<li>
													{' '}
													Biaya pengadaan Produk pengganti, masing-masing yang timbul dari
													atau terkait dengan penggunaan, ketidakmampuan untuk menggunakan,
													kinerja atau kegagalan dari Situs dan/atau setiap isi, konten,
													materi, data, deskripsi, gambar maupun informasi yang ditampilkan
													pada Situs maupun yang ditempatkan atau terdapat dalam Situs,
													sepanjang biaya, kerugian maupun kerusakan tersebut tidak
													diakibatkan karena kesalahan dan/atau kelalaian Kami yang dapat
													dibuktikan secara nyata. Kami tidak memberikan pernyataan dan/atau
													jaminan bahwa Situs bebas virus dan/atau materi berbahaya lainnya
													di dalam Situs yang dapat mengakibatkan tidak berfungsinya media,
													jaringan, sistem, dan/atau peralatan yang Anda gunakan untuk
													mengakses Situs ini. Anda dengan ini sepakat dan mengikatkan diri
													untuk membebaskan dan melepaskan Kami dari setiap klaim, tuntutan,
													dan/atau gugatan dari Anda maupun dari segala kerugian yang Anda
													alami sehubungan dengan hal tersebut.
												</li>
											</ol>
										</li>
										<li>
											{' '}
											Kami tidak bertanggung jawab kepada Anda maupun kepada pihak lainnya
											atas kerugian yang dialami oleh Anda dan/atau pihak manapun yang
											disebabkan oleh kegagalan, penundaan dan/atau terputusnya Layanan.
											Sehubungan dengan hal tersebut di atas, Pelanggan menyetujui bahwa
											Pelanggan tidak dapat mengajukan klaim, gugatan, dan/atau tuntutan
											dalam bentuk apapun kepada Kami atas segala kerugian atau kerusakan
											yang timbul.
										</li>
										<li>
											{' '}
											Anda dengan ini secara tegas menyatakan dan sepakat bahwa penggunaan
											Situs oleh Anda adalah risiko Anda sendiri berdasarkan inisiatif Anda
											sendiri tanpa paksaan dari Kami. Syarat dan Ketentuan ini dan
											Ketentuan Lain tidak dapat dianggap, diartikan, atau
											diinterpretasikan sebagai jaminan dari Kami:
											<ol>
												<li>
													{' '}
													Atas kompabilitas Situs dengan media, jaringan, sistem dan/atau
													peralatan yang Anda gunakan untuk mengakses Situs ini;
												</li>
												<li>
													{' '}
													Bahwa Situs ini bebas dari interupsi, virus dan/atau <i>bug</i>;
												</li>
												<li> Bahwa Situs tanpa cela;</li>
												<li>
													{' '}
													Bahwa hasil pemrosesan Pemesanan melalui Situs adalah selalu akurat
													dan dapat diandalkan, dan
												</li>
												<li>
													{' '}
													Setiap kekurangan Situs, baik yang disampaikan oleh Anda maupun
													yang Kami temukan sendiri akan Kami perbaiki.
												</li>
											</ol>
										</li>
									</ol>
								</li>
								<li>
									<b>GANTI RUGI</b>
									<br /> Anda wajib dan dengan ini setuju untuk mengganti seluruh
									kerugian yang dialami oleh Kami, serta membela dan membebaskan dan
									melepaskan Kami maupun direktur, pejabat, karyawan, konsultan, agen,
									dan afiliasi Kami dari setiap dan semua:
									<ol>
										<li>
											Klaim, teguran, tuntutan dan/atau gugatan dari pihak manapun dalam
											bentuk apapun;
										</li>
										<li>
											{' '}
											Kewajiban, kerusakan, pengeluaran, dan/atau biaya (termasuk namun
											tidak terbatas pada biaya hukum) yang timbul atau dialami, sehubungan
											dengan atau sebagai akibat dari penggunaan Situs ini oleh Anda atau
											pelanggaran Anda terhadap Syarat dan Ketentuan ini, Ketentuan Lain
											dan ketentuan hukum dan peraturan perundang-undangan yang berlaku
											sehubungan dengan penggunaan Situs ini.
										</li>
									</ol>
								</li>
								<li>
									<b>PENGADUAN DAN PELAPORAN KELUHAN</b>
									<br />
									<ol>
										<li>
											{' '}
											Kami menempatkan nilai besar pada kepuasan Anda dan akan melaksanakan
											kewajiban Kami sesuai dengan Syarat dan Ketentuan ini dan Ketentuan
											Lain. Anda dapat menghubungi Kami setiap saat pada alamat sebagaimana
											tercantum dalam Situs. Kami akan berusaha untuk memberikan solusi
											atas keluhan Anda terkait dengan penggunaan Situs secepat mungkin dan
											akan menghubungi Anda untuk pemeriksaan keluhan terkait.
										</li>
										<li>
											{' '}
											Kami membuka layanan pelaporan atas seluruh tindakan yang dirasa
											menyimpang dari implementasi Syarat dan Ketentuan, Ketentuan Lain,
											maupun peraturan internal Kami. Pelaporan tersebut dapat disampaikan
											melalui:
											<br />
											Email:{' '}
											<a
												href={'mailto:ethics-exsternal@mbiz.co.id'}
												style={{ color: '#1A9AED' }}
											>
												ethics-exsternal@mbiz.co.id
											</a>
											<br />
											Telepon/SMS: 0822-1300-1016
										</li>
										<li>
											{' '}
											Kerahasiaan identitas Anda akan dijamin sepenuhnya dan Kami akan
											menindaklanjuti seluruh laporan sesuai dengan peraturan internal dan
											peraturan perundang-undangan yang berlaku.
										</li>
										<li>
											{' '}
											Anda memahami dan menyadari bahwa email Anda dapat disaring oleh
											filter spam Kami atau tidak terkirim kepada Kami, atau korespondensi
											yang Kami kirimkan kepada Anda tidak mencapai Anda atau tidak dapat
											Anda terima. Jika Anda tidak menerima tanggapan dari Kami dalam waktu
											3 (tiga) Hari Kerja setelah pengiriman email keluhan Anda, Anda dapat
											menghubungi Kami kembali.
										</li>
									</ol>
								</li>
								<li>
									<b>PERLINDUNGAN DATA PRIBADI </b>
									<div>
										{' '}
										Silahkan lihat{' '}
										<a
											href={`${window.location.origin}/pages/id/privacy-policy`}
											style={{ color: '#1A9AED' }}
										>
											Kebijakan Privasi
										</a>{' '}
										Kami yang merupakan bagian tidak terpisahkan dari Syarat dan Ketentuan
										ini.
									</div>
								</li>
								<li>
									<b>KEADAAN KAHAR (FORCE MAJEURE)</b>
									<br />
									<ol>
										<li>
											{' '}
											Kami tidak bertanggung jawab kepada Anda untuk setiap pelanggaran,
											hambatan, atau keterlambatan dalam penggunaan Situs maupun Syarat dan
											Ketentuan ini oleh Kami yang disebabkan oleh keadaan kahar{' '}
											<i>(force majeure)</i> maupun keadaan atau kondisi apapun di luar
											kendali Kami, termasuk namun tidak terbatas pada:
											<ol>
												<li> Pemogokan, lock-out atau tindakan industrial lainnya;</li>
												<li>
													{' '}
													Keributan sipil, kerusuhan, invasi, serangan teroris atau ancaman
													serangan teroris, perang (baik dinyatakan atau tidak) atau ancaman
													atau persiapan untuk perang;
												</li>
												<li>
													{' '}
													Kebakaran, ledakan, badai, banjir, gempa bumi, longsor, wabah, atau
													bencana alam lainnya;
												</li>
												<li>
													{' '}
													Ketidakmungkinan penggunaan kereta api, pesawat pengirim, alat
													transportasi bermotor, atau alat transportasi lainnya baik publik
													atau swasta;
												</li>
												<li>
													{' '}
													Ketidakmungkinan penggunaan jaringan telekomunikasi publik atau
													swasta;
												</li>
												<li>
													{' '}
													Tindakan, keputusan, undang-undang, peraturan, atau pembatasan dari
													pemerintah, pengiriman, pos atau aksi mogok transportasi lain yang
													berkaitan, kegagalan atau kecelakaan; dan/atau;
												</li>
												<li>
													{' '}
													Kerusakan pada jaringan atau sistem komputer atau sistem internet
													pada Situs Kami.
												</li>
											</ol>
										</li>
										<li>
											{' '}
											Dalam hal keadaan atau peristiwa sebagaimana dimaksud Pasal 15.1 di
											atas berlangsung selama lebih dari satu minggu, baik Pelanggan atau
											Kami dapat segera mengakhiri Transaksi dengan menyampaikan
											pemberitahuan tertulis dan tanpa kewajiban apapun dari Kami selain
											pengembalian Produk yang sudah Pelanggan pesan melalui Pemesanan dan
											sudah Pelanggan bayar namun belum Kami kirimkan.
										</li>
										<li>
											{' '}
											Jika Kami telah menyediakan Produk yang identik atau mirip dengan
											lebih dari satu Pelanggan namun Kami tidak dapat memenuhi kewajiban
											Kami kepada Pelanggan karena terjadinya satu atau lebih peristiwa
											sebagaimana dimaksud Pasal 15.1 tersebut di atas, Kami berhak untuk
											memutuskan Transaksi mana yang akan tetap berlaku dan Transaksi mana
											yang akan Kami akhiri.
										</li>
										<li>
											{' '}
											Kami memiliki kebijaksanaan mutlak untuk menentukan solusi yang akan
											Kami ambil dalam memenuhi kewajiban Kami berdasarkan Transaksi dalam
											hal terjadi peristiwa sebagaimana dimaksud Pasal 15.1 tersebut di
											atas.
										</li>
									</ol>
								</li>
								<li>
									<b> PEMBERITAHUAN</b>
									<br />
									<ol>
										<li>
											{' '}
											Setiap pemberitahuan kepada Pelanggan atau Penjual sehubungan dengan
											Transaksi akan diberikan secara tertulis baik melalui surat atau
											email kepada pihak yang bersangkutan di alamat atau alamat email
											terakhir yang diketahui.
										</li>
										<li>
											{' '}
											Pemberitahuan yang disampaikan melalui pos harus dianggap telah
											diterima oleh yang bersangkutan dalam waktu 2 (dua) Hari Kerja sejak
											tanggal pengiriman, jika pengiriman ditujukan ke alamat penerima di
											Indonesia.
										</li>
										<li>
											{' '}
											Pemberitahuan yang disampaikan melalui email dianggap telah diterima
											oleh yang bersangkutan dalam waktu 2 (dua) hari kalender sejak
											tanggal email dikirim.
										</li>
										<li>
											{' '}
											Dalam membuktikan pengiriman pemberitahuan, cukup dibuktikan dengan
											pembuktian tertulis atau elektronik di mana surat atau email tersebut
											telah benar-benar dikirimkan.
										</li>
									</ol>
								</li>
								<li>
									<b>HAK ATAS KEKAYAAN INTELEKTUAL</b>
									<ol>
										<li>
											{' '}
											Semua isi, konten, materi, data, deskripsi, gambar maupun informasi
											yang ditampilkan pada Situs maupun yang ditempatkan atau terdapat
											dalam Situs termasuk tetapi tidak terbatas pada teks, grafik, logo,
											tombol ikon, gambar, klip audio, digital download, judul kampanye
											dan/atau kompilasi data Kami, dan iklan (namun tidak termasuk
											Informasi Produk maupun merek, logo, gambar, simbol dan/atau bentuk
											visual lainnya yang terkait dengan Produk) sepenuhnya merupakan milik
											Kami atau Kami telah memperoleh hak dari pemegang hak atau pemegang
											lisensinya yang dilindungi oleh peraturan perundang-undangan yang
											berlaku. Anda tidak diizinkan untuk mempublikasikan, memanipulasi,
											mendistribusikan, menggandakan, menyebarluaskan dan/atau mereproduksi
											dengan cara maupun dalam bentuk atau format apapun, satu atau lebih
											isi, konten, materi, data, deskripsi, gambar maupun informasi yang
											ditampilkan pada Situs (termasuk Informasi Produk) maupun salinannya
											untuk keperluan apapun termasuk tetapi tidak terbatas untuk keperluan
											bisnis Anda maupun dalam rangka kerjasama dengan pihak manapun.
										</li>
										<li>
											{' '}
											Setiap grafis, logo, judul halaman, tombol ikon, teks, maupun nama
											layanan yang termasuk dalam atau tersedia dalam Situs (namun tidak
											termasuk Informasi Produk maupun merek, logo, gambar, simbol dan/atau
											bentuk visual lainnya yang terkait dengan Produk) adalah merek dagang
											milik Kami atau pemegang lisensi, dilindungi oleh Undang-Undang Hak
											Cipta yang berlaku di Indonesia dan berlaku di yurisdiksi lain. Merek
											dagang Mbiz.co.id tidak dapat dengan cara apapun dan dalam kondisi
											apapun digunakan dalam kaitannya dengan barang, jasa, produk dan/atau
											hal lainnya yang tidak tersedia pada Situs, termasuk dengan cara
											apapun yang atau mungkin dapat menyebabkan kebingungan di kalangan
											publik maupun Pelanggan atau dengan cara yang merendahkan atau
											mendiskreditkan Kami atau Situs. Semua merek dagang atau hak atas
											kekayaan intelektual lainnya yang tidak dimiliki oleh Kami yang
											muncul di Situs adalah milik masing-masing pemiliknya.
										</li>
										<li>
											{' '}
											Anda tidak boleh menggunakan bagian manapun dari Situs Kami maupun
											sebagian atau seluruh isi, konten, materi, data, deskripsi, gambar
											maupun informasi yang ditampilkan pada Situs maupun yang ditempatkan
											atau terdapat dalam Situs Kami untuk tujuan apapun termasuk namun
											tidak terbatas untuk tujuan komersial tanpa memperoleh lisensi
											langsung dan resmi serta secara tertulis terlebih dahulu dari Kami
											atau pemegang lisensi yang bersangkutan. Dalam hal Kami mengetahui
											adanya pelanggaran hak atas kekayaan intelektual, Kami dapat
											mengambil tindakan hukum sesuai dengan ketentuan hukum dan peraturan
											perundang-undangan yang berlaku.
										</li>
										<li>
											{' '}
											Jika Anda mencetak, menyalin atau mengunduh bagian manapun dari Situs
											Kami namun melanggar satu atau lebih Syarat dan Ketentuan ini, hak
											Anda untuk menggunakan Situs akan segera dihentikan tanpa
											pemberitahuan terlebih dahulu dan tanpa diperlukan ijin dari Anda dan
											Anda wajib untuk, sesuai keputusan atau kebijakan Kami, mengembalikan
											atau memusnahkan setiap salinan isi, konten, materi, data, deskripsi,
											gambar maupun informasi yang ditampilkan pada Situs maupun yang
											ditempatkan atau terdapat dalam Situs Kami yang telah Anda buat atau
											Anda akses atau Anda miliki. Anda dilarang dengan cara apapun
											memodifikasi, menerjemahkan, menguraikan, membongkar atau membuat
											karya turunan berdasarkan perangkat lunak atau dokumentasi yang
											menyertainya disediakan oleh Kami maupun oleh pemegang lisensi yang
											bersangkutan.
										</li>
									</ol>
								</li>
								<li>
									<b>IKLAN PADA SITUS</b>
									<br /> Kami akan berupaya semaksimal mungkin untuk mematuhi setiap
									peraturan perundang-undangan yang terkait dengan pengelolaan Situs.
									Walaupun demikian, terdapat kondisi di mana pengiklan dalam Situs
									bertindak di luar kendali dan di luar kemampuan Kami, yang mungkin
									melanggar peraturan perundang-undangan yang berlaku. Dalam hal
									demikian, tanggung jawab atas pelanggaran tersebut sepenuhnya merupakan
									tanggung jawab dari pengiklan dalam Situs tersebut dan Anda setuju
									untuk membebaskan dan melepaskan Kami dari tanggung jawab tersebut.
								</li>
								<li>
									<b>LINK KE SITUS</b>
									<ol>
										<li>
											{' '}
											Anda dilarang untuk membangun link sedemikian rupa untuk menunjukkan
											bentuk asosiasi, persetujuan atau dukungan dari Kami tanpa
											persetujuan Kami.
										</li>
										<li>
											{' '}
											Anda tidak diperkenankan untuk membuat link dari setiap situs yang
											tidak dimiliki oleh Anda.
										</li>
										<li>
											{' '}
											Situs ini tidak boleh dibingkai pada situs lain, dan Anda dilarang
											untuk membuat link menuju situs Anda di halaman Situs, dan Anda tidak
											diperkenankan untuk membuat link ke bagian manapun dari Situs ini
											selain halaman depan. Kami berhak untuk menarik izin penghubungan
											atau link tanpa pemberitahuan kepada Anda dan tanpa persetujuan Anda.
										</li>
										<li>
											{' '}
											Kami tidak bertanggung jawab atas isi dan konten link di luar Situs
											atau situs yang disediakan atau dipasang oleh pihak ketiga, baik yang
											dimasukkan oleh Pelanggan yang tidak disebutkan namanya atau oleh
											mitra Kami atau oleh pihak manapun. Dengan ditampilkannya link, situs
											atau konten pihak ketiga dalam Situs, tidak dapat diartikan atau
											dianggap atau diasumsikan bahwa Kami maupun pihak terafiliasi,
											pejabat, petugas, atau pegawainya memiliki hubungan kerja sama dengan
											pihak ketiga tersebut.
										</li>
									</ol>
								</li>
								<li>
									<b>ANTI SUAP DAN ANTI KORUPSI</b>
									<ol>
										<li>
											{' '}
											AndAnda menyatakan dan menjamin bahwa Anda telah mengetahui, membaca,
											dan memahami dengan baik seluruh ketentuan hukum dan peraturan
											perundang-undangan terkait dengan anti suap dan korupsi yang berlaku
											di Indonesia, termasuk Kitab Undang-Undang Hukum Pidana,
											Undang-Undang Nomor 11 Tahun 1980 tentang Tindak Pidana Suap, dan
											Undang-Undang Nomor 31 Tahun 1999 jo. Undang-Undang Nomor 20 Tahun
											2001 tentang Pemberantasan Tindak Pidana Korupsi serta segala
											perubahan, penambahan, atau ketentuan pelaksananya{' '}
											<b>
												(&#34; Peraturan mengenai Anti Suap dan Anti Korupsi&#34;)
											</b>{' '}
											dalam setiap transaksi bisnis dan kegiatan yang dilakukan sehubungan
											dengan Kami, dan tidak akan melakukan tindakan apapun yang melanggar
											atau mungkin melanggar Peraturan mengenai Anti Suap dan Anti Korupsi.
											Oleh karena itu, Anda dengan ini sepakat bahwa:
											<ol>
												<li>
													{' '}
													Anda tidak akan memperkerjakan dan mempertahankan orang yang
													merupakan pejabat pemerintah atau karyawan, termasuk karyawan dari
													korporasi, agen, atau badan yang dimiliki atau dikendalikan oleh
													pemerintah, kecuali jika jasa yang dipertahankan adalah pekerjaan
													konsultasi yang tulen dan, jika diperlukan, pejabat yang
													dipertahankan telah memperoleh persetujuan oleh otoritas/pemberi
													kerja yang kompeten untuk layanan konsultasi yang dimaksud;
												</li>
												<li>
													{' '}
													Anda tidak akan, secara langsung maupun tidak langsung, membayar,
													menawarkan, menjanjikan, atau mengizinkan untuk membayar atau
													mengalihkan uang atau apapun yang berharga dengan nilai berapapun,
													termasuk hadiah, sumbangan politik, pembayaran fasilitasi ataupun
													amal, kepada pihak manapun, termasuk pejabat atau karyawan swasta
													maupun pemerintah, atau kepada partai politik atau kandidat untuk
													jabatan politik, dengan tujuan untuk mempengaruhi pengambilan
													keputusan yang menguntungkan bisnis Anda ataupun Kami yang
													bertentangan dengan Peraturan mengenai Anti Suap dan Anti Korupsi;{' '}
												</li>
												<li>
													{' '}
													Anda akan memenuhi dan memastikan bahwa masing-masing dari
													pemimpin, pemilik, pemegang saham, pejabat, direksi, karyawan, dan
													agen mematuhi, semua Peraturan mengenai Anti Suap dan Anti Korupsi
													yang berlaku dalam setiap transaksi bisnis dan kegiatan yang
													dilakukan sehubungan dengan Kami;
												</li>
												<li>
													{' '}
													Anda akan senantiasa mengambil semua langkah yang wajar untuk
													memastikan bahwa setiap orang dan badan yang dipekerjakan atau
													terasosiasi dengan Anda dalam kaitannya dengan Kami tidak akan
													melanggar ketentuan ini ataupun Peraturan mengenai Anti Suap dan
													Anti Korupsi;
												</li>
												<li>
													{' '}
													Anda akan segera memberitahukan Kami secara tertulis dalam hal
													orang yang diperkerjakan atau terasosiasi Anda menjadi pejabat
													pemerintah, pejabat atau kandidat partai politik, kecuali jasa yang
													dipertahankan adalah pekerjaan konsultasi yang tulen dan, jika
													diperlukan, pejabat yang dipertahankan telah memperoleh persetujuan
													seperlunya dari otoritas/pemberi kerja yang kompeten untuk layanan
													konsultasi yang dimaksud;
												</li>
												<li>
													{' '}
													Anda akan memelihara catatan yang benar dan akurat sesuai kebutuhan
													untuk mendemonstrasikan kepatuhan terhadap Syarat dan Ketentuan
													serta Ketentuan Lain ini dan akan menyediakan Kami sertifikasi
													tertulis atas kepatuhan tersebut berdasarkan permintaan yang
													sederhana;
												</li>
												<li>
													{' '}
													Kami berhak untuk mengaudit pembukuan dan catatan Anda, sepanjang
													diperlukan, untuk tujuan menilai kepatuhan terhadap ketentuan Kami,
													tergantung pada kewajaran tempat, tanggal dan waktu dari audit yang
													dimaksud, sepanjang pembukuan dan catatan tersebut berhubungan
													dengan kinerja Anda sehubungan dengan Kami.
												</li>
											</ol>
										</li>
										<li>
											{' '}
											Anda akan segera memberitahukan Kami secara tertulis dalam hal Anda
											mengetahui ada karyawan Kami yang melanggar atau patut diduga
											melanggar satu atau lebih ketentuan ini, termasuk ketentuan Peraturan
											mengenai Anti Suap dan Anti Korupsi dalam melaksanakan pekerjaannya
											dengan Anda.
										</li>
										<li>
											{' '}
											Anda akan membebaskan, melepaskan, serta mengganti rugi Kami dari
											segala macam tuntutan hukum dan/atau ganti rugi yang timbul dan
											diajukan oleh pihak lain terkait dengan pelanggaran dan/atau
											kelalaian Anda atas satu atau lebih ketentuan ini dan Peraturan
											mengenai Anti Suap dan Anti Korupsi, termasuk kelalaian Anda untuk
											melaksanakan kewajibannya berdasarkan ketentuan ayat 1 dan ayat 2
											Pasal ini. Segala bentuk pernyataan, tindakan, dan janji yang dibuat
											atau dilakukan oleh Anda kepada pihak ketiga lainnya yang melanggar
											ketentuan ini atau Peraturan mengenai Anti Suap dan Anti Korupsi
											merupakan pernyataan, tindakan, dan janji yang dibuat dan menjadi
											tanggung jawab Anda sendiri, serta tidak mewakili Kami.
										</li>
									</ol>
								</li>
								<li>
									<b>PEMBEBASAN</b>
									<br />
									Konten Situs dan semua informasi yang tersedia di dalamnya disediakan
									&#34;sebagaimana adanya&#34; dan &#34;sebagaimana tersedia&#34; tanpa
									jaminan apapun, baik tersurat maupun tersirat, dan Anda setuju untuk
									menggunakan Situs ini dan konten apa adanya dengan risiko Anda sendiri.
									Kami tidak membuat pernyataan, jaminan, atau garansi dalam bentuk
									apapun, tersurat atau tersirat, atau sebaliknya, lisan maupun tertulis,
									langsung atau tidak langsung, termasuk tidak terbatas pada, pernyataan
									apapun, jaminan, atau jaminan diperdagangkan, kesesuaian untuk umum
									atau tujuan tertentu, atas non-pelanggaran, ketepatan atau kelengkapan
									jawaban, hasil, atau ketiadaan virus, sehubungan dengan Situs dan
									konten Situs, atau hal lain apapun sehubungan dengan Situs.
								</li>
								<li>
									<b>PEMISAHAN</b>
									<br /> Jika ada klausul dalam Syarat dan Ketentuan ini menjadi atau
									dinyatakan tidak berlaku, cacat hukum, atau tidak dapat dilaksanakan
									berdasarkan hukum yang berlaku atau oleh pengadilan yang berwenang, hal
									tersebut tidak akan mempengaruhi ketentuan, pasal, atau bagian lain
									dari Syarat dan Ketentuan ini, dan semuanya akan tetap berlaku dan
									mengikat secara sah.
								</li>
								<li>
									<b>HUBUNGAN</b>
									<br /> Tidak ada ketentuan dalam Syarat dan Ketentuan yang menciptakan
									hubungan kemitraan, agen atau hubungan pemberi kerja dan karyawan
									antara Anda dan Kami.
								</li>
								<li>
									<b>HAK PIHAK KETIGA</b>
									<br /> Tidak ada orang atau pihak manapun diluar Anda dan Kami yang
									memiliki hak atau berhak untuk mendapatkan keuntungan dari Kontrak
									antara Anda dan Kami serta Syarat dan Ketentuan ini.
								</li>
								<li>
									<b>HUKUM YANG BERLAKU</b>
									<br /> Syarat dan Ketentuan serta semua kewajiban kontraktual yang
									timbul dari atau berhubungan dengan Syarat dan Ketentuan dan Ketentuan
									Lain diatur dan ditafsirkan sesuai dengan hukum Indonesia. Dalam hal
									terdapat perselisihan sehubungan dengan Syarat dan Ketentuan, Ketentuan
									Lain maupun hubungan kontraktual lainnya, Kami dan Anda sepakat untuk
									menyelesaikan segala perselisihan tersebut melalui Pengadilan Negeri
									Jakarta Selatan. Semua transaksi, korespondensi, dan kontak antara Kami
									dan Anda akan dibuat atau dilakukan dalam bahasa Indonesia atau bahasa
									Indonesia yang disertai dengan terjemahan bahasa Inggris.
								</li>
								<li>
									<b>PERUBAHAN</b>
									<br />
									<div>
										{' '}
										Kami berhak untuk memperbaharui Syarat dan Ketentuan ini setiap waktu
										dengan menampilkannya di Situs. Anda dengan ini menyatakan setuju
										bahwa Anda diwajibkan untuk melihat dan membaca pembaharuan tersebut.
										Apabila Anda tidak setuju, keberatan, atau menolak pembaharuan
										tersebut, Anda diminta untuk segera menghentikan penggunaan dan/atau
										akses Anda kepada Situs, serta menon-aktifkan Akun Anda. Apabila Anda
										tidak melakukan hal-hal tersebut, maka Anda dianggap telah menyetujui
										dan sepakat serta menerima pembaharuan dari Syarat dan Ketentuan
										tersebut. Setiap penggunaan Situs setelah pembaharuan tersebut
										ditampilkan secara online, akan dianggap sebagai penerimaan dan
										penundukan diri Anda pada pembaharuan Syarat dan Ketentuan yang
										berlaku pada saat tersebut. Setiap ada pengiriman Pemesanan ke Situs,
										Anda setuju bahwa Anda melakukannya dan tunduk pada Syarat dan
										Ketentuan yang berlaku pada tanggal Anda mengirimkan Pemesanan Anda.
										Anda berkewajiban dan bertanggung jawab untuk membaca, meninjau, dan
										mengerti Syarat dan Ketentuan yang terbaru setiap kali Anda
										mengirimkan Pemesanan Anda.
									</div>
									<div style={{ marginTop: 8 }}>
										Kami dan Anda dengan ini sepakat untuk mengesampingkan ketentuan ayat
										2 dan ayat 3 dari Pasal 1266 Kitab Undang-Undang Hukum Perdata
										Indonesia, sepanjang diperlukan putusan pengadilan untuk mengakhiri
										Syarat dan Ketentuan ini.
									</div>
									<div style={{ marginTop: 8 }}>
										<i>
											Syarat & Ketentuan ini terakhir diperbaharui tanggal 24 Juni 2024.
										</i>
									</div>
								</li>
							</ol>
						</Box>
					</Box>
				</Container>
			</LayoutComponent>
		</>
	)
}

export default TermAndConditions
