import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dashboard,
	},
	containerRoot: {
		padding: theme.spacing(3, 12),
	},
	datepicker: {
		width: 227,
	},
	tableRoot: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},
	totalPrice: {
		padding: theme.spacing(1, 3),
		justifyContent: 'space-between',
		display: 'flex',
		flex: '1',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	textareaRoot: {
		width: 380,
	},
	addressValue: {
		width: 290,
	},
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		outline: 0,
	},
	modalFade: {
		minWidth: 600,
		outline: 0,
	},
	modalCloseIcon: {
		alignSelf: 'end',
	},
	containerField: {
		alignItems: 'center',
		justifyContent: 'space-around',
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(2),
	},
	outlinedInputRoot: {
		overflow: 'hidden',
		paddingRight: 0,
		marginTop: 4,
	},
	outlinedInputadornedEnd: {
		backgroundColor: theme.palette.secondary,
	},
	autocompleteRoot: {
		width: 250,
	},
	buttonDanger: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	buttonWidth: {
		minWidth: 120,
	},
	containerButton: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	kembali: {
		marginRight: theme.spacing(2),
	},
	btnWhite: {
		backgroundColor: '#fff',
	},
	modalContent: {
		padding: theme.spacing(3),
	},
	formRfqTop: {
		marginTop: theme.spacing(1),
	},
	font14: {
		fontSize: '14px',
	},
	font19: {
		fontSize: '19px',
	},
	errorText: {
		color: 'red',
	},
	tooltip: {
		maxWidth: 275,
		fontSize: 12,
	},
	buttonFile: {
		width: 140,
		height: 33,
		marginTop: 5,
		marginBottom: 5,
		border: '1px solid #007BFF',
		borderRadius: '3px',
	},
	buttonDialog: {
		marginBottom: 20,
	},
	labelRequired: {
		position: 'relative',
		'&:after': {
			content: '"*"',
			position: 'absolute',
			color: theme.palette.error.dark,
		},
	},
	imageUpload: {
		width: '14px',
		marginRight: 5,
	},
	iconWarning: {
		width: '16px',
		height: '14px',
		marginTop: 2,
		marginRight: 3,
	},
	iconContent: {
		marginBottom: theme.spacing(3),
	},
	paper: () => ({
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(6),
		borderRadius: theme.shape.borderRadius,
		textAlign: 'center',
		justifyContent: 'center',
	}),
	styleIconbutton: {
		paddingLeft: 8,
		color: 'black',
	},
	smallICon: {
		width: 16,
		height: 16,
	},
	arrow: {
		color: theme.palette.common.black,
	},
	tooltipContainer: {
		maxWidth: 600,
		width: 350,
		backgroundColor: theme.palette.common.black,
		boxShadow: theme.shadows[2],
		minHeight: 48,
		maxHeight: 300,
		opacity: 1,
	},
	inputRootBorder: {
		paddingRight: '0 !important',
		backgroundColor: theme.palette.common.white,
		borderRadius: theme.spacing(0.5),
		paddingLeft: theme.spacing(1.5),
		paddingBottom: '0 !important',
		height: 40,
	},
	borderInput: {
		border: '1px solid #BCBCBC',
	},
	borderError: {
		border: '1px solid red',
	},
	endAdornment: {
		top: 0,
		right: 0,
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: 40,
		borderRadius: theme.shape.borderRadius,
		padding: '4px 6.7px',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	option: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	textDescOptionSelect: {
		fontSize: '12px',
		paddingLeft: 20,
		color: '#6F757C',
		marginTop: '0px !improtant',
		paddingTop: '0px !improtant',
	},
	textTitleOptionSelect: {
		fontSize: '16px',
		paddingLeft: 10,
		marginTop: '0px !improtant',
		paddingTop: '0px !improtant',
	},
	listbox: {
		backgroundColor: theme.palette.common.white,
		borderRadius: theme.spacing(0.5),
		border: '1px solid #BCBCBC',
	},
	detailAnggaran: {
		fontSize: 14,
		paddingLeft: 2,
		paddingTop: 5,
	},
	errorColorText: {
		color: '#DC3545',
	},
	button: {
		marginTop: 15,
		color: theme.palette.secondary.main,
		borderColor: theme.palette.secondary.main,
	},
	textLocation: {
		color: theme.palette.grey[600],
		marginTop: '4px',
	},
}))

export default styles
