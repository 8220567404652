export const buttonFilter = [
	{
		status: 'all-step',
		state: '',
		child: false,
	},
	{
		status: 'PR1',
		state: '1',
		child: true,
	},
	{
		status: 'PR0',
		state: '0',
		child: true,
	},
	{
		status: 'PR2',
		state: '2',
		child: false,
	},
	{
		status: 'PR3',
		state: '3',
		child: false,
	},
	{
		status: 'PR4',
		state: '4',
		child: false,
	},
	{
		status: 'PR5',
		state: '5',
		child: false,
	},
]

export const buttonStatus = [
	{
		status: 'Semua Status',
		state: '',
	},
	{
		status: 'Butuh Persetujuan Penyetuju Kategori',
		state: 1,
	},
	{
		status: 'Butuh Persetujuan Penyetuju Departemen',
		state: 2,
	},
	{
		status: 'Butuh Persetujuan Eproc',
		state: 3,
	},
]
