import { makeStyles } from '@material-ui/core/styles'

export const dashboardStyle = makeStyles((theme) => ({
	noMargin: {
		'&:last-child': {
			padding: 24,
		},
	},
	cardFull: {
		width: '100%',
	},
	buttonFull: {
		width: '100%',
		height: '100%',
		backgroundColor: theme.palette.grey.A200,
		color: theme.palette.text.secondary,
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	borderColor: {
		borderColor: theme.palette.grey.A200,
	},
}))
