import { BANK_DELETE, BANK_LIST } from '../../types'
import API from '../../../utils/api'

const loading = () => {
	return {
		type: `${BANK_DELETE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${BANK_DELETE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${BANK_DELETE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const successGetList = (data) => {
	return {
		type: `${BANK_LIST}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const clearDeleteBank = () => {
	return {
		type: `${BANK_DELETE}_CLEAR`,
	}
}

export const deleteBank = (id) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			const response = await API({
				method: 'DELETE',
				url: `/master/bank/delete/${id}`,
			})

			const getListBank = await API({
				method: 'GET',
				url: '/master/bank/list',
			})
			dispatch(successGetList(getListBank))
			dispatch(success(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
