import * as Yup from 'yup'

const schema = Yup.object().shape({
	name: Yup.string().required('Nama Lengkap tidak boleh kosong'),
	mobile: Yup.string()
		.required('No Ponsel tidak boleh kosong')
		.matches(
			/^\+?[\d+]+$/,
			'No Ponsel hanya boleh mengandung karakter + dan numerik'
		),
})

export default schema
