/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import { BankList, BankAdd } from '../routes'
import { rolesText } from '../constants/text'

export const BankNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard/bank-account'
				roles={rolesText('bank')}
				Component={BankList}
			/>
			<PrivateRoute
				path='/dashboard/bank-account/add'
				roles={rolesText('bank')}
				Component={BankAdd}
			/>
			<PrivateRoute
				path='/dashboard/bank-account/edit/:BankID'
				roles={rolesText('bank')}
				Component={BankAdd}
			/>
		</>
	)
}
