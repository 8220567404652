import { ADDRESS_UPDATE } from '../../../types'
import API from '../../../../utils/api'
import { navigate } from 'gatsby'
import { clearDeleteAddress } from './deleteAction'
import { clearCreateAddress } from './createAction'

export const resetAction = () => {
	return {
		type: `${ADDRESS_UPDATE}_RESET`,
	}
}

const loading = () => {
	return {
		type: `${ADDRESS_UPDATE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${ADDRESS_UPDATE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${ADDRESS_UPDATE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const loadingData = () => {
	return {
		type: `${ADDRESS_UPDATE}_LOAD_DATA`,
	}
}

const successGetData = (data) => {
	return {
		type: `${ADDRESS_UPDATE}_DATA`,
		payload: {
			...data,
		},
	}
}

export const clearUpdateAddress = () => {
	return {
		type: `${ADDRESS_UPDATE}_CLEAR`,
	}
}

export const updateAddress = (id, data) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			const response = await API({
				method: 'PUT',
				url: `/companies/address/${id}`,
				data: data,
			})

			if ('error_backend' in response.data) {
				dispatch(failure(response.data.error_backend.http_body))
			} else {
				dispatch(success(response.data))
				dispatch(clearDeleteAddress())
				dispatch(clearCreateAddress())
				navigate('/dashboard/company-address')
			}
		} catch (err) {
			dispatch(failure(err))
		}
	}
}

export const detailAddress = (id) => {
	return async (dispatch) => {
		dispatch(loadingData())
		try {
			const response = await API({
				method: 'GET',
				url: `/companies/address/${id}`,
			})

			dispatch(successGetData(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
