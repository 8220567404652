import React, { useState, useEffect } from 'react'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	Paper,
	TextField,
	Button,
	Container,
	CircularProgress,
	FormGroup,
	FormControlLabel,
	FormControl,
	Switch,
	Checkbox,
	InputAdornment,
	Tooltip,
	IconButton,
} from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Breadcrumbs from 'Components/breadcrumbs'
import { Styles } from './styles'
import schema from './schema'
import { navigate } from 'gatsby'
import Modal from 'Components/modal'
import { useSelector, useDispatch } from 'react-redux'
import { createBudget, updateBudget } from 'States/actions/Budget/createAction'
import { requestDepartmentList } from 'States/actions/Company/Department/listAction'
import PropTypes from 'prop-types'
import TagAutocomplete from '../../../components/tagAutocomplete/component'
import DatePickerRange from 'Components/DateRange'
import { format } from 'date-fns'
import { dateFormat, isNumberInput } from '../../../utils/globalUtils'
import { Info } from '@material-ui/icons/'
import ModalMessages from './modalMessage'
import {
	requestBudgetDetail,
	resetAction,
} from 'States/actions/Budget/detailAction'

const initDataBudget = {
	id: '',
	name: '',
	descriptions: '',
	date_start: '',
	date_stop: '',
	auto_non_active: 0,
	departemen: [],
	active: false,
	status: '',
	total: 0,
	total_used: 0,
	periode: '',
}

const FormBudget = ({ budgetID }) => {
	const classes = Styles()
	const dispatch = useDispatch()
	const pageName = 'Anggaran'
	const mode = budgetID == 0 ? 'new' : 'edit'
	const [showLoading, setLoading] = useState(false)
	const [, setError] = useState(false)
	const [field, setField] = useState(initDataBudget)
	const department = useSelector((state) => state.departmentListReducer)
	const [checkedTag, setCheckedTag] = useState(true)
	const [departemenInitial, setDepartemenIntial] = useState([])

	useEffect(() => {
		if (department.isLoading) {
			let queryDepartment = { limit: 200, filter: '{"status":"99"}' }
			dispatch(requestDepartmentList({ queryString: queryDepartment }))
		}
	}, [])

	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [range, setRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	])
	const [color, setColor] = useState('#a9acaf')
	const [placeHolder, setPlaceHolder] = useState('Rentang Tanggal')
	const [checked, setChecked] = useState(false)

	// reducer for budget
	const budgetDetail = useSelector((state) => state.budgetDetailReducer)
	const [successModal, setSuccessModal] = useState(false)
	const [modalStatus, setModalStatus] = useState('')
	const [approveLoading, setApproveLoading] = useState(false)

	const [breadcrumbsTambah] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Pengaturan Pembelian',
			link: '#',
		},
		{
			segment: pageName,
			link: '/dashboard/purchase-setting/budget',
		},
		{
			segment: `Tambah ${pageName}`,
			link: '#',
		},
	])

	const [breadcrubmbsUbah] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Pengaturan Pembelian',
			link: '#',
		},
		{
			segment: pageName,
			link: '/dashboard/purchase-setting/budget',
		},
		{
			segment: `Ubah ${pageName}`,
			link: '#',
		},
	])

	const numberOptions = {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}

	const getListDepartemenID = (data) => {
		let res = []
		data && data.length != 0 ? data.map((item) => res.push(item.id)) : null
		return res
	}

	const handleSubmitForm = (values) => {
		let depart = getListDepartemenID(values.departemen)
		let data = {
			name: values.name,
			descriptions: values.descriptions,
			startDate: startDate,
			endDate: endDate,
			checked: checked ? 1 : 0,
			total: parseInt(values.total),
			//If status as active send value 99 and if status non active send value 98
			status: values.active ? 99 : 98,
			departemen: depart,
		}
		setApproveLoading(true)
		setLoading(true)
		if (mode == 'edit') {
			//to do update data API edit budget
			updateBudget(data, budgetID)
				.then((response) => {
					if (response) {
						setApproveLoading(false)
						setSuccessModal(true)
						setLoading(false)
						if ('error_backend' in response.data) {
							setModalStatus('error')
						} else {
							setModalStatus('success')
						}
					}
				})
				.catch((err) => {
					setApproveLoading(false)
					setLoading(false)
					console.log(err)
					setSuccessModal(true)
					setModalStatus('error')
				})
		} else {
			createBudget(data)
				.then((response) => {
					if (response) {
						setApproveLoading(false)
						setSuccessModal(true)
						setLoading(false)
						if ('error_backend' in response.data) {
							setModalStatus('error')
						} else {
							setModalStatus('success')
						}
					}
				})
				.catch((err) => {
					console.log(err)
					setLoading(false)
					setApproveLoading(false)
					setSuccessModal(true)
					setModalStatus('error')
				})
		}
	}

	const handleChangeCheckBox = (event) => {
		setChecked(event.target.checked)
	}

	const handleSuccessModal = () => {
		navigate('/dashboard/purchase-setting/budget')
	}

	const getListDepart = (data) => {
		let res = []
		data && data.length != 0
			? data.map((item) => res.push(item.departments))
			: null
		return res
	}

	useEffect(() => {
		if (!budgetDetail.isError) {
			if (mode == 'edit') {
				setLoading(budgetDetail.isLoading)
				if (budgetDetail.data) {
					let data = field
					data.id = budgetDetail.data.id
					data.name = budgetDetail.data.name
					data.descriptions = budgetDetail.data.descriptions
					data.date_start = budgetDetail.data.date_start
					data.date_stop = budgetDetail.data.date_stop
					data.auto_non_active = budgetDetail.data.auto_non_active
					data.status = budgetDetail.data.status
					data.total = budgetDetail.data.total
					data.total_used = budgetDetail.data.total_used
					let tempDepart = getListDepart(budgetDetail.data.budget_departments) || []
					data.departemen = tempDepart
					setDepartemenIntial(tempDepart)
					setStartDate(dateFormat(data.date_start, 'yyyy-MM-dd'))
					setEndDate(dateFormat(data.date_stop, 'yyyy-MM-dd'))
					setColor('#a9acaf')
					setChecked(data.auto_non_active == 1 ? true : false)
					//If status as active send value 99 and if status non active send value 98
					data.active = data.status == 99 ? true : false
					setField(data)
				}
			}
		} else {
			if (mode == 'edit') {
				setError(budgetDetail.isError)
				setLoading(budgetDetail.isLoading)
			}
		}
	}, [budgetDetail])

	const handleOnChange = (item) => {
		const str = `${format(item.selection.startDate, 'yyyy-MM-dd')} ~ ${format(
			item.selection.endDate,
			'yyyy-MM-dd'
		)}`
		setPlaceHolder(str)
		setRange([item.selection])
		setColor('#007BFF')
		setStartDate(dateFormat(item.selection.startDate, 'yyyy-MM-dd'))
		setEndDate(dateFormat(item.selection.endDate, 'yyyy-MM-dd'))
	}

	const handleOnClear = () => {
		setPlaceHolder('Rentang Tanggal')
		setColor('#a9acaf')
		setRange([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		])
		setStartDate('')
		setEndDate('')
	}

	useEffect(() => {
		setLoading(false)
		if (mode == 'edit') {
			setField({ ...initDataBudget })
			dispatch(requestBudgetDetail(budgetID))
		}
		return () => {
			dispatch(resetAction())
		}
	}, [])

	const formikInitValue = () => {
		return {
			id: field ? field.id : '',
			name: field ? field.name : '',
			descriptions: field ? field.descriptions : '',
			total: field ? field.total : 0,
			departemen: field ? field.departemen : [],
			active: field ? field.active : true,
			periode: field ? field.periode : '',
		}
	}

	return (
		<DashboardLayout>
			<Container>
				<Modal open={showLoading} icon='none' disableBackdropClick>
					<Box my={3} alignContent='center'>
						<CircularProgress color='secondary' style={{ marginBottom: '24px' }} />
						{approveLoading && (
							<Typography>Menyimpan {pageName}, Mohon Tunggu</Typography>
						)}
						{budgetDetail.isLoading && (
							<Typography>Mengambil Data {pageName}, Mohon Tunggu</Typography>
						)}
					</Box>
				</Modal>
				<ModalMessages
					succesModal={successModal}
					modalStatus={modalStatus}
					handleSuccessCreate={() => handleSuccessModal()}
					onClose={() => {
						setSuccessModal(false)
					}}
					isUpdate={mode == 'edit' ? true : false}
				/>
				<Box>
					{mode == 'new' ? (
						<Breadcrumbs breadcrumbs={breadcrumbsTambah} />
					) : (
						<Breadcrumbs breadcrumbs={breadcrubmbsUbah} />
					)}
				</Box>
				<Box mt={2}>
					<Typography variant='h6'>
						{mode == 'new' ? 'Tambah' : 'Ubah'} {pageName}
					</Typography>
				</Box>
				<Box my={2}>
					<Formik
						validateOnChange={false}
						enableReinitialize
						initialValues={formikInitValue()}
						validationSchema={schema}
						onSubmit={(values) =>
							startDate && endDate ? handleSubmitForm(values) : null
						}
					>
						{({ errors, touched, values, setValues }) => (
							<Form method='post'>
								<Paper>
									<Box pt={1} pb={2} px={2}>
										<Grid container spacing={5}>
											{/* Left Column */}
											<Grid item xs={12}>
												<Grid container spacing={3}>
													<Grid item xs={9}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={3}>
																<Typography
																	variant='subtitle2'
																	className={`${classes.labelRequired} ${classes.padTop}`}
																>
																	Nama Anggaran
																</Typography>
															</Grid>
															<Grid item xs={9}>
																<Field
																	as={TextField}
																	variant='outlined'
																	color='primary'
																	placeholder='Masukkan Nama Anggaran'
																	margin='dense'
																	fullWidth
																	error={errors.name && touched.name}
																	name='name'
																	InputProps={{
																		classes: {
																			input: classes.styleInput,
																		},
																	}}
																	inputProps={{
																		maxLength: 50,
																	}}
																/>
																<ErrorMessage name='name'>
																	{(msg) => (
																		<Typography variant='caption' color='error'>
																			{msg}
																		</Typography>
																	)}
																</ErrorMessage>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={9}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={3}>
																<Typography
																	variant='subtitle2'
																	className={`${classes.labelRequired} ${classes.padTop}`}
																>
																	Deskripsi Anggaran
																</Typography>
															</Grid>
															<Grid item xs={9}>
																<Field
																	as={TextField}
																	variant='outlined'
																	color='primary'
																	placeholder='Masukkan Deskripsi Anggaran'
																	margin='dense'
																	fullWidth
																	error={errors.descriptions && touched.descriptions}
																	name='descriptions'
																	multiline={true}
																	rows={3}
																	inputProps={{
																		maxLength: 100,
																	}}
																	InputProps={{
																		classes: {
																			input: classes.styleInput,
																		},
																	}}
																/>
																<ErrorMessage name='descriptions'>
																	{(msg) => (
																		<Typography variant='caption' color='error'>
																			{msg}
																		</Typography>
																	)}
																</ErrorMessage>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={9}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={3}>
																<Typography
																	variant='subtitle2'
																	className={`${classes.labelRequired} ${classes.padTop}`}
																>
																	Periode Anggaran
																</Typography>
															</Grid>
															<Grid item xs={9} className={classes.gridStyleMargin}>
																<Grid item xs={7}>
																	<Field
																		as={DatePickerRange}
																		ranges={range}
																		color={color}
																		onSelectDate={(item) => handleOnChange(item)}
																		placeholder={placeHolder}
																		onClear={() => handleOnClear()}
																		error={errors.periode && touched.periode}
																		name='periode'
																		isBudget={true}
																		endDate={endDate}
																		startDate={startDate}
																	/>
																	<Typography variant='caption' color='error'>
																		{touched.periode
																			? startDate && endDate
																				? ''
																				: 'Wajib diisi'
																			: ''}
																	</Typography>
																</Grid>
																<Grid item xs={12}>
																	<FormControl
																		sx={{ m: 3 }}
																		component='fieldset'
																		variant='standard'
																	>
																		<FormGroup>
																			<FormControlLabel
																				classes={{
																					label: classes.formLabel,
																					root: classes.rootFormLabel,
																				}}
																				control={
																					<Checkbox
																						defaultChecked
																						color='primary'
																						inputProps={{ 'aria-label': 'primary checkbox' }}
																						size='small'
																						onChange={handleChangeCheckBox}
																						checked={checked}
																						className={classes.rootCheckBox}
																						icon={<span className={classes.icon} />}
																						checkedIcon={<span className={classes.checkedIcon} />}
																					/>
																				}
																				label='Otomatis nonaktifkan status anggaran jika telah melewati tanggal berakhir periode anggaran'
																			/>
																		</FormGroup>
																	</FormControl>
																</Grid>
															</Grid>
														</Grid>
													</Grid>

													{mode == 'edit' ? null : (
														<Grid item xs={9}>
															<Grid container alignItems='flex-start'>
																<Grid item xs={3}>
																	<Box className={classes.labelInput}>
																		<Typography
																			variant='subtitle2'
																			className={`${classes.labelRequired} ${classes.padTop}`}
																		>
																			Total Anggaran
																		</Typography>
																		<Tooltip
																			placement='top-start'
																			title='Perkiraan total nominal anggaran yang ditentukan untuk pembelian (tidak termasuk pajak) pada setiap transaksinya'
																			arrow
																			classes={{
																				arrow: classes.arrow,
																				tooltip: classes.tooltipContainer,
																			}}
																		>
																			<IconButton
																				size='small'
																				classes={{ root: classes.styleIconbutton }}
																			>
																				<Info fontSize='small' className={classes.smallICon} />
																			</IconButton>
																		</Tooltip>
																	</Box>
																</Grid>
																<Grid item xs={9}>
																	<Grid item xs={7}>
																		<Field
																			as={TextField}
																			variant='outlined'
																			color='primary'
																			margin='dense'
																			fullWidth
																			error={errors.total && touched.total}
																			name='total'
																			onChange={(event) => {
																				event.target.value == '' ||
																				isNumberInput(event.target.value)
																					? setValues({
																							...values,
																							total: event.target.value,
																					  })
																					: null
																			}}
																			InputProps={{
																				startAdornment: (
																					<InputAdornment
																						position='start'
																						variant='filled'
																						classes={{
																							root: classes.rootAdorment,
																						}}
																					>
																						<span style={{ color: 'black' }}>Rp</span>
																					</InputAdornment>
																				),
																				classes: {
																					adornedStart: classes.adornedStart,
																					input: classes.inputAnggaran,
																				},
																			}}
																		/>
																	</Grid>
																	<ErrorMessage name='total'>
																		{(msg) => (
																			<Typography variant='caption' color='error'>
																				{msg}
																			</Typography>
																		)}
																	</ErrorMessage>
																	<Grid item xs={12} className={classes.cardWarningBudget}>
																		<Typography className={`${classes.fontSize12} `}>
																			Nominal anggaran yang akan dikurangi ketika transaksi telah
																			diproses melewati tahap Quotation adalah sejumlah nilai
																			Subtotal (tidak termasuk pajak) transaksi tersebut.
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													)}

													<Grid item xs={9}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={3}>
																<Box className={classes.labelInput}>
																	<Typography
																		variant='subtitle2'
																		className={`${classes.labelRequired} ${classes.padTop}`}
																	>
																		Departemen
																	</Typography>
																	<Tooltip
																		placement='top-start'
																		title='Seluruh akun pemohon dibawah departemen yang dipilih akan diwajibkan menggunakan anggaran setiap memproses Quotation menjadi Purchase Request/ Purchase Order di Mbiz.co.id'
																		arrow
																		classes={{
																			arrow: classes.arrow,
																			tooltip: classes.tooltipContainer,
																		}}
																	>
																		<IconButton
																			size='small'
																			classes={{ root: classes.styleIconbutton }}
																		>
																			<Info fontSize='small' className={classes.smallICon} />
																		</IconButton>
																	</Tooltip>
																</Box>
															</Grid>
															<Grid item xs={9}>
																<Field
																	as={TagAutocomplete}
																	setMaxTag={20}
																	id='departemen'
																	name='departemen'
																	error={errors.departemen && touched.departemen}
																	initialSuggestion={department ? department.data?.items : []}
																	initialTag={
																		mode == 'edit' && departemenInitial.length != 0
																			? departemenInitial
																			: []
																	}
																	setParentTag={(val) => {
																		setValues({
																			...values,
																			departemen: val,
																		})
																	}}
																	checked={checkedTag}
																	isNeedFirstInitialTags={
																		mode == 'edit' && departemenInitial.length != 0 ? true : false
																	}
																	setParentChecked={setCheckedTag}
																	placeholderText='+ Tambah departemen'
																	classNames={{ root: classes.menuStyle }}
																/>
																<Typography variant='caption' color='error'>
																	{touched.departemen
																		? values.departemen.length != 0
																			? ''
																			: 'Wajib diisi'
																		: mode == 'edit' && values.departemen.length == 0
																		? 'Wajib diisi'
																		: ''}
																</Typography>
																<Grid
																	container
																	direction='row'
																	justifyContent='flex-end'
																	alignItems='flex-start'
																	item
																	xs={12}
																>
																	<span style={{ fontSize: 12 }}>
																		{`Total Departemen ${values.departemen.length}/20`}
																	</span>
																</Grid>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={9}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={3}>
																<Box className={classes.labelInput}>
																	<Typography
																		variant='subtitle2'
																		className={`${classes.labelRequired} ${classes.padTop}`}
																	>
																		Status
																	</Typography>
																	<Tooltip
																		placement='top-start'
																		title='Hanya anggaran yang aktif yang akan dapat dipilih untuk digunakan pada transaksi'
																		arrow
																		classes={{
																			arrow: classes.arrow,
																			tooltip: classes.tooltipContainer,
																		}}
																	>
																		<IconButton
																			size='small'
																			classes={{ root: classes.styleIconbutton }}
																		>
																			<Info fontSize='small' className={classes.smallICon} />
																		</IconButton>
																	</Tooltip>
																</Box>
															</Grid>
															<Grid item xs={9} className={classes.gridStyleMargin}>
																<Field name='active'>
																	{({ field }) => (
																		<FormControlLabel
																			control={
																				<Switch
																					name='active'
																					color='secondary'
																					{...field}
																					checked={values.active}
																				/>
																			}
																			label={values.active ? 'Aktif' : 'Tidak Aktif'}
																			labelPlacement='end'
																			classes={{
																				labelPlacementStart: classes.labelPlacementStart,
																				label: classes.fontSize14,
																			}}
																		/>
																	)}
																</Field>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Box>
								</Paper>

								{mode == 'edit' ? (
									<Paper>
										<Box pt={1} pb={2} px={2} mt={5}>
											<Grid container spacing={5}>
												{/* Left Column */}
												<Grid item xs={12}>
													<Grid container spacing={3}>
														<Grid item xs={9}>
															<Grid container alignItems='flex-start'>
																<Grid item xs={3}>
																	<Box className={classes.labelInput}>
																		<Typography
																			variant='subtitle2'
																			className={`${classes.labelRequired} ${classes.padTop}`}
																		>
																			Total Anggaran
																		</Typography>
																		<Tooltip
																			placement='top-start'
																			title='Perkiraan total nominal anggaran yang ditentukan untuk pembelian (tidak termasuk pajak) pada setiap transaksinya'
																			arrow
																			classes={{
																				arrow: classes.arrow,
																				tooltip: classes.tooltipContainer,
																			}}
																		>
																			<IconButton
																				size='small'
																				classes={{ root: classes.styleIconbutton }}
																			>
																				<Info fontSize='small' className={classes.smallICon} />
																			</IconButton>
																		</Tooltip>
																	</Box>
																</Grid>
																<Grid item xs={5}>
																	<Field
																		as={TextField}
																		variant='outlined'
																		color='primary'
																		margin='dense'
																		fullWidth
																		error={errors.total && touched.total}
																		name='total'
																		onChange={(event) => {
																			event.target.value == '' || isNumberInput(event.target.value)
																				? setValues({
																						...values,
																						total: event.target.value,
																				  })
																				: null
																		}}
																		InputProps={{
																			startAdornment: (
																				<InputAdornment
																					position='start'
																					variant='filled'
																					classes={{
																						root: classes.rootAdorment,
																					}}
																				>
																					<span style={{ color: 'black' }}>Rp</span>
																				</InputAdornment>
																			),
																			classes: {
																				adornedStart: classes.adornedStart,
																				input: classes.inputAnggaran,
																			},
																		}}
																	/>
																	<ErrorMessage name='total'>
																		{(msg) => (
																			<Typography variant='caption' color='error'>
																				{msg}
																			</Typography>
																		)}
																	</ErrorMessage>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={9}>
															<Grid container alignItems='flex-start'>
																<Grid item xs={3}>
																	<Box className={classes.labelInput}>
																		<Typography
																			variant='subtitle2'
																			className={`${classes.padTop} ${classes.fontSize14}`}
																		>
																			Sisa Anggaran
																		</Typography>
																	</Box>
																</Grid>
																<Grid item xs={9}>
																	<Typography
																		className={`${classes.padTop} ${classes.fontSize14}`}
																	>
																		Rp{' '}
																		{budgetDetail && budgetDetail.data
																			? (field.total - field.total_used).toLocaleString(
																					'en-US',
																					numberOptions
																			  )
																			: 0}
																	</Typography>
																	{budgetDetail && budgetDetail.data ? (
																		values.total != field.total ? (
																			<Typography
																				className={`${classes.colorAnggaranBaru} ${classes.fontSize14}`}
																			>
																				Sisa anggaran baru: Rp{' '}
																				{budgetDetail && budgetDetail.data
																					? (
																							field.total -
																							field.total_used +
																							(values.total - field.total)
																					  ).toLocaleString('en-US', numberOptions)
																					: 0}
																			</Typography>
																		) : null
																	) : null}
																</Grid>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={9} className={classes.cardWarningBudget}>
														<Typography className={`${classes.fontSize12} `}>
															Nominal anggaran yang akan dikurangi ketika transaksi telah
															diproses melewati tahap Quotation adalah sejumlah nilai Subtotal
															(tidak termasuk pajak) transaksi tersebut.
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										</Box>
									</Paper>
								) : null}
								<Box mt={2}>
									<Grid container justify='flex-end'>
										<Grid item>
											<Box>
												<Button
													size='medium'
													className={`${classes.buttonAction} ${classes.btnCancel}`}
													disable={approveLoading}
													onClick={(event) => {
														event.preventDefault()
														navigate('/dashboard/purchase-setting/budget', {
															replace: true,
														})
													}}
												>
													Batal
												</Button>
												<Button
													size='medium'
													type='submit'
													className={`${classes.buttonAction} ${classes.btnSave}`}
												>
													Simpan
													{approveLoading && (
														<CircularProgress size={14} style={{ marginLeft: '5px' }} />
													)}
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

FormBudget.propTypes = {
	budgetID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

FormBudget.defaultProps = {
	budgetID: 0,
}

export default FormBudget
