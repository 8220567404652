import React, { useState, useEffect } from 'react'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	Paper,
	TextField,
	Button,
	FormControl,
	FormGroup,
	FormControlLabel,
	Switch,
	Container,
	CircularProgress,
	Collapse,
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import Breadcrumbs from 'Components/breadcrumbs'
import Label from 'Components/label'
import { DepartmentAddStyles } from './styles'
import schema from './schema'
import { navigate } from 'gatsby'
import Modal from 'Components/modal'
import { useSelector, useDispatch } from 'react-redux'
import { createDepartment } from 'States/actions/Company/Department/createAction'
import {
	detailDepartment,
	updateDepartment,
	resetAction,
} from 'States/actions/Company/Department/updateAction'
import PropTypes from 'prop-types'
import { checkDepartment } from '../../../utils/globalUtils'

const DepartmentAdd = ({ departmentID }) => {
	const classes = DepartmentAddStyles()
	const dispatch = useDispatch()
	const mode = departmentID == 0 ? 'new' : 'edit'
	const [showLoading, setLoading] = useState(false)
	const [name, setName] = useState('')
	const [status, setStatus] = useState(false)
	const [error, setError] = useState(false)
	const newReducer = useSelector((state) => state.departmentCreateReducer)
	const editReducer = useSelector((state) => state.departmentUpdateReducer)
	const [isEditable, setEditable] = useState(true)
	const [disableButton, setDisableButton] = useState(false)

	const [breadcrumbsTambah] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Departemen',
			link: '/dashboard/department',
		},
		{
			segment: 'Tambah Departemen',
			link: '#',
		},
	])
	const [breadcrubmbsUbah] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Departemen',
			link: '/dashboard/department',
		},
		{
			segment: 'Ubah Departemen',
			link: '#',
		},
	])

	const handleSubmitForm = (values) => {
		let data = {}
		data.name = values.department_name
		data.status = values.department_status ? 99 : 98
		if (mode == 'edit') {
			data.id = departmentID
			dispatch(updateDepartment(data))
		} else {
			dispatch(createDepartment(data))
		}
	}

	const resetError = () => {
		setError(false)
		return ''
	}

	const handleValidateForm = async (validateForm, val) => {
		const error = await validateForm()
		const currentErrorKeys = Object.keys(error)
		if (currentErrorKeys.length) {
			currentErrorKeys.map((item) => {
				val.setFieldError(item)
				val.setFieldTouched(item)
			})
		} else {
			setLoading(true)
		}
	}

	useEffect(() => {
		if (!newReducer.isError && !editReducer.isError) {
			if (mode == 'edit') {
				setLoading(editReducer.isLoading || editReducer.isLoadingData)
				if (editReducer.data) {
					setName(editReducer.data.name)
					setError(editReducer.isError)
					let tempStatus = editReducer.data.status == 99 ? true : false
					setStatus(tempStatus)
				}
			} else {
				setError(newReducer.isError)
				setLoading(newReducer.isLoading)
			}
		} else {
			// console.log(editReducer.isError)
			if (mode == 'edit') {
				setError(editReducer.isError)
				setLoading(editReducer.isLoading || editReducer.isLoadingData)
			} else {
				setError(newReducer.isError)
				setLoading(newReducer.isLoading)
			}
		}
	}, [newReducer, editReducer])

	useEffect(() => {
		setLoading(false)
		if (mode == 'edit') {
			dispatch(detailDepartment(departmentID))
		}
		return () => {
			dispatch(resetAction())
		}
	}, [])

	const handleChangeStatus = async (status, setFieldValue) => {
		setFieldValue('department_status', !status)
		if (mode == 'edit' && status) {
			try {
				const res = await checkDepartment(departmentID)
				setEditable(res)
				setDisableButton(false)
			} catch (err) {
				setEditable(!isEditable)
				setDisableButton(true)
			}
		} else {
			setEditable(true)
			setDisableButton(false)
		}
	}

	return (
		<DashboardLayout>
			<Container>
				<Modal open={showLoading} icon='none' disableBackdropClick>
					<Box my={3} alignContent='center'>
						<CircularProgress color='secondary' style={{ marginBottom: '24px' }} />
						{(editReducer.isLoading || newReducer.isLoading) && (
							<Typography>Menyimpan Departement, Mohon Tunggu</Typography>
						)}
						{editReducer.isLoadingData && (
							<Typography>Mengambil Data Departement, Mohon Tunggu</Typography>
						)}
					</Box>
				</Modal>
				<Box>
					{mode == 'new' ? (
						<Breadcrumbs breadcrumbs={breadcrumbsTambah} />
					) : (
						<Breadcrumbs breadcrumbs={breadcrubmbsUbah} />
					)}
				</Box>
				<Box mt={2}>
					<Typography variant='h6'>
						{mode == 'new' ? 'Tambah' : 'Ubah'} Departemen
					</Typography>
				</Box>
				<Box mt={2}>
					<Formik
						enableReinitialize
						initialValues={{ department_name: name, department_status: status }}
						validationSchema={schema}
						onSubmit={(values) => handleSubmitForm(values)}
					>
						{({
							errors,
							touched,
							values,
							handleChange,
							validateForm,
							setFieldValue,
							...props
						}) => (
							<Form method='post'>
								<Paper>
									<Box py={1} px={2}>
										<Grid container>
											<Grid container item alignItems='center' xs={3}>
												<Typography variant='subtitle1' className={classes.labelRequired}>
													Nama Departemen
												</Typography>
											</Grid>
											<Grid container item alignItems='center' xs={6}>
												<TextField
													variant='outlined'
													color='primary'
													placeholder='Nama Departemen'
													margin='dense'
													fullWidth
													inputProps={{ maxLength: 100 }}
													error={
														error || (errors.department_name && touched.department_name)
													}
													onChange={(e) => {
														handleChange(e)
														resetError()
													}}
													value={values.department_name}
													name='department_name'
												/>
												{errors.department_name && touched.department_name && (
													<Box>
														<Typography color='error' variant='caption'>
															{errors.department_name}
														</Typography>
													</Box>
												)}
												{error && (
													<Box>
														<Typography color='error' variant='caption'>
															Nama Departemen sudah terbuat
														</Typography>
													</Box>
												)}
											</Grid>
										</Grid>
										<Grid container>
											<Grid container item alignItems='center' xs={3}>
												<Typography variant='subtitle1'>Status</Typography>
											</Grid>
											<Grid container item alignItems='center' xs={6}>
												<FormControl component='fieldset'>
													<FormGroup>
														<FormControlLabel
															control={
																<Switch
																	name='department_status'
																	value={99}
																	color='secondary'
																	error={errors.department_status && touched.department_status}
																	onChange={() =>
																		handleChangeStatus(values.department_status, setFieldValue)
																	}
																	checked={values.department_status}
																/>
															}
														/>
													</FormGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Collapse in={!isEditable}>
											<Box mb={3} mt={1}>
												<Label type='error'>
													<Typography variant='body1'>
														Departemen tidak dapat dinonaktifkan karena terdapat akun
														berstatus aktif sebagai Pemohon atau Penyetuju pada manajemen
														Persetujuan yang menggunakan departemen ini.
													</Typography>
												</Label>
											</Box>
										</Collapse>
									</Box>
								</Paper>
								<Box mt={2}>
									<Grid container justify='flex-end'>
										<Grid item>
											<Box>
												<Button
													size='medium'
													className={`${classes.buttonAction} ${classes.btnCancel}`}
													onClick={(event) => {
														event.preventDefault()
														navigate('/dashboard/department', {
															replace: true,
														})
													}}
												>
													Batal
												</Button>
												<Button
													size='medium'
													type='submit'
													disabled={disableButton}
													className={`${classes.buttonAction} ${classes.btnSave}`}
													onClick={() => handleValidateForm(validateForm, props)}
													classes={{
														disabled: classes.btnDisable,
													}}
												>
													Simpan
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

DepartmentAdd.propTypes = {
	departmentID: PropTypes.string,
}

DepartmentAdd.defaultProps = {
	departmentID: '0',
}

export default DepartmentAdd
