import { BILLING_ADDRESS, SHIPPING_ADDRESS, SET_DEFAULT } from '../types'
import API from '../../utils/api'

const loading = (type) => {
	return {
		type: `${type}_PENDING`,
	}
}

const failure = (type, error) => {
	return {
		type: `${type}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (type, data) => {
	return {
		type: `${type}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const setDefault = (addressType, data) => {
	return {
		type: `${SET_DEFAULT}_${addressType}`,
		payload: {
			data: data.data.result[0],
		},
	}
}

export const setAddress = (addressType, data) => {
	return {
		type: `${SET_DEFAULT}_${addressType}`,
		payload: {
			data,
		},
	}
}

export const receiverPhone = (data) => {
	return {
		type: 'SET_RECEIVER_PHONE',
		payload: {
			data,
		},
	}
}

const mainAction = (
	type,
	addressType,
	page = '',
	name = '',
	region = '',
	isDefault,
	limit = 5
) => {
	return async (dispatch) => {
		const requestAddressType = addressType == 'billing' ? 'invoice' : 'delivery'
		dispatch(loading(type))
		try {
			const response = await API({
				method: 'GET',
				url: `/companies/address/?type=${requestAddressType}&ts=${new Date().getTime()}&active=true&page=${page}&page_size=${limit}&name=${name}&region=${region}`,
			})
			if (response.data.data.result.length && isDefault) {
				dispatch(success(type, response.data))
				dispatch(setDefault(addressType, response.data))
			} else if (response.data.data.result.length) {
				dispatch(success(type, response.data))
			} else {
				dispatch(success(type, response.data))
			}
		} catch (err) {
			failure(type, err)
		}
	}
}

export const requestAddress = (
	type,
	isDefault,
	page,
	name,
	region,
	limit = 5
) => {
	switch (type) {
		case 'billing':
			return mainAction(
				BILLING_ADDRESS,
				'billing',
				page,
				name,
				region,
				isDefault,
				limit
			)

		case 'shipping':
			return mainAction(
				SHIPPING_ADDRESS,
				'shipping',
				page,
				name,
				region,
				isDefault,
				limit
			)
	}
}
