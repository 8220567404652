import { DEPARTMENT_CREATE } from '../../../types'
import API from '../../../../utils/api'
import { navigate } from 'gatsby'
// import { newUriAPI } from '../../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${DEPARTMENT_CREATE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${DEPARTMENT_CREATE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${DEPARTMENT_CREATE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const clearCreateDepartment = () => {
	return {
		type: `${DEPARTMENT_CREATE}_CLEAR`,
	}
}

export const createDepartment = ({ name, status }) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			const response = await API({
				method: 'POST',
				url: '/companies/departments/create',
				data: { name, status },
			})

			if ('error_backend' in response.data) {
				dispatch(failure(response.data.error_backend.http_body))
			} else {
				dispatch(success(response.data))
				navigate('/dashboard/department')
			}
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
