/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from 'react'
import styles from '../Transactions/styles'
import { useSelector, useDispatch } from 'react-redux'
import Beadcrums from 'Components/breadcrumbs'
import {
	Box,
	Container,
	Typography,
	Button,
	Grid,
	Paper,
	Tooltip,
	CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import DashboardLayout from 'Components/dashboardLayout'
import * as qs from 'query-string'
import {
	breadcrums,
	databuttonStatus,
	changeData,
	findSelectedColumn,
} from './helper/generaHelper'
import { chunkSizeArray, newUri } from '../../../utils/globalUtils'
import GetAppIcon from '@material-ui/icons/GetApp'
import { fileExport } from '../../../utils/exports'
import { getReportFile } from 'States/actions/Transactions/Report/downloadFileAction'
import TableData from 'Components/tableData'
import { loadDataColumnTable } from '../Transactions/helper/loadData'
import { loadNameFile } from './helper/loadData'
import { Multiple } from '../../../components/dropdown'
import ButtonFilter from '../../../components/buttonFilter'

const UnduhDataPerusahaan = (props) => {
	const classes = styles()

	const queryString = qs.parse(props.location.search)
	const dispatch = useDispatch()

	const reportFile = useSelector((state) => state.reportFile)

	// local state
	const perPage = 10
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [query, setQuery] = useState(
		queryString.type ? queryString.type : 'bank-account'
	)
	const [dataDownload, setDataDownload] = useState([])
	const [colSelected, setColSelected] = useState(
		queryString.type && queryString.columns
			? findSelectedColumn(queryString.type, queryString.columns)
			: []
	)
	const [columns, setColumns] = useState([])
	const [selectedAll, setSelectedAll] = useState(false)
	const [indeterminate, setIndeterminate] = useState(false)
	const [dataFile, setDataFile] = useState([])
	const [headerData, setHeaderData] = useState([])
	const [renderData, setRenderData] = useState(false)
	// end of local state

	let myRoles = useSelector((state) => state.auth.data.data.user_roles)

	useEffect(() => {
		let _query = newUri(queryString)
		propsNavigate(_query)
	}, [])

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	queryString.type = query
	queryString.per_page = perPage
	queryString.page = page
	queryString.columns = colSelected.length
		? colSelected.map((val) => val?.index).join(',')
		: ''

	const funcSetButton = (query) => {
		queryString.type = query
		// reset filter
		queryString.per_page = perPage
		queryString.page = 1
		setPage(1)
		setQuery(query)
		setColSelected([])
		setSelectedAll(false)
		setIndeterminate(false)
		setDataFile([])
		setHeaderData([])
		setRenderData(false)
		let _query = newUri(queryString)
		propsNavigate(_query)
	}

	const generateButton = useCallback(
		(items) => {
			return items && items.length != 0
				? items.map((row, index) => {
						let variant = row.query === query
						return (
							<ButtonFilter
								variant={variant}
								onClick={() => {
									funcSetButton(row.query)
								}}
								key={index}
								status={row.status}
								customStatus={true}
							/>
						)
				  })
				: null
		},
		[query]
	)

	const handleChange = (event) => {
		setColSelected(event.target.value)
	}

	const handleSelecAll = (_selectAll) => {
		if (!_selectAll) {
			setSelectedAll(true)
			setColSelected(columns)
		} else {
			setColSelected([])
			setSelectedAll(false)
		}
	}

	// const renderValue = (values) => {
	// 	if (values.length) {
	// 		return (
	// 			<Typography style={{ color: '#007BFF' }}>
	// 				{values.length} Terpilih
	// 			</Typography>
	// 		)
	// 	}
	// 	return (
	// 		<Typography variant='body2' style={{ color: '#a9acaf' }}>
	// 			Pilih Kolom
	// 		</Typography>
	// 	)
	// }

	useEffect(() => {
		if (query) {
			let { _query, temp } = changeData(query, myRoles)
			setColumns(temp)
			setQuery(_query)
			setColSelected(
				queryString.type && queryString.columns
					? findSelectedColumn(queryString.type, queryString.columns)
					: []
			)
		}
	}, [query])

	useEffect(() => {
		if (columns.length) {
			if (selectedAll && colSelected.length < columns.length) {
				setIndeterminate(true)
				setSelectedAll(false)
			}
			if (indeterminate && colSelected.length == columns.length) {
				setIndeterminate(false)
				setSelectedAll(true)
			}
			if (colSelected.length == columns.length) {
				setSelectedAll(true)
			}
			queryString.columns = colSelected.length
				? colSelected.map((val) => val?.index).join(',')
				: ''
			let _query = newUri(queryString)
			propsNavigate(_query)
		}
	}, [colSelected, columns, selectedAll])

	const handleLoadData = () => {
		setPage(1)
		queryString.page = 1
		let urlData = '/report-management/company/' + query
		setRenderData(true)
		dispatch(getReportFile(query, null, urlData))
		let _query = newUri(queryString)
		propsNavigate(_query)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		let _query = newUri(queryString)
		propsNavigate(_query)
	}

	// hooks for set default state
	useEffect(() => {
		// success
		if (reportFile.data && reportFile.data.result) {
			let result = reportFile.data.result
			if (result) {
				let header = loadDataColumnTable(colSelected)
				let temp = chunkSizeArray(result, 10)
				setDataFile(temp)
				setHeaderData(header)
				setDataDownload(result)
			}
		} else {
			setDataFile([])
			setDataDownload([])
			setHeaderData([])
		}
	}, [reportFile])

	const exportData = (typeFile) => {
		let namaFile = loadNameFile(query)
		fileExport(headerData, namaFile, dataDownload, typeFile)
	}

	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box>
					<Beadcrums breadcrumbs={breadcrums} />
				</Box>
				<Box className={'searchContainer'}>
					<Typography variant='h6'>Unduh Data Perusahaan</Typography>
					<br />

					<Grid container spacing={3}>
						<Grid item xs={9}>
							{generateButton(databuttonStatus)}
						</Grid>
					</Grid>
				</Box>
				<Box mt={1}>
					<Grid container spacing={3} direction='row'>
						<Grid item xs={3}>
							<Multiple
								colSelected={colSelected}
								selectedAll={selectedAll}
								handleSelecAll={handleSelecAll}
								handleChange={handleChange}
								indeterminat={indeterminate}
								columns={columns}
							/>
						</Grid>
						<Grid item xs={6}>
							<Tooltip
								classes={{ tooltip: classes.tooltip }}
								title='Mohon lakukan pemilihan kolom terlebih dahulu'
								arrow={true}
								placement='right'
								disableHoverListener={colSelected.length != 0 ? true : false}
							>
								<span>
									<Button
										variant='contained'
										color='secondary'
										size='small'
										onClick={() => {
											handleLoadData()
										}}
										disabled={
											colSelected.length != 0 && !reportFile.isLoading ? false : true
										}
										className={classes.buttonStyle}
									>
										{reportFile.isLoading ? <CircularProgress size={15} /> : 'Muat Data'}
									</Button>
								</span>
							</Tooltip>
						</Grid>

						{headerData && headerData.length != 0 ? (
							<Grid
								item
								xs={3}
								container
								justify='flex-end'
								alignItems='flex-start'
								style={{ marginTop: -30 }}
							>
								<div>
									<Typography variant='subtitle1'>Unduh Data Sebagai</Typography>
								</div>
								<div
									style={{
										flexDirection: 'row',
									}}
								>
									<Button
										variant='contained'
										color='secondary'
										className={classes.buttonUnduh}
										size='small'
										startIcon={<GetAppIcon />}
										onClick={() => {
											exportData('.csv')
										}}
									>
										.csv
									</Button>
									<Button
										variant='contained'
										color='secondary'
										className={classes.buttonUnduh}
										size='small'
										startIcon={<GetAppIcon />}
										onClick={() => {
											exportData('.xls')
										}}
									>
										.xls
									</Button>
								</div>
							</Grid>
						) : null}
					</Grid>
				</Box>
				{renderData ? (
					<Box>
						<TableData
							perPage={perPage}
							// limitChange={handlePerRowsChange}
							columns={headerData}
							isLoading={reportFile.isLoading}
							pagination={
								!reportFile.isLoading && reportFile.data
									? reportFile.data.pagination
									: false
							}
							data={dataFile[page - 1]}
							name={'report'}
							paginationPageChange={handlePageChange}
							pageNow={parseInt(queryString.page)}
							// limitValue={limitValue}
							isReport={true}
							totalRowData={dataDownload ? dataDownload.length : 1}
						/>
					</Box>
				) : (
					<Paper variant='outlined' className={classes.boxSpacing}>
						<Box
							height='100%'
							display='flex'
							width='100%'
							alignItems='center'
							justifyContent='center'
						>
							<Typography variant='body1'>Belum Ada Data yang Dimuat</Typography>
						</Box>
					</Paper>
				)}
			</Container>
		</DashboardLayout>
	)
}

UnduhDataPerusahaan.propTypes = {
	navigate: PropTypes.func,
	location: PropTypes.any,
}

export default React.memo(UnduhDataPerusahaan)
