import { makeStyles } from '@material-ui/core/styles'

export const CompanyprofileStyle = makeStyles((theme) => ({
	container: {
		overflow: 'hidden',
	},
	gridSearch: {
		marginTop: '32px',
		marginBottom: '15px',
	},
	customField: {
		width: '275px',
	},
	boxSpacing: {
		marginTop: '15px',
	},
	buttonAdd: {
		backgroundColor: theme.palette.warning.dark,
	},
	bcheckBox: {
		color: theme.palette.primary.main,
	},
	image: {
		width: 180,
		height: 180,
	},
	docImage: {
		width: 100,
		height: 100,
	},
	customButton: {
		fontSize: '12px',
	},
	buttonNegative: {
		backgroundColor: theme.palette.error.dark,
		color: theme.palette.common.white,
	},
	customTooltip: {
		position: 'absolute',
		marginTop: '3px',
		padding: '0',
		minWidth: '0',
	},
	customIcon: {
		fontSize: '14px',
		marginLeft: '10px',
	},
	formLabel: {
		display: 'flex',
		alignItems: 'top',
		margin: '5px 0',
	},
	contactField: {
		marginTop: 15,
	},
	wordBreak: {
		wordBreak: 'break-all',
	},
	lineHeight: {
		lineHeight: 1.75,
	},
	labelRequired: {
		position: 'relative',
		'&:after': {
			content: '"*"',
			position: 'absolute',
			color: theme.palette.error.dark,
		},
	},
	textDialog: {
		margin: theme.spacing(2, 0),
	},
	marginTB1: {
		margin: theme.spacing(1, 0),
	},
	ellipsisText: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	link: {
		cursor: 'pointer',
	},
}))
