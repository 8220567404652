import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	Modal,
	Fade,
	Paper,
	Backdrop,
	Box,
	Typography,
	Divider,
	Button,
	Grid,
	Table,
	TableCell,
	TableHead,
	TableBody,
	TableRow,
	CircularProgress,
	Tooltip,
} from '@material-ui/core'
import { dateFormatFromOdoo } from '../../../../utils/globalUtils'
import PropTypes from 'prop-types'
import styles from './styles'
import TrackingLine from '../../../../components/trackingLine'
import { NoData } from '../../../../components/svgs'
import { requestShippingTracking } from 'States/actions/Transactions/PurchaseOrder/trackingAction'
import { statusText } from '../../../../assets/status'

const ModalDetailDN = (props) => {
	const classes = styles()
	const dispatch = useDispatch()
	const shippingTracking = useSelector((state) => state.trackingReducer)

	useEffect(() => {
		if (props.open)
			dispatch(requestShippingTracking(!props.data ? null : props.data.awb_no))
	}, [props.open])

	const handleRefreshTracking = (noAWB) => {
		dispatch(requestShippingTracking(noAWB))
	}

	return (
		<Modal
			className={classes.modal}
			BackdropComponent={Backdrop}
			open={props.open}
			BackdropProps={{
				timeout: 500,
			}}
			onClose={props.onClose}
			{...props}
		>
			<Fade
				in={props.open}
				style={{
					width:
						props.data &&
						props.data.state != 'assigned' &&
						props.data.shippingProvider.name == 'SAP'
							? '100%'
							: 720,
					maxWidth: '90%',
				}}
			>
				<Paper style={{ overflow: 'hidden' }}>
					<Box
						display='flex'
						alignItems='stretch'
						className={classes.modalContainer}
					>
						<Box
							display='flex'
							flexDirection='column'
							className={classes.modalContent}
							width={
								props.data &&
								props.data.state != 'assigned' &&
								props.data.shippingProvider.name == 'SAP'
									? '65%'
									: '100%'
							}
							justifyContent='space-between'
							style={{ paddingRight: 0 }}
						>
							<Box
								display='flex'
								flexDirection='column'
								flexGrow={1}
								flexShrink={1}
								className={classes.detailShipping}
							>
								<Box mr={3}>
									<Typography variant='body1'>
										<strong>
											Rincian Surat Jalan {!props.data ? null : props.data.dnNumber}
										</strong>
									</Typography>
									<Divider className={classes.divider} />
								</Box>

								<Box mt={2}>
									<Grid container alignItems='center'>
										<Grid item sm={3}>
											<Typography variant='body2'>
												<strong>Nomor PO</strong>
											</Typography>
										</Grid>
										<Grid item sm={3}>
											{!props.data
												? null
												: props.data.poNumber
												? props.data.poNumber
												: '-'}
										</Grid>
										<Grid item sm={3}>
											<Typography variant='body2'>
												<strong>Ekspedisi Pengiriman</strong>
											</Typography>
										</Grid>
										<Grid item sm={3}>
											{!props.data
												? null
												: props.data.shippingProvider?.name
												? props.data.shippingProvider.name
												: '-'}
										</Grid>
									</Grid>
								</Box>
								<Box mt={2}>
									<Grid container alignItems='center'>
										<Grid item sm={3}>
											<Typography variant='body2'>
												<strong>No. Surat Jalan Mbiz</strong>
											</Typography>
										</Grid>
										<Grid item sm={3}>
											{!props.data
												? null
												: props.data.mbiz_delivery_no
												? props.data.mbiz_delivery_no
												: '-'}
										</Grid>
										<Grid item sm={3}>
											<Typography variant='body2'>
												<strong>No. Resi</strong>
											</Typography>
										</Grid>
										<Grid item sm={3}>
											{!props.data ? null : props.data.awb_no ? props.data.awb_no : '-'}
										</Grid>
									</Grid>
								</Box>
								<Box mt={2}>
									<Grid container alignItems='center'>
										<Grid item sm={3}>
											<Typography variant='body2'>
												<strong>Status</strong>
											</Typography>
										</Grid>
										<Grid item sm={3}>
											{!props.data
												? null
												: props.data.state
												? statusText(props.data.state)
												: '-'}
										</Grid>
										<Grid item sm={3}>
											<Typography variant='body2'>
												<strong>Tanggal Pesanan Diterima</strong>
											</Typography>
										</Grid>
										<Grid item sm={3}>
											{!props.data
												? null
												: props.data.receivedDate
												? dateFormatFromOdoo(props.data.receivedDate, 'dd MMMM yyyy')
												: '-'}
										</Grid>
									</Grid>
								</Box>
								<Box mt={2}>
									<Grid container alignItems='center'>
										<Grid item sm={3}>
											<Typography variant='body2'>
												<strong>Tanggal Pesanan Dikirim</strong>
											</Typography>
										</Grid>
										<Grid item sm={3}>
											<Box className={classes.addressValue}>
												<Typography variant='body2'>
													{!props.data
														? null
														: props.data.date_done
														? dateFormatFromOdoo(props.data.date_done, 'dd MMMM yyyy')
														: '-'}
												</Typography>
											</Box>
										</Grid>
										<Grid item sm={3}>
											<Typography variant='body2'>
												<strong>Penerima</strong>
											</Typography>
										</Grid>
										<Grid item sm={3}>
											<Box className={classes.addressValue}>
												{props.data && props.data.receiver_name ? (
													props.data.receiver_name.length > 20 ? (
														<Tooltip
															placement='bottom'
															title={props.data.receiver_name}
															classes={{
																tooltip: classes.tooltipContainerShipping,
															}}
															disableHoverListener={false}
														>
															<Typography variant='body2'>{`${props.data.receiver_name.slice(
																0,
																20
															)}...`}</Typography>
														</Tooltip>
													) : (
														<Typography variant='body2'>
															{props.data.receiver_name}
														</Typography>
													)
												) : (
													<Typography variant='body2'>-</Typography>
												)}
											</Box>
										</Grid>
									</Grid>
								</Box>

								<Paper
									elevation={0}
									className={classes.customTable}
									style={{ minHeight: 100, maxHeight: '100%', paddingRight: 20 }}
								>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell align='left' style={{ paddingLeft: 0 }}>
													<strong>No</strong>
												</TableCell>
												<TableCell>
													<strong>Produk</strong>
												</TableCell>
												<TableCell align='right' style={{ whiteSpace: 'nowrap' }}>
													<strong>Pesanan Dikirim</strong>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{!props.data
												? null
												: props.data.move_ids_without_package.map((item, index) => (
														<TableRow key={index}>
															<TableCell
																style={{ verticalAlign: 'top', paddingLeft: 0 }}
																align='left'
															>
																<Typography variant='body2'>{index + 1}</Typography>
															</TableCell>
															<TableCell style={{ verticalAlign: 'top' }}>
																<Typography variant='body2'>{item.product_sku_no}</Typography>
																<Typography variant='body2'>{item.name}</Typography>
															</TableCell>
															<TableCell style={{ verticalAlign: 'top' }} align='right'>
																<Typography variant='body2'>{item.quantity_done}</Typography>
															</TableCell>
														</TableRow>
												  ))}
										</TableBody>
									</Table>
								</Paper>
							</Box>

							<Box className={classes.containerButton} mt={3} mr={3}>
								<Button
									onClick={props.onClose}
									variant='contained'
									size='small'
									color='secondary'
								>
									Tutup
								</Button>
							</Box>
						</Box>
						{props.data &&
						props.data.state != 'assigned' &&
						props.data.shippingProvider.name == 'SAP' ? (
							<Box
								display='flex'
								flexDirection='column'
								className={classes.modalContent}
								style={{ backgroundColor: '#f1f4f9' }}
								width='45%'
							>
								<Typography variant='body1' align='center'>
									<strong>Lacak Pengiriman</strong>
								</Typography>
								<Typography variant='body1' align='center' mt={6}>
									<strong>No. AWB {!props.data ? null : props.data.awb_no}</strong>
								</Typography>
								<Typography variant='body2' align='center' mt={6}>
									Status : {!props.data ? null : statusText(props.data.state)}
								</Typography>

								<Paper elevation={0} className={classes.trackingLine}>
									{shippingTracking.isLoading ? (
										<Box
											display='flex'
											flexDirection='column'
											alignItems='center'
											px={5}
											py={5}
										>
											<CircularProgress />
										</Box>
									) : shippingTracking.data && shippingTracking.data.length > 0 ? (
										<TrackingLine data={shippingTracking.data} />
									) : (
										<Box
											display='flex'
											flexDirection='column'
											alignItems='center'
											px={5}
											py={3}
										>
											<NoData />
											<Box my={3}>
												<Typography variant='body2' align='center'>
													Nomor Referensi / AWB tidak ditemukan. Silakan tekan tombol{' '}
													<strong>Refresh</strong> untuk memperbarui data.
												</Typography>
											</Box>
											<Box display='flex' justifyContent='end'>
												<Button
													onClick={() =>
														handleRefreshTracking(!props.data ? null : props.data.awb_no)
													}
													variant='outlined'
													size='small'
													color='secondary'
												>
													Refresh
												</Button>
											</Box>
										</Box>
									)}
								</Paper>
							</Box>
						) : (
							''
						)}
					</Box>
				</Paper>
			</Fade>
		</Modal>
	)
}

ModalDetailDN.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	data: PropTypes.object,
}

export default ModalDetailDN
