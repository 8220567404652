import { makeStyles } from '@material-ui/core/styles'

export const PurchaseRequestStyles = makeStyles((theme) => ({
	buttonSearch: {
		padding: '4.5px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: theme.shape.borderRadius,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	gridSearch: {
		width: '100%',
	},
	buttonExport: {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
	},
	iconAction: {
		fontSize: '22px',
	},
	buttonDetail: {
		padding: theme.spacing(0.5, 1),
		minWidth: 'initial',
	},
	btnEdit: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnDelete: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	container: {
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		flex: 1,
	},
}))
