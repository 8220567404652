export const statusText = (state) => {
	switch (state) {
		case 'draft':
			return 'draft'
		case 'proforma':
			return 'Proforma'
		case 'paid':
			return 'Telah Dibayar'
		case 'not_paid':
			return 'Belum Dibayar'
		case 'paydraft':
			return 'Menunggu Konfirmasi'
		case 'PR3':
		case 'PR6':
		case 'PR7':
		case 'paycancelled':
			return 'Ditolak'
		case 'PR2':
			return 'Disetujui'
		case 'PR0':
		case 'mou_saleorder_draft':
			return 'Dalam Proses'
		case 'payvalidated':
		case 'payposted':
			return 'Diterima'
		case 'all':
		case 'all_status':
			return 'Semua Status'
		case 'all-step':
			return 'Semua Tahap'
		case 'all_type':
			return 'Semua Tipe'
		case 'sent':
			return 'Quotation'
		case 'rfq':
			return 'RFQ'
		case 'sale':
		case 'sale_done':
			return 'Purchase Order'
		case 'revised':
			return 'Revisi Penawaran'
		case 'revised_invoice':
		case 'payrevised':
		case 'payrevised_payment':
			return 'Direvisi'
		case 'done':
			return 'Dikirim'
		case 'assigned':
			return 'Diproses'
		case 'received':
			return 'Diterima'
		case 'ready':
			return 'Diproses'
		case 'waiting':
			return '-'
		case 'cancelled':
		case 'cancel':
			return 'Dibatalkan'
		case 'expired':
		case 'PR4':
		case '4':
			return 'Kedaluwarsa'
		case 'btn99':
		case '99':
		case 'active':
		case 'true':
		case 'btntrue':
		case 'posted':
			return 'Aktif'
		case 'btn98':
		case '98':
		case 'false':
		case 'btnfalse':
		case 'nonactive':
			return 'Tidak Aktif'
		case 'MOUActive':
			return 'Aktif'
		case 'MOUInactive':
		case 'inactive':
			return 'Tidak Aktif'
		case 'MOUNeedConfirm':
		case 'confirm':
			return 'Butuh Persetujuan'
		case 'MOUDone':
			return 'Selesai'
		case '0':
			return 'Menunggu'
		case '2':
			return 'Menyetujui'
		case '3':
			return 'Menolak'
		case 'PR5':
		case '5':
			return 'Dilewati'
		case 'PR1':
			return 'Butuh Persetujuan'
		case 'mou_saleorder_created':
			return 'PO Terbuat'
		case 'mou_saleorder_cancelled':
			return 'PO Dibatalkan'
		default:
			return '-'
	}
}

export const statusStyle = (state) => {
	switch (state) {
		case 'draft':
		case 'paycancelled':
		case 'cancelled':
		case 'cancel':
		case 'PR3':
		case 'PR6':
		case 'PR7':
		case 'false':
		case 'inactive':
		case 'MOUInactive':
		case '3':
		case 'mou_saleorder_cancelled':
			return 'red'
		case 'proforma':
			return 'lightBlue'
		case 'revised_invoice':
		case 'payrevised':
		case 'payrevised_payment':
			return 'lightRed'
		case 'posted':
		case 'quotation':
		case 'sent':
		case 'PR0':
		case '99':
		case 'true':
		case 'MOUActive':
		case 'active':
			return 'blue'
		case 'MOUDone':
		case 'payvalidated':
		case 'payposted':
		case 'paid':
		case 'sale':
		case 'sale_done':
		case 'PR2':
		case 'mou_saleorder_created':
		case '2':
			return 'green'
		case 'paydraft':
		case 'not_paid':
		case 'revised':
		case 'rfq':
		case 'MOUNeedConfirm':
		case 'confirm':
		case 'mou_saleorder_draft':
		case '0':
			return 'yellow'
		case 'all':
		case 'all-step':
		case 'all_status':
			return 'darkBlue'
		case 'PR5':
		case '5':
			return 'darkerBlue'
		case 'expired':
		case 'PR4':
		case '4':
		case '98':
			return 'grey'
		case 'btn99':
		case 'btn98':
		case 'btntrue':
		case 'btnfalse':
			return 'white'
		case 'PR1':
			return 'lightOrange'
		default:
			return 'secondary'
	}
}
