import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Divider,
	TextField,
	Button,
	ButtonBase,
	Tooltip,
	Grid,
	Checkbox,
} from '@material-ui/core'
import {
	approvePR,
	requestPRDetail,
} from 'States/actions/Transactions/PurchaseRequest/detail'
import PropTypes from 'prop-types'
import { PurchaseRequestDetailStyles } from './styles'
import UploadIcon from '../../../../assets/images/icons/upload.png'
import HelpIcon from '@material-ui/icons/Help'
import WarningIcon from '@material-ui/icons/Warning'
import { validateUpload, removeItemArray } from '../../../../utils/globalUtils'
import {
	type,
	validateSubmit,
} from '../../PurchaseRequest/detail/helper/helper'
import UploadFile from '../../PurchaseRequest/detail/helper/uploadFile'
import API from '../../../../utils/api'
import axios from 'axios'
import ModalMessages from './modalMessage'
import { navigate } from '@reach/router'

let CancelToken = axios.CancelToken
let axiosToken = CancelToken.source()
import { createArrayContactId } from './helper/helper'
import TagAutocomplete from '../../../../components/tagAutocomplete/component'

const Component = (props) => {
	const prDetail = useSelector((state) => state.PRDetail)
	const classes = PurchaseRequestDetailStyles()
	const dispatch = useDispatch()
	const inputRef = useRef([])
	const sendEmailRef = useRef([])

	const usersAccount = useSelector((state) => state.UsersAccountListReducer)
	const userProfile = useSelector((state) => state.userProfileReducer)

	const [note, setNote] = useState('')
	const [noRef, setNoRef] = useState('')
	const [fileUpload, setFileUpload] = useState([])
	const [progressLoad, setPorgressLoad] = useState([0, 0, 0, 0, 0])
	const [progressTotal, setPorgressTotal] = useState([0, 0, 0, 0, 0])
	const [docFile, setDocFile] = useState([])
	const [errorFile, setErrorFile] = useState([false, false, false, false, false])
	const [progressBar, setProgressBar] = useState([0, 0, 0, 0, 0])
	const [errorMsg, setErrorMsg] = useState([])
	const [loadProgress, setLoadProgress] = useState([
		false,
		false,
		false,
		false,
		false,
	])
	const [successModal, setSuccessModal] = useState(false)
	const [modalStatus, setModalStatus] = useState('')
	const [approveLoading, setApproveLoading] = useState(false)
	const [checked, setChecked] = useState(false)
	const [tagSuggest, setTags] = useState([])

	useEffect(() => {
		if (props.open) {
			if (!checked) {
				setTimeout(() => {
					sendEmailRef.current.click()
				}, 10)
			}
		}
	}, [props.open])

	//upload file start
	const handleUploadImage = () => {
		setErrorMsg([])
		inputRef.current.click()
	}

	const cancel = (index) => {
		axiosToken.cancel()
		removeData(index)
	}

	const removeData = (index) => {
		let dataFile = [...fileUpload]
		let loadProg = [...progressLoad]
		let totalProg = [...progressTotal]
		let doc = [...docFile]
		let errFile = [...errorFile]
		let resRemove = fileUpload.length == 1 ? [] : removeItemArray(dataFile, index)
		let totalProgRemove = removeItemArray(totalProg, index)
		let loadProgRemove = removeItemArray(loadProg, index)
		let docRemove = removeItemArray(doc, index)
		let errorRemove = removeItemArray(errFile, index)
		setFileUpload(resRemove)
		setPorgressLoad(loadProgRemove)
		setPorgressTotal(totalProgRemove)
		setDocFile(docRemove)
		setErrorFile(errorRemove)
	}

	const generateUploadFile = (items) => {
		return items && items.length != 0 ? (
			items.map((item, i) => {
				return (
					<UploadFile
						data={item}
						key={i}
						onDeleteData={() => removeData(i)}
						onCancel={() => cancel(i)}
						progressLoad={progressLoad[i]}
						progressTotal={progressTotal[i]}
						error={errorFile[i]}
						progress={progressBar[i]}
						loadProgress={loadProgress[i]}
					/>
				)
			})
		) : (
			<></>
		)
	}

	const onChangeInput = () => {
		let dataForm = new FormData()
		let index = fileUpload.length
		let file = inputRef.current.files[0]
		let res = [...fileUpload]
		let progLoad = [...progressLoad]
		let progTotal = [...progressTotal]
		let error = [...errorFile]
		let doc = [...docFile]
		let progress = [...progressBar]
		let loading = [...loadProgress]
		inputRef.current.value = ''
		let checkValidate = validateUpload(5242880, type, file)
		if (checkValidate.isError) {
			setErrorMsg(checkValidate.textErrorMultiple)
		} else {
			res.push(file.name)
			setFileUpload(res)
			dataForm.append('files[]', file, file.name)
			dataForm.append('types', 'sale_order')
			API({
				method: 'POST',
				url: '/storages/upload',
				data: dataForm,
				headers: { 'Content-Type': 'multipart/form-data' },
				onUploadProgress: (ProgressEvent) => {
					loading[index] = true
					setLoadProgress(loading)
					progress[index] = (ProgressEvent.loaded / ProgressEvent.total) * 100
					setProgressBar(progress)
					progLoad[index] = ProgressEvent.loaded
					setPorgressLoad(progLoad)
					progTotal[index] = ProgressEvent.total
					setPorgressTotal(progTotal)
				},
				cancelToken: axiosToken.token,
			})
				.then((result) => {
					loading[index] = false
					setLoadProgress(loading)
					doc[index] = result.data.data.results[0].id
					setDocFile(doc)
					error[index] = false
					setErrorFile(error)
				})
				.catch((err) => {
					if (axios.isCancel(err)) {
						axiosToken = CancelToken.source()
					} else {
						loading[index] = false
						setLoadProgress(loading)
						error[index] = true
						setErrorFile(error)
					}
				})
		}
	}
	useEffect(() => {
		if (prDetail.data != null && prDetail.isLoading == false) {
			setNoRef(
				prDetail.data.reference_no
					? prDetail.data.reference_no
					: prDetail.data.quotation
					? prDetail.data.quotation.data.client_order_ref
					: ''
			)
		}
	}, [prDetail.data, prDetail.isLoading])

	const handleApprovePr = (token, isNeedUploadFile) => {
		let values = {
			notes: note,
			reference_no: noRef,
			po_user_mail: createArrayContactId(tagSuggest),
		}
		let validate = validateSubmit(docFile, errorFile[0])
		if (!values.reference_no) {
			values.reference_no = prDetail.data.quotation.data.client_order_ref
		}
		if (isNeedUploadFile && validate.isError) {
			setErrorMsg(validate.errorMsg)
		} else {
			setErrorMsg([])
			props.onClose()
			setErrorFile([false, false, false, false, false])
			setApproveLoading(true)
			setFileUpload([])
			setChecked(false)
			let storage_id = []
			docFile.length != 0
				? docFile.map((item) => {
						let temp = {
							id: item,
						}
						storage_id.push(temp)
				  })
				: null
			approvePR(props.prNumber, prDetail.data.approval_token, values, storage_id)
				.then((response) => {
					if (response) {
						setApproveLoading(false)
						setSuccessModal(true)
						if ('error_backend' in response.data) {
							setModalStatus('error')
						} else {
							setModalStatus('success')
						}
					}
				})
				.catch((err) => console.log(err))
		}
	}

	const handleSuccess = () => {
		dispatch(requestPRDetail(props.prNumber))
		setSuccessModal(false)
		if (docFile.length != 0) {
			navigate('/dashboard/purchase-order')
		}
	}

	const handleChangeCheckBox = (event) => {
		setChecked(event.target.checked)
	}

	return (
		<>
			<Dialog
				open={props.open}
				onClose={() => {
					props.onClose()
					setErrorMsg([])
					setFileUpload([])
				}}
				fullWidth
				maxWidth='sm'
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Purchase Order</DialogTitle>
				<Divider />
				<DialogContent>
					<Grid container direction='column'>
						{!prDetail.isLoading &&
							prDetail.data.budgets &&
							prDetail.data.budgets.budget_name && (
								<Grid item>
									<Typography variant='subtitle2'>Anggaran</Typography>
									<Typography variant='body2'>
										{prDetail.data.budgets.budget_name}
									</Typography>
								</Grid>
							)}
						<Grid item>
							<Typography variant='subtitle2' style={{ marginTop: 12 }}>
								Masukkan catatan pembelian (optional)
							</Typography>
						</Grid>
						<Grid item>
							<TextField
								variant='outlined'
								margin='dense'
								fullWidth
								name='notes'
								placeholder='Masukkan catatan pembelian'
								value={note}
								onChange={(e) => setNote(e.target.value)}
							/>
						</Grid>
						<Grid item>
							<Typography variant='subtitle2' style={{ marginTop: 5 }}>
								No.Referensi PO (optional)
							</Typography>
						</Grid>
						<Grid item>
							<TextField
								variant='outlined'
								margin='dense'
								name='reference_no'
								fullWidth
								placeholder='Masukkan No.Referensi PO'
								value={noRef}
								onChange={(e) => setNoRef(e.target.value)}
							/>
						</Grid>
						<Grid container direction='row' item>
							<Typography
								variant='subtitle2'
								style={{ marginTop: 5 }}
								className={classes.labelRequired}
							>
								Unggah Dokumen (maksimal 5)
							</Typography>
							<Tooltip
								classes={{ tooltip: classes.tooltip }}
								placement='right'
								title='Lampirkan minimal satu dokumen pendukung untuk transaksi (Contoh: Dokumen PO Internal).'
								arrow
							>
								<HelpIcon style={{ fontSize: 14, marginLeft: 7, marginTop: 7 }} />
							</Tooltip>
						</Grid>
						{fileUpload && fileUpload.length < 5 ? (
							<ButtonBase
								onClick={() => handleUploadImage()}
								err
								className={classes.buttonFile}
							>
								<input
									ref={(el) => (inputRef.current = el)}
									name={'img'}
									type='file'
									style={{ display: 'none' }}
									onChange={() => onChangeInput()}
								/>
								<img src={UploadIcon} alt='upload' className={classes.imageUpload} />
								<Typography style={{ color: '#007BFF', fontSize: '14px' }}>
									Pilih Dokumen
								</Typography>
							</ButtonBase>
						) : null}
						{errorMsg && errorMsg.length != 0
							? errorMsg.map((item, i) => (
									<Grid container direction='row' item key={i}>
										<WarningIcon color='error' className={classes.iconWarning} />
										<Typography variant='caption' color='error'>
											{item}
										</Typography>
									</Grid>
							  ))
							: null}
						{fileUpload && fileUpload.length < 5 ? (
							<>
								<Typography variant='caption'>
									Format dokumen: pdf, doc, docx, xls, xlsx, png, jpg, jpeg.
								</Typography>
								<Typography variant='caption'>Ukuran Dokumen Maksimal 5MB</Typography>
							</>
						) : null}
						<div style={{ marginTop: fileUpload && fileUpload.length < 5 ? 10 : 20 }}>
							{generateUploadFile(fileUpload)}
						</div>
						<Grid container direction='row' item style={{ marginTop: -5 }}>
							<Checkbox
								inputRef={(el) => (sendEmailRef.current = el)}
								defaultChecked
								color='primary'
								inputProps={{ 'aria-label': 'primary checkbox' }}
								size='small'
								onChange={handleChangeCheckBox}
								checked={checked}
								className={classes.rootCheckBox}
								icon={<span className={classes.icon} />}
								checkedIcon={<span className={classes.checkedIcon} />}
							/>
							<Typography variant='body1' style={{ fontSize: '14px', marginTop: 2 }}>
								Kirimkan kabar terbaru terkait Purchase Order ini (opsional)
							</Typography>
							<Tooltip
								classes={{ tooltip: classes.tooltip }}
								placement='right'
								title='Penerima email akan menerima email terkait Purchase Order, seperti: Pengiriman Pesanan, Penerimaan Pesanan, ataupun Penolakan Pesanan'
								arrow
							>
								<HelpIcon style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }} />
							</Tooltip>
						</Grid>
						{checked ? (
							<div style={{ marginLeft: 21, marginTop: 2 }}>
								<Typography
									variant='body1'
									style={{ fontSize: '12px', marginBottom: 2 }}
								>
									Kirim email ke (maksimal 10 penerima)
								</Typography>
								<TagAutocomplete
									initialSuggestion={usersAccount ? usersAccount.data?.items : []}
									initialTag={userProfile ? userProfile.data : []}
									setParentTag={setTags}
									checked={checked}
									setParentChecked={setChecked}
									placeholderText='+ Tambah Penerima'
								/>
							</div>
						) : null}
					</Grid>
					<DialogActions style={{ padding: '15px 0', marginTop: '15px' }}>
						<Button
							onClick={() => {
								props.onClose()
								setErrorMsg([])
								setFileUpload([])
								setErrorFile([false, false, false, false, false])
							}}
							className={classes.btnCancel}
						>
							Batal
						</Button>
						<Button
							onClick={() => handleApprovePr(prDetail.data.approval_token, true)}
							variant='contained'
							color='secondary'
						>
							Buat Purchase Order
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<ModalMessages
				succesModal={successModal}
				modalStatus={modalStatus}
				afterCancel={false}
				cancelModal={false}
				handleReject={props.handleReject}
				handleSuccessCreate={() => handleSuccess()}
				prNumber={props.prNumber}
				approveLoading={approveLoading}
				docFile={docFile}
				onClose={() => {
					setSuccessModal(false)
					props.closeModal
				}}
			/>
		</>
	)
}

Component.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	prNumber: PropTypes.string,
	handleReject: PropTypes.handleReject,
	handleSuccessCreate: PropTypes.handleSuccessCreate,
	closeModal: PropTypes.func,
}

export default Component
