import { MOU_SUBMIT_PO } from '../../types'
import API from '../../../utils/api'

const loading = () => {
	return {
		type: `${MOU_SUBMIT_PO}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${MOU_SUBMIT_PO}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${MOU_SUBMIT_PO}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const reset = (data) => {
	return {
		type: `${MOU_SUBMIT_PO}_RESET`,
		payload: {
			...data,
		},
	}
}

export const submitPOMOU = (data, type_dispatch = 'default') => {
	return async (dispatch) => {
		if (type_dispatch == 'RESET') {
			dispatch(reset())
		} else {
			dispatch(loading())
			try {
				let response = await API({
					method: 'POST',
					url: '/mou/transaction/create',
					data: data,
				})

				if (response) {
					if (response.data.error_backend) {
						dispatch(failure(response.data.error_backend))
					} else {
						dispatch(success(response.data))
					}
				}
			} catch (err) {
				dispatch(failure(err))
			}
		}
	}
}
