import { createTheme } from 'react-data-table-component'

export const dataTableStyle = {
	table: {
		style: {
			marginTop: '13px',
			width: 'max-content',
			minWidth: '100%',
		},
	},
	tableWrapper: {
		style: {
			display: 'table',
			height: 'inherit',
		},
	},
	header: {
		style: {
			display: 'none',
		},
	},
	headCells: {
		style: {
			minHeight: '43px',
			fontSize: '14px',
			backgroundColor: 'transparent',
		},
	},
	headRow: {
		style: {
			borderBottom: 'none',
		},
	},
	cells: {
		style: {
			fontSize: '14px',
		},
	},
	rows: {
		style: {
			fontSize: '14px',
			backgroundColor: '#fff',
			'&:first-child': {
				borderRadius: '4px 4px 0px 0px',
			},
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#e7eaf2 !important',
			},
			'&:last-child': {
				borderRadius: '0px 0px 4px 4px',
				border: 'none !important',
			},
			'&:last-of-type': {
				border: 'none !important',
			},
		},
	},
}

export const dataTableStyleNoPadding = {
	table: {
		style: {
			width: 'max-content',
			minWidth: '100%',
		},
	},
	tableWrapper: {
		style: {
			display: 'table',
			height: 'inherit',
		},
	},
	header: {
		style: {
			display: 'none',
		},
	},
	headCells: {
		style: {
			minHeight: '43px',
			fontSize: '14px',
			backgroundColor: 'transparent',
		},
	},
	headRow: {
		style: {
			borderBottom: 'none',
		},
	},
	cells: {
		style: {
			fontSize: '14px',
		},
	},
	rows: {
		style: {
			fontSize: '14px',
			backgroundColor: '#fff',
			'&:first-child': {
				borderRadius: '4px 4px 0px 0px',
			},
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#e7eaf2 !important',
			},
			'&:last-child': {
				borderRadius: '0px 0px 4px 4px',
				border: 'none !important',
			},
			'&:last-of-type': {
				border: 'none !important',
			},
		},
	},
}

export const tableFixed = {
	table: {
		style: {
			width: '100%',
			minWidth: '100%',
		},
	},
}

export const multiLineCellStyle = {
	paddingTop: '16px',
	paddingBottom: '16px',
	alignItems: 'start',
}

export const cellTextRed = {
	color: '#DC3545',
}

createTheme('mbizTheme', {
	text: {
		primary: '#343A40',
		secondary: '#343A40',
	},
	background: {
		default: 'transparent',
	},
	context: {
		background: '#cb4b16',
		text: '#343A40',
	},
	divider: {
		default: '#ccc',
	},
	action: {
		button: 'rgba(0,0,0,.54)',
		hover: 'rgba(0,0,0,.08)',
		disabled: 'rgba(0,0,0,.12)',
	},
})
