import { PURCHASE_REQUEST_LIST } from '../../../types'
import API from '../../../../utils/api'
import { newUriAPI } from '../../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${PURCHASE_REQUEST_LIST}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${PURCHASE_REQUEST_LIST}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${PURCHASE_REQUEST_LIST}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestPRList = ({ sendQuery }) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			let url = ''
			if (sendQuery) url = newUriAPI(sendQuery)

			let response = await API({
				method: 'GET',
				url: '/purchase-requests/list' + (url != '' ? '?' + url : ''),
			})

			dispatch(success(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
