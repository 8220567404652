import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	containerRoot: {
		padding: theme.spacing(3, 12),
	},
	root: {
		backgroundColor: theme.palette.background.dashboard,
	},
	buttonActionWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '70%',
		marginTop: theme.spacing(2),
	},
}))

export default styles
