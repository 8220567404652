import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	titlePrice: {
		color: theme.palette.secondary.main,
		fontSize: '0.813',
	},
	priceRange: {
		color: theme.palette.alert.dark,
		fontWeight: 900,
	},
	variantAlert: {
		backgroundColor: '#fef2cd',
		display: 'flex',
		marginTop: theme.spacing(1),
		borderRadius: 5,
		padding: theme.spacing(1),
		justifyContent: 'center',
		fontSize: '0.69rem',
	},
	nested: {
		paddingLeft: theme.spacing(1),
	},
	cardImageContainer: {
		height: 200,
		overflow: 'hidden',
	},
	cardTitle: {
		fontSize: '0.87rem',
		textAlign: 'center',
	},
	cardTitleWrapper: {
		overflow: 'hidden',
		overflowWrap: 'break-word',
		height: 45,
	},
	cardContent: {
		padding: 8,
	},
	ellipsisText: {
		lineHeight: '1.4rem',
		height: '45px',
		display: '-webkit-box',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		WebkitLineClamp: '2',
		WebkitBoxOrient: 'vertical',
	},
}))

export default styles
