import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	Modal as ModalPo,
	Fade,
	Paper,
	Backdrop,
	Box,
	Typography,
	Divider,
	TextField,
	Button,
	ButtonBase,
	Tooltip,
	Grid,
	IconButton,
} from '@material-ui/core'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import {
	createPo,
	resetAction,
} from 'States/actions/Transactions/Quotation/createPO'
import PropTypes from 'prop-types'
import styles from './styles'
import UploadIcon from '../../../../assets/images/icons/upload.png'
import HelpIcon from '@material-ui/icons/Help'
import WarningIcon from '@material-ui/icons/Warning'
import { validateUpload, removeItemArray } from '../../../../utils/globalUtils'
import {
	type,
	validateSubmit,
} from '../../PurchaseRequest/detail/helper/helper'
import UploadFile from '../../PurchaseRequest/detail/helper/uploadFile'
import API from '../../../../utils/api'
import axios from 'axios'
import { MultiSelect } from '../../../../components/dropdown'
import { Help } from '@material-ui/icons/'
import { dateFormat } from '../../../../utils/globalUtils'
import stylesHTML from './stylesHTML'
import ModalMessage from './modalMessage'

let CancelToken = axios.CancelToken
let axiosToken = CancelToken.source()

const Component = (props) => {
	const classes = styles()
	const dispatch = useDispatch()
	const data = useSelector((state) => state.createPo)
	const inputRef = useRef([])
	const [fileUpload, setFileUpload] = useState([])
	const [progressLoad, setPorgressLoad] = useState([0, 0, 0, 0, 0])
	const [progressTotal, setPorgressTotal] = useState([0, 0, 0, 0, 0])
	const [docFile, setDocFile] = useState([])
	const [errorFile, setErrorFile] = useState([false, false, false, false, false])
	const [progressBar, setProgressBar] = useState([0, 0, 0, 0, 0])
	const [errorMsg, setErrorMsg] = useState([])
	const [loadProgress, setLoadProgress] = useState([
		false,
		false,
		false,
		false,
		false,
	])
	const [successModal, setSuccessModal] = useState(false)
	//untuk field anggaran
	const budgetList = useSelector((state) => state.budgetListValidReducer)
	const [selectedOptions, setSelectedOptions] = useState()
	const [errorAnggaran, setErrorAnggaran] = useState(false)
	const [isUpdateBudget, setIsUpdateBudget] = useState(false)
	//upload file start
	const handleUploadImage = () => {
		setErrorMsg([])
		inputRef.current.click()
	}

	const cancel = (index) => {
		axiosToken.cancel()
		removeData(index)
	}

	const removeData = (index) => {
		let dataFile = [...fileUpload]
		let loadProg = [...progressLoad]
		let totalProg = [...progressTotal]
		let doc = [...docFile]
		let errFile = [...errorFile]
		let resRemove = fileUpload.length == 1 ? [] : removeItemArray(dataFile, index)
		let totalProgRemove = removeItemArray(totalProg, index)
		let loadProgRemove = removeItemArray(loadProg, index)
		let docRemove = removeItemArray(doc, index)
		let errorRemove = removeItemArray(errFile, index)
		setFileUpload(resRemove)
		setPorgressLoad(loadProgRemove)
		setPorgressTotal(totalProgRemove)
		setDocFile(docRemove)
		setErrorFile(errorRemove)
	}

	const generateUploadFile = (items) => {
		return items && items.length != 0 ? (
			items.map((item, i) => {
				return (
					<UploadFile
						data={item}
						key={i}
						onDeleteData={() => removeData(i)}
						onCancel={() => cancel(i)}
						progressLoad={progressLoad[i]}
						progressTotal={progressTotal[i]}
						error={errorFile[i]}
						progress={progressBar[i]}
						loadProgress={loadProgress[i]}
					/>
				)
			})
		) : (
			<></>
		)
	}

	const onChangeInput = () => {
		let dataForm = new FormData()
		let index = fileUpload.length
		let file = inputRef.current.files[0]
		let res = [...fileUpload]
		let progLoad = [...progressLoad]
		let progTotal = [...progressTotal]
		let error = [...errorFile]
		let doc = [...docFile]
		let progress = [...progressBar]
		let loading = [...loadProgress]
		inputRef.current.value = ''
		let checkValidate = validateUpload(5242880, type, file)
		if (checkValidate.isError) {
			setErrorMsg(checkValidate.textErrorMultiple)
		} else {
			res.push(file.name)
			setFileUpload(res)
			dataForm.append('files[]', file, file.name)
			dataForm.append('types', 'sale_order')
			API({
				method: 'POST',
				url: '/storages/upload',
				data: dataForm,
				headers: { 'Content-Type': 'multipart/form-data' },
				onUploadProgress: (ProgressEvent) => {
					loading[index] = true
					setLoadProgress(loading)
					progress[index] = (ProgressEvent.loaded / ProgressEvent.total) * 100
					setProgressBar(progress)
					progLoad[index] = ProgressEvent.loaded
					setPorgressLoad(progLoad)
					progTotal[index] = ProgressEvent.total
					setPorgressTotal(progTotal)
				},
				cancelToken: axiosToken.token,
			})
				.then((result) => {
					loading[index] = false
					setLoadProgress(loading)
					doc[index] = result.data.data.results[0].id
					setDocFile(doc)
					error[index] = false
					setErrorFile(error)
				})
				.catch((err) => {
					if (axios.isCancel(err)) {
						axiosToken = CancelToken.source()
					} else {
						loading[index] = false
						setLoadProgress(loading)
						error[index] = true
						setErrorFile(error)
					}
				})
		}
	}

	const submitForm = (values) => {
		let validate = validateSubmit(docFile, errorFile[0])
		let validateAnggaran =
			budgetList && budgetList.data && budgetList.data.items.length != 0
				? selectedOptions
					? false
					: true
				: false
		setErrorAnggaran(validateAnggaran)
		if (validate.isError || validateAnggaran) {
			setErrorMsg(validate.errorMsg)
		} else {
			let storage_id = []
			docFile.length != 0
				? docFile.map((item) => {
						let temp = {
							id: item,
						}
						storage_id.push(temp)
				  })
				: null
			budgetList && budgetList.data && budgetList.data.items.length != 0
				? (values.budget_data = {
						budget_id: selectedOptions.id,
						current_total: selectedOptions.total,
						current_used: selectedOptions.total_used,
						requestor_id: props.requestor_id,
				  })
				: null
			dispatch(createPo(props.id, values, storage_id))
		}
	}

	const onCloseModal = () => {
		setFileUpload([])
		setErrorMsg([])
		setErrorFile([false, false, false, false, false])
		setErrorAnggaran(false)
		setSelectedOptions()
		props.onClose()
	}

	const handleSuccessModal = () => {
		setSuccessModal(false)
		dispatch(resetAction())
		navigate('/dashboard/purchase-order')
	}

	useEffect(() => {
		if (data.data || data.isError) {
			setSuccessModal(true)
			data.isError ? null : onCloseModal()
			setIsUpdateBudget(
				data.isError
					? data.data.http_body.includes('there is update on budget') ||
							data.data.http_body.includes('budget data not found')
					: false
			)
		}
	}, [data.data, data.isError])

	const handleToggleOption = (selectedOptions) =>
		setSelectedOptions(selectedOptions)

	const handleClearOptions = () => setSelectedOptions()

	const numberOptions = {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}

	const renderOption = (option) => {
		return (
			<React.Fragment>
				{option ? (
					<div>
						<span style={stylesHTML.textTitleOptionSelect}>{option.name}</span>
						<br />
						<span style={stylesHTML.textDescOptionPadding}>
							Periode Anggaran : {dateFormat(option.date_start, 'dd MMMM yyyy')} -
							{dateFormat(option.date_stop, 'dd MMMM yyyy')}
						</span>
						<br />
						<div style={stylesHTML.styleDesc}>
							<span style={stylesHTML.textDescOptionSelect}>Sisa Anggaran : </span>
							<span
								style={
									option.total - option.total_used > 0
										? stylesHTML.textDescOptionSelect
										: stylesHTML.textcolorError
								}
							>
								Rp{' '}
								{(option.total - option.total_used).toLocaleString(
									'en-US',
									numberOptions
								)}
							</span>
						</div>
					</div>
				) : (
					<div></div>
				)}
			</React.Fragment>
		)
	}

	return (
		<>
			<ModalPo
				className={classes.modal}
				BackdropComponent={Backdrop}
				open={props.open}
				BackdropProps={{
					timeout: 500,
				}}
				onClose={() => onCloseModal()}
				onBackdropClick={() => onCloseModal()}
				{...props}
			>
				<Fade in={props.open} className={classes.modalFade}>
					<Paper>
						<Box
							display='flex'
							flexDirection='column'
							className={classes.modalContent}
						>
							<Typography variant='h6'> Membuat Purchase Order </Typography>
							<Divider className={classes.divider} />
							<Formik
								initialValues={{
									buying_note: '',
									refNumber: props.ref_po || '',
								}}
								onSubmit={(values) => submitForm(values)}
							>
								{({ handleChange, handleSubmit, values }) => (
									<Form method='post' onSubmit={handleSubmit}>
										{budgetList &&
										budgetList.data &&
										budgetList.data.items.length != 0 ? (
											<Box display='flex' flexDirection='column' mt={3}>
												<Box display='flex' flexDirection='row'>
													<Typography className={classes.labelRequired} variant='body1'>
														<strong>Anggaran</strong>
													</Typography>
													<Tooltip
														placement='top-start'
														title={
															<span>
																Anda diwajibkan untuk memilih anggaran sebelum memproses
																Quotation ke tahap berikutnya. Setelah Quotation berhasil
																diproses lebih lanjut,{' '}
																<strong>
																	nilai Anggaran akan dikurangi sesuai nominal Subtotal yang ada
																	di Quotation (diluar Pajak).
																</strong>
																<br /> <br />
																Nilai anggaran akan dikembalikan jika nantinya terdapat
																pembatalan/penolakan transaksi.{' '}
															</span>
														}
														arrow
														classes={{
															arrow: classes.arrow,
															tooltip: classes.tooltipContainer,
														}}
													>
														<IconButton
															size='small'
															classes={{ root: classes.styleIconbutton }}
														>
															<Help fontSize='small' className={classes.smallICon} />
														</IconButton>
													</Tooltip>
												</Box>
												<MultiSelect
													items={budgetList && budgetList.data ? budgetList.data.items : []}
													getOptionLabel={(option) => option.name || ''}
													selectedValues={selectedOptions}
													placeholder='Pilih Anggaran'
													onToggleOption={handleToggleOption}
													onClearOptions={handleClearOptions}
													isMultiple={false}
													isFullWidth={true}
													classStyle={{
														option: classes.option,
														inputRoot: `${classes.inputRootBorder} ${
															errorAnggaran ? classes.borderError : classes.borderInput
														}`,
														endAdornment: classes.endAdornment,
														popupIndicator: classes.selectIcon,
														listbox: classes.listbox,
													}}
													renderOption={renderOption}
												/>
												{selectedOptions ? (
													<div>
														<Typography className={classes.detailAnggaran}>
															Periode Anggaran :{' '}
															{dateFormat(selectedOptions.date_start, 'dd MMMM yyyy')} -
															{dateFormat(selectedOptions.date_stop, 'dd MMMM yyyy')}
														</Typography>
														<Grid container direction='row'>
															<Typography className={classes.detailAnggaran}>
																Sisa Anggaran :
															</Typography>
															<Typography
																className={
																	selectedOptions.total - selectedOptions.total_used > 0
																		? classes.detailAnggaran
																		: `${classes.detailAnggaran} ${classes.errorColorText}`
																}
															>
																Rp{' '}
																{(
																	selectedOptions.total - selectedOptions.total_used
																).toLocaleString('en-US', numberOptions)}
															</Typography>
														</Grid>
													</div>
												) : null}
												{errorAnggaran ? (
													<Grid container direction='row' item>
														<Typography variant='caption' color='error'>
															Anggaran harus dipilih
														</Typography>
													</Grid>
												) : null}
											</Box>
										) : null}
										<Box display='flex' flexDirection='column' mt={'12px'}>
											<Typography
												className={classes.inputLabelModalConfirm}
												variant='body1'
												style={{ fontSize: '14px' }}
											>
												<strong>Catatan Pembelian (Opsional) </strong>
											</Typography>
											<TextField
												disabled={data.isLoading}
												name='buying_note'
												onChange={handleChange}
												value={values.buying_note}
												fullWidth
												size='small'
												variant='outlined'
												placeholder='Masukkan Catatan Pembelian'
											/>
										</Box>
										<Box display='flex' flexDirection='column' mt={2}>
											<Typography
												className={classes.inputLabelModalConfirm}
												variant='body1'
												style={{ fontSize: '14px' }}
											>
												<strong>Nomor Referensi PO (Opsional) </strong>
											</Typography>
											<TextField
												disabled={data.isLoading}
												name='refNumber'
												onChange={handleChange}
												value={values.refNumber}
												fullWidth
												size='small'
												variant='outlined'
												placeholder='Masukkan Nomor Referensi PO'
											/>
										</Box>
										<Box display='flex' flexDirection='row' mt={2}>
											<Typography
												variant='subtitle2'
												style={{ marginTop: 5 }}
												className={classes.labelRequired}
											>
												Unggah Dokumen (maksimal 5)
											</Typography>
											<Tooltip
												classes={{ tooltip: classes.tooltip }}
												placement='right'
												title='Lampirkan minimal satu dokumen pendukung untuk transaksi (Contoh: Dokumen PO Internal).'
												arrow
											>
												<HelpIcon style={{ fontSize: 14, marginLeft: 7, marginTop: 7 }} />
											</Tooltip>
										</Box>
										<Box display='flex' flexDirection='column'>
											{fileUpload && fileUpload.length < 5 ? (
												<ButtonBase
													onClick={() => handleUploadImage()}
													err
													className={classes.buttonFile}
												>
													<input
														ref={(el) => (inputRef.current = el)}
														name={'img'}
														type='file'
														style={{ display: 'none' }}
														onChange={() => onChangeInput()}
													/>
													<img
														src={UploadIcon}
														alt='upload'
														className={classes.imageUpload}
													/>
													<Typography style={{ color: '#007BFF', fontSize: '14px' }}>
														Pilih Dokumen
													</Typography>
												</ButtonBase>
											) : null}
											{errorMsg && errorMsg.length != 0
												? errorMsg.map((item, i) => (
														<Grid container direction='row' item key={i}>
															<WarningIcon color='error' className={classes.iconWarning} />
															<Typography variant='caption' color='error'>
																{item}
															</Typography>
														</Grid>
												  ))
												: null}
											{fileUpload && fileUpload.length < 5 ? (
												<>
													<Typography variant='caption'>
														Format dokumen: pdf, doc, docx, xls, xlsx, png, jpg, jpeg.
													</Typography>
													<Typography variant='caption'>
														Ukuran Dokumen Maksimal 5MB
													</Typography>
												</>
											) : null}
											<div
												style={{
													marginTop: fileUpload && fileUpload.length < 5 ? 10 : 20,
												}}
											>
												{generateUploadFile(fileUpload)}
											</div>
										</Box>
										<Box
											mt={1}
											display='flex'
											style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
										>
											<Button
												onClick={() => onCloseModal()}
												variant='contained'
												size='small'
												className={[classes.buttonDanger, classes.kembali].join(' ')}
											>
												Batal
											</Button>
											<Button
												disabled={data.isLoading}
												type='submit'
												variant='contained'
												size='small'
												color='secondary'
											>
												Buat Purchase Order
											</Button>
										</Box>
									</Form>
								)}
							</Formik>
						</Box>
					</Paper>
				</Fade>
			</ModalPo>
			<ModalMessage
				succesModal={successModal}
				modalStatus={
					data.isError ? (isUpdateBudget ? 'warning' : 'error') : 'success'
				}
				handleSuccessModal={() => handleSuccessModal()}
				onClose={() => {
					setSuccessModal(false)
					dispatch(resetAction())
				}}
				isPR={false}
			/>
		</>
	)
}

Component.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	ref_po: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	requestor_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Component.defaultProps = {
	ref_po: '',
}

export default Component
