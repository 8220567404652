import React, { useState } from 'react'
import LayoutComponent from 'Components/layout'
import { Container, Box, Grid, Typography } from '@material-ui/core'
import Breadcrumbs from 'Components/breadcrumbs'
import { HowToStyle } from './styles'

const HowTo = () => {
	const classes = HowToStyle()

	const [breadcrumbs] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Cara Penggunaan',
			link: '/pages/how-to',
		},
	])

	return (
		<>
			<LayoutComponent>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrumbs} />
					</Box>
					<Box>
						<Typography variant='h6'>
							5 Langkah Mudah Bertransaksi di Mbiz.co.id
						</Typography>
					</Box>
					<Box mt={2}>
						<Grid container justify='center'>
							<Grid item xs={12}>
								<Grid container justify='center' alignItems='center'>
									<Grid item container justify='center' xs={4}>
										<Box
											p={2}
											display='flex'
											justifyContent='center'
											alignItems='flex-end'
											width='300px'
											height='300px'
											className={`${classes.Boxes} ${classes.bg1}`}
										>
											<Box>
												<Typography variant='subtitle1' align='center'>
													Pembelian Produk
												</Typography>
												<Typography variant='body2' align='center'>
													Login, Pilih produk/jasa &amp; Masuk ke keranjangan
												</Typography>
											</Box>
										</Box>
									</Grid>
									<Grid item container justify='center' xs={4}>
										<Box
											p={2}
											display='flex'
											justifyContent='center'
											alignItems='flex-end'
											width='300px'
											height='300px'
											className={`${classes.Boxes} ${classes.bg2}`}
										>
											<Box>
												<Typography variant='subtitle1' align='center'>
													Pembuatan RFQ dan Quotation
												</Typography>
												<Typography variant='body2' align='center'>
													Buat RFQ sebagai dokumen permintaan dan terima konfirmasi
													permintaan berupa Quotation
												</Typography>
											</Box>
										</Box>
									</Grid>
									<Grid item container justify='center' xs={4}>
										<Box
											p={2}
											display='flex'
											justifyContent='center'
											alignItems='flex-end'
											width='300px'
											height='300px'
											className={`${classes.Boxes} ${classes.bg3}`}
										>
											<Box>
												<Typography variant='subtitle1' align='center'>
													Persetujuan PR (Purchase Request) & PO (Purchase Order)
												</Typography>
												<Typography variant='body2' align='center'>
													Setujui Purchase Request dan Buat Purchase Order untuk pengiriman
													barang
												</Typography>
											</Box>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container justify='center' alignItems='center'>
									<Grid item container justify='center' xs={4}>
										<Box
											p={2}
											display='flex'
											justifyContent='center'
											alignItems='flex-end'
											width='300px'
											height='300px'
											className={`${classes.Boxes} ${classes.bg4}`}
										>
											<Box>
												<Typography variant='subtitle1' align='center'>
													Pengiriman dan Penagihan (Invoicing)
												</Typography>
												<Typography variant='body2' align='center'>
													Terima tagihan setelah barang diterima
												</Typography>
											</Box>
										</Box>
									</Grid>
									<Grid item container justify='center' xs={4}>
										<Box
											p={2}
											display='flex'
											justifyContent='center'
											alignItems='flex-end'
											width='300px'
											height='300px'
											className={`${classes.Boxes} ${classes.bg5}`}
										>
											<Box>
												<Typography variant='subtitle1' align='center'>
													Pembayaran
												</Typography>
												<Typography variant='body2' align='center'>
													Lakukan pembayaran sesuai metode TOP (Term of Payment) yang
													disepakati diawal
												</Typography>
											</Box>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</LayoutComponent>
		</>
	)
}

export default HowTo
