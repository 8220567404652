import React from 'react'
import LayoutErrorPage from '../../../components/layoutErrorPage'
import LinkExpired from '../../../assets/images/content/TautanSudahTidakTersedia.png'
import { Typography, Box, Link } from '@material-ui/core'
import { navigate } from '@reach/router'
import useStyles from '../styles'

const LinkNotFound = () => {
	const classes = useStyles()
	const handleNavigate = (path) => {
		navigate(path)
	}
	return (
		<LayoutErrorPage variant='light'>
			<Box>
				<img src={LinkExpired} style={{ width: '40%' }} />
				<Box mt={2} mb={5}>
					<Typography variant='h6'>
						<b>Tautan tidak ditemukan</b>
					</Typography>
					<Typography variant='body1' style={{ cursor: 'pointer' }}>
						Silakan hubungi{' '}
						<Link
							className={classes.uri}
							onClick={() => handleNavigate('/contact-us')}
						>
							layanan pelanggan
						</Link>{' '}
						untuk bantuan lebih lanjut atau kembali ke{' '}
						<Link className={classes.uri} onClick={() => handleNavigate('/')}>
							beranda Mbiz.
						</Link>
					</Typography>
				</Box>
			</Box>
		</LayoutErrorPage>
	)
}

export default LinkNotFound
