import * as Columns from './columns'
import { acl } from 'Utilities/acl'
import { navigate } from 'gatsby'

export const breadcrums = [
	{
		segment: 'Home',
		link: '/dashboard/',
	},
	{
		segment: 'Pusat Unduh Data',
		link: '#',
	},
	{
		segment: 'Unduh Data Transaksi',
		link: '#',
	},
]

export const dataDummy = [
	{
		code: 'AC',
		country_id: 100,
		id: 1709,
		name: 'Aceh',
	},
	{
		code: 'PLM',
		country_id: 100,
		id: 1809,
		name: 'Palembang',
	},
]

export const databuttonStatus = [
	{
		status: 'RFQ',
		query: 'rfqs',
	},
	{
		status: 'Quotation',
		query: 'quotations',
	},
	{
		status: 'Purchase Request',
		query: 'purchase-requests',
	},
	{
		status: 'Purchase Order',
		query: 'purchase-orders',
	},
	{
		status: 'Tagihan',
		query: 'invoices',
	},
	{
		status: 'Pembayaran',
		query: 'payments',
	},
]

export const getColumns = (query, userRoles) => {
	let temp = []
	let access = ''
	let _query = query
	let placeholder = ''
	switch (query) {
		case 'rfqs':
			temp = Columns.rfq_columns
			placeholder = 'Tanggal RFQ Dibuat'
			break
		case 'quotations':
			temp = Columns.qn_columns
			placeholder = 'Tanggal Quotation Dibuat'
			break
		case 'purchase-requests':
			temp = Columns.pr_columns
			placeholder = 'Waktu PR Dibuat'
			break
		case 'purchase-orders':
			temp = Columns.po_columns
			placeholder = 'Tanggal PO Dibuat'
			break
		case 'invoices':
			access = 'admin,manager,finance'
			if (!acl(userRoles, access)) {
				navigate('/401', { replace: true })
				localStorage.setItem('lastQueryReportTrans', JSON.stringify(''))
			}
			temp = Columns.invoices_column
			placeholder = 'Tanggal Tagihan Dibuat'
			break
		case 'payments':
			access = 'manager,finance'
			if (!acl(userRoles, access)) {
				navigate('/401', { replace: true })
				localStorage.setItem('lastQueryReportTrans', JSON.stringify(''))
			}
			temp = Columns.payment_colums
			placeholder = 'Tanggal Transfer'
			break
		default:
			temp
			placeholder = 'Tanggal Dibuat'
			break
	}

	return { temp, _query, placeholder }
}

export const elipsisString = (keyword, length) => {
	let str = keyword
	if (typeof keyword == 'string' && keyword.length > length) {
		str = keyword.slice(0, length) + '...'
	}
	return str
}

export const stateReplacer = (query = 'rfqs') => {
	window.history.replaceState(
		null,
		'report rfqs',
		`/dashboard/download/transaction?type=${query}`
	)
}

export const findData = (data, query) => {
	let res = data.find((item) => item.query == query)
	return res ? res.status : ''
}

export const findSelectedColumn = (query, index) => {
	let temp = []
	index = index.split(',').map((x) => +x)
	switch (query) {
		case 'rfqs':
			temp = Columns.rfq_columns
			break
		case 'quotations':
			temp = Columns.qn_columns
			break
		case 'purchase-requests':
			temp = Columns.pr_columns
			break
		case 'purchase-orders':
			temp = Columns.po_columns
			break
		case 'invoices':
			temp = Columns.invoices_column
			break
		case 'payments':
			temp = Columns.payment_colums
			break
		default:
			temp
			break
	}
	const selectedColumn = index.length
		? temp.filter((val) => index.indexOf(parseInt(val.index)) > -1)
		: []
	return selectedColumn
}
