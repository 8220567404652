import * as Yup from 'yup'

var schema = Yup.object().shape({
	user: Yup.string().required('Nama lengkap tidak boleh kosong'),
	level: Yup.string()
		.test('level', 'Level wajib dipilih', function (s) {
			return s != 'none'
		})
		.required('Level wajib dipilih'),
	department: Yup.string().required('Department tidak boleh kosong'),
	min: Yup.number('Minimal transaksi harus number').required(
		'Minimal transaksi tidak boleh kosong'
	),
	max: Yup.number('Maximal transaksi harus number').required(
		'Maximal transaksi tidak boleh kosong'
	),
})

schema = schema.test(
	// this test is added additional to any other (build-in) tests
	'customMinMAX',
	null, // we'll return error message ourself if needed
	(obj) => {
		if (obj.max === 0) {
			return true
		} else if (obj.max < obj.min && obj.max !== 0) {
			return new Yup.ValidationError(
				'Batas maksimum transaksi harus lebih besar dari batas minimum transaksi', // your custom error message
				null,
				'validation_min_max'
			)
		}

		return true // everything is fine
	}
)

export default schema
