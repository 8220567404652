import React, { useState, useEffect } from 'react'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	Paper,
	TextField,
	Button,
	Container,
	CircularProgress,
	Collapse,
	IconButton,
	Tooltip,
} from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Breadcrumbs from 'Components/breadcrumbs'
import { Styles } from './styles'
import schema from './schema'
import { navigate } from 'gatsby'
import Modal from 'Components/modal'
import { useSelector, useDispatch } from 'react-redux'
import { getLocation, resetLocationAction } from 'States/actions/Master'
import { createAddress } from 'States/actions/Address/Address/createAction'
import {
	updateAddress,
	detailAddress,
	resetAction,
} from 'States/actions/Address/Address/updateAction'
import PropTypes from 'prop-types'
import { Alert, Autocomplete } from '@material-ui/lab'
import { HighlightOffOutlined } from '@material-ui/icons'
import { addressTypesList } from '../../../constants/text'
import { generateAddressType } from '../../../utils/globalUtils'

const AddressAdd = ({ addressID }) => {
	const classes = Styles()
	const dispatch = useDispatch()
	const pageName = 'Alamat'
	const mode = addressID == 0 ? 'new' : 'edit'
	const [showLoading, setLoading] = useState(false)
	const [, setError] = useState(false)
	const [field, setField] = useState(null)
	const [notifError, setNotifError] = useState(false)
	const [notifErrorMessage, setNotifErrorMessage] = useState('')
	const [locationLoading, setLocationLoading] = useState(true)
	const [locationLoadingWording, setLocationLoadingWording] = useState(
		'Masukkan minimal 3 karakter'
	)
	const newReducer = useSelector((state) => state.addressCreate)
	const editReducer = useSelector((state) => state.addressUpdate)
	const dataLocation = useSelector((state) => state.location.location)

	const [breadcrumbsTambah] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Daftar Alamat',
			link: '/dashboard/company-address',
		},
		{
			segment: `Tambah ${pageName}`,
			link: '#',
		},
	])

	const [breadcrubmbsUbah] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Daftar Alamat',
			link: '/dashboard/company-address',
		},
		{
			segment: `Ubah ${pageName}`,
			link: '#',
		},
	])

	const handleSearchLocation = (_e, val) => {
		if (val.length >= 3) {
			setLocationLoading(false)
			setLocationLoadingWording('Loading')
			dispatch(getLocation(val))
		} else {
			setLocationLoading(true)
			setLocationLoadingWording('Masukkan minimal 3 karakter')
			dispatch(resetLocationAction())
		}
	}

	const handleRenderOption = (option) => {
		return (
			<div>
				<span key={option.id}>{option.complete_name}</span>
			</div>
		)
	}

	const handleSubmitForm = (values) => {
		let data = {
			name: values.addressLabel,
			type: values.addressType.type,
			region_id: values.location.id,
			street: values.address,
			pic: values.pic,
			mobile: values.mobile,
			phone: values.phone,
			active: values.active.id == 1,
		}

		if (mode == 'edit') {
			dispatch(updateAddress(addressID, data))
		} else {
			dispatch(createAddress(data))
		}
	}

	useEffect(() => {
		if (!newReducer.isError && !editReducer.isError) {
			if (mode == 'edit') {
				setLoading(editReducer.isLoading || editReducer.isLoadingData)
				if (editReducer.data) {
					let data = editReducer.data
					data.active = {
						id: data.active ? 1 : 0,
						name: data.active ? 'Aktif' : 'Tidak Aktif',
					}
					setField(data)
				}
			} else {
				setError(newReducer.isError)
				setLoading(newReducer.isLoading)
			}
		} else {
			let errorMsg = ''
			if (mode == 'edit') {
				setError(editReducer.isError)
				setLoading(editReducer.isLoading || editReducer.isLoadingData)
				errorMsg = JSON.parse(editReducer.data)
				setNotifError(editReducer.isError)
			} else {
				setError(newReducer.isError)
				setLoading(newReducer.isLoading)
				errorMsg = JSON.parse(newReducer.data)
				setNotifError(newReducer.isError)
			}
			errorMsg = errorMsg.error?.message?.toLowerCase()
			setNotifErrorMessage(generateErrorValidate(errorMsg))
		}
	}, [newReducer, editReducer])

	const generateErrorValidate = (error) => {
		if (error === 'duplicate label') {
			return 'Label alamat sudah terpakai, silakan masukkan label alamat lain'
		} else if (error === 'must have at least one address type for delivery') {
			return 'Alamat tidak dapat diubah karena Alamat Pengiriman hanya satu yang terdaftar pada Perusahaan'
		} else if (error === 'must have at least one address type for invoice') {
			return 'Alamat tidak dapat diubah karena Alamat Tagihan hanya satu yang terdaftar pada Perusahaan'
		}
		return ''
	}

	useEffect(() => {
		setLoading(false)
		if (mode == 'edit') {
			dispatch(detailAddress(addressID))
		}
		return () => {
			dispatch(resetAction())
			dispatch(resetLocationAction())
		}
	}, [])

	const formikInitValue = () => {
		return {
			addressLabel: field ? field.name : '',
			addressType: field
				? {
					type: field.type,
					name: generateAddressType(field.type),
				}
				: null,
			location: field
				? {
					id: field.region_id?.id,
					complete_name: field.region_id?.complete_name,
					level: field.region_id?.level,
				}
				: null,
			address: field ? field.street : '',
			pic: field ? field.pic : '',
			mobile: field ? field.mobile : '',
			phone: field ? field.phone : '',
			active: field
				? {
					id: field.active?.id,
					name: field.active?.name,
				}
				: {
					id: 1,
					name: 'Aktif',
				},
		}
	}

	return (
		<DashboardLayout>
			<Container>
				<Modal open={showLoading} icon='none' disableBackdropClick>
					<Box my={3} alignContent='center'>
						<CircularProgress color='secondary' style={{ marginBottom: '24px' }} />
						{(editReducer.isLoading || newReducer.isLoading) && (
							<Typography>Menyimpan {pageName}, Mohon Tunggu</Typography>
						)}
						{editReducer.isLoadingData && (
							<Typography>Mengambil Data {pageName}, Mohon Tunggu</Typography>
						)}
					</Box>
				</Modal>
				<Box>
					{mode == 'new' ? (
						<Breadcrumbs breadcrumbs={breadcrumbsTambah} />
					) : (
						<Breadcrumbs breadcrumbs={breadcrubmbsUbah} />
					)}
				</Box>
				<Box mt={2}>
					<Typography variant='h6'>
						{mode == 'new' ? 'Tambah' : 'Ubah'} {pageName}
					</Typography>
				</Box>
				<Box my={2}>
					<Formik
						enableReinitialize
						initialValues={formikInitValue()}
						validationSchema={schema}
						onSubmit={(values) => handleSubmitForm(values)}
					>
						{({ errors, touched, values, ...prop }) => (
							<Form method='post'>
								<Paper>
									<Box py={2} px={2}>
										<Grid container spacing={5}>
											{/* Left Column */}
											<Grid item xs={12} md={6}>
												<Grid container spacing={3}>
													<Grid item xs={12}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={5}>
																<Typography
																	variant='subtitle1'
																	className={`${classes.labelRequired} ${classes.padTop}`}
																>
																	Label Alamat
																</Typography>
															</Grid>
															<Grid item xs={7}>
																<Field
																	as={TextField}
																	variant='outlined'
																	color='primary'
																	placeholder='Masukkan Label Alamat'
																	margin='dense'
																	fullWidth
																	error={errors.addressLabel && touched.addressLabel}
																	name='addressLabel'
																/>
																<ErrorMessage name='addressLabel'>
																	{(msg) => (
																		<Typography variant='caption' color='error'>
																			{msg}
																		</Typography>
																	)}
																</ErrorMessage>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={12}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={5}>
																<Typography
																	variant='subtitle1'
																	className={`${classes.labelRequired} ${classes.padTop2}`}
																>
																	Tipe Alamat
																</Typography>
															</Grid>
															<Grid item xs={7}>
																<Field
																	as={Autocomplete}
																	id='addressType'
																	name='addressType'
																	value={values.addressType}
																	getOptionLabel={(option) => option.name}
																	getOptionSelected={(opt, value) => opt.name == value.name}
																	options={addressTypesList.data}
																	onChange={(_opt, val) =>
																		prop.setValues({
																			...values,
																			addressType: val ? val : null,
																		})
																	}
																	style={{ width: '100%' }}
																	renderInput={(params) => (
																		<TextField
																			placeholder='Pilih Tipe Alamat'
																			fullWidth
																			margin='dense'
																			variant='outlined'
																			name='addressType'
																			error={errors.addressType && touched.addressType}
																			{...params}
																		/>
																	)}
																	noOptionsText={<i>Maaf, kata kunci tidak ditemukan</i>}
																	classes={{
																		noOptions: classes.noOptionText,
																	}}
																/>
																<ErrorMessage name='addressType'>
																	{(msg) => (
																		<Typography variant='caption' color='error'>
																			{msg}
																		</Typography>
																	)}
																</ErrorMessage>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={12}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={5}>
																<Typography
																	variant='subtitle1'
																	className={`${classes.labelRequired} ${classes.padTop2}`}
																>
																	Lokasi
																</Typography>
															</Grid>
															<Grid item xs={7}>
																<Field
																	as={Autocomplete}
																	name='location'
																	onInputChange={(_e, val) => handleSearchLocation(_e, val)}
																	onChange={(_opt, val) => {
																		prop.setValues({
																			...values,
																			location: val
																				? {
																					id: val.id,
																					complete_name: val.complete_name,
																					level: val.level,
																				}
																				: null,
																		})
																	}}
																	value={values.location}
																	getOptionLabel={(option) => option.complete_name || ''}
																	renderOption={(option) => handleRenderOption(option)}
																	getOptionSelected={(opt, value) =>
																		opt.complete_name == value.complete_name
																	}
																	loading={dataLocation.isLoading || locationLoading}
																	options={dataLocation.data}
																	renderInput={(params) => (
																		<Tooltip
																			title={values?.location?.complete_name || ''}
																			placement='bottom'
																			classes={{
																				tooltip: classes.tooltip,
																				tooltipPlacementBottom: classes.tooltipPlacementBottom,
																			}}
																		>
																			<TextField
																				{...params}
																				variant='outlined'
																				placeholder='Masukkan Provinsi, Kota, Kecamatan, Kelurahan, Kode Pos'
																				margin='dense'
																				name='location'
																				error={errors.location && touched.location}
																			/>
																		</Tooltip>
																	)}
																	noOptionsText={<i>Maaf, kata kunci tidak ditemukan</i>}
																	loadingText={<i>{locationLoadingWording}</i>}
																	ListboxProps={{
																		style: {
																			maxHeight: '370px',
																		},
																	}}
																	classes={{
																		noOptions: classes.noOptionText,
																		loading: classes.loadingText,
																	}}
																/>
																<ErrorMessage name='location'>
																	{(msg) => (
																		<Typography variant='caption' color='error'>
																			{msg}
																		</Typography>
																	)}
																</ErrorMessage>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={12}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={5}>
																<Typography
																	variant='subtitle1'
																	className={`${classes.labelRequired} ${classes.padTop}`}
																>
																	Alamat Lengkap
																</Typography>
															</Grid>
															<Grid item xs={7}>
																<Field
																	as={TextField}
																	variant='outlined'
																	color='primary'
																	placeholder='Masukkan Alamat Lengkap'
																	margin='dense'
																	fullWidth
																	error={errors.address && touched.address}
																	name='address'
																	multiline={true}
																	rows={3}
																/>
																<ErrorMessage name='address'>
																	{(msg) => (
																		<Typography variant='caption' color='error'>
																			{msg}
																		</Typography>
																	)}
																</ErrorMessage>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>

											{/* Right Column */}
											<Grid item xs={12} md={6}>
												<Grid container spacing={3}>
													<Grid item xs={12}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={5}>
																<Typography
																	variant='subtitle1'
																	className={`${classes.labelRequired} ${classes.padTop}`}
																>
																	PIC
																</Typography>
															</Grid>
															<Grid item xs={7}>
																<Field
																	as={TextField}
																	variant='outlined'
																	color='primary'
																	placeholder='Masukkan Nama PIC'
																	margin='dense'
																	fullWidth
																	error={errors.pic && touched.pic}
																	name='pic'
																/>
																<ErrorMessage name='pic'>
																	{(msg) => (
																		<Typography variant='caption' color='error'>
																			{msg}
																		</Typography>
																	)}
																</ErrorMessage>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={12}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={5}>
																<Typography
																	variant='subtitle1'
																	className={`${classes.labelRequired} ${classes.padTop}`}
																>
																	No. Ponsel
																</Typography>
															</Grid>
															<Grid item xs={7}>
																<Field
																	as={TextField}
																	variant='outlined'
																	color='primary'
																	placeholder='Masukkan No. Ponsel'
																	margin='dense'
																	fullWidth
																	error={errors.mobile && touched.mobile}
																	name='mobile'
																/>
																<ErrorMessage name='mobile'>
																	{(msg) => (
																		<Typography variant='caption' color='error'>
																			{msg}
																		</Typography>
																	)}
																</ErrorMessage>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={12}>
														<Grid container alignItems='flex-start'>
															<Grid item xs={5}>
																<Typography variant='subtitle1' className={classes.padTop}>
																	No. Telepon
																</Typography>
															</Grid>
															<Grid item xs={7}>
																<Field
																	as={TextField}
																	variant='outlined'
																	color='primary'
																	placeholder='Masukkan No. Telepon'
																	margin='dense'
																	fullWidth
																	error={errors.phone && touched.phone}
																	name='phone'
																/>
																<ErrorMessage name='phone'>
																	{(msg) => (
																		<Typography variant='caption' color='error'>
																			{msg}
																		</Typography>
																	)}
																</ErrorMessage>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Box>
									<Box pb={2} px={2}>
										<Collapse in={notifError}>
											<Box pb={1.5}>
												<Alert
													icon={false}
													severity='error'
													classes={{
														message: classes.alertMessage,
													}}
													action={
														<IconButton
															aria-label='close'
															size='small'
															className={classes.errorAlertCloseIcon}
															onClick={() => {
																setNotifError(false)
															}}
														>
															<HighlightOffOutlined fontSize='inherit' />
														</IconButton>
													}
												>
													{notifErrorMessage}
												</Alert>
											</Box>
										</Collapse>
									</Box>
								</Paper>
								<Box mt={2}>
									<Grid container justify='flex-end'>
										<Grid item>
											<Box>
												<Button
													size='medium'
													className={`${classes.buttonAction} ${classes.btnCancel}`}
													onClick={(event) => {
														event.preventDefault()
														navigate('/dashboard/company-address', {
															replace: true,
														})
													}}
												>
													Batal
												</Button>
												<Button
													size='medium'
													type='submit'
													className={`${classes.buttonAction} ${classes.btnSave}`}
												>
													Simpan
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

AddressAdd.propTypes = {
	addressID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

AddressAdd.defaultProps = {
	addressID: 0,
}

export default AddressAdd
