import { CATALOG, CATALOG_NEW_PRICE, GET_DETAIL_CATEGORY_SLUG } from '../types'
import API from '../../utils/api'
import Slugify from 'slugify'

const loading = (type) => {
	return {
		type: `${type}_PENDING`,
	}
}

const failure = (type, error) => {
	return {
		type: `${type}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (type, data) => {
	return {
		type: `${type}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestCatalog = ({ slug, queryString, auth }) => {
	//export const requestCatalog = ({ slug, queryString }) => {
	return async (dispatch) => {
		dispatch(loading(CATALOG))
		try {
			let newUri = ''
			let sort = 'updated'
			let filter = {}
			// let str = queryString.q ? queryString.q : ''
			if (queryString.q && queryString.q != '') {
				sort = 'relevance'
			}

			filter.active = 'true'

			if (slug != 'search') {
				filter.category_slug = slug
			}

			let strFilter =
				'{' +
				Object.keys(filter)
					.map((key) => '"' + key + '"' + ':' + '"' + filter[key] + '"')
					.join(',') +
				'}'

			if (queryString) {
				queryString.filter = strFilter
				newUri = Object.keys(queryString)
					.map((key) => key + '=' + queryString[key])
					.join('&')
			}
			const response = await API({
				method: 'get',
				url:
					'/catalogs' +
					(newUri != ''
						? `?ts=${new Date().getTime()}&` + 'sort=' + sort + '&' + newUri
						: `?ts=${new Date().getTime()}&` + 'sort=' + sort),
			})

			if (
				response.data.data.items.length == 1 &&
				queryString.q &&
				queryString.q.slice(0, 3).toUpperCase() == 'SKU'
			) {
				let variant_id
				variant_id =
					response.data.data.items[0].variant_id &&
					response.data.data.items[0].variant_id != null
						? response.data.data.items[0].variant_id
						: response.data.data.items[0].id
				const slug = Slugify(
					`${response.data.data.items[0].name} ${response.data.data.items[0].id} ${variant_id} `
				)
				window.location.href = `/catalog/detail/${slug}`
			}

			dispatch(success(CATALOG, response.data))

			// if (response.data && auth.loggedIn) {
			if (response.data) {
				dispatch(success(CATALOG_NEW_PRICE, null))
				response.data.data.items.map(async (val) => {
					let product_id = val.id
					let price_param_req = {}
					price_param_req.partner_id = 0
					if (response.data && auth.loggedIn) {
						price_param_req.partner_id = auth.data.data.companies.partner_id
					}
					price_param_req.pricelist_id = 0
					price_param_req.add_qty = 1
					price_param_req.product_id = 0
					price_param_req.product_template_id = product_id
					try {
						const resp_price = await API({
							method: 'POST',
							url: '/price-list/product',
							data: price_param_req,
						})
						if (resp_price) {
							if (resp_price.data.error_backend) {
								let error = {}
								error.err = resp_price.data.error_backend
								dispatch(failure(CATALOG_NEW_PRICE, error))
							} else {
								dispatch(success(CATALOG_NEW_PRICE, resp_price.data))
							}
						}
					} catch (err) {
						err.http_body
							? dispatch(failure(CATALOG_NEW_PRICE, err.http_body))
							: dispatch(failure(CATALOG_NEW_PRICE, err.http_body))
					}
				})
			}
		} catch (err) {
			dispatch(failure(CATALOG, err))
		}
	}
}

export const requestCatalogPricing = (items) => {
	return async (dispatch, getState) => {
		dispatch(loading(CATALOG_NEW_PRICE))
		const auth = getState().auth.data
		items.map(async (val) => {
			let price_param_req = {}
			price_param_req.partner_id = 0
			price_param_req.add_qty = 1
			price_param_req.product_id = 0
			price_param_req.pricelist_id = 0
			if (auth) price_param_req.partner_id = auth.data.companies.partner_id
			price_param_req.product_template_id = val.id
			dispatch(loading(CATALOG_NEW_PRICE))
			try {
				const price = await API({
					method: 'POST',
					url: '/price-list/product',
					data: price_param_req,
				})
				dispatch(success(CATALOG_NEW_PRICE, price.data))
			} catch (err) {
				dispatch(failure(CATALOG_NEW_PRICE, err))
			}
		})
	}
}

export const getDetailCategoriesSlug = (slug) => {
	return async (dispatch) => {
		dispatch(loading(GET_DETAIL_CATEGORY_SLUG))
		try {
			const response = await API({
				method: 'GET',
				url: `categories/detail-slugs?slug=${slug}`,
			})
			dispatch(success(GET_DETAIL_CATEGORY_SLUG, response.data))
		} catch (e) {
			console.log(e)
			dispatch(failure(GET_DETAIL_CATEGORY_SLUG, e))
		}
	}
}
