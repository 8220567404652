import { USERS_ACCOUNT_DETAIL } from '../../types'
import API from '../../../utils/api'
import { setGlobalLoading } from '../globalAction'

export const resetAction = () => {
	return {
		type: `${USERS_ACCOUNT_DETAIL}_RESET`,
	}
}

const loading = () => {
	return {
		type: `${USERS_ACCOUNT_DETAIL}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${USERS_ACCOUNT_DETAIL}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${USERS_ACCOUNT_DETAIL}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestUserAccountDetail = (id) => {
	return async (dispatch) => {
		dispatch(loading())
		dispatch(setGlobalLoading(true))
		try {
			let response = await API({
				method: 'GET',
				url: '/users/detail/' + id,
			})

			dispatch(success(response.data))
			dispatch(setGlobalLoading(false))
		} catch (err) {
			dispatch(failure(err))
			dispatch(setGlobalLoading(false))
		}
	}
}
