import React from 'react'
import LoginLayout from 'Components/loginLayout'
import { loginStyle } from '../Login/styles'
import { ResetPasswordForm } from 'Components/forms'
import { Grid, Typography, Button } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'
import { navigate } from '@reach/router'
import { useSelector } from 'react-redux'

const ResetPassword = () => {
	const handleNavigate = (path) => {
		navigate(path)
	}
	const handleRedirect = (path) => {
		window.location.href = path
	}
	const classes_loginStyle = loginStyle()
	const resetPasswordReducer = useSelector((state) => state.resetPasswordReducer)
	return (
		<LoginLayout
			classes={classes_loginStyle}
			type={
				resetPasswordReducer.resetPassword.data &&
				resetPasswordReducer.resetPassword.isError == false &&
				resetPasswordReducer.resetPassword.data.contact_id
					? 'message'
					: 'form'
			}
		>
			{resetPasswordReducer.resetPassword.data &&
			resetPasswordReducer.resetPassword.isError == false &&
			resetPasswordReducer.resetPassword.data.contact_id ? (
				<Grid>
					<Typography
						className={classes_loginStyle.title}
						text-align='center'
						variant='h6'
					>
						Reset Kata Sandi Berhasil
					</Typography>
					<CheckCircle style={{ color: green[500] }} fontSize='large' />
					<Typography variant='body2'>
						Kata Sandi berhasil diperbarui. <br />
						Silakan melakukan login untuk mulai menggunakan platform Mbiz.co.id
					</Typography>
					<Grid>
						<Button
							style={{ margin: '5px' }}
							size='default'
							type='submit'
							variant='contained'
							color='secondary'
							onClick={() => handleNavigate('/login')}
						>
							Pergi ke Halaman Login
							{/* {data.isLoading ? <CircularProgress /> : 'Masuk'} */}
						</Button>
						<Button
							style={{ margin: '5px' }}
							size='default'
							type='submit'
							variant='contained'
							color='secondary'
							onClick={() => handleRedirect('/')}
						>
							Pergi ke Halaman Beranda
							{/* {data.isLoading ? <CircularProgress /> : 'Masuk'} */}
						</Button>
					</Grid>
				</Grid>
			) : (
				<ResetPasswordForm classes={classes_loginStyle} />
			)}
		</LoginLayout>
	)
}

export default ResetPassword
