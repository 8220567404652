import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import LayoutComponent from '../layout'
import Logo from '../../assets/images/svgs/logo-white.svg'
import PropTypes from 'prop-types'

const handleNavigate = (path) => {
	// navigate(path)
	window.location.href = path
}

const LoginLayout = ({ children, classes, type = 'form' }) => {
	const classes_loginStyle = classes
	return (
		<LayoutComponent
			header={false}
			newsletter={false}
			className={classes_loginStyle.login_background}
			variant='light'
		>
			{type == 'form' ? (
				<Grid className={classes_loginStyle.form_login} container>
					<Grid item xs={12} sm={2}></Grid>
					<Grid item xs={12} sm={4} className={classes_loginStyle.container}>
						<div className={classes_loginStyle.container_content}>
							<img
								onClick={() => handleNavigate('/')}
								src={Logo}
								className={classes_loginStyle.mbiz_image}
							/>
							<div className={classes_loginStyle.small}>
								Platform <span className={classes_loginStyle.text_warning}>B2B</span>{' '}
								Indonesia
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={4} className={classes_loginStyle.container_field}>
						{children}
					</Grid>
					<Grid item xs={12} sm={2}></Grid>
				</Grid>
			) : type == 'message' ? (
				<Paper className={classes_loginStyle.message_container} variant='outlined'>
					{children}
				</Paper>
			) : type == 'messageSuccess' ? (
				<Paper className={classes_loginStyle.successForgot} variant='outlined'>
					{children}
				</Paper>
			) : null}
		</LayoutComponent>
	)
}

LoginLayout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	classes: PropTypes.object,
	type: PropTypes.string,
}
export default LoginLayout
