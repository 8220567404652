import { BANK_CREATE } from '../../types'
import API from '../../../utils/api'
import { navigate } from 'gatsby'
import { clearDeleteBank } from './deleteAction'
import { clearUpdateBank } from './updateAction'
// import { newUriAPI } from '../../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${BANK_CREATE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${BANK_CREATE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${BANK_CREATE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const clearCreateBank = () => {
	return {
		type: `${BANK_CREATE}_CLEAR`,
	}
}

export const createBank = ({
	acc_number,
	bank_id,
	branch,
	acc_holder_name,
}) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			const response = await API({
				method: 'POST',
				url: '/master/bank/create',
				data: { acc_number, bank_id, branch, acc_holder_name },
			})

			if ('error_backend' in response.data) {
				dispatch(failure(response.data.error_backend.http_body))
			} else {
				dispatch(success(response.data))
				dispatch(clearDeleteBank())
				dispatch(clearUpdateBank())
				navigate('/dashboard/bank-account')
			}
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
