import API from '../../../utils/api'

export const createBudget = (values) => {
	return API({
		url: '/budgets/create',
		method: 'POST',
		data: {
			name: values.name,
			descriptions: values.descriptions,
			date_start: values.startDate,
			date_stop: values.endDate,
			auto_non_active: values.checked,
			total: values.total,
			status: values.status,
			department_id: values.departemen,
		},
	})
}

export const updateBudget = (values, idBudget) => {
	return API({
		url: `/budgets/update/${idBudget}`,
		method: 'PUT',
		data: {
			name: values.name,
			descriptions: values.descriptions,
			date_start: values.startDate,
			date_stop: values.endDate,
			auto_non_active: values.checked,
			total: values.total,
			status: values.status,
			department_id: values.departemen,
		},
	})
}
