import Numeral from 'numeral'

export const formatMoney = (value) => {
	let rupiah = ''
	let val = value
	if (val == undefined) val = ''
	let angkaRev = val.toString().split('').reverse().join('')
	for (let i = 0; i < angkaRev.length; i++) {
		if (i % 3 == 0) rupiah += angkaRev.substr(i, 3) + '.'
	}
	return (
		'Rp ' +
		rupiah
			.split('', rupiah.length - 1)
			.reverse()
			.join('')
	)
}

export const numeralFormat = (value) => {
	let formated = 'Rp ' + Numeral(value).format('0,0.00')
	return formated
}
