import { CONFIRM_PAYMENT, CONFIRM_BULK_PAYMENT } from '../../../types'
import API from '../../../../utils/api'
import { requestDetailInvoiceAPI } from 'States/actions/Transactions/Invoice/detailAction'
import { navigate } from 'gatsby'

export const confirmsinglePayment = async (data, invoiceId) => {
	try {
		const getDetailInvoice = await requestDetailInvoiceAPI(invoiceId)
		if (getDetailInvoice.data.data.state == 'draft') {
			return navigate('/404')
		}

		return {
			type: CONFIRM_PAYMENT,
			payload: API({
				method: 'POST',
				url: '/payment/confirmation/',
				data: {
					communication: data.communication,
					amount: Number(data.amount),
					mbiz_bank_account: data.mbiz_bank_account,
					partner_bank_account: data.partner_bank_account,
					payment_date: data.payment_date,
					payment_notes: data.payment_notes,
					internal_code: data.internal_code,
					storage_id: data.storage_id,
				},
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		}
	} catch (err) {
		navigate('/404')
	}
}

export const confirmBulkPayment = (data) => {
	return {
		type: CONFIRM_BULK_PAYMENT,
		payload: API({
			method: 'POST',
			url: '/payment/confirmation/',
			data: {
				communication: data.communication.join(','),
				amount: Number(data.amount),
				mbiz_bank_account: data.mbiz_bank_account,
				partner_bank_account: data.partner_bank_account,
				payment_date: data.payment_date,
				payment_notes: data.payment_notes,
				internal_code: data.internal_code,
				storage_id: data.storage_id,
			},
		}),
	}
}
