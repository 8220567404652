import { PAYMENT_DETAIL } from '../../../types'
import API from '../../../../utils/api'
import { success, failure, loading } from '../../../commonActions'

export const requestDetailPayment = (paymentID) => {
	return async (dispatch) => {
		dispatch(loading(PAYMENT_DETAIL))
		try {
			const response = await API({
				url: `/payment/details/${paymentID}`,
			})
			dispatch(success(PAYMENT_DETAIL, response.data))
		} catch (err) {
			dispatch(failure(PAYMENT_DETAIL))
		}
	}
}

export const resetAction = () => {
	return {
		type: `${PAYMENT_DETAIL}_RESET`,
	}
}