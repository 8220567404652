import { makeStyles } from '@material-ui/core/styles'
import bg1 from '../../assets/images/svgs/howto/howto-bg1.svg'
import bg2 from '../../assets/images/svgs/howto/howto-bg2.svg'
import bg3 from '../../assets/images/svgs/howto/howto-bg3.svg'
import bg4 from '../../assets/images/svgs/howto/howto-bg4.svg'
import bg5 from '../../assets/images/svgs/howto/howto-bg5.svg'

export const HowToStyle = makeStyles((theme) => ({
	Boxes: {
		position: 'relative',
		margin: 24,
		boxShadow: '#ccc 3px 3px 4px',
		borderRadius: '5%',
	},
	bg1: {
		background: `url(${bg1}) no-repeat center 20% ${theme.palette.background.surface1}`,
		backgroundSize: '63%',
	},
	bg2: {
		background: `url(${bg2}) no-repeat center 20% ${theme.palette.background.surface1}`,
		backgroundSize: '60%',
	},
	bg3: {
		background: `url(${bg3}) no-repeat center 20% ${theme.palette.background.surface1}`,
		backgroundSize: '60%',
	},
	bg4: {
		background: `url(${bg4}) no-repeat center 20% ${theme.palette.background.surface1}`,
		backgroundSize: '70%',
	},
	bg5: {
		background: `url(${bg5}) no-repeat center 20% ${theme.palette.background.surface1}`,
		backgroundSize: '47%',
	},
}))
