import { BANK_ACCOUNT } from '../types'
import { success, failure, loading } from '../commonActions'
import API from '../../utils/api'

export const requstBankAccount = (type, account) => {
	return async (dispatch) => {
		dispatch(loading(`${BANK_ACCOUNT}_${type}`))
		try {
			const response = await await API({
				method: 'GET',
				url: `/master/bank/list?type=${account}`,
			})
			dispatch(success(`${BANK_ACCOUNT}_${type}`, response.data))
		} catch (err) {
			dispatch(failure(`${BANK_ACCOUNT}_${type}`))
		}
	}
}
