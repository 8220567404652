import { makeStyles } from '@material-ui/core/styles'

export const listAnggaranStyle = makeStyles((theme) => ({
	container: {
		overflow: 'hidden',
	},
	gridSearch: {
		width: '100%',
	},
	customField: {
		width: '275px',
	},
	boxStatuses: {
		marginBottom: '15px',
	},
	buttonAdd: {
		backgroundColor: theme.palette.warning.contrastText,
		color: theme.palette.common.white,
	},
	gridListButton: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	customButton: {
		fontSize: '12px',
	},
	floatRight: {
		float: 'right',
	},
	floatLeft: {
		float: 'left',
	},
	buttonAction: {
		margin: '0 5px',
		padding: '4px 16px',
		minWidth: 0,
	},
	btnEdit: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnDelete: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	buttonSearch: {
		padding: '7px 9px 6px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: theme.shape.borderRadius,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	searchDepartment: {
		width: 300,
		'&>div>input': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			padding: '9px 0 8px 18px',
		},
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		padding: '4px 6.7px',

		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	selectContainer: {
		borderRadius: theme.shape.borderRadius,
		paddingRight: '45px !important',
		marginRight: theme.spacing(0.5),
	},
	selectInput: {
		padding: '9px 0 8px 18px !important',
	},
	inputRoot: {
		paddingRight: '0 !important',
	},
	endAdornment: {
		top: 0,
	},
	infoBox: {
		padding: '13px',
		display: 'flex',
		alignItems: 'center',
	},
	infoBoxIcon: {
		fontSize: 28,
		marginRight: 15,
	},
}))
