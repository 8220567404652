import React from 'react'
import Styles from './styles'
import { Cat10 } from '../svgs'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@material-ui/core'
import { getMappingIcon } from '../../utils/category'
import { addCategoryForRef } from 'States/actions/Category/categoryRefAction'
import { useDispatch } from 'react-redux'
import { Link } from 'gatsby'

const Component = ({
	categories,
	m_top = '',
	type = 'navigate',
	isCategories,
}) => {
	// const handleNavigate = slug => {
	// 	window.location.href = `/catalog/${slug}`
	// }
	const dispatch = useDispatch()
	const onClickHandler = (data) => {
		if (type == 'navigate') {
			window.location.href = `/catalog/${data}`
		} else {
			dispatch(addCategoryForRef(data))
		}
	}

	const classes = Styles()
	const icons_map = getMappingIcon({
		color: 'white',
		width: '50px',
		height: '60px',
	})

	return (
		<Paper
			elevation={0}
			className={classes.categoryContainer}
			style={{ marginTop: m_top }}
		>
			{categories.length != 0 &&
				categories.map((item, index) => (
					<>
						{isCategories ? (
							<div
								onClick={() => onClickHandler(type == 'navigate' ? item.slug : index)}
								className={classes.categoryItem}
							>
								<Paper
									style={{ cursor: 'pointer' }}
									elevation={0}
									className={classes.categoryIcon}
								>
									{icons_map ? (
										icons_map[item.slug] ? (
											icons_map[item.slug]
										) : (
											<Cat10 stroke='white' width='50px' height='60px' />
										)
									) : (
										<Cat10 stroke='white' width='50px' height='60px' />
									)}
								</Paper>
								<Typography className={classes.categoryTitle}>
									{item.category_name}
								</Typography>
							</div>
						) : (
							<Link
								to={`/catalog/${item.slug}`}
								style={{ textDecoration: 'none', color: 'inherit' }}
								key={item.id.toString()}
							>
								<div
									onClick={() => onClickHandler(type == 'navigate' ? item.slug : index)}
									className={classes.categoryItem}
								>
									<Paper
										style={{ cursor: 'pointer' }}
										elevation={0}
										className={classes.categoryIcon}
									>
										{icons_map ? (
											icons_map[item.slug] ? (
												icons_map[item.slug]
											) : (
												<Cat10 stroke='white' width='50px' height='60px' />
											)
										) : (
											<Cat10 stroke='white' width='50px' height='60px' />
										)}
									</Paper>
									<Typography className={classes.categoryTitle}>
										{item.category_name}
									</Typography>
								</div>
							</Link>
						)}
					</>
				))}
		</Paper>
	)
}

Component.propTypes = {
	categories: PropTypes.array,
	m_top: PropTypes.string,
	type: PropTypes.string,
	isCategories: PropTypes.bool,
}

export default Component
