import React, { useState, useEffect } from 'react'
import LayoutComponent from 'Components/layout'
import {
	Box,
	Grid,
	CircularProgress,
	TextField,
	IconButton,
	Container,
	Typography,
} from '@material-ui/core'
import CardListing from 'Components/cardListing'
import { SearchRounded } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import Slugify from 'slugify'
import { searchStyle } from './styles'
import { formatMoney } from '../../utils/formatMoney'
import PropTypes from 'prop-types'
import image_not_found from '../../assets/images/content/image_not_found.png'
import Pagination from 'rc-pagination'
import localeInfo from 'rc-pagination/lib/locale/id_ID'
import { chunkSizeArray } from '../../utils/globalUtils'
import error404 from '../../assets/images/background/xsearch.png'
import SEO from 'Components/seo'
import { getPromoPages } from 'States/actions/Promo/promoAction'
import { titleTagPromotion, searchNameProduct } from './helper'

const apiUrl = process.env.GATSBY_IMAGE_URL

const Promo = () => {
	const dispatch = useDispatch()
	const [name, setName] = useState('')
	const [dataList, setDataList] = useState([])
	const [totalRecord, setTotalRecord] = useState(1)
	const [pageNow, setPageNow] = useState(1)
	const [dataPromo, setDataPromo] = useState([])
	const [tag, setTag] = useState('')
	const [loading, setLoading] = useState(true)
	const [errSearch, setErrSearch] = useState('')
	const [error, setError] = useState(false)
	const perPage = 24
	let queryString = ''

	if (typeof window !== 'undefined') {
		queryString = window.location.pathname
	}
	const customeLocalInfo = localeInfo
		? ((localeInfo.jump_to = 'Menuju halaman ke'), localeInfo)
		: null

	const classes = searchStyle()
	let tempNameImg = queryString.split('/')

	const handleNavigate = (path, productId, productName) => {
		const slug = Slugify(`${productName} ${productId} ${productId} `)
		return `${path}/${slug}`
	}

	const handelBeranda = (path) => {
		window.location.href = path
	}

	const dataPromoPages = useSelector((state) => state.promoPages)
	const auth = useSelector((state) => state.auth)
	const catalogPrice = useSelector((state) => state.catalogReducer.catalogPrice)

	useEffect(() => {
		dispatch(getPromoPages(tempNameImg[2], auth))
		let temp = titleTagPromotion(tempNameImg[2])
		setTag(temp)
	}, [])

	// hooks for set default state
	useEffect(() => {
		// success
		if (dataPromoPages.data && dataPromoPages.data.items) {
			setLoading(dataPromoPages.isLoading)
			let result = dataPromoPages.data.items
			let dataTemp = result.length != 0 ? chunkSizeArray(result, 24) : []
			setDataPromo(dataTemp[pageNow - 1])
			setDataList(dataTemp)
			setTotalRecord(result.length)
			setError(dataPromoPages.isError)
		} else {
			setLoading(dataPromoPages.isLoading)
			setDataPromo([])
			setDataList([])
			setTotalRecord(0)
			setError(true)
		}
	}, [dataPromoPages])

	const handleEnter = (e) => {
		if (e.key === 'Enter') {
			handleNameSearch(name)
		}
	}

	const handleNameSearch = (val) => {
		setErrSearch(val)
		let res = searchNameProduct(
			dataPromoPages.data && dataPromoPages.data.items
				? dataPromoPages.data.items
				: [],
			val
		)
		setPageNow(1)
		setTotalRecord(res.length)
		let dataTemp = res.length != 0 ? chunkSizeArray(res, 24) : []
		setDataPromo(dataTemp[0])
	}

	const handleSearchName = (value) => {
		setName(value)
	}

	const handleChangePagination = (e) => {
		setDataPromo(dataList[e - 1])
		setPageNow(e)
	}

	const handlePrice = (index, id) => {
		let price = ''
		catalogPrice.data.map((val) => {
			if (val.result.product_template_id == id) {
				price = formatMoney(val.result.lowest_price)
			}
		})
		return price
	}

	const render404 = (isError) => {
		return (
			<Box p={5} textAlign='center'>
				<img src={error404} style={{ width: '40%' }} />
				<Box mt={2}>
					<Typography variant='h6'>{isError ? 'Error: 404' : ''}</Typography>
					<Typography variant='body1'>
						<b>
							{isError
								? 'Mohon maaf, halaman yang Anda cari tidak ditemukan.'
								: `Mohon maaf, hasil pencarian kata kunci "${errSearch}" tidak ditemukan.`}
						</b>
					</Typography>
					{isError ? (
						<Typography variant='body1' style={{ cursor: 'pointer' }}>
							Silahkan kembali ke <a onClick={() => handelBeranda('/')}>beranda</a>
						</Typography>
					) : (
						<Typography variant='body1'>Silahkan coba kata kunci lainnya.</Typography>
					)}
				</Box>
			</Box>
		)
	}

	return (
		<LayoutComponent>
			<Container>
				<SEO
					title={tag ? tag.title : ''}
					description={tag ? tag.description : ''}
				/>
				{loading ? null : error ? (
					render404(true)
				) : (
					<Box>
						<img
							src={
								loading
									? image_not_found
									: error
									? image_not_found
									: require(`../../assets/images/content/${tempNameImg[2]}.png`).default
							}
							style={{
								flex: 1,
								width: '100%',
								height: loading ? 200 : error ? 200 : '100%',
							}}
						/>
						<Grid container className={classes.listContainer}>
							<Typography variant='h1' className={classes.fontSizeh1}>
								{tag ? tag.title : ''}
							</Typography>
							<Grid
								container
								direction='row'
								justify='flex-end'
								alignItems='flex-start'
								className={classes.listSearch}
							>
								<TextField
									placeholder='Cari Produk'
									type='search'
									className={classes.gridSearch}
									InputProps={{
										disableUnderline: false,
									}}
									onKeyDown={(e) => handleEnter(e)}
									onChange={(e) => {
										handleSearchName(e.target.value)
									}}
								/>
								<IconButton
									variant='contained'
									color='primary'
									className={classes.buttonSearch}
									onClick={() => handleNameSearch(name)}
								>
									<SearchRounded fontSize='small' />
								</IconButton>
							</Grid>

							{dataPromo && dataPromo.length != 0 ? (
								<Box className={classes.gridList}>
									{dataPromo.map((val, index) =>
										val.can_be_sale && val.active ? (
											<CardListing
												href={handleNavigate('/catalog/detail', val.id, val.name)}
												key={index}
												price={
													catalogPrice.isLoading == false ? (
														handlePrice(index, val.id)
													) : (
														<CircularProgress />
													)
												}
												alert={val.variant_list.length > 1 ? true : false}
												alertText='Produk tersedia dengan pilihan'
												title={val.name}
												media={
													val.image != ''
														? `${apiUrl}/180x180/erp/${val.image}`
														: image_not_found
												}
											/>
										) : null
									)}
								</Box>
							) : name != '' || errSearch != '' ? (
								render404(false)
							) : null}
							{totalRecord > 24 ? (
								<Grid
									container
									direction='row'
									justify='flex-end'
									alignItems='flex-start'
									className={classes.listPagination}
								>
									<Pagination
										current={pageNow}
										total={totalRecord}
										pageSize={perPage}
										showPrevNextJumpers
										showQuickJumper
										locale={customeLocalInfo}
										onChange={(e) => handleChangePagination(e)}
									/>
								</Grid>
							) : null}
						</Grid>
					</Box>
				)}
			</Container>
		</LayoutComponent>
	)
}

Promo.propTypes = {
	navigate: PropTypes.func,
}

export default Promo
