import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import {
	Typography,
	TextField,
	Button,
	Grid,
	Box,
	Divider,
	CircularProgress,
} from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import PropTypes from 'prop-types'
import schema from './schema'
import Alert from 'Components/alert'
import { useDispatch, useSelector } from 'react-redux'
import {
	contactUsMailAction,
	resetAction,
} from 'States/actions/ContactUs/contactUsAction'
import CustomModal from 'Components/modal'

const ContactForm = ({ classes }) => {
	const contactUsData = useSelector((state) => state.ContactUsReducer)
	const recaptchaKey = process.env.GATSBY_RECAPTCHA_KEY
	const [modalNotif, setModalNotif] = useState(false)
	const [notifSuccess, setNotifSuccess] = useState(false)
	// uncomment this if want to show error message form backend
	// const [errorBackend, setErrorBackend] = useState({})
	const CHARACTER_LIMIT = {
		nama: 50,
		judul: 50,
		email: 100,
		pesan: 3000,
	}
	const dispatch = useDispatch()

	const handleSubmitContactUs = (val) => {
		const myState = {
			name: val.nama,
			message_title: val.judul,
			email: val.email,
			message_content: val.pesan,
		}
		dispatch(contactUsMailAction({ ...myState }))
	}

	const handleModalClose = () => {
		setModalNotif(false)
		if (notifSuccess) {
			dispatch(resetAction())
			// navigate('/')
			window.location.href = '/'
		}
	}

	useEffect(() => {
		// uncomment this if want show error message from backend, and put errorBackend object to alert
		// if (contactUsData.error) {
		// 	if (contactUsData.error.http_body) {
		// 		let http_body = contactUsData.error.http_body
		// 		if (http_body) {
		// 			let body_obj = JSON.parse(http_body)
		// 			setErrorBackend(body_obj)
		// 		}
		// 	}
		// 	setNotifSuccess(false)
		// }

		// set notif success
		if (!contactUsData.isError && contactUsData.data) {
			setNotifSuccess(true)
		}

		// show modal
		if (contactUsData.data) {
			dispatch(resetAction())
			setModalNotif(true)
		}
	}, [contactUsData])

	return (
		<Box>
			{contactUsData.isError ? (
				<Grid container justify='center' style={{ marginBottom: '25px' }}>
					<Grid item lg={10} md={10} sm={10} xs={10}>
						<Alert variant='alert'>
							<Typography variant='body1'>
								Pesan Gagal Dikirim ke help@mbiz.co.id
							</Typography>
						</Alert>
					</Grid>
				</Grid>
			) : null}
			<Box paddingX={2}>
				<Typography
					variant='body1'
					className={`${classes.detailTitle} ${classes.formTitle}`}
					gutterBottom
				>
					Hubungi kami lewat form
				</Typography>
				<Divider />
			</Box>
			<Grid container justify='center'>
				<Grid item lg={10} md={10} sm={10} xs={10}>
					<Formik
						initialValues={{ nama: '', judul: '', email: '', pesan: '', captcha: '' }}
						validationSchema={schema}
						onSubmit={(val) => handleSubmitContactUs(val)}
					>
						{({
							errors,
							handleChange,
							handleSubmit,
							values,
							touched,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit} className={classes.form}>
								<label>Nama Lengkap</label>
								<TextField
									className={classes.inputField}
									variant='outlined'
									fullWidth
									size='small'
									margin='dense'
									onChange={handleChange}
									value={values.nama}
									inputProps={{
										maxLength: CHARACTER_LIMIT.nama,
									}}
									helperText={
										values.nama.length > 0
											? `${values.nama.length}/${CHARACTER_LIMIT.nama}`
											: false
									}
									name='nama'
									type='input'
								/>
								<Typography
									variant='caption'
									display='block'
									className={classes.validationText}
								>
									{errors.nama && touched.nama ? (
										<Grid className={classes.validationTextItem}>
											<Warning style={{ fontSize: '12px', marginRight: '5px' }} />{' '}
											{errors.nama}
										</Grid>
									) : null}
								</Typography>
								<label>Email</label>
								<TextField
									className={classes.inputField}
									variant='outlined'
									fullWidth
									size='small'
									margin='dense'
									onChange={handleChange}
									value={values.email}
									inputProps={{
										maxLength: CHARACTER_LIMIT.email,
									}}
									helperText={
										values.email.length > 0
											? `${values.email.length}/${CHARACTER_LIMIT.email}`
											: false
									}
									name='email'
									type='email'
								/>
								<Typography
									variant='caption'
									display='block'
									className={classes.validationText}
								>
									{errors.email && touched.email ? (
										<Grid className={classes.validationTextItem}>
											<Warning style={{ fontSize: '12px', marginRight: '5px' }} />{' '}
											{errors.email}
										</Grid>
									) : null}
								</Typography>
								<label>Judul</label>
								<TextField
									className={classes.inputField}
									variant='outlined'
									fullWidth
									size='small'
									margin='dense'
									onChange={handleChange}
									value={values.judul}
									inputProps={{
										maxLength: CHARACTER_LIMIT.judul,
									}}
									helperText={
										values.judul.length > 0
											? `${values.judul.length}/${CHARACTER_LIMIT.judul}`
											: false
									}
									name='judul'
									type='input'
								/>
								<Typography
									variant='caption'
									display='block'
									className={classes.validationText}
								>
									{errors.judul && touched.judul ? (
										<Grid className={classes.validationTextItem}>
											<Warning style={{ fontSize: '12px', marginRight: '5px' }} />{' '}
											{errors.judul}
										</Grid>
									) : null}
								</Typography>
								<label>Pesan Anda</label>
								<TextField
									className={classes.textareaField}
									variant='outlined'
									fullWidth
									size='small'
									margin='dense'
									multiline
									rows={4}
									onChange={handleChange}
									value={values.pesan}
									inputProps={{
										maxLength: CHARACTER_LIMIT.pesan,
									}}
									helperText={
										values.pesan.length > 0
											? `${values.pesan.length}/${CHARACTER_LIMIT.pesan}`
											: false
									}
									name='pesan'
									type='input'
								/>
								<Typography
									variant='caption'
									display='block'
									className={classes.validationText}
								>
									{errors.pesan && touched.pesan ? (
										<Grid className={classes.validationTextItem}>
											<Warning style={{ fontSize: '12px', marginRight: '5px' }} />{' '}
											{errors.pesan}
										</Grid>
									) : null}
								</Typography>
								<ReCAPTCHA
									name='captcha'
									sitekey={recaptchaKey}
									onChange={(response) => {
										setFieldValue('captcha', response)
									}}
								/>
								<Typography
									variant='caption'
									display='block'
									className={classes.validationText}
								>
									{errors.captcha && touched.captcha ? (
										<Grid className={classes.validationTextItem}>
											<Warning style={{ fontSize: '12px', marginRight: '5px' }} />{' '}
											{errors.captcha}
										</Grid>
									) : null}
								</Typography>
								<Button
									size='small'
									type='submit'
									variant='contained'
									color='secondary'
									className={classes.formButton}
								>
									{contactUsData.isLoading ? <CircularProgress size={28} /> : 'Kirim'}
								</Button>
							</form>
						)}
					</Formik>
					<CustomModal
						icon={notifSuccess ? 'success' : 'error'}
						open={modalNotif}
						onClose={() => handleModalClose()}
					>
						<Typography variant='h6'>
							{notifSuccess ? (
								<strong>Pesan Berhasil Dikirim ke help@mbiz.co.id</strong>
							) : (
								<strong>Pesan Gagal Dikirim ke help@mbiz.co.id</strong>
							)}
						</Typography>
						<Box
							display='flex'
							flexDirection='row'
							mt={3}
							justifyContent='center'
							// width={300}
						>
							<Button
								className={classes.buttonWidth}
								color='secondary'
								size='small'
								classes={{
									contained: classes.buttonDanger,
								}}
								variant='contained'
								onClick={() => handleModalClose()}
							>
								{' '}
								OK{' '}
							</Button>
						</Box>
					</CustomModal>
				</Grid>
			</Grid>
		</Box>
	)
}

ContactForm.propTypes = {
	classes: PropTypes.object,
}

export default ContactForm
