export const loadNameFile = (query) => {
	let res = ''
	if (query == 'bank-account') {
		res = 'Daftar Akun Bank'
	} else if (query == 'address') {
		res = 'Daftar Alamat'
	} else if (query == 'user-account') {
		res = 'Daftar Akun Pengguna'
	} else if (query == 'department') {
		res = 'Daftar Departemen'
	} else if (query == 'approval-category') {
		res = 'Daftar Penyetuju Kategori'
	} else if (query == 'approval-department') {
		res = 'Daftar Penyetuju Departemen'
	} else if (query == 'approval-eprocurement') {
		res = 'Daftar E-procrument'
	}
	return res
}
