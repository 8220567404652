const stylesHTML = {
	textDescOptionSelect: {
		fontSize: '12px',
		color: '#6F757C',
	},
	textTitleOptionSelect: {
		fontSize: '16px',
		paddingLeft: 10,
	},
	padding5: {
		padding: 5,
	},
	textDescOptionPadding: {
		fontSize: '12px',
		color: '#6F757C',
		paddingLeft: 20,
	},
	textcolorError: {
		fontSize: '12px',
		color: '#DC3545',
	},
	styleDesc: {
		flexDirection: 'row',
		paddingLeft: 20,
	},
}

export default stylesHTML
