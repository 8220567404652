import { makeStyles } from '@material-ui/core/styles'

export const homepageStyle = makeStyles((theme) => ({
	banner: {
		display: 'grid',
		gridTemplateColumns: '1.5fr .5fr',
		gridTemplateRows: '1.3fr 1fr',
		height: 400,
		marginTop: theme.spacing(2.5),
		gridGap: theme.spacing(0.5),
		overflow: 'hidden',
		'& img': {
			width: '100%',
			height: 'auto',
			objectFit: 'contain',
		},
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: '1fr 1fr',
			height: 'auto',
		},
	},
	bannerSlider: {
		gridRow: '1/3',
		overflow: 'hidden',
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			gridRow: '1/2',
			gridColumn: '1/3',
		},
		'&:hover': {
			'& .slick-prev,.slick-next': {
				display: 'block!important',
				width: 'auto',
				height: 'auto',
			},
		},
		'& .slick-prev': {
			left: 10,
			display: 'none!important',
			zIndex: 1,
			'&:before': {
				fontSize: 40,
			},
		},
		'& .slick-next': {
			right: 10,
			zIndex: 1,
			display: 'none!important',
			'&:before': {
				fontSize: 40,
			},
		},
		'& .slick-dots li': {
			width: 32,
			margin: '0 2px',
		},
		'& .slick-active .custom-dots': {
			background: '#ffffff',
		},
		'& .custom-dots': {
			height: 4,
			width: '100%',
			background: '#ffffff8c',
		},
		'& .slick-dots': {
			transform: 'translateY(-20px)',
		},
	},
	bannerSmall: {
		background: 'white',
	},
	promoContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		marginTop: theme.spacing(2.5),
		gridColumnGap: theme.spacing(2.5),
		width: '100%',
		justifyItems: 'center',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: 'repeat(3, 1fr)',
			gridRowGap: theme.spacing(1),
		},
		[theme.breakpoints.down('xs')]: {
			gridTemplateRows: 'repeat(3, 1fr)',
			gridRowGap: theme.spacing(1),
			gridTemplateColumns: '1fr',
		},
	},
	promoImageContainer: {
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		cursor: 'pointer',
		width: '100%',
		'& img': {
			width: '100%',
			height: 'auto',
		},
		// '&:hover div': {
		// 	right: 0,
		// },
	},
	promoTextContainer: {
		position: 'absolute',
		left: 0,
		right: '50%',
		bottom: 0,
		top: 0,
		backgroundColor: 'rgba(0,0,0,0.5)',
		display: 'flex',
		alignItems: 'flex-end',
		padding: 10,
		paddingBottom: theme.spacing(2),
		color: theme.palette.common.white,
		transition: 'right 0.3s ease-out',
		'& h6': {
			width: 150,
		},
	},
	partnerContainer: {
		'& div.slick-track': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		'& .slick-slide': {
			width: '100% !important',
			[theme.breakpoints.down('md')]: {
				display: 'flex',
				justifyContent: 'center',
			},
		},
		'& .slick-prev::before,.slick-next::before': {
			color: '#000',
		},
		'& .slick-arrow': {
			height: '30px!important',
			width: '30px!important',
		},
		'& .slick-arrow::before, .slick-arrow.slick-disabled::before': {
			display: 'block',
			opacity: 0.25,
			width: 18,
			height: 18,
			position: 'absolute',
			top: '50%',
			left: 'calc(50% - 1px)',
			transform: 'translate(-50%, -50%)',
			mozTransform: 'translate(-50%, -52%)',
		},
	},
	sliderItem: {
		display: 'flex !important',
		alignItems: 'center',
		justifyContent: 'center',
	},
	iconVisi: {
		height: 70,
		display: 'flex',
		alignItems: 'center',
	},
	containerVisi: {
		margin: 'auto',
		'& .item': {
			marginTop: 30,
		},
	},
	visiText: {
		width: 215,
		height: 59.75,
		textAlign: 'center',
	},
	containerImg: {
		'& img': {
			width: '100%',
			height: 'auto',
		},
	},
	titlePartner: {
		marginBottom: theme.spacing(2),
	},
}))
