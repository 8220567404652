import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
	Stepper,
	Step,
	StepLabel,
	StepConnector,
	Typography,
	StepContent,
	Box,
	Grid,
	Paper,
	Divider,
	IconButton,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import clsx from 'clsx'
import style from './style'
import { Pembeli, Server, UserTie } from 'Components/svgs'
import Mbiz from '../../assets/images/icons/logo.png'
import moment from 'moment'
import { useSpring, animated } from 'react-spring'
import { useMeasure } from '../../hooks/useMeasure'
import { warningTitle, dangerTitle, mappingActor } from './constant'

const Iconlabel = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		zIndex: 1,
		color: '#fff',
		width: 36,
		height: 36,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		boxShadow: '0px 3px 6px #00000029',
	},
	list: {
		marginLeft: theme.spacing(2.3),
		paddingLeft: theme.spacing(0),
	},
}))

const ColorlibConnector = withStyles({
	line: {
		borderColor: '#BDBDBD!important',
		borderLeftWidth: '2px!important',
		transform: 'translateX(14px)',
		display: 'none!important',
	},
})(StepConnector)

const CustomContent = withStyles({
	root: {
		borderColor: '#BDBDBD!important',
		borderLeftWidth: '2px!important',
		transform: 'translateX(5px)',
		paddingBottom: '20px!important',
		marginTop: '-36px',
		paddingLeft: '40px',
	},
})(StepContent)

const replaceLink = (message) => {
	message = message
		.replace('[[RFQ-LINK]]', '/dashboard/rfq')
		.replace('[[Quotation-LINK]]', '/dashboard/quotation')
		.replace('[[PR-LINK]]', '/dashboard/purchase-request')
		.replace('[[PO-LINK]]', '/dashboard/purchase-order')
		.replace('[[MOU-LINK]]', '/dashboard/mou')
		.replace('[[Invoice-LINK]]', '/dashboard/invoice')
		.replace('[[Payment-LINK]]', '/dashboard/payment')
	return message
}

const Component = (props) => {
	const classes = Iconlabel()
	const timelineClass = style()
	const timelineData = props.data
	const [rect, elRef] = useMeasure([timelineData])
	const [collapsed, setCollapsed] = useState(true)
	const ColorlibStepIcon = (props, icon_no) => {
		const icons = {
			BUYER: <Pembeli style={{ width: '17px' }} />,
			MBIZ: <img src={Mbiz} style={{ width: '27px' }} alt='mbiz' />,
			APPROVER: <UserTie style={{ width: '17px' }} />,
			SYSTEM: <Server style={{ width: '17px' }} />,
		}

		return <div className={clsx(classes.root)}>{icons[icon_no]}</div>
	}

	ColorlibStepIcon.propTypes = {
		icon: PropTypes.node,
	}

	const checkTitle = (title = '', titleType = null) => {
		return titleType.includes(title) ? true : false
	}

	useEffect(() => {
		const script = document.createElement('script')

		const scriptText = document.createTextNode(
			'\
			var coll = document.getElementsByClassName("collapsible");\
			var i;\
			for (i = 0; i < coll.length; i++) {\
				coll[i].addEventListener("click", function() {\
					this.classList.toggle("active");\
					var child = this.childNodes[0];\
					var child1 = this.childNodes[1];\
					var content = this.parentNode.parentNode.nextElementSibling;\
					if (content.style.display === "table") {\
						content.style.display = "none";\
						child.innerHTML = "Tampilkan rincian ";\
						child1.className = "chevron bottom";\
					} else {\
						content.style.display = "table";\
						child.innerHTML = "Sembunyikan rincian ";\
						child1.className = "chevron top";\
					}\
				});\
			}\
		'
		)

		script.appendChild(scriptText)
		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	}, [])

	const renderStep = (props, row, key) => {
		return (
			<Step active={true} key={key}>
				<StepLabel
					StepIconComponent={(props) => ColorlibStepIcon(props, row.actor)}
				>
					<Typography variant='subtitle1'>&nbsp;</Typography>
				</StepLabel>
				<CustomContent>
					<Paper
						elevation={0}
						className={clsx({
							[timelineClass.danger]: checkTitle(
								row.title ? row.title : null,
								dangerTitle
							),
							[timelineClass.warning]: checkTitle(
								row.title ? row.title : null,
								warningTitle
							),
							[timelineClass.default]: true,
						})}
					>
						<Box py={'10px'} px={'16px'}>
							<Grid
								container
								justify='space-between'
								className={timelineClass.container}
							>
								<Grid
									container
									item
									xs={8}
									justify='flex-start'
									className={timelineClass.actorTitle}
								>
									<span className={timelineClass.actor}>
										{mappingActor(row.actor).concat(' ')}
									</span>
									{row.title}
								</Grid>
								<Grid container item xs={4} justify='flex-end'>
									<Typography className={timelineClass.title}>
										{moment(row.created_at).format('DD MMMM YYYY HH:mm')} WIB
									</Typography>
								</Grid>
							</Grid>
							<Divider
								style={{ marginLeft: '-16px', marginRight: '-16px' }}
								className={clsx({
									[timelineClass.dividerDanger]: checkTitle(
										row.title ? row.title : null,
										dangerTitle
									),
									[timelineClass.dividerWarning]: checkTitle(
										row.title ? row.title : null,
										warningTitle
									),
								})}
							/>
							<Box mt={1}>
								<Typography
									className={timelineClass.message}
									dangerouslySetInnerHTML={{ __html: replaceLink(row.message) }}
								></Typography>
							</Box>
						</Box>
					</Paper>
				</CustomContent>
			</Step>
		)
	}

	const collapseWrapperStyle = useSpring({
		background: collapsed
			? 'linear-gradient(0deg, rgb(255, 255, 255) 5%, rgba(255,255,255,0.3071429255295869) 100%)'
			: 'linear-gradient(0deg, rgba(255, 255,255, 0) 0%, rgba(255, 255, 255, 0) 0%)',
		height: collapsed ? 110 : 50,
	})

	const prop = useSpring({
		height: collapsed ? 540 : rect.height + 24,
	})
	return (
		<animated.div
			className={clsx({ [timelineClass.stepperWrapper]: timelineData.length > 5 })}
			style={timelineData.length > 5 ? (collapsed ? prop : null) : null}
		>
			<Box py={1} px={3} my={3} ref={elRef}>
				<Stepper
					orientation='vertical'
					connector={<ColorlibConnector />}
					style={{ width: '100%', paddingBottom: '4px' }}
					// ref={compRef}
				>
					{timelineData.map((row, key) => renderStep(props, row, key))}
				</Stepper>
			</Box>
			{timelineData.length > 5 ? (
				<animated.div
					className={timelineClass.collapseWrapper}
					style={collapseWrapperStyle}
				>
					<IconButton
						disableRipple
						size='small'
						onClick={() => setCollapsed(!collapsed)}
					>
						{collapsed ? <ExpandMore /> : <ExpandLess />}
					</IconButton>
				</animated.div>
			) : null}
		</animated.div>
	)
}

Component.propTypes = {
	data: PropTypes.array,
	id: PropTypes.number,
}

export default Component
