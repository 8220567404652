import * as Yup from 'yup'

const schemaDepartment = Yup.object().shape({
	nama_bank: Yup.object()
		.shape({
			id: Yup.number(),
			name: Yup.string(),
		})
		.required('Nama Bank harus diisi')
		.nullable(),
	cabang_bank: Yup.string()
		.required('Nama Cabang harus diisi')
		.min(3, 'Nama Cabang harus diisi paling sedikit 3 karakter')
		.max(200, 'Nama Cabang harus diisi paling banyak 200 karakter'),
	akun_bank: Yup.number()
		.typeError('Input harus angka')
		.min(3, 'Nomor Akun Bank harus diisi paling sedikit 3 nomor')
		.required('Nomor akun bank harus diisi'),
	// .required('Nomor Akun Bank harus diisi'),
	nama_akun: Yup.string()
		.required('Nama Pemegang Akun harus diisi')
		.min(3, 'Nama Pemegang Akun harus diisi paling sedikit 3 karakter')
		.max(200, 'Nama Pemegang Akun harus diisi paling banyak 200 karakter'),
})

export default schemaDepartment
