/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import {
	Box,
	Container,
	Typography,
	Button,
	Grid,
	TextField,
	IconButton,
	Link,
} from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'
import ListIcon from '@material-ui/icons/List'
import useStyles from './styles'
import { requestInvoiceList } from 'States/actions/Transactions/Invoice/listAction'
import * as qs from 'query-string'
import {
	newUri,
	dateFormat,
	dateFormatFromOdoo,
	isValidDate,
} from '../../../../utils/globalUtils'
import { numeralFormat } from '../../../../utils/formatMoney'
import Breadcrumbs from 'Components/breadcrumbs'
import Status from 'Components/status'
import DashboardLayout from 'Components/dashboardLayout'
import TableData from 'Components/tableData'
import { format } from 'date-fns'
import DatePickerRange from 'Components/DateRange'
import { buttonFilter, buttonStatus } from './helper'

const breadcrumbs = [
	{
		segment: 'Home',
		link: '/dashboard/',
	},
	{
		segment: 'Transaksi',
		link: '#',
	},
	{
		segment: 'Tagihan',
		link: '#',
	},
]

const InvoiceList = (props) => {
	const classes = useStyles()

	const dispatch = useDispatch()
	const list = useSelector((state) => state.invoiceListReducer)
	const queryString = qs.parse(props.location.search)

	const [perPage, setPerPage] = useState(
		queryString.per_page ? queryString.per_page : 10
	)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [invoiceNo, setinvoiceNo] = useState(
		queryString.transaction_no ? queryString.transaction_no : ''
	)
	const [startDate, setStartDate] = useState(
		queryString.start_date ? queryString.start_date : ''
	)
	const [endDate, setEndDate] = useState(
		queryString.end_date ? queryString.end_date : ''
	)
	const [state, setState] = useState(queryString.state ? queryString.state : '')
	const [paymentState, setPaymentState] = useState(
		queryString.invoice_payment_state ? queryString.invoice_payment_state : ''
	)
	const [range, setRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	])
	const [dataList, setDataList] = useState([])
	const joinDate = (startDate, endDate) => `${startDate} ~ ${endDate}`

	const [placeHolder, setPlaceHolder] = useState(
		startDate && endDate ? joinDate(startDate, endDate) : 'Rentang Tanggal'
	)
	const [color, setColor] = useState(
		isValidDate(startDate) && isValidDate(startDate) ? '#007BFF' : '#a9acaf'
	)

	queryString.per_page = perPage
	queryString.page = page
	queryString.transaction_no = invoiceNo
	queryString.start_date = startDate
	queryString.end_date = endDate
	queryString.state = state
	queryString.invoice_payment_state = paymentState

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	const submitSearch = () => {
		let query = newUri(queryString)
		dispatch(requestInvoiceList({ queryString }))
		propsNavigate(query)
	}

	const handleOnChange = (item) => {
		const str = `${format(item.selection.startDate, 'yyyy-MM-dd')} ~ ${format(
			item.selection.endDate,
			'yyyy-MM-dd'
		)}`
		setPage(1)
		setPlaceHolder(str)
		setRange([item.selection])
		setColor('#007BFF')
		setStartDate(dateFormat(item.selection.startDate, 'yyyy-MM-dd'))
		setEndDate(dateFormat(item.selection.endDate, 'yyyy-MM-dd'))
		queryString.start_date = dateFormat(item.selection.startDate, 'yyyy-MM-dd')
		queryString.end_date = dateFormat(item.selection.endDate, 'yyyy-MM-dd')
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleOnClear = () => {
		setPlaceHolder('Rentang Tanggal')
		setColor('#a9acaf')
		setRange([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		])
		setStartDate('')
		setEndDate('')
		setPage(1)
		queryString.start_date = ''
		queryString.end_date = ''
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	useEffect(() => {
		dispatch(requestInvoiceList({ queryString }))
	}, [])

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => submitSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [invoiceNo])

	const renderStatus = (state, paymentState) => {
		if (state == 'proforma') {
			return '-'
		} else {
			return <Status status={paymentState} />
		}
	}

	// hooks for set default state
	useEffect(() => {
		// success
		if (list.data && list.data.result) {
			let result = list.data.result
			if (result) {
				setDataList(result)
			}
		} else {
			setDataList([])
		}
	}, [list])

	const columns = [
		{
			name: 'NO. TAGIHAN',
			selector: 'name',
			sortable: true,
			cell: (row) => (
				<Link
					color='secondary'
					href={`/dashboard/invoice/${row.name}`}
					target='_blank'
				>
					{row.name}
				</Link>
			),
			grow: 3,
		},
		{
			name: 'TANGGAL TAGIHAN',
			selector: 'finance_created_date',
			sortable: true,
			cell: (row) =>
				row.finance_created_date != false
					? dateFormatFromOdoo(row.finance_created_date, 'dd-MMM-yyyy')
					: '-',
			grow: 2,
		},
		{
			name: 'NO. PO',
			selector: 'po_number',
			sortable: true,
			cell: (row) => (
				<Link
					color='secondary'
					href={`/dashboard/purchase-order/${row.po_number}`}
					target='_blank'
				>
					{row.po_number}
				</Link>
			),
			grow: 3,
		},
		{
			name: 'NO. DN/BAST',
			selector: 'shipping_number',
			sortable: true,
			cell: (row) => (row.shipping_number != false ? row.shipping_number : '-'),
			grow: 3,
		},
		// {
		// 	name: 'PEMOHON',
		// 	selector: 'invoice_partner_display_name',
		// 	sortable: true,
		// 	grow: 2,
		// },
		{
			name: 'TOTAL TAGIHAN',
			selector: 'amount_total_signed',
			sortable: true,
			cell: (row) => numeralFormat(row.amount_total_signed),
			grow: 3,
		},
		{
			name: 'SISA PEMBAYARAN',
			selector: 'amount_residual_signed',
			sortable: true,
			cell: (row) => numeralFormat(row.amount_residual_signed),
			grow: 3,
		},
		{
			name: 'JATUH TEMPO',
			selector: 'invoice_date_due',
			sortable: true,
			cell: (row) =>
				row.invoice_date_due != false
					? dateFormatFromOdoo(row.invoice_date_due, 'dd-MMM-yyyy')
					: '-',
			grow: 2,
		},
		{
			name: 'sisa hari',
			selector: 'invoice_date_due',
			sortable: true,
			cell: (row) => daysLeft(row.invoice_date_due),
			grow: 2,
		},
		{
			name: 'TIPE',
			selector: 'state',
			sortable: true,
			cell: (row) => <Status status={row.state} />,
			grow: 3,
		},
		{
			name: 'STATUS PEMBAYARAN',
			selector: 'state',
			sortable: true,
			cell: (row) => renderStatus(row.state, row.invoice_payment_state),
			grow: 3,
		},
		{
			name: 'AKSI',
			selector: 'id',
			sortable: true,
			cell: (row) => (
				<Button
					id='btn-detail-invoice'
					className={classes.buttonAction}
					variant='contained'
					color='secondary'
					size='small'
					onClick={() => navigateTo(row.name)}
				>
					<ListIcon className={classes.iconAction} />
				</Button>
			),
			grow: 1,
		},
	]

	const limitValue = [10, 25, 50, 100]

	const navigateTo = (id) => {
		navigate('/dashboard/invoice/' + id)
	}

	// calculate diff between 2 date
	const daysLeft = (endDate) => {
		if (endDate == false) {
			return '-'
		}
		let today = new Date()
		endDate = new Date(endDate)
		let diffTime = endDate - today
		let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
		if (diffDays > 0) {
			return <Typography>{diffDays} Hari</Typography>
		} else {
			return (
				<Typography className={classes.fontDanger}>
					{Math.abs(diffDays)} Hari
				</Typography>
			)
		}
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setPerPage(event.target.value)
		queryString.per_page = event.target.value
		let maxPage = Math.ceil(
			list.data.pagination.total_records / queryString.per_page
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		submitSearch()
	}

	const handleChangeSearch = (e) => {
		let value = e.target.value.trim()
		queryString.transaction_no = value
		setinvoiceNo(value)
		setPage(1)
		queryString.page = 1
	}

	const handleButtonState = (e, btnState) => {
		queryString.invoice_payment_state = ''
		setState(btnState)
		setPage(1)
		setPaymentState('')
		queryString.state = btnState
		queryString.page = 1
		submitSearch()
	}

	const handleButtonStatus = (e, btnStatus) => {
		setPaymentState(btnStatus)
		setPage(1)
		queryString.invoice_payment_state = btnStatus
		queryString.page = 1
		submitSearch()
	}

	const handleSearchRFQ = () => {
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleEnterSearch = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>
				<Box className={'searchContainer'}>
					<Typography variant='h6'>Daftar Tagihan</Typography>
					<br />
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<DatePickerRange
								ranges={range}
								color={color}
								onSelectDate={(item) => handleOnChange(item)}
								placeholder={placeHolder}
								onClear={() => handleOnClear()}
							/>
						</Grid>
						<Grid item xs={3}>
							<Grid item className={classes.gridSearch}>
								<TextField
									placeholder='Cari No. Tagihan/No. PO'
									type='search'
									className={'nameSearch'}
									onChange={(e) => handleChangeSearch(e)}
									onKeyDown={(e) => handleEnterSearch(e)}
									onBlur={(e) => handleChangeSearch(e)}
									defaultValue={invoiceNo}
									value={invoiceNo}
								/>
								<IconButton
									className={classes.inputButton}
									variant='contained'
									color='primary'
									onClick={handleSearchRFQ}
								>
									<SearchRounded fontSize='small' />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<Box mt={2}>
					<TableData
						buttonState={buttonFilter}
						buttonStatus={buttonStatus}
						buttonStateClick={handleButtonState}
						buttonStatusClick={handleButtonStatus}
						limitValue={limitValue}
						perPage={perPage}
						limitChange={handlePerRowsChange}
						columns={columns}
						isLoading={list.isLoading}
						pagination={list.data ? list.data.pagination : false}
						data={dataList}
						name={'Tagihan'}
						paginationPageChange={handlePageChange}
						pageNow={parseInt(queryString.page)}
						labelStatus='Status Pembayaran'
						labelState='Tipe'
						queryState={queryString.state}
						queryStatus={queryString.invoice_payment_state}
					/>
				</Box>
				<br />
			</Container>
		</DashboardLayout>
	)
}

InvoiceList.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default InvoiceList
