import * as Yup from 'yup'

const schema = Yup.object().shape({
	username: Yup.string()
		.required('Username tidak boleh kosong.')
		.min(3, 'Username tidak boleh kurang dari 3 karakter.')
		.matches(
			/^[a-z][a-z0-9_.-]*$/,
			'Username hanya boleh huruf kecil, angka, ".", "-" dan "_".'
		),
	password: Yup.string()
		.required('Kata sandi tidak boleh kosong.')
		.min(6, 'Kata sandi tidak boleh kurang dari 6 karakter.'),
	confirmpassword: Yup.string()
		.required('Konfirmasi kata sandi tidak boleh kosong.')
		.oneOf([Yup.ref('password'), null], 'Kata sandi tidak sama.'),
})

export default schema
