import React, { useState, useEffect } from 'react'
import Layout from 'Components/layout'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
	requestPRDetail,
	approvePR,
	rejectPR,
	resetAction as resetActionPRDetail,
} from 'States/actions/Transactions/PurchaseRequest/detail'
import {
	Box,
	Typography,
	Grid,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Container,
	CircularProgress,
	Collapse,
	IconButton,
	Tooltip,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Image from 'material-ui-image'
import Status from 'Components/status'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Breadcrumbs from 'Components/breadcrumbs'
import ApprovalDetail from './approvalDetails'
import { PurchaseRequestDetailStyles } from './styles'
import { numeralFormat } from '../../../../utils/formatMoney'
import { rolePenyetuju, checkRole } from '../../../../utils/acl'
import ScrollToTop from 'Components/scrollToTop'
import Timeline from 'Components/timeline'
import {
	getTimeline,
	resetAction,
} from 'States/actions/Transactions/Timeline/timelineAction'
// get user account and list departemen
import { requestUserAccountList } from 'States/actions/UsersAccount/listAction'
import './stylesCss.css'
import ModalMessages from './modalMessage'
import ModalPR from './modalPR'
import { renderButtonComponent } from './helper/helper'
import Label from 'Components/label'
import { Info } from '@material-ui/icons'
import { dataTransactions } from '../../../../utils/bodyDataObject'

const imageStorage = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'

const splitVariant = (str) => {
	const arr = str.match(/\(.+?\)/g)
	let joinVar = '-'
	if (arr) {
		const variant = arr.map((item) => item.slice(1, -1))
		joinVar = variant
	}

	return joinVar
}

const PurchaseRequestDetail = ({ navigate, prNumber }) => {
	const allowedPenyetuju = checkRole(`${rolePenyetuju}`, false)
	const classes = PurchaseRequestDetailStyles()
	const dispatch = useDispatch()
	const timelineData = useSelector((state) => state.timelineReducer)
	// data detail state
	const [dataPR, setDataPR] = useState({ ...dataTransactions })

	const [breadcrums, setBreadcrums] = useState([
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Transaksi',
			link: '#',
		},
		{
			segment: 'Purchase Request',
			link: '#',
		},
	])
	const [succesModal, setSuccessModal] = useState(false)
	const [modalStatus, setModalStatus] = useState('')
	const [approveLoading, setApproveLoading] = useState(false)
	const [cancelModal, setCancelModal] = useState(false)
	const [afterCancel, setAfterCancel] = useState(false)
	let note = ''
	const [noRef, setNoRef] = useState('')
	const [collapse, setCollapse] = useState(false)

	const CustomCell = withStyles(() => ({
		root: {
			border: 'none',
		},
	}))(TableCell)
	const BorderedCell = withStyles(() => ({
		root: {
			borderTop: '1px solid #ddd',
		},
	}))(TableCell)

	const [open, setOpen] = React.useState(false)

	// const sendEmailRef = useRef([])

	useEffect(() => {
		handleSetBreadcrums(prNumber)
		dispatch(
			requestUserAccountList({
				queryString: { filter: '{"status":"99", "verified_email":"1"}' },
			})
		)
	}, [])

	const openDialog = (state) => {
		setOpen(state)
		// setTimeout(() => {
		// 	sendEmailRef.current.click()
		// }, 10)
	}

	// breadcrums function
	const handleSetBreadcrums = (value) => {
		let tmp_breadcrums = [
			{
				segment: 'Home',
				link: '/dashboard/',
			},
			{
				segment: 'Transaksi',
				link: '/dashboard',
			},
			{
				segment: 'Purchase Request',
				link: '../purchase-request',
			},
		]
		setBreadcrums([
			...tmp_breadcrums,
			{
				segment: value,
				link: '#',
			},
		])
	}

	const prDetail = useSelector((state) => state.PRDetail)

	useEffect(() => {
		dispatch(requestPRDetail(prNumber))
		return () => {
			dispatch(resetActionPRDetail())
		}
	}, [prNumber])

	const user = useSelector((state) => state.auth.data.data)

	const handleApprovePr = () => {
		let values = {
			notes: note,
			reference_no: noRef,
			po_user_mail: [],
		}
		if (!values.reference_no) {
			values.reference_no = dataPR.NoRefPO
		}
		setApproveLoading(true)
		let storage_id = []
		approvePR(prNumber, dataPR.tokenApproval, values, storage_id)
			.then((response) => {
				if (response) {
					setApproveLoading(false)
					setSuccessModal(true)
					if ('error_backend' in response.data) {
						setModalStatus('error')
					} else {
						setModalStatus('success')
					}
				}
			})
			.catch((err) => console.log(err))
	}

	const openModal = (isCancel) => {
		isCancel ? setCancelModal(true) : setOpen(true)
	}

	const handleBackPage = () => {
		navigate('/dashboard/purchase-request')
	}

	const renderStatusPr = (curent_approval_users, status, is_rejected) => {
		const exploded_users_ids = curent_approval_users.split(',')
		const currentApprover = exploded_users_ids.find((id) => id.includes(user.id))
		if (is_rejected) return <Status status={'PR3'} />
		if (status === 4) return <Status status='PR4' />
		if (status === 5) return <Status status='PR5' />
		if (status === 2) return <Status status='PR2' />
		if (currentApprover && status === 1) return <Status status='PR1' />
		else return <Status status='PR0' />
	}

	const renderButton = (obj) => {
		if (obj) {
			if (Object.prototype.hasOwnProperty.call(obj, 'current_user_approvals')) {
				return renderButtonComponent(
					obj.curent_approval_users,
					obj.is_rejected,
					obj.current_user_approvals[0].approver.approver_role.role.approval_name,
					obj.remaining_approvals,
					obj.status,
					classes,
					allowedPenyetuju,
					approveLoading,
					user,
					handleApprovePr,
					openModal,
					handleBackPage,
					openDialog
				)
			} else {
				return renderButtonComponent(
					obj.curent_approval_users,
					obj.is_rejected,
					null,
					obj.remaining_approvals,
					obj.status,
					classes,
					allowedPenyetuju,
					approveLoading,
					user,
					handleApprovePr,
					openModal,
					handleBackPage,
					openDialog
				)
			}
		} else {
			return renderButtonComponent(
				null,
				true,
				null,
				null,
				null,
				classes,
				null,
				null,
				null,
				null,
				null,
				handleBackPage,
				null
			)
		}
	}

	const handleReject = (cancelReason) => {
		rejectPR(prNumber, dataPR.tokenApproval, cancelReason)
			.then((res) => {
				const status = 'error_backend' in res.data ? 'error' : 'success'
				setModalStatus(status)
				setApproveLoading(false)
				setCancelModal(false)
				setAfterCancel(true)
			})
			.catch((err) => {
				console.log(err)
				setModalStatus('failed')
			})
	}

	useEffect(() => {
		if (
			prDetail.data != null &&
			prDetail.isLoading == false &&
			prDetail.data.purchase_request_no == prNumber &&
			prDetail.data.transaction_id &&
			prDetail.data.transaction_id[0]
		) {
			let dataTemp = dataPR
			dataTemp.noPR = prDetail.data.purchase_request_no
			dataTemp.noQuotation = prDetail.data.quotation_no
			dataTemp.NoRefPO = prDetail.data.reference_no
				? prDetail.data.reference_no
				: prDetail.data.quotation?.data?.client_order_ref ?? ''
			dataTemp.timeCreate = prDetail.data.created_at
			dataTemp.expiredDate = prDetail.data.expired_at
			dataTemp.requestor = prDetail.data.quotation?.data?.requestor_name ?? ''
			dataTemp.deparRequestor =
				prDetail.data.requestor?.user_department?.company_departments?.name ?? '-'
			dataTemp.internalNotes =
				prDetail.data.quotation?.data?.customer_internal_notes ?? '-'

			if (prDetail.data.quotation.data.partner_shipping_id &&
				'region_id' in prDetail.data.quotation.data.partner_shipping_id) {
				dataTemp.shippingAdd =
					prDetail.data.quotation?.data?.partner_shipping_id?.street ?? ''
				dataTemp.shippingAddLocation =
					prDetail.data.quotation?.data?.partner_shipping_id?.region_id.complete_name ?? ''
			} else {
				dataTemp.shippingAdd =
					prDetail.data.quotation?.data?.partner_shipping_id?.contact_address ?? ''
					dataTemp.shippingAddLocation = ''
			}

			if (prDetail.data.quotation.data.partner_invoice_id &&
				'region_id' in prDetail.data.quotation.data.partner_invoice_id) {
				dataTemp.billingAdd =
					prDetail.data.quotation?.data?.partner_invoice_id?.street ?? ''
				dataTemp.billingAddLocation =
					prDetail.data.quotation?.data?.partner_invoice_id?.region_id.complete_name ?? ''
			} else {
				dataTemp.billingAdd =
				prDetail.data.quotation?.data?.partner_invoice_id?.contact_address ?? ''
					dataTemp.billingAddLocation = ''
			}

			dataTemp.receiverName = prDetail.data.quotation?.data?.receiver_name ?? ''
			dataTemp.receiverPhone = prDetail.data.quotation?.data?.receiver_phone ?? ''
			dataTemp.note = prDetail.data.notes
			dataTemp.amountUntax = prDetail.data.quotation?.data?.amount_untaxed ?? 0
			dataTemp.amountTotal = prDetail.data.quotation?.data?.amount_total ?? 0
			dataTemp.amountGroup = prDetail.data.quotation?.data?.amount_by_group ?? []
			dataTemp.orderLine = prDetail.data.quotation?.data?.order_line ?? []
			dataTemp.id = prDetail.data.id
			dataTemp.shippingVendor =
				prDetail.data.quotation?.data?.shipping_vendor ?? null
			dataTemp.userApproval = prDetail.data.curent_approval_users
			dataTemp.status = prDetail.data.status
			dataTemp.isReject = prDetail.data.is_rejected
			dataTemp.tokenApproval = prDetail.data.approval_token
			dataTemp.budgets = prDetail.data.budgets
			setDataPR(dataTemp)
			dispatch(getTimeline(prDetail.data.transaction_id[0].id))
		} else {
			setDataPR({ ...dataTransactions })
			dispatch(resetAction())
		}
		if (prDetail.data != null && prDetail.isLoading == false) {
			setNoRef(
				prDetail.data.reference_no
					? prDetail.data.reference_no
					: prDetail.data.quotation?.data?.client_order_ref ?? ''
			)
		}
	}, [prDetail.data, prDetail.isLoading, prNumber])

	const handleSuccessCreate = () => {
		dispatch(requestPRDetail(prNumber))
		setSuccessModal(false)
	}

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>
				<Box my={2}>
					<Typography variant='h6'>Rincian Persetujuan Pembelian</Typography>
				</Box>
				<Box mt={3}>
					<Paper elevation={1}>
						<Box py={2} px={3}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. PR</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.noPR
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Referensi PO</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.NoRefPO
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Quotation</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															<a
																href={`${window.location.origin}/dashboard/quotation/${dataPR.noQuotation}`}
																target='_blank'
																rel='noopener noreferrer'
															>
																{dataPR.noQuotation}
															</a>
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Tahap</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													{prDetail.isLoading ? (
														<Skeleton variant='text' />
													) : dataPR.userApproval ||
														dataPR.status ||
														dataPR.isReject ? (
														<Box width={120}>
															{renderStatusPr(
																dataPR.userApproval,
																dataPR.status,
																dataPR.isReject
															)}
														</Box>
													) : null}
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Waktu PR Dibuat</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.timeCreate && moment(dataPR.timeCreate).format('DD MMMM YYYY hh:mm')
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Batas Membuat PO</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.expiredDate && moment(dataPR.expiredDate).format('DD MMMM YYYY hh:mm')
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Pemohon</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.requestor
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Departemen Pemohon</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.deparRequestor
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Untuk Keperluan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.internalNotes
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Pengiriman</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.shippingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.shippingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Nama Penerima</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.receiverName
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Telepon Penerima</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.receiverPhone
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.billingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.billingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Catatan Untuk Manajerial</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{prDetail.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataPR.note
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Box>

				{/* approval details */}
				<Box mt={2}>
					<Paper elevation={2}>
						<Box py={1} px={3}>
							<Box display='flex' justifyContent='space-between' alignItems='center'>
								<Typography varian='h6'>
									<strong>Rincian Persetujuan</strong>
								</Typography>
								<IconButton size='small' onClick={() => setCollapse(!collapse)}>
									{collapse ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
								</IconButton>
							</Box>
							<Collapse in={collapse}>
								{!prDetail.data ? null : <ApprovalDetail data={prDetail.data} />}
							</Collapse>
						</Box>
					</Paper>
				</Box>
				{/* end of approval details */}

				{/* bottom form */}
				<Box mt={2}>
					<Paper elevation={1}>
						<Box py={2} px={3}>
							{/* table */}
							<Box>
								<Table classes={{ root: classes.tableRoot }}>
									<TableHead>
										<TableRow>
											<TableCell align='center'>No</TableCell>
											<TableCell align='center'>Produk</TableCell>
											<TableCell align='center'>Unit</TableCell>
											<TableCell align='center'>Kuantitas</TableCell>
											<TableCell align='center'>Harga / Unit</TableCell>
											<TableCell align='center'>Total Harga</TableCell>
											<TableCell align='center'>Pajak</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{prDetail.isLoading
											? (
												<TableRow key={'skeleton'}>
													<CustomCell>
														<Skeleton variant='text' />
													</CustomCell>
													<CustomCell>
														<Box display='flex' flexDirection='row'>
															<Box overflow='hidden' width={60} height={60}>
																<Skeleton variant='rect' width={60} height={60} />
															</Box>
															<Box
																ml={1}
																display='flex'
																flexDirection='column'
																justifyContent='space-between'
																style={{ width: '100%' }}
															>
																<Box>
																	<Typography variant='body2'>
																		<Skeleton variant='text' width={250} />
																	</Typography>
																	<Typography variant='caption'>
																		<Skeleton variant='text' width={250} />
																	</Typography>
																</Box>
																<Typography variant='caption'>
																	<Skeleton variant='text' width={250} />
																</Typography>
															</Box>
														</Box>
													</CustomCell>
													<CustomCell align='center'>
														<Skeleton variant='text' />
													</CustomCell>
													<CustomCell align='center'>
														<Skeleton variant='text' />
													</CustomCell>
													<CustomCell align='center'>
														<Skeleton variant='text' />
													</CustomCell>
													<CustomCell align='center'>
														<Skeleton variant='text' />
													</CustomCell>
													<CustomCell align='center'>
														<Skeleton variant='text' />
													</CustomCell>
												</TableRow>
											)
											: dataPR.orderLine.map((item, index) => (
													<TableRow key={item.id}>
														<CustomCell>{index + 1}</CustomCell>
														<CustomCell>
															<Box display='flex' flexDirection='row'>
																<Box>
																	<Image
																		src={
																			imageStorage +
																			item.product_id.product_tmpl_id.image_128_filestore
																		}
																		style={{ width: 60, height: 60 }}
																	/>
																</Box>
																<Box
																	ml={1}
																	display='flex'
																	flexDirection='column'
																	justifyContent='space-between'
																>
																	<Box>
																		<Typography variant='body2'>
																			{item.product_id.sku_no}
																		</Typography>
																		<Typography variant='caption'>{item.name}</Typography>
																	</Box>
																	<Typography variant='caption'>
																		varian: {splitVariant(item.name)}
																	</Typography>
																	{item.eta_lead_time > 0 && (
																		<Label type='success'>
																			<Typography
																				variant='caption'
																				style={{
																					fontWeight: '500',
																				}}
																			>
																				Estimasi diterima {item.eta_lead_time} hari kerja setelah PO
																				terbuat
																			</Typography>
																		</Label>
																	)}
																</Box>
															</Box>
														</CustomCell>
														<CustomCell align='center'>{item.product_uom.name}</CustomCell>
														<CustomCell align='center'>{item.product_uom_qty}</CustomCell>
														<CustomCell align='center'>
															{numeralFormat(item.price_unit)}
														</CustomCell>
														<CustomCell align='center'>
															{numeralFormat(item.price_subtotal)}
														</CustomCell>
														<CustomCell
															align='center'
															style={{
																maxWidth: 120,
															}}
														>
															{item.tax_id.map((tax) => `${tax.name} `)}
														</CustomCell>
													</TableRow>
											  ))}
										{dataPR.shippingVendor && (
											<>
												<TableRow>
													<BorderedCell colSpan={7}>
														<Typography variant='body1'>
															<strong>Rincian Pengiriman</strong>
														</Typography>
													</BorderedCell>
												</TableRow>
												<TableRow>
													<BorderedCell />
													<BorderedCell colSpan={4}>
														<Typography variant='body1'>
															<strong>Ekspedisi</strong>
														</Typography>
													</BorderedCell>
													<BorderedCell align='center'>
														<Typography variant='body1'>
															<strong>Biaya Pengiriman</strong>
														</Typography>
													</BorderedCell>
													<BorderedCell align='center'>
														<Typography variant='body1'>
															<strong>Pajak</strong>
														</Typography>
													</BorderedCell>
												</TableRow>
												{dataPR.shippingVendor.map((item, index) => (
													<TableRow key={index}>
														<CustomCell />
														<CustomCell colSpan={4}>
															<Box display='flex' flexDirection='row'>
																<Box>
																	<Image
																		src={
																			imageStorage +
																			item.product_id.product_tmpl_id.image_128_filestore
																		}
																		style={{ width: 60, height: 60 }}
																	/>
																</Box>
																<Box ml={1} display='flex' alignItems='center'>
																	<Box>
																		<Typography variant='body2'>{item.name}</Typography>
																	</Box>
																</Box>
															</Box>
														</CustomCell>
														<CustomCell align='center'>
															{numeralFormat(item.price_unit)}
														</CustomCell>
														<CustomCell
															align='center'
															style={{
																maxWidth: 120,
															}}
														>
															{item.tax_id.map((tax) => `${tax.name} `)}
														</CustomCell>
													</TableRow>
												))}
											</>
										)}
									</TableBody>
								</Table>
							</Box>
							{/* end of table */}

							{/* total */}
							<Box mt={2}>
								<Box display='flex' flex='1' justifyContent='space-between' mb={2}>
									<Box pr={8}>
										{!prDetail.isLoading && dataPR.budgets?.budget_name && (
											<Box mb={1.5}>
												<Label type='grey' size='md'>
													<Box
														style={{
															display: 'flex',
															alignItems: 'center',
															flexDirection: 'row',
														}}
													>
														<Typography variant='body2'>
															Transaksi ini menggunakan anggaran{' '}
															<strong>{dataPR.budgets.budget_name}</strong> sebesar{' '}
															<strong>{numeralFormat(dataPR.budgets.total_used)}</strong>
															<Tooltip
																placement='top-end'
																title='Nilai anggaran diambil dari nominal Subtotal (diluar pajak) saat Quotation diproses oleh Pemohon. Nilai anggaran akan otomatis dikembalikan jika transaksi dibatalkan/ditolak.'
																arrow
																classes={{
																	arrow: classes.arrow,
																	tooltip: classes.tooltipContainer,
																}}
															>
																<IconButton
																	size='small'
																	classes={{ root: classes.styleIconbutton }}
																>
																	<Info fontSize='small' className={classes.smallICon} />
																</IconButton>
															</Tooltip>
														</Typography>
													</Box>
												</Label>
											</Box>
										)}
										<Typography variant='body2'>
											<strong>Catatan Permintaan Khusus</strong>
										</Typography>
										<Typography variant='body2'>
											{prDetail.isLoading
												? null
												: prDetail.data?.quotation?.data?.note ?? '-'}
										</Typography>
									</Box>
									<Box display='flex' minWidth={300} flexDirection='column'>
										<Box px={3} display='flex' flex='1' justifyContent='space-between'>
											<Typography variant='body1'>Subtotal</Typography>
											<Typography variant='body1'>
												{prDetail.isLoading ? null : numeralFormat(dataPR.amountUntax)}
											</Typography>
										</Box>
										{prDetail.isLoading
											? null
											: dataPR.amountGroup.map((tax, index) => (
													<Box
														key={index}
														px={3}
														display='flex'
														flex='1'
														justifyContent='space-between'
													>
														<Typography variant='body1'>{tax[0]}</Typography>
														<Typography variant='body1'>{numeralFormat(tax[1])}</Typography>
													</Box>
											  ))}
										<Box className={classes.totalPrice}>
											<Typography variant='body1'>
												<strong>TOTAL</strong>
											</Typography>
											<Typography variant='body1'>
												{prDetail.isLoading ? null : numeralFormat(dataPR.amountTotal)}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							{/* end of total */}
						</Box>
					</Paper>
				</Box>

				<Box mt={2}>
					<Grid container justify='flex-end'>
						<Grid item>
							<Box>{prDetail.isLoading ? null : renderButton(prDetail.data)}</Box>
						</Grid>
					</Grid>
				</Box>
				{/* Timeline */}
				{timelineData.isLoading ? (
					<Box style={{ textAlign: 'center' }} mt={2}>
						<CircularProgress size={20} />
					</Box>
				) : timelineData.isLoading == false &&
				  prDetail.isLoading == false &&
				  timelineData.data &&
				  timelineData.data.length > 0 &&
				  dataPR.id ? (
					<>
						<Box mt={0}>
							<Typography variant='h6'>Timeline</Typography>
						</Box>
						<Box mt={'10px'}>
							<Paper elevation={2}>
								<Timeline data={timelineData.data} id={dataPR.id} />
							</Paper>
						</Box>
					</>
				) : null}
				{/* End Timeline */}
				{/* dialog PO */}
				{prDetail.data && (
					<ModalPR
						open={open}
						onClose={() => setOpen(false)}
						prNumber={prNumber}
						handleReject={handleReject}
						handleSuccessCreate={handleSuccessCreate}
						approveLoading={approveLoading}
						closeModal={() => {
							setSuccessModal(false)
							setCancelModal(false)
							setAfterCancel(false)
							setModalStatus('')
						}}
					/>
				)}

				{/* END dialog PO */}
			</Container>

			<ModalMessages
				succesModal={succesModal}
				modalStatus={modalStatus}
				afterCancel={afterCancel}
				cancelModal={cancelModal}
				handleReject={handleReject}
				handleSuccessCreate={handleSuccessCreate}
				prNumber={prNumber}
				approveLoading={approveLoading}
				docFile={[]}
				onClose={() => {
					setCancelModal(false)
					setAfterCancel(false)
					setSuccessModal(false)
					setModalStatus('')
				}}
			/>
			<ScrollToTop />
		</Layout>
	)
}

PurchaseRequestDetail.propTypes = {
	navigate: PropTypes.func,
	prNumber: PropTypes.string,
}

export default PurchaseRequestDetail
