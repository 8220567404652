/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles'
import moment from 'moment'
import { sumBy } from 'lodash'
import Layout from 'Components/layout'
import { EditOutlined, Warning } from '@material-ui/icons'
// import Alert from 'Components/alert'
import { requestProvinces } from 'States/actions/locationAction'
import { requestAddress } from 'States/actions/companyAddressAction'
import { withStyles } from '@material-ui/core/styles'
import { formatMoney, numeralFormat } from '../../../utils/formatMoney'
import Image from 'material-ui-image'
import CustomModal from 'Components/modal'
import schema from './schema'
import Beadcrums from 'Components/breadcrumbs'
import { Formik, Form, Field } from 'formik'
import ModalDelivery from './modalDelivery'
import ModalBilling from './modalBilling'
import {
	Box,
	Button,
	IconButton,
	Container,
	Paper,
	Typography,
	Grid,
	TextField,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	// Tooltip,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { getDetailMou } from 'States/actions/MOU/detail'
import { requestMOUDelivery } from 'States/actions/MOU/MOUDelivery'
import { submitPOMOU } from 'States/actions/MOU/MOUSubmitPO'
import { requestDetailCompany } from 'States/actions/CompanyProfile/profileAction'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateMomentUtils from '@date-io/moment'
import 'moment/locale/id'
import PropTypes from 'prop-types'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)

const Component = ({ mouNumber, navigate }) => {
	const classes = styles()
	const dispatch = useDispatch()

	const [selectedDate, setSelectedDate] = useState(null)
	const [confirmModal, setConfirmModal] = useState(false)
	const [modalDelivery, setModalDelivery] = useState(false)
	const [modalBilling, setModalBilling] = useState(false)
	const [note, setNote] = useState('')
	const [modalNotif, setModalNotif] = useState(false)
	const [notifSuccess, setNotifSuccess] = useState(false)

	const auth = useSelector((state) => state.auth)
	const userProfile = useSelector((state) => state.userProfileReducer)
	const companyDetail = useSelector(
		(state) => state.auth.data.data.company_details
	)
	const billingAddress = useSelector(
		(state) => state.companyAddressReducer.billingAddressReducer
	)
	const deliveryAddress = useSelector((state) => state.MOUDeliveryReducer)
	const detailMou = useSelector((state) => state.detailMou)
	const mouItems = useSelector((state) => state.mouItems)
	const responseData = useSelector((state) => state.MOUSubmitPOReducer)
	const imageStorage = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'

	// first action
	useEffect(() => {
		dispatch(requestProvinces())
		// dispatch(requestAddress('billing', true))
		dispatch(getDetailMou(mouNumber))
		dispatch(requestDetailCompany())
	}, [mouNumber])
	// ===================

	const handleDateChange = (date) => {
		setSelectedDate(date)
	}

	// get error_backend and parse
	useEffect(() => {
		var tmpErrorBackend
		if (responseData.error) {
			if (responseData.error.http_body) {
				let http_body = responseData.error.http_body
				if (http_body) {
					let body_obj = JSON.parse(http_body)
					tmpErrorBackend = body_obj
					setErrorBackend(body_obj)
				}
			}
		}

		// set notif error
		if ((tmpErrorBackend && tmpErrorBackend.error) || responseData.isError) {
			setNotifError(true)
		}

		// set notif success
		if (!responseData.isError && responseData.data) {
			setNotifSuccess(true)
		}

		// show modal
		if (responseData.data != null) {
			setModalNotif(true)
		}
	}, [responseData])

	const handleModal = (type) => {
		switch (type) {
			case 'billing':
				dispatch(requestAddress('billing', false))
				setModalBilling(true)
				break
			case 'delivery':
				if (detailMou.data) {
					setModalDelivery(true)
					dispatch(requestMOUDelivery(detailMou.data.id, false))
				}
		}
	}

	const handleSubmitForm = (values) => {
		setConfirmModal(false)
		let data = {}
		data.items = []

		data.client_order_ref = values.noRefPO
		data.customer_internal_notes = values.keperluan
		data.expected_delivery_date = moment(selectedDate).format('YYYY-MM-DD')
		if (
			!data.expected_delivery_date ||
			data.expected_delivery_date == 'Invalid date'
		) {
			data.expected_delivery_date = ''
		}
		data.note = note
		data.partner_invoice_id = billingAddress.defaultAddress
			? billingAddress.defaultAddress.id
			: companyDetail.id
		data.partner_shipping_id = deliveryAddress.defaultAddress
			? deliveryAddress.defaultAddress.partner_shipping_id.id
			: companyDetail.id
		data.receiver_name = values.namaPenerima
		data.receiver_phone = values.receiverPhone
		data.requestor_name =
			userProfile.isLoading == false && userProfile.data
				? userProfile.data.name
				: ''
		data.requestor_department =
			auth.data.data.user_department.company_departments.name
		data.sales_agreement_id = detailMou.data.id

		// set items
		for (let i = 0; i < mouItems.data.length; i++) {
			data.items.push({
				product_id: mouItems.data[i].product_id.id,
				product_uom_qty: mouItems.data[i].qty,
				blanket_order_line: mouItems.data[i].id,
				price_unit: mouItems.data[i].price_unit,
			})
		}

		// console.log('data handleSubmitForm', data)
		dispatch(submitPOMOU(data))
	}

	const handleModalOK = () => {
		setModalNotif(false)
		if (notifSuccess) {
			dispatch(submitPOMOU({}, 'RESET'))
			navigate('/dashboard/mou')
		}
	}

	const handleValidateForm = async (validateForm, action) => {
		const error = await validateForm()
		const currentErrorKeys = Object.keys(error)
		if (currentErrorKeys.length) {
			currentErrorKeys.map((item) => {
				action.setFieldError(item)
				action.setFieldTouched(item)
			})
		} else {
			setConfirmModal(true)
		}
	}

	const calculateSubtotal = (val) => {
		let subTotal = sumBy(val, (item) => item.qty * item.price_unit)
		return subTotal
	}

	const calculateTaxes = (val) => {
		let taxes = []
		let uniq_tax = []

		val.map((itm) => {
			itm.taxes_id.map((tax) => {
				taxes.push({
					[tax.name]: (tax.amount * (itm.qty * itm.price_unit)) / 100,
				})
			})
		})

		for (var i in taxes) {
			let prop_name = Object.getOwnPropertyNames(taxes[i])[0]

			if (
				!uniq_tax.some((obj) =>
					Object.prototype.hasOwnProperty.call(obj, prop_name)
				)
			) {
				uniq_tax.push({
					[prop_name]: taxes[i][prop_name],
				})
			} else {
				let counter = uniq_tax
					.map(function (e) {
						let prop_name2 = Object.getOwnPropertyNames(e)[0]
						return prop_name2
					})
					.indexOf(prop_name)

				uniq_tax[counter][prop_name] =
					uniq_tax[counter][prop_name] + taxes[i][prop_name]
			}
		}

		return uniq_tax
	}

	const calculateTotal = (val) => {
		let taxes = calculateTaxes(val)
		let totalTax = null
		if (taxes.length) {
			totalTax = taxes
				.map((itm) => itm[Object.keys(itm)])
				.reduce((acc, val) => acc + val)
		}
		let subTotal = calculateSubtotal(val)
		return totalTax + subTotal
	}

	const breadcrums = [
		{
			segment: 'Home',
			link: '/dashboard',
		},
		{
			segment: 'MOU',
			link: '/dashboard/mou',
		},
		{
			segment: mouNumber || '',
			link: '/dashboard/mou/detail/' + mouNumber,
		},
		{
			segment: 'Form PO',
			link: '#',
		},
	]

	const renderLabel = (date) => {
		if (date) {
			return moment(date).format('DD MMMM YYYY')
		} else {
			return 'Pilih Tanggal'
		}
	}

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Beadcrums breadcrumbs={breadcrums} />
				</Box>

				<Formik
					enableReinitialize
					initialValues={{
						receiverPhone: '',
						keperluan: '',
						namaPenerima: '',
						noRefPO: '',
						alamatTagihan: '',
						alamatPengiriman: '',
					}}
					validationSchema={schema}
					onSubmit={(values) => handleSubmitForm(values)}
				>
					{({
						errors,
						touched,
						values,
						handleChange,
						submitForm,
						validateForm,
						...action
					}) => {
						// console.log('values', values)
						return (
							<>
								<Form method='post'>
									<Box mb={3}>
										<Typography variant='h6' style={{ marginBottom: '10px' }}>
											Form Purchase Order
										</Typography>
										<Paper className={classes.formRfqTop}>
											<Box py={2} px={3}>
												<Box mb={1.5}>
													<Grid container alignItems='center'>
														<Grid item lg={2}>
															<Typography variant='body2'>
																<strong>No. MOU</strong>
															</Typography>
														</Grid>
														<Grid item lg={3}>
															<Typography variant='body2'>{mouNumber}</Typography>
														</Grid>
														<Grid item lg={3}>
															<Typography variant='body2'>
																<strong>Alamat Pengiriman</strong>
															</Typography>
														</Grid>
														<Grid item container lg={4} justify='space-between'>
															<Grid item lg={9}>
																<Box className={classes.addressValue}>
																	{deliveryAddress.isLoading ? (
																		<Skeleton variant='text' />
																	) : (
																		<TextField
																			className={classes.addressValue}
																			onChange={handleChange}
																			value={values.alamatPengiriman}
																			name='alamatPengiriman'
																			margin='dense'
																			variant='outlined'
																			size='small'
																			placeholder='Pilih Alamat Pengiriman'
																			multiline={true}
																			fullWidth
																			error={errors.alamatPengiriman && touched.alamatPengiriman}
																			rows={2}
																		/>
																	)}
																</Box>
																<Box>
																	{errors.alamatPengiriman && touched.alamatPengiriman && (
																		<Typography color='error' variant='caption' component='span'>
																			<Box display='flex' flexDirection='row' alignItems='center'>
																				<Warning style={{ fontSize: 15, marginRight: 5 }} />
																				{errors.alamatPengiriman}
																			</Box>
																		</Typography>
																	)}
																</Box>
															</Grid>
															<Grid item lg={2}>
																<IconButton
																	size='small'
																	color='secondary'
																	variant='contained'
																	onClick={() => handleModal('delivery')}
																>
																	<EditOutlined fontSize='small' />
																</IconButton>
															</Grid>
														</Grid>
													</Grid>
												</Box>

												<Box mb={1.5}>
													<Grid container alignItems='center'>
														<Grid item lg={2}>
															<Typography variant='body2'>
																<strong>Pemohon</strong>
															</Typography>
														</Grid>
														<Grid item lg={3}>
															<Typography variant='body2'>
																{userProfile.isLoading == false && userProfile.data ? (
																	userProfile.data.name
																) : (
																	<Skeleton variant='text' width='80%' />
																)}
															</Typography>
														</Grid>
														<Grid item lg={3}>
															<Typography variant='body2'>
																<strong>Alamat Tagihan</strong>
															</Typography>
														</Grid>
														<Grid item container lg={4} justify='space-between'>
															<Grid item lg={9}>
																<Box>
																	{billingAddress.isLoading ? (
																		<Skeleton variant='text' />
																	) : (
																		<TextField
																			className={classes.addressValue}
																			onChange={handleChange}
																			value={values.alamatTagihan}
																			name='alamatTagihan'
																			margin='dense'
																			variant='outlined'
																			size='small'
																			placeholder='Pilih Alamat Tagihan'
																			multiline={true}
																			fullWidth
																			error={errors.alamatTagihan && touched.alamatTagihan}
																			rows={2}
																		/>
																	)}
																</Box>
																<Box>
																	{errors.alamatTagihan && touched.alamatTagihan && (
																		<Typography color='error' variant='caption' component='span'>
																			<Box display='flex' flexDirection='row' alignItems='center'>
																				<Warning style={{ fontSize: 15, marginRight: 5 }} />
																				{errors.alamatTagihan}
																			</Box>
																		</Typography>
																	)}
																</Box>
															</Grid>
															<Grid item lg={2}>
																<IconButton
																	size='small'
																	color='secondary'
																	variant='contained'
																	onClick={() => handleModal('billing')}
																>
																	<EditOutlined fontSize='small' />
																</IconButton>
															</Grid>
														</Grid>
													</Grid>
												</Box>

												<Box mb={1}>
													<Grid container alignItems='center'>
														<Grid item lg={2}>
															<Typography variant='body2'>
																<strong>Departemen Pemohon</strong>
															</Typography>
														</Grid>
														<Grid item lg={3}>
															<Typography variant='body2'>
																{auth.data.data.user_department.company_departments.name}
															</Typography>
														</Grid>
														<Grid item lg={3}>
															<Box className={classes.labelInput}>
																<Typography variant='body2'>
																	<strong>Ekspektasi Pesanan Diterima</strong>
																</Typography>
															</Box>
														</Grid>
														<Grid item lg={3}>
															<MuiPickersUtilsProvider utils={DateMomentUtils} locale='id'>
																<KeyboardDatePicker
																	// className={classes.datepicker}
																	minDate={new Date()}
																	autoOk
																	disableToolbar
																	variant='inline'
																	format={'YYYY-MM-DD'}
																	margin='dense'
																	inputVariant='outlined'
																	id='date-picker-inline'
																	value={selectedDate}
																	fullWidth
																	labelFunc={renderLabel}
																	onChange={handleDateChange}
																	KeyboardButtonProps={{
																		'aria-label': 'change date',
																		color: 'secondary',
																		// style: { color: '#007BFF' },
																	}}
																/>
															</MuiPickersUtilsProvider>
														</Grid>
													</Grid>
												</Box>

												<Box mb={1}>
													<Grid container alignItems='center'>
														<Grid item lg={2}>
															<Box className={classes.labelInput}>
																<Typography variant='body2'>
																	<strong>Untuk Keperluan</strong>
																</Typography>
															</Box>
														</Grid>
														<Grid item lg={3}>
															<Field
																name='keperluan'
																size='small'
																margin='dense'
																fullWidth
																variant='outlined'
																placeholder='Masukkan keperluan anda'
																className={classes.datepicker}
																as={TextField}
															/>
														</Grid>
														<Grid item lg={3}>
															<Typography variant='body2'>
																<strong>Nama Penerima</strong>
															</Typography>
														</Grid>
														<Grid item lg={3}>
															<Field
																name='namaPenerima'
																size='small'
																margin='dense'
																fullWidth
																variant='outlined'
																error={errors.namaPenerima && touched.namaPenerima}
																placeholder='Masukkan Nama Penerima'
																as={TextField}
															/>
															<Box>
																{errors.namaPenerima && touched.namaPenerima && (
																	<Typography color='error' variant='caption' component='span'>
																		<Box display='flex' flexDirection='row' alignItems='center'>
																			<Warning style={{ fontSize: 15, marginRight: 5 }} />
																			{errors.namaPenerima}
																		</Box>
																	</Typography>
																)}
															</Box>
														</Grid>
													</Grid>
												</Box>

												<Box>
													<Grid container alignItems='center'>
														<Grid item lg={2}>
															<Box className={classes.labelInput}>
																<Typography variant='body2'>
																	<strong>No. Referensi PO</strong>
																</Typography>
															</Box>
														</Grid>
														<Grid item lg={3}>
															<Field
																name='noRefPO'
																size='small'
																margin='dense'
																fullWidth
																variant='outlined'
																placeholder='Masukkan no. referensi PO anda'
																className={classes.datepicker}
																as={TextField}
															/>
														</Grid>
														<Grid item lg={3}>
															<Typography variant='body2'>
																<strong>No. Telp Penerima</strong>
															</Typography>
														</Grid>
														<Grid item lg={3}>
															<Field
																name='receiverPhone'
																size='small'
																margin='dense'
																fullWidth
																variant='outlined'
																error={errors.receiverPhone && touched.receiverPhone}
																placeholder='Masukkan No Telepon Penerima'
																as={TextField}
															/>
															<Box>
																{errors.receiverPhone && touched.receiverPhone && (
																	<Typography color='error' variant='caption' component='span'>
																		<Box display='flex' flexDirection='row' alignItems='center'>
																			<Warning style={{ fontSize: 15, marginRight: 5 }} />
																			{errors.receiverPhone}
																		</Box>
																	</Typography>
																)}
															</Box>
														</Grid>
													</Grid>
												</Box>
											</Box>
										</Paper>
									</Box>

									{/* bottom form */}
									<Box>
										<Paper>
											<Box py={2} px={3}>
												{/* table */}
												<Box>
													<Table
														classes={{
															root: classes.tableRoot,
														}}
													>
														<TableHead>
															<TableRow>
																<TableCell align='center'>No</TableCell>
																<TableCell align='left' style={{ paddingLeft: '84px' }}>
																	Produk
																</TableCell>
																<TableCell align='center'>Unit</TableCell>
																<TableCell align='center'>Kuantitas</TableCell>
																<TableCell align='center'>Harga / Unit</TableCell>
																<TableCell align='center'>Total Harga</TableCell>
																<TableCell align='center'>Pajak</TableCell>
															</TableRow>
														</TableHead>

														<TableBody>
															{mouItems.isLoading ? (
																<TableRow key={'skeleton'}>
																	<CustomCell>
																		<Skeleton variant='text' />
																	</CustomCell>
																	<CustomCell>
																		<Box display='flex' flexDirection='row'>
																			<Box overflow='hidden' width={60} height={60}>
																				<Skeleton variant='rect' width={60} height={60} />
																			</Box>
																			<Box
																				ml={1}
																				display='flex'
																				flexDirection='column'
																				justifyContent='space-between'
																				style={{ width: '100%' }}
																			>
																				<Box>
																					<Typography variant='body2'>
																						<Skeleton variant='text' />
																					</Typography>
																					<Typography variant='caption'>
																						<Skeleton variant='text' />
																					</Typography>
																				</Box>
																				<Typography variant='caption'>
																					<Skeleton variant='text' />
																				</Typography>
																			</Box>
																		</Box>
																	</CustomCell>
																	<CustomCell align='center'>
																		<Skeleton variant='text' />
																	</CustomCell>
																	<CustomCell align='center'>
																		<Skeleton variant='text' />
																	</CustomCell>
																	<CustomCell align='center'>
																		<Skeleton variant='text' />
																	</CustomCell>
																	<CustomCell align='center'>
																		<Skeleton variant='text' />
																	</CustomCell>
																	<CustomCell
																		align='center'
																		style={{
																			maxWidth: 120,
																		}}
																	>
																		<Skeleton variant='text' />
																	</CustomCell>
																</TableRow>
															) : (
																mouItems.data.map((item, index) => (
																	<TableRow key={index}>
																		<CustomCell>{index + 1}</CustomCell>
																		<CustomCell>
																			<Box
																				display='flex'
																				flexDirection='row'
																				alignItems='center'
																				justifyContent='start'
																			>
																				<Box overflow='hidden' width={60} height={60}>
																					<Image
																						src={
																							imageStorage +
																							item.product_id.product_tmpl_id.image_128_filestore
																						}
																					/>
																				</Box>
																				<Box
																					ml={1}
																					display='flex'
																					flexDirection='column'
																					justifyContent='space-between'
																				>
																					<Box>
																						<Typography variant='body2'>
																							{item.product_id.sku_no}
																						</Typography>
																						<Typography variant='caption'>
																							{item.product_id.display_name}
																						</Typography>
																					</Box>
																					<Typography variant='caption'>
																						Varian:{' '}
																						{item.product_id.product_template_attribute_value_ids
																							.length == 0
																							? '-'
																							: item.product_id.product_template_attribute_value_ids.map(
																									(item) => `${item.name} `
																							  )}
																					</Typography>
																				</Box>
																			</Box>
																		</CustomCell>
																		<CustomCell align='center'>
																			{item.product_uom.name}
																		</CustomCell>
																		<CustomCell align='center'>{item.qty}</CustomCell>
																		<CustomCell align='center'>
																			{formatMoney(item.price_unit)}
																		</CustomCell>
																		<CustomCell align='center'>
																			<strong> {formatMoney(item.total)} </strong>
																		</CustomCell>
																		<CustomCell align='center'>
																			{item.taxes_id.map((tax) => `${tax.name} `)}
																		</CustomCell>
																	</TableRow>
																))
															)}
														</TableBody>
													</Table>
												</Box>
												{/* end of table */}

												{/* total */}
												<Box mt={2}>
													<Box display='flex' flex='1' justifyContent='space-between' mb={2}>
														<Box>
															<Box className={classes.labelInput}>
																<Typography variant='body1'>
																	<strong>Catatan Pembelian</strong>
																</Typography>
															</Box>
															<TextField
																value={note}
																onChange={(e) => setNote(e.target.value)}
																classes={{
																	root: classes.textareaRoot,
																}}
																placeholder='Kebutuhan ATK Departemen Produk'
																multiline
																rows={4}
																variant='outlined'
															/>
														</Box>
														{mouItems.isLoading ? (
															'loading'
														) : (
															<Box display='flex' minWidth={300} flexDirection='column'>
																<Box
																	px={3}
																	display='flex'
																	flex='1'
																	justifyContent='space-between'
																>
																	<Typography variant='body1'>Subtotal</Typography>
																	<Typography variant='body1'>
																		{numeralFormat(calculateSubtotal(mouItems.data))}
																	</Typography>
																</Box>
																<Box
																	px={3}
																	display='flex'
																	flex='1'
																	justifyContent='space-between'
																>
																	<Typography variant='body1'>Biaya Pengiriman</Typography>
																	<Typography variant='body1'>{numeralFormat(0)}</Typography>
																</Box>
																{calculateTaxes(mouItems.data).map((itm, index) => (
																	<Box
																		px={3}
																		key={index}
																		display='flex'
																		flex='1'
																		justifyContent='space-between'
																	>
																		<Typography variant='body1'>{Object.keys(itm)}</Typography>
																		<Typography variant='body1'>
																			{numeralFormat(itm[Object.keys(itm)])}
																		</Typography>
																	</Box>
																))}
																<Box className={classes.totalPrice}>
																	<Typography variant='body1'>
																		<strong>TOTAL</strong>
																	</Typography>
																	<Typography variant='body1'>
																		{numeralFormat(calculateTotal(mouItems.data))}
																	</Typography>
																</Box>
															</Box>
														)}
													</Box>
												</Box>
											</Box>
										</Paper>
									</Box>
									<Box className={classes.containerButton} mt={2}>
										<Button
											className={classes.buttonWidth}
											variant='contained'
											onClick={() => handleValidateForm(validateForm, action)}
											size='small'
											color='secondary'
										>
											Lanjutkan
										</Button>
										<Button
											className={`${classes.buttonWidth} ${classes.kembali}`}
											variant='outlined'
											color='secondary'
											onClick={() => navigate(`/dashboard/mou/detail/${mouNumber}`)}
											size='small'
										>
											Kembali
										</Button>
									</Box>
								</Form>

								<CustomModal
									icon='question'
									open={confirmModal}
									onClose={() => setConfirmModal(false)}
								>
									<Typography variant='h6'>
										<strong>Apakah Anda ingin mengirimkan Form PO?</strong>
									</Typography>
									<Typography className={classes.modalSubtitle} variant='subtitle2'>
										Form PO yang telah dikirimkan akan berstatus Dalam Proses
									</Typography>
									<Box
										display='flex'
										flexDirection='row'
										justifyContent='space-around'
										mt={3}
										width={400}
									>
										<Button
											className={`${classes.buttonWidth} ${classes.kembali}`}
											size='small'
											variant='outlined'
											color='secondary'
											onClick={() => setConfirmModal(false)}
										>
											Batal
										</Button>
										<Button
											className={classes.buttonWidth}
											size='small'
											color='secondary'
											variant='contained'
											type='submit'
											style={{ width: 150 }}
											onClick={() => submitForm()}
										>
											Kirim Form PO
										</Button>
									</Box>
								</CustomModal>
								<CustomModal
									icon={notifSuccess ? 'success' : 'error'}
									open={modalNotif}
									onClose={() => setModalNotif(false)}
								>
									<Typography variant='h6'>
										{notifSuccess ? (
											<strong>Form PO Berhasil Dikirim</strong>
										) : (
											<strong>Purchase Order Gagal Dibuat</strong>
										)}
									</Typography>
									<Box
										display='flex'
										flexDirection='row'
										mt={3}
										justifyContent='center'
										// width={300}
									>
										<Button
											className={classes.buttonWidth}
											size='small'
											color='secondary'
											variant='contained'
											onClick={() => handleModalOK()}
										>
											{' '}
											OK{' '}
										</Button>
									</Box>
								</CustomModal>

								{/* billing modal */}
								<ModalBilling
									setFieldValue={action.setFieldValue}
									open={modalBilling}
									onClose={() => setModalBilling(false)}
								/>

								{/* end of billing modal */}
								{detailMou.data && detailMou.data.id ? (
									<ModalDelivery
										setFieldValue={action.setFieldValue}
										open={modalDelivery}
										onClose={() => setModalDelivery(false)}
										mouID={detailMou.data.id}
									/>
								) : null}
							</>
						)
					}}
				</Formik>
			</Container>
		</Layout>
	)
}

Component.propTypes = {
	mouNumber: PropTypes.string,
	navigate: PropTypes.func,
}

export default Component
