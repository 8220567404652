import {
	SUBMIT_RFQ,
	FILTER_ITEM_RFQ,
	RESET_LIST_CART_ITEM,
} from '../../../types'
import API from 'Utilities/api'
import { map } from 'lodash'
import { failure, loading, success } from '../../../commonActions'
import { navigate } from 'gatsby'
import { getPrice } from '../../detailProductAction'
const filterItem = (type, data) => {
	return {
		type: `${type}_FULFILLED`,
		payload: {
			data,
		},
	}
}

export const requestRfqItems = () => {
	return async (dispatch, getState) => {
		const cartItems = await getState().cart.savedCart.items
		const listAllCartItems = await getState().cart.listCart.filteredItems
		const auth = await getState().auth
		const ids = map(cartItems, 'id')
		let filterCartByItemSelected = listAllCartItems.filter((item) =>
			ids.includes(item.id)
		)
		filterCartByItemSelected.map((product, key) => {
			getPrice(
				parseInt(product.product_id),
				parseInt(auth.data.data.companies.partner_id),
				parseInt(product.variant.id),
				0,
				parseInt(product.qty)
			).then((res) => {
				if ('error_backend' in res.data) {
					filterCartByItemSelected[key].selling_price = 0
				} else {
					filterCartByItemSelected[key].selling_price = res.data.data.result.price
				}
				dispatch(
					filterItem(
						FILTER_ITEM_RFQ,
						filterCartByItemSelected.filter((item) => item.selling_price > 0)
					)
				)
			})
		})
	}
}

export const submitRfq = (values) => {
	return async (dispatch) => {
		dispatch(loading(SUBMIT_RFQ))
		try {
			const response = await postRequestSubmitRfq(values)
			if ('error_backend' in response.data) {
				if (response.data.error_backend.http_status_code == 401) {
					navigate('/login', { replace: true })
				} else if (response.data.error_backend.http_status_code == 500) {
					dispatch(failure(SUBMIT_RFQ, response.data.error_backend.http_body))
				} else {
					const errBody = JSON.parse(response.data.error_backend.http_body)
					let inactiveIds = []
					if (errBody?.error?.data) {
						inactiveIds = getActiveIds(errBody.error.data, false)
					}
					const activeItems = values.cart_items_id.filter(
						(item) => !inactiveIds.includes(item)
					)
					if (activeItems.length > 0) {
						dispatch(
							submitRfq({
								...values,
								cart_items_id: activeItems,
							})
						)
					} else {
						navigate('/cart', { replace: true })
					}
				}
			} else {
				dispatch({
					type: RESET_LIST_CART_ITEM,
				})
				dispatch(success(SUBMIT_RFQ, response.data))
				navigate('/dashboard/rfq-submited', { replace: true })
			}
		} catch (err) {
			dispatch(failure(SUBMIT_RFQ, err))
		}
	}
}

const postRequestSubmitRfq = async (values) => {
	return await API({
		url: '/carts/sale-order',
		method: 'POST',
		data: {
			cart_items_id: values.cart_items_id,
			client_order_ref: values.client_order_ref,
			partner_invoice_id: values.partner_invoice_id,
			partner_shipping_id: values.partner_shipping_id,
			expected_delivery_date:
				values.expected_delivery_date === 'Invalid date'
					? ''
					: values.expected_delivery_date,
			receiver_name: values.receiver_name,
			receiver_phone: values.receiver_phone,
			customer_internal_notes: values.customer_internal_notes,
			note: values.note,
			requestor_name: values.requestor_name,
			requestor_department: values.requestor_department,
		},
	})
}

const getActiveIds = (productsStatus, active) => {
	return productsStatus
		.filter((product) => product.active == active)
		.map((item) => item.id)
}
