import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import { Box, Typography } from '@material-ui/core'
import styles from './styles'
import { dateFormatFromOdoo } from '../../utils/globalUtils'
import PropTypes from 'prop-types'

export default function TrackingLine({ data }) {
	const classes = styles()
	const dataTracking = data.reverse()

	return (
		<Timeline align='left' style={{ fontSize: 13, padding: 0 }}>
			{dataTracking.map((data, i) => (
				<TimelineItem key={i}>
					<TimelineOppositeContent style={{ flex: 0.4 }}>
						<Box
							className={[
								classes.timelineText,
								i == 0 ? classes.timelineTextActive : classes.timelineTextDefault,
							].join(' ')}
						>
							{dateFormatFromOdoo(data.create_date, 'dd MMMM yyyy')}
						</Box>
						<Box className={classes.timelineText}>
							{dateFormatFromOdoo(data.create_date, 'HH:mm')} WIB
						</Box>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							className={[
								classes.timelineDot,
								i == 0 ? classes.timelineDotActive : classes.timelineDotDefault,
							].join(' ')}
						/>
						<TimelineConnector className={classes.timelineConnector} />
					</TimelineSeparator>
					<TimelineContent>
						<Box
							className={[
								classes.timelineText,
								i == 0 ? classes.timelineTextActive : classes.timelineTextDefault,
							].join(' ')}
						>
							{data.rowstate_name}
						</Box>
						<Box>
							<Typography variant='body2' className={classes.timelineText}>
								Lokasi / Oleh: {data.counter_name} by {data.user_inp}
							</Typography>
							<Typography variant='body2' className={classes.timelineText}>
								Keterangan: {data.description}
							</Typography>
							<Typography variant='body2' className={classes.timelineText}>
								No. Referensi: {data.reference_no}
							</Typography>
						</Box>
					</TimelineContent>
				</TimelineItem>
			))}
		</Timeline>
	)
}

TrackingLine.propTypes = {
	data: PropTypes.array.isRequired,
}
