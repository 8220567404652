import React from 'react'
import PropTypes from 'prop-types'
import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContentText,
	Typography,
	Paper,
	Button,
	DialogContent,
} from '@material-ui/core'

function Component({ open, handleClose }) {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>Departemen Untuk Pembelian</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					<Paper elevation={0}>
						<Typography variant='body1'>
							Jika akun Anda didaftarkan sebagai Pemohon, maka akun akan memiliki
							Departemen yang diwakilkan pembeliannya.
						</Typography>
					</Paper>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	)
}

Component.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
}

export default Component
