import * as Yup from 'yup'

const schemaRfq = Yup.object().shape({
	noRefPO: Yup.string(),
	receiverPhone: Yup.number()
		.typeError('Input Harus Angka')
		.required('No Telepon Penerima Harus Diisi'),
	keperluan: Yup.string(),
	namaPenerima: Yup.string().required('Nama Penerima Harus Diisi'),
	alamatPengiriman: Yup.string().required('Alamat Pengiriman Harus Diisi'),
	alamatTagihan: Yup.string().required('Alamat Tagihan Harus Diisi'),
})

export default schemaRfq
