import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	container: ({ variant }) => ({
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor:
			variant === 'alert'
				? theme.palette.alert.main
				: variant === 'warning'
				? theme.palette.warning.light
				: theme.palette.success.light,
		padding: 8,
		borderRadius: 5,
		boxShadow: `0px 0px 2px ${
			variant === 'alert'
				? theme.palette.alert.main
				: variant === 'warning'
				? theme.palette.warning.light
				: theme.palette.success.light
		}`,
	}),
	icon: ({ variant }) => ({
		marginRight: theme.spacing(1),
		display: 'flex',
		color:
			variant === 'alert'
				? theme.palette.alert.dark
				: variant === 'warning'
				? theme.palette.warning.dark
				: theme.palette.success.main,
	}),

	iconClose: ({ variant }) => ({
		color:
			variant === 'alert'
				? theme.palette.alert.dark
				: variant === 'warning'
				? theme.palette.warning.dark
				: theme.palette.success.main,
		alignSelf: 'flex-end',
	}),
}))

export default styles
