import React from 'react'
import { Box, Grid, Typography, Divider } from '@material-ui/core'
import { KeyboardBackspace } from '@material-ui/icons'
import LayoutComponent from 'Components/layout'
import { registerStyle } from './styles'
import Logo from '../../assets/images/svgs/logo-white.svg'
import PropTypes from 'prop-types'

const handleNavigate = () => {
	// navigate(path)
	window.location.href = '/'
}

const Register = () => {
	const classes = registerStyle()
	return (
		<LayoutComponent
			header={false}
			newsletter={false}
			className={classes.register_background}
			variant='light'
			isFooter={false}
		>
			<Grid container className={classes.containers}>
				<Grid item lg={4} md={4} sm={6} xs={12} className={classes.detailWrapper}>
					<Box className={classes.detail}>
						<Box className={classes.BoxMB}>
							<Typography variant='h5' className={classes.detailTitle}>
								Pendaftaran Akun Baru
							</Typography>
							<Typography variant='body2'>
								Untuk pendaftaran akun baru, silakan <br />
								menghubungi:
							</Typography>
						</Box>
						<Box className={classes.BoxMB}>
							<Typography variant='h6' className={classes.detailTitle}>
								Layanan Pelanggan
							</Typography>
							<Typography variant='body2'>
								Email : <span className={classes.uri}>help@mbiz.co.id</span>
							</Typography>
							<Typography variant='body2'>Telp : 021 400 01 036</Typography>
						</Box>
						<Divider className={classes.registerDivider} />
						<Box className={classes.BoxMB}>
							<Typography variant='h6' className={classes.detailTitle}>
								Jadwal Operasional
							</Typography>
							<Typography variant='body2'>
								Senin - Jumat (Kecuali hari libur atau cuti bersama)
							</Typography>
							<Typography variant='body2'>09.00 - 18.00 WIB</Typography>
						</Box>
						<Divider className={classes.registerDivider} />
						<Box className={classes.BoxMB}>
							<img src={Logo} className={classes.mbiz_image} />
							{/* <address> */}
							<Typography variant='body2'>PT Brilliant Ecommerce Berjaya</Typography>
							<Typography variant='body2'>Jl. HR. Rasuna Said Kav. B-12</Typography>
							<Typography variant='body2'>Setiabudi, Jakarta 12940</Typography>
							<Typography variant='body2'>Indonesia</Typography>
							{/* </address> */}
						</Box>
						<Divider className={classes.registerDivider} />
						<Box>
							<Grid className={classes.back} onClick={() => handleNavigate('/')}>
								<KeyboardBackspace />
								<Typography variant='body1'>Ke Halaman Utama</Typography>
							</Grid>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</LayoutComponent>
	)
}

Register.propTypes = {
	className: PropTypes.object,
}
export default Register
