/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import { AboutBudget, AddBudget, DetailBudget, ListAnggaran } from '../routes'
import { rolesText } from '../constants/text'

export const BudgetNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard/purchase-setting/budget/about'
				roles={rolesText('billing')}
				Component={AboutBudget}
			/>
			<PrivateRoute
				path='/dashboard/purchase-setting/budget/add'
				roles={rolesText('billing')}
				Component={AddBudget}
			/>
			<PrivateRoute
				path='/dashboard/purchase-setting/budget/detail/:budgetID'
				roles={rolesText('billing')}
				Component={DetailBudget}
			/>
			<PrivateRoute
				path='/dashboard/purchase-setting/budget'
				roles={rolesText('billing')}
				Component={ListAnggaran}
			/>
			<PrivateRoute
				path='/dashboard/purchase-setting/budget/edit/:budgetID'
				roles={rolesText('billing')}
				Component={AddBudget}
			/>
		</>
	)
}
