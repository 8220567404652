/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Box, Typography } from '@material-ui/core'
import DashboardLayout from 'Components/dashboardLayout'
import Breadcrumbs from 'Components/breadcrumbs'
import { requestUserAccountDetail } from 'States/actions/UsersAccount/detailAction'
import { checkUser } from 'Utilities/globalUtils'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import { tableStyle, styles } from './style'

const breadcrumbs = [
	{
		segment: 'Home',
		link: '/dashboard',
	},
	{
		segment: 'Perusahaan',
		link: '/dashboard',
	},
	{
		segment: 'Daftar Akun Pengguna',
		link: '/dashboard/user-account',
	},
	{
		segment: 'Ubah Akun Pengguna',
		link: '#',
	},
	{
		segment: 'Dokumen Dalam Proses',
		link: '#',
	},
]

function Component({ userID }) {
	const classes = styles()
	const dispatch = useDispatch()
	const userData = useSelector((state) => state.UsersAccountDetailReducer)
	const [data, setData] = useState({
		loading: false,
		result: [],
	})

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		dispatch(requestUserAccountDetail(userID))
		getDocumentList(userID)
	}, [userID])

	function getDocumentList(id) {
		setData({ ...data, loading: true, result: [] })
		checkUser(id)
			.then((res) => {
				setData({ ...data, loading: false, result: res.data })
			})
			.catch((err) => console.log(err))
	}

	const columns = [
		{
			name: 'NO',
			selector: (_row, index) => index + 1,
			width: '200px',
		},
		{
			name: 'NO. PURCHASE REQUEST',
			selector: 'purchase_request_no',
			cell: (row) => (
				<a
					href={`${location.origin}/dashboard/purchase-request/${row.purchase_request_no}`}
					rel='noopener noreferrer'
					target='_blank'
				>
					{row.purchase_request_no}
				</a>
			),
		},
	]

	const transactionColumn = [
		{
			name: 'NO',
			selector: (_row, index) => index + 1,
			width: '200px',
		},
		{
			name: 'NO. RFQ',
			selector: 'rfq_no',
			cell: (row) => (
				<>
					{row.rfq_no ? (
						<a
							href={`${location.origin}/dashboard/rfq/${row.rfq_no}`}
							rel='noopener noreferrer'
							target='_blank'
						>
							{row.rfq_no}
						</a>
					) : (
						'-'
					)}
				</>
			),
			width: '250px',
		},
		{
			name: 'NO. QUOTATION',
			selector: 'quotation_no',
			cell: (row) => (
				<>
					{row.quotation_no ? (
						<a
							href={`${location.origin}/dashboard/quotation/${row.quotation_no}`}
							rel='noopener noreferrer'
							target='_blank'
						>
							{row.quotation_no}
						</a>
					) : (
						'-'
					)}
				</>
			),
			width: '250px',
		},
	]

	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box height={70} alignItems='center' display='flex'>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>
				<Box p={1}>
					<Typography variant='h6'>Dokumen Dalam Proses</Typography>
					{data.result.transaction && data.result.transaction.length ? (
						<>
							<Box mt={2}>
								<Typography>
									Berikut daftar dokumen dengan pemohon{' '}
									<strong>
										{!userData.isLoading && userData.data && userData.data.name}
									</strong>{' '}
									yang belum di proses hingga tahap Purchase Order
								</Typography>
							</Box>
							<Box mt={3}>
								<DataTable
									data={data.result.transaction}
									columns={transactionColumn}
									noHeader
									customStyles={tableStyle}
									persistTableHead
								/>
							</Box>
						</>
					) : null}

					{data.result.purchase_requests && data.result.purchase_requests.length ? (
						<>
							<Box mt={3}>
								<Typography variant='body1'>
									Berikut daftar dokumen Purchase Request yang membutuhkan persetujuan{' '}
									<strong>
										{!userData.isLoading && userData.data && userData.data.name}
									</strong>
									:
								</Typography>
							</Box>
							<Box mt={3}>
								<DataTable
									columns={columns}
									data={data.result.purchase_requests}
									noHeader
									customStyles={tableStyle}
									progressPending={data.loading}
									persistTableHead
								/>
							</Box>
						</>
					) : null}
				</Box>
			</Container>
		</DashboardLayout>
	)
}

Component.propTypes = {
	userID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Component
