import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactTags from 'react-tag-autocomplete'
import { Grid, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import {
	filterData,
	markIt,
	removeItemArray,
	sortArraybyId,
	sortArrayNumber,
} from '../../utils/globalUtils'

const TagAutocomplete = (props) => {
	const initialSuggestion = props.initialSuggestion
	const initialTag = props.initialTag
	const maxTag = props.setMaxTag
	const [suggestions, setsuggestions] = useState(initialSuggestion)
	const [tagSuggest, setTags] = useState([])
	const [firstInitial, setFirstInitial] = useState(props.isNeedFirstInitialTags)

	const StyleTooltip = withStyles(() => ({
		arrow: {
			'&:before': {
				border: '1px solid #E6E8ED',
			},
			color: 'white',
		},
		tooltip: {
			backgroundColor: 'white',
			border: '1px solid #E6E8ED',
			color: 'black',
			margin: 5,
		},
	}))(Tooltip)

	useEffect(() => {
		if (props.isNeedFirstInitialTags)
			setFirstInitial(props.isNeedFirstInitialTags)
	}, [props.isNeedFirstInitialTags])

	useEffect(() => {
		if (props.checked) {
			if (firstInitial) handleAddition(initialTag)
		}
	}, [props.checked, firstInitial])

	useEffect(() => {
		if (props.initialSuggestion) {
			setsuggestions(props.initialSuggestion)
		}
	}, [props.initialSuggestion])

	const handleDelete = (i) => {
		setFirstInitial(false)
		let suggest = props.initialSuggestion
		let findDataSuggestions =
			tagSuggest.length !== 1 && isFinite(i)
				? suggest.findIndex((item) => item.id === tagSuggest[i].id)
				: -1
		let temp = [...suggestions]
		if (findDataSuggestions !== -1) {
			temp.push(suggest[findDataSuggestions])
			temp = sortArraybyId(temp)
		}
		const tags = [...tagSuggest]
		let res = tags.length === 1 ? [] : removeItemArray(tags, i)
		setTags(res)
		props.setParentTag(res)
		props.setParentChecked(tagSuggest.length === 1 ? false : true)
		setsuggestions(tagSuggest.length === 1 ? suggest : temp)
	}

	const handleAddTagArray = (data) => {
		let temp = []
		data && data.length != 0
			? data.map((tempItem) => {
					let res = suggestions.findIndex((item) => item.id === tempItem.id)
					temp.push(res)
			  })
			: null
		return temp.length != 0 ? temp : -1
	}

	const handleAddition = (tag) => {
		setFirstInitial(false)
		let validate = tagSuggest.length === 0 ? false : filterData(tagSuggest, tag)
		let findDataSuggestions =
			tag && Array.isArray(tag)
				? handleAddTagArray(tag)
				: suggestions.findIndex((item) => item.id === tag.id)
		if (!validate) {
			if (tagSuggest.length != maxTag) {
				let temp = [...suggestions]
				if (Array.isArray(findDataSuggestions)) {
					sortArrayNumber(findDataSuggestions)
					findDataSuggestions
						.reverse()
						.map((item) => (item != -1 ? temp.splice(item, 1) : null))
				} else if (findDataSuggestions !== -1) {
					temp.splice(findDataSuggestions, 1)
				}
				const tags =
					tagSuggest.length < maxTag
						? Array.isArray(findDataSuggestions)
							? [].concat(tagSuggest, tag)
							: findDataSuggestions !== -1
							? [].concat(tagSuggest, suggestions[findDataSuggestions])
							: [].concat(tagSuggest, tag)
						: tagSuggest

				setTags(tags)
				props.setParentTag(tags)
				setsuggestions(temp)
			}
		}
	}

	const SuggestionComponent = (item, query) => {
		return (
			<span
				id={item.id}
				style={{ fontSize: 12 }}
				dangerouslySetInnerHTML={{
					__html: markIt(`${item.name} ${item.email ? item.email : ''}`, query),
				}}
			/>
		)
	}

	const componentTag = (tag, onDelete) => {
		return (
			<StyleTooltip
				title={
					<div>
						<Typography
							variant='body1'
							style={{ fontWeight: 600, fontSize: 13, color: '#343A40' }}
						>
							{tag.name}
						</Typography>
						<Typography variant='body2' style={{ fontSize: 11, color: '#343A40' }}>
							{tag.email}
						</Typography>
					</div>
				}
				placement='bottom-end'
			>
				<button
					type='button'
					onClick={onDelete}
					style={{ marginRight: 5, border: '1px solid #BBBBBB' }}
				>
					<Grid
						container
						direction='row'
						style={{ height: '18px' }}
						justify='flex-start'
						alignItems='center'
					>
						<Typography
							variant='body2'
							style={{ color: '#6C757E', fontSize: 14, marginRight: 3 }}
						>
							{tag && tag.name
								? tag.name.length > 20
									? `${tag.name.slice(0, 20)}...`
									: tag.name
								: ''}
						</Typography>
						<CloseIcon style={{ fontSize: 14, color: '#6C757E' }} />
					</Grid>
				</button>
			</StyleTooltip>
		)
	}

	return (
		<div>
			<ReactTags
				tags={tagSuggest}
				suggestions={tagSuggest.length < maxTag ? suggestions : []}
				onDelete={(i) => handleDelete(i)}
				onAddition={(tag) => handleAddition(tag)}
				allowNew={false}
				placeholderText={tagSuggest.length < maxTag ? props.placeholderText : ''}
				minQueryLength={1}
				maxSuggestionsLength={10}
				tagComponent={(item) => componentTag(item.tag, item.onDelete)}
				suggestionComponent={(tag) => SuggestionComponent(tag.item, tag.query)}
				classNames={props.classNames}
			/>
		</div>
	)
}

TagAutocomplete.propTypes = {
	initialSuggestion: PropTypes.object,
	initialTag: PropTypes.object,
	checked: PropTypes.bool,
	setParentTag: PropTypes.func,
	setParentChecked: PropTypes.func,
	placeholderText: PropTypes.string,
	classNames: PropTypes.object,
	setMaxTag: PropTypes.number,
	isNeedFirstInitialTags: PropTypes.bool,
}

TagAutocomplete.defaultProps = {
	isNeedFirstInitialTags: true,
	setMaxTag: 10,
}

export default TagAutocomplete
