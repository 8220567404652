/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import styles from './styles'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { statusText } from '../../../../assets/status'
import {
	requestDetailInvoice,
	resetAction as resetActionInvoice,
} from 'States/actions/Transactions/Invoice/detailAction'
import { withStyles } from '@material-ui/core/styles'
import {
	Box,
	Button,
	Container,
	Paper,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	CircularProgress,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Layout from 'Components/layout'
import Breadcrumbs from 'Components/breadcrumbs'
import Image from 'material-ui-image'
import { numeralFormat } from '../../../../utils/formatMoney'
import {
	generateAddress,
	dateFormatFromOdoo,
} from '../../../../utils/globalUtils'
import ScrollToTop from 'Components/scrollToTop'
import PrintReport from 'Components/buttonPrintReport'
import Timeline from 'Components/timeline'
import {
	getTimeline,
	resetAction,
} from 'States/actions/Transactions/Timeline/timelineAction'
import { dataTransactions } from '../../../../utils/bodyDataObject'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)
const BorderedCell = withStyles(() => ({
	root: {
		borderTop: '1px solid #ddd',
	},
}))(TableCell)

const Component = ({ invoiceID, navigate }) => {
	const classes = styles()
	const dispatch = useDispatch()
	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Transaksi',
			link: '/dashboard',
		},
		{
			segment: 'Tagihan',
			link: '/dashboard/invoice',
		},
		{
			segment: 'Rincian Tagihan',
			link: '#',
		},
	])
	const detailInvoice = useSelector((state) => state.invoiceDetailReducer)

	const timelineData = useSelector((state) => state.timelineReducer)

	const imageStorage = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'

	const [dataInvoice, setDataInvoice] = useState({ ...dataTransactions })

	useEffect(() => {
		dispatch(requestDetailInvoice(invoiceID))
		return () => {
			dispatch(resetActionInvoice())
		}
	}, [invoiceID])

	useEffect(() => {
		if (
			detailInvoice.data != null &&
			detailInvoice.isLoading == false &&
			detailInvoice.data.name == invoiceID
		) {
			let dataTemp = dataInvoice
			dataTemp.noInvoice = detailInvoice.data.name
			dataTemp.noMOU = detailInvoice.data.sales_agreement_id?.name
			dataTemp.noPO = detailInvoice.data.po_number
			dataTemp.noDNBAST = detailInvoice.data.shipping_number || '-'
			dataTemp.NoRefPO = detailInvoice.data.po_client_order_ref || '-'
			dataTemp.timeCreate =
				dateFormatFromOdoo(
					detailInvoice.data.finance_created_date,
					'dd MMMM yyyy'
				) || '-'
			dataTemp.termOfPayment =
				String(detailInvoice.data.invoice_payment_term_id.name).replace(
					'Days',
					'hari'
				) || null
			dataTemp.state = detailInvoice.data.state
			dataTemp.paymentState = detailInvoice.data.invoice_payment_state
			dataTemp.dueDate =
				dateFormatFromOdoo(detailInvoice.data.invoice_date_due, 'dd MMMM yyyy') ||
				'-'
			dataTemp.shippingAdd =
				detailInvoice.data.partner_shipping_id.street || '-'
			dataTemp.shippingAddLocation =
				detailInvoice.data.partner_shipping_id.region_id.complete_name || '-'
			dataTemp.billingAdd =
				detailInvoice.data.partner_invoice_id.street || '-'
			dataTemp.billingAddLocation =
				detailInvoice.data.partner_invoice_id.region_id.complete_name || '-'
			dataTemp.amountUntax =
				numeralFormat(detailInvoice.data.amount_untaxed_signed) || null
			dataTemp.amountTotal =
				numeralFormat(detailInvoice.data.amount_total_signed) || null
			dataTemp.amountGroup = detailInvoice.data.amount_by_group
			dataTemp.orderLine = detailInvoice.data.invoice_line_ids
			dataTemp.shippingVendor = detailInvoice.data.shipping_vendor
			dataTemp.id = detailInvoice.data.id
			setDataInvoice(dataTemp)
			detailInvoice.data.transaction_id && detailInvoice.data.transaction_id[0]
				? dispatch(getTimeline(detailInvoice.data.transaction_id[0].id))
				: null
		} else {
			dispatch(resetAction())
		}
	}, [detailInvoice.data, detailInvoice.isLoading, invoiceID])

	const splitVariant = (str) => {
		const arr = str.match(/\(.+?\)/g)
		let joinVar = '-'
		if (arr) {
			const variant = arr.map((item) => item.slice(1, -1))
			joinVar = variant
		}

		return joinVar
	}

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>

				{/* top form */}
				<Box mb={3}>
					<Grid style={{ display: 'flex' }}>
						{dataInvoice.id && (
							<PrintReport ext='pdf' reportType='invoice' reportID={dataInvoice.id} />
						)}
						<Typography variant='h6'>Rincian Tagihan</Typography>
					</Grid>

					<Paper className={classes.formRfqTop}>
						<Box py={2} px={3}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : dataInvoice.noInvoice ? (
															<a
																href={`${window.location.origin}/dashboard/invoice/${dataInvoice.noInvoice}`}
																target='blank'
															>
																{dataInvoice.noInvoice}
															</a>
														) : (
															'-'
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. MOU</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : dataInvoice.noMOU ? (
															<a
																href={`${window.location.origin}/dashboard/mou/detail/${dataInvoice.noMOU}`}
																target='blank'
															>
																{dataInvoice.noMOU}
															</a>
														) : (
															'-'
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Purchase Order</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : dataInvoice.noPO ? (
															<a
																href={`${window.location.origin}/dashboard/purchase-order/${dataInvoice.noPO}`}
																target='blank'
															>
																{dataInvoice.noPO}
															</a>
														) : (
															'-'
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. DN/BAST</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataInvoice.noDNBAST
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>No. Referensi PO</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataInvoice.NoRefPO
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Tanggal Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataInvoice.timeCreate
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Tipe Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : (
															statusText(dataInvoice.state) || null
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Status Pembayaran</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataInvoice.state === 'proforma'
																? '-'
																: statusText(String(dataInvoice.paymentState).toLocaleLowerCase()) ||
																'-'
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Jangka Waktu Pembayaran</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' width='80%' />
														) : (
															dataInvoice.termOfPayment
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Tanggal Jatuh Tempo</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' width='80%' />
														) : (
															dataInvoice.dueDate
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Pengiriman</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<>
																<Skeleton variant='text' width='90%' />
																<Skeleton variant='text' width='75%' />
																<Skeleton variant='text' width='80%' />
															</>
														) : (
															dataInvoice.shippingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataInvoice.shippingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={6}>
													<Typography variant='body2'>
														<strong>Alamat Tagihan</strong>
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography variant='body2'>
														{detailInvoice.isLoading ? (
															<>
																<Skeleton variant='text' width='90%' />
																<Skeleton variant='text' width='75%' />
																<Skeleton variant='text' width='80%' />
															</>
														) : (
															dataInvoice.billingAdd
														)}
													</Typography>
													<Typography variant='body2' className={classes.textLocation}>
														{detailInvoice.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataInvoice.billingAddLocation
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Box>
				{/* end of top form */}

				<Box mb={3}>
					<Paper style={{ backgroundColor: '#cce5ff' }}>
						<Box p={3}>
							<Box mb={2}>
								<Grid container justify='center'>
									<Grid item lg={12}>
										<Typography variant='body2' align='center'>
											<strong>
												Pembelian dapat dikirimkan ke salah satu rekening PT. Brilliant
												eCommerce Berjaya
											</strong>
										</Typography>
									</Grid>
								</Grid>
							</Box>

							<Box mb={2}>
								<Grid container>
									{/* =================== */}
									<Grid item container lg={6}>
										<Grid item lg={6}>
											<Typography variant='body2'>
												<strong>PT BANK NATIONAL NOBU</strong>
											</Typography>
										</Grid>
										<Grid item lg={6}>
											<Typography variant='body2'>
												16830003313 (Cabang Menara Matahari)
											</Typography>
										</Grid>
									</Grid>
									{/* ==================== */}
									<Grid item container lg={6}>
										<Grid item lg={6}>
											<Typography variant='body2'>
												<strong>PT BANK CENTRAL ASIA (BCA)</strong>
											</Typography>
										</Grid>
										<Grid item lg={6}>
											<Typography variant='body2'>
												4509990899 (Cabang Menara Matahari)
											</Typography>
										</Grid>
									</Grid>
									{/* ==================== */}
								</Grid>
							</Box>

							<Box mb={2}>
								<Grid container>
									{/* =================== */}
									<Grid item container lg={6}>
										<Grid item lg={6}>
											<Typography variant='body2'>
												<strong>PT BANK MEGA</strong>
											</Typography>
										</Grid>
										<Grid item lg={6}>
											<Typography variant='caption'>
												013410011000123 (Cabang Kuningan Card Center)
											</Typography>
										</Grid>
									</Grid>
									{/* ==================== */}
									<Grid item container lg={6}>
										<Grid item lg={6}>
											<Typography variant='body2'>
												<strong>PT BANK CIMB NIAGA</strong>
											</Typography>
										</Grid>
										<Grid item lg={6}>
											<Typography variant='body2'>
												800109427800 (Cabang Menara ASIA)
											</Typography>
										</Grid>
									</Grid>
									{/* ==================== */}
								</Grid>
							</Box>

							<Box mb={2}>
								<Grid container>
									{/* =================== */}
									<Grid item container lg={6}>
										<Grid item lg={6}>
											<Typography variant='body2'>
												<strong>PT BANK DANAMON INDONESIA</strong>
											</Typography>
										</Grid>
										<Grid item lg={6}>
											<Typography variant='body2'>
												3609423169 (Cabang Menara Danamon)
											</Typography>
										</Grid>
									</Grid>
									{/* ===================== */}
								</Grid>
							</Box>
						</Box>
					</Paper>
				</Box>
				{/* bottom form */}
				<Box>
					<Paper>
						<Box py={2} px={3}>
							{/* table */}
							<Box>
								<Table classes={{ root: classes.tableRoot }}>
									<TableHead>
										<TableRow>
											<TableCell align='center'>No</TableCell>
											<TableCell align='center'>Produk</TableCell>
											<TableCell align='center'>Unit</TableCell>
											<TableCell align='center'>Kuantitas</TableCell>
											<TableCell align='center'>Harga / Unit</TableCell>
											<TableCell align='center'>Total Harga</TableCell>
											<TableCell align='center'>Pajak</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{detailInvoice.isLoading ? (
											<TableRow key={'skeleton'}>
												<CustomCell>
													<Skeleton variant='text' width='80%' />
												</CustomCell>
												<CustomCell>
													<Box display='flex' flexDirection='row'>
														<Box overflow='hidden' width={60} height={60}>
															<Skeleton variant='rect' width={60} height={60} />
														</Box>
														<Box
															ml={1}
															display='flex'
															flexDirection='column'
															justifyContent='space-between'
															style={{ width: '100%' }}
														>
															<Box>
																<Typography variant='body2'>
																	<Skeleton variant='text' width={250} />
																</Typography>
																<Typography variant='caption'>
																	<Skeleton variant='text' width={250} />
																</Typography>
															</Box>
															<Typography variant='caption'>
																<Skeleton variant='text' width={250} />
															</Typography>
														</Box>
													</Box>
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell
													align='center'
													style={{
														maxWidth: 120,
													}}
												>
													<Skeleton variant='text' />
												</CustomCell>
											</TableRow>
										) : (
											dataInvoice.orderLine && dataInvoice.orderLine.length
												? dataInvoice.orderLine.map((item, index) => (
													<TableRow key={item.id}>
														<CustomCell>{index + 1}</CustomCell>
														<CustomCell>
															<Box display='flex' flexDirection='row'>
																<Box>
																	<Image
																		src={
																			imageStorage +
																			item.product_id.product_tmpl_id.image_128_filestore
																		}
																		style={{ width: 60, height: 60 }}
																	/>
																</Box>
																<Box
																	ml={1}
																	display='flex'
																	flexDirection='column'
																	justifyContent='space-between'
																>
																	<Box>
																		<Typography variant='body2'>
																			{item.product_id.sku_no}
																		</Typography>
																		<Typography variant='caption'>{item.name}</Typography>
																	</Box>
																	<Typography variant='caption'>
																		varian: {splitVariant(item.name)}
																	</Typography>
																</Box>
															</Box>
														</CustomCell>
														<CustomCell align='center'>{item.product_uom_id.name}</CustomCell>
														<CustomCell align='center'>{item.quantity}</CustomCell>
														<CustomCell align='center'>
															{numeralFormat(item.price_unit)}
														</CustomCell>
														<CustomCell align='center'>
															{numeralFormat(item.price_subtotal)}
														</CustomCell>
														<CustomCell
															align='center'
															style={{
																maxWidth: 120,
															}}
														>
															{item.tax_ids &&
																item.tax_ids.length &&
																item.tax_ids.map((tax) => `${tax.name} `)}
														</CustomCell>
													</TableRow>
												))
												: null
										)}
										{dataInvoice.shippingVendor && (
											<>
												<TableRow>
													<BorderedCell colSpan={7}>
														<Typography variant='body1'>
															<strong>Rincian Pengiriman</strong>
														</Typography>
													</BorderedCell>
												</TableRow>
												<TableRow>
													<BorderedCell />
													<BorderedCell colSpan={4}>
														<Typography variant='body1'>
															<strong>Ekspedisi</strong>
														</Typography>
													</BorderedCell>
													<BorderedCell align='center'>
														<Typography variant='body1'>
															<strong>Biaya Pengiriman</strong>
														</Typography>
													</BorderedCell>
													<BorderedCell align='center'>
														<Typography variant='body1'>
															<strong>Pajak</strong>
														</Typography>
													</BorderedCell>
												</TableRow>
												{dataInvoice.shippingVendor.length
													? dataInvoice.shippingVendor.map((item, index) => (
															<TableRow key={index}>
																<CustomCell />
																<CustomCell colSpan={4}>
																	<Box display='flex' flexDirection='row'>
																		<Box>
																			<Image
																				src={
																					imageStorage +
																					item.product_id.product_tmpl_id.image_128_filestore
																				}
																				style={{ width: 60, height: 60 }}
																			/>
																		</Box>
																		<Box ml={1} display='flex' alignItems='center'>
																			<Box>
																				<Typography variant='body2'>{item.name}</Typography>
																			</Box>
																		</Box>
																	</Box>
																</CustomCell>
																<CustomCell align='center'>
																	{numeralFormat(item.price_unit)}
																</CustomCell>
																<CustomCell
																	align='center'
																	style={{
																		maxWidth: 120,
																	}}
																>
																	{item.tax_ids &&
																		item.tax_ids.length &&
																		item.tax_ids.map((tax) => `${tax.name} `)}
																</CustomCell>
															</TableRow>
													  ))
													: null}
											</>
										)}
									</TableBody>
								</Table>
							</Box>
							{/* end of table */}

							{/* total */}
							<Box mt={2}>
								<Box display='flex' flex='1' justifyContent='space-between' mb={2}>
									<Box>
										{/* <Typography variant='body1'>
											<strong>Catatan Permintaan Khusus</strong>
										</Typography>
										<Typography variant='body1'>
											{detailInvoice.isLoading ? null : detailInvoice.data.narration}
										</Typography> */}
									</Box>
									<Box display='flex' minWidth={300} flexDirection='column'>
										<Box px={3} display='flex' flex='1' justifyContent='space-between'>
											<Typography variant='body1'>Subtotal</Typography>
											<Typography variant='body1'>
												{detailInvoice.isLoading ? (
													<Skeleton variant='text' width={100} />
												) : (
													dataInvoice.amountUntax
												)}
											</Typography>
										</Box>
										{dataInvoice.amountGroup.length
											? dataInvoice.amountGroup.map((tax, index) => (
													<Box
														key={index}
														px={3}
														display='flex'
														flex='1'
														justifyContent='space-between'
													>
														<Typography variant='body1'>{tax[0]}</Typography>
														<Typography variant='body1'>{numeralFormat(tax[1])}</Typography>
													</Box>
											  ))
											: null}
										<Box className={classes.totalPrice}>
											<Typography variant='body1'>
												<strong>TOTAL</strong>
											</Typography>
											<Typography variant='body1'>
												{detailInvoice.isLoading ? (
													<Skeleton variant='text' width={100} />
												) : (
													dataInvoice.amountTotal
												)}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							{/* end of total */}
						</Box>
					</Paper>
				</Box>
				{/* end of bottom form */}

				{/* button action wrapper */}
				<Box className={classes.containerButton} mt={2}>
					{dataInvoice.paymentState === 'not_paid' &&
					dataInvoice.state === 'posted' ? (
						<Button
							id='btn-confirm-payment'
							className={classes.buttonWidth}
							variant='contained'
							onClick={() =>
								navigate(`../../payment/confirm/${dataInvoice.noInvoice}`)
							}
							size='small'
							color='secondary'
						>
							Konfirmasi Pembayaran
						</Button>
					) : null}

					<Button
						id='btn-back'
						className={`${classes.buttonWidth} ${classes.kembali} ${classes.buttonDanger}`}
						variant='contained'
						onClick={() => navigate('/dashboard/invoice')}
						size='small'
						color='secondary'
					>
						Kembali
					</Button>
				</Box>
				{/* end of button action wrapper */}

				{/* riwayat pembayaran */}
				{/* <Box>
					<Typography variant='body1'>Riwayat Pembayaran</Typography>
					<Paper>
						<Table size='small'>
							<TableHead>
								<TableRow>
									<TableCell>No</TableCell>
									<TableCell>No. Pembayaran</TableCell>
									<TableCell>Tanggal Transfer</TableCell>
									<TableCell>Tanggal Tagihan</TableCell>
									<TableCell>Jumlah Pembayaran</TableCell>
									<TableCell>Sisa Pembayaran</TableCell>
									<TableCell>Status Pembayaran</TableCell>
									<TableCell>Aksi</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{detailInvoice.isLoading
									? null
									: detailInvoice.data.picking_ids.map((item, index) => (
											<TableRow key={index}>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{item.name}</TableCell>
												<TableCell>{item.mbiz_delivery_no}</TableCell>
												<TableCell>{item.create_date}</TableCell>
												<TableCell>{status(item.state)}</TableCell>
												<TableCell>{item.received_date}</TableCell>
												<TableCell>{item.receiver_name}</TableCell>
												<TableCell>aksi</TableCell>
											</TableRow>
									  ))}
								<TableRow>
									<TableCell></TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
				</Box> */}
				{/* end of riwayat pembayaran */}

				{/* Timeline */}
				{timelineData.isLoading ? (
					<Box style={{ textAlign: 'center' }} mt={2}>
						<CircularProgress size={20} />
					</Box>
				) : timelineData.isLoading == false &&
				  detailInvoice.isLoading == false &&
				  timelineData.data &&
				  timelineData.data.length > 0 &&
				  detailInvoice.data &&
				  detailInvoice.data.id ? (
					<>
						<Box mt={0}>
							<Typography variant='h6'>Timeline</Typography>
						</Box>
						<Box mt={'10px'}>
							<Paper elevation={2}>
								<Timeline data={timelineData.data} id={detailInvoice.data.id} />
							</Paper>
						</Box>
					</>
				) : null}
				{/*End Timeline */}
			</Container>
			<ScrollToTop />
		</Layout>
	)
}

Component.propTypes = {
	invoiceID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	navigate: PropTypes.func,
}

export default React.memo(Component)
