export const dangerTitle = [
	//RFQ
	'Membatalkan RFQ',
	'Menolak RFQ',
	'Menghentikan Tahap RFQ',
	'Membatalkan Permintaan',
	'Menolak Permintaan',
	'Menghentikan Tahap Permintaan',
	//quotation
	'Menolak Quotation',
	'Membatalkan Quotation',
	'Menghentikan Tahap Quotation',
	'Menolak Penawaran',
	'Membatalkan Penawaran',
	'Menghentikan Tahap Penawaran',
	//Purchase Request
	'Menolak Purchase Request',
	'Menolak Persetujuan Pembelian',
	'Menghentikan Proses Persetujuan',
	//Purchase Order,
	'Menolak Purchase Order',
	'Menolak Pesanan Pembelian',
	//Invoice,
	'Membatalkan Tagihan',
	//Payment,
	'Menolak Pembayaran',
]

export const warningTitle = [
	//quotation
	'Merevisi Quotation',
	'Merevisi Penawaran',
	//Invoice,
	'Menarik Tagihan',
	'Tagihan Jatuh Tempo',
	//Payment,
	'Memproses Ulang Konfirmasi Pembayaran',
]

export const mappingActor = (actor) => {
	switch (actor) {
		case 'BUYER':
			return 'Pembeli'
		case 'MBIZ':
			return 'Mbiz'
		case 'APPROVER':
			return 'Penyetuju'
		case 'SYSTEM':
			return 'Sistem'
		default:
			return '-'
	}
}
