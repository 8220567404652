import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, Box } from '@material-ui/core'
import LoginLayout from 'Components/loginLayout'
import { loginStyle } from '../Login/styles'
import { ForgotPasswordForm } from 'Components/forms'
import { useSelector, useDispatch } from 'react-redux'
import { isBrowser } from '../../utils/globalUtils'
import moment from 'moment'
import { TautanEmailSuccess, TautanEmailGagal } from 'Components/svgs'
import PropTypes from 'prop-types'
import { resetAction } from 'States/actions/UsersAccount/forgotPasswordAction'

const ForgotPassword = ({ navigate }) => {
	const dispatch = useDispatch()
	const classes_loginStyle = loginStyle()
	const forgotPasswordReducer = useSelector(
		(state) => state.forgotPasswordReducer
	)
	let mins = isBrowser()
		? JSON.parse(window.localStorage.getItem('timeCount'))
		: 0
	const [seconds, setSecond] = useState(mins && mins <= 62 ? mins : 0)
	const [showTime, setShowTime] = useState(
		isBrowser() ? JSON.parse(window.localStorage.getItem('isShowTime')) : false
	)

	const timeDifferent = isBrowser()
		? JSON.parse(window.localStorage.getItem('timeDiff'))
		: null

	const errForgotPass =
		forgotPasswordReducer.isLoading == false &&
		forgotPasswordReducer.isError &&
		forgotPasswordReducer.error
			? JSON.parse(forgotPasswordReducer.error.http_body)
			: null
	let interval = null

	// reset interval count timer
	const resetInterval = () => {
		clearInterval(interval)
		localStorage.setItem('timeCount', JSON.stringify(0))
		localStorage.setItem('timeDiff', JSON.stringify(null))
		localStorage.setItem('isShowTime', JSON.stringify(false))
	}

	const startInterval = () => {
		window.location.reload()
	}

	//component did mount untuk cek perbedaan waktu set time countnya dengan waktu reload
	useEffect(() => {
		let timeNow = moment(new Date())
		let diffTime = timeDifferent ? timeNow.diff(timeDifferent, 'seconds') : 0
		setSecond(diffTime > 60 ? 0 : seconds)
		setShowTime(diffTime > 60 || diffTime == 0 ? false : true)
	}, [])

	//component did update for set interval time count nya
	useEffect(() => {
		if (seconds > 0) {
			interval = null
			if (seconds <= 60) {
				interval = setInterval(() => {
					setSecond(seconds - 1)
					localStorage.setItem('timeCount', JSON.stringify(seconds - 1))
				}, 1000)
			} else if (seconds === 0) {
				resetInterval()
			}
		} else if (seconds == 0) {
			resetInterval()
		}
		return () => clearInterval(interval)
	}, [seconds])

	useEffect(() => {
		if (
			forgotPasswordReducer.data &&
			forgotPasswordReducer.isError == false &&
			forgotPasswordReducer.data.contact_id
		) {
			setSecond(60)
			localStorage.setItem('timeCount', JSON.stringify(60))
			localStorage.setItem('isShowTime', JSON.stringify(true))
			localStorage.setItem(
				'timeDiff',
				JSON.stringify(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
			)
		}
	}, [forgotPasswordReducer])

	const handleBeranda = () => {
		dispatch(resetAction())
		navigate('/')
	}

	return (
		<LoginLayout
			classes={classes_loginStyle}
			type={
				forgotPasswordReducer.data &&
				forgotPasswordReducer.isError == false &&
				forgotPasswordReducer.data.contact_id
					? 'messageSuccess'
					: forgotPasswordReducer.data &&
					  forgotPasswordReducer.isError &&
					  forgotPasswordReducer.error &&
					  errForgotPass.error.error_type == 'request_limit'
					? 'message'
					: 'form'
			}
		>
			{forgotPasswordReducer.data &&
			forgotPasswordReducer.isError == false &&
			forgotPasswordReducer.data.contact_id ? (
				<Grid style={{ marginTop: 25 }}>
					<TautanEmailSuccess />
					<Typography
						className={classes_loginStyle.titleTautanEmail}
						text-align='center'
						variant='h6'
						style={{ marginBottom: 20, marginTop: 10 }}
					>
						Tautan Berhasil Terkirim
					</Typography>
					<Typography
						variant='body2'
						text-align='left'
						className={classes_loginStyle.textLeft}
						style={{
							color: '#343A40',
							fontSize: '14px',
							marginBottom: 20,
							marginLeft: 30,
							marginRight: 30,
						}}
					>
						Kami telah mengirimkan email reset kata sandi ke email Anda. <br />
						Silakan pilih tautan pada email tersebut untuk mengatur kata sandi Anda.
					</Typography>
					<Typography
						variant='body2'
						text-align='left'
						className={classes_loginStyle.textLeft}
						style={{
							color: '#343A40',
							fontSize: '14px',
							fontWeight: 600,
							marginBottom: 10,
							marginLeft: 30,
							marginRight: 30,
						}}
					>
						Belum Menerima Email?
					</Typography>
					<Typography
						variant='body2'
						text-align='left'
						className={classes_loginStyle.textLeft}
						style={{
							color: '#343A40',
							fontSize: '14px',
							marginBottom: 30,
							marginLeft: 30,
							marginRight: 30,
						}}
					>
						Silakan cek folder spam Anda. Jika Anda belum menerima email, <br />
						silakan ulangi proses reset kata sandi dengan memilih tombol di bawah ini:
					</Typography>
					<Button
						size='large'
						type='submit'
						variant='contained'
						color='secondary'
						className={classes_loginStyle.formButton}
						onClick={() => startInterval()}
					>
						Ulangi Reset Kata Sandi
					</Button>
				</Grid>
			) : forgotPasswordReducer.data &&
			  forgotPasswordReducer.isError &&
			  forgotPasswordReducer.error &&
			  errForgotPass.error.error_type == 'request_limit' ? (
				<Grid style={{ marginTop: 25, marginBottom: 30 }}>
					<TautanEmailGagal />
					<Typography
						className={classes_loginStyle.titleTautanEmail}
						text-align='center'
						variant='h6'
						style={{
							marginBottom: 20,
							marginLeft: 30,
							marginRight: 30,
							marginTop: 10,
						}}
					>
						{/* {errForgotPass.error.message} */}
						Pengiriman Tautan Telah Mencapai Batas
					</Typography>
					<Typography
						variant='body2'
						style={{
							color: '#343A40',
							fontSize: 14,
							marginBottom: 15,
							marginLeft: 50,
							marginRight: 50,
							marginTop: 10,
						}}
						className={classes_loginStyle.textLeft}
					>
						<Box textAlign='justify' m={1}>
							Pengiriman tautan reset kata sandi hanya dapat dilakukan sebanyak{' '}
							<b>5 (lima) </b> kali dalam sehari. Silakan cek kembali kotak masuk pada
							email Anda yang digunakan untuk akun Mbiz.co.id
						</Box>
					</Typography>
					<Button
						size='large'
						type='submit'
						variant='contained'
						color='secondary'
						className={classes_loginStyle.formButton}
						onClick={() => handleBeranda()}
					>
						Kembali ke Beranda
					</Button>
				</Grid>
			) : (
				<>
					<ForgotPasswordForm
						classes={classes_loginStyle}
						seconds={seconds}
						isShowTime={showTime}
					/>
				</>
			)}
		</LoginLayout>
	)
}

ForgotPassword.propTypes = {
	navigate: PropTypes.func,
}

export default ForgotPassword
