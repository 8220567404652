import { makeStyles } from '@material-ui/styles'

export const styles = makeStyles(() => ({
	container: {
		'& .rdt_TableBody': {
			maxHeight: 500,
			overflow: 'auto',
		},
	},
}))
export const tableStyle = {
	table: {
		style: {
			backgroundColor: 'transparent',
		},
	},
	head: {
		style: {
			minHeight: '43px',
			fontSize: '14px',
			backgroundColor: 'transparent',
		},
	},
	rows: {
		style: {
			fontSize: '13px',
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'none',
				borderBottomWidth: 'none',
			},
		},
	},
	headRow: {
		style: {
			minHeight: '56px',
			borderBottomWidth: 'none',
			borderBottomStyle: 'none',
			backgroundColor: 'transparent',
		},
	},
}
