import { makeStyles } from '@material-ui/core/styles'

export const searchStyle = makeStyles((theme) => ({
	gridContainer: {
		marginTop: theme.spacing(2),
	},
	gridList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(6, 1fr)',
		gridGap: 15,
		marginTop: 35,
		marginBottom: 15,
	},
	gridSearch: {
		width: 200,
		border: '1px solid #bbb',
		borderTopLeftRadius: 5,
		borderBottomLeftRadius: 5,
		borderRight: 0,
		color: 'transparent',
	},
	buttonSearch: {
		paddingLeft: -10,
		height: 34,
		width: 50,
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: 5,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	listContainer: {
		backgroundColor: 'transparent',
		flex: 1,
		marginTop: '10px',
		width: '100%',
		heigth: '100%',
	},
	listSearch: {
		marginTop: 20,
	},
	typoPage: {
		paddingTop: theme.spacing(0.5),
		marginRight: theme.spacing(2),
	},
	listPagination: {
		marginBottom: 15,
		marginRight: 15,
	},
	fontSizeh1: {
		fontSize: '22px',
	},
}))
