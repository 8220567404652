import React from 'react'
import {
	Card,
	CardContent,
	CardMedia,
	Box,
	Typography,
	Grid,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Styles from './styles'
import image_not_found from '../../assets/images/content/image_not_found.png'
import { Link } from 'gatsby'

const CardListing = ({ media, title, price, alert, alertText, href }) => {
	const classes = Styles()
	const addDefaultSrc = (ev) => {
		ev.target.src = image_not_found
	}
	return (
		<Card style={{ cursor: 'pointer' }}>
			<Link to={href} style={{ textDecoration: 'none', color: 'inherit' }}>
				<Box className={classes.cardImageContainer}>
					<CardMedia
						image={media}
						onError={(e) => addDefaultSrc(e)}
						component='img'
					/>
				</Box>
				<CardContent className={classes.cardContent}>
					<Box className={classes.cardTitleWrapper}>
						<Typography variant='body1' align='center' className={classes.cardTitle}>
							<strong id='product-name' className={classes.ellipsisText}>
								{title}
							</strong>
						</Typography>
					</Box>
					<Typography align='center' variant='body2' className={classes.titlePrice}>
						Harga Mulai Dari
					</Typography>
					<Box height={38}>
						{typeof price == 'string' ? (
							<Typography
								variant='body1'
								align='center'
								className={`${classes.priceRange} ${classes.cardTitle}`}
							>
								<strong id='product-price'>{price}</strong>
							</Typography>
						) : (
							<Grid style={{ textAlign: 'center' }}>{price}</Grid>
						)}
					</Box>

					{alert && (
						<Box className={classes.variantAlert}>
							<Typography variant='caption'>{alertText}</Typography>
						</Box>
					)}
				</CardContent>
			</Link>
		</Card>
	)
}

CardListing.propTypes = {
	media: PropTypes.string.isRequired,
	title: PropTypes.string,
	alert: PropTypes.bool,
	alertText: PropTypes.string,
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClick: PropTypes.func,
	href: PropTypes.string,
}

CardListing.defaultProps = {
	media: '',
	alert: 'false',
	title: 'this is title',
}

export default CardListing
