import { makeStyles } from '@material-ui/core/styles'

export const PurchaseRequestDetailStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dashboard,
	},
	buttonSearch: {
		padding: '4.5px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: 0,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	gridSearch: {
		width: '85%',
	},
	buttonExport: {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
	},
	buttonAction: {
		margin: '0 5px',
	},
	buttonWidth: {
		minWidth: 120,
	},
	kembali: {
		marginRight: theme.spacing(2),
	},
	btnWhite: {
		backgroundColor: '#fff',
	},
	btnEdit: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnCancel: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	formLabel: {
		marginBottom: 5,
	},
	containerRoot: {
		padding: theme.spacing(3, 12),
	},
	datepicker: {
		width: 227,
	},
	tableRoot: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},
	totalPrice: {
		padding: theme.spacing(1, 3),
		justifyContent: 'space-between',
		display: 'flex',
		flex: '1',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	currency: {
		position: 'relative',
		'&:before': {
			content: '"Rp."',
			position: 'absolute',
			right: 130,
		},
	},
	stepLabel: {
		backgroundColor: theme.palette.primary.main,
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		right: 12,
	},

	colorSecondary: {
		color: '#BBBBBB',
	},
	spacing: {
		paddingTop: 20,
	},
	tooltip: {
		maxWidth: 275,
		fontSize: 12,
	},
	buttonFile: {
		width: 140,
		height: 33,
		marginTop: 10,
		marginBottom: 5,
		border: '1px solid #007BFF',
		borderRadius: '3px',
	},
	buttonDialog: {
		marginBottom: 20,
	},
	labelRequired: {
		position: 'relative',
		'&:after': {
			content: '"*"',
			position: 'absolute',
			color: theme.palette.error.dark,
		},
	},
	imageUpload: {
		width: '14px',
		marginRight: 5,
	},
	iconWarning: {
		width: '16px',
		height: '14px',
		marginTop: 2,
		marginRight: 3,
	},
	buttonDelete: {
		color: '#6C757E',
		backgroundColor: 'transparent',
		opacity: 0.6,
		'&:hover': {
			color: '#DC3545',
			opacity: 1,
		},
		marginTop: 5,
	},
	rootCheckBox: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		paddingRight: 5,
		paddingLeft: 0,
		paddingBottom: 0,
		paddingTop: 0,
		margin: 0,
	},
	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow:
			'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow:
			'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				// eslint-disable-next-line prettier/prettier
				'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
				// eslint-disable-next-line prettier/prettier
				' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
				// eslint-disable-next-line prettier/prettier
				'1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'black\'/%3E%3C/svg%3E")',
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	arrow: {
		color: theme.palette.common.black,
	},
	tooltipContainer: {
		maxWidth: 272,
		backgroundColor: theme.palette.common.black,
		boxShadow: theme.shadows[2],
		minHeight: 48,
		maxHeight: 300,
		opacity: 1,
		padding: '12px',
		margin: '2px 0',
		marginRight: '-30px',
	},
	styleIconbutton: {
		color: 'black',
		padding: '1px',
		marginLeft: '2px',
		marginTop: '-2px',
	},
	smallICon: {
		width: 16,
		height: 16,
	},
	textLocation: {
		color: theme.palette.grey[600],
		marginTop: '4px',
	},
}))
