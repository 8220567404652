import React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { checkRole } from '../../utils/acl'

import { withStyles } from '@material-ui/styles'

const GlobalCss = withStyles({
	'@global': {
		a: {
			textDecoration: 'none !important',
			color: '#007BFF',
		},
	},
})(() => null)

const PrivateRoute = ({ roles, Component, ...props }) => {
	const isLoggedIn = useSelector((state) => state.auth.loggedIn)
	if (!isLoggedIn) {
		navigate('/login')
		return null
	}
	if (typeof roles === 'undefined') {
		navigate('/401')
		return null
	}

	checkRole(roles, true)

	return (
		<>
			<GlobalCss />
			<Component {...props} />
		</>
	)
}

PrivateRoute.propTypes = {
	roles: PropTypes.any,
	Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

export default PrivateRoute
