import { INVOICE_DETAIL } from '../../../types'
import API from '../../../../utils/api'
import { success, failure, loading } from '../../../commonActions'
import { navigate } from 'gatsby'

export const requestDetailInvoiceAPI = (invoiceId) => {
	return API({
		url: `/invoices/details/${invoiceId}`,
	})
}

export const requestDetailInvoice = (invoiceId) => {
	return async (dispatch) => {
		dispatch(loading(INVOICE_DETAIL))
		try {
			const response = await requestDetailInvoiceAPI(invoiceId)
			if (
				response.data &&
				response.data.data &&
				response.data.data.state === 'draft'
			) {
				navigate('/404')
			}
			if (response.data && response.data.error_backend) {
				dispatch(failure(INVOICE_DETAIL))
			} else {
				dispatch(success(INVOICE_DETAIL, response.data))
			}
		} catch (err) {
			dispatch(failure(INVOICE_DETAIL))
		}
	}
}

export const resetAction = () => {
	return {
		type: `${INVOICE_DETAIL}_RESET`,
	}
}
