import React, { useEffect } from 'react'

import { Formik, Form, Field } from 'formik'
import {
	Typography,
	TextField,
	Button,
	FormControl,
	InputAdornment,
	IconButton,
	OutlinedInput,
	Grid,
	Collapse,
	Link,
	CircularProgress,
} from '@material-ui/core'
import {
	Visibility,
	VisibilityOff,
	// HighlightOffOutlined,
} from '@material-ui/icons'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import schema from './schema'
import Alert from '../../alert'
import { navigate } from '@reach/router'
import { loginAction, resetAction } from 'States/actions/authAction'

const handleNavigate = (path) => {
	navigate(path)
}

const LoginForm = ({ classes }) => {
	const dispatch = useDispatch()
	const [showPassword, setShowPassword] = React.useState(false)
	const [isError, setIsError] = React.useState(false)
	const data = useSelector((state) => state.auth, shallowEqual)
	const [errMessage, setErrMessage] = React.useState(false)
	// const [open, setOpen] = React.useState(false)

	const callback = React.useCallback(() => {
		setShowPassword(!showPassword)
	}, [showPassword])

	const handleSubmitLogin = (val) => {
		const myState = {
			user: val.username,
			pass: val.password,
			redirect: data.redirect,
		}
		localStorage.setItem('lastQueryReportTrans', JSON.stringify(''))
		localStorage.setItem('lastQueryReportCompany', JSON.stringify(''))
		dispatch(loginAction({ ...myState }))
	}

	const resetErrorMessage = () => {
		setIsError(false)
		return ''
	}

	useEffect(() => {
		setIsError(data.isError)
	}, [data])

	useEffect(() => {
		setErrMessage(data.err)
	}, [data])

	useEffect(() => {
		dispatch(resetAction())
	}, [dispatch])

	return (
		<Formik
			enableReinitialize
			initialValues={{ username: '', password: '' }}
			validationSchema={schema}
			onSubmit={(values) => handleSubmitLogin(values)}
		>
			{({ errors, handleSubmit, touched }) => {
				return (
					<Form
						onSubmit={handleSubmit}
						method='post'
						className={[classes.form, classes.p_4]}
					>
						<Typography
							className={[classes.title, classes.mb_3].join(' ')}
							text-align='center'
							variant='h3'
						>
							Masuk ke akun Anda
						</Typography>
						<Typography className={[classes.subtitle, classes.textLeft].join(' ')}>
							Selamat datang di{' '}
							{/* <Link className={classes.uri} onClick={() => handleNavigate('/')}>
								Mbiz.co.id
							</Link> */}
							<a className={classes.uri} href='/'>
								Mbiz.co.id
							</a>
							<br />
							Silakan tulis username dan kata sandi Anda dibawah ini untuk masuk.
						</Typography>
						<Collapse
							in={
								(errors.username && touched.username) ||
								(errors.password && touched.password) ||
								isError
							}
						>
							<Alert variant={'alert'}>
								<Typography variant='body1' className={classes.alertText}>
									{(errors.username && touched.username && errors.username) ||
										(errors.password && touched.password && errors.password) ||
										(isError && errMessage)}
									{(errors.username && touched.username) ||
									(errors.password && touched.password)
										? resetErrorMessage()
										: ''}
								</Typography>
							</Alert>
						</Collapse>
						<Field name='username'>
							{({
								field, // { name, value, onChange, onBlur }
								// form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
							}) => (
								<TextField
									variant='outlined'
									fullWidth
									size='small'
									margin='dense'
									placeholder='Username'
									type='input'
									{...field}
								/>
							)}
						</Field>

						<Field name='password'>
							{({ field }) => (
								<FormControl className={classes.password} variant='outlined'>
									<OutlinedInput
										id='outlined-adornment-password'
										type={showPassword ? 'text' : 'password'}
										placeholder='Kata Sandi'
										{...field}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													edge='end'
													onClick={() => callback()}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							)}
						</Field>

						<Grid container>
							<Grid item xs={12} sm={12} className={classes.mb_3}>
								<Typography className={classes.forgotpass}>
									<Link
										className={classes.uri}
										onClick={() => handleNavigate('/forgot-password')}
									>
										Lupa Kata Sandi?
									</Link>
								</Typography>
							</Grid>
						</Grid>

						<Button
							fullWidth
							size='large'
							type='submit'
							variant='contained'
							color='secondary'
							className={classes.formButton}
							disabled={data.isLoading}
						>
							{data.isLoading ? <CircularProgress /> : 'Masuk'}
						</Button>

						<hr className={classes.hr_line}></hr>
						<Typography className={classes.textLeft}>
							<small>
								Belum mempunyai akun?
								<br />
								<Link
									className={classes.uri}
									onClick={() => handleNavigate('register')}
								>
									Klik disini
								</Link>{' '}
								untuk informasi pendaftaran
							</small>
						</Typography>
					</Form>
				)
			}}
		</Formik>
	)
}

LoginForm.propTypes = {
	classes: PropTypes.object,
}

export default LoginForm
