import React, { useEffect, useState } from 'react'
import { Publish } from '@material-ui/icons'
import styles from './styles'

const Component = () => {
	const classes = styles()
	const [visible, setVisibility] = useState(false)
	const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.pageYOffset > 300) {
				setVisibility(true)
			} else {
				setVisibility(false)
			}
		})
	}, [])

	return visible ? (
		<div className={classes.scrollToTop} onClick={scrollToTop}>
			<Publish style={{ fontSize: '30px' }} />
		</div>
	) : (
		false
	)
}

export default Component
