import { BUDGET_DETAIL } from '../../types'
import { success, failure, loading } from '../../commonActions'
import API from '../../../utils/api'
import { navigate } from 'gatsby'

export const resetAction = () => {
	return {
		type: `${BUDGET_DETAIL}_RESET`,
	}
}

export const requestBudgetDetail = (budgetID) => {
	return async (dispatch) => {
		dispatch(loading(BUDGET_DETAIL))
		try {
			const response = await API({
				method: 'GET',
				url: `/budgets/details/${budgetID}`,
			})
			if (response.data) {
				if ('error_backend' in response.data) {
					navigate(`/${response.data.error_backend.http_status_code}`)
				} else {
					dispatch(success(BUDGET_DETAIL, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(BUDGET_DETAIL, err))
		}
	}
}
