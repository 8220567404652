import { RESET_PASSWORD, RESET_PASSWORD_USER_DETAIL } from '../../types'
import API from '../../../utils/api'
import { success, failure, loading } from '../../commonActions'
import { navigate } from 'gatsby'

export const getUserDetailAction = (catalog_id) => {
	return async (dispatch) => {
		dispatch(loading(RESET_PASSWORD_USER_DETAIL))
		try {
			const response = await API({
				method: 'GET',
				url: `users/temp/detail/${catalog_id}`,
			})
			if (response) {
				if (response.data.error_backend) {
					dispatch(failure(RESET_PASSWORD_USER_DETAIL, response.data.error_backend))
				} else {
					dispatch(success(RESET_PASSWORD_USER_DETAIL, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(RESET_PASSWORD_USER_DETAIL, err))
		}
	}
}

export const checkToken = ({ data, auth }) => {
	let response = {
		success: true,
		error: false,
		loading: true,
	}
	return new Promise((resolve, reject) => {
		API({
			method: 'POST',
			url: `/users/token/${data.id}`,
			data: {
				token_reset_password: data.token_reset_password,
			},
		})
			.then((res) => {
				if ('error_backend' in res.data) {
					response = {
						success: false,
						error: true,
						loading: false,
					}
					let http_body = JSON.parse(res.data.error_backend.http_body)
					let errorData = http_body.error.message
					errorData = errorData.toLowerCase()
					if (
						errorData.includes('token expired') ||
						errorData.includes('expired token')
					) {
						navigate('/reset-password-link-expired')
					} else if (
						errorData.includes('token invalid') ||
						errorData.includes('invalid token')
					) {
						navigate('/link-not-available')
					} else if (errorData.includes('already used')) {
						if (auth.loggedIn) {
							navigate('/dashboard')
						} else {
							navigate('/login')
						}
					} else {
						navigate('/link-not-found')
					}
					resolve(response)
				} else {
					response = {
						success: true,
						error: false,
						loading: false,
					}
					resolve(response)
				}
			})
			.catch(() => {
				reject('error')
				navigate('/404')
			})
	})
}

export const resetPasswordAction = ({
	id,
	name,
	username,
	email,
	password,
	confirm_password,
	token_reset_password,
}) => {
	return async (dispatch) => {
		dispatch(loading(RESET_PASSWORD))
		try {
			const response = await dispatch({
				type: RESET_PASSWORD,
				timeout: null,
				payload: API({
					method: 'PUT',
					url: `/users/update/${id}`,
					data: {
						name,
						username,
						email,
						password,
						confirm_password,
						token_reset_password,
					},
				}),
			})
			if (response) {
				if (response.value.data.error_backend) {
					let error = {}
					error.err = response.value.data.error_backend
					dispatch(failure(RESET_PASSWORD, error))
				} else {
					dispatch(success(RESET_PASSWORD, response.value.data))
				}
			}
		} catch (err) {
			dispatch({
				type: `${RESET_PASSWORD}_REJECTED`,
				err,
			})
		}
	}
}
