import * as Yup from 'yup'

const schema = Yup.object().shape({
	newPassword: Yup.string()
		.required('Kata sandi baru dan Konfirmasi kata sandi baru harus diisi')
		.min(6, 'Kata Sandi yang baru minimal harus 6 karakter'),
	newConfPassword: Yup.string()
		.required('Kata sandi baru dan Konfirmasi kata sandi baru harus diisi')
		.when('newPassword', {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
				[Yup.ref('newPassword')],
				'Kata sandi baru dan Konfirmasi kata sandi baru tidak sesuai'
			),
		}),
})

export default schema
