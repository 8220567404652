import * as Yup from 'yup'

var schema = Yup.object().shape({
	user: Yup.object().required('Nama lengkap tidak boleh kosong'),
	level: Yup.string()
		.test('level', 'Level wajib dipilih', function (s) {
			return s != 'none'
		})
		.required('Level wajib dipilih'),
})

export default schema
