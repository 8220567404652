import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Grid,
	Typography,
	Paper,
	Divider,
	Button,
	TextField,
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormControl,
	Switch,
	Collapse,
	CircularProgress,
	Container,
	Tooltip,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { EdituseraccountStyle } from './styles'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import schema from '../schema'
import {
	requestUserAccountDetail,
	resetAction,
} from 'States/actions/UsersAccount/detailAction'
import { requestUserAccountUpdate } from 'States/actions/UsersAccount/updateAction'
import { requestDepartmentList } from 'States/actions/Company/Department/listAction'
import Alert from 'Components/alert'
import { HighlightOffOutlined } from '@material-ui/icons'
import { toRoleId, checkUser } from '../../../utils/globalUtils'
import { navigate } from 'gatsby'
import CustomModal from 'Components/modal'
import Breadcrumbs from 'Components/breadcrumbs'
import { DepartmentDialog, RolesDialog } from 'Components/dialogs'
import Label from 'Components/label'
import PropTypes from 'prop-types'

const breadcrumbs = [
	{
		segment: 'Home',
		link: '/dashboard',
	},
	{
		segment: 'Perusahaan',
		link: '/dashboard',
	},
	{
		segment: 'Daftar Akun Pengguna',
		link: '/dashboard/user-account',
	},
	{
		segment: 'Ubah Akun Pengguna',
		link: '#',
	},
]

const initDataAccount = {
	name: '',
	username: '',
	email: '',
	mobile: '',
	jobTitle: '',
	status: '',
	userBuyerRoles: [],
}

const edituserAccount = ({ userID }) => {
	// breadcumbs

	const classes = EdituseraccountStyle()
	const [open, setOpen] = useState(false)
	const [open2, setOpen2] = useState(false)

	const [scroll, setScroll] = useState('paper')
	const [confirmModalSubmit, setConfirmModalSubmit] = useState(false)
	// const [errorBackend, setErrorBackend] = useState({})
	const [modalNotif, setModalNotif] = useState(false)
	// const [notifError, setNotifError] = useState(false)
	const [notifSuccess, setNotifSuccess] = useState(false)
	const [listDepartment, setListDepartment] = useState([])
	const [isSelfAdmin, setIsSelfAdmin] = useState(false)
	const [dataSelected, setDataSelected] = useState({
		department: '',
		departmentSelected: {},
	})
	const [showLabel, setShowLabel] = useState({})
	const [errorList, setErrorList] = useState({
		status: {
			document_pending: false,
			isApprover: false,
		},
		roles: {
			document_pending: false,
			isApprover: false,
		},
	})

	const dispatch = useDispatch()
	const responseData = useSelector((state) => state.UsersAccountUpdateReducer)
	const detailData = useSelector((state) => state.UsersAccountDetailReducer)
	const auth = useSelector((state) => state.auth.data)
	const [dataAccount, setDataAccount] = useState(initDataAccount)

	const listDepartmentReducer = useSelector(
		(state) => state.departmentListReducer
	)

	const handleClickOpen = (scrollType) => () => {
		setOpen(true)
		setScroll(scrollType)
	}

	const handleClickOpen2 = (scrollType) => () => {
		setOpen2(true)
		setScroll(scrollType)
	}

	useEffect(() => {
		dispatch(requestUserAccountUpdate({}, userID, 'RESET'))
	}, [])

	useEffect(() => {
		if (detailData.isLoading == false && detailData.data != null) {
			let dataTemp = dataAccount
			dataTemp.name = detailData.data.name
			dataTemp.username = detailData.data.username
			dataTemp.email = detailData.data.email
			dataTemp.mobile = detailData.data.mobile
			dataTemp.jobTitle = detailData.data.job_title
			dataTemp.status = (detailData.data && detailData.data.status == 99) || false
			dataTemp.userBuyerRoles = detailData.data.user_buyer_roles
			setDataAccount(dataTemp)
		}
	}, [detailData.isLoading, detailData.data])

	// get error_backend and parse
	useEffect(() => {
		// var tmpErrorBackend
		// if (responseData.error) {
		// if (responseData.error.http_body) {
		// let http_body = responseData.error.http_body
		// if (http_body) {
		// let body_obj = JSON.parse(http_body)
		// tmpErrorBackend = body_obj
		// setErrorBackend(body_obj)
		// }
		// }
		// }

		// set notif error
		// if (
		// 	(tmpErrorBackend &&
		// 		tmpErrorBackend.error &&
		// 		containsString(tmpErrorBackend.error.message, ['Error', 'error']) &&
		// 		tmpErrorBackend.error.message) ||
		// 	responseData.isError
		// ) {
		// 	setNotifError(true)
		// }

		// set notif success
		if (
			!responseData.isError &&
			responseData.data &&
			responseData.data.message != ''
		) {
			setNotifSuccess(true)
		}

		// show modal
		if (responseData.data.message != '') {
			setModalNotif(true)
		}
	}, [responseData])

	useEffect(() => {
		setDataAccount({ ...initDataAccount })
		dispatch(requestUserAccountDetail(userID))
		dispatch(
			requestDepartmentList({
				queryString: { limit: 200, filter: '{"status":"99"}' },
			})
		)
		return () => {
			dispatch(resetAction())
		}
	}, [])

	// list department filter
	useEffect(() => {
		if (listDepartmentReducer.data) {
			let result = listDepartmentReducer.data.items
			if (result) {
				setListDepartment(result)
			}
		}
	}, [listDepartmentReducer])

	useEffect(() => {
		if (detailData.isLoading == false && detailData.data) {
			if (userID == auth.data.id) setIsSelfAdmin(true)
		}
	}, [userID, auth.data, detailData])

	useEffect(() => {
		if (detailData.data && listDepartment.length > 0) {
			let filterRole, departmentSelected

			let userRoles = dataAccount.userBuyerRoles
			filterRole = userRoles.filter(function (role) {
				return role.role_id == 9 // role staff
			})[0]

			if (filterRole) {
				departmentSelected = listDepartment.filter(function (item) {
					return item.id == filterRole.department_id
				})[0]
			}

			if (departmentSelected) {
				setDataSelected({
					...dataSelected,
					department: departmentSelected && departmentSelected.name,
					departmentSelected: departmentSelected,
				})
			} else {
				setDataSelected({
					...dataSelected,
					department: '',
					departmentSelected: {},
				})
			}
		}
	}, [detailData, listDepartment])

	// eslint-disable-next-line prettier/prettier
	const handleValidateForm = async (validateForm, option, values, initialValues) => {
		const error = await validateForm()
		const currentErrorKeys = Object.keys(error)
		if (currentErrorKeys.length) {
			currentErrorKeys.map((item) => {
				option.setFieldError(item)
				option.setFieldTouched(item)
			})
			// eslint-disable-next-line prettier/prettier
		} else if ((!values.roles_aggreement && initialValues.roles_aggreement) || (!values.roles_staff && initialValues.roles_staff)) {
			checkUser(userID)
				.then((res) => {
					const { can_action, purchase_requests, transaction, message } = res.data
					// eslint-disable-next-line prettier/prettier
					if (!can_action && message.includes('this user is still an active approver') && !values.roles_aggreement) {
						setShowLabel({ ...showLabel, ['roles']: true })
						setErrorList({
							...errorList,
							['roles']: {
								isApprover: true,
							},
						})
					}

					// eslint-disable-next-line prettier/prettier
					if (!can_action && transaction && !values.roles_staff) {
						setShowLabel({ ...showLabel, ['roles']: true })
						setErrorList({
							...errorList,
							['roles']: {
								document_pending: true,
							},
						})
					}

					// eslint-disable-next-line prettier/prettier
					if(!can_action && (!values.roles_aggreement && !values.roles_staff) && (transaction || purchase_requests.length)) {
						setShowLabel({ ...showLabel, ['roles']: true })
						setErrorList({
							...errorList,
							['roles']: {
								isApprover: true,
								document_pending: true,
							},
						})
					}

					// eslint-disable-next-line prettier/prettier
					if(!can_action && !values.roles_aggreement  && purchase_requests.length) {
						setShowLabel({ ...showLabel, ['roles']: true })
						setErrorList({
							...errorList,
							['roles']: {
								isApprover: true,
								document_pending: true,
							},
						})
					}

					// eslint-disable-next-line prettier/prettier
					if(!can_action && (!values.roles_aggreement && !values.roles_staff) && !purchase_requests.length && transaction) {
						setShowLabel({ ...showLabel, ['roles']: true })
						setErrorList({
							...errorList,
							['roles']: {
								document_pending: true,
							},
						})
					}

					// eslint-disable-next-line prettier/prettier
					if (!can_action && !message && !values.roles_staff && values.roles_aggreement && !transaction) {
						setShowLabel({ ...showLabel, ['roles']: false })
						setConfirmModalSubmit(true)
					}
					// eslint-disable-next-line prettier/prettier
					if (!can_action && values.roles_staff && !values.roles_aggreement && transaction && !purchase_requests.length && !message.includes('this user is still an active approver')) {
						setShowLabel({ ...showLabel, ['roles']: false })
						setConfirmModalSubmit(true)
					}
					// eslint-disable-next-line prettier/prettier
					if (!can_action && !values.roles_staff && values.roles_aggreement && !transaction && purchase_requests.length) {
						setShowLabel({ ...showLabel, ['roles']: false })
						setConfirmModalSubmit(true)
					}

					// eslint-disable-next-line prettier/prettier
					if (!can_action && !values.roles_staff && values.roles_aggreement && !transaction && !purchase_requests.length ) {
						setShowLabel({ ...showLabel, ['roles']: false })
						setConfirmModalSubmit(true)
					}

					if (can_action) {
						setShowLabel({ ...showLabel, ['roles']: false })
						setConfirmModalSubmit(true)
					}
				})
				.catch()
		} else {
			setShowLabel({ ...showLabel, ['roles']: false })
			setConfirmModalSubmit(true)
		}
	}

	const handleSubmit = (formdata) => {
		let formdataStatus = formdata.status
		var listRolesName = ['admin', 'aggreement', 'finance', 'staff']
		let userRoles = []

		// set status
		if (formdataStatus) {
			formdata.status = 99
		} else {
			formdata.status = 98
		}

		// set roles
		for (let i = 0; i < listRolesName.length; i++) {
			let roleName = listRolesName[i]
			if (formdata['roles_' + roleName]) {
				if (roleName == 'staff') {
					userRoles.push({
						role_id: toRoleId(roleName),
						department_id: formdata.department.id || formdata.departmentSelected.id,
					})
				} else {
					userRoles.push({ role_id: toRoleId(roleName) })
				}
			}
		}

		// set user_departments
		if (formdata['roles_staff']) {
			formdata.user_departments = [
				formdata.department.id || formdata.departmentSelected.id,
			]
		}

		formdata.username = dataAccount.username || formdata.username

		formdata.user_roles = userRoles
		dispatch(requestUserAccountUpdate(formdata, userID))
		setConfirmModalSubmit(false)
	}

	const handleModalOK = () => {
		setModalNotif(false)
		if (notifSuccess) {
			dispatch(requestUserAccountUpdate({}, userID, 'RESET'))
			navigate('/dashboard/user-account')
		}
	}

	const checkedRoles = (role_id) => {
		if (dataAccount.userBuyerRoles) {
			let userRoles = dataAccount.userBuyerRoles
			let filterRole = userRoles.filter(function (role) {
				return role.role_id == role_id
			})[0]

			if (filterRole) {
				return true
			}
		}

		return false
	}

	const handleInputChangeDepartment = (v) => {
		if (!v || v == null) {
			v = ''
		}
		if (v.length > 2) {
			let queryString = {
				limit: 100,
				status: 99,
				query: JSON.stringify({ name: v }),
				filter: '{"status":"99"}',
			}
			dispatch(requestDepartmentList({ queryString: queryString }))
		}
	}

	// handle check user
	const handleCheckUser = (prop) => {
		prop.setFieldValue(prop.name, !prop.status)
		if (prop.status == true) {
			checkUser(userID)
				.then((res) => {
					const { can_action, purchase_requests, transaction, message } = res.data
					// eslint-disable-next-line prettier/prettier
					if (!can_action && message.includes('this user is still an active approver') && !transaction) {
						setShowLabel({ ...showLabel, [prop.section]: true })
						setErrorList({
							...errorList,
							[prop.section]: {
								isApprover: true,
							},
						})
					}
					if (!can_action && transaction) {
						setShowLabel({ ...showLabel, [prop.section]: true })
						setErrorList({
							...errorList,
							[prop.section]: {
								document_pending: true,
							},
						})
					}
					if (!can_action && purchase_requests.length) {
						setShowLabel({ ...showLabel, [prop.section]: true })
						setErrorList({
							...errorList,
							[prop.section]: {
								isApprover: true,
								document_pending: true,
							},
						})
					}
					// eslint-disable-next-line prettier/prettier
					if (!can_action && message.includes('this user is still an active approver') && purchase_requests.length && !transaction) {
						setShowLabel({ ...showLabel, [prop.section]: true })
						setErrorList({
							...errorList,
							[prop.section]: {
								document_pending: true,
								isApprover: true,
							},
						})
					}

					// eslint-disable-next-line prettier/prettier
					if (!can_action && message.includes('this user is still an active approver') && !purchase_requests.length && transaction) {
						setShowLabel({ ...showLabel, [prop.section]: true })
						setErrorList({
							...errorList,
							[prop.section]: {
								document_pending: true,
								isApprover: true,
							},
						})
					}

					// eslint-disable-next-line prettier/prettier
					if (!can_action && message.includes('this user is still an active approver') && purchase_requests.length && transaction) {
						setShowLabel({ ...showLabel, [prop.section]: true })
						setErrorList({
							...errorList,
							[prop.section]: {
								document_pending: true,
								isApprover: true,
							},
						})
					}
				})
				.catch((err) => console.log(err))
		}
		setShowLabel({ ...showLabel, [prop.section]: false })
	}

	return (
		<Formik
			enableReinitialize
			validationSchema={schema}
			onSubmit={(values) => handleSubmit(values)}
			initialValues={{
				name: dataAccount.name,
				username: dataAccount.username,
				email: dataAccount.email,
				job_title: dataAccount.jobTitle,
				mobile: dataAccount.mobile,
				roles_admin: checkedRoles(6),
				roles_aggreement: checkedRoles(7),
				roles_finance: checkedRoles(8),
				roles_staff: checkedRoles(9),
				status: dataAccount.status,
				...dataSelected,
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				submitForm,
				validateForm,
				initialValues,
				...others
			}) => {
				return (
					<>
						<Form onSubmit={handleSubmit} method='post'>
							<DashboardLayout>
								<Container>
									<Box height={70} alignItems='center' display='flex'>
										<Breadcrumbs breadcrumbs={breadcrumbs} />
									</Box>
									<Box p={1}>
										<Typography variant='h6'>Ubah Akun Pengguna</Typography>
										<Paper
											variant='outlined'
											elevation={3}
											className={classes.boxSpacing}
										>
											<Box p={2}>
												<Collapse in={responseData.isError}>
													<Alert
														iconClose={
															<HighlightOffOutlined
																onClick={() =>
																	dispatch(requestUserAccountUpdate({}, userID, 'RESET'))
																}
															/>
														}
														variant={'alert'}
													>
														<Typography variant='body1'>
															{responseData.isError && 'Terjadi kesalahan server'}
														</Typography>
													</Alert>
												</Collapse>
												<Collapse
													in={!responseData.isError && responseData.data.message != ''}
												>
													<Alert
														iconClose={
															<HighlightOffOutlined
																onClick={() =>
																	dispatch(requestUserAccountUpdate({}, userID, 'RESET'))
																}
															/>
														}
														variant={'success'}
													>
														<Typography variant='body1'>
															{!responseData.isError &&
																responseData.data.message != '' &&
																'Success Updated.'}
														</Typography>
													</Alert>
												</Collapse>

												<Typography className={classes.formTitle}>
													Informasi Dasar Pengguna
												</Typography>
												<Divider />
												<Grid
													container
													spacing={3}
													justify='space-between'
													className={classes.formField}
												>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2' className={classes.labelRequired}>
															Nama Lengkap
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='name'>
															{({ field }) => (
																<TextField
																	name='name'
																	type='input'
																	fullWidth
																	inputProps={{ maxLength: 100 }}
																	variant='outlined'
																	margin='dense'
																	placeholder='Nama Lengkap'
																	defaultValue={dataAccount.name}
																	onChange={handleChange}
																	error={errors.name && touched.name}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='name' />
														</span>
													</Grid>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2' className={classes.labelRequired}>
															Username
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='username'>
															{({ field }) => (
																<TextField
																	name='username'
																	type='input'
																	fullWidth
																	variant='outlined'
																	margin='dense'
																	placeholder='Username'
																	disabled={true}
																	defaultValue={dataAccount.username}
																	onChange={handleChange}
																	error={errors.username && touched.username}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='username' />
														</span>
													</Grid>
												</Grid>
												<Grid
													container
													spacing={3}
													justify='space-between'
													className={classes.formField}
												>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2' className={classes.labelRequired}>
															Email
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='email'>
															{({ field }) => (
																<TextField
																	name='email'
																	type='input'
																	fullWidth
																	variant='outlined'
																	margin='dense'
																	placeholder='Email'
																	defaultValue={dataAccount.email}
																	onChange={handleChange}
																	error={errors.email && touched.email}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='email' />
														</span>
													</Grid>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2' className={classes.labelRequired}>
															No.Ponsel
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='mobile'>
															{({ field }) => (
																<TextField
																	name='mobile'
																	type='input'
																	fullWidth
																	variant='outlined'
																	margin='dense'
																	placeholder='No. Ponsel'
																	defaultValue={dataAccount.mobile}
																	onChange={handleChange}
																	error={errors.mobile && touched.mobile}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='mobile' />
														</span>
													</Grid>
												</Grid>
											</Box>
										</Paper>
										<Paper
											variant='outlined'
											elevation={3}
											className={classes.boxSpacing}
										>
											<Box p={2}>
												<Typography className={classes.formTitle}>
													Informasi Departemen Pengguna
												</Typography>
												<Divider />
												<Grid
													container
													spacing={3}
													justify='space-between'
													className={classes.formField}
												>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2'>Jabatan</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='job_title'>
															{({ field }) => (
																<TextField
																	id='job_title'
																	fullWidth
																	variant='outlined'
																	margin='dense'
																	placeholder='Jabatan'
																	defaultValue={dataAccount.jobTitle}
																	onChange={handleChange}
																	error={errors.job_title && touched.job_title}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='job_title' />
														</span>
													</Grid>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2' className={classes.labelRequired}>
															Roles
															<Button
																onClick={handleClickOpen('paper')}
																size='small'
																className={classes.customTooltip}
															>
																<HelpIcon className={classes.customIcon} />
															</Button>
															<RolesDialog
																open={open}
																scroll={scroll}
																handleClose={() => setOpen(false)}
															/>
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<FormControl>
															<FormGroup>
																<Field name='roles_admin'>
																	{({ field }) => (
																		<Tooltip
																			title={
																				isSelfAdmin ? (
																					<React.Fragment>
																						<Typography variant='body1'>
																							Anda tidak bisa menghapus role Admin akun Anda sendiri.
																						</Typography>
																					</React.Fragment>
																				) : (
																					''
																				)
																			}
																			arrow
																			placement='top'
																		>
																			<FormControlLabel
																				control={
																					<Checkbox
																						disabled={isSelfAdmin}
																						color='primary'
																						className={classes.bcheckBox}
																						name='roles_admin'
																						onChange={handleChange}
																						checked={values.roles_admin}
																						// value={true}
																						{...field}
																					/>
																				}
																				label='Admin'
																				labelPlacement='end'
																			/>
																		</Tooltip>
																	)}
																</Field>
																<Field name='roles_aggreement'>
																	{({ field }) => (
																		<FormControlLabel
																			control={
																				<Checkbox
																					color='primary'
																					className={classes.bcheckBox}
																					name='roles_aggreement'
																					checked={values.roles_aggreement}
																					onChange={handleChange}
																					{...field}
																				/>
																			}
																			label='Penyetuju'
																			labelPlacement='end'
																		/>
																	)}
																</Field>
																<Field name='roles_finance'>
																	{({ field }) => (
																		<FormControlLabel
																			control={
																				<Checkbox
																					color='primary'
																					className={classes.bcheckBox}
																					name='roles_finance'
																					onChange={handleChange}
																					checked={values.roles_finance}
																					// value={true}
																					{...field}
																				/>
																			}
																			label='Finance'
																			labelPlacement='end'
																		/>
																	)}
																</Field>
																<Field name='roles_staff'>
																	{({ field }) => (
																		<FormControlLabel
																			control={
																				<Checkbox
																					color='primary'
																					className={classes.bcheckBox}
																					name='roles_staff'
																					onChange={handleChange}
																					checked={values.roles_staff}
																					{...field}
																				/>
																			}
																			label='Pemohon'
																			labelPlacement='end'
																		/>
																	)}
																</Field>
															</FormGroup>
															<span style={{ color: 'red' }}>
																<ErrorMessage name='validation_roles' />
															</span>
														</FormControl>

														<span style={{ color: 'red' }}>
															<ErrorMessage name='roles' />
														</span>
													</Grid>
												</Grid>
												<Grid
													container
													spacing={3}
													justify='flex-end'
													className={classes.formField}
												>
													{values.roles_staff && values.roles_staff === true && (
														<>
															<Grid item xs={3} className={classes.formLabel}>
																<Typography
																	variant='subtitle2'
																	className={classes.labelRequired}
																>
																	Departemen Untuk Pembelian
																	<Button
																		onClick={handleClickOpen2('paper')}
																		size='small'
																		className={classes.customTooltip}
																	>
																		<HelpIcon className={classes.customIcon} />
																	</Button>
																	<DepartmentDialog
																		open={open2}
																		handleClose={() => setOpen2(false)}
																	/>
																</Typography>
															</Grid>
															<Grid item xs={3}>
																<Autocomplete
																	id='department'
																	name='department'
																	options={listDepartment}
																	getOptionLabel={(option) => option.name}
																	onChange={(e, v, r) => {
																		if (r == 'select-option') {
																			others.setFieldValue('department', v.name)
																			others.setFieldValue('departmentSelected', v)
																		}
																	}}
																	onInputChange={(e, v, r) => {
																		if (r == 'clear' || r == 'input') {
																			handleInputChangeDepartment(v)
																			others.setFieldValue('department', '')
																		}
																	}}
																	onClose={(e, r) => {
																		if (r != 'select-option') {
																			others.setFieldValue('department', '')
																		}
																	}}
																	style={{
																		width: '100%',
																	}}
																	inputValue={values.department || ''}
																	renderInput={(params) => (
																		<Field name='department'>
																			{({ field }) => (
																				<TextField
																					placeholder='Pilih Department'
																					fullWidth
																					margin='dense'
																					variant='outlined'
																					error={errors.department && touched.department}
																					{...params}
																					{...field}
																				/>
																			)}
																		</Field>
																	)}
																/>
																<span style={{ color: 'red' }}>
																	<ErrorMessage name='validation_department' />
																</span>
															</Grid>
														</>
													)}
												</Grid>
												<Grid container className={classes.formField}>
													<Box mt={2} width={'100%'}>
														<Collapse in={showLabel['roles']}>
															<Label type='error'>
																<Typography variant='body1' component='span'>
																	<ul className={classes.errorList}>
																		{!errorList['roles'].isApprover ? null : (
																			<li>
																				Roles akun tidak dapat diubah karena akun masih terdaftar
																				sebagai Penyetuju di Manajemen Persetujuan.
																			</li>
																		)}
																		{!errorList['roles'].document_pending ? null : (
																			<li>
																				Roles akun tidak dapat diubah karena akun masih terdaftar
																				sebagai Pemohon atau Penyetuju dari dokumen yang butuh
																				diproses. Klik{' '}
																				<a
																					href={`/dashboard/user-account/${userID}/document-onprocess`}
																					target='_blank'
																					rel='noopener noreferrer'
																				>
																					di sini
																				</a>{' '}
																				untuk melihat daftar dokumen.
																			</li>
																		)}
																	</ul>
																</Typography>
															</Label>
														</Collapse>
													</Box>
												</Grid>
											</Box>
										</Paper>
										<Paper
											variant='outlined'
											elevation={3}
											className={classes.boxSpacing}
										>
											<Box p={2}>
												<Grid
													container
													justify='space-between'
													className={classes.formField}
												>
													<Grid item xs={12}>
														{isSelfAdmin ? (
															<Tooltip
																classes={{
																	popper: classes.popper,
																}}
																title={
																	<React.Fragment>
																		<Typography variant='body1'>
																			Anda tidak bisa menonaktifkan akun Anda sendiri.
																		</Typography>
																	</React.Fragment>
																}
																arrow
																placement='top'
															>
																<FormControl>
																	<FormGroup>
																		<Field>
																			{({ field }) => (
																				<FormControlLabel
																					classes={{
																						disabled: classes.formControlDisabled,
																					}}
																					control={
																						<Switch
																							classes={{
																								disabled: classes.disabled,
																							}}
																							disabled={true}
																							name='status'
																							color='secondary'
																							value='99'
																							checked={true}
																							{...field}
																						/>
																					}
																					label='Status'
																					labelPlacement='start'
																				/>
																			)}
																		</Field>
																	</FormGroup>
																</FormControl>
															</Tooltip>
														) : (
															<FormControl>
																<FormGroup
																	onChange={() =>
																		handleCheckUser({
																			setFieldValue: others.setFieldValue,
																			name: 'status',
																			status: values.status,
																			section: 'status',
																		})
																	}
																>
																	<Field name='status'>
																		{({ field }) => (
																			<FormControlLabel
																				classes={{
																					labelPlacementStart: classes.labelPlacementStart,
																				}}
																				control={
																					<Switch
																						name='status'
																						color='secondary'
																						value='99'
																						checked={values.status}
																						{...field}
																					/>
																				}
																				label='Status'
																				labelPlacement='start'
																			/>
																		)}
																	</Field>
																</FormGroup>
															</FormControl>
														)}
													</Grid>
												</Grid>
												<Grid container className={classes.formField}>
													<Box width={'100%'}>
														<Collapse in={showLabel['status']}>
															<Label type='error'>
																<Typography variant='body1' component='span'>
																	<ul className={classes.errorList}>
																		{!errorList['status'].isApprover ? null : (
																			<li>
																				Akun tidak dapat dinonaktifkan karena akun masih terdaftar
																				sebagai Penyetuju di Manajemen Persetujuan.
																			</li>
																		)}
																		{!errorList['status'].document_pending ? null : (
																			<li>
																				Akun tidak dapat dinonaktifkan karena akun masih terdaftar
																				sebagai Pemohon atau Penyetuju dari dokumen yang butuh
																				diproses. Klik{' '}
																				<a
																					href={`/dashboard/user-account/${userID}/document-onprocess`}
																					target='_blank'
																					rel='noopener noreferrer'
																				>
																					di sini
																				</a>{' '}
																				untuk melihat daftar dokumen.
																			</li>
																		)}
																	</ul>
																</Typography>
															</Label>
														</Collapse>
													</Box>
												</Grid>
											</Box>
										</Paper>
										<Grid
											container
											spacing={1}
											justify='flex-end'
											className={classes.boxSpacing}
										>
											<Grid item>
												<Button
													variant='contained'
													color='primary'
													className={classes.buttonNegative}
													onClick={() => navigate('/dashboard/user-account')}
												>
													Batal
												</Button>
											</Grid>
											<Grid item>
												<Button
													id='btn-save'
													variant='contained'
													color='secondary'
													disabled={responseData.isLoading || showLabel['status']}
													// type='submit'
													onClick={() =>
														handleValidateForm(validateForm, others, values, initialValues)
													}
												>
													Simpan{' '}
													{responseData.isLoading && (
														<CircularProgress size={14} style={{ marginLeft: '5px' }} />
													)}
												</Button>
											</Grid>
										</Grid>
									</Box>
								</Container>
							</DashboardLayout>
						</Form>

						<CustomModal
							icon='question'
							open={confirmModalSubmit}
							onClose={() => setConfirmModalSubmit(false)}
						>
							<Typography variant='h6'>
								<strong>Submit Perbarui User ?</strong>
							</Typography>
							<Box
								display='flex'
								flexDirection='row'
								mt={3}
								justifyContent='space-around'
								width={300}
							>
								<Button
									className={classes.buttonWidth}
									size='small'
									classes={{
										contained: classes.buttonDanger,
									}}
									variant='contained'
									onClick={() => setConfirmModalSubmit(false)}
								>
									{' '}
									Batal{' '}
								</Button>
								<Button
									className={classes.buttonWidth}
									size='small'
									color='secondary'
									variant='contained'
									type='submit'
									onClick={() => submitForm()}
								>
									{' '}
									Ya{' '}
								</Button>
							</Box>
						</CustomModal>

						<CustomModal
							icon={notifSuccess ? 'success' : 'error'}
							open={modalNotif}
							onClose={() => setModalNotif(false)}
						>
							<Typography variant='h6'>
								{notifSuccess ? (
									<strong>Perbarui Akun Pengguna Berhasil</strong>
								) : (
									<strong>Perbarui Akun Pengguna Gagal</strong>
								)}
							</Typography>
							<Box
								display='flex'
								flexDirection='row'
								mt={3}
								justifyContent='center'
								// width={300}
							>
								<Button
									className={classes.buttonWidth}
									size='small'
									classes={{
										contained: classes.buttonDanger,
									}}
									variant='contained'
									onClick={() => handleModalOK()}
								>
									{' '}
									OK{' '}
								</Button>
							</Box>
						</CustomModal>
					</>
				)
			}}
		</Formik>
	)
}

edituserAccount.propTypes = {
	userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export default edituserAccount
