import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import styles from './styles'
import {
	Box,
	Drawer,
	ListItemIcon,
	Collapse,
	Typography,
	Grid,
} from '@material-ui/core'
import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import { getStructure } from './structure'
import Skeleton from '@material-ui/lab/Skeleton'
import profileImg from '../../assets/images/icons/profile.png'

const imageStorage = process.env.GATSBY_IMAGE_URL

const Component = () => {
	const [openedMenu, setOpenedMenu] = useState({})
	const [hoveredMenu, setHoveredMenu] = useState({})
	const [activeName, setActiveName] = useState('')
	const auth = useSelector((state) => state.auth)
	const classes = styles()
	const sidebarStructure = getStructure()
	const activeLink = window.location.pathname
	const iconSize = '20px'
	const userProfile = useSelector((state) => state.userProfileReducer)

	const handleNavigate = (path) => {
		navigate(path)
	}

	const handleToggle = (name) => {
		if (openedMenu[name] == true) {
			setOpenedMenu({ ...openedMenu, [name]: false })
		} else {
			setOpenedMenu({ ...openedMenu, [name]: true })
		}
	}

	const toggleHover = (name, state) => {
		setHoveredMenu({ [name]: state })
	}

	useEffect(() => {
		let split = activeName.split('.')
		let length = split.length
		let name = ''
		let openedObject = openedMenu
		for (let counter = 0; counter < length; counter++) {
			if (name != '') {
				name += '.'
			}
			name += split[counter]
			if (counter + 1 < length) {
				openedObject[name] = true
			}
		}
		setOpenedMenu({ ...openedMenu, ...openedObject })
	}, [activeName])

	const generateIcon = (item) => {
		return (
			<item.icon
				isHover={hoveredMenu[item.name]}
				isActive={activeName == item.name}
				width={iconSize}
				height={iconSize}
				color={'#6c757e'}
				hoverColor={'#009EFF'}
				activeColor={'#007BFF'}
			/>
		)
	}

	const generateMenu = (item, index) => {
		if (activeName == '' && activeLink.includes(item.link)) {
			setActiveName(item.name)
		}
		let classesParent =
			'parent' in item ? classes.listItemGutters : classes.listItem
		let classesActive = activeName == item.name ? classes.activeItem : ''

		return (
			<React.Fragment key={index}>
				<Grid
					id={item.id}
					onClick={() => {
						if ('child' in item) {
							handleToggle(item.name)
						} else if ('link' in item) {
							handleNavigate(item.link)
						}
					}}
					onMouseEnter={() => {
						toggleHover(item.name, true)
					}}
					onMouseLeave={() => {
						toggleHover(item.name, false)
					}}
					variant='body1'
					className={`${classesParent} ${classesActive}`}
				>
					<ListItemIcon
						key={item.name + 'list'}
						classes={{ root: classes.listItemIcon }}
					>
						{'icon' in item ? generateIcon(item) : false}
					</ListItemIcon>
					{item.title}
					{'child' in item ? (
						openedMenu[item.name] ? (
							<ExpandLessOutlined className={classes.iconRight} />
						) : (
							<ExpandMoreOutlined className={classes.iconRight} fontSize='small' />
						)
					) : (
						false
					)}
				</Grid>
				{'child' in item ? (
					<Collapse key={item.name} in={openedMenu[item.name]}>
						{item.child.map((row, index) => generateMenu(row, index))}
					</Collapse>
				) : (
					false
				)}
			</React.Fragment>
		)
	}

	return (
		<Drawer
			variant='permanent'
			className={clsx(classes.drawer)}
			classes={{ paper: classes.drawerPaper }}
		>
			<Box className={classes.imgProfileContainer} justifyContent='center'>
				{userProfile.data &&
				userProfile.data.contact_details.image_512_filestore ? (
					<img
						src={`${imageStorage}/180x180/erp/${userProfile.data.contact_details.image_512_filestore}`}
						style={{
							width: 'auto',
							height: '100%',
						}}
					/>
				) : (
					<img
						alt='profile'
						src={profileImg}
						style={{
							height: '100%',
							width: 'auto',
						}}
					/>
				)}
			</Box>
			<Box mt={1} className={classes.textCenter}>
				<Typography variant='body1'>
					<strong className={classes.profileName}>
						{userProfile.isLoading == false && userProfile.data ? (
							userProfile.data.name
						) : (
							<Skeleton
								style={{ margin: 'auto' }}
								variant='rect'
								width={200}
								height={15}
								animation='wave'
							/>
						)}
					</strong>
				</Typography>
				{auth.isLoading ? (
					<Skeleton variant='text' />
				) : (
					<Typography variant='body2'>
						{auth.data?.data?.company_details?.name ?? ''}
					</Typography>
				)}

				<Grid container>
					<Grid item md={12}>
						<Typography variant='body2'>
							<a
								className={classes.link}
								onClick={() => {
									handleNavigate('/dashboard/profile')
								}}
							>
								Lihat Profil
							</a>{' '}
						</Typography>
					</Grid>
				</Grid>
			</Box>

			{/* ==== Start of Sidebar Menu ==== */}
			<Box mt={2}>
				{sidebarStructure.map((row, index) => generateMenu(row, index))}
			</Box>
			{/* ==== End of Sidebar Menu ==== */}
		</Drawer>
	)
}

Component.propTypes = {}

export default Component
