import React from 'react'
import PropTypes from 'prop-types'
import LoginLayout from 'Components/loginLayout'
import LoginLayoutapl from 'Components/loginLayoutapl'
import LoginLayoutcombiphar from 'Components/loginLayoutcombiphar'
import LoginLayoutsampoerna from 'Components/loginLayoutsampoerna'
import LoginLayoutunilever from 'Components/loginLayoutunilever'
import LoginForm from 'Components/forms/loginForm'
import {
	loginStyle,
	aplstyles,
	combipharstyles,
	sampoernastyles,
	unileverstyles,
} from './styles'
import PartnerLoginForm from 'Components/forms/partnerLoginForm'
import Logounilever from '../../assets/images/whitelabel/logo-unilever.png'
import padlock from '../../assets/images/whitelabel/padlock.svg'
import padlock2 from '../../assets/images/whitelabel/padlock2.svg'
import loginIcon from '../../assets/images/whitelabel/login.svg'

// import { test } from '../../utils/exports'

const Login = (props) => {
	const classes_loginStyle = loginStyle()
	const classes_apl = aplstyles()
	const classes_combiphar = combipharstyles()
	const classes_sampoerna = sampoernastyles()
	const classes_unilever = unileverstyles()

	const getSubdomain = () => {
		let arrDomain = props.location.hostname.split('.')
		switch (arrDomain[0]) {
			case 'sampoerna':
				return (
					<LoginLayoutsampoerna classes={classes_sampoerna}>
						<PartnerLoginForm
							classes={classes_sampoerna}
							formLogo=''
							formTitle=''
							formTitleIcon={padlock}
							errorPosition='bottom'
							poweredBy={true}
						/>
					</LoginLayoutsampoerna>
				)
			case 'apl':
				return (
					<LoginLayoutapl classes={classes_apl}>
						<PartnerLoginForm
							classes={classes_apl}
							formLogo=''
							formTitle='Member Login'
							formTitleIcon={loginIcon}
							errorPosition='bottom'
							poweredBy={true}
						/>
					</LoginLayoutapl>
				)
			case 'combiphar':
				return (
					<LoginLayoutcombiphar classes={classes_combiphar}>
						<PartnerLoginForm
							classes={classes_combiphar}
							formLogo=''
							formTitle='Member Login'
							formTitleIcon=''
							errorPosition='bottom'
							poweredBy={false}
						/>
					</LoginLayoutcombiphar>
				)
			case 'unilever':
				return (
					<LoginLayoutunilever classes={classes_unilever}>
						<PartnerLoginForm
							classes={classes_unilever}
							formLogo={Logounilever}
							formTitle='Selamat Datang Kembali'
							formTitleIcon={padlock2}
							errorPosition='top'
							poweredBy={true}
						/>
					</LoginLayoutunilever>
				)
			default:
				return (
					<LoginLayout classes={classes_loginStyle}>
						<LoginForm classes={classes_loginStyle} />
					</LoginLayout>
				)
		}
	}

	return (
		<React.Fragment>
			{getSubdomain()}
			{/* <LoginForm classes={classes_loginStyle} /> */}
		</React.Fragment>
	)
}

Login.propTypes = {
	location: PropTypes.any,
}

export default Login
