export const getInitialValue = (companyDetail, contactList) => {
	let values = {
		namaPembeli: '',
		latarBelakang: '',
		alamat: '',
		location: null,
		noTelp: '',
		noPonsel: '',
		child_ids: [],
		editPic: null,
	}
	if (companyDetail.data && contactList.data) {
		const {
			name,
			company_background,
			street,
			region_id,
			phone,
			mobile,
		} = companyDetail.data.company_details
		values = {
			namaPembeli: name || '',
			latarBelakang: company_background || '',
			alamat: street || '',
			location: {
				id: region_id.id || 0,
				complete_name: region_id.complete_name,
				level: region_id.level,
			},
			noTelp: phone || '',
			noPonsel: mobile || '',
			child_ids:
				contactList.data.result.map((pic) => ({
					id: pic.id,
					name: pic.name,
					email: pic.email,
					mobile: pic.mobile,
					phone: pic.phone,
					action: 'updated',
					jabatan: pic.function,
					type: 'contact',
				})) || [],
			editPic: null,
			storage_id: 0,
		}
	}

	return values
}

export const handleEdit = (value, action) => {
	action.setFieldTouched('editPic.name', false)
	action.setFieldTouched('editPic.phone', false)
	action.setFieldTouched('editPic.email', false)
	action.setFieldValue('editPic.id', value.id)
	action.setFieldValue('editPic.name', value.name)
	action.setFieldValue('editPic.email', value.email)
	action.setFieldValue('editPic.jabatan', value.jabatan)
	action.setFieldValue('editPic.phone', value.phone)
	action.setFieldValue('editPic.type', 'contact')
	if (value.action == 'updated') {
		action.setFieldValue('editPic.action', 'updated')
	} else if (value.action == 'new') {
		action.setFieldValue('editPic.action', 'new')
	}
}
