import { makeStyles } from '@material-ui/core/styles'

export const EditCompanyProfileStyle = makeStyles((theme) => ({
	container: {
		overflow: 'hidden',
	},
	gridSearch: {
		marginTop: '32px',
		marginBottom: '15px',
	},
	customField: {
		width: '275px',
	},
	boxSpacing: {
		marginTop: '15px',
	},
	buttonAdd: {
		backgroundColor: theme.palette.warning.dark,
	},
	bcheckBox: {
		color: theme.palette.primary.main,
	},
	image: {
		width: 100,
		height: 100,
	},
	customButton: {
		fontSize: '12px',
	},
	buttonNegative: {
		backgroundColor: theme.palette.error.dark,
		color: theme.palette.common.white,
	},
	customTooltip: {
		position: 'absolute',
		marginTop: '3px',
		padding: '0',
		minWidth: '0',
	},
	customIcon: {
		fontSize: '14px',
		marginLeft: '10px',
	},
	formLabel: {
		display: 'flex',
		alignItems: 'center',
	},
	contactField: {
		marginTop: 15,
	},
	wordBreak: {
		wordBreak: 'break-all',
	},
	lineHeight: {
		lineHeight: 1.75,
	},
	textArea: {
		width: '100%',
		height: '100% !important',
	},
	buttonIcon: {
		justifyContent: 'flex-end',
	},
	colorRed: {
		color: theme.palette.alert.dark,
	},
	labelRequired: {
		position: 'relative',
		'&:after': {
			content: '"*"',
			position: 'absolute',
			color: theme.palette.error.dark,
		},
	},
	textDialog: {
		margin: '15px 0',
	},
	addContact: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.palette.background.surface1,
	},
	linkCursor: {
		cursor: 'pointer',
		color: theme.palette.info.dark,
	},
	buttonDanger: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	buttonWidth: {
		width: 120,
	},
	marginTB1: {
		margin: theme.spacing(1, 0),
	},
	noOptionText: {
		color: 'inherit',
	},
	loadingText: {
		color: 'inherit',
	},
	tooltip: {
		fontSize: '12px',
		padding: '8px 12px',
		borderRadius: '2px',
		background: 'rgba(97, 97, 97, 1)',
	},
	tooltipPlacementBottom: {
		margin: '6px 0 !important',
		transform: 'translateX(50px) !important',
	},
	cardRelative: {
		position: 'relative',
		overflow: 'visible',
	},
	link: {
		cursor: 'pointer',
	},
	linkDisabled: {
		cursor: 'default',
		color: '#CBCED5',
	},
	deleteButton: {
		position: 'absolute',
		top: '0',
		right: '0',
		transform: 'translateY(-50%) translateX(50%)',
		padding: '0',
		background: 'white',
		color: theme.palette.alert.dark,
	},
	cardContent: {
		padding: '16px !important',
	},
	modalTitle: {
		marginBottom: '20px',
	},
	emptyPic: {
		color: '#A2A4A7',
	},
	docImage: {
		width: 100,
		height: 100,
	},
	ellipsisText: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
}))
