export const dataTransactions = {
	id: '',
	noPO: '',
	noMOU: '',
	noPR: '',
	noRFQ: '',
	noQuotation: '',
	NoRefPO: '',
	timeCreate: '',
	expiredDate: '',
	requestor: '',
	deparRequestor: '',
	internalNotes: '',
	note: '',
	shippingAdd: '',
	billingAdd: '',
	expectedDelivDate: '',
	receiverName: '',
	receiverPhone: '',
	state: '',
	parentState: '',
	amountUntax: 0,
	amountTotal: 0,
	amountGroup: [],
	orderLine: [],
	status: '',
	shippingVendor: [],
	isReject: false,
	userApproval: '',
	tokenApproval: '',
	noInvoice: '',
	noDNBAST: '',
	paymentState: '',
	termOfPayment: '',
	dueDate: '',
	media: [],
	budgets: null,
}
