import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { getTransactionLog } from 'States/actions/MOU/detail'
import { dateFormatFromOdoo } from '../../../utils/globalUtils'
import { navigate } from 'gatsby'
import Status from 'Components/status'
import ListIcon from '@material-ui/icons/List'
import { formatMoney } from '../../../utils/formatMoney'
import {
	Box,
	Button,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Grid,
	Tooltip,
} from '@material-ui/core'
import Styles from './styles'
import Pagination from '@material-ui/lab/Pagination'
import { InfoOutlined } from '@material-ui/icons'

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		fontSize: 11,
		border: '1px solid #dadde9',
		'& .MuiTooltip-arrow': {
			backgroundColor: '#fff',
			border: 'solid #dadde9',
			borderWidth: '0 1px 1px 0',
			display: 'inline-block',
			padding: '6px',
			transform: 'rotate(135deg)',
			'-webkit-transform': 'rotate(135deg)',
		},
	},
}))(Tooltip)

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)

const generateStatus = (status) => {
	if (status == 'sale') {
		return <Status status={'mou_saleorder_created'} />
	} else if (status === 'draft' || status === 'sent') {
		return <Status status={'mou_saleorder_draft'} />
	} else {
		return <Status status={'mou_saleorder_cancelled'} />
	}
}

const Component = ({ mouId, pageSize }) => {
	const dispatch = useDispatch()
	const [totalPage, setTotalPage] = useState(1)
	const [page, setPage] = useState(1)
	// selector
	const transactionLog = useSelector((state) => state.transactionLogMou)

	useEffect(() => {
		dispatch(getTransactionLog(mouId, pageSize, page))
	}, [mouId, pageSize])

	useEffect(() => {
		if (
			!transactionLog.isLoading &&
			transactionLog.data &&
			transactionLog.data.result
		) {
			const { pagination } = transactionLog.data
			const newTotalPage = Math.ceil(pagination.total_records / pageSize)
			setTotalPage(newTotalPage)
		}
	}, [transactionLog.data, transactionLog.isLoading])

	const handlePageChange = (_e, page) => {
		setPage(page)
		dispatch(getTransactionLog(mouId, pageSize, page))
	}

	const classes = Styles()
	return (
		<Box>
			<Paper>
				<Box py={2} px={3}>
					<Typography variant='body1'>
						<strong>Log Transaksi</strong>
						<LightTooltip
							title='Merupakan daftar riwayat transaksi pembelian melalui MOU. Anda dapat masuk ke halaman Rincian Pembelian (Purchase Order) dengan menekan tombol Aksi.'
							arrow
							placement='right'
							style={{
								color: '#6b6d70',
								marginLeft: '5px',
								fontSize: '20px',
								transform: 'translateY(3px)',
							}}
						>
							<InfoOutlined />
						</LightTooltip>
					</Typography>
					<Box mt={1.5}>
						<Table classes={{ root: classes.tableRoot }}>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography variant='body2'>
											<strong>Tanggal Pengajuan</strong>
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='body2'>
											<strong>Tanggal PO Terbuat</strong>
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='body2'>
											<strong>No. PO</strong>
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='body2'>
											<strong>Total Nilai PO</strong>
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='body2'>
											<strong>Nama Pembuat PO</strong>
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='body2'>
											<strong>Status</strong>
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='body2'>
											<strong>Aksi</strong>
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!transactionLog.data
									? null
									: !transactionLog.data.result
									? null
									: transactionLog.data.result.map((item, index) => (
											<TableRow key={index}>
												<CustomCell>
													{dateFormatFromOdoo(item.create_date, 'dd MMMM yyyy')}
												</CustomCell>
												<CustomCell>
													{item.purchase_order_no
														? dateFormatFromOdoo(item.po_create_date, 'dd MMMM yyyy')
														: '-'}
												</CustomCell>
												<CustomCell>{item.purchase_order_no || '-'}</CustomCell>
												<CustomCell>{formatMoney(item.amount_untaxed)}</CustomCell>
												<CustomCell>{item.requestor_name || '-'}</CustomCell>
												<CustomCell style={{ display: 'flex' }}>
													<Box width={120}>{generateStatus(item.state)}</Box>
												</CustomCell>
												<CustomCell>
													{item.state !== 'draft' &&
													item.state !== 'sent' &&
													item.purchase_order_no ? (
														<Button
															className={classes.buttonAction}
															variant='contained'
															color='secondary'
															size='small'
															onClick={() =>
																navigate(`/dashboard/purchase-order/${item.purchase_order_no}`)
															}
														>
															<ListIcon className={classes.iconAction} />
														</Button>
													) : (
														'-'
													)}
												</CustomCell>
											</TableRow>
									  ))}
							</TableBody>
						</Table>
					</Box>
				</Box>
			</Paper>
			{transactionLog.data && transactionLog.data.result && (
				<Box className={classes.paginationContainer}>
					<Grid container justify='flex-end' alignItems='center'>
						<Typography className={classes.typoPage}>
							{`Menampilkan ${transactionLog.data.pagination.count} data dari ${transactionLog.data.pagination.total_records}`}
						</Typography>
						<Pagination
							count={totalPage}
							defaultValue={1}
							page={page}
							shape='rounded'
							color='secondary'
							// TODO CREATE FUNCTION FOR HANDLE CHANGE PAGE
							onChange={handlePageChange}
							siblingCount={1}
						/>
					</Grid>
				</Box>
			)}
		</Box>
	)
}

Component.propTypes = {
	mouId: PropTypes.number,
	pageSize: PropTypes.number,
}

export default Component
