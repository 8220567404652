import { CATEGORY } from '../../types'

export const addCategoryForRef = (i) => {
	return async (dispatch) => {
		dispatch({
			type: CATEGORY,
			payload: {
				data: { i },
			},
		})
	}
}
