import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Styles from './styles'
import { Autocomplete, Pagination, Skeleton } from '@material-ui/lab'
import { Boxes } from 'Components/svgs'
import { requestAddress } from 'States/actions/companyAddressAction'
import { CloseOutlined, SearchOutlined } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { generateAddress } from '../../../utils/globalUtils'
import {
	setAddress,
	// receiverPhone,
} from 'States/actions/companyAddressAction'
import PropTypes from 'prop-types'
import {
	Box,
	Modal as MuiModal,
	Button,
	Divider,
	Fade,
	Backdrop,
	Paper,
	Typography,
	Grid,
	TextField,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	OutlinedInput,
	TableContainer,
} from '@material-ui/core'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)

const ModalBilling = ({ open, onClose, setFieldValue }) => {
	const classes = Styles()
	const dispatch = useDispatch()
	const [dataProvice, setDataProvince] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [city, setCity] = useState('')
	const [prov, setProv] = useState({
		name: '',
		state_id: null,
	})
	const [name, setName] = useState('')

	const province = useSelector((state) => state.locationReducer.provincies)
	const billingAddress = useSelector(
		(state) => state.companyAddressReducer.billingAddressReducer
	)

	useEffect(() => {
		setDataProvince(province.data.result)
	}, [province.data])

	useEffect(() => {
		if (open) {
			dispatch(requestAddress('billing', false, 1, '', name, city, 4))
			setCurrentPage(1)
			setProv({
				name: '',
				state_id: '',
			})
			setName('')
			setCity('')
		}
	}, [open])

	const handleSelect = (addrs) => {
		dispatch(setAddress('billing', addrs))
		// dispatch(receiverPhone(billingAddress.defaultAddress.phone))
	}

	const handlePageChange = (e, page) => {
		setCurrentPage(page)
		dispatch(requestAddress('billing', false, page, prov.state_id, name, city, 4))
	}

	const handleChangeProvince = (value) => {
		if (value) {
			setProv({
				name: value.name,
				state_id: value.id,
			})
			dispatch(requestAddress('billing', false, 1, value.id, name, city, 4))
		} else {
			setProv({
				name: '',
				state_id: '',
			})
			dispatch(requestAddress('billing', false, 1, '', name, city, 4))
		}
	}

	const handleSearch = () => {
		dispatch(requestAddress('billing', false, 1, prov.state_id, name, city, 4))
	}

	const handleHide = () => {
		setFieldValue('alamatTagihan', generateAddress(billingAddress.defaultAddress))
		onClose()
	}

	return (
		<MuiModal
			className={classes.modal}
			open={open}
			closeAfterTransition
			onClose={() => handleHide()}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open} className={classes.modalFade}>
				<Paper>
					<Box p={2} display='flex' flexDirection='column'>
						<CloseOutlined
							className={classes.modalCloseIcon}
							onClick={() => handleHide()}
						/>
						<Typography variant='h6'>
							<strong>Ubah Alamat Penagihan</strong>
						</Typography>
						<Divider />
						<Box className={classes.containerField}>
							<Box>
								<Autocomplete
									classes={{
										root: classes.autocompleteRoot,
										input: classes.selectInput,
										inputRoot: classes.inputRoot,
										endAdornment: classes.endAdornment,
										popupIndicator: classes.selectIcon,
									}}
									renderOption={(option) => option.name}
									getOptionSelected={(option, value) => option.name == value.name}
									getOptionLabel={(option) => option.name || ''}
									options={dataProvice}
									onChange={(opt, val) => handleChangeProvince(val)}
									renderInput={(params) => (
										<TextField
											variant='outlined'
											{...params}
											placeholder='Pilih Provinsi'
										/>
									)}
								/>
							</Box>
							<Box>
								<OutlinedInput
									margin='dense'
									value={city}
									onChange={(e) => setCity(e.target.value)}
									placeholder='Masukan nama kota'
								/>
							</Box>
							<Box>
								<OutlinedInput
									margin='dense'
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder='Masukan nama tempat'
								/>
							</Box>
							<Box>
								<Button
									variant='contained'
									color='secondary'
									onClick={() => handleSearch()}
								>
									<SearchOutlined />
								</Button>
							</Box>
						</Box>
						{billingAddress.data ? (
							!billingAddress.data.result.length ? (
								<Box
									display='flex'
									alignItems='center'
									flexDirection='column'
									justifyContent='center'
									py={2}
								>
									<Boxes />
									<Typography>
										Tidak ada data alamat tagihan yang dapat ditampilkan
									</Typography>
								</Box>
							) : (
								<TableContainer className={classes.tableContainer}>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell>
													<strong>Kota/Provinsi</strong>
												</TableCell>
												<TableCell style={{ width: 380 }}>
													<strong>Alamat</strong>
												</TableCell>
												<TableCell>
													<strong>Nama Tempat</strong>
												</TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{billingAddress.isLoading
												? [1, 2, 3, 4, 5].map((itm) => (
														<TableRow key={itm}>
															<CustomCell>
																<Skeleton variant='rect' height={30} width={200} />
															</CustomCell>
															<CustomCell>
																<Skeleton variant='rect' height={30} width={200} />
															</CustomCell>
															<CustomCell>
																<Skeleton variant='rect' height={30} width={100} />
															</CustomCell>
															<CustomCell>
																<Skeleton variant='rect' height={30} width={100} />
															</CustomCell>
														</TableRow>
												  ))
												: billingAddress.data.result.map((addrs, index) => (
														<TableRow key={index}>
															<CustomCell>
																{addrs.city}, {addrs.state_id.name}
															</CustomCell>
															<CustomCell>
																{addrs.street}
																{addrs.sub_district.name ? ', ' + addrs.sub_district.name : ''}
																{addrs.district.name ? ', ' + addrs.district.name : ''}
															</CustomCell>
															<CustomCell>{addrs.name}</CustomCell>
															<CustomCell>
																<Button
																	onClick={() => handleSelect(addrs)}
																	fullWidth
																	size='small'
																	variant={
																		billingAddress.defaultAddress &&
																		billingAddress.defaultAddress.id === addrs.id
																			? 'contained'
																			: 'outlined'
																	}
																	color='secondary'
																>
																	{billingAddress.defaultAddress &&
																	billingAddress.defaultAddress.id === addrs.id
																		? 'Terpilih'
																		: 'Pilih'}
																</Button>
															</CustomCell>
														</TableRow>
												  ))}
										</TableBody>
									</Table>
								</TableContainer>
							)
						) : null}
						<Divider />
						<Box my={2}>
							{!billingAddress.data ? null : !billingAddress.data.pagination
									.total_page ? null : (
								<Grid container justify='space-between' alignItems='center'>
									<Typography className={classes.typoPage}>
										{`Menampilkan ${billingAddress.data.pagination.count} data dari ${billingAddress.data.pagination.total_records} `}
									</Typography>
									<Pagination
										count={Math.ceil(billingAddress.data.pagination.total_records / 4)}
										page={currentPage}
										shape='rounded'
										color='secondary'
										onChange={handlePageChange}
										siblingCount={2}
									/>
								</Grid>
							)}
						</Box>
					</Box>
				</Paper>
			</Fade>
		</MuiModal>
	)
}

ModalBilling.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	setFieldValue: PropTypes.func,
}

export default React.memo(ModalBilling)
