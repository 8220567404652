import { ADDRESS_CREATE } from '../../../types'
import API from '../../../../utils/api'
import { navigate } from 'gatsby'
import { clearDeleteAddress } from './deleteAction'

const loading = () => {
	return {
		type: `${ADDRESS_CREATE}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${ADDRESS_CREATE}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${ADDRESS_CREATE}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const clearCreateAddress = () => {
	return {
		type: `${ADDRESS_CREATE}_CLEAR`,
	}
}

export const createAddress = (data) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			const response = await API({
				method: 'POST',
				url: '/companies/address/create',
				data: data,
			})

			if ('error_backend' in response.data) {
				dispatch(failure(response.data.error_backend.http_body))
			} else {
				dispatch(success(response.data))
				dispatch(clearDeleteAddress())
				navigate('/dashboard/company-address')
			}
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
