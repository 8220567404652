import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.primary.main,
		zIndex: 3,
		position: 'relative',
	},
	headerItem: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(1),
		color: theme.palette.common.white,
		[theme.breakpoints.up('lg')]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	borderMenu: {
		borderBottom: '1px solid',
		borderBottomColor: theme.palette.grey.A100,
		fontWeight: 'bold',
	},
	verticalCenter: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}))

export default useStyles
