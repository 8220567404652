import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import styles from './style'

function Component({ children, type, size }) {
	const classes = styles({ type, size })
	return (
		<Box>
			<Box className={classes.container}>{children}</Box>
		</Box>
	)
}

Component.propTypes = {
	type: PropTypes.string,
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	children: PropTypes.node,
}

Component.defaultProps = {
	size: 'sm',
}

export default Component
