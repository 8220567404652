/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
	Box,
	Container,
	Typography,
	Button,
	Grid,
	TextField,
	IconButton,
	Link,
} from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'
import useStyles from './styles'
import { navigate } from '@reach/router'
import { requestMOUList } from 'States/actions/MOU/MOUListAction'
import * as qs from 'query-string'
import {
	newUri,
	dateFormat,
	dateFormatFromOdoo,
	isValidDate,
} from '../../../utils/globalUtils'
import { formatMoney } from '../../../utils/formatMoney'
import Breadcrumbs from 'Components/breadcrumbs'
import { format } from 'date-fns'
import Status from 'Components/status'
import DashboardLayout from 'Components/dashboardLayout'
import ListIcon from '@material-ui/icons/List'
import TableData from 'Components/tableData'

import DatePickerRange from 'Components/DateRange'

const MOUList = (props) => {
	const classes = useStyles()

	const dispatch = useDispatch()
	const list = useSelector((state) => state.MOUListReducer)
	const queryString = qs.parse(props.location.search)

	const [perPage, setPerPage] = useState(
		queryString.per_page ? queryString.per_page : 10
	)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [mouNo, setMouNo] = useState(
		queryString.mou_no ? queryString.mou_no : ''
	)
	const [startDate, setStartDate] = useState(
		queryString.start_date ? queryString.start_date : ''
	)
	const [endDate, setEndDate] = useState(
		queryString.end_date ? queryString.end_date : ''
	)
	const [state, setState] = useState(queryString.state ? queryString.state : '')
	const [range, setRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	])
	const [dataList, setDataList] = useState([])
	const joinDate = (startDate, endDate) => `${startDate} ~ ${endDate}`

	const [placeHolder, setPlaceHolder] = useState(
		startDate && endDate ? joinDate(startDate, endDate) : 'Rentang Tanggal'
	)
	const [color, setColor] = useState(
		isValidDate(startDate) && isValidDate(startDate) ? '#007BFF' : '#a9acaf'
	)

	queryString.per_page = perPage
	queryString.page = page
	queryString.mou_no = mouNo
	queryString.start_date = startDate
	queryString.end_date = endDate
	queryString.state = state

	const handleOnChange = (item) => {
		const str = `${format(item.selection.startDate, 'yyyy-MM-dd')} ~ ${format(
			item.selection.endDate,
			'yyyy-MM-dd'
		)}`
		setPlaceHolder(str)
		setRange([item.selection])
		setColor('#007BFF')
		setStartDate(dateFormat(item.selection.startDate, 'yyyy-MM-dd'))
		setEndDate(dateFormat(item.selection.endDate, 'yyyy-MM-dd'))
		queryString.start_date = dateFormat(item.selection.startDate, 'yyyy-MM-dd')
		queryString.end_date = dateFormat(item.selection.endDate, 'yyyy-MM-dd')
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleOnClear = () => {
		setPlaceHolder('Rentang Tanggal')
		setColor('#a9acaf')
		setRange([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		])
		setStartDate('')
		setEndDate('')
		queryString.start_date = ''
		queryString.end_date = ''
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	useEffect(() => {
		dispatch(requestMOUList({ queryString }))
	}, [])

	// hooks for set default state
	useEffect(() => {
		// success
		if (list.data && list.data.result) {
			let result = list.data.result
			if (result) {
				setDataList(result)
			}
		} else {
			setDataList([])
		}
	}, [list])

	// useEffect(() => {
	// 	if (startDate != '' || endDate != '') {
	// 		submitSearch()
	// 	}
	// }, [startDate, endDate])

	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'MOU',
			link: '#',
		},
	]

	const handleClickDetail = (mou_name) => {
		navigate(`/dashboard/mou/detail/${mou_name}`)
	}

	const handleLabel = (moustate) => {
		switch (moustate) {
			case 'active':
				return 'MOUActive'
			case 'inactive':
				return 'MOUInactive'
			case 'done':
				return 'MOUDone'
			case 'confirm':
				return 'MOUNeedConfirm'
			default:
				return moustate
		}
	}

	const columns = [
		{
			name: 'NO MOU',
			selector: 'name',
			sortable: true,
			cell: (row) => (
				<Link
					href={`/dashboard/mou/detail/${row.name}`}
					color='secondary'
					target='_blank'
				>
					{row.name}
				</Link>
			),
			width: '150px',
		},
		{
			name: 'JUDUL MOU',
			selector: 'agreement_title',
			sortable: true,
			width: '150px',
		},
		{
			name: 'MOU DIMULAI',
			selector: 'start_date',
			sortable: true,
			cell: (row) => dateFormatFromOdoo(row.start_date, 'dd-MMM-yyyy'),
			width: '200px',
		},
		{
			name: 'BERLAKU HINGGA',
			selector: 'validity_date',
			expected_date: 'dateMou',
			sortable: true,
			cell: (row) => dateFormatFromOdoo(row.validity_date, 'dd-MMM-yyyy'),
			width: '170px',
		},
		{
			name: 'TOTAL NILAI MOU',
			selector: 'amount_untaxed',
			sortable: true,
			cell: (row) => formatMoney(Math.round(row.amount_untaxed)),
			width: '180px',
		},
		{
			name: 'STATUS',
			selector: 'state',
			sortable: true,
			cell: (row) => <Status status={handleLabel(row.state)} />,
			width: '150px',
		},
		{
			name: 'AKSI',
			selector: 'aksi',
			sortable: true,
			center: true,
			width: '90px',
			cell: (row) => (
				<Button
					id='btn-detail'
					className={classes.buttonAction}
					variant='contained'
					color='secondary'
					size='small'
					onClick={() => handleClickDetail(row.name)}
				>
					<ListIcon className={classes.iconAction} />
				</Button>
			),
		},
	]

	const limitValue = [10, 25, 50, 100]

	const buttonStatus = [
		{
			status: 'all_status',
			state: '',
		},
		{
			status: 'MOUNeedConfirm',
			state: 'confirm',
		},
		{
			status: 'MOUActive',
			state: 'active',
		},
		{
			status: 'MOUInactive',
			state: 'inactive',
		},
		{
			status: 'MOUDone',
			state: 'done',
		},
	]

	const propsNavigate = (query) => {
		props.navigate(`/dashboard/mou?${query}`)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setPerPage(event.target.value)
		queryString.per_page = event.target.value
		let maxPage = Math.ceil(
			list.data.pagination.total_records / queryString.per_page
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		submitSearch()
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => submitSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [mouNo])

	const handleChangeSearchMou = (e) => {
		let value = e.target.value
		queryString.mou_no = value
		queryString.page = 1
		setPage(1)
		setMouNo(value)
	}

	function submitSearch() {
		let query = newUri(queryString)
		dispatch(requestMOUList({ queryString }))
		propsNavigate(query)
	}

	const handleButtonState = (e, btnState) => {
		setState(btnState)
		queryString.state = btnState
		queryString.page = 1
		submitSearch()
	}

	const handleSearchMou = () => {
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleEnterSearchMou = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>
				<Box className={'searchContainer'}>
					<Typography variant='h6'>MOU</Typography>
					<br />
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<DatePickerRange
								ranges={range}
								color={color}
								onSelectDate={(item) => handleOnChange(item)}
								placeholder={placeHolder}
								onClear={() => handleOnClear()}
							/>
						</Grid>
						<Grid item xs={3}>
							<Grid item className={classes.gridSearch}>
								<TextField
									placeholder='Cari No. MOU / Judul MOU'
									type='search'
									className={'nameSearch'}
									InputProps={{
										disableUnderline: false,
									}}
									onChange={(e) => handleChangeSearchMou(e)}
									onKeyDown={(e) => handleEnterSearchMou(e)}
									// onBlur={e => handleChangeSearchMou(e)}
									value={mouNo}
								/>
								<IconButton
									className={classes.buttonSearch}
									variant='contained'
									color='primary'
									onClick={handleSearchMou}
								>
									<SearchRounded fontSize='small' />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<Box mt={2}>
					<TableData
						buttonState={buttonStatus}
						buttonStateClick={handleButtonState}
						limitValue={limitValue}
						perPage={perPage}
						limitChange={handlePerRowsChange}
						columns={columns}
						isLoading={list.isLoading}
						pagination={list.data ? list.data.pagination : false}
						data={dataList}
						name={'MOU'}
						paginationPageChange={handlePageChange}
						pageNow={parseInt(queryString.page)}
						labelState='Status'
						queryState={queryString.state}
					/>
				</Box>
				<br />
			</Container>
		</DashboardLayout>
	)
}

MOUList.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default MOUList
