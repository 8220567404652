import { MOU_LIST } from '../../types'
import API from '../../../utils/api'
import { newUriAPI } from '../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${MOU_LIST}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${MOU_LIST}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${MOU_LIST}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestMOUList = ({ queryString }) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			let url = ''
			if (queryString) url = newUriAPI(queryString)

			let response = await API({
				method: 'GET',
				url: '/mou/list' + (url != '' ? '?' + url : ''),
			})

			dispatch(success(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
