import React from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './styles'

const Component = ({ variant, children, icon, iconClose }) => {
	const classes = styles({ variant })
	return (
		<Box display='flex' className={classes.container}>
			<Box className={classes.icon}>{icon}</Box>
			<Box flex={1}>{children}</Box>
			<Box className={classes.iconClose}>{iconClose}</Box>
		</Box>
	)
}

Component.propTypes = {
	variant: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	icon: PropTypes.element,
	iconClose: PropTypes.element,
}

Component.defaultProps = {
	variant: 'success',
}

export default Component
