/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import {
	RFQ,
	TransactionsRFQList,
	Cart,
	DetailRfq,
	RfqSubmited,
	DetailQuotation,
	TransactionsQuotationList,
	DetailPurchaseOrder,
	TransactionsPOList,
	InvoiceList,
	InvoiceDetail,
	PaymentList,
	PaymentDetail,
	PurchaseRequest,
	PurchaseRequestDetail,
	PaymentConfirmation,
	BulkPaymentConfirmation,
} from '../routes'
import { rolesText } from '../constants/text'

export const TransactionNav = () => {
	return (
		<>
			{/* rfq  */}
			<PrivateRoute
				path='/dashboard/rfq/form'
				roles={rolesText('pemohon')}
				Component={RFQ}
			/>
			<PrivateRoute
				path='/dashboard/rfq'
				roles={rolesText('all')}
				Component={TransactionsRFQList}
			/>
			<PrivateRoute path='/cart' roles={rolesText('pemohon')} Component={Cart} />
			<PrivateRoute
				path='/dashboard/rfq/:rfqId'
				roles={rolesText('all')}
				Component={DetailRfq}
			/>
			<PrivateRoute
				path='/dashboard/rfq-submited'
				roles={rolesText('pemohon')}
				Component={RfqSubmited}
			/>
			{/* quotation */}
			<PrivateRoute
				path='/dashboard/quotation/:saleOrderId'
				roles={rolesText('all')}
				Component={DetailQuotation}
			/>
			<PrivateRoute
				path='/dashboard/quotation'
				roles={rolesText('all')}
				Component={TransactionsQuotationList}
			/>
			{/* Purchase Order */}
			<PrivateRoute
				path='/dashboard/purchase-order/:poNumber'
				roles={rolesText('all')}
				Component={DetailPurchaseOrder}
			/>
			<PrivateRoute
				path='/dashboard/purchase-order/'
				roles={rolesText('all')}
				Component={TransactionsPOList}
			/>
			{/* Invoice */}
			<PrivateRoute
				path='/dashboard/invoice/'
				roles={rolesText('invoice')}
				Component={InvoiceList}
			/>
			<PrivateRoute
				path='/dashboard/invoice/:invoiceID'
				roles={rolesText('invoice')}
				Component={InvoiceDetail}
			/>
			{/* Payment */}
			<PrivateRoute
				path='/dashboard/payment/'
				roles={rolesText('payment')}
				Component={PaymentList}
			/>
			<PrivateRoute
				path='/dashboard/payment/:paymentID'
				roles={rolesText('payment')}
				Component={PaymentDetail}
			/>
			{/* Purchase Request */}
			<PrivateRoute
				path='/dashboard/purchase-request'
				roles={rolesText('all')}
				Component={PurchaseRequest}
			/>
			<PrivateRoute
				path='/dashboard/purchase-request/:prNumber'
				roles={rolesText('all')}
				Component={PurchaseRequestDetail}
			/>
			{/* Payment */}
			<PrivateRoute
				path='/dashboard/payment/confirm/:invoiceId'
				roles={rolesText('finance')}
				Component={PaymentConfirmation}
			/>
			<PrivateRoute
				path='/dashboard/payment/confirm/bulk'
				roles={rolesText('finance')}
				Component={BulkPaymentConfirmation}
			/>
		</>
	)
}
