/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import {
	Unduhdatatransaksi,
	UnduhDataPerusahaan,
	UnduhDataMOU,
} from '../routes'
import { rolesText } from '../constants/text'

export const ReportNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard/download/transaction'
				roles={rolesText('all')}
				Component={Unduhdatatransaksi}
			/>
			<PrivateRoute
				path='/dashboard/download/company'
				roles={rolesText('all')}
				Component={UnduhDataPerusahaan}
			/>
			<PrivateRoute
				path='/dashboard/download/mou'
				roles={rolesText('all')}
				Component={UnduhDataMOU}
			/>
		</>
	)
}
