import React, { useState } from 'react'
import LayoutComponent from 'Components/layout'
import {
	Container,
	Box,
	Grid,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputBase,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Breadcrumbs from 'Components/breadcrumbs'
import { PrivacyPolicyStyle } from './styles'
import { navigate } from 'gatsby'

const PrivacyPolicy = () => {
	const classes = PrivacyPolicyStyle()

	const CustomInput = withStyles((theme) => ({
		root: {
			'label + &': {
				marginTop: theme.spacing(3),
			},
		},
		input: {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'&#34;Segoe UI&#34;',
				'Roboto',
				'&#34;Helvetica Neue&#34;',
				'Arial',
				'sans-serif',
				'&#34;Apple Color Emoji&#34;',
				'&#34;Segoe UI Emoji&#34;',
				'&#34;Segoe UI Symbol&#34;',
			].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
			},
		},
	}))(InputBase)

	const lang = {
		id: '/pages/id/privacy-policy',
		en: '/pages/en/privacy-policy',
	}
	const activeLink = window.location.pathname

	const handleChange = (event) => {
		navigate(event.target.value)
	}

	// const handleNavigate = path => {
	// 	navigate(path)
	// }

	const [breadcrumbs] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Kebijakan Privasi',
			link: '#',
		},
	])

	return (
		<>
			<LayoutComponent>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrumbs} />
					</Box>
					<Box>
						<Grid container justify='space-between'>
							<Grid item xs={6}>
								<Typography variant='h6'>Kebijakan Privasi</Typography>
							</Grid>
							<Grid container item justify='flex-end' alignItems='center' xs={3}>
								<FormControl>
									<Select
										id='lang-product-return'
										value={activeLink}
										onChange={handleChange}
										input={<CustomInput fullWidth variant='outlined' color='primary' />}
									>
										<MenuItem value={lang['id']}>Bahasa Indonesia</MenuItem>
										<MenuItem value={lang['en']}>Bahasa Inggris</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box mt={2}>
						<Box mb={1} textAlign='justify'>
							<Typography variant='body1'>
								Selamat datang di MBiz.co.id. Terima kasih telah mengunjungi situs web
								Kami. Halaman ini menampilkan Kebijakan Privasi (“<b>Kebijakan</b>”),
								dimana hal-hal mengenai privasi dan data pribadi Anda akan diatur
								disini. Apabila Anda tidak setuju atau memiliki keberatan atas hal-hal
								yang diatur dalam Kebijakan ini, kami meminta Anda untuk segera
								menghentikan penggunaan layanan Kami. Sebagai pengguna layanan Kami,
								Anda diwajibkan untuk turut dan tunduk pada Kebijakan ini.
							</Typography>
						</Box>
						<Box mb={2} textAlign='justify'>
							<Typography variant='body1'>
								Kami, PT Brilliant Ecommerce Berjaya (BEB), suatu perusahaan yang
								melakukan usaha melalui situs web www.mbiz.co.id (“<b>www.mbiz.co.id</b>
								” atau &quot;<b>Kami</b>&quot;), memahami betapa pentingnya privasi dan
								data pribadi Anda dan kami berkomitmen penuh untuk melindungi privasi
								dan data pribadi Anda. Kami menghimbau Anda untuk tidak mengakses
								www.mbiz.co.id atau menyediakan data pribadi atau data apa pun kepada
								Kami sebelum Anda membaca, memahami, dan menyetujui Kebijakan ini secara
								menyeluruh. Harap dicatat dan diperhatikan bahwa Kebijakan Privasi ini
								hanya berlaku bagi pengguna layanan Kami.
							</Typography>
						</Box>
						<Box mb={2} textAlign='justify'>
							<Typography variant='body1'>
								Kebijakan ini menjelaskan penanganan Kami atas data pribadi dan data
								lainnya dan bagaimana Kami berkomitmen untuk melindungi privasi Anda.
								Anda memahami bahwa Kami dapat menyediakan informasi tambahan yang
								spesifik untuk suatu produk atau layanan sebagai suplemen bagi Kebijakan
								ini agar selalu dapat memberi informasi kepada Anda mengenai tujuan
								pengumpulan data lainnya.
							</Typography>
						</Box>
						<Box mb={2} textAlign='justify'>
							<Typography variant='body1'>
								Kami menyarankan Anda untuk mengunjungi halaman ini dan membaca
								Kebijakan ini secara berkala.
							</Typography>
						</Box>
						<Box mt={2}>
							<ol className={classes.contentList}>
								<li>
									<b>Penggunaan data pribadi Anda </b>
									<ol>
										<li>
											<span>1.1</span>
											<b>Pengumpulan dan Pemanfaatan data Anda</b>
											<p>
												{' '}
												Pembuatan akun, dan pembelian atau penjualan atau mendaftarkan
												produk atau layanan Jika Anda mengirimkan permintaan untuk membuat
												akun atau untuk membeli produk di www.mbiz.co.id atau menggunakan
												layanan Kami, Kami dapat meminta Anda untuk menyediakan informasi,
												seperti nama perusahaan Anda, anggaran dasar perusahaan Anda,
												nama-nama yang memiliki otorisasi pada Perusahaan Anda termasuk
												kartu identitas milik orang-orang tersebut, alamat email, alamat
												pengiriman, nomor telepon, informasi produk yang dibutuhkan, dan
												izin-izin yang perusahaan Anda miliki, termasuk tetapi tidak
												terbatas pada Surat Keterangan Domisili Perusahaan, Nomor Pokok
												Wajib Pajak, Surat Keterangan Pengusaha Kena Pajak, Tanda Daftar
												Perusahaan, Surat Izin Usaha Perdagangan dan/atau surat izin usaha
												lainnya (“<b>Data Anda</b>”), dengan tujuan untuk
												mengimplementasikan pembelian produk Anda, memberikan pelayanan
												kepada Anda, membuat pengubahan atas permintaan Anda, menyediakan
												dukungan teknis atau pembaruan resmi, dan lainnya.
											</p>
											<p>
												Atas hal tersebut diatas, Anda dengan ini menyatakan setuju untuk
												memberikan Data Anda dan/atau data lainnya kepada Kami untuk
												digunakan lebih lanjut sesuai dengan Kebijakan ini.
											</p>
											<b>Promosi atau survei</b>
											<p>
												{' '}
												Anda menyetujui bahwa kami dapat meminta Anda untuk menyediakan
												informasi yang relevan, seperti nomor telepon dan alamat email,
												untuk bisa menghubungi Anda, mengirim informasi tentang produk dan
												layanan yang mungkin Anda minati, atau mengundang Anda ke promosi
												atau survei Kami. Anda dapat meminta Kami untuk tidak menggunakan
												Data Anda untuk promosi atau survey dengan cara menghubungi Customer
												Care.
											</p>
											<b>Statistik</b>
											<p>
												Kami dapat mengumpulkan informasi tertentu untuk analisis, termasuk
												nama perangkat, versi perangkat, nomor identifikasi perangkat,
												informasi perkiraan lokasi geografis (seperti ID area tempat
												perangkat Anda berada), ID jaringan penyedia layanan Anda, cara Anda
												menggunakan situs, dan alamat IP yang Anda gunakan untuk mengakses
												Internet. Kami mengumpulkan dan memproses informasi tersebut untuk
												memberi Anda pengalaman pengguna yang lebih personal dan untuk
												menyesuaikan konten, meningkatkan produk kami, menganalisis
												efisiensi operasi bisnis kami, menganalisis pembagian pasar kami,
												meningkatkan keamanan dari jaringan dan layanan Kami, dan
												mengaktifkan layanan kepada Anda.{' '}
											</p>
											<b>Permasalahan</b>
											<p>
												{' '}
												Jika akun Anda mengalami pemasalahan, Anda dapat memilih untuk
												mengirim informasi pemasalahan tersebut ke Kami sehingga Kami
												dan/atau mitra Kami dapat memberikan layanan bantuan kepada Anda.
												Anda setuju bahwa Kami dapat mengumpulkan informasi yang relevan
												tentang masalah tersebut.{' '}
											</p>
											<b>Penyimpanan data</b>
											<p>
												Beberapa layanan yang Kami berikan memungkinkan Anda untuk menyimpan
												data. Kami dapat mengumpulkan dan menyimpan data yang Anda unggah
												atau unduh serta data yang diperlukan untuk mengimplementasikan
												unggahan dan unduhan tersebut.{' '}
											</p>
										</li>
										<li>
											<span>1.2</span>
											<b>Lokasi</b>
											<p>
												{' '}
												Saat Anda mengakses layanan atau mencari informasi berdasarkan suatu
												lokasi spesifik, Kami dapat mengumpulkan, menggunakan, dan memproses
												informasi lokasi yang tepat atau perkiraan lokasi dari perangkat
												Anda. Hal ini adalah untuk mengizinkan Kami memberikan layanan yang
												lebih baik kepada Anda. Informasi yang dikumpulkan dapat menyertakan
												nomor identifikasi perangkat, jenis, model, dan informasi lokasi
												waktu-aktual (termasuk yang diperoleh melalui GPS, Wi-Fi, dan ID
												jaringan penyedia layanan Anda). Kami mengumpulkan informasi lokasi
												secara anonim untuk menyediakan dan meningkatkan produk dan layanan
												berbasis lokasi tersebut.
											</p>
										</li>
										<li>
											<span>1.3</span>
											<b>Pengumpulan dan penggunaan informasi lainnya </b>
											<p>
												Kami dapat mengumpulkan informasi statistik agregat, seperti jumlah
												pengunjung ke situs web Kami. Kami mengumpulkan informasi tersebut
												untuk dapat memahami bagaimana orang-orang menggunakan situs web,
												produk, atau layanan kami. Dengan cara ini, Kami dapat meningkatkan
												dan merampingkan layanan kami untuk dapat memenuhi kebutuhan
												pelanggan dengan lebih baik lagi. Kami dapat, atas kebijakannya
												sendiri, mengumpulkan, menggunakan, menransfer, atau mengungkapkan
												informasi non-pribadi untuk tujuan lain.{' '}
											</p>
										</li>
									</ol>
								</li>
								<li>
									<b>
										Penggunaan <i>cookie</i>
									</b>
									<p>
										{' '}
										Untuk memastikan situs web kami berfungsi dengan benar, kami terkadang
										meletakkan file data kecil di komputer atau perangkat seluler Anda,
										dikenal sebagai <i>cookie</i>. <i>Cookie</i> adalah sebuah file teks
										sederhana yang disimpan di komputer atau perangkat seluler Anda oleh
										suatu server situs web, dan hanya server itu yang akan dapat mengambil
										atau membaca konten <i>cookie</i> tersebut. Setiap <i>cookie</i>{' '}
										adalah unik untuk browser web Anda. <i>Cookie</i> mengandung informasi
										anonim, seperti pengenal unik, nama situs, angka, dan karakter.{' '}
										<i>Cookie</i> mengizinkan situs web untuk mengingat informasi, seperti
										preferensi Anda atau apa isi keranjang belanja Anda.
									</p>
									<p>
										{' '}
										Sebagian besar situs web besar atau penyedia layanan Internet
										melakukan hal ini untuk dapat meningkatkan pengalaman pengguna dengan
										cara mengizinkan situs web untuk &quot;mengingat&quot; Anda, baik saat
										melakukan kunjungan tunggal (menggunakan &quot;<i>cookie</i>{' '}
										sesi&quot;), atau untuk kunjungan berulang (menggunakan &quot;
										<i>cookie</i> persisten&quot;). <i>Cookie</i> membantu situs web
										mengingat pengaturan – bahasa, ukuran font, dan preferensi lain yang
										Anda gunakan untuk melihat situs web tersebut di komputer atau
										perangkat seluler Anda. Dengan cara ini, Anda tak perlu memasukkan
										kembali pengaturan tersebut kapan pun Anda kembali mengunjungi situs
										web kami. Jika suatu situs web tidak menggunakan <i>cookie</i>, situs
										web tersebut akan menganggap Anda sebagai pengunjung baru setiap kali
										Anda membuka halaman baru di situs web tersebut. Misalnya, saat Anda
										memasukkan rincian login Anda dan pindah ke halaman lain, situs web
										tersebut takkan mengenali Anda, dan takkan bisa membuat Anda tetap
										masuk ke situs web tersebut. <i>Cookie</i> kami tidak digunakan untuk
										mengidentifikasi Anda secara pribadi. Mereka hanya digunakan untuk
										membuat situs web tersebut melayani Anda dengan lebih baik lagi.
									</p>
									<p>
										{' '}
										Anda dapat menghapus semua <i>cookie</i> yang sudah ada di komputer
										Anda dan mengatur sebagian besar browser untuk memblokir <i>cookie</i>
										. Namun, jika Anda melakukannya, Anda mungkin harus menyesuaikan
										beberapa pengaturan preferensi secara manual setiap kali Anda
										mengunjungi situs web kami. Kami tidak menggunakan <i>cookie</i> untuk
										tujuan selain dari yang disebutkan di sini dan tidak mengumpulkan data
										pribadi apa pun untuk tujuan lain.
									</p>
								</li>
								<li>
									<b>Pengungkapan Data Anda.</b>
									<p>
										Kami tidak akan pernah menjual Data Anda kepada pihak ketiga tanpa
										sepengetahuan Anda. Namun, Kami dapat mengungkapkan Data Anda kepada:
									</p>
									<ol>
										<li>
											<span>(i)</span>
											<b>
												<u>
													Afiliasi Kami atau pihak ketiga yang bekerja sama secara resmi
													dengan Kami.
												</u>
											</b>
											<p>
												{' '}
												Kami mungkin perlu memberikan layanan tertentu kepada Anda melalui
												beberapa pihak yang bekerjasama dengan Kami. Oleh karena itu, Kami
												dapat membagikan beberapa informasi pribadi Anda kepada pihak yang
												bekerjasama tersebut untuk memperkaya layanan kami dan meningkatkan
												keseluruhan pengalaman penggunaan Anda. Misalnya, saat Anda
												memutuskan untuk membeli produk di situs web Kami, Kami akan perlu
												mengirimkan informasi Data Anda ke pihak yang bekerjasama dengan
												kami untuk mengizinkan mereka mengirimkan produk tersebut kepada
												Anda atau memberikan layanan lain kepada Anda. Kami melarang semua
												afiliasi Kami atau pihak ketiga yang berwenang untuk menggunakan
												informasi pribadi yang dibagikan tersebut untuk tujuan lain.
											</p>
										</li>
										<li>
											<span>(ii)</span>
											<b>
												<u>Badan Pemerintah berdasarkan ketentuan hukum</u>
											</b>
											<p>
												{' '}
												Kami dapat mengungkapkan informasi Data Anda saat diperlukan oleh
												undang-undang, tindakan hukum, litigasi, atau otoritas pemerintah,
												otoritas publik. Informasi Data Anda juga dapat diungkapkan ke
												pihak-pihak yang bertransaksi dalam keadaan di mana Kami terlibat
												dalam reorganisasi, merjer, atau likuidasi kepailitan.
											</p>
										</li>
										<li>
											<span>(iii)</span>
											<b>
												<u>
													Perusahaan lain setelah Anda dengan tegas telah menyetujui
													pengungkapan tersebut
												</u>
											</b>
											<p>
												{' '}
												Kami dapat membagikan informasi Data Anda dengan perusahaan lain.
											</p>
										</li>
									</ol>
								</li>
								<li>
									<b>Perubahan Data Anda</b>
									<p>
										Anda akan bertanggungjawab atas akurasi informasi Data Anda yang Anda
										kirimkan. Kami berusaha menjaga akurasi informasi Data Anda, dan Kami
										akan menghapus atau mengubah informasi tersebut sesuai permintaan
										Anda. Anda dapat menghubungi kami dengan mengirim email ke Customer
										Care. Sesuai dengan syarat dan ketentuan Kami, Anda dapat mengelola
										informasi Data Anda setiap saat, seperti misalnya informasi
										pendaftaran akun pribadi Anda.
									</p>
								</li>
								<li>
									<b>Perlindungan Data Anda</b>
									<p>
										Kami melakukan upaya pencegahan, termasuk namun tidak terbatas untuk,
										tindakan teknis, fisik, dan peraturan untuk melindungi informasi Data
										Anda dari akses tidak berwenang, pengungkapan, penggunaan, pengubahan,
										kerusakan, atau kehilangan. Meskipun kami melakukan tindakan-tindakan
										tersebut untuk melindungi Data Anda, Kami tidak menyatakan atau
										menjamin bahwa upaya keamanan yang Kami lakukan merupakan upaya
										keamanan yang 100% sempurna atau tidak dapat diretas.{' '}
									</p>
								</li>
								<li>
									<b>Pembaharuan</b>
									<p>
										Setiap perubahan yang Kami buat dalam Kebijakan ini akan ditampilkan
										dalam bentuk pemberitahuan di situs web Kami, Anda dengan ini
										menyatakan setuju bahwa Anda diwajibkan untuk melihat, membaca dan
										mempelajari pemberitahuan tersebut. Untuk kenyamanan Anda, Kami
										menyarankan Anda untuk mengunjungi halaman ini dan membaca Kebijakan
										ini secara berkala.
									</p>
								</li>
								<li>
									<b>Hubungi Kami</b>
									<p>
										{' '}
										Jika Anda memiliki pertanyaan lain, komentar, atau saran, harap
										kirimkan email ke Customer Care.
									</p>
								</li>
							</ol>
						</Box>
					</Box>
				</Container>
			</LayoutComponent>
		</>
	)
}

export default PrivacyPolicy
