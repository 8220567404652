import React, { useState } from 'react'
import LayoutComponent from 'Components/layout'
import {
	Container,
	Box,
	Grid,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputBase,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Breadcrumbs from 'Components/breadcrumbs'
import { navigate } from 'gatsby'
import xcheck from '../../../assets/images/icons/staticpage/xcheck.png'
import checked from '../../../assets/images/icons/staticpage/checked.png'
// import imgComplaint from '../../../assets/images/icons/staticpage/alur-pengembalian-produk.png'
import { ComplaintStyle } from './styles'

const Complaint = () => {
	const classes = ComplaintStyle()

	const CustomInput = withStyles((theme) => ({
		root: {
			'label + &': {
				marginTop: theme.spacing(3),
			},
		},
		input: {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
			},
		},
	}))(InputBase)

	const lang = {
		id: '/pages/id/complaint',
		en: '/pages/en/complaint',
	}
	const activeLink = window.location.pathname

	const handleChange = (event) => {
		navigate(event.target.value)
	}

	const [breadcrumbs] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Complaint Policy',
			link: '#',
		},
	])

	return (
		<>
			<LayoutComponent>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrumbs} />
					</Box>
					<Box>
						<Grid container justify='space-between'>
							<Grid item xs={6}>
								<Typography variant='h6'>
									Complaint Policy
								</Typography>
							</Grid>
							<Grid container item justify='flex-end' alignItems='center' xs={3}>
								<FormControl>
									<Select
										id='lang-product-return'
										value={activeLink}
										onChange={handleChange}
										input={<CustomInput fullWidth variant='outlined' color='primary' />}
									>
										<MenuItem value={lang['id']}>Bahasa Indonesia</MenuItem>
										<MenuItem value={lang['en']}>Bahasa Inggris</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box mt={2}>
						<ol className={classes.contentList}>
							<li>We do not accept complaints against products that have been received with the reason for changing their minds or ordering errors made by Customers intentionally or unintentionally, including product color selection errors, measurement errors, and errors in other detailed information.</li>
							<li>Customers can file a complaint against the product that has been received within 3 working days from the date of receipt of the product.</li>
							<li>The process of submitting a complaint can be done by filling out the following Google Form link: (<a href="https://docs.google.com/forms/d/e/1FAIpQLSfWe39pDNrXNMH2eO_ADJCsCOtRZrOjL9mx50oBnqhvbkJqpg/viewform?vc=0&c=0&r=028&gxlrw=1&gxl">Complaint Form</a>).</li>
							<li>We will conduct periodic checks for the submission of complaints that we have received.</li>
							<li>After submitting a complaint is approved by us, the customer can send back the product that is complained of and we will follow up on it following the applicable provisions.</li>
							<li>We have the right to refuse to complain about the product received if;</li>
							<ol type="a">
								<li>The application is submitted more than 3 working days after the date of receipt of the Product; or</li>
								<li>The product you want to complain about does not comply with the Terms.</li>
							</ol>
							<li>If the Customer intends to file a complaint within 3 working days after the date of receipt of the Product, the Customer may contact the relevant service center listed on the Product warranty card to process the exchange or return of the Product.</li>
							<li>Shipping Products back to us does not state that the claim is accepted. After we receive the product from the customer, we will check the condition of the product and the eligibility of the product to be claimed or returned following the provisions.</li>
							<li>We will notify the results of our inspection of product complaints to customers within 3 (three) working days after the date of receipt of the product by us.</li>
							<li>If based on the results of our inspection it is decided that the Product complies with the Terms and therefore deserves to be exchanged or returned ("Product Return"), We will send a replacement Product to the Customer and bear the cost of shipping the replacement Product.</li>
							<li> If based on the results of our inspection it is decided that the Product returned by the Customer is not following the Terms, then We have the right to return the Product to the Customer.</li>
							<li>Customers can contact Customer Service if  Customer has not received the claimed Product within 21 (twenty-one) working days from the date of notification of the inspection results, to obtain information regarding the delivery status of the product being complained of.</li>
							<li> If there is a disagreement or difference of opinion regarding the results of our inspection of the Product to be returned, and if the Customer does not contact Customer Service within 3 working days, the Customer will be deemed to have received the results of our inspection, and return the Product to the Customer.</li>
							<li>Products that have been claimed and received back by Customers will be treated the same as new Products, and therefore these Terms will apply the same to the replacement Products.</li>
							<p>Complaint Terms</p>
							<span>We will declare the eligibility of the Product when the Product has been received by us for the inspection proces.Product returns are subject to the following criteria:</span>
							<br />
							<div className={classes.returnTable}>
								<p>
									Criteria for Returned Product ( <img src={checked} width={16} /> =
									required, <img src={xcheck} width={16} /> = not required )
								</p>
								<table>
									<thead>
										<tr>
											<th width='30%'>Reason for Return</th>
											<th width='10%'>Seal is Intact</th>
											<th width='10%'>New Product</th>
											<th width='10%'>
												Package Complete
												<br />
												<span>(including box, accessories, gift)</span>
											</th>
											<th width='10%'>Original Box Packaging</th>
											<th width='10%'>Product is Not Broken</th>
											<th width='10%'>Label &amp; Tag</th>
											<th width='10%'>
												SN &amp; IMEI is the Same
												<br />
												<span>(for electronic)</span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Broken</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
										</tr>
										<tr>
											<td>Defect</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
										</tr>
										<tr>
											<td>Wrong product sent</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
										</tr>
										<tr>
											<td>Incomplete</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
										</tr>
										<tr>
											<td>Does not match description on website</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
										</tr>
										<tr>
											<td>Past expiration date (e.g. food)</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={checked} />
											</td>
											<td>
												<img width={27} src={xcheck} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p>Note:&nbsp;Product returns do not apply to the Product categories below unless the Product is declared damaged, malfunctioning, or experiencing shipping errors.</p>

							<ul>
								<li>Beauty product</li>
								<li>Foods, including those with and without preservatives</li>
								<li>Underwear</li>
								<li>Consumable products</li>
								<li>Vouchers, tickets.</li>
							</ul>
						</ol>
					</Box>
				</Container>
			</LayoutComponent>
		</>
	)
}

export default Complaint
