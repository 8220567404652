import React from 'react'
import { Paper, Typography, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import Styles from './styles'

const Component = (props) => {
	const classes = Styles()
	return (
		<Paper className={classes.cardContainer}>
			<GatsbyImage
				image={props.imgName}
				className={classes.cardImg}
				alt={props.alt}
			/>
			<Box className={classes.cardContentWrapper}>
				<Typography align='center' variant='h6'>
					{props.name}
				</Typography>
				<Typography align='center' variant='body1' gutterBottom>
					{props.title}
				</Typography>
				<Typography align='center' variant='body2' gutterBottom>
					{props.desc1}
				</Typography>
				<Typography align='center' variant='body2' gutterBottom>
					{props.desc2}
				</Typography>
			</Box>
		</Paper>
	)
}

Component.propTypes = {
	imgName: PropTypes.object,
	name: PropTypes.string,
	title: PropTypes.string,
	desc1: PropTypes.string,
	desc2: PropTypes.string,
	alt: PropTypes.string,
}

export default Component
