import React, { useRef, useEffect, useState } from 'react'
import styles from './styles'
import PropTypes from 'prop-types'
import Status from 'Components/status'
import { useSelector, useDispatch } from 'react-redux'
import {
	getDetailMou,
	saveMouItems,
	resetAction,
} from 'States/actions/MOU/detail'
import { withStyles } from '@material-ui/core/styles'
import {
	dateFormatFromOdoo,
	generateAddress,
	generateAddressCompany,
	getFileExtension,
	listExt,
} from '../../../utils/globalUtils'
import { filter } from 'lodash'
import QuantityInput from 'Components/quantityInput'
import LogTransaction from './logTransaction'
import API from '../../../utils/api'
import Modal from 'Components/modal'
import Breadcrumbs from 'Components/breadcrumbs'
import Skeleton from '@material-ui/lab/Skeleton'
import { requestDetailCompany } from 'States/actions/CompanyProfile/profileAction'
import {
	Box,
	Button,
	Container,
	Paper,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Collapse,
} from '@material-ui/core'
import Layout from 'Components/layout'
import Image from 'material-ui-image'
import { formatMoney } from '../../../utils/formatMoney'
import { rolePenyetuju, checkRole, rolePemohon } from '../../../utils/acl'
import PreviewImage from 'Components/ImageModal'
import { Pdf, PPT, Docx, Excel, BlankFile } from 'Components/svgs'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)

const initDataMou = {
	state: '',
	startDate: '',
	activedDate: '',
	validityDate: '',
	agreementTitle: '',
	amountUntaxed: '',
	agreementDescription: '',
	agreementMinValue: '',
	agreementMinQty: '',
	partnerName: '',
	partnerPicName: '',
	partnerAddress: '',
	partnerPicEmail: '',
	partnerPhone: '',
	companyName: '',
	userName: '',
	companyAddress: '',
	userEmail: '',
	companyPhone: '',
	attachmentIds: '',
	activedFrom: '',
	activedPartnerName: '',
	inactivedDate: '',
	id: '',
}

const Component = ({ mouNumber, navigate }) => {
	const classes = styles()
	const dispatch = useDispatch()
	const [eprocId, setEprocId] = useState(null)
	const [breadcrums, setBreadcrums] = useState([])
	const [visModalActivate, setVisModalActivate] = useState(false)
	const [activating, setActivating] = useState(false)
	const [alertSuccess, setAlertSuccess] = useState(false)
	const [orderline, setOrderLine] = useState([])
	const [showInput, setShowInput] = useState(false)
	const [mov, setMov] = useState(false)
	const [moq, setMoq] = useState(false)
	const [alertError, setAlertError] = useState(false)
	const [collapsed, setCollapse] = useState(false)
	const [disabled, setDisabled] = useState([])
	const [openImage, setOpenImage] = useState({})
	const pageSize = 10

	const [dataMou, setDataMou] = useState(initDataMou)

	// selector
	const detailMou = useSelector((state) => state.detailMou)
	const user = useSelector((state) => state.auth.data)
	const imageStorage = process.env.GATSBY_IMAGE_URL
	// const fileStorage = process.env.GATSBY_FILESTORAGE
	const company_profile = useSelector((state) => state.companyProfile.company)

	// acl
	const allowedPenyetuju = checkRole(`${rolePenyetuju}`, false)
	const allowedPemohon = checkRole(`${rolePemohon}`, false)

	const myRefs = useRef(null)

	const scrollToRef = (ref) => {
		setShowInput(true)
		window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' })
	}

	useEffect(() => {
		if (detailMou.data != null && detailMou.isLoading == false) {
			let dataTemp = dataMou
			dataTemp.state = detailMou.data.state
			dataTemp.startDate =
				dateFormatFromOdoo(detailMou.data.start_date, 'dd MMMM yyyy') || '-'
			dataTemp.activedDate =
				dateFormatFromOdoo(detailMou.data.actived_date, 'dd MMMM yyyy') || '-'
			dataTemp.validityDate =
				dateFormatFromOdoo(detailMou.data.validity_date, 'dd MMMM yyyy') || '-'
			dataTemp.agreementTitle = detailMou.data.agreement_title
			dataTemp.amountUntaxed = detailMou.data.amount_untaxed
			dataTemp.agreementDescription = detailMou.data.agreement_description
			dataTemp.agreementMinValue = detailMou.data.agreement_min_value
			dataTemp.agreementMinQty = detailMou.data.agreement_min_qty
			dataTemp.partnerName = detailMou.data.partner_id?.name || null
			dataTemp.partnerPicName = detailMou.data.partner_pic_id?.name || '-'
			dataTemp.partnerAddress = detailMou.data.partner_id.street
			dataTemp.partnerAddressLocation = detailMou.data.partner_id.region_id.complete_name
			dataTemp.partnerPicEmail = detailMou.data.partner_pic_id?.email || '-'
			dataTemp.partnerPhone = detailMou.data.partner_id?.phone || null
			dataTemp.companyName = detailMou.data.company_id?.name || null
			dataTemp.userName = detailMou.data.user_id?.name || null
			dataTemp.companyAddress = detailMou.data.company_id.street
			dataTemp.companyAddressLocation = detailMou.data.company_id.region_id.complete_name
			dataTemp.userEmail = detailMou.data.user_id?.email || '-'
			dataTemp.companyPhone = detailMou.data.company_id?.phone || null
			dataTemp.attachmentIds = detailMou.data.attachment_ids || []
			dataTemp.activedFrom = detailMou.data.actived_from
			dataTemp.activedPartnerName = detailMou.data.actived_partner_id?.name
			dataTemp.inactivedDate = detailMou.data.inactived_date
			dataTemp.id = detailMou.data.id
			setDataMou(dataTemp)
		}
	}, [detailMou.data, detailMou.isLoading])

	// get eproc user
	useEffect(() => {
		setDataMou({ ...initDataMou })
		getMou()
		dispatch(requestDetailCompany())
		API.get(`/approvers/list/eproc?query={"user_id":"${user.data.id}"}`)
			.then((res) => {
				if (res.data.data.items) {
					setEprocId(res.data.data.items[0].user_id)
				}
			})
			.catch((err) => console.log(err))
		return () => {
			dispatch(resetAction())
		}
	}, [mouNumber])

	useEffect(() => {
		handleSetBreadcrums(mouNumber)
	}, [mouNumber])

	// save orderline items data
	useEffect(() => {
		if (detailMou.data) {
			let temp_order_line = detailMou.data.line_ids
			temp_order_line.forEach((val) => {
				val.qty = 0
				val.total = 0
			})
			setOrderLine(temp_order_line)
		}
	}, [detailMou.data])

	const renderButton = (status) => {
		if (status == 'confirm') {
			if (
				company_profile.isLoading == false &&
				company_profile.data.is_pr_active
			) {
				if (user.data.id == eprocId) {
					return (
						<React.Fragment>
							<Button
								id='btn-back'
								variant='outlined'
								color='secondary'
								size='small'
								onClick={() => navigate('/dashboard/mou')}
								className={`${classes.kembali} ${classes.btnWhite} ${classes.buttonWidth}`}
							>
								<Typography variant='caption'>Kembali</Typography>
							</Button>
							<Button
								id='btn-active'
								variant='contained'
								color='secondary'
								size='small'
								onClick={() => setVisModalActivate(true)}
								className={`${classes.buttonWidth}`}
							>
								<Typography variant='caption'>Aktifkan MOU</Typography>
							</Button>
						</React.Fragment>
					)
				}
			} else if (allowedPenyetuju) {
				return (
					<React.Fragment>
						<Button
							id='btn-back'
							variant='outlined'
							color='secondary'
							size='small'
							onClick={() => navigate('/dashboard/mou')}
							className={`${classes.kembali} ${classes.btnWhite} ${classes.buttonWidth}`}
						>
							<Typography variant='caption'>Kembali</Typography>
						</Button>
						<Button
							id='btn-activate'
							variant='contained'
							color='secondary'
							size='small'
							onClick={() => setVisModalActivate(true)}
							className={`${classes.buttonWidth}`}
						>
							<Typography variant='caption'>Aktifkan MOU</Typography>
						</Button>
					</React.Fragment>
				)
			}
		} else if (status == 'active') {
			if (allowedPemohon) {
				if (!showInput) {
					return (
						<React.Fragment>
							<Button
								id='btn-back'
								variant='outlined'
								color='secondary'
								size='small'
								onClick={() => navigate('/dashboard/mou')}
								className={`${classes.kembali} ${classes.btnWhite} ${classes.buttonWidth}`}
							>
								<Typography variant='caption'>Kembali</Typography>
							</Button>
							<Button
								id='btn-next'
								variant='contained'
								color='secondary'
								size='small'
								onClick={() => scrollToRef(myRefs)}
								className={`${classes.buttonWidth}`}
							>
								<Typography variant='caption'>Lanjutkan Transaksi</Typography>
							</Button>
						</React.Fragment>
					)
				}

				return (
					<Button
						id='btn-back'
						variant='outlined'
						color='secondary'
						size='small'
						onClick={() => setShowInput(false)}
						className={`${classes.btnWhite} ${classes.buttonWidth}`}
					>
						<Typography variant='caption'>Kembali</Typography>
					</Button>
				)
			}
		}

		return (
			<Button
				id='btn-back'
				variant='outlined'
				color='secondary'
				size='small'
				onClick={() => navigate('/dashboard/mou')}
				className={`${classes.kembali} ${classes.btnWhite} ${classes.buttonWidth}`}
			>
				<Typography variant='caption'>Kembali</Typography>
			</Button>
		)
	}

	const renderButtonLanjutkanTransaksi = (status) => {
		if (status == 'active') {
			if (allowedPemohon) {
				if (showInput) {
					return (
						<React.Fragment>
							<Button
								id='btn-trx'
								variant='contained'
								color='secondary'
								size='small'
								disabled={disabled.length < 1}
								onClick={() => hanldeTransaction()}
								className={`${classes.buttonWidth}`}
							>
								<Typography variant='caption'>Lakukan Transaksi</Typography>
							</Button>
						</React.Fragment>
					)
				}
			}
		}
	}

	const activateMou = () => {
		setActivating(true)
		API.post(`/mou/activate/${dataMou.id}`)
			.then(() => {
				setActivating(false)
				setVisModalActivate(false)
				setAlertSuccess(true)
			})
			.catch(() => {
				setActivating(false)
			})
	}

	const getMou = () => {
		dispatch(getDetailMou(mouNumber))
		setAlertSuccess(false)
	}

	const generateVariant = (str) => {
		let joinVar = ''
		str.forEach((variant) => (joinVar += variant.name + ', '))
		return joinVar.split(',').slice(0, -1).join(',')
	}

	// breadcrums function
	const handleSetBreadcrums = (value) => {
		let tmp_breadcrums = [
			{
				segment: 'Home',
				link: '/dashboard/',
			},
			{
				segment: 'MOU',
				link: '/dashboard/mou',
			},
		]
		setBreadcrums([
			...tmp_breadcrums,
			{
				segment: value,
				link: '#',
			},
		])
	}

	const handleIncrease = (index, remaining_qty) => {
		const temp_data = orderline
		if (temp_data[index].qty < remaining_qty) {
			temp_data[index].qty += 1
			temp_data[index].total = temp_data[index].qty * temp_data[index].price_unit
			setOrderLine([...temp_data])
			setDisabled(() => filter(temp_data, (item) => item.qty >= 1))
			return
		}
		return false
	}

	const hanldeTransaction = () => {
		let { agreementMinQty, agreementMinValue } = dataMou
		let totalTrx = 0
		let totalQty = 0

		orderline.map((item) => {
			totalTrx += item.total
			totalQty += item.qty
		})

		if (totalTrx < agreementMinValue && totalQty < agreementMinQty) {
			setMoq(true)
			setMov(true)
			setAlertError(true)
		} else if (totalTrx < agreementMinValue) {
			setMov(true)
			setMoq(false)
			setAlertError(true)
		} else if (totalQty < agreementMinQty) {
			setMoq(true)
			setMov(false)
			setAlertError(true)
		} else {
			setMoq(false)
			setMov(false)
			dispatch(saveMouItems(disabled))
			navigate(`/dashboard/mou/form/${mouNumber}`)
		}
	}

	const handleDecrease = (index) => {
		const temp_data = orderline
		temp_data[index].qty -= 1
		if (temp_data[index].qty < 0) {
			temp_data[index].qty = 0
		}
		temp_data[index].total = temp_data[index].qty * temp_data[index].price_unit
		setDisabled(() => filter(temp_data, (item) => item.qty >= 1))
		setOrderLine([...temp_data])
	}

	const handleInputChange = (value, index, remaining_qty) => {
		let temp_data = orderline
		if (value > remaining_qty) {
			temp_data[index].qty = remaining_qty
			temp_data[index].total = temp_data[index].qty * temp_data[index].price_unit
			setDisabled(() => filter(temp_data, (item) => item.qty >= 1))
			setOrderLine([...temp_data])
			return
		}
		temp_data[index].qty = value
		temp_data[index].total = temp_data[index].qty * temp_data[index].price_unit
		setDisabled(() => filter(temp_data, (item) => item.qty >= 1))
		setOrderLine([...temp_data])
	}

	const activatedBy = () => {
		const { activedFrom, activedPartnerName } = dataMou
		let activated_by = 'PT Brilliant Ecommerce Berjaya (Mbiz)'

		if (activedFrom == 'mbiz') {
			return activated_by
		}

		activated_by = activedPartnerName
		return activated_by
	}

	const renderMouStatus = (status) => {
		if (status == 'done') {
			return <Status status={'MOUDone'} />
		}

		return <Status status={status} />
	}

	const renderInactiveDate = (status) => {
		if (status == 'inactive') {
			return dateFormatFromOdoo(dataMou.inactivedDate, 'dd MMMM yyyy')
		}
		return '-'
	}

	const downloadFile = (linkSource, filename) => {
		API({
			url: `${imageStorage}/500x500/erp/${linkSource}`,
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', filename)
			document.body.appendChild(link)
			link.click()
		})
	}

	const getFileThumb = (filename, sname) => {
		let file_ext = getFileExtension(filename)
		let ext = file_ext.toLowerCase()
		if (listExt.excel.includes(ext)) {
			return <Excel className={classes.fileThumb} />
		} else if (listExt.doc.includes(ext)) {
			return <Docx className={classes.fileThumb} />
		} else if (listExt.ppt.includes(ext)) {
			return <PPT className={classes.fileThumb} />
		} else if (listExt.pdf.includes(ext)) {
			return <Pdf className={classes.fileThumb} />
		} else if (listExt.image.includes(ext)) {
			return <Image src={`${imageStorage}/180x180/erp/${sname}`} />
		}

		return <BlankFile className={classes.blankFileThumb} />
	}

	const handleFile = (filename, sname, index) => {
		let file_ext = getFileExtension(filename)
		let ext = file_ext.toLowerCase()
		if (listExt.image.includes(ext)) {
			return (
				<Typography
					variant='body2'
					className={classes.fileName}
					onClick={() => setOpenImage({ ...openImage, [index]: true })}
				>
					<strong>{filename}</strong>
				</Typography>
			)
		} else if (listExt.pdf.includes(ext)) {
			return (
				<Typography variant='body2' className={classes.fileName}>
					<a target='blank' href={`${window.location.origin}/document/${sname}`}>
						<strong>{filename}</strong>
					</a>
				</Typography>
			)
		} else {
			return (
				<Typography
					variant='body2'
					className={classes.fileName}
					onClick={() => downloadFile(sname, filename)}
				>
					<strong>{filename}</strong>
				</Typography>
			)
		}
	}

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>

				<Box mb={3}>
					<Typography variant='h6'>Rincian MOU</Typography>
					<Paper className={classes.formRfqTop} elevation={0}>
						<Box py={2} px={3}>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={4}>
													<Typography variant='body2'>
														<strong>Status</strong>
													</Typography>
												</Grid>
												<Grid item xs={8}>
													<Box width={120}>
														{detailMou.isLoading ? (
															<Skeleton variant='text' />
														) : (
															renderMouStatus(dataMou.state)
														)}
													</Box>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={4}>
													<Typography variant='body2'>
														<strong>No. MOU</strong>
													</Typography>
												</Grid>
												<Grid item xs={8}>
													<Typography variant='body2'>
														{mouNumber}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={4}>
													<Typography variant='body2'>
														<strong>Judul MOU</strong>
													</Typography>
												</Grid>
												<Grid item xs={8}>
													<Typography variant='body2'>
														{detailMou.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataMou.agreementTitle
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={4}>
													<Typography variant='body2'>
														<strong>Deskripsi</strong>
													</Typography>
												</Grid>
												<Grid item xs={8}>
													<Typography variant='body2'>
														{detailMou.isLoading ? (
															<Skeleton variant='text' />
														) : (
															dataMou.agreementDescription
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={4}>
													<Typography variant='body2'>
														<strong>Ketentuan Pembelian</strong>
													</Typography>
												</Grid>
												<Grid container item xs={8}>
													<Grid item lg={7} md={7} sm={7}>
														<Typography variant='body2'>MOV (Minimum Order Value):</Typography>
														<Typography variant='body2'>
															MOQ (Minimum Order Quantity):
														</Typography>
													</Grid>
													<Grid
														item
														lg={5}
														md={5}
														sm={5}
														container
														alignItems='flex-end'
														direction='column'
													>
														<Typography variant='body2'>
															{detailMou.isLoading ? (
																<Skeleton variant='text' />
															) : (
																formatMoney(dataMou.agreementMinValue)
															)}
														</Typography>
														<Typography variant='body2'>
															{detailMou.isLoading ? (
																<Skeleton variant='text' />
															) : (
																dataMou.agreementMinQty
															)}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={4}>
													<Typography variant='body2'>
														<strong>Diaktifkan Oleh</strong>
													</Typography>
												</Grid>
												<Grid item xs={8}>
													<Typography variant='body2'>
														{detailMou.isLoading ? (
															<Skeleton variant='text' />
														) : (
															activatedBy() || '-'
														)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={4}>
													<Typography variant='body2'>
														<strong>Tanggal MOU</strong>
													</Typography>
												</Grid>
												<Grid container item xs={8}>
													<Grid item xs={6}>
														<Typography variant='body2'>
															<strong>MOU Dimulai</strong>
														</Typography>
														<Typography variant='body2'>
															{detailMou.isLoading ? (
																<Skeleton variant='text' />
															) : (
																dataMou.startDate
															)}
														</Typography>
														<Typography className={classes.statusPadding} variant='body2'>
															<strong>Aktif</strong>
														</Typography>
														<Typography variant='body2'>
															{detailMou.isLoading ? (
																<Skeleton variant='text' />
															) : (
																dataMou.activedDate
															)}
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant='body2'>
															<strong>Berlaku Hingga</strong>
														</Typography>
														<Typography variant='body2'>
															{detailMou.isLoading ? (
																<Skeleton variant='text' />
															) : (
																dataMou.validityDate
															)}
														</Typography>
														<Typography className={classes.statusPadding} variant='body2'>
															<strong>Tidak Aktif</strong>
														</Typography>
														<Typography variant='body2'>
															{detailMou.isLoading ? (
																<Skeleton variant='text' />
															) : (
																renderInactiveDate(dataMou.state)
															)}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
											<Grid container spacing={4} alignItems="flex-start">
												<Grid item xs={4}>
													<Typography variant='body2'>
														<strong>Total Nilai MOU</strong>
													</Typography>
												</Grid>
												<Grid item xs={8}>
													<Typography variant='body2'>
														{detailMou.isLoading ? (
															<Skeleton variant='text' />
														) : (
															formatMoney(dataMou.amountUntaxed)
														)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Box>

				{/* aa */}
				<Box mb={3}>
					<Grid container spacing={3}>
						{/* pihak pertama */}
						<Grid item lg={6} md={6} sm={6}>
							<Paper elevation={0} style={{ height: '100%' }}>
								<Box py={2} px={3}>
									<Box mb={2}>
										<Typography variant='body1' align='center'>
											<strong style={{ fontSize: '1.2rem' }}>Pihak Pertama</strong>
										</Typography>
									</Box>
									<Grid container spacing={2}>
										<Grid item lg={12} md={12} sm={12}>
											<Typography variant='body2'>
												{detailMou.isLoading ? (
													<Skeleton variant='text' />
												) : (
													<strong>{dataMou.partnerName}</strong>
												)}
											</Typography>
										</Grid>
										<Grid item lg={12} md={12} sm={12} container>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													<strong>Nama PIC</strong>
												</Typography>
											</Grid>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.partnerPicName
													)}
												</Typography>
											</Grid>
										</Grid>

										<Grid item lg={12} md={12} sm={12} container>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													<strong>Alamat Lengkap</strong>
												</Typography>
											</Grid>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.partnerAddress
													)}
												</Typography>
												<Typography variant='body2' className={classes.textLocation}>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.partnerAddressLocation
													)}
												</Typography>
											</Grid>
										</Grid>

										<Grid item lg={12} md={12} sm={12} container>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													<strong>Email</strong>
												</Typography>
											</Grid>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.partnerPicEmail
													)}
												</Typography>
											</Grid>
										</Grid>

										<Grid item lg={12} md={12} sm={12} container>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													<strong>No. Telepon</strong>
												</Typography>
											</Grid>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.partnerPhone
													)}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>
						{/* end of pihak pertama */}

						{/* pihak kedua */}
						<Grid item lg={6} md={6} sm={6}>
							<Paper elevation={0} style={{ height: '100%' }}>
								<Box py={2} px={3}>
									<Box mb={2}>
										<Typography variant='body1' align='center'>
											<strong style={{ fontSize: '1.2rem' }}>Pihak Kedua</strong>
										</Typography>
									</Box>

									<Grid container spacing={2}>
										<Grid item lg={12} md={12} sm={12}>
											<Typography variant='body2'>
												{detailMou.isLoading ? (
													<Skeleton variant='text' />
												) : (
													<strong>{dataMou.companyName}</strong>
												)}
											</Typography>
										</Grid>
										<Grid item lg={12} md={12} sm={12} container>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													<strong>Nama PIC</strong>
												</Typography>
											</Grid>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.userName
													)}
												</Typography>
											</Grid>
										</Grid>

										<Grid item lg={12} md={12} sm={12} container>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													<strong>Alamat Lengkap</strong>
												</Typography>
											</Grid>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.companyAddress
													)}
												</Typography>
												<Typography variant='body2' className={classes.textLocation}>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.companyAddressLocation
													)}
												</Typography>
											</Grid>
										</Grid>

										<Grid item lg={12} md={12} sm={12} container>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													<strong>Email</strong>
												</Typography>
											</Grid>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.userEmail
													)}
												</Typography>
											</Grid>
										</Grid>

										<Grid item lg={12} md={12} sm={12} container>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													<strong>No. Telepon</strong>
												</Typography>
											</Grid>
											<Grid item lg={6} md={6} sm={6}>
												<Typography variant='body2'>
													{detailMou.isLoading ? (
														<Skeleton variant='text' />
													) : (
														dataMou.companyPhone
													)}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>
						{/* end of pihak kedua */}
					</Grid>
				</Box>
				{/* end of aaa */}

				{/* document */}
				<Box mb={3}>
					<Paper elevation={0}>
						<Box pt={2} px={3}>
							<Collapse in={collapsed} collapsedHeight={280}>
								<Box mb={2}>
									<Typography variant='body1'>
										<strong style={{ fontSize: '1.2rem' }}>Dokumen</strong>
									</Typography>
								</Box>
								<Grid container spacing={2}>
									{detailMou.isLoading
										? [1, 2, 3, 4].map((item) => (
												<Grid item key={item} lg={3} md={3} sm={3}>
													<Skeleton variant='rect' height={250} width={'90%'} />
												</Grid>
										  ))
										: dataMou.attachmentIds && dataMou.attachmentIds.length != 0
										? dataMou.attachmentIds.map((item, index) => (
												<Grid item key={index} lg={3} spacing={1}>
													<Grid item lg={12} md={12} sm={12}>
														<Box mb={1.5}>
															<Typography variant='body2'>
																<strong>File {index + 1}</strong>
															</Typography>
														</Box>
													</Grid>
													<Grid container direction='column'>
														<Grid item xs={12}>
															<Box width={150} height={150} overflow='hidden'>
																{getFileThumb(item.name, item.store_fname)}
															</Box>
														</Grid>
														<Grid item xs={12}>
															<Box pt={2} pb={2}>
																{handleFile(item.name, item.store_fname, index)}
															</Box>
														</Grid>
													</Grid>

													{/*<Grid item lg={8} md={8} sm={8}>
														<Box mb={1.2}>
															<Typography variant='body2'>
																<strong>Deskripsi Dokumen</strong>
															</Typography>
														</Box>
														<Typography variant='body2'>{item.description}</Typography>
													</Grid>*/}
													<PreviewImage
														open={openImage[index]}
														handleClose={() => setOpenImage({ ...openImage, [index]: false })}
														title={item.name}
													>
														<img src={`${imageStorage}/500x500/erp/${item.store_fname}`} />
													</PreviewImage>
												</Grid>
										  ))
										: null}
								</Grid>
							</Collapse>
						</Box>
						<Box px={5} pb={2}>
							{detailMou.isLoading ? null : (dataMou.attachmentIds &&
									dataMou.attachmentIds.length) > 4 ? (
								<Typography
									onClick={() => setCollapse(!collapsed)}
									color='secondary'
									variant='body2'
									align='right'
									style={{ cursor: 'pointer' }}
								>
									<strong>{!collapsed ? 'Lihat Selengkapnya' : 'Lebih Sedikit'}</strong>
								</Typography>
							) : null}
						</Box>
					</Paper>
				</Box>
				{/* end of document */}

				{/* bottom form */}
				<Box mb={3}>
					<Paper elevation={0}>
						<Box py={2} px={3}>
							{/* table */}
							<Box ref={myRefs}>
								<Table classes={{ root: classes.tableRoot }}>
									<TableHead>
										<TableRow>
											<TableCell align='center' style={{ width: 65 }}>
												<strong>No</strong>
											</TableCell>
											<TableCell>
												<strong>Produk</strong>
											</TableCell>
											<TableCell align='center'>
												<strong>Kuantitas</strong>
											</TableCell>
											<TableCell align='center'>
												<strong>Harga / Unit</strong>
											</TableCell>
											{dataMou.state != 'confirm' && (
												<TableCell align='center'>
													<strong>Tersisa</strong>
												</TableCell>
											)}
											{showInput ? (
												<TableCell align='center'>
													<strong>Masukkan Jumlah Pembelian</strong>{' '}
												</TableCell>
											) : null}
										</TableRow>
									</TableHead>

									<TableBody>
										{detailMou.isLoading ? (
											<TableRow key={'skeleton'}>
												<CustomCell>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell>
													<Box display='flex' flexDirection='row'>
														<Box overflow='hidden' width={60} height={60}>
															<Skeleton variant='rect' width={60} height={60} />
														</Box>
														<Box
															ml={1}
															display='flex'
															flexDirection='column'
															justifyContent='space-between'
															style={{ width: '100%' }}
														>
															<Box>
																<Typography variant='body2'>
																	<Skeleton variant='text' />
																</Typography>
																<Typography variant='caption'>
																	<Skeleton variant='text' />
																</Typography>
															</Box>
															<Typography variant='caption'>
																<Skeleton variant='text' />
															</Typography>
														</Box>
													</Box>
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
												<CustomCell align='center'>
													<Skeleton variant='text' />
												</CustomCell>
											</TableRow>
										) : (
											orderline.length != 0 &&
											orderline.map((item, index) => (
												<TableRow key={item.id}>
													<CustomCell>{index + 1}</CustomCell>
													<CustomCell>
														<Box display='flex' flexDirection='row' alignItems='center'>
															<Box overflow='hidden' width={60} height={60}>
																<Image
																	src={
																		imageStorage +
																		'/180x180/erp/' +
																		item.product_id.product_tmpl_id.image_128_filestore
																	}
																/>
															</Box>
															<Box
																ml={1}
																display='flex'
																flexDirection='column'
																justifyContent='space-between'
															>
																<Box maxWidth={300}>
																	<Typography variant='body2'>
																		{item.product_id.sku_no}
																	</Typography>
																	<Typography variant='caption'>{item.name}</Typography>
																</Box>
																<Typography variant='caption'>
																	varian:{' '}
																	{generateVariant(
																		item.product_id.product_template_attribute_value_ids
																	) || '-'}
																</Typography>
															</Box>
														</Box>
													</CustomCell>
													<CustomCell align='center'>{item.original_uom_qty}</CustomCell>
													<CustomCell align='center'>
														{formatMoney(item.price_unit)}
													</CustomCell>
													{dataMou.state != 'confirm' && (
														<CustomCell align='center'>{item.remaining_qty}</CustomCell>
													)}
													{showInput ? (
														<CustomCell>
															<QuantityInput
																value={item.qty}
																maxValue={item.remaining_qty}
																minValue={0}
																onIncrease={() => handleIncrease(index, item.remaining_qty)}
																onDecrease={() => handleDecrease(index)}
																onInputChange={(value) =>
																	handleInputChange(value, index, item.remaining_qty)
																}
															/>
														</CustomCell>
													) : null}
												</TableRow>
											))
										)}
									</TableBody>
								</Table>
							</Box>
							<Box className={classes.containerButton} mt={2}>
								{detailMou.isLoading || company_profile.isLoading
									? null
									: renderButtonLanjutkanTransaksi(dataMou.state)}
							</Box>
							{/* end of table */}
						</Box>
					</Paper>
				</Box>

				{/* log transaksi */}
				{detailMou.isLoading ? null : dataMou.state != 'confirm' ? (
					<LogTransaction mouId={dataMou.id} pageSize={pageSize} />
				) : null}

				{/* end of log transaksi */}

				<Box className={classes.containerButton} mt={2}>
					{detailMou.isLoading || company_profile.isLoading
						? null
						: renderButton(dataMou.state)}
				</Box>
			</Container>

			{/* MODAL */}
			<Modal
				icon='question'
				open={visModalActivate}
				onClose={() => setVisModalActivate(false)}
			>
				<Typography variant='body2' className={classes.font19}>
					<strong>Apakah Anda ingin mengaktifkan MOU?</strong>
				</Typography>
				<Box mt={2} display='flex' justifyContent='space-evenly'>
					<Button
						className={`${classes.buttonDanger} ${classes.buttonWidth}`}
						onClick={() => setVisModalActivate(false)}
						size='small'
					>
						Batal
					</Button>

					<Button
						variant='contained'
						color='secondary'
						className={`${classes.buttonWidth}`}
						onClick={() => activateMou()}
						disabled={activating}
						size='small'
					>
						Ya
					</Button>
				</Box>
			</Modal>
			{/* end of MODAL */}

			{/* alert success */}
			<Modal open={alertSuccess} icon='success'>
				<Box mb={2.5}>
					<Typography variant='body2'>
						<strong>MOU Berhasil Diaktifkan</strong>
					</Typography>
				</Box>
				<Button
					size='small'
					variant='contained'
					color='secondary'
					style={{ width: 77, margin: 'auto' }}
					onClick={() => getMou()}
				>
					<Typography variant='caption'>OK</Typography>
				</Button>
			</Modal>
			{/* end of alert success */}

			{/* alert error */}
			<Modal open={alertError} icon='error'>
				<Typography variant='body2'>
					<strong>Error</strong>
				</Typography>
				<Typography variant='body2'>
					<strong>Pesanan Anda belum memenuhi ketentuan </strong>
				</Typography>
				{mov ? (
					<Typography variant='body2'>
						-{' '}
						<i>
							Minimum Order Value (MOV){' '}
							{!detailMou.isLoading && formatMoney(dataMou.agreementMinValue)}
						</i>
					</Typography>
				) : null}
				{moq ? (
					<Typography variant='body2'>
						-{' '}
						<i>
							Minimum Order Quantity (MOQ){' '}
							{!detailMou.isLoading && dataMou.agreementMinQty}
						</i>
					</Typography>
				) : null}
				<Box mt={1.5}>
					<Button
						size='small'
						variant='contained'
						color='secondary'
						className={classes.buttonWidth}
						onClick={() => setAlertError(false)}
					>
						<Typography variant='caption'>OK</Typography>
					</Button>
				</Box>
			</Modal>
			{/* end of alert error */}
		</Layout>
	)
}

Component.propTypes = {
	mouNumber: PropTypes.string,
	navigate: PropTypes.func,
}

export default React.memo(Component)
