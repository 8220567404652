import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import Modal from 'Components/modal'
import Styles from './styles'

const Component = (props) => {
	const classes = Styles()

	const closeModal = () => {
		window.location.reload()
		props.onClose()
	}

	return (
		<Modal
			open={props.succesModal}
			icon={props.modalStatus}
			isNeedMarginBottom={false}
		>
			<Box mt={0}>
				<Typography variant='body1' sx={{ marginTop: -10 }}>
					{props.modalStatus == 'warning' ? (
						<strong>
							Terdapat perubahan data anggaran, <br />
							silakan proses ulang Quotation Anda
						</strong>
					) : (
						<strong>
							{props.modalStatus == 'success' && props.isPR
								? 'Purchase Request Berhasil Dibuat'
								: props.modalStatus == 'success'
								? 'Purchase Order Berhasil Dibuat'
								: props.modalStatus == 'error' && props.isPR
								? 'Purchase Request Gagal Dibuat'
								: 'Purchase Order Gagal Dibuat'}
						</strong>
					)}
				</Typography>
				{props.modalStatus == 'success' || props.modalStatus == 'error' ? (
					<Button
						variant='contained'
						color='secondary'
						size='small'
						onClick={() =>
							props.modalStatus == 'error'
								? props.onClose()
								: props.handleSuccessModal()
						}
					>
						OK
					</Button>
				) : (
					<Button
						variant='outlined'
						size='small'
						onClick={() => closeModal()}
						className={classes.button}
					>
						Muat Ulang Halaman
					</Button>
				)}
			</Box>
		</Modal>
	)
}

Component.propTypes = {
	succesModal: PropTypes.bool,
	onClose: PropTypes.func,
	modalStatus: PropTypes.string,
	isPR: PropTypes.bool,
	handleSuccessModal: PropTypes.func,
}

export default Component
