import React from 'react'

import { Formik, Form, Field } from 'formik'
import {
	Typography,
	TextField,
	Button,
	Collapse,
	Link,
	Box,
	CircularProgress,
	Grid,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import schema from './schema'
import Alert from '../../alert'
import { navigate } from '@reach/router'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch, useSelector } from 'react-redux'
import { checkUsernameAction } from 'States/actions/UsersAccount/forgotPasswordAction'

const recaptchaKey = process.env.GATSBY_RECAPTCHA_KEY

const ForgotPasswordForm = ({ classes, seconds, isShowTime }) => {
	window.recaptchaOptions = {
		useRecaptchaNet: true,
	}
	const dispatch = useDispatch()
	const forgotPasswordReducer = useSelector(
		(state) => state.forgotPasswordReducer
	)
	const handleNavigate = (path) => {
		navigate(path)
	}
	const handleSubmitForgotPassword = (val) => {
		const username = val.username
		dispatch(checkUsernameAction(username))
	}

	return (
		<Formik
			enableReinitialize
			initialValues={{ username: '', captcha: '' }}
			validationSchema={schema}
			onSubmit={(values) => handleSubmitForgotPassword(values)}
		>
			{({ errors, handleSubmit, touched, setFieldValue }) => {
				// console.log(errors)
				return (
					<Form
						onSubmit={handleSubmit}
						method='post'
						className={[classes.form, classes.p_4]}
					>
						<Typography
							className={[classes.title, classes.mb_3].join(' ')}
							text-align='center'
							variant='h3'
						>
							Lupa Kata Sandi
						</Typography>
						<Typography className={[classes.subtitle, classes.textLeft].join(' ')}>
							Silakan masukkan username yang Anda gunakan pada <br />
							saat Anda mendaftar sebagai member Mbiz.co.id. <br />
							Kami akan mengirimkan tautan untuk melakukan reset kata <br />
							kata sandi akun Anda.
						</Typography>
						<Collapse
							in={
								(errors.username && touched.username) ||
								(errors.captcha && touched.captcha)
							}
						>
							<Alert variant={'alert'}>
								<Typography variant='body1' className={classes.alertText}>
									{errors.username && touched.username && errors.username}
								</Typography>
								<Typography variant='body1' className={classes.alertText}>
									{errors.captcha && touched.captcha && errors.captcha}
								</Typography>
							</Alert>
						</Collapse>
						<Field name='username'>
							{({
								field, // { name, value, onChange, onBlur }
								// form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
							}) => (
								<TextField
									variant='outlined'
									fullWidth
									size='small'
									margin='dense'
									placeholder='Masukkan Username'
									type='input'
									{...field}
								/>
							)}
						</Field>
						<Box className={classes.captchaContainer}>
							<ReCAPTCHA
								name='captcha'
								sitekey={recaptchaKey}
								onChange={(response) => {
									setFieldValue('captcha', response)
								}}
							/>
						</Box>

						<Button
							fullWidth
							size='large'
							type='submit'
							variant='contained'
							color='secondary'
							className={classes.formButton}
							disabled={
								forgotPasswordReducer.isLoading
									? forgotPasswordReducer.isLoading
									: seconds
									? seconds == 0
										? false
										: true
									: false
							}
						>
							{forgotPasswordReducer.isLoading ? <CircularProgress /> : 'Submit'}
						</Button>
						{isShowTime ? (
							<Grid
								className={classes.rowTextTimeCount}
								container
								justify='center'
								alignItems='center'
							>
								<Typography className={classes.textCounter}>Mohon menunggu </Typography>
								<Typography className={classes.textTimeCount}>
									{seconds} detik
								</Typography>
								<Typography className={classes.textCounter}>
									untuk mengirim ulang email kata sandi
								</Typography>
							</Grid>
						) : null}
						{isShowTime ? null : <hr className={classes.hr_line}></hr>}
						<div></div>
						<Typography className={classes.textCenter}>
							<small>
								Belum punya akun?{' '}
								<Link
									className={classes.uri}
									onClick={() => handleNavigate('register')}
								>
									Daftar disini
								</Link>
							</small>
						</Typography>
					</Form>
				)
			}}
		</Formik>
	)
}

ForgotPasswordForm.propTypes = {
	classes: PropTypes.object,
	seconds: PropTypes.number,
	isShowTime: PropTypes.bool,
}

export default ForgotPasswordForm
