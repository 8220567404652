/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import PropTypes from 'prop-types'
import {
	Box,
	Grid,
	Paper,
	Typography,
	TextField,
	Button,
	Container,
	IconButton,
} from '@material-ui/core'
import { Edit, SearchRounded, List, Help } from '@material-ui/icons'
import { listAnggaranStyle } from './styles'
import { navigate } from 'gatsby'
import Breadcrumbs from 'Components/breadcrumbs'
import * as qs from 'query-string'
import { requestBudgetList } from 'States/actions/Budget/listAction'
import { requestDepartmentList } from 'States/actions/Company/Department/listAction'
import { newUri } from '../../../utils/globalUtils'
import TableData from 'Components/tableData'
import Status from 'Components/status'
import { numeralFormat } from '../../../utils/formatMoney'
import { MultiSelect } from '../../../components/dropdown'
import { multiLineCellStyle, cellTextRed } from '../../../assets/dataTable'

// breadcumbs
const breadcrumbs = [
	{
		segment: 'Home',
		link: '/dashboard',
	},
	{
		segment: 'Perusahaan',
		link: '#',
	},
	{
		segment: 'Pengaturan Pembelian',
		link: '#',
	},
	{
		segment: 'Anggaran',
		link: '/dashboard/purchase-setting/budget',
	},
]

const buttonStatus = [
	{
		status: 'all_status',
		state: '',
	},
	{
		status: '99', // button aktif
		state: '99',
	},
	{
		status: '98', // button tidak aktif
		state: '98',
	},
]

const limitValue = [10, 25, 50, 100]

const ListAnggaran = (props) => {
	const classes = listAnggaranStyle()
	const dispatch = useDispatch()
	const budgetList = useSelector((state) => state.budgetListReducer)
	const departmentList = useSelector((state) => state.departmentListReducer)
	const [optionDepartment, setOptionDepartment] = useState([])
	const [selectedOptions, setSelectedOptions] = useState([])

	// THIS FOR DATATABLE
	const [dataBudgetList, setDataBudgetList] = useState([])

	const queryString = qs.parse(props.location.search)

	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [limit, setLimit] = useState(queryString.limit ? queryString.limit : 10)
	const [budgetName, setBudgetName] = useState(
		queryString.budget_name ? queryString.budget_name : ''
	)
	const [departmentID, setDepartmentID] = useState(
		queryString.department_id ? queryString.department_id : ''
	)
	const [status, setStatus] = useState(
		queryString.status ? queryString.status : ''
	)

	queryString.limit = limit
	queryString.page = page
	queryString.budget_name = budgetName
	queryString.department_id = departmentID
	queryString.status = status

	const columns = [
		{
			name: 'NAMA ANGGARAN',
			selector: 'name',
			sortable: true,
			width: '25%',
			wrap: true,
			style: multiLineCellStyle,
		},
		{
			name: 'TOTAL ANGGARAN',
			selector: 'total',
			sortable: true,
			width: '15%',
			style: multiLineCellStyle,
			cell: (row) => numeralFormat(row.total),
		},
		{
			name: 'SISA ANGGARAN',
			selector: '',
			sortable: true,
			width: '15%',
			style: multiLineCellStyle,
			conditionalCellStyles: [
				{
					when: (row) => row.total - row.total_used < 0,
					style: cellTextRed,
				},
			],
			cell: (row) => {
				let sisa = row.total - row.total_used
				return numeralFormat(sisa)
			},
		},
		{
			name: 'DEPARTEMEN',
			selector: 'departments',
			sortable: true,
			width: '25%',
			wrap: true,
			style: multiLineCellStyle,
		},
		{
			name: 'STATUS',
			selector: '',
			sortable: true,
			width: '10%',
			style: multiLineCellStyle,
			cell: (row) => <Status status={row.status.toString()} />,
		},
		{
			name: 'AKSI',
			selector: '',
			sortable: true,
			hide: 'md',
			width: '10%',
			style: multiLineCellStyle,
			cell: (row) => buttonGenerator(row),
		},
	]

	const handleClick = (id, action) => {
		if (action == 'edit') {
			navigate(`/dashboard/purchase-setting/budget/edit/${id}`)
		} else {
			navigate(`/dashboard/purchase-setting/budget/detail/${id}`)
		}
	}

	const buttonGenerator = (row) => (
		<>
			<Button
				variant='contained'
				size='small'
				className={`${classes.buttonAction} ${classes.btnEdit}`}
				onClick={() => handleClick(row.id, 'edit')}
			>
				<Edit fontSize='small' />
			</Button>
			<Button
				variant='contained'
				size='small'
				className={`${classes.buttonAction} ${classes.btnEdit}`}
				onClick={() => handleClick(row.id)}
			>
				<List fontSize='small' />
			</Button>
		</>
	)

	useEffect(() => {
		dispatch(requestBudgetList({ queryString }))
		dispatch(
			requestDepartmentList({
				queryString: { limit: 200, filter: '{"status":"99"}' },
			})
		)
	}, [])

	useEffect(() => {
		let data = []
		if (!departmentList.isLoading && departmentList.data?.items) {
			departmentList.data.items.map((val) => {
				data.push({
					label: `${val.name}`,
					value: `${val.id}`,
				})
			})
		}
		if (data.length > 0) setOptionDepartment(data)
	}, [departmentList.data.items])

	useEffect(() => {
		if (optionDepartment && departmentID) {
			const arrDeptID = departmentID.split(',')

			const a = optionDepartment.filter(
				(item) => arrDeptID.indexOf(item.value) !== -1
			)
			setSelectedOptions(a)
		}
	}, [optionDepartment])

	useEffect(() => {
		if (budgetList.data) {
			let result = budgetList.data.items
			if (result) {
				setDataBudgetList(result)
			} else {
				setDataBudgetList([])
			}
		} else {
			setDataBudgetList([])
		}
	}, [budgetList])

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setLimit(event.target.value)
		queryString.limit = event.target.value
		let maxPage = Math.ceil(
			budgetList.data.pagination.total_records / queryString.limit
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		submitSearch()
	}

	const handleChangeSearch = (e) => {
		let value = e.target.value.trim()
		setBudgetName(value)
		setPage(1)
		queryString.budget_name = value
		queryString.page = 1
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => submitSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [budgetName])

	function submitSearch() {
		let query = newUri(queryString)

		dispatch(requestBudgetList({ queryString }))
		propsNavigate(query)
	}

	const handleButtonState = (event, btnState) => {
		setStatus(btnState)
		setPage(1)
		queryString.status = btnState
		queryString.page = 1
		submitSearch()
	}

	const handleSearchBudget = () => {
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleEnterSearch = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	const handleToggleOption = (selectedOptions) => {
		setSelectedOptions(selectedOptions)
		let id = []
		if (selectedOptions.length > 0) {
			selectedOptions.map((val) => {
				id.push(val.value)
			})
		}
		setDepartmentID(id)
		setPage(1)
		queryString.page = 1
	}

	const handleClearOptions = () => {
		setSelectedOptions([])
		setDepartmentID('')
		setPage(1)
		queryString.page = 1
	}

	useEffect(() => {
		submitSearch()
	}, [departmentID])

	const handleSelectAll = (isSelected) => {
		if (isSelected) {
			handleToggleOption(optionDepartment)
		} else {
			handleClearOptions()
		}
	}

	return (
		<>
			<DashboardLayout>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrumbs} />
					</Box>
					<Box my={2}>
						<Typography variant='h6'>Anggaran</Typography>
					</Box>
					<Box mt={1}>
						<Paper variant='outlined'>
							<Box className={classes.infoBox}>
								<Help className={classes.infoBoxIcon} />
								<Typography variant='body1'>
									Klik{' '}
									<a
										href='#'
										onClick={(event) => {
											event.preventDefault()
											navigate('/dashboard/purchase-setting/budget/about', {
												replace: true,
											})
										}}
									>
										di sini
									</a>{' '}
									untuk informasi mengenai anggaran.
								</Typography>
							</Box>
						</Paper>
					</Box>
					<Box mt={3}>
						<Grid container justify='flex-end'>
							<Button
								variant='contained'
								color='secondary'
								size='small'
								onClick={(event) => {
									event.preventDefault()
									navigate('/dashboard/purchase-setting/budget/add', {
										replace: true,
									})
								}}
							>
								Tambah Anggaran
							</Button>
						</Grid>
					</Box>
					<Box mt={2}>
						<Grid container justify='space-between'>
							<Grid container item xs={6} justify='flex-start'>
								<Grid container item xs={6} alignItems='center'>
									<TextField
										placeholder='Cari Nama Anggaran'
										type='search'
										InputProps={{ disableUnderline: false }}
										defaultValue={queryString.budget_name}
										onChange={(e) => handleChangeSearch(e)}
										onKeyDown={(e) => handleEnterSearch(e)}
										className={classes.searchDepartment}
										style={{ width: '80%' }}
									/>
									<IconButton
										className={classes.buttonSearch}
										variant='contained'
										color='primary'
										onClick={() => handleSearchBudget()}
									>
										<SearchRounded fontSize='small' />
									</IconButton>
								</Grid>
								<Grid container item xs={6} alignItems='center'>
									<MultiSelect
										items={departmentList.isLoading ? [] : optionDepartment}
										getOptionLabel={(option) => option.label}
										selectedValues={selectedOptions}
										label=''
										placeholder='Pilih Departemen'
										selectAllLabel='Pilih Semua'
										onToggleOption={handleToggleOption}
										onClearOptions={handleClearOptions}
										onSelectAll={handleSelectAll}
										isMultiple={true}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Box mt={2}>
						<TableData
							buttonState={buttonStatus}
							buttonStateClick={handleButtonState}
							limitValue={limitValue}
							perPage={limit}
							limitChange={handlePerRowsChange}
							columns={columns}
							isLoading={budgetList.isLoading}
							pagination={budgetList.data ? budgetList.data.pagination : {}}
							data={dataBudgetList}
							name={'Anggaran'}
							paginationPageChange={handlePageChange}
							pageNow={parseInt(queryString.page)}
							fixedWidth
							labelState='Status'
							queryState={queryString.status}
						/>
						<br />
					</Box>
				</Container>
			</DashboardLayout>
		</>
	)
}

ListAnggaran.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default ListAnggaran
