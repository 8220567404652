import { TIMELINE } from '../../../types'
import API from '../../../../utils/api'
import { success, loading, failure } from '../../../commonActions'

export const resetAction = () => {
	return {
		type: `${TIMELINE}_RESET`,
	}
}

export const getTimeline = (transId) => {
	return async (dispatch) => {
		dispatch(loading(TIMELINE))
		try {
			let response = await API({
				method: 'GET',
				url: `/transaction-logs/${transId}`,
			})

			if (response.data.data) {
				dispatch(success(TIMELINE, response.data))
			} else if (response.data.error_backend) {
				let error
				error = response.data.error_backend
				dispatch(failure(TIMELINE, error))
			}
		} catch (err) {
			dispatch(failure(TIMELINE, err))
		}
	}
}
