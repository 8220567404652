import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ProgressBar from '../../../../../components/progressBar'
import { Pdf, Docx, Excel, Doc, Jpg, Other, Png } from 'Components/svgs'
import { Grid, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import { PurchaseRequestDetailStyles } from '../styles'
import {
	convertBytesToString,
	getFileExtension,
} from '../../../../../utils/globalUtils'

const UploadFile = (props) => {
	const classes = PurchaseRequestDetailStyles()
	const [dataFile, setdataFile] = useState(props.data)
	const [progressBar, setProgressBar] = useState(0)
	const [progLoad, setProgLoad] = useState(0)
	const [progTotal, setProgTotal] = useState(0)
	const [extension, setExtension] = useState('')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (
			props.data ||
			props.progressLoad ||
			props.progressTotal ||
			props.progress ||
			props.loadProgress
		) {
			let file_ext = getFileExtension(props.data)
			let ext = file_ext ? file_ext.toLowerCase() : ''
			setExtension(ext)
			setdataFile(props.data)
			setProgressBar(Math.round(props.progress, 2))
			setProgLoad(props.progressLoad)
			setProgTotal(props.progressTotal)
			setLoading(props.loadProgress)
		}
	}, [
		props.data,
		props.progressLoad,
		props.progressTotal,
		props.progress,
		props.loadProgress,
	])

	const containerStyles = {
		height: 67,
		width: '100%',
		backgroundColor: props.error
			? 'rgba(248,215,218,0.5)'
			: 'rgba(204,229,255,0.6)',
		marginBottom: 15,
		marginLeft: 1,
	}

	const fileThumb = {
		width: '35px',
		height: '45px',
	}

	const fileThumbIconOther = {
		width: '30px',
		height: '40px',
	}

	const titleStyle = {
		color: 'black',
		fontWeight: 'bold',
		fontSize: '13px',
		marginTop: 3,
	}

	const sizeTextStyle = {
		color: 'black',
		fontSize: '12px',
	}

	const buttonBatal = {
		width: '20px',
		height: '20px',
	}

	const teksBatal = {
		color: '#DC3545',
		opacity: 1,
	}

	const teksGagalUngah = {
		fontStyle: 'italic',
		fontSize: '12px',
	}

	const listExt = {
		excel: ['xls', 'xlsx'],
		doc: ['doc'],
		docx: ['docx'],
		pdf: ['pdf'],
		jpg: ['jpg', 'jpeg'],
		png: ['png'],
	}

	const getFileThumb = (ext) => {
		if (listExt.excel.includes(ext)) {
			return <Excel style={fileThumb} />
		} else if (listExt.doc.includes(ext)) {
			return <Doc style={fileThumb} />
		} else if (listExt.docx.includes(ext)) {
			return <Docx style={fileThumb} />
		} else if (listExt.pdf.includes(ext)) {
			return <Pdf style={fileThumb} />
		} else if (listExt.jpg.includes(ext)) {
			return <Jpg style={fileThumb} />
		} else if (listExt.png.includes(ext)) {
			return <Png style={fileThumb} />
		} else {
			return <Other style={fileThumbIconOther} />
		}
	}

	return (
		<Grid
			container
			spacing={2}
			style={containerStyles}
			justify='center'
			alignItems='center'
		>
			<Grid item xs={1} style={{ marginTop: 3 }}>
				{dataFile ? getFileThumb(extension) : null}
			</Grid>
			<Grid container direction='column' item xs={11}>
				<Grid container direction='row' item>
					<Grid container direction='column' item xs={10}>
						<span style={titleStyle}>
							{dataFile.length >= 60
								? `${dataFile.slice(0, 57)}....${extension}`
								: dataFile}
						</span>
						{props.error ? (
							<span style={{ ...teksBatal, ...teksGagalUngah }}>
								Gagal mengunggah dokumen
							</span>
						) : (
							<span style={sizeTextStyle}>
								{progLoad != progTotal && loading
									? `${convertBytesToString(progLoad, true)}/${convertBytesToString(
											progTotal,
											true
									  )}`
									: convertBytesToString(progTotal, true)}
							</span>
						)}
					</Grid>
					<Grid item xs={2} container justify='flex-end' alignItems='center'>
						{props.error ? (
							<Button
								onClick={props.onDeleteData}
								size='small'
								className={classes.buttonDelete}
								style={{ paddingRight: 10 }}
							>
								<DeleteIcon style={buttonBatal} />
								<span>Hapus</span>
							</Button>
						) : progLoad != progTotal && (!props.error || loading) ? (
							<Button
								onClick={props.onCancel}
								size='small'
								style={{ paddingRight: 10 }}
							>
								<CloseIcon style={{ ...teksBatal, ...buttonBatal }} />
								<span style={teksBatal}>Batal</span>
							</Button>
						) : (
							<Button
								onClick={props.onDeleteData}
								size='small'
								className={classes.buttonDelete}
								style={{ paddingRight: 10 }}
							>
								<DeleteIcon style={buttonBatal} />
								<span>Hapus</span>
							</Button>
						)}
					</Grid>
				</Grid>
				{props.error ? null : progLoad != progTotal && (!props.error || loading) ? (
					<div style={{ paddingRight: 9 }}>
						<ProgressBar progress={progressBar} />
					</div>
				) : null}
			</Grid>
		</Grid>
	)
}

UploadFile.propTypes = {
	data: PropTypes.object,
	onDeleteData: PropTypes.func,
	onCancel: PropTypes.func,
	progressLoad: PropTypes.number,
	progressTotal: PropTypes.number,
	error: PropTypes.bool,
	progress: PropTypes.number,
	loadProgress: PropTypes.bool,
}

export default UploadFile
