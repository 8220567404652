import { DETAIL_MOU, SAVE_MOU_ITEMS, LOG_TRANSACTION_MOU } from '../../types'
import { success, loading, failure } from '../../commonActions'
import API from '../../../utils/api'

export const resetAction = () => {
	return {
		type: `${DETAIL_MOU}_RESET`,
	}
}

export const getDetailMou = (mouNumber) => {
	return async (dispatch) => {
		dispatch(loading(DETAIL_MOU))
		try {
			const response = await API({
				method: 'GET',
				url: `/mou/details/${mouNumber}`,
			})
			dispatch(success(DETAIL_MOU, response.data))
		} catch (err) {
			console.log(err)
			dispatch(failure(DETAIL_MOU, err))
		}
	}
}

export const getTransactionLog = (mouNumber, pageSize, page) => {
	return async (dispatch) => {
		dispatch(loading(LOG_TRANSACTION_MOU))
		try {
			const response = await API({
				method: 'GET',
				url: `/purchase-orders/rfqs/list?page_size=${pageSize}&page=${page}&filter=["sales_agreement_id","=",${mouNumber}]`,
			})
			dispatch(success(LOG_TRANSACTION_MOU, response.data))
		} catch (err) {
			console.log(err)
			dispatch(failure(LOG_TRANSACTION_MOU, err))
		}
	}
}

export const saveMouItems = (items) => {
	// const data = filter(items, value => value.qty >= 1)
	return (dispatch) => {
		dispatch({
			type: SAVE_MOU_ITEMS,
			payload: {
				data: items,
			},
		})
	}
}
