import { makeStyles } from '@material-ui/core/styles'
import backgroundForm from '../../assets/images/background/form-backgrounds.jpg'

export const contactStyle = makeStyles((theme) => ({
	container: {
		overflow: 'hidden',
		marginBottom: '50px',
	},
	detailWrapper: {
		backgroundImage: `url(${backgroundForm})`,
		padding: theme.spacing(4),
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPositionX: '50%',
		display: 'flex',
		position: 'relative',
		'&::after': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			bottom: 0,
			backgroundColor: 'rgba(0,0,0,.6)',
		},
	},
	detail: {
		color: theme.palette.common.white,
		zIndex: 1,
	},
	detailTitle: {
		fontWeight: 'bold',
	},
	formWrapper: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	formTitle: {
		// marginLeft: theme.spacing(6.4),
		marginLeft: theme.spacing(7),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			marginLeft: 29.2,
		},
	},
	validationText: {
		height: 25,
		color: theme.palette.error.main,
	},
	validationTextItem: {
		display: 'flex',
		alignItems: 'center',
	},
	form: {
		marginTop: theme.spacing(2),
	},
	formButton: {
		float: 'right',
		marginTop: theme.spacing(2),
	},
	inputField: {
		'& .MuiFormHelperText-root': {
			position: 'absolute',
			color: '#777',
			alignSelf: 'flex-end',
			marginTop: '40px',
			paddingRight: '20px',
		},
	},
	textareaField: {
		'& .MuiFormHelperText-root': {
			position: 'absolute',
			color: '#777',
			alignSelf: 'flex-end',
			marginTop: '100px',
			paddingRight: '20px',
		},
	},
}))
