import { BUDGET_LIST } from '../../types'
import API from '../../../utils/api'
import { newUriAPI } from '../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${BUDGET_LIST}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${BUDGET_LIST}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${BUDGET_LIST}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestBudgetList = ({ queryString }) => {
	function generateParams(queryString) {
		let uriAPIStr = newUriAPI(queryString)
		return uriAPIStr
	}

	return async (dispatch) => {
		dispatch(loading())
		try {
			let query = ''
			if (queryString) query = generateParams(queryString)

			let response = await API({
				method: 'GET',
				url: '/budgets/list' + (query != '' ? '?' + query : ''),
			})

			dispatch(success(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
