import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import {
	Box,
	Container,
	Typography,
	Button,
	Grid,
	TextField,
	IconButton,
	Link,
} from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'
import ListIcon from '@material-ui/icons/List'
import useStyles from './styles'
import { requestPaymentList } from 'States/actions/Transactions/Payment/listAction'
import * as qs from 'query-string'
import {
	newUri,
	dateFormat,
	dateFormatFromOdoo,
	isValidDate,
} from '../../../../utils/globalUtils'
import { numeralFormat } from '../../../../utils/formatMoney'
import Breadcrumbs from 'Components/breadcrumbs'
import Status from 'Components/status'
import DashboardLayout from 'Components/dashboardLayout'
import { roleFinance, checkRole } from '../../../../utils/acl'
import TableData from 'Components/tableData'
import { format } from 'date-fns'
import DatePickerRange from 'Components/DateRange'

const PaymentList = (props) => {
	const allowedFinance = checkRole(`${roleFinance}`, false)
	const classes = useStyles()

	const dispatch = useDispatch()
	const paymentList = useSelector((state) => state.paymentListReducer)

	const queryString = qs.parse(props.location.search)

	const [perPage, setPerPage] = useState(
		queryString.per_page ? queryString.per_page : 10
	)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [paymentNo, setPaymentNo] = useState(
		queryString.payment_no ? queryString.payment_no : ''
	)
	const [startDate, setStartDate] = useState(
		queryString.start_date ? queryString.start_date : ''
	)
	const [endDate, setEndDate] = useState(
		queryString.end_date ? queryString.end_date : ''
	)
	const [state, setState] = useState(queryString.state ? queryString.state : '')

	const [range, setRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	])
	const [dataList, setDataList] = useState([])
	const joinDate = (startDate, endDate) => `${startDate} ~ ${endDate}`

	const [placeHolder, setPlaceHolder] = useState(
		startDate && endDate ? joinDate(startDate, endDate) : 'Rentang Tanggal'
	)
	const [color, setColor] = useState(
		isValidDate(startDate) && isValidDate(startDate) ? '#007BFF' : '#a9acaf'
	)

	const handleOnChange = (item) => {
		const str = `${format(item.selection.startDate, 'yyyy-MM-dd')} ~ ${format(
			item.selection.endDate,
			'yyyy-MM-dd'
		)}`
		setPlaceHolder(str)
		setRange([item.selection])
		setColor('#007BFF')
		setStartDate(dateFormat(item.selection.startDate, 'yyyy-MM-dd'))
		setEndDate(dateFormat(item.selection.endDate, 'yyyy-MM-dd'))
		queryString.start_date = dateFormat(item.selection.startDate, 'yyyy-MM-dd')
		queryString.end_date = dateFormat(item.selection.endDate, 'yyyy-MM-dd')
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleOnClear = () => {
		setPlaceHolder('Rentang Tanggal')
		setColor('#a9acaf')
		setRange([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		])
		setStartDate('')
		setEndDate('')
		queryString.start_date = ''
		queryString.end_date = ''
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	useEffect(() => {
		dispatch(requestPaymentList({ queryString }))
	}, [])

	// hooks for set default state
	useEffect(() => {
		// success
		if (paymentList.data && paymentList.data.result) {
			let result = paymentList.data.result
			if (result) {
				setDataList(result)
			}
		} else {
			setDataList([])
		}
	}, [paymentList])

	queryString.per_page = perPage
	queryString.page = page
	queryString.payment_no = paymentNo
	queryString.start_date = startDate
	queryString.end_date = endDate
	queryString.state = state

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => submitSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [paymentNo])

	const buttonStatus = [
		{
			status: 'all',
			state: '',
		},
		{
			status: 'paydraft',
			state: 'draft',
		},
		{
			status: 'payvalidated',
			state: 'posted',
		},
		{
			status: 'paycancelled',
			state: 'cancelled',
		},
		{
			status: 'payrevised_payment',
			state: 'revised',
		},
	]

	const limitValue = [10, 25, 50, 100]

	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Transaksi',
			link: '#',
		},
		{
			segment: 'Pembayaran',
			link: '#',
		},
	]

	const columns = [
		{
			name: 'NO. PEMBAYARAN',
			selector: 'name',
			sortable: true,
			cell: (row) => (row.name != false ? makeLink(row.name) : '-'),
			grow: 3,
		},
		{
			name: 'TANGGAL TRANSFER',
			selector: 'payment_date',
			sortable: true,
			cell: (row) =>
				row.payment_date != false
					? dateFormatFromOdoo(row.payment_date, 'dd-MMM-yyyy')
					: '-',
			grow: 3,
		},
		{
			name: 'TANGGAL KONFIRMASI',
			selector: 'confirmation_date',
			sortable: true,
			cell: (row) =>
				row.confirmation_date != false
					? dateFormatFromOdoo(row.confirmation_date, 'dd-MMM-yyyy')
					: '-',
			grow: 3,
		},
		{
			name: 'TOTAL TAGIHAN',
			selector: 'move_amount_total',
			sortable: true,
			cell: (row) => numeralFormat(row.move_amount_total),
			grow: 3,
		},
		{
			name: 'JUMLAH PEMBAYARAN',
			selector: 'amount',
			sortable: true,
			cell: (row) => numeralFormat(row.amount),
			grow: 3,
		},
		{
			name: 'SISA PEMBAYARAN',
			selector: 'move_amount_residual',
			sortable: true,
			cell: (row) => numeralFormat(row.move_amount_residual),
			grow: 4,
		},
		{
			name: 'STATUS',
			selector: 'state',
			sortable: true,
			cell: (row) => makeStatus(row.state),
			grow: 3,
		},
		{
			name: 'AKSI',
			selector: 'id',
			sortable: true,
			cell: (row) => generateButton(row),
			grow: 1,
		},
	]

	const makeLink = (paymentName) => (
		<Link
			color='secondary'
			href={`/dashboard/payment/${paymentName}`}
			target='_blank'
		>
			{paymentName}
		</Link>
	)

	const makeStatus = (state) => <Status status={`pay${state}`} />

	const generateButton = (row) => (
		<Button
			id='btn-detail-payment'
			className={classes.buttonAction}
			variant='contained'
			color='secondary'
			size='small'
			onClick={() => navigateTo(row.name)}
		>
			<ListIcon className={classes.iconAction} />
		</Button>
	)

	const navigateTo = (id) => {
		navigate('/dashboard/payment/' + id)
	}

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	const handlePageChange = (_e, page) => {
		setPage(page)
		queryString.page = page
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setPerPage(event.target.value)
		queryString.per_page = event.target.value
		let maxPage = Math.ceil(
			paymentList.data.pagination.total_records / queryString.page_size
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		submitSearch()
	}

	const handleChangeSearch = (e) => {
		let value = e.target.value.trim()
		queryString.payment_no = value
		setPaymentNo(value)
		setPage(1)
	}

	function submitSearch() {
		let query = newUri(queryString)
		dispatch(requestPaymentList({ queryString }))
		propsNavigate(query)
	}

	const handleButtonState = (e, btnState) => {
		setState(btnState)
		setPage(1)
		queryString.state = btnState
		queryString.page = 1
		submitSearch()
	}

	const handleSearchPayment = () => {
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleEnterSearch = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>
				<Box className={'searchContainer'}>
					<Typography variant='h6'>Daftar Pembayaran</Typography>
					<br />
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<DatePickerRange
								ranges={range}
								color={color}
								onSelectDate={(item) => handleOnChange(item)}
								placeholder={placeHolder}
								onClear={() => handleOnClear()}
							/>
						</Grid>
						<Grid item xs={4}>
							<Grid item className={classes.gridSearch}>
								<TextField
									placeholder='Cari No. Pembayaran'
									type='search'
									className={'nameSearch'}
									onChange={(e) => handleChangeSearch(e)}
									onKeyDown={(e) => handleEnterSearch(e)}
									// onBlur={e => handleChangeSearch(e)}
									defaultValue={paymentNo}
								/>
								<IconButton
									className={classes.inputButton}
									variant='contained'
									color='primary'
									onClick={handleSearchPayment}
								>
									<SearchRounded fontSize='small' />
								</IconButton>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							{allowedFinance ? (
								<Button
									id='btn-confirm-bulk-payment'
									variant='outlined'
									color='secondary'
									className={classes.buttonBulk}
									onClick={() => props.navigate('confirm/bulk')}
								>
									Pembayaran Gabungan
								</Button>
							) : null}
						</Grid>
					</Grid>
				</Box>
				{/* TABLE */}
				<Box mt={2}>
					<TableData
						buttonState={buttonStatus}
						buttonStateClick={handleButtonState}
						limitValue={limitValue}
						perPage={perPage}
						limitChange={handlePerRowsChange}
						columns={columns}
						isLoading={paymentList.isLoading}
						pagination={paymentList.data ? paymentList.data.pagination : false}
						data={dataList}
						name={'Pembayaran'}
						paginationPageChange={handlePageChange}
						pageNow={queryString.page}
						labelState='Status'
						queryState={state}
					/>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

PaymentList.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default PaymentList
