import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	TextField,
	IconButton,
	Button,
	Container,
	Collapse,
	Select,
	MenuItem,
} from '@material-ui/core'
import { SearchRounded, Edit, Delete } from '@material-ui/icons'
import { newUri } from '../../../utils/globalUtils'
import { requestBankList } from 'States/actions/Bank/listAction'
import { clearCreateBank } from 'States/actions/Bank/createAction'
import { clearDeleteBank, deleteBank } from 'States/actions/Bank/deleteAction'
import { clearUpdateBank } from 'States/actions/Bank/updateAction'
import { navigate } from 'gatsby'
import Breadcrumbs from 'Components/breadcrumbs'
import { Styles } from './styles'
import * as qs from 'query-string'
import TableData from 'Components/tableData'
import Modal from 'Components/modal'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'

const breadcrums = [
	{
		segment: 'Home',
		link: '/dashboard/',
	},
	{
		segment: 'Perusahaan',
		link: '#',
	},
	{
		segment: 'Akun Bank',
		link: '#',
	},
]

const Bank = (props) => {
	const classes = Styles()
	const dispatch = useDispatch()
	const list = useSelector((state) => state.bankList)
	const queryString = qs.parse(props.location.search)

	const create = useSelector((state) => state.bankNew)
	const deleted = useSelector((state) => state.bankDelete)
	const updated = useSelector((state) => state.bankUpdate)
	const [name, setName] = useState(queryString.name ? queryString.name : '')
	const [perPage, setPerPage] = useState(
		queryString.per_page ? queryString.per_page : 10
	)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [confirm, setConfirm] = useState(false)
	const [item, setItem] = useState(false)

	queryString.name = name
	queryString.per_page = perPage
	queryString.page = page
	queryString.filter = ''
	queryString.type = 'partner_bank'

	const columns = [
		{
			name: 'NAMA BANK',
			selector: 'bank_name',
			sortable: true,
			hide: 'md',
			grow: 3,
		},
		{
			name: 'nomor akun bank',
			selector: 'acc_number',
			sortable: true,
			grow: 2,
		},
		{
			name: 'nama pemegang akun',
			selector: 'acc_holder_name',
			sortable: true,
			cell: (row) => row.acc_holder_name || '-',
			grow: 2,
		},
		{
			name: 'AKSI',
			selector: 'aksi',
			sortable: true,
			center: true,
			width: '90px',
			cell: (row) => buttonGenerator(row),
		},
	]

	const buttonGenerator = (row) => (
		<>
			<Button
				variant='contained'
				size='small'
				className={`${classes.buttonAction} ${classes.btnEdit}`}
				onClick={() => {
					handleClick(row.id, 'edit')
				}}
			>
				<Edit fontSize='small' />
			</Button>
			<Button
				variant='contained'
				size='small'
				className={`${classes.buttonAction} ${classes.btnDelete}`}
				onClick={() => {
					handleClick(row.id, 'delete')
				}}
			>
				<Delete fontSize='small' />
			</Button>
		</>
	)

	const handleClick = (id, action) => {
		if (action == 'edit') {
			navigate(`/dashboard/bank-account/edit/${id}`)
		} else {
			setConfirm(true)
			setItem(id)
		}
	}

	useEffect(() => {
		return () => {
			dispatch(clearCreateBank())
			dispatch(clearUpdateBank())
			dispatch(clearDeleteBank())
		}
	}, [])

	const clearNotification = () => {
		dispatch(clearCreateBank())
		dispatch(clearUpdateBank())
		dispatch(clearDeleteBank())
	}

	const alertText = () => {
		if (create.alert) {
			return 'Akun Bank baru berhasil ditambahkan.'
		}
		if (deleted.alert) {
			return 'Akun Bank berhasil dihapus.'
		}
		if (updated.alert) {
			return 'Akun Bank berhasil diubah.'
		}
	}

	useEffect(() => {
		dispatch(requestBankList({ queryString }))
	}, [])

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		let query = newUri(queryString)

		dispatch(requestBankList({ queryString }))
		propsNavigate(query)
	}

	const handlePerRowsChange = (event) => {
		setPerPage(event.target.value)
		queryString.per_page = event.target.value
		let maxPage = Math.ceil(
			list.data.data.pagination.total_records / queryString.per_page
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		let query = newUri(queryString)

		dispatch(requestBankList({ queryString }))
		propsNavigate(query)
	}

	const handleNameSearch = () => {
		queryString.name = name
		let query = newUri(queryString)

		dispatch(requestBankList({ queryString }))
		propsNavigate(query)
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => handleNameSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [name])

	const handleSearchName = (value) => {
		queryString.name = value
		queryString.page = 1
		setPage(1)
		setName(value)
	}

	const propsNavigate = (query) => {
		props.navigate(`/dashboard/bank-account?${query}`)
	}

	const confirmClose = () => {
		setConfirm(false)
	}

	const deleteItem = () => {
		clearNotification()
		dispatch(deleteBank(item))
		setConfirm(false)
	}

	const limitValue = [10, 25, 50, 100]

	const buildDropdownLimit = (items) => {
		return (
			<Grid container justify='flex-end' alignItems='center'>
				<Typography className={classes.typoPage2}>
					<strong>Tampilkan </strong>
				</Typography>
				<Select
					classes={{ root: classes.selectContainer, icon: classes.selectIcon }}
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={perPage}
					onChange={handlePerRowsChange}
					style={{ marginRight: '4px' }}
				>
					{items.map((row, index) => {
						return (
							<MenuItem key={index} value={row}>
								{row}
							</MenuItem>
						)
					})}
				</Select>
			</Grid>
		)
	}

	return (
		<DashboardLayout>
			<Container>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>
				<Box my={2}>
					<Grid container justify='space-between'>
						<Grid container item lg={4} justify='flex-start'>
							<Typography variant='h6'>Akun Bank</Typography>
						</Grid>
						<Grid container item xs={3} justify='flex-end'>
							<Button
								variant='contained'
								color='secondary'
								size='small'
								onClick={(event) => {
									event.preventDefault()
									navigate('/dashboard/bank-account/add', {
										replace: true,
									})
								}}
							>
								Tambah Akun Bank
							</Button>
						</Grid>
					</Grid>
				</Box>
				<Box mt={1}>
					<Grid container justify='space-between'>
						<Grid container item xs={8} justify='flex-start'>
							<Grid item xs={5}>
								<TextField
									placeholder='Cari Nama Bank / Nomor Akun'
									type='search'
									className={classes.gridSearch}
									InputProps={{
										disableUnderline: false,
									}}
									onChange={(e) => {
										handleSearchName(e.target.value)
									}}
									value={name}
								/>
								<IconButton
									variant='contained'
									color='primary'
									className={classes.buttonSearch}
									onClick={handleNameSearch}
								>
									<SearchRounded fontSize='small' />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<Box mt={1}>
					<Grid container item justify='flex-end'>
						{buildDropdownLimit(limitValue)}
					</Grid>
				</Box>

				<Collapse
					in={create.alert || deleted.alert || updated.alert}
					style={{ paddingTop: '16px' }}
				>
					<Alert
						severity='info'
						action={
							<IconButton
								aria-label='close'
								color='inherit'
								size='small'
								onClick={() => {
									clearNotification()
								}}
							>
								<CloseIcon fontSize='inherit' />
							</IconButton>
						}
					>
						{alertText()}
					</Alert>
				</Collapse>

				<Box mt={3}>
					<Modal open={confirm}>
						<Typography>
							Apakah Anda yakin
							<br />
							ingin menghapus akun bank ini ?
						</Typography>
						<Box mt={2} className={classes.paddingTop}>
							<Button
								onClick={() => confirmClose()}
								color='secondary'
								variant='outlined'
								size='small'
							>
								Batal
							</Button>
							&nbsp;&nbsp;&nbsp;
							<Button
								onClick={() => {
									deleteItem()
								}}
								className={classes.buttonRed}
								variant='contained'
								size='small'
							>
								Ya, Hapus
							</Button>
						</Box>
					</Modal>

					<TableData
						perPage={perPage}
						limitChange={handlePerRowsChange}
						columns={columns}
						isLoading={list.isLoading}
						pagination={
							list.data && list.data.data && list.data.data.pagination
								? list.data.data.pagination
								: false
						}
						data={
							list.data && list.data.data && list.data.data.result
								? list.data.data.result
								: false
						}
						name={'Bank'}
						paginationPageChange={handlePageChange}
						pageNow={parseInt(queryString.page)}
					/>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

Bank.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default Bank
