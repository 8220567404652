/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import styles from './styles'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
	requestDetailPayment,
	resetAction,
} from 'States/actions/Transactions/Payment/detailAction'
import { withStyles } from '@material-ui/core/styles'
import {
	Box,
	Container,
	Paper,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
} from '@material-ui/core'
import Layout from 'Components/layout'
import Image from 'material-ui-image'
import Breadcrumbs from 'Components/breadcrumbs'
import { numeralFormat } from '../../../../utils/formatMoney'
import Status from 'Components/status'
import { roleFinance, checkRole } from '../../../../utils/acl'
import {
	getFileExtension,
	listExt,
	listExtensions,
} from '../../../../utils/globalUtils'
import ScrollToTop from 'Components/scrollToTop'
import PrintReport from 'Components/buttonPrintReport'
import { Pdf, PPT, Docx, Excel, BlankFile } from 'Components/svgs'
import { Skeleton } from '@material-ui/lab'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)
const StyleTooltip = withStyles(() => ({
	tooltip: {
		marginTop: 5,
		marginBottom: 5,
		maxWidth: 'none',
	},
}))(Tooltip)

const thumb = process.env.GATSBY_IMAGE_URL + '/180x180/erp/'

const Component = ({ paymentID }) => {
	const classes = styles()
	const dispatch = useDispatch()
	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Transaksi',
			link: '/dashboard',
		},
		{
			segment: 'Pembayaran',
			link: '/dashboard/payment',
		},
		{
			segment: 'Rincian Pembayaran',
			link: '#',
		},
	])

	const detail = useSelector((state) => state.paymentDetailReducer)

	const initDataPayment = {
		id: '',
		confirmation_date: '',
		partner_bank_account: '',
		mbiz_bank_account: '',
		payment_notes: '',
		communication: '',
		name: '',
		payment_date: '',
		move_amount_total: 0,
		amount: 0,
		move_amount_residual: 0,
		state: '',
		payment_document_ids: [],
	}
	const [dataPayment, setDataPayment] = useState({ ...initDataPayment })

	useEffect(() => {
		dispatch(requestDetailPayment(paymentID))
		return () => {
			dispatch(resetAction())
		}
	}, [paymentID])

	useEffect(() => {
		if (detail.isLoading == false && detail.data != null) {
			let dataTemp = dataPayment
			dataTemp.id = detail.data.id
			dataTemp.confirmation_date = detail.data.confirmation_date || '-'
			dataTemp.partner_bank_account =
				detail.data.partner_bank_account?.display_name || '-'
			dataTemp.mbiz_bank_account =
				detail.data.mbiz_bank_account?.display_name || '-'
			dataTemp.payment_notes = detail.data.payment_notes
			dataTemp.communication = detail.data.communication
				? renderInvoiceNumber(detail.data.communication)
				: ''
			dataTemp.name = detail.data.name
			dataTemp.payment_date = detail.data.payment_date
			dataTemp.move_amount_total = detail.data.move_amount_total
			dataTemp.amount = detail.data.amount
			dataTemp.move_amount_residual = detail.data.move_amount_residual
			dataTemp.state = detail.data.state
			dataTemp.payment_document_ids = detail.data.payment_document_ids?.length
				? detail.data.payment_document_ids
				: []
			setDataPayment(dataTemp)
		} else {
			setDataPayment({ ...initDataPayment })
		}
	}, [detail.isLoading, detail.data])

	const renderInvoiceNumber = (invoiceNumber) => {
		const arrInvoiceNo = invoiceNumber.split(',')
		return arrInvoiceNo.map((item, index) => (
			<Typography key={index} variant='body1'>
				<a
					href={`${window.location.origin}/dashboard/invoice/${item}`}
					target='blank'
				>
					{item}
				</a>
			</Typography>
		))
	}

	const generateDetailTable = () => {
		return (
			<Table classes={{ root: classes.tableRoot }}>
				<TableHead>
					<TableRow>
						<TableCell align='center' className={classes.fontBold}>
							No. Tagihan
						</TableCell>
						<TableCell align='center' className={classes.fontBold}>
							No. Pembayaran
						</TableCell>
						<TableCell align='center' className={classes.fontBold}>
							Tanggal Transfer
						</TableCell>
						<TableCell align='center' className={classes.fontBold}>
							Total Tagihan
						</TableCell>
						<TableCell align='center' className={classes.fontBold}>
							Jumlah Pembayaran
						</TableCell>
						<TableCell align='center' className={classes.fontBold}>
							Sisa Pembayaran
						</TableCell>
						<TableCell align='center' className={classes.fontBold}>
							Status Pembayaran
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{detail.isLoading ? (
						<TableRow>
							<CustomCell align='center' className={classes.noBorderBottom}>
								<Skeleton variant='text' />
							</CustomCell>
							<CustomCell align='center'>
								<Skeleton variant='text' />
							</CustomCell>
							<CustomCell align='center'>
								<Skeleton variant='text' />
							</CustomCell>
							<CustomCell align='center'>
								<Skeleton variant='text' />
							</CustomCell>
							<CustomCell align='center'>
								<Skeleton variant='text' />
							</CustomCell>
							<CustomCell align='center'>
								<Skeleton variant='text' />
							</CustomCell>
							<CustomCell align='center'>
								<Skeleton variant='text' />
							</CustomCell>
						</TableRow>
					) : (
						<TableRow>
							<CustomCell align='center' className={classes.noBorderBottom}>
								{dataPayment.communication}
							</CustomCell>
							<CustomCell align='center'>{dataPayment.name}</CustomCell>
							<CustomCell align='center'>{dataPayment.payment_date}</CustomCell>
							<CustomCell align='center'>
								{numeralFormat(dataPayment.move_amount_total)}
							</CustomCell>
							<CustomCell align='center'>
								{numeralFormat(dataPayment.amount)}
							</CustomCell>
							<CustomCell align='center'>
								{numeralFormat(dataPayment.move_amount_residual)}
							</CustomCell>
							<CustomCell align='center'>
								<Status status={'pay' + dataPayment.state} />
							</CustomCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		)
	}

	const listMimeType = {
		excel: [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		],
		doc: [
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		],
		ppt: [
			'application/vnd.ms-powerpoint',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		],
		pdf: ['application/pdf'],
		image: ['image/jpeg', 'image/png', 'image/gif'],
	}

	const getFileThumb = (filename, image_filestore, mimetype) => {
		let file_ext = getFileExtension(filename)
		let ext = file_ext ? file_ext.toLowerCase() : mimetype
		let list = file_ext ? listExt : listMimeType
		if (list.excel.includes(ext)) {
			return <Excel className={classes.fileThumb} />
		} else if (list.doc.includes(ext)) {
			return <Docx className={classes.fileThumb} />
		} else if (list.ppt.includes(ext)) {
			return <PPT className={classes.fileThumb} />
		} else if (list.pdf.includes(ext)) {
			return <Pdf className={classes.fileThumb} />
		} else if (list.image.includes(ext)) {
			return (
				<Image
					src={thumb + image_filestore}
					style={{
						objectFit: 'contain',
						width: '100%',
						height: '100%',
					}}
				/>
			)
		}

		return <BlankFile className={classes.blankFileThumb} />
	}

	const downloadFile = (linkSource, filename) => {
		window.location.href = `${
			process.env.GATSBY_API_URL + '/' + process.env.GATSBY_API_VERSION
		}/storages/download/${linkSource}/${filename}`
	}

	const nameDisplay = (filename) => {
		let file_extsions = getFileExtension(filename)
		let extions = file_extsions ? file_extsions.toLowerCase() : ''
		let text = `.${extions}`
		let res = listExtensions.includes(extions)
			? filename.replace(text, '')
			: filename
		return res
	}

	const allowDownloadReceipt = checkRole(`${roleFinance}`, false)
	const displayDownloadReceipt = (image_filestore, name, filename, mimetype) => {
		if (allowDownloadReceipt) {
			return (
				<Grid
					container
					justifyContent='center'
					alignItems='center'
					direction='column'
				>
					<Grid item>
						<Box className={classes.image}>
							{getFileThumb(filename, image_filestore, mimetype)}
						</Box>
					</Grid>
					<Grid item>
						<StyleTooltip
							title={name}
							placement='bottom-start'
							disableHoverListener={name.length < 20 ? true : false}
						>
							<Typography
								variant='body2'
								className={classes.fileName}
								onClick={() => downloadFile(image_filestore, filename)}
							>
								<strong>
									{name.length >= 20 ? `${name.slice(0, 20)}...` : nameDisplay(name)}
								</strong>
							</Typography>
						</StyleTooltip>
					</Grid>
				</Grid>
			)
		} else {
			return getFileThumb(filename, image_filestore)
		}
	}

	const generateDetail = () => {
		return (
			<Grid container>
				<Grid item xs={12}>
					<Grid container className={classes.paddingBottom}>
						<Grid item xs={12} sm={2}>
							<Typography>
								<strong>Tanggal Konfirmasi</strong>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={10}>
							<Typography>
								{detail.isLoading ? (
									<Skeleton variant='text' width={100} />
								) : (
									dataPayment.confirmation_date
								)}
							</Typography>
						</Grid>
					</Grid>
					<Grid container className={classes.paddingBottom}>
						<Grid item xs={12} sm={2}>
							<Typography>
								<strong>Transfer Dari</strong>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={10}>
							<Typography>
								{detail.isLoading ? (
									<Skeleton variant='text' width={300} />
								) : (
									dataPayment.partner_bank_account
								)}
							</Typography>
						</Grid>
					</Grid>
					<Grid container className={classes.paddingBottom}>
						<Grid item xs={12} sm={2}>
							<Typography>
								<strong>Transfer Ke</strong>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={10}>
							<Typography>
								{detail.isLoading ? (
									<Skeleton variant='text' width={250} />
								) : (
									dataPayment.mbiz_bank_account
								)}
							</Typography>
						</Grid>
					</Grid>
					<Grid container className={classes.paddingBottom}>
						<Grid item xs={12} sm={2}>
							<Typography>
								<strong>Catatan</strong>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={10}>
							<Typography>
								{detail.isLoading ? (
									<Skeleton variant='text' width={300} />
								) : (
									dataPayment.payment_notes
								)}
							</Typography>
						</Grid>
					</Grid>
					<Grid container className={classes.paddingBottom}>
						<Grid item xs={12}>
							<Typography>
								<strong id='bukti-pembayaran'>Bukti Pembayaran:</strong>
							</Typography>
						</Grid>
					</Grid>
					<Grid container className={classes.paddingBottom}>
						{detail.isLoading ? (
							Array.from(Array(5)).map((_, index) => (
								<Grid item lg={2} key={index} className={classes.margintumbhnail}>
									<Grid
										container
										justifyContent='center'
										alignItems='center'
										direction='column'
									>
										<Grid item>
											<Box className={classes.image}>
												<Skeleton variant='rect' width={150} height={150} />
											</Box>
										</Grid>
										<Grid item>
											<Box className={classes.fileName}>
												<Skeleton variant='text' width={150} />
											</Box>
										</Grid>
									</Grid>
								</Grid>
							))
						) : dataPayment.payment_document_ids.length != 0 ? (
							dataPayment.payment_document_ids.map((item, index) => (
								<Grid item lg={2} key={index} className={classes.margintumbhnail}>
									{displayDownloadReceipt(
										item.file_store_name,
										item.name,
										item.file_name,
										item.file_store_mimetype
									)}
								</Grid>
							))
						) : (
							<Grid item container justifyContent='center' alignItems='center'>
								<Typography style={{ textAlign: 'center', color: '#a2a4a7' }}>
									Tidak ada dokumen yang terlampir
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		)
	}

	return (
		<Layout className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>
				{/* bottom form */}
				<Box>
					<Grid style={{ display: 'flex' }}>
						{dataPayment.id && (
							<PrintReport ext='pdf' reportType='payment' reportID={dataPayment.id} />
						)}
						<Typography variant='h6'>Rincian Pembayaran</Typography>
					</Grid>
					<Paper>
						<Box py={2} px={3}>
							{/* table */}
							<Box>{generateDetailTable()}</Box>
							{/* end of table */}
						</Box>
					</Paper>
				</Box>
				{/* end of bottom form */}

				<Box mt={2}>
					<Paper>
						<Box py={2} px={3}>
							<Box>{generateDetail()}</Box>
						</Box>
					</Paper>
				</Box>
			</Container>
			<ScrollToTop />
		</Layout>
	)
}

Component.propTypes = {
	paymentID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	// navigate: PropTypes.func,
}

export default React.memo(Component)
