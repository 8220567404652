import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dashboard,
	},
	container: {
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		flex: 1,
	},
	containerRoot: {
		padding: theme.spacing(3, 12),
	},
	selectContainer: {
		borderRadius: theme.shape.borderRadius,
		paddingRight: '45px !important',
		marginRight: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	selectInput: {
		padding: '7px 0 6px 12px !important',
	},
	inputRoot: {
		paddingRight: '0 !important',
		width: 'calc(100% - 20px)',
	},
	endAdornment: {
		top: 0,
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		width: '32px',
		top: 'inherit',
	},
	boxSpacing: {
		marginTop: '15px',
		height: 400,
		marginBottom: '15px',
	},
	statusButton: ({ variant }) => ({
		backgroundColor: !variant ? '#fff' : theme.palette.status['darkBlue'],
		color: !variant ? theme.palette.status.blue : theme.palette.status.text1,
		fontSize: '0.8rem',
		padding: theme.spacing(0.6, 1),
		borderRadius: theme.shape.borderRadius,
		textAlign: 'center',
		opacity: 1,
		marginRight: theme.spacing(2),
		'&:hover': {
			backgroundColor: !variant ? '#fff' : theme.palette.status['darkBlue'],
			color: !variant ? theme.palette.status.blue : theme.palette.status.text1,
			opacity: 0.8,
		},
		'&:last-child': {
			marginRight: theme.spacing(0),
		},
	}),
	buttonStyle: {
		width: 250,
		height: 35,
	},
	selectColumns: {
		width: '100%',
		borderRadius: 5,
		overflow: 'hidden',
	},
	muiSelect: {
		paddingBottom: 8,
		'&:focus': {
			backgroundColor: 'white!important',
		},
	},
	icon: {
		backgroundColor: theme.palette.secondary['main'],
		color: theme.palette.secondary['contrastText'],
		top: 0,
		right: 0,
		height: 38,
		width: 37,
	},
	headerSelect: {
		position: 'sticky',
		zIndex: 2,
		top: 0,
		backgroundColor: 'white!important',
		'&:hover': {
			backgroundColor: 'white!important',
		},
	},
	selected: {
		backgroundColor: 'white!important',
		color: 'black',
	},
	itemList: {
		backgroundColor: 'white!important',
		'&:hover': {
			backgroundColor: 'white!important',
		},
		color: 'black',
	},
	labelHeader: {
		fontSize: 14,
	},
	paperSelect: {
		maxHeight: 330,
	},
	buttonUnduh: {
		margin: theme.spacing(1),
	},
	rowButtonUnduh: {
		marginTop: 0,
		marginLeft: 10,
	},
	marginTable: {
		margin: 10,
	},
	tooltip: {
		maxWidth: 200,
	},
}))

export default styles
