import React, { useState } from 'react'
// import PDFViewer from 'pdf-viewer-reactjs'
import './custom.css'
import './sample.scss'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'

// const exampleWrapperStyle = {
// 	width: '80%',
// 	margin: 'auto',
// }
// function component() {
// 	return (
// 		<div style={exampleWrapperStyle}>
// 			<PDFViewer
// 				document={{
// 					// url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
// 				}}
// 				canvasCss='customViewer'
// 				scale={2}
// 				hideRotation
// 				hideZoom
// 			/>
// 		</div>
// 	)
// }

// export default component

const options = {
	cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
	cMapPacked: true,
}

function MyApp(props) {
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [loading, setLoading] = useState(true)
	const imageStorage = process.env.GATSBY_IMAGE_URL
	// const fileStorage = process.env.GATSBY_FILESTORAGE

	function onDocumentLoadSuccess({ numPages, ...others }) {
		console.log(others)
		setNumPages(numPages)
		setLoading(false)
	}

	function hanldeNext() {
		if (pageNumber >= numPages) return false
		setPageNumber((pageNumber) => pageNumber + 1)
	}

	function handlePrev() {
		if (pageNumber <= 1) return false
		setPageNumber((pageNumber) => pageNumber - 1)
	}

	function ongetText(params) {
		console.log('getext', params)
	}

	return (
		<div className='pdf_wrapper'>
			<div className='header'>
				{loading ? null : (
					<>
						<button onClick={() => handlePrev()}>prev</button>
						<p>{`${pageNumber} of ${numPages}`}</p>
						<button onClick={() => hanldeNext()}>next</button>
					</>
				)}
			</div>
			<Document
				file={`${imageStorage}/180x180/erp/${props.documentId}`}
				onLoadSuccess={onDocumentLoadSuccess}
				options={options}
				loading={<CircularProgress value={50} size={60} thickness={4} />}
			>
				<Page pageNumber={pageNumber} scale={1.5} onLoadProgress={ongetText} />
			</Document>
		</div>
	)
}

MyApp.propTypes = {
	documentId: PropTypes.string,
}

export default MyApp
