import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	Paper,
	TextField,
	Button,
	CircularProgress,
	Collapse,
	Select,
	MenuItem,
	Container,
} from '@material-ui/core'
import Breadcrumbs from 'Components/breadcrumbs'
import { ApprovalAddStyles } from './styles'
import { navigate } from 'gatsby'
import AutoComplete from '@material-ui/lab/Autocomplete'
import { requestUserAccountList } from 'States/actions/UsersAccount/listAction'
import { requestAECreate } from 'States/actions/Approval/Eproc/AECreateAction'
import { requestAEList } from 'States/actions/Approval/Eproc/AEListAction'

import { HighlightOffOutlined } from '@material-ui/icons'
import Alert from 'Components/alert'

// import { requestListFilterCategory } from 'States/actions/listFilterCategoryAction'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import schema from '../schema'
import { filterUserApproval } from '../../../../utils/globalUtils'
import CustomModal from 'Components/modal'

const ApprovalEprocAdd = () => {
	const classes = ApprovalAddStyles()

	const dispatch = useDispatch()
	const listApprovalReducer = useSelector((state) => state.AEListReducer)
	const listUserReducer = useSelector((state) => state.UsersAccountListReducer)
	const responseData = useSelector((state) => state.AECreateReducer)

	const [listUser, setListUser] = useState([])
	const [listLevel, setListLevel] = useState([{ level: 1 }])
	const [errorBackend, setErrorBackend] = useState({})
	const [modalNotif, setModalNotif] = useState(false)
	const [notifError, setNotifError] = useState(false)
	const [notifSuccess, setNotifSuccess] = useState(false)

	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '/dashboard',
		},
		{
			segment: 'Manajemen Persetujuan',
			link: '/dashboard/approval/eproc',
		},
		{
			segment: 'Tambah E-Procurement',
			link: '#',
		},
	])

	const queryStringUser = { limit: 100, status: 99, role_id: 7 }

	useEffect(() => {
		let queryString = { limit: 200, status: 99 }
		dispatch(requestUserAccountList({ queryString: queryStringUser }))
		dispatch(requestAEList({ queryString: queryString }))
	}, [])

	// list approver
	useEffect(() => {
		// success
		if (listApprovalReducer.data) {
			let result = listApprovalReducer.data.items
			if (result) {
				let arrLevel = []
				for (let i = 0; i < result.length; i++) {
					arrLevel.push(result[i].level)
				}
				if (arrLevel.length > 0) {
					let resultLevel = []
					let maxLevel = Math.max(...arrLevel)
					for (let i = 0; i < maxLevel + 1; i++) {
						resultLevel.push({ level: i + 1 })
					}

					setListLevel(resultLevel)
					// let missingNumber = findMissingNumber(arrLevel)
					// setListLevel([{ level: missingNumber }])
				}
			}
		}
	}, [listApprovalReducer])

	// hooks for set default state
	useEffect(() => {
		if (listUserReducer.data) {
			let result = listUserReducer.data.items
			if (result) {
				setListUser(filterUserApproval(result))
			}
		} else {
			setListUser([])
		}
	}, [listUserReducer])

	// get error_backend and parse
	useEffect(() => {
		var tmpErrorBackend
		if (responseData.error) {
			if (responseData.error.http_body) {
				let http_body = responseData.error.http_body
				if (http_body) {
					let body_obj = JSON.parse(http_body)
					tmpErrorBackend = body_obj
					setErrorBackend(body_obj)
				}
			}
		}

		// set notif error
		if (
			(tmpErrorBackend &&
				tmpErrorBackend.error &&
				// containsString(tmpErrorBackend.error.message, ['Error', 'error']) &&
				tmpErrorBackend.error.message) ||
			responseData.isError
		) {
			setNotifError(true)
		}

		// set notif success
		if (
			!responseData.isError &&
			responseData.data &&
			responseData.data.message != ''
		) {
			setNotifSuccess(true)
		}

		// show modal
		if (responseData.data != null) {
			setModalNotif(true)
		}
	}, [responseData])

	useEffect(() => {
		dispatch(requestAECreate({}, 'RESET'))
	}, [])

	const handleOptionLabel = (option) => {
		return option.name
	}

	const handleSubmit = (formdata) => {
		let dataCreate = {}
		dataCreate.user_id = formdata.user.id
		dataCreate.level = formdata.level

		dispatch(requestAECreate(dataCreate))
	}

	const handleModalOK = () => {
		setModalNotif(false)
		if (notifSuccess) {
			dispatch(requestAECreate({}, 'RESET'))
			navigate('/dashboard/approval/eproc')
		}
	}

	const handleChangeFullname = (value) => {
		if (!value || value == null) {
			value = ''
		}
		if (value.length > 2) {
			let queryString = { ...queryStringUser, keyword: value }
			dispatch(requestUserAccountList({ queryString: queryString }))
		}
	}

	return (
		<Formik
			enableReinitialize
			initialValues={{ user: '', level: 'none' }}
			validationSchema={schema}
			onSubmit={(values) => handleSubmit(values)} //handleSubmit(values, setSubmitting)
		>
			{({
				values,
				errors,
				touched,
				// handleChange,
				// submitForm,
				// validateForm,
				...others
			}) => {
				// console.log('values: ', values)
				return (
					<>
						<Form method='post'>
							<DashboardLayout>
								<Container>
									<Box>
										<Breadcrumbs breadcrumbs={breadcrums} />
									</Box>
									<Box mt={2}>
										<Typography variant='h6'>
											Manajemen Persetujuan - Tambah E-Procurement
										</Typography>
									</Box>
									<Box>
										<Collapse in={notifError}>
											<Alert iconClose={<HighlightOffOutlined />} variant={'alert'}>
												<Typography variant='body1'>
													{(errorBackend.error && errorBackend.error.message) ||
														'Terjadi kesalahan server'}

													{/* {responseData.isError && 'Terjadi kesalahan server'} */}
												</Typography>
											</Alert>
										</Collapse>
										<Collapse in={notifSuccess}>
											<Alert iconClose={<HighlightOffOutlined />} variant={'success'}>
												<Typography variant='body1'>
													{notifSuccess && 'Success Created.'}
												</Typography>
											</Alert>
										</Collapse>
									</Box>
									<Box mt={2}>
										<Paper>
											<Box p={1}>
												<Grid container spacing={3}>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Nama Lengkap</Typography>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<AutoComplete
																id='user'
																name='user'
																options={listUser}
																renderOption={(option) => option.name}
																getOptionLabel={(option) => handleOptionLabel(option)}
																onChange={(e, v, r) => {
																	if (r == 'select-option') {
																		others.setFieldValue('user', v)
																	}
																}}
																onInputChange={(e, v, r) => {
																	if (r == 'clear' || r == 'input') {
																		handleChangeFullname(v)
																		others.setFieldValue('user', '')
																	}
																}}
																onClose={(e, r) => {
																	if (r != 'select-option') {
																		others.setFieldValue('user', '')
																	}
																}}
																style={{ width: '100%' }}
																renderInput={(params) => (
																	<Field name='user'>
																		{({ field }) => (
																			<TextField
																				placeholder='Pilih User'
																				// onChange={e => handleChangeListUser(e)}
																				// onBlur={e => handleChangeListUser(e)}
																				fullWidth
																				margin='dense'
																				variant='outlined'
																				error={errors.user && touched.user}
																				{...params}
																				{...field}
																			/>
																		)}
																	</Field>
																)}
															/>
															<span style={{ color: 'red' }}>
																<ErrorMessage name='user' />
															</span>
														</Grid>
													</Grid>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Level</Typography>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<Field name='level'>
																{({ field }) => (
																	<Select
																		{...field}
																		variant='outlined'
																		placeholder='Pilih Level'
																		error={errors.level && touched.level}
																		value={values.level}
																		onChange={(event) =>
																			others.setFieldValue('level', event.target.value)
																		}
																		style={{
																			width: '100%',
																			color: values.level == 'none' ? '#ada5a5' : 'black',
																		}}
																	>
																		{values.level == 'none' && (
																			<MenuItem key={values.level} value={values.level} disabled>
																				Pilih Level
																			</MenuItem>
																		)}

																		{listLevel.length > 0 &&
																			listLevel.map((row, index) => {
																				return (
																					<MenuItem key={index} value={row.level}>
																						{row.level}
																					</MenuItem>
																				)
																			})}
																	</Select>
																)}
															</Field>
															<span style={{ color: 'red' }}>
																<ErrorMessage name='level' />
															</span>
														</Grid>
													</Grid>
												</Grid>
												<Grid container spacing={3}>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Username</Typography>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<TextField
																variant='outlined'
																margin='dense'
																placeholder='Username'
																fullWidth
																value={(values.user && values.user.username) || ''}
																// inputProps={{
																// 	readOnly: true,
																// }}
																disabled={true}
															></TextField>
														</Grid>
													</Grid>
												</Grid>
												<Grid container spacing={3}>
													<Grid container item xs={6}>
														<Grid container item alignItems='center' xs={4}>
															<Typography variant='subtitle1'>Email</Typography>
														</Grid>
														<Grid container item alignItems='center' xs={8}>
															<TextField
																variant='outlined'
																margin='dense'
																type='email'
																placeholder='Email'
																value={(values.user && values.user.email) || ''}
																fullWidth
																// inputProps={{
																// 	readOnly: true,
																// }}
																disabled={true}
															></TextField>
														</Grid>
													</Grid>
												</Grid>
											</Box>
										</Paper>
										<Box mt={2}>
											<Grid container justify='flex-end'>
												<Grid item>
													<Box>
														<Button
															size='medium'
															className={`${classes.buttonAction} ${classes.btnCancel}`}
															onClick={() => {
																navigate('/dashboard/approval/eproc')
															}}
														>
															Batal
														</Button>
														<Button
															type='submit'
															size='medium'
															className={`${classes.buttonAction} ${classes.btnSave}`}
															disabled={responseData.isLoading}
														>
															Simpan{' '}
															{responseData.isLoading && (
																<CircularProgress size={14} style={{ marginLeft: '5px' }} />
															)}
														</Button>
													</Box>
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Container>
							</DashboardLayout>
						</Form>
						<CustomModal
							icon={notifSuccess ? 'success' : 'error'}
							open={modalNotif}
							onClose={() => setModalNotif(false)}
						>
							<Typography variant='h6'>
								{notifSuccess ? (
									<strong>Tambah Penyetuju Eproc Berhasil</strong>
								) : (
									<strong>Tambah Penyetuju Eproc Gagal</strong>
								)}
							</Typography>
							<Box
								display='flex'
								flexDirection='row'
								mt={3}
								justifyContent='center'
								// width={300}
							>
								<Button
									className={classes.buttonWidth}
									size='small'
									classes={{
										contained: classes.buttonDanger,
									}}
									variant='contained'
									onClick={() => handleModalOK()}
								>
									{' '}
									OK{' '}
								</Button>
							</Box>
						</CustomModal>
					</>
				)
			}}
		</Formik>
	)
}

export default ApprovalEprocAdd
