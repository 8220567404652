import { MOU_DELIVERY_ADDRESS, SET_DEFAULT } from '../../types'
import API from '../../../utils/api'

const loading = () => {
	return {
		type: `${MOU_DELIVERY_ADDRESS}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${MOU_DELIVERY_ADDRESS}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${MOU_DELIVERY_ADDRESS}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

const setDefault = (addressType, data) => {
	return {
		type: `${SET_DEFAULT}_${addressType}`,
		payload: {
			data: data.data.result[0],
		},
	}
}

export const setAddress = (addressType, data) => {
	return {
		type: `${SET_DEFAULT}_${addressType}`,
		payload: {
			data,
		},
	}
}

export const requestMOUDelivery = (
	id,
	isDefault,
	page = '',
	state_id = '',
	name = '',
	city = ''
) => {
	return async (dispatch) => {
		dispatch(loading())
		try {
			let response = await API({
				method: 'GET',
				url: `/mou/delivery-address/${id}?ts=${new Date().getTime()}&active=true&page=${page}&page_size=4&state_id=${state_id}&name=${name}&city=${city}`,
			})

			if (response) {
				if (response.data.error_backend) {
					dispatch(failure(response.data.error_backend))
				} else {
					if (response.data && isDefault) {
						dispatch(setDefault('MOU_DELIVERY_ADDRESS', response.data))
					}
					dispatch(success(response.data))
				}
			}
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
