import { APPROVAL_CATEGORY_LIST } from '../../../types'
import API from '../../../../utils/api'
import { newUriAPI } from '../../../../utils/globalUtils'

const loading = () => {
	return {
		type: `${APPROVAL_CATEGORY_LIST}_PENDING`,
	}
}

const failure = (error) => {
	return {
		type: `${APPROVAL_CATEGORY_LIST}_REJECTED`,
		payload: {
			error,
		},
	}
}

const success = (data) => {
	return {
		type: `${APPROVAL_CATEGORY_LIST}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestACList = ({ queryString }) => {
	function generateParams(queryString) {
		let uriAPIStr = newUriAPI(queryString)
		let filter = {}
		let query = {}

		Object.keys(queryString).forEach((key) => {
			if (queryString[key] != '') {
				switch (key) {
					case ('status', 'state'):
						filter.status = '' + queryString[key]
						break
					case 'category_id':
						query.category_id = '' + queryString[key]
						break
					case 'keyword':
						query.keyword = queryString[key]
						break
					default:
						'nothing'
				}
			}
		})

		if (Object.keys(filter).length > 0) {
			uriAPIStr = uriAPIStr + '&filter=' + JSON.stringify(filter)
		}

		if (Object.keys(query).length > 0) {
			uriAPIStr = uriAPIStr + '&query=' + JSON.stringify(query)
		}

		return uriAPIStr
	}

	return async (dispatch) => {
		dispatch(loading())
		try {
			let url = ''
			if (queryString) url = generateParams(queryString)

			let response = await API({
				method: 'GET',
				url: '/approvers/list/categories' + (url != '' ? '?' + url : ''),
			})

			dispatch(success(response.data))
		} catch (err) {
			dispatch(failure(err))
		}
	}
}
