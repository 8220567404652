/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import PropTypes from 'prop-types'
import {
	Box,
	Grid,
	Typography,
	TextField,
	Button,
	Container,
	IconButton,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Edit, SearchRounded, List } from '@material-ui/icons'
import { daftarakunStyle } from './styles'
import { navigate } from 'gatsby'
import Breadcrumbs from 'Components/breadcrumbs'
import * as qs from 'query-string'
import { requestUserAccountList } from 'States/actions/UsersAccount/listAction'
import { requestDepartmentList } from 'States/actions/Company/Department/listAction'
import { newUri, arrObjectToStr } from '../../../utils/globalUtils'
import TableData from 'Components/tableData'
import Status from 'Components/status'

// const departments = [{ title: 'Staff' }, { title: 'Boss' }, { title: 'CTO' }]
const roles = [
	{ id: 6, title: 'Admin' },
	{ id: 7, title: 'Penyetuju' },
	{ id: 8, title: 'Finance' },
	{ id: 9, title: 'Pemohon' },
]

// breadcumbs
const breadcrumbs = [
	{
		segment: 'Home',
		link: '/dashboard',
	},
	{
		segment: 'Perusahaan',
		link: '/dashboard',
	},
	{
		segment: 'Daftar Akun Pengguna',
		link: '/dashboard/user-account',
	},
]

const buttonStatus = [
	{
		status: 'all_status',
		state: '',
	},
	{
		status: '99',
		state: '99',
	},
	{
		status: '98',
		state: '98',
	},
]

const limitValue = [10, 25, 50, 100]

const Daftarakun = (props) => {
	const classes = daftarakunStyle()

	const dispatch = useDispatch()
	const usersAccount = useSelector((state) => state.UsersAccountListReducer)
	const departmentList = useSelector((state) => state.departmentListReducer)

	// THIS FOR DATATABLE
	const [dataUA, setDataUA] = useState([])
	const queryString = qs.parse(props.location.search)

	const [limit, setLimit] = useState(queryString.limit ? queryString.limit : 10)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [keyword, setKeyword] = useState(
		queryString.keyword ? queryString.keyword : ''
	)
	const [roleID, setRoleID] = useState(
		queryString.role_id ? queryString.role_id : ''
	)
	const [departmentID, setDepartmentID] = useState(
		queryString.department_id ? queryString.department_id : ''
	)
	const [status, setStatus] = useState(
		queryString.status ? queryString.status : ''
	)
	const [selectedDepartment, setSelectedDepartment] = useState(null)

	queryString.limit = limit
	queryString.page = page
	queryString.keyword = keyword
	queryString.role_id = roleID
	queryString.department_id = departmentID
	queryString.status = status

	const columns = [
		{
			name: 'NAMA LENGKAP',
			selector: 'name',
			sortable: true,
			grow: 2,
			cell: (row) => {
				return row.name
			},
		},
		{
			name: 'USERNAME',
			selector: 'username',
			sortable: true,
			grow: 2,
			cell: (row) => {
				return row.username
			},
		},
		{
			name: 'EMAIL',
			selector: 'email',
			sortable: true,
			grow: 2,
			cell: (row) => {
				return row.email
			},
		},
		{
			name: 'ROLE',
			selector: 'user_buyer_roles',
			sortable: true,
			grow: 2,
			cell: (row) => {
				if (row.user_buyer_roles.length > 0) {
					let listRoles = []
					row.user_buyer_roles.forEach((items) => {
						listRoles.push(items.user_roles)
					})
					return arrObjectToStr(listRoles, 'role_name', ', ')
				} else {
					;('')
				}
			},
		},
		// {
		// 	name: 'DEPARTMENT',
		// 	selector: 'department.name',
		// 	sortable: true,
		// 	grow: 2,
		// },
		{
			name: 'STATUS',
			selector: 'status',
			sortable: true,
			cell: (row) => <Status status={row.status.toString()} />,
			// cell: row => (row.status == 99 ? 'Aktif' : 'Tidak Aktif'),
		},
		{
			name: 'AKSI',
			selector: 'id',
			sortable: true,
			hide: 'md',
			cell: (row) => buttonGenerator(row),
		},
	]

	const buttonGenerator = (row) => (
		<>
			<Button
				variant='contained'
				size='small'
				className={`${classes.buttonAction} ${classes.btnEdit}`}
				onClick={() => {
					navigate('/dashboard/user-account/edit/' + row.id)
				}}
			>
				<Edit fontSize='small' />
			</Button>
			<Button
				variant='contained'
				size='small'
				className={`${classes.buttonAction} ${classes.btnEdit}`}
				onClick={() => {
					navigate('/dashboard/user-account/' + row.id)
				}}
			>
				<List fontSize='small' />
			</Button>
		</>
	)

	useEffect(() => {
		submitSearch()
		dispatch(
			requestDepartmentList({
				queryString: { limit: 200, filter: '{"status":"99"}' },
			})
		)
	}, [])

	// hooks for set default state
	useEffect(() => {
		// success
		if (usersAccount.data) {
			let result = usersAccount.data.items
			if (result) {
				setDataUA(result)
			}
		} else {
			setDataUA([])
		}
	}, [usersAccount])

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	const handlePageChange = (event, value) => {
		setPage(value)
		queryString.page = value
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setLimit(event.target.value)
		queryString.limit = event.target.value
		let maxPage = Math.ceil(
			usersAccount.data.pagination.total_record / queryString.limit
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		submitSearch()
	}

	const handleClickStatus = (event, btnState) => {
		setStatus(btnState)
		setPage(1)
		queryString.status = btnState
		queryString.page = 1
		let query = newUri(queryString)
		dispatch(requestUserAccountList({ queryString }))
		propsNavigate(query)
	}

	const handleChangeInputSearch = (e) => {
		let value = e.target.value.trim()
		setKeyword(value)
		setPage(1)
		queryString.keyword = value
		queryString.page = 1
	}

	const handleEnterInputSearch = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => submitSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [keyword])

	const submitSearch = () => {
		let query = newUri(queryString)
		dispatch(requestUserAccountList({ queryString }))
		propsNavigate(query)
	}

	const handleChangeRoles = (_opt, val) => {
		setRoleID(val ? val.id : '')
		setPage(1)
		queryString.role_id = val ? val.id : ''
		queryString.page = 1
		submitSearch()
	}

	useEffect(() => {
		if (
			!departmentList.isLoading &&
			departmentList.data.items.length &&
			departmentID
		) {
			setSelectedDepartment(
				departmentList.data.items.find((val) => val.id == departmentID)
			)
		}
	}, [departmentList.isLoading, departmentList.data.items])

	const handleChangeDepartment = (_opt, val) => {
		setDepartmentID(val ? val.id : '')
		setSelectedDepartment(
			val ? departmentList.data.items.find((data) => data.id == val.id) : null
		)
		setPage(1)
		queryString.page = 1
		queryString.department_id = val ? val.id : ''
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const findData = (data, id) => {
		let res = data.length != 0 && data.find((element) => element.id == id)
		return res ? res : null
	}

	return (
		<DashboardLayout>
			<Container style={{ overflow: 'hidden' }}>
				<Box height={70} alignItems='center' display='flex'>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>
				<Box p={1}>
					<Box mb={2}>
						<Grid container justify='space-between'>
							<Grid item>
								<Typography variant='h6'>Daftar Akun Pengguna</Typography>
							</Grid>
							<Grid item>
								<Button
									id='btn-add-account'
									variant='contained'
									color='secondary'
									size='small'
									onClick={(event) => {
										event.preventDefault()
										navigate('/dashboard/user-account/add', {
											replace: true,
										})
									}}
								>
									Tambah Akun Pengguna
								</Button>
							</Grid>
						</Grid>
					</Box>

					<Box mb={2}>
						<Grid container spacing={0}>
							{/* 							<Grid item xs={4}>
								<Grid item>
									<Autocomplete
										id='department'
										options={departments}
										getOptionLabel={option => option.title}
										classes={{
											input: classes.selectInput,
											inputRoot: classes.inputRoot,
											endAdornment: classes.endAdornment,
											popupIndicator: classes.selectIcon,
										}}
										renderInput={params => (
											<TextField
												{...params}
												placeholder='Departement'
												style={{ width: 300 }}
											/>
										)}
									/>
								</Grid>
							</Grid> */}
							<Grid item xs={3}>
								<Grid item>
									<Autocomplete
										id='roles'
										options={roles}
										getOptionLabel={(option) => option.title}
										classes={{
											input: classes.selectInput,
											inputRoot: classes.inputRoot,
											endAdornment: classes.endAdornment,
											popupIndicator: classes.selectIcon,
										}}
										renderInput={(params) => (
											<TextField {...params} placeholder='Roles' style={{ width: 250 }} />
										)}
										onChange={handleChangeRoles}
										value={findData(roles, queryString.role_id)}
									/>
								</Grid>
							</Grid>
							<Grid item xs={3}>
								<Grid item>
									<Autocomplete
										id='departemen'
										loading={departmentList.isLoading}
										options={departmentList.data.items}
										getOptionLabel={(option) => option.name}
										getOptionSelected={(opt, value) => opt.name == value.name}
										classes={{
											input: classes.selectInput,
											inputRoot: classes.inputRoot,
											endAdornment: classes.endAdornment,
											popupIndicator: classes.selectIcon,
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												placeholder='Departemen Pembelian'
												style={{ width: 250 }}
											/>
										)}
										onChange={handleChangeDepartment}
										value={selectedDepartment}
									/>
								</Grid>
							</Grid>
							<Grid item xs={4}>
								<Grid item className={classes.gridSearch}>
									<TextField
										placeholder='Cari Nama Lengkap / Email / Username'
										type='search'
										InputProps={{ disableUnderline: false }}
										defaultValue={queryString.keyword || ''}
										onChange={(e) => handleChangeInputSearch(e)}
										onKeyDown={(e) => handleEnterInputSearch(e)}
										onBlur={(e) => handleChangeInputSearch(e)}
										className={classes.searchDepartment}
									/>
									<IconButton
										className={classes.buttonSearch}
										variant='contained'
										color='primary'
										onClick={() => submitSearch()}
									>
										<SearchRounded fontSize='small' />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<TableData
						buttonState={buttonStatus}
						buttonStateClick={handleClickStatus}
						limitValue={limitValue}
						perPage={limit}
						limitChange={handlePerRowsChange}
						columns={columns}
						isLoading={usersAccount.isLoading}
						pagination={usersAccount.data ? usersAccount.data.pagination : {}}
						data={dataUA}
						name={'Akun Pengguna'}
						paginationPageChange={handlePageChange}
						queryState={queryString.status}
						pageNow={parseInt(queryString.page)}
						labelState='Status'
					/>
					<br />
				</Box>
			</Container>
		</DashboardLayout>
	)
}

Daftarakun.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default Daftarakun
