import { DETAIL_PURCHASE_ORDER } from '../../../types'
import API from '../../../../utils/api'
import { success, failure, loading } from '../../../commonActions'
import { navigate } from 'gatsby'

export const requestDetailPo = (poId) => {
	return async (dispatch) => {
		dispatch(loading(DETAIL_PURCHASE_ORDER))
		try {
			const response = await API({
				url: `/purchase-orders/rfqs/detail/${poId}`,
			})
			if (response.data) {
				if ('error_backend' in response.data) {
					navigate(`/${response.data.error_backend.http_status_code}`)
				} else {
					dispatch(success(DETAIL_PURCHASE_ORDER, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(DETAIL_PURCHASE_ORDER))
		}
	}
}

export const resetAction = () => {
	return {
		type: `${DETAIL_PURCHASE_ORDER}_RESET`,
	}
}