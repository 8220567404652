/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import {
	Daftarakun,
	AdduserAccount,
	EdituserAccount,
	DetailuserAccount,
	DocumentOnprocess,
} from '../routes'
import { rolesText } from '../constants/text'

export const AccountNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard/user-account'
				roles={rolesText('admin')}
				Component={Daftarakun}
			/>
			<PrivateRoute
				path='/dashboard/user-account/add'
				roles={rolesText('admin')}
				Component={AdduserAccount}
			/>
			<PrivateRoute
				path='/dashboard/user-account/edit/:userID'
				roles={rolesText('admin')}
				Component={EdituserAccount}
			/>
			<PrivateRoute
				path='/dashboard/user-account/:userID'
				roles={rolesText('admin')}
				Component={DetailuserAccount}
			/>
			<PrivateRoute
				path='/dashboard/user-account/:userID/document-onprocess'
				roles={rolesText('admin')}
				Component={DocumentOnprocess}
			/>
		</>
	)
}
