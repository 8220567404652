export const buttonFilter = [
	{
		status: 'all_type',
		state: '',
		child: false,
	},
	{
		status: 'proforma',
		state: 'proforma',
		child: false,
	},
	{
		status: 'posted',
		state: 'posted',
		child: true,
	},
	{
		status: 'revised_invoice',
		state: 'revised_invoice',
		child: true,
	},
]

export const buttonStatus = [
	{
		status: 'Semua Status',
		state: '',
	},
	{
		status: 'Belum Dibayar',
		state: 'not_paid',
	},
	{
		status: 'Telah Dibayar',
		state: 'paid',
	},
]
