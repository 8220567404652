import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
	buttonSearch: {
		padding: '4.5px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: theme.shape.borderRadius,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	marginMinus: {
		marginRight: '-5px',
	},
	gridSearch: {
		width: '100%',
	},
	rootBreadcumb: {
		'& > svg': {
			margin: theme.spacing(0),
		},
		padding: '10px',
	},
	customButton: {
		fontSize: '12px',
		marginRight: '10px',
	},
	buttonDetail: {
		fontSize: '12px',
	},
	iconAction: {
		fontSize: '22px',
	},
	textCenter: {
		textAlign: 'center',
	},
	buttonAction: {
		padding: theme.spacing(0.5, 1),
		minWidth: 'initial',
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		width: '32px',
		top: 'inherit',
	},
	selectContainer: {
		borderRadius: theme.shape.borderRadius,
		paddingRight: '45px !important',
	},
	typoPage: {
		paddingTop: theme.spacing(0.5),
		marginRight: theme.spacing(2),
	},
	container: {
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		flex: 1,
	},
}))

export default useStyles
