import * as Yup from 'yup'

const schemaRfq = Yup.object().shape({
	noRef: Yup.string(),
	noTeleponPemohon: Yup.number().typeError('Input harus angka'),
	keperluan: Yup.string(),
	namaPenerima: Yup.string().required('Nama penerima harus di isi'),
	receiverPhone: Yup.string().required('Nomor Telepon Penerima harus diisi'),
})

export default schemaRfq
