import React from 'react'
import LayoutErrorPage from '../../../components/layoutErrorPage'
import LinkExpired from '../../../assets/images/content/TautanSudahTidakTersedia.png'
import { Typography, Box, Button } from '@material-ui/core'
import { navigate } from '@reach/router'

const OnboardingExpiredLink = () => {
	const handleNavigate = (path) => {
		navigate(path)
	}
	let queryString = ''

	if (typeof window !== 'undefined') {
		queryString = window.location.pathname
	}

	return (
		<LayoutErrorPage variant='light'>
			<Box>
				<img src={LinkExpired} style={{ width: '40%' }} />
				<Box mt={2} mb={5}>
					<Typography variant='h6'>
						<b>Tautan yang Anda tuju sudah kedaluwarsa</b>
					</Typography>
					<Typography variant='body1' style={{ cursor: 'pointer' }}>
						{queryString == '/reset-password-link-expired'
							? 'Silakan lakukan permintaan reset kata sandi kembali.'
							: 'Silakan menghubungi Mbiz untuk mendapatkan kembali email pendaftaran akun Mbiz.co.id'}
					</Typography>
				</Box>
				<Button
					variant='contained'
					color='secondary'
					onClick={() =>
						handleNavigate(
							queryString == '/reset-password-link-expired'
								? '/forgot-password'
								: '/contact-us'
						)
					}
				>
					<Typography variant='body1' style={{ textTransform: 'capitalize' }}>
						{queryString == '/reset-password-link-expired'
							? 'Reset Kata Sandi'
							: 'Hubungi Mbiz'}
					</Typography>
				</Button>
			</Box>
		</LayoutErrorPage>
	)
}

export default OnboardingExpiredLink
