/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import {
	Dashboard,
	NotAuthorized,
	Home,
	Contact,
	About,
	Search,
	Account,
	Login,
	Detail,
	Categories,
	Register,
	ForgotPassword,
	ResetPassword,
	HowTo,
	ComplaintId,
	ComplaintEn,
	TermAndConditionsId,
	TermAndConditionsEn,
	PrivacyPolicyId,
	PrivacyPolicyEn,
	Document,
	Promo,
	OnboardingExpiredLink,
	LinkNotAvailable,
	LinkNotFound,
} from '../routes'
import Notfound from '../pages/404'
import InternalServerError from '../pages/500'
import { rolesText } from '../constants/text'

export const GeneralNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard'
				roles={rolesText('all')}
				Component={Dashboard}
			/>
			<NotAuthorized path='/401' />
			<Home path='/' />
			<Contact path='/contact-us' />
			<About path='/pages/about' />
			<Search path='/catalog/:slug' />
			<Account path='/onboarding' />
			<Login path='/login' />
			<Register path='/register' />
			<Categories path='/categories' />
			<Detail path='/catalog/detail/:productId' />
			<ForgotPassword path='/forgot-password' />
			<ResetPassword path='/reset-password' />
			<HowTo path='/pages/how-to' />
			<ComplaintId path='/pages/id/complaint' />
			<ComplaintEn path='/pages/en/complaint' />
			<TermAndConditionsId path='/pages/id/term-and-condition' />
			<TermAndConditionsEn path='/pages/en/term-and-condition' />
			<PrivacyPolicyId path='/pages/id/privacy-policy' />
			<PrivacyPolicyEn path='/pages/en/privacy-policy' />
			<InternalServerError path='/500' />
			<Document path='/document/:documentId' />
			<Promo path='/pages/:tagName' />
			<Notfound default />

			<OnboardingExpiredLink path='/onboarding-link-expired' />
			<OnboardingExpiredLink path='/reset-password-link-expired' />
			<LinkNotAvailable path='/link-not-available' />
			<LinkNotFound path='/link-not-found' />
		</>
	)
}
