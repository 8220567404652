import * as Yup from 'yup'
const err_msg = {
	require: {
		nama: 'Nama Lengkap tidak boleh kosong, maksimal 50 karakter.',
		email: 'Alamat email tidak boleh kosong, maksimal 50 karakter.',
		judul: 'Judul tidak boleh kosong, maksimal 100 karakter.',
		pesan: 'Pesan Anda tidak boleh kosong, maksimal 3000 karakter.',
		captcha: 'Captcha harus dipilih',
	},
	matches: {
		nama:
			'Tidak boleh ada spasi di awal dan akhir nama, Nama hanya boleh diisi oleh huruf',
	},
	email: {
		email: 'Email Tidak Valid ! Format email xxx@xxx.xxx,',
		// email: 'format_not_valid',
	},
}

const schema = Yup.object().shape({
	nama: Yup.string()
		.required(err_msg.require.nama)
		.matches(/^([a-zA-Z]+\s)*[a-zA-Z]+$/, err_msg.matches.nama),
	email: Yup.string().email(err_msg.email.email).required(err_msg.require.email),
	judul: Yup.string().required(err_msg.require.judul),
	pesan: Yup.string().required(err_msg.require.pesan),
	captcha: Yup.string().nullable().required(err_msg.require.captcha),
})

export default schema
