import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dashboard,
	},
	containerRoot: {
		padding: theme.spacing(0, 12),
		[theme.breakpoints.down('md')]: {
			padding: 0,
		},
	},
	datepicker: {
		width: 227,
	},
	tableRoot: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
	},
	totalPrice: {
		padding: theme.spacing(1, 3),
		justifyContent: 'space-between',
		display: 'flex',
		flex: '1',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	textareaRoot: {
		width: 380,
	},
	addressValue: {
		width: 290,
	},
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalFade: {
		minWidth: 850,
	},
	modalCloseIcon: {
		alignSelf: 'end',
	},
	containerField: {
		alignItems: 'center',
		justifyContent: 'space-around',
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(2),
	},
	outlinedInputRoot: {
		overflow: 'hidden',
		paddingRight: 0,
		marginTop: 4,
	},
	outlinedInputadornedEnd: {
		backgroundColor: theme.palette.secondary,
	},
	autocompleteRoot: {
		width: 250,
	},
	buttonDanger: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	buttonWidth: {
		minWidth: 120,
	},
	containerButton: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	kembali: {
		marginRight: theme.spacing(2),
	},
	btnWhite: {
		backgroundColor: '#fff',
	},
	modalContent: {
		padding: theme.spacing(3),
	},
	formRfqTop: {
		marginTop: theme.spacing(1),
	},
	font14: {
		fontSize: '14px',
	},
	font19: {
		fontSize: '19px',
	},
	buttonAction: {
		padding: theme.spacing(0.4, 1),
		minWidth: 'initial',
	},
	iconAction: {
		fontSize: '22px',
	},
	paginationContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(2),
	},
	typoPage: {
		paddingTop: theme.spacing(0.5),
		marginRight: theme.spacing(2),
	},
	fileName: {
		color: '#007BFF',
		'&:hover': {
			cursor: 'pointer',
		},
		wordWrap: 'break-word',
	},
	statusPadding: {
		paddingTop: '5px',
	},
	fileThumb: {
		marginLeft: '-9px',
		width: '150px',
		height: '150px',
	},
	blankFileThumb: {
		marginLeft: '-18px',
		width: '150px',
		height: '150px',
	},
	textLocation: {
		color: theme.palette.grey[600],
		marginTop: '4px',
	},
}))

export default styles
