export const buttonFilter = [
	{
		status: 'all-step',
		state: '',
		child: false,
	},
	{
		status: 'sale',
		state: 'sale',
		child: true,
	},
	{
		status: 'cancel',
		state: 'cancel',
		child: false,
	},
]

export const buttonStatus = [
	{
		status: 'Semua Status',
		state: '',
	},
	{
		status: 'Belum Ada Status',
		state: 1,
	},
	{
		status: 'Dalam Pengiriman',
		state: 2,
	},
	{
		status: 'Diterima Sebagian',
		state: 4,
	},
	{
		status: 'Diterima Seluruhnya',
		state: 3,
	},
]
