import React from 'react'
import { IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './styles'

const Component = ({ title, variant, children, className, ...props }) => {
	const classes = styles({ variant })
	className =
		className == undefined ? classes.button : className + ' ' + classes.button
	return (
		<IconButton
			classes={classes.root}
			className={className}
			ari-label={title}
			disableRipple
			disableFocusRipple
			{...props}
		>
			{children}
		</IconButton>
	)
}

Component.propTypes = {
	variant: PropTypes.oneOf([
		'primary',
		'secondary',
		'warning',
		'alert',
		'error',
		'success',
		'info',
	]),
	size: PropTypes.oneOf(['small', 'medium']),
	title: PropTypes.string,
	children: PropTypes.element,
	className: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
		PropTypes.string,
	]),
}

Component.defaultProps = {
	variant: 'success',
	size: 'medium',
	title: '',
}

export default Component
