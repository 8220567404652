/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	TextField,
	IconButton,
	Button,
	Container,
	Collapse,
} from '@material-ui/core'
import { SearchRounded, Edit, Delete } from '@material-ui/icons'
import {
	dateFormatFromOdoo,
	newUri,
	checkDepartment,
} from '../../../utils/globalUtils'
import { requestDepartmentList } from 'States/actions/Company/Department/listAction'
import { clearCreateDepartment } from 'States/actions/Company/Department/createAction'
import {
	clearDeleteDepartment,
	deleteDepartment,
} from 'States/actions/Company/Department/deleteAction'
import { clearUpdateDepartment } from 'States/actions/Company/Department/updateAction'
import { navigate } from 'gatsby'
import Breadcrumbs from 'Components/breadcrumbs'
import { DepartmentStyles } from './styles'
import * as qs from 'query-string'
import TableData from 'Components/tableData'
import Status from 'Components/status'
import Modal from 'Components/modal'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import { roleAdmin, checkRole } from '../../../utils/acl'

const Department = (props) => {
	const classes = DepartmentStyles()
	const dispatch = useDispatch()
	const list = useSelector((state) => state.departmentListReducer)
	const create = useSelector((state) => state.departmentCreateReducer)
	const deleted = useSelector((state) => state.departmentDeleteReducer)
	const updated = useSelector((state) => state.departmentUpdateReducer)
	
	const [confirm, setConfirm] = useState(false)
	const [errorConfirm, setErrorConfirm] = useState(false)
	const [item, setItem] = useState(false)
	const [isAddNewData, setIsAddNewData] = useState(false)
	const [isDeleted, setIsDeleted] = useState(false)
	const [isUpdated, setIsUpdated] = useState(false)
	const [selectedDepartment, setSelectedDepartment] = useState('')
	const allowedToDelete = checkRole(`${roleAdmin}`, false)

	const queryString = qs.parse(props.location.search)

	const [name, setName] = useState(queryString.name ? queryString.name : '')
	const [perPage, setLimit] = useState(queryString.limit ? queryString.limit : 10)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [state, setState] = useState(queryString.state ? queryString.state : '')

	queryString.name = name
	queryString.limit = perPage
	queryString.page = page
	queryString.state = state

	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Departemen',
			link: '#',
		},
	])

	const buttonStatus = [
		{
			status: 'all',
			state: '',
		},
		{
			status: 'btn99',
			state: '99',
		},
		{
			status: 'btn98',
			state: '98',
		},
	]

	const columns = [
		{
			name: 'NAMA DEPARTEMEN',
			selector: 'name',
			sortable: true,
			hide: 'md',
			grow: 4,
		},
		{
			name: 'TERAKHIR DIUBAH',
			selector: 'updated_at',
			sortable: true,
			cell: (row) => dateFormatFromOdoo(row.updated_at, 'dd-MMM-yyyy'),
			grow: 3,
		},
		{
			name: 'STATUS',
			selector: 'status',
			sortable: true,
			cell: (row) => <Status status={String(row.status)} />,
			grow: 1,
		},
		{
			name: 'AKSI',
			selector: 'aksi',
			sortable: true,
			hide: 'md',
			grow: 1,
			cell: (row) => buttonGenerator(row),
		},
	]

	const buttonGenerator = (row) => (
		<>
			<Button
				variant='contained'
				size='small'
				className={`${classes.buttonAction} ${classes.btnEdit}`}
				onClick={() => {
					handleClick(row, 'edit')
				}}
			>
				<Edit fontSize='small' />
			</Button>
			{allowedToDelete ? (
				<Button
					variant='contained'
					size='small'
					className={`${classes.buttonAction} ${classes.btnDelete}`}
					onClick={() => {
						handleClick(row, 'delete')
					}}
				>
					<Delete fontSize='small' />
				</Button>
			) : null}
		</>
	)

	const handleClick = (row, action) => {
		if (action == 'edit') {
			navigate(`/dashboard/department/edit/${row.id}`)
		}

		if (action == 'delete') {
			checkDepartment(row.id)
				.then(() => {
					setConfirm(true)
					setSelectedDepartment(row.name)
					setItem(row.id)
				})
				.catch(() => {
					setErrorConfirm(true)
				})
		}
	}

	useEffect(() => {
		return () => {
			dispatch(clearCreateDepartment())
			dispatch(clearUpdateDepartment())
			dispatch(clearDeleteDepartment())
		}
	}, [])

	const clearNotification = () => {
		if (isAddNewData) {
			dispatch(clearCreateDepartment())
		}
		if (isUpdated) {
			dispatch(clearUpdateDepartment())
		}
		if (isDeleted) {
			dispatch(clearDeleteDepartment())
		}
	}

	const alertText = () => {
		if (isAddNewData) {
			return 'Departemen baru berhasil ditambahkan.'
		}
		if (isDeleted) {
			return 'Departemen berhasil dihapus.'
		}
		if (isUpdated) {
			return 'Departemen berhasil diubah.'
		}
	}

	useEffect(() => {
		submitSearch()
		setIsAddNewData(create.alert)
		setIsDeleted(deleted.alert)
		setIsUpdated(updated.alert)
	}, [])

	useEffect(() => {
		submitSearch()
		setIsDeleted(deleted.alert)
		setIsAddNewData(create.alert)
		setIsUpdated(updated.alert)
	}, [create.alert, deleted.alert, updated.alert])

	const limitValue = [10, 25, 50, 100]

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setLimit(event.target.value)
		queryString.limit = event.target.value
		let maxPage = Math.ceil(
			list.data.pagination.total_records / queryString.limit
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		submitSearch()
	}

	const handleChangeSearch = (e) => {
		let value = e.target.value.trim()
		setName(value)
		setPage(1)
		queryString.name = value
		queryString.page = 1
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => submitSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [name])

	function submitSearch() {
		let query = newUri(queryString)
		let sendQuery = {
			query: queryString.name ? JSON.stringify({ name: queryString.name }) : '',
			filter: queryString.state ? JSON.stringify({ status: queryString.state }) : '',
			limit: queryString.limit,
			page: queryString.page,
		}
		dispatch(requestDepartmentList({ queryString: sendQuery }))
		propsNavigate(query)
	}

	const handleNameSearch = () => {
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleButtonState = (event, btnState) => {
		setState(btnState)
		setPage(1)
		queryString.state = btnState
		queryString.page = 1
		submitSearch()
	}

	const confirmClose = () => {
		setConfirm(false)
	}

	const deleteItem = () => {
		dispatch(deleteDepartment(item))
		setConfirm(false)
	}

	return (
		<DashboardLayout>
			<Container>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>
				<Box my={2}>
					<Grid container justify='space-between'>
						<Grid container item lg={4} justify='flex-start'>
							<Typography variant='h6'>Departemen</Typography>
						</Grid>
						<Grid container item xs={3} justify='flex-end'>
							<Button
								variant='contained'
								color='secondary'
								size='small'
								onClick={(event) => {
									event.preventDefault()
									navigate('/dashboard/department/new', {
										replace: true,
									})
								}}
							>
								Tambah Departemen
							</Button>
						</Grid>
					</Grid>
				</Box>
				<Box mt={1}>
					<Grid container justify='space-between'>
						<Grid container item xs={4} justify='flex-start'>
							<Grid item xs={12}>
								<TextField
									placeholder='Cari Departemen'
									type='search'
									className={classes.searchField}
									InputProps={{
										disableUnderline: false,
									}}
									onChange={handleChangeSearch}
									defaultValue={queryString.name}
								/>
								<IconButton
									variant='contained'
									color='primary'
									className={classes.buttonSearch}
									onClick={handleNameSearch}
								>
									<SearchRounded fontSize='small' />
								</IconButton>
							</Grid>
						</Grid>
						<Grid container item xs={4} justify='flex-end'>
							{/* <Button
								variant='outlined'
								className={classes.buttonExport}
								size='small'
								style={{ padding: '3px 18.1px' }}
							>
								Expor Ke Excel <InsertDriveFileOutlined fontSize='small' />
							</Button> */}
						</Grid>
					</Grid>
				</Box>

				<Collapse
					in={isAddNewData || isUpdated || isDeleted}
					style={{ paddingTop: '16px' }}
				>
					<Alert
						severity='info'
						action={
							<IconButton
								aria-label='close'
								color='inherit'
								size='small'
								onClick={() => {
									clearNotification()
								}}
							>
								<CloseIcon fontSize='inherit' />
							</IconButton>
						}
					>
						{alertText()}
					</Alert>
				</Collapse>

				<Box mt={2}>
					<TableData
						buttonState={buttonStatus}
						buttonStateClick={handleButtonState}
						limitValue={limitValue}
						perPage={perPage}
						limitChange={handlePerRowsChange}
						columns={columns}
						isLoading={list.isLoading}
						pagination={'pagination' in list.data ? list.data.pagination : false}
						data={'items' in list.data ? list.data.items : false}
						name={'Departement'}
						paginationPageChange={handlePageChange}
						pageNow={parseInt(queryString.page)}
						queryState={queryString.state}
						labelState='Status'
					/>
				</Box>
			</Container>

			{/* MODAL */}

			<Modal open={errorConfirm} icon='error'>
				<Box mb={2}>
					<Typography variant='h6'>Departemen Tidak dapat Dihapus</Typography>
				</Box>
				<Typography variant='body1'>
					Terdapat Akun berstatus Aktif sebagai Pemohon atau Penyetuju pada Manajemen
					Persetujuan yang menggunakan departemen ini.
				</Typography>
				<Box mt={2} className={classes.paddingTop}>
					<Button
						onClick={() => setErrorConfirm(false)}
						color='secondary'
						variant='outlined'
						size='small'
					>
						Kembali
					</Button>
				</Box>
			</Modal>

			<Modal open={confirm}>
				<Typography>
					Apakah Anda yakin ingin menghapus departemen{' '}
					<strong>{selectedDepartment}</strong> ?
				</Typography>
				<Box mt={2} className={classes.paddingTop}>
					<Button
						onClick={() => confirmClose()}
						color='secondary'
						variant='outlined'
						size='small'
					>
						Batal
					</Button>
					&nbsp;&nbsp;&nbsp;
					{allowedToDelete ? (
						<Button
							onClick={() => {
								deleteItem()
							}}
							className={classes.buttonRed}
							variant='contained'
							size='small'
						>
							Ya, Hapus
						</Button>
					) : null}
				</Box>
			</Modal>
			{/* END OF MODAL */}
		</DashboardLayout>
	)
}
Department.propTypes = {
	navigate: PropTypes.func,
	location: PropTypes.object,
}

export default Department
