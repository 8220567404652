/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import { Companyprofile, EditCompanyProfile } from '../routes'
import { rolesText } from '../constants/text'

export const CompanyProfileNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard/company-profile'
				roles={rolesText('all')}
				Component={Companyprofile}
			/>
			<PrivateRoute
				path='/dashboard/company-profile/edit'
				roles={rolesText('profile')}
				Component={EditCompanyProfile}
			/>
		</>
	)
}
