export const columnBank = [
	{
		label: 'Nama Bank',
		value: 'bank_name',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Nomor Akun Bank',
		value: 'bank_account_no',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Nama Pemegang Akun',
		value: 'bank_account_name',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'Nama Cabang',
		value: 'bank_branch_name',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'Tanggal Didaftarkan',
		value: 'create_date',
		format: '',
		depth: false,
		index: 5,
	},
]

export const columnAlamat = [
	{
		label: 'Label Alamat',
		value: 'address_name',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Alamat Lengkap',
		value: 'address',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Lokasi',
		value: 'location',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'PIC',
		value: 'pic',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'No. Ponsel',
		value: 'mobile',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'Tipe Alamat',
		value: 'type',
		format: '',
		depth: false,
		index: 6,
	},
]

export const columnPengguna = [
	{
		label: 'Nama Lengkap',
		value: 'name',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Username',
		value: 'username',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Email',
		value: 'email',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'No. Ponsel',
		value: 'mobile',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'Jabatan',
		value: 'job_title',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'Roles',
		value: 'role',
		format: '',
		depth: false,
		index: 6,
	},
	{
		label: 'Departemen',
		value: 'department',
		format: '',
		depth: false,
		index: 7,
	},
	{
		label: 'Tanggal Didaftarkan',
		value: 'create_date',
		format: '',
		depth: false,
		index: 8,
	},
	{
		label: 'Status',
		value: 'status',
		format: '',
		depth: false,
		index: 9,
	},
]

export const columnDepartemen = [
	{
		label: 'Nama Departemen',
		value: 'name',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Dibuat Oleh',
		value: 'created_by',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Tanggal Didaftarkan',
		value: 'create_date',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'Status',
		value: 'status',
		format: '',
		depth: false,
		index: 4,
	},
]

export const columnPenyetujuKategori = [
	{
		label: 'Nama Lengkap',
		value: 'name',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Username',
		value: 'username',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Email',
		value: 'email',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'Kategori',
		value: 'category_name',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'Tanggal Didaftarkan',
		value: 'created_at',
		format: '',
		depth: false,
		index: 5,
	},
]

export const columnPenyetujuDepartemen = [
	{
		label: 'Nama Lengkap',
		value: 'name',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Username',
		value: 'username',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Email',
		value: 'email',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'Departemen',
		value: 'department_name',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'Level',
		value: 'level',
		format: '',
		depth: false,
		index: 5,
	},
	{
		label: 'Batas Transaksi Dari',
		value: 'min_transaction',
		format: '',
		depth: false,
		index: 6,
	},
	{
		label: 'Batas Transaksi Hingga',
		value: 'max_transaction',
		format: '',
		depth: false,
		index: 7,
	},
	{
		label: 'Tanggal Didaftarkan',
		value: 'created_at',
		format: '',
		depth: false,
		index: 8,
	},
]

export const columnEProc = [
	{
		label: 'Nama Lengkap',
		value: 'name',
		format: '',
		depth: false,
		index: 1,
	},
	{
		label: 'Username',
		value: 'username',
		format: '',
		depth: false,
		index: 2,
	},
	{
		label: 'Email',
		value: 'email',
		format: '',
		depth: false,
		index: 3,
	},
	{
		label: 'Level',
		value: 'level',
		format: '',
		depth: false,
		index: 4,
	},
	{
		label: 'Tanggal Didaftarkan',
		value: 'created_at',
		format: '',
		depth: false,
		index: 5,
	},
]
