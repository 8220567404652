import { SEARCH_INVOICE } from '../../../types'
import { failure, loading } from '../../../commonActions'
import { sumBy, map } from 'lodash'
import API from '../../../../utils/api'

const success = (type, data) => ({
	type: `${type}_FULFILLED`,
	payload: {
		data: data,
	},
})
export const searchInvoice = () => {
	return async (dispatch) => {
		dispatch(loading(SEARCH_INVOICE))
		try {
			let newRes = []
			const response = await API({
				method: 'GET',
				url: '/invoices/list?invoice_payment_state=not_paid',
			})
			if (response.data.data.result) {
				let isNotProforma = response.data.data.result.filter(
					(item) => item.state === 'posted'
				)
				isNotProforma.map((item) => {
					newRes.push({
						label: item.name,
						value: item.name,
						amount_residual_signed: item.amount_residual_signed,
						amount_total_signed: item.amount_total_signed,
						invoice_date: item.invoice_date,
						invoice_date_due: item.invoice_date_due,
						id: item.id,
					})
				})
			}
			dispatch(success(SEARCH_INVOICE, newRes))
		} catch (err) {
			dispatch(failure(SEARCH_INVOICE, err))
		}
	}
}

export const calculateTotal = (item) => {
	return (dispatch) =>
		dispatch({
			type: 'CALCULATE_TOTAL',
			payload: {
				data: sumBy(item, (total) => total.amount_residual_signed),
			},
		})
}

export const selectInvoice = (item) => {
	let data = map(item, 'label')
	return (dispatch) =>
		dispatch({
			type: 'SELECT_INVOICE',
			payload: {
				data: data,
			},
		})
}
