import { makeStyles } from '@material-ui/core/styles'

export const useStyle = makeStyles({
	container: {
		backgroundColor: '#000000bf',
		position: 'absolute',
		width: '100%',
		left: 0,
		height: '100%',
		top: 0,
		zIndex: 5,
		display: ({ visible }) => (visible ? 'flex' : 'none'),
		justifyContent: 'center',
		alignItems: 'center',
	},
	loadingBox: {
		width: 300,
		padding: 16,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
	},
	button: {
		minWidth: 90,
	},
})
