import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	button: ({ variant }) => ({
		color: theme.palette[variant].main,
		fontSize: '0.7rem',
		padding: 0,
		'&:hover': {
			color: theme.palette[variant].light,
		},
	}),
}))

export default styles
