import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	Paper,
	Button,
	Divider,
	CircularProgress,
	Container,
} from '@material-ui/core'
import { People, Warning } from '@material-ui/icons'
import { navigate } from 'gatsby'
import Breadcrumbs from 'Components/breadcrumbs'
import { ApprovalConfigStyles } from './styles'
import { requestACActivate } from 'States/actions/Approval/Configure/ACActivate'
import { requestACInactivate } from 'States/actions/Approval/Configure/ACInactivate'
import { requestDetailCompany } from 'States/actions/CompanyProfile/profileAction'
import { requestAEList } from 'States/actions/Approval/Eproc/AEListAction'
import { requestPRList } from 'States/actions/Transactions/PurchaseRequest/listAction'
import CustomModal from 'Components/modal'
import Alert from 'Components/alert'
import Skeleton from '@material-ui/lab/Skeleton'

const ApprovalConfig = () => {
	const classes = ApprovalConfigStyles()

	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Dashboard',
			link: '/dashboard/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Manajemen Persetujuan',
			link: '#',
		},
		{
			segment: 'Pengaturan',
			link: '#',
		},
	])

	const dispatch = useDispatch()
	// const dataLogin = useSelector(state => state.auth)
	const responseDataActivate = useSelector((state) => state.ACActivateReducer)
	const responseDataInactivate = useSelector(
		(state) => state.ACInactivateReducer
	)
	const companyDetailReducer = useSelector((state) => state.companyProfile)
	const listEprocReducer = useSelector((state) => state.AEListReducer)
	const listPRReducer = useSelector((state) => state.PRList)

	const [confirmModalSubmit, setConfirmModalSubmit] = useState(false)
	const [modalNotif, setModalNotif] = useState(false)
	const [notifError, setNotifError] = useState(false)
	const [notifErrorMessage, setNotifErrorMessage] = useState('')
	const [notifSuccess, setNotifSuccess] = useState(false)
	const [statusApproval, setStatusApproval] = useState(0)
	const [beforeStatusApproval, setBeforeStatusApproval] = useState(0)
	const [listEproc, setListEproc] = useState([])
	const [listPR, setListPR] = useState([])
	const [errorBackend, setErrorBackend] = useState({})

	var queryString = { state: '99' }

	useEffect(() => {
		let queryPR = {
			limit: 1,
			query: '(status=0 OR status=1)',
		}
		dispatch(requestPRList({ sendQuery: queryPR }))
		dispatch(requestDetailCompany())
		dispatch(requestAEList({ queryString }))
	}, [])

	useEffect(() => {
		if (companyDetailReducer.company && companyDetailReducer.company.data) {
			setStatusApproval(companyDetailReducer.company.data.is_pr_active)
		}
	}, [companyDetailReducer])

	useEffect(() => {
		if (responseDataActivate.data || responseDataInactivate.data) {
			setNotifSuccess(true)
			setModalNotif(true)
			setErrorBackend({})
			dispatch(requestDetailCompany())
		}

		if (responseDataActivate.error || responseDataInactivate.error) {
			var responseDataError
			if (responseDataActivate.error) {
				responseDataError = responseDataActivate.error
			} else {
				responseDataError = responseDataInactivate.error
			}
			var tmpErrorBackend
			if (responseDataError) {
				if (responseDataError.http_body) {
					let http_body = responseDataError.http_body
					if (http_body) {
						let body_obj = JSON.parse(http_body)
						tmpErrorBackend = body_obj
						setErrorBackend(body_obj)

						if (tmpErrorBackend.error && tmpErrorBackend.error != '') {
							setModalNotif(true)
							setNotifSuccess(false)
						}
					}
				}
			}
		}
	}, [responseDataActivate, responseDataInactivate])

	// hooks for set default state
	useEffect(() => {
		// success
		if (listEprocReducer.data && listEprocReducer.data.items) {
			let result = listEprocReducer.data.items
			setListEproc(result)
		} else {
			setListEproc([])
		}

		// success
		if (listPRReducer.data && listPRReducer.data.items) {
			let result = listPRReducer.data.items
			setListPR(result)
		} else {
			setListPR([])
		}
	}, [listEprocReducer, listPRReducer])

	const submitForm = () => {
		// reset all
		dispatch(requestACActivate({}, 'RESET'))
		dispatch(requestACInactivate({}, 'RESET'))
		setConfirmModalSubmit(false)

		// validation submit
		if (listEproc.length == 0) {
			setNotifError(true)
			setModalNotif(true)
			setNotifErrorMessage(
				'Dibutuhkan minimal satu E-Procurement aktif untuk mengaktifkan manajemen persetujuan'
			)
			return false
		} else if (listPR.length > 0) {
			let isPrActive = listPR.filter(
				(item) => item.status == 0 || item.status == 1
			)

			if (Boolean(isPrActive.length)) {
				setNotifError(true)
				setModalNotif(true)
				setNotifErrorMessage(
					'Gagal menonaktifkan manajemen persetujuan. Terdapat dokumen Purchase Request yang masih dalam proses persetujuan. Harap selesaikan seluruh proses persetujuan untuk menonaktifkan manajemen persetujuan.'
				)
				return false
			}
		}

		// submit setting approval
		if (statusApproval != 1) {
			setBeforeStatusApproval(statusApproval)
			dispatch(requestACActivate({}))
		} else {
			setBeforeStatusApproval(statusApproval)
			dispatch(requestACInactivate({}))
		}
	}

	const handleModalOK = () => {
		setModalNotif(false)

		dispatch(requestDetailCompany())
		dispatch(requestAEList({ queryString }))
	}

	return (
		<>
			<DashboardLayout>
				<Container>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrums} />
					</Box>
					<Box my={2}>
						<Typography variant='h6'>Manajemen Persetujuan - Pengaturan</Typography>
					</Box>
					<Box mt={1}>
						<Paper variant='outlined'>
							<Box p={1}>
								<Grid container alignItems='center'>
									<Grid item lg={1}>
										<Grid container alignItems='center'>
											<People style={{ fontSize: 70 }} />
										</Grid>
									</Grid>
									<Grid item lg={6}>
										<Typography variant='body1'>
											{companyDetailReducer.company.isLoading ? (
												<Skeleton variant='text' />
											) : (
												<>
													Manajement persetujuan Anda berstatus{' '}
													<span style={{ color: statusApproval == 1 ? 'green' : 'red' }}>
														{statusApproval == 1 ? 'Aktif' : 'Tidak Aktif'}
													</span>
												</>
											)}
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Box>
					{notifError && (
						<Box mt={3}>
							<Alert severity='error' variant='alert'>
								<Box display='flex' alignItems='center'>
									<Warning style={{ color: 'red', marginRight: '10px' }} />
									<Box>
										<Typography variant='body1'>{notifErrorMessage}</Typography>
									</Box>
								</Box>
							</Alert>
						</Box>
					)}
					<Box mt={1} style={{ overflowY: 'scroll', minHeight: 500 }}>
						<Box mb={1}>
							<Paper>
								<Box p={1} display='flex' alignItems='center'>
									<ul style={{ margin: 0 }}>
										<li>
											Dengan Mengaktifkan Manajemen Persetujuan, setelah Quotation yang
											diterima dari Penjual akan melalui tahap persetujuan agar dapat
											diproses menjadi Purchase Order.
										</li>
										<li>
											Untuk Mengaktifkan Manajemen Persetujuan, pastikan role yang
											dibutuhkan sudah terpenuhi.
										</li>
										<li>
											Manajemen Persetujuan dapat diaktifkan per departemen dengan membuat
											skema persetujuan terlebih dahulu.
										</li>
										<li>
											Jika Manajemen Persetujuan dinonaktifkan makan proses persetujuan
											tidak dijalankan dan Quotation yang diterima dapat langsung diproses
											menjadi Purchase Order.
										</li>
									</ul>
								</Box>
							</Paper>
						</Box>
						<Box mb={1}>
							<Paper>
								<Box p={1}>
									<Typography variant='h6' gutterBottom>
										Keterangan Role pada Manajemen Persetujuan
									</Typography>
									<Divider />
									<Box mt={2}>
										<Typography variant='subtitle1'>
											Manajemen Persetujuan - Pemohon
										</Typography>
										<Typography variant='body1'>Role: Staff Pemohon</Typography>
										<ul>
											<li>
												<strong>
													Untuk dapat mengaktifkan proses persetujuan, Perusahaan harus
													memiliki minimal satu Pemohon
												</strong>
											</li>
											<li>Pemohon dapat memasukan pesanan kedalam keranjang belanja</li>
											<li>
												Pemohon dapat melakukan pembuatan dokumen transaksi RFQ, Quotation
												dan Purchase Request(PR)
											</li>
											<li>
												Pemohon dapat melakukan aksi pada transaksi, seperti: pembatalan
												RFQ, penolakan Quotation, dan klaim penerimaan pesanan
											</li>
										</ul>
									</Box>
									<Box mt={2}>
										<Typography variant='subtitle1'>
											Manajemen Persetujuan - Penyetuju Kategori
										</Typography>
										<Typography variant='body1'>Role: Penyetuju</Typography>
										<ul>
											<li>
												Penyetuju Kategori dapat menyetujui/menolak transaksi yang dilakukan
												oleh Pemohon sesuai dengan kategori dari produk yang dibeli oleh
												Pemohon
											</li>
											<li>
												Kosongkan list Penyetuju Kategori jika tidak memerlukan persetujuan
												untuk pembelian produk per kategori
											</li>
										</ul>
									</Box>
									<Box mt={2}>
										<Typography variant='subtitle1'>
											Manajemen Persetujuan - Penyetuju Departemen
										</Typography>
										<Typography variant='body1'>Role: Penyetuju</Typography>
										<ul>
											<li>
												Penyetuju Departemen dapat menyetujui/menolak transaksi yang
												dilakukan oleh Pemohon sesuai dengan departemen dari Pemohon
											</li>
											<li>
												Kosongkan list Penyetuju Departemen jika tidak memerlukan
												persetujuan untuk pembelian produk per departemen
											</li>
										</ul>
									</Box>
									<Box mt={2}>
										<Typography variant='subtitle1'>
											Manajemen Persetujuan - E-Procurement
										</Typography>
										<Typography variant='body1'>Role: Penyetuju</Typography>
										<ul>
											<li>
												<strong>
													Untuk dapat mengaktifkan proses persetujuan, Perusahaan harus
													memiliki minimal satu E-procurement
												</strong>
											</li>
											<li>
												E-Procurement sebagai penyetuju akhir dari proses persetujuan
												Purchase Request (PR) yang akan membuat dokumen Purchase Order (PO)
											</li>
											<li>
												Jika list Penyetuju Kategori dan Penyetuju Departemen kosong, maka
												proses persetujuan Purchase Request (PR) Akan langsung masuk ke
												persetujuan E-Procurement
											</li>
										</ul>
									</Box>
								</Box>
							</Paper>
						</Box>
					</Box>
					<Box mt={2}>
						<Grid container justify='flex-end'>
							<Grid item>
								<Box>
									<Button
										size='medium'
										variant='outlined'
										color='primary'
										onClick={(event) => {
											event.preventDefault()
											navigate('/dashboard/approval/category', {
												replace: true,
											})
										}}
										style={{ marginRight: '10px' }}
									>
										Kembali
									</Button>
									<Button
										size='medium'
										variant='contained'
										color='secondary'
										// className={classes.buttonAction}
										className={
											statusApproval == 1 ? classes.btnDelete : classes.buttonWidth
										}
										onClick={() => setConfirmModalSubmit(true)}
										disabled={
											responseDataActivate.isLoading ||
											responseDataInactivate.isLoading ||
											companyDetailReducer.company.isLoading
										}
									>
										{statusApproval == 1
											? 'Nonakfifkan Manajemen Persetujuan'
											: 'Aktifkan Manajemen Persetujuan '}

										{(responseDataActivate.isLoading ||
											responseDataInactivate.isLoading ||
											companyDetailReducer.company.isLoading) && (
											<CircularProgress size={14} style={{ marginLeft: '5px' }} />
										)}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</DashboardLayout>

			<CustomModal
				icon='question'
				open={confirmModalSubmit}
				onClose={() => setConfirmModalSubmit(false)}
			>
				<Typography variant='h6'>
					<strong>
						{statusApproval == 1 ? 'Nonaktifkan' : 'Aktifkan'} Manajemen Persetujuan?
					</strong>
				</Typography>

				<Typography>
					Dengan {statusApproval == 1 ? 'menonaktifkan' : 'mengaktifkan'} Manajemen
					Persetujuan, Quotation yang diterima harus melalui proses persetujuan untuk
					dijadikan Purchase Order.
				</Typography>
				<Box display='flex' flexDirection='row' mt={3} justifyContent='center'>
					<Button
						className={classes.btnDelete}
						size='small'
						style={{ marginRight: '20px' }}
						variant='contained'
						onClick={() => setConfirmModalSubmit(false)}
					>
						Batal
					</Button>
					<Button
						className={classes.buttonWidth}
						size='small'
						color='secondary'
						variant='contained'
						type='submit'
						onClick={() => submitForm()}
					>
						Ya, {statusApproval == 1 ? 'nonaktifkan' : 'aktifkan'}
					</Button>
				</Box>
			</CustomModal>

			<CustomModal
				icon={notifSuccess ? 'success' : 'error'}
				open={modalNotif}
				onClose={() => setModalNotif(false)}
			>
				<>
					<Typography variant='h6'>
						{errorBackend.error && (
							<strong style={{ fontSize: '16px' }}>{errorBackend.error}</strong>
						)}
						{!errorBackend.error && notifSuccess && (
							<strong>
								Manajemen Persetujuan {notifSuccess ? 'berhasil' : 'gagal'}{' '}
								{beforeStatusApproval == 1 ? 'nonaktifkan' : 'diaktifkan'}
							</strong>
						)}
					</Typography>
					{notifError && (
						<Box mt={3}>
							<Box display='flex' alignItems='center'>
								<Box>
									<Typography variant='body1'>{notifErrorMessage}</Typography>
								</Box>
							</Box>
						</Box>
					)}
					<Box display='flex' flexDirection='row' mt={3} justifyContent='center'>
						<Button
							className={classes.buttonWidth}
							size='small'
							classes={{
								contained: classes.buttonDanger,
							}}
							variant='contained'
							onClick={() => handleModalOK()}
						>
							{' '}
							OK{' '}
						</Button>
					</Box>
				</>
			</CustomModal>
		</>
	)
}

export default ApprovalConfig
