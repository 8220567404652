import { makeStyles } from '@material-ui/core/styles'

export const aboutStyle = makeStyles((theme) => ({
	iframe: { width: '100%', height: 350 },
	boxDecorationContainer: { background: theme.palette.primary.main },
	imageDecoration1: { transform: 'translateY(-45px)', padding: '10px 30px' },
	imageDecoration2: { transform: 'translateY(45px)', padding: '10px 30px' },
	cardContainer: {
		gridTemplateColumns: 'repeat(2, minmax(295px, 440px))',
		gridGap: '50px 50px',
		justifyContent: 'center',
	},
}))
