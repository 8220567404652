/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
	Box,
	Container,
	Typography,
	Button,
	Grid,
	TextField,
	IconButton,
	Link,
} from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'
import useStyles from './styles'
import { navigate } from '@reach/router'
import { requestQuotationList } from 'States/actions/Transactions/Quotation/quotationListAction'
import * as qs from 'query-string'
import {
	newUri,
	dateFormat,
	dateFormatFromOdoo,
	isValidDate,
} from '../../../../utils/globalUtils'
import { numeralFormat } from '../../../../utils/formatMoney'
import Breadcrumbs from 'Components/breadcrumbs'
import { format } from 'date-fns'
import Status from 'Components/status'
import DashboardLayout from 'Components/dashboardLayout'
import ListIcon from '@material-ui/icons/List'
import TableData from 'Components/tableData'
import DatePickerRange from 'Components/DateRange'
import { buttonStatus, buttonFilter } from './helper'

const breadcrumbs = [
	{
		segment: 'Home',
		link: '/dashboard/',
	},
	{
		segment: 'Transaksi',
		link: '#',
	},
	{
		segment: 'Quotation',
		link: '#',
	},
]

const TransactionsQuotationList = (props) => {
	const classes = useStyles()

	const dispatch = useDispatch()
	const list = useSelector((state) => state.quotationListReducer)
	const queryString = qs.parse(props.location.search)

	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [perPage, setPerPage] = useState(
		queryString.page_size ? queryString.page_size : 10
	)
	const [transactionNo, setTransactionNo] = useState(
		queryString.transaction_no ? queryString.transaction_no : ''
	)
	const [startDate, setStartDate] = useState(
		queryString.start_date ? queryString.start_date : ''
	)
	const [endDate, setEndDate] = useState(
		queryString.end_date ? queryString.end_date : ''
	)
	const [state, setState] = useState(queryString.state ? queryString.state : '')
	const [status, setStatus] = useState(
		queryString.status ? queryString.status : ''
	)

	// THIS FOR DATATABLE
	const [dataList, setDataList] = useState([])
	const [range, setRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	])
	const joinDate = (startDate, endDate) => `${startDate} ~ ${endDate}`

	const [placeHolder, setPlaceHolder] = useState(
		startDate && endDate ? joinDate(startDate, endDate) : 'Rentang Tanggal'
	)
	const [color, setColor] = useState(
		isValidDate(startDate) && isValidDate(startDate) ? '#007BFF' : '#a9acaf'
	)

	queryString.page_size = perPage
	queryString.page = page
	queryString.transaction_no = transactionNo
	queryString.start_date = startDate
	queryString.end_date = endDate
	queryString.state = state
	queryString.status = status

	const handleOnChange = (item) => {
		const str = `${format(item.selection.startDate, 'yyyy-MM-dd')} ~ ${format(
			item.selection.endDate,
			'yyyy-MM-dd'
		)}`
		setPlaceHolder(str)
		setRange([item.selection])
		setColor('#007BFF')
		setStartDate(dateFormat(item.selection.startDate, 'yyyy-MM-dd'))
		setEndDate(dateFormat(item.selection.endDate, 'yyyy-MM-dd'))
		queryString.start_date = dateFormat(item.selection.startDate, 'yyyy-MM-dd')
		queryString.end_date = dateFormat(item.selection.endDate, 'yyyy-MM-dd')
		queryString.page = 1
		setPage(1)
		submitSearch()
	}

	const handleOnClear = () => {
		setPlaceHolder('Rentang Tanggal')
		setColor('#a9acaf')
		setRange([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		])
		setStartDate('')
		setEndDate('')
		queryString.start_date = ''
		queryString.end_date = ''
		queryString.page = 1
		setPage(1)
		submitSearch()
	}

	// hooks for set default state
	useEffect(() => {
		// success
		if (list.data && list.data.result) {
			let result = list.data.result
			if (result) {
				setDataList(result)
			}
		} else {
			setDataList([])
		}
	}, [list])

	const renderStatus = (status) => {
		if (status == 'done') {
			return 'sale_done'
		}

		return status
	}

	const handleClickDetail = (id) => {
		navigate(`/dashboard/quotation/${id}`)
	}

	const columns = [
		{
			name: 'NO. Quotation',
			selector: 'quotation_no',
			sortable: true,
			cell: (row) => (
				<Link
					href={`/dashboard/quotation/${row.quotation_no}`}
					color='secondary'
					target='_blank'
				>
					{row.quotation_no}
				</Link>
			),
			width: '150px',
		},
		{
			name: 'NO. RFQ',
			selector: 'rfq_no',
			sortable: true,
			cell: (row) => (
				<Link
					href={`/dashboard/rfq/${row.rfq_no}`}
					color='secondary'
					target='_blank'
				>
					{row.rfq_no}
				</Link>
			),
			width: '150px',
		},
		{
			name: 'TGL Quotation DIBUAT',
			selector: 'create_date',
			sortable: true,
			cell: (row) => dateFormatFromOdoo(row.date_order, 'dd-MMM-yyyy'),
			width: '200px',
		},
		{
			name: 'BATAS MEMBUAT PO',
			selector: 'validity_date',
			expected_date: 'dateQuotation',
			sortable: true,
			cell: (row) => dateFormatFromOdoo(row.validity_date, 'dd-MMM-yyyy'),
			width: '170px',
		},
		{
			name: 'PEMOHON',
			selector: 'requestor_name',
			sortable: true,
			width: '150px',
		},
		{
			name: 'TOTAL TRANSAKSI',
			selector: 'amount_total',
			sortable: true,
			cell: (row) => numeralFormat(row.amount_total),
			width: '180px',
		},
		{
			name: 'TAHAP',
			selector: 'state',
			sortable: true,
			cell: (row) => <Status status={renderStatus(row.state)} />,
			width: '150px',
		},
		{
			name: 'STATUS',
			selector: 'quotation_status',
			sortable: true,
			width: '150px',
		},
		{
			name: 'AKSI',
			selector: 'aksi',
			sortable: true,
			center: true,
			width: '90px',
			cell: (row) => (
				<Button
					id='btn-detail-qn'
					className={classes.buttonAction}
					variant='contained'
					color='secondary'
					size='small'
					onClick={() => handleClickDetail(row.quotation_no)}
				>
					<ListIcon className={classes.iconAction} />
				</Button>
			),
		},
	]

	const limitValue = [10, 25, 50, 100]

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setPerPage(event.target.value)
		queryString.page_size = event.target.value
		let maxPage = Math.ceil(
			list.data.pagination.total_records / queryString.page_size
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		submitSearch()
	}

	const handleChangeSearchQuotation = (e) => {
		let value = e.target.value.trim()
		queryString.transaction_no = value
		queryString.page = 1
		setPage(1)
		setTransactionNo(value)
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => submitSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [transactionNo])

	useEffect(() => {
		dispatch(requestQuotationList({ queryString }))
	}, [])

	const submitSearch = () => {
		let query = newUri(queryString)
		dispatch(requestQuotationList({ queryString }))
		propsNavigate(query)
	}

	const handleButtonState = (e, btnState) => {
		setState(btnState)
		setStatus('')
		setPage(1)
		queryString.state = btnState
		queryString.page = 1
		queryString.status = ''
		submitSearch()
	}

	const handleButtonStatus = (e, btnStatus) => {
		setStatus(btnStatus)
		setPage(1)
		queryString.status = btnStatus
		queryString.page = 1
		submitSearch()
	}

	const handleSearchQuotation = () => {
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleEnterSearchQuotation = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>
				<Box className={'searchContainer'}>
					<Typography variant='h6'>Daftar Penawaran (Quotation)</Typography>
					<br />
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<DatePickerRange
								ranges={range}
								color={color}
								onSelectDate={(item) => handleOnChange(item)}
								placeholder={placeHolder}
								onClear={handleOnClear}
							/>
						</Grid>
						<Grid item xs={3}>
							<Grid item className={classes.gridSearch}>
								<TextField
									id='field-search-qn'
									placeholder='Cari No. Quotation / No. RFQ'
									type='search'
									className={'nameSearch'}
									InputProps={{
										disableUnderline: false,
									}}
									onChange={(e) => handleChangeSearchQuotation(e)}
									onKeyDown={(e) => handleEnterSearchQuotation(e)}
									// onBlur={e => handleChangeSearchQuotation(e)}
									defaultValue={transactionNo}
								/>
								<IconButton
									className={classes.buttonSearch}
									variant='contained'
									color='primary'
									onClick={handleSearchQuotation}
								>
									<SearchRounded fontSize='small' />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<Box mt={2}>
					<TableData
						buttonStatus={buttonStatus}
						buttonState={buttonFilter}
						buttonStatusClick={handleButtonStatus}
						buttonStateClick={handleButtonState}
						limitValue={limitValue}
						perPage={perPage}
						limitChange={handlePerRowsChange}
						columns={columns}
						isLoading={list.isLoading}
						pagination={list.data ? list.data.pagination : false}
						data={dataList}
						name={'Qoutation'}
						paginationPageChange={handlePageChange}
						pageNow={parseInt(queryString.page)}
						queryState={queryString.state}
						queryStatus={queryString.status}
					/>
				</Box>
				<br />
			</Container>
		</DashboardLayout>
	)
}

TransactionsQuotationList.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default TransactionsQuotationList
