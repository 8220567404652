import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	container: {
		width: '100%',
		minHeight: '100%',
		[theme.breakpoints.up('lg')]: {
			padding: theme.spacing(2, 0),
		},
	},
	mediaContainer: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.primary.main,
		marginBottom: 1,
		'& div.slick-track': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		'& .slick-slide': {
			width: '100% !important',
			[theme.breakpoints.down('md')]: {
				display: 'flex',
				justifyContent: 'center',
			},
		},
		'& .slick-prev::before,.slick-next::before': {
			color: '#000',
		},
		'& .slick-arrow': {
			height: '30px!important',
			width: '30px!important',
		},
		'& .slick-arrow::before, .slick-arrow.slick-disabled::before': {
			display: 'block',
			opacity: 0.25,
			width: 18,
			height: 18,
			position: 'absolute',
			top: '50%',
			left: 'calc(50% - 1px)',
			transform: 'translate(-50%, -50%)',
			mozTransform: 'translate(-50%, -52%)',
		},
	},
	sliderItem: {
		display: 'flex !important',
		alignItems: 'center',
		justifyContent: 'center',
	},
	layoutDashboard: {
		backgroundColor: '#e7ebf0',
	},
}))
export default styles
