import { makeStyles } from '@material-ui/core/styles'

export const ComplaintStyle = makeStyles((theme) => ({
	contentList: {
		fontSize: theme.typography.body1.fontSize,
		counterReset: 'item',
		listStyleType: 'decimal',
		display: 'block',

		'&>li': {
			'&::before': {
				content: 'counters(item, ".")',
				counterIncrement: 'item',
				position: 'absolute',
				marginRight: '100%',
				right: '10px',
			},
		},

		'&>li>ol': {
			padding: 0,
			counterReset: 'item',
			listStyleType: 'decimal',
		},

		'&>li>ol>li>ol': {
			paddingLeft: 20,
			counterReset: 'item',
			listStyleType: 'decimal',
		},

		'&>li>ol>li': {
			listStyle: 'decimal outside',
			margin: '10px 0 10px 30px',
			position: 'relative',
			display: 'block',

			'&::before': {
				content: 'counters(item, ".")',
				counterIncrement: 'item',
				position: 'absolute',
				marginRight: '100%',
				right: '10px',
			},
		},

		'&>li>ol>li>ol>li': {
			listStyle: 'decimal outside',
			margin: '10px 0 10px 30px',
			position: 'relative',
			display: 'block',

			'&::before': {
				content: 'counters(item, ".")',
				counterIncrement: 'item',
				position: 'absolute',
				marginRight: '100%',
				right: '10px',
			},
		},
	},

	returnTable: {
		'&>p': {
			textAlign: 'center',
			marginBottom: 10,
		},
		'&>table': {
			border: '1px solid #ddd',
			maxWidth: '100%',
			width: '100%',
			marginBottom: 20,
			borderCollapse: 'collapse',
			borderSpacing: 0,
			'&>thead': {
				backgroundColor: ' #264f6b',
				color: '#ffffff',
				fontSize: '13px',
				fontWeight: 'bold',
				textAlign: 'center',

				'&>tr>th': {
					padding: 8,

					'&:first-child': {
						textAlign: 'left',
					},
				},
			},
			'&>tbody>tr>td': {
				padding: 8,
				verticalAlign: 'middle',
				textAlign: 'center',

				'&:first-child': {
					textAlign: 'left',
				},
			},
		},
	},
	linkColor: {
		color: theme.palette.secondary.main,
	},
}))
