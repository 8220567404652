/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import DashboardLayout from 'Components/dashboardLayout'
import PropTypes from 'prop-types'
import {
	Box,
	Typography,
	Grid,
	TextField,
	IconButton,
	Container,
	Button,
} from '@material-ui/core'
import {
	newUri,
	dateFormat,
	dateFormatFromOdoo,
	isValidDate,
} from '../../../../utils/globalUtils'
import { SearchRounded } from '@material-ui/icons'
import * as qs from 'query-string'
import { navigate } from 'gatsby'
import Breadcrumbs from 'Components/breadcrumbs'
import { PurchaseRequestStyles } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { requestPRList } from 'States/actions/Transactions/PurchaseRequest/listAction'
import TableData from 'Components/tableData'
import { numeralFormat } from '../../../../utils/formatMoney'
import Status from 'Components/status'
import ListIcon from '@material-ui/icons/List'
import { format } from 'date-fns'
import DatePickerRange from 'Components/DateRange'
import { buttonStatus, buttonFilter } from './helper'

const PurchaseRequest = (props) => {
	const classes = PurchaseRequestStyles()

	const dispatch = useDispatch()
	const list = useSelector((state) => state.PRList)
	const user = useSelector((state) => state.auth.data.data)

	const [range, setRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	])
	const [dataPR, setDataPR] = useState([])

	const queryString = qs.parse(props.location.search)

	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [perPage, setPerPage] = useState(
		queryString.per_page ? queryString.per_page : 10
	)
	const [transactionNo, setTransactionNo] = useState(
		queryString.transaction_no ? queryString.transaction_no : ''
	)
	const [startDate, setStartDate] = useState(
		queryString.start_date ? queryString.start_date : ''
	)
	const [endDate, setEndDate] = useState(
		queryString.end_date ? queryString.end_date : ''
	)
	const [state, setState] = useState(queryString.state ? queryString.state : '')
	const [approverStatus, setApproverStatus] = useState(
		queryString.approver_status ? queryString.approver_status : ''
	)

	queryString.page = page
	queryString.per_page = perPage
	queryString.transaction_no = transactionNo
	queryString.start_date = startDate
	queryString.end_date = endDate
	queryString.state = state
	queryString.approver_status = approverStatus

	const joinDate = (startDate, endDate) => `${startDate} ~ ${endDate}`

	const [placeHolder, setPlaceHolder] = useState(
		startDate && endDate ? joinDate(startDate, endDate) : 'Rentang Tanggal'
	)
	const [color, setColor] = useState(
		isValidDate(startDate) && isValidDate(startDate) ? '#007BFF' : '#a9acaf'
	)

	const handleOnChange = (item) => {
		const str = joinDate(
			format(item.selection.startDate, 'yyyy-MM-dd'),
			format(item.selection.endDate, 'yyyy-MM-dd')
		)
		setPlaceHolder(str)
		setRange([item.selection])
		setColor('#007BFF')
		setStartDate(dateFormat(item.selection.startDate, 'yyyy-MM-dd'))
		setEndDate(dateFormat(item.selection.endDate, 'yyyy-MM-dd'))
		queryString.start_date = dateFormat(item.selection.startDate, 'yyyy-MM-dd')
		queryString.end_date = dateFormat(item.selection.endDate, 'yyyy-MM-dd')
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleOnClear = () => {
		setPlaceHolder('Rentang Tanggal')
		setColor('#a9acaf')
		setRange([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		])
		setStartDate('')
		setEndDate('')
		queryString.start_date = ''
		queryString.end_date = ''
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	useEffect(() => {
		submitSearch()
	}, [])

	// hooks for set default state
	useEffect(() => {
		// success
		if (list.data && list.data.items) {
			let result = list.data.items || false
			if (result) {
				setDataPR(result)
			}
		} else {
			setDataPR([])
		}
	}, [list])

	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Transaksi',
			link: '#',
		},
		{
			segment: 'Purchase Request',
			link: '#',
		},
	])

	const generateStatus = (status, tahap) => {
		let status_label = ''
		switch (tahap) {
			case 0:
			case 1:
				if (status && status.categories.length > 0) {
					status_label = 'Butuh Persetujuan Penyetuju Kategori'
				} else if (status && status.department.length > 0) {
					status_label =
						'Butuh Persetujuan Penyetuju Departement ' + status.department[0].text
				} else if (status && status.eproc.length > 0) {
					status_label = 'Butuh Persetujuan ' + status.eproc[0].text
				}
				return status_label
			case 2:
				return 'PO Terbuat'
			default:
				return '-'
		}
	}

	const columns = [
		{
			name: 'NO. PURCHASE REQUEST',
			selector: 'purchase_request_no',
			sortable: true,
			grow: 3,
			cell: (row) => (
				<a
					href={`${location.origin}/dashboard/purchase-request/${row.purchase_request_no}`}
					rel='noopener noreferrer'
					target='_blank'
				>
					{row.purchase_request_no}
				</a>
			),
			width: '200px',
		},
		{
			name: 'NO. QUOTATION',
			selector: 'quotation_no',
			sortable: true,
			grow: 3,
			cell: (row) => (
				<a
					href={`${location.origin}/dashboard/quotation/${row.quotation_no}`}
					rel='noopener noreferrer'
					target='_blank'
				>
					{row.quotation_no}
				</a>
			),
			width: '150px',
		},
		{
			name: 'WAKTU PR DIBUAT',
			selector: 'created_at',
			sortable: true,
			grow: 2,
			cell: (row) => dateFormatFromOdoo(row.created_at, 'dd-MMM-yyyy'),
			width: '160px',
		},
		{
			name: 'BATAS MEMBUAT PO',
			selector: 'expired_at',
			sortable: true,
			cell: (row) => dateFormatFromOdoo(row.expired_at, 'dd-MMM-yyyy'),
			grow: 2,
			width: '180px',
		},
		{
			name: 'PEMOHON',
			selector: 'requestor',
			sortable: true,
			cell: (row) => row.requestor.name,
			grow: 2,
			width: '150px',
		},
		{
			name: 'TOTAL TRANSAKSI',
			selector: 'amount_total',
			sortable: true,
			cell: (row) => numeralFormat(row.amount_total),
			grow: 2,
			width: '180px',
		},
		{
			name: 'TAHAP',
			selector: 'status',
			sortable: true,
			grow: 3,
			cell: (row) => (
				<Status
					status={
						'PR' +
						(row.status == 1
							? row.curent_approval_users.split(',').find((id) => id.includes(user.id))
								? row.status
								: 0
							: row.status)
					}
				/>
			),
			width: '150px',
		},
		{
			name: 'STATUS',
			selector: 'status_label',
			sortable: true,
			grow: 6,
			cell: (row) => generateStatus(row.status_state_label, row.status),
		},
		{
			name: 'AKSI',
			selector: 'purchase_request_no',
			sortable: true,
			grow: 1,
			cell: (row) => (
				<Button
					id='btn-detail-pr'
					onClick={() => handleClickDetail(row.purchase_request_no)}
					className={classes.buttonDetail}
					variant='contained'
					color='secondary'
					size='small'
				>
					<ListIcon className={classes.iconAction} />
				</Button>
			),
		},
	]

	const handleClickDetail = (id) => {
		navigate(`/dashboard/purchase-request/${id}`)
	}

	// const handleClickQN = id => {
	// 	navigate(`/dashboard/quotation/${id}`)
	// }

	const limitValue = [10, 25, 50, 100]

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setPerPage(event.target.value)
		queryString.per_page = event.target.value
		let maxPage = Math.ceil(
			list.data.pagination.total_records / queryString.per_page
		)
		if (maxPage < queryString.page) {
			setPage(maxPage)
			queryString.page = maxPage
		}
		submitSearch()
	}

	const handleChangeSearchPR = (e) => {
		let value = e.target.value.trim()
		setTransactionNo(value)
		setPage(1)
		queryString.transaction_no = value
		queryString.page = 1
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => submitSearch(), 1000)
		return () => clearTimeout(timeOutId)
	}, [transactionNo])

	function submitSearch() {
		let query = newUri(queryString)
		let sendQuery = {
			limit: queryString.per_page,
			start_date: queryString.start_date,
			end_date: queryString.end_date,
			page: queryString.page,
			approver_status: queryString.approver_status,
			transaction_no: queryString.transaction_no,
			status: queryString.state,
		}
		dispatch(requestPRList({ sendQuery }))
		propsNavigate(query)
	}

	const handleButtonState = (e, btnState) => {
		setState(btnState)
		setApproverStatus('')
		setPage(1)
		queryString.state = btnState
		queryString.approver_status = ''
		queryString.page = 1
		submitSearch()
	}

	const handleButtonStatus = (e, btnStatus) => {
		setApproverStatus(btnStatus)
		setPage(1)
		queryString.approver_status = btnStatus
		queryString.page = 1
		submitSearch()
	}

	const handleSearchPR = () => {
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const handleEnterSearchPR = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrums} />
				</Box>
				<Box my={2}>
					<Typography variant='h6'>Daftar Persetujuan Pembelian</Typography>
				</Box>
				<Box mt={1} className={'searchContainer'}>
					<Grid container>
						<Grid item xs={3}>
							<DatePickerRange
								ranges={range}
								color={color}
								onSelectDate={(item) => handleOnChange(item)}
								placeholder={placeHolder}
								onClear={() => handleOnClear()}
							/>
						</Grid>
						<Grid item xs={3}>
							<Grid item className={classes.gridSearch}>
								<TextField
									placeholder='Cari No. PR / No. Quotation'
									type='search'
									className={'nameSearch'}
									InputProps={{
										disableUnderline: false,
									}}
									onChange={(e) => handleChangeSearchPR(e)}
									onKeyDown={(e) => handleEnterSearchPR(e)}
									// onBlur={e => handleChangeSearchPR(e)}
									defaultValue={queryString.transaction_no}
								/>
								<IconButton
									className={classes.buttonSearch}
									variant='contained'
									color='primary'
									onClick={handleSearchPR}
								>
									<SearchRounded fontSize='small' />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<Box mt={2}>
					<TableData
						buttonStatus={buttonStatus}
						buttonState={buttonFilter}
						buttonStatusClick={handleButtonStatus}
						buttonStateClick={handleButtonState}
						limitValue={limitValue}
						perPage={perPage}
						limitChange={handlePerRowsChange}
						columns={columns}
						isLoading={list.isLoading}
						pagination={list.data ? list.data.pagination : false}
						data={dataPR}
						name={'Purchase Request'}
						paginationPageChange={handlePageChange}
						pageNow={parseInt(queryString.page)}
						queryState={queryString.state}
						queryStatus={queryString.approver_status}
					/>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

PurchaseRequest.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

export default PurchaseRequest
