import { BUDGET_HISTORY } from '../../types'
import { success, failure, loading } from '../../commonActions'
import API from '../../../utils/api'
import { navigate } from 'gatsby'

export const resetAction = () => {
	return {
		type: `${BUDGET_HISTORY}_RESET`,
	}
}

export const requestBudgetHistory = (budgetID, page) => {
	return async (dispatch) => {
		dispatch(loading(BUDGET_HISTORY))
		try {
			const response = await API({
				method: 'GET',
				url: `/budget-logs/${budgetID}?page=${page}&limit=5`,
			})
			if (response.data) {
				if ('error_backend' in response.data) {
					navigate(`/${response.data.error_backend.http_status_code}`)
				} else {
					dispatch(success(BUDGET_HISTORY, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(BUDGET_HISTORY, err))
		}
	}
}
