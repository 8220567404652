import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Grid,
	Typography,
	Paper,
	Divider,
	Button,
	TextField,
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormControl,
	Switch,
	Collapse,
	CircularProgress,
	Container,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { adduseraccountStyle } from './styles'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import schema from '../schema'
import { requestUserAccountCreate } from 'States/actions/UsersAccount/createAction'
import { requestDepartmentList } from 'States/actions/Company/Department/listAction'
import Alert from 'Components/alert'
import { HighlightOffOutlined } from '@material-ui/icons'
import { containsString, toRoleId } from '../../../utils/globalUtils'
import { navigate } from 'gatsby'
import CustomModal from 'Components/modal'
import Breadcrumbs from 'Components/breadcrumbs'
import { RolesDialog, DepartmentDialog } from 'Components/dialogs'

// const departments = [{ title: 'Staff' }, { title: 'Boss' }, { title: 'CTO' }]

const adduserAccount = () => {
	// breadcumbs
	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/dashboard',
		},
		{
			segment: 'Perusahaan',
			link: '/dashboard',
		},
		{
			segment: 'Daftar Akun Pengguna',
			link: '/dashboard/user-account',
		},
		{
			segment: 'Tambah Akun Pengguna',
			link: '#',
		},
	]

	const classes = adduseraccountStyle()
	const [open, setOpen] = useState(false)
	const [open2, setOpen2] = useState(false)
	const [scroll, setScroll] = useState('paper')
	const [confirmModalSubmit, setConfirmModalSubmit] = useState(false)
	const [errorBackend, setErrorBackend] = useState({})
	const [modalNotif, setModalNotif] = useState(false)
	// const [notifError, setNotifError] = useState(false)
	const [notifSuccess, setNotifSuccess] = useState(false)
	const [listDepartment, setListDepartment] = useState([])

	const dispatch = useDispatch()
	const responseData = useSelector((state) => state.UsersAccountCreateReducer)
	const dataLogin = useSelector((state) => state.auth)
	const listDepartmentReducer = useSelector(
		(state) => state.departmentListReducer
	)

	const handleClickOpen = (scrollType) => () => {
		setOpen(true)
		setScroll(scrollType)
	}

	const handleClickOpen2 = (scrollType) => () => {
		setOpen2(true)
		setScroll(scrollType)
	}

	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		dispatch(
			requestDepartmentList({
				queryString: { limit: 200, filter: '{"status":"99"}' },
			})
		)
	}, [])

	// list department filter
	useEffect(() => {
		if (listDepartmentReducer.data) {
			let result = listDepartmentReducer.data.items
			if (result) {
				setListDepartment(result)
			}
		}
	}, [listDepartmentReducer])

	// get error_backend and parse
	useEffect(() => {
		// var tmpErrorBackend
		if (responseData.error) {
			if (responseData.error.http_body) {
				let http_body = responseData.error.http_body
				if (http_body) {
					let body_obj = JSON.parse(http_body)
					// tmpErrorBackend = body_obj
					setErrorBackend(body_obj)
				}
			}
		}

		// set notif error
		// eslint-disable-next-line prettier/prettier
		// if ( (tmpErrorBackend && tmpErrorBackend.error && containsString(tmpErrorBackend.error.message, ['Error', 'error']) && tmpErrorBackend.error.message) || responseData.isError ) {
		// 	setNotifError(true)
		// }

		// set notif success
		if (
			!responseData.isError &&
			responseData.data &&
			responseData.data.message != ''
		) {
			setNotifSuccess(true)
		}

		// show modal
		if (responseData.data.message != '') {
			setModalNotif(true)
		}
	}, [responseData])

	useEffect(() => {
		dispatch(requestUserAccountCreate({}, 'RESET'))
	}, [])

	const handleValidateForm = async (validateForm, val) => {
		const error = await validateForm()
		const currentErrorKeys = Object.keys(error)
		if (currentErrorKeys.length) {
			currentErrorKeys.map((item) => {
				val.setFieldError(item)
				val.setFieldTouched(item)
			})
		} else {
			setConfirmModalSubmit(true)
		}
	}

	const handleSubmit = (formdata, setSubmitting) => {
		let formdataStatus = formdata.status
		//let formdataRoles = formdata.roles
		var listRolesName = ['admin', 'aggreement', 'finance', 'staff']
		let userRoles = []

		// set contactID
		formdata.contact_id = dataLogin.data.data.contact_id

		// set status
		if (formdataStatus) {
			formdata.status = 99
		} else {
			formdata.status = 98
		}

		// set roles
		for (let i = 0; i < listRolesName.length; i++) {
			let roleName = listRolesName[i]
			if (formdata['roles_' + roleName]) {
				if (roleName == 'staff') {
					userRoles.push({
						role_id: toRoleId(roleName),
						department_id: formdata.department.id,
					})
				} else {
					userRoles.push({ role_id: toRoleId(roleName) })
				}
			}
		}

		// set user_departments
		if (formdata['roles_staff']) {
			formdata.user_departments = [formdata.department.id]
		}

		formdata.user_roles = userRoles

		// console.log('formdata', formdata)

		dispatch(requestUserAccountCreate(formdata))
		setConfirmModalSubmit(false)
		setSubmitting(false)
	}

	const handleModalOK = () => {
		setModalNotif(false)
		if (notifSuccess) {
			dispatch(requestUserAccountCreate({}, 'RESET'))
			navigate('/dashboard/user-account')
		}
	}

	const handleInputChangeDepartment = (v) => {
		if (!v || v == null) {
			v = ''
		}
		if (v.length > 2) {
			let queryString = {
				limit: 100,
				status: 99,
				query: JSON.stringify({ name: v }),
				filter: '{"status":"99"}',
			}
			dispatch(requestDepartmentList({ queryString: queryString }))
		}
	}

	return (
		<Formik
			enableReinitialize
			initialValues={{
				name: '',
				username: '',
				email: '',
				job_title: '',
				mobile: '',
				roles_admin: false,
				roles_aggreement: false,
				roles_finance: false,
				roles_staff: false,
			}}
			validationSchema={schema}
			onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				submitForm,
				validateForm,
				...others
			}) => {
				// console.log('values: ', values)
				return (
					<>
						<Form method='post'>
							<DashboardLayout>
								<Container>
									<Box height={70} alignItems='center' display='flex'>
										<Breadcrumbs breadcrumbs={breadcrumbs} />
									</Box>
									<Box p={1}>
										<Typography variant='h6'>Tambah Akun Pengguna</Typography>
										<Paper
											variant='outlined'
											elevation={3}
											className={classes.boxSpacing}
										>
											<Box p={2}>
												<Collapse in={responseData.isError}>
													<Alert
														iconClose={
															<HighlightOffOutlined
																onClick={() => dispatch(requestUserAccountCreate({}, 'RESET'))}
															/>
														}
														variant={'alert'}
													>
														<Typography variant='body1'>
															{(errorBackend.error &&
																containsString(errorBackend.error.message, [
																	'is not available',
																]) &&
																'Username sudah terpakai, silakan masukkan username lain') ||
																'Terjadi kesalahan server'}
														</Typography>
													</Alert>
												</Collapse>
												<Collapse
													in={!responseData.isError && responseData.data.message != ''}
												>
													<Alert
														iconClose={
															<HighlightOffOutlined
																onClick={() => dispatch(requestUserAccountCreate({}, 'RESET'))}
															/>
														}
														variant={'success'}
													>
														<Typography variant='body1'>
															{!responseData.isError &&
																responseData.data.message != '' &&
																'Success Created.'}
														</Typography>
													</Alert>
												</Collapse>

												<Typography className={classes.formTitle}>
													Informasi Dasar Pengguna
												</Typography>
												<Divider />
												<Grid
													container
													spacing={3}
													justify='space-between'
													className={classes.formField}
												>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2' className={classes.labelRequired}>
															Nama Lengkap
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='name'>
															{({ field }) => (
																<TextField
																	name='name'
																	type='input'
																	fullWidth
																	inputProps={{ maxLength: 100 }}
																	variant='outlined'
																	margin='dense'
																	placeholder='Nama Lengkap'
																	onChange={handleChange}
																	value={values.name}
																	error={errors.name && touched.name}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='name' />
														</span>
													</Grid>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2' className={classes.labelRequired}>
															Username
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='username'>
															{({ field }) => (
																<TextField
																	name='username'
																	type='input'
																	fullWidth
																	variant='outlined'
																	margin='dense'
																	placeholder='Username'
																	onChange={handleChange}
																	value={values.username}
																	error={errors.username && touched.username}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='username' />
														</span>
													</Grid>
												</Grid>
												<Grid
													container
													spacing={3}
													justify='space-between'
													className={classes.formField}
												>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2' className={classes.labelRequired}>
															Email
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='email'>
															{({ field }) => (
																<TextField
																	name='email'
																	type='input'
																	fullWidth
																	variant='outlined'
																	margin='dense'
																	placeholder='Alamat Email'
																	onChange={handleChange}
																	value={values.email}
																	error={errors.email && touched.email}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='email' />
														</span>
													</Grid>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2' className={classes.labelRequired}>
															No.Ponsel
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='mobile'>
															{({ field }) => (
																<TextField
																	name='mobile'
																	type='input'
																	fullWidth
																	variant='outlined'
																	margin='dense'
																	placeholder='No. Ponsel'
																	onChange={handleChange}
																	value={values.mobile}
																	error={errors.mobile && touched.mobile}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='mobile' />
														</span>
													</Grid>
												</Grid>
											</Box>
										</Paper>
										<Paper
											variant='outlined'
											elevation={3}
											className={classes.boxSpacing}
										>
											<Box p={2}>
												<Typography className={classes.formTitle}>
													Informasi Departemen Pengguna
												</Typography>
												<Divider />
												<Grid
													container
													spacing={3}
													justify='space-between'
													className={classes.formField}
												>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography variant='subtitle2'>Jabatan</Typography>
													</Grid>
													<Grid item xs={3}>
														<Field name='job_title'>
															{({ field }) => (
																<TextField
																	id='job_title'
																	fullWidth
																	variant='outlined'
																	margin='dense'
																	placeholder='Jabatan'
																	onChange={handleChange}
																	value={values.job_title}
																	error={errors.job_title && touched.job_title}
																	{...field}
																/>
															)}
														</Field>
														<span style={{ color: 'red' }}>
															<ErrorMessage name='job_title' />
														</span>
													</Grid>
													<Grid item xs={3} className={classes.formLabel}>
														<Typography
															component='span'
															variant='subtitle2'
															className={classes.labelRequired}
														>
															Roles
															<Button
																onClick={handleClickOpen('paper')}
																size='small'
																className={classes.customTooltip}
															>
																<HelpIcon className={classes.customIcon} />
															</Button>
															<RolesDialog
																open={open}
																handleClose={handleClose}
																scroll={scroll}
															/>
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<FormControl>
															<FormGroup>
																<Field name='roles_admin'>
																	{({ field }) => (
																		<FormControlLabel
																			control={
																				<Checkbox
																					color='primary'
																					className={classes.bcheckBox}
																					name='roles_admin'
																					onChange={handleChange}
																					value={true}
																					{...field}
																				/>
																			}
																			label='Admin'
																			labelPlacement='end'
																		/>
																	)}
																</Field>
																<Field name='roles_aggreement'>
																	{({ field }) => (
																		<FormControlLabel
																			control={
																				<Checkbox
																					color='primary'
																					className={classes.bcheckBox}
																					name='roles_aggreement'
																					onChange={handleChange}
																					value={true}
																					{...field}
																				/>
																			}
																			label='Penyetuju'
																			labelPlacement='end'
																		/>
																	)}
																</Field>
																<Field name='roles_finance'>
																	{({ field }) => (
																		<FormControlLabel
																			control={
																				<Checkbox
																					color='primary'
																					className={classes.bcheckBox}
																					name='roles_finance'
																					onChange={handleChange}
																					value={true}
																					{...field}
																				/>
																			}
																			label='Finance'
																			labelPlacement='end'
																		/>
																	)}
																</Field>
																<Field name='roles_staff'>
																	{({ field }) => (
																		<FormControlLabel
																			control={
																				<Checkbox
																					color='primary'
																					className={classes.bcheckBox}
																					name='roles_staff'
																					onChange={handleChange}
																					value={true}
																					{...field}
																				/>
																			}
																			label='Pemohon'
																			labelPlacement='end'
																		/>
																	)}
																</Field>
															</FormGroup>
															<span style={{ color: 'red' }}>
																<ErrorMessage name='validation_roles' />
															</span>
														</FormControl>
													</Grid>
												</Grid>
												<Grid
													container
													spacing={3}
													justify='space-between'
													className={classes.formField}
												>
													<Grid item xs={3} className={classes.formLabel}>
														{/* <Typography variant='subtitle2' className={classes.labelRequired}>
															Departemen
														</Typography> */}
													</Grid>
													<Grid item xs={3}>
														{/* <Autocomplete
															multiple
															id='department'
															options={departments}
															getOptionLabel={option => option.title}
															renderInput={params => (
																<TextField
																	{...params}
																	variant='outlined'
																	placeholder=' Pilih Departemen'
																	margin='dense'
																/>
															)}
														/> */}
													</Grid>
													{values.roles_staff && values.roles_staff === true && (
														<>
															<Grid item xs={3} className={classes.formLabel}>
																<Typography
																	variant='subtitle2'
																	className={classes.labelRequired}
																>
																	Departemen Untuk Pembelian
																	<Button
																		onClick={handleClickOpen2('paper')}
																		size='small'
																		className={classes.customTooltip}
																	>
																		<HelpIcon className={classes.customIcon} />
																	</Button>
																	<DepartmentDialog
																		open={open2}
																		handleClose={() => setOpen2(false)}
																	/>
																</Typography>
															</Grid>
															<Grid item xs={3}>
																<Autocomplete
																	id='department'
																	name='department'
																	options={listDepartment}
																	getOptionLabel={(option) => option.name}
																	onChange={(e, v, r) => {
																		if (r == 'select-option') {
																			others.setFieldValue('department', v)
																		}
																	}}
																	onInputChange={(e, v, r) => {
																		if (r == 'clear' || r == 'input') {
																			handleInputChangeDepartment(v)
																			others.setFieldValue('department', '')
																		}
																	}}
																	onClose={(e, r) => {
																		if (r != 'select-option') {
																			others.setFieldValue('department', '')
																		}
																	}}
																	style={{ width: '100%' }}
																	renderInput={(params) => (
																		<Field name='department'>
																			{({ field }) => (
																				<TextField
																					placeholder='Pilih Department'
																					fullWidth
																					margin='dense'
																					variant='outlined'
																					error={errors.department && touched.department}
																					{...params}
																					{...field}
																				/>
																			)}
																		</Field>
																	)}
																/>
																<span style={{ color: 'red' }}>
																	<ErrorMessage name='validation_department' />
																</span>
															</Grid>
														</>
													)}
												</Grid>
											</Box>
										</Paper>
										<Paper
											variant='outlined'
											elevation={3}
											className={classes.boxSpacing}
										>
											<Box p={2}>
												<Grid
													container
													justify='space-between'
													className={classes.formField}
												>
													<Grid item xs={12}>
														<FormControl>
															<FormGroup>
																<Field name='status'>
																	{({ field }) => (
																		<FormControlLabel
																			control={
																				<Switch
																					name='status'
																					color='secondary'
																					value='99'
																					{...field}
																				/>
																			}
																			label='Status'
																			labelPlacement='start'
																		/>
																	)}
																</Field>
															</FormGroup>
														</FormControl>
													</Grid>
												</Grid>
											</Box>
										</Paper>
										<Grid
											container
											spacing={1}
											justify='flex-end'
											className={classes.boxSpacing}
										>
											<Grid item>
												<Button
													variant='contained'
													color='primary'
													className={classes.buttonNegative}
													onClick={() => navigate('/dashboard/user-account')}
												>
													Batal
												</Button>
											</Grid>
											<Grid item>
												<Button
													id='btn-save'
													variant='contained'
													color='secondary'
													disabled={responseData.isLoading}
													// type='submit'
													onClick={() => handleValidateForm(validateForm, others)}
												>
													Simpan{' '}
													{responseData.isLoading && (
														<CircularProgress size={14} style={{ marginLeft: '5px' }} />
													)}
												</Button>
											</Grid>
										</Grid>
									</Box>
								</Container>
							</DashboardLayout>
						</Form>

						<CustomModal
							icon='question'
							open={confirmModalSubmit}
							onClose={() => setConfirmModalSubmit(false)}
						>
							<Typography variant='h6'>
								<strong>Submit Create User ?</strong>
							</Typography>
							<Box
								display='flex'
								flexDirection='row'
								mt={3}
								justifyContent='space-around'
								width={300}
							>
								<Button
									className={classes.buttonWidth}
									size='small'
									classes={{
										contained: classes.buttonDanger,
									}}
									variant='contained'
									onClick={() => setConfirmModalSubmit(false)}
								>
									{' '}
									Batal{' '}
								</Button>
								<Button
									className={classes.buttonWidth}
									size='small'
									color='secondary'
									variant='contained'
									type='submit'
									onClick={() => submitForm()}
								>
									{' '}
									Ya{' '}
								</Button>
							</Box>
						</CustomModal>

						<CustomModal
							icon={notifSuccess ? 'success' : 'error'}
							open={modalNotif}
							onClose={() => setModalNotif(false)}
						>
							<Typography variant='h6'>
								{notifSuccess ? (
									<strong>Tambah Akun Pengguna Berhasil</strong>
								) : (
									<strong>Tambah Akun Pengguna Gagal</strong>
								)}
							</Typography>
							<Box
								display='flex'
								flexDirection='row'
								mt={3}
								justifyContent='center'
								// width={300}
							>
								<Button
									className={classes.buttonWidth}
									size='small'
									classes={{
										contained: classes.buttonDanger,
									}}
									variant='contained'
									onClick={() => handleModalOK()}
								>
									{' '}
									OK{' '}
								</Button>
							</Box>
						</CustomModal>
					</>
				)
			}}
		</Formik>
	)
}

export default adduserAccount
