import React, { useState, useEffect } from 'react'
import {
	List,
	ListItemIcon,
	ListItemText,
	ListItem,
	Collapse,
} from '@material-ui/core'
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons'
import PropTypes from 'prop-types'
import Styles from './styles'
// import { navigate } from '@reach/router'

const Component = ({ data, uri, parentId = [] }) => {
	const [allParentId, setAllParentid] = useState([])

	useEffect(() => {
		setAllParentid([...parentId])
	}, [parentId])

	const filterCategoriesData = data

	const classes = Styles()
	const [level, setLevel] = useState({})

	const handleClickCategories = (item) => {
		let newUri = Object.keys(uri)
			.map((key) => key + '=' + uri[key])
			.join('&')

		window.location.href =
			`/catalog/${item.slug}` + (newUri && newUri != '' ? '?' + newUri : '')
		// 	navigate(
		// 	`/catalog/${item.slug}` + (newUri && newUri != '' ? '?' + newUri : '')
		// )
	}

	const handleCollapse = (item) => {
		// console.log('Collapse trigger')
		// handleClickCategories(item)
		//const path = (item.parent_path === '') ? 1 : item.parent_path.split('/').length + 1
		setAllParentid([])
		const path =
			item.parent_path === '' ? 1 : item.parent_path.split('/').length - 1

		if (level[path] == item.id) {
			setLevel({ ...level, [path]: false })
		} else {
			setLevel({ ...level, [path]: item.id })
		}
	}

	const isCollapse = (item, lvl, type = 'parent') => {
		if (type == 'parent') {
			if (item.id === level[lvl]) {
				return true
			} else if (allParentId.length > 0) {
				if (item.id !== level[lvl] && allParentId.includes(item.id)) {
					const path =
						item.parent_path === '' ? 1 : item.parent_path.split('/').length - 1
					setLevel({ ...level, [path]: item.id })
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		} else {
			if (item.parent_id === level[lvl]) {
				return true
			} else {
				return false
			}
		}
	}

	return (
		<List disablePadding>
			{filterCategoriesData.map((item, index) => (
				<React.Fragment key={index.toString()}>
					<ListItem className={classes.listItem} divider disableGutters>
						<ListItemText onClick={() => handleClickCategories(item)}>
							{item.category_name}
						</ListItemText>
						{item.child_category && item.child_category.length ? (
							<ListItemIcon
								onClick={() => handleCollapse(item)}
								className={classes.listIcon}
							>
								{isCollapse(item, '1', 'parent', allParentId) ? (
									<ExpandLessOutlined fontSize='small' />
								) : (
									<ExpandMoreOutlined fontSize='small' />
								)}
							</ListItemIcon>
						) : null}
					</ListItem>
					{item.child_category && item.child_category.length > 0
						? item.child_category.map((item, index) => (
								<Collapse
									key={index}
									in={isCollapse(item, '1', 'child')}
									timeout='auto'
									unmountOnExit
								>
									<List disablePadding>
										<ListItem className={classes.listItem} divider>
											<ListItemText onClick={() => handleClickCategories(item)}>
												{item.category_name}
											</ListItemText>
											{item.child_category && item.child_category.length ? (
												<ListItemIcon
													onClick={() => handleCollapse(item)}
													className={classes.listIcon}
												>
													{isCollapse(item, '2', 'parent', allParentId) ? (
														<ExpandLessOutlined fontSize='small' />
													) : (
														<ExpandMoreOutlined fontSize='small' />
													)}
												</ListItemIcon>
											) : null}
										</ListItem>
										{item.child_category && item.child_category.length
											? item.child_category.map((item, index) => (
													<Collapse
														key={index}
														in={isCollapse(item, '2', 'child')}
														timeout='auto'
														unmountOnExit
													>
														<List disablePadding>
															<ListItem
																className={`${classes.listItem} ${classes.listItem}--1`}
																divider
															>
																<ListItemText onClick={() => handleClickCategories(item)}>
																	{item.category_name}
																</ListItemText>
																{/* {item.child_category && item.child_category.length ? (
																	<ListItemIcon
																		onClick={() => handleCollapse(item)}
																		className={classes.listIcon}
																	>
																		{item.id === level['3'] ? (
																			<ExpandLessOutlined fontSize='small' />
																		) : (
																			<ExpandMoreOutlined fontSize='small' />
																		)}
																	</ListItemIcon>
																) : null} */}
															</ListItem>
															{/* {item.child_category && item.child_category.length
																? item.child_category.map((item, index) => (
																		<Collapse
																			key={index}
																			in={item.parent_id === level['3']}
																			timeout='auto'
																			unmountOnExit
																		>
																			<List disablePadding>
																				<ListItem
																					className={`${classes.listItem} ${classes.listItem}--2`}
																					divider
																				>
																					<ListItemText>{item.category_name}</ListItemText>
																				</ListItem>
																			</List>
																		</Collapse>
																  ))
																: null} */}
														</List>
													</Collapse>
											  ))
											: null}
									</List>
								</Collapse>
						  ))
						: null}
				</React.Fragment>
			))}
		</List>
	)
}

Component.propTypes = {
	data: PropTypes.array,
	aggsCatalog: PropTypes.array,
	uri: PropTypes.object,
	parentId: PropTypes.array,
}

export default Component
