import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LayoutComponent from 'Components/layout'
import Card from 'Components/cardAbout'
import Breadcrums from 'Components/breadcrumbs'
import { Container, Box, Grid, Typography } from '@material-ui/core'
import { aboutStyle } from './styles'
import content1 from '../../assets/images/content/content-1.jpg'
import content2 from '../../assets/images/content/content-2.jpg'

const About = () => {
	const classes = aboutStyle()
	const content = useStaticQuery(graphql`
		{
			allDataJson {
				edges {
					node {
						data {
							clevel {
								name
								title
								misc1
								misc2
								picture {
									relativePath
									id
									childImageSharp {
										gatsbyImageData(
											layout: FIXED
											width: 200
											placeholder: NONE
											formats: [AUTO, WEBP]
										)
									}
								}
							}
							domain
							misc1
							misc2
							title
							video
						}
					}
				}
			}
		}
	`)

	const breadcrums = [
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Tentang Kami',
			link: '#',
		},
	]
	const { data } = content.allDataJson.edges[0].node
	return (
		<>
			<LayoutComponent>
				<Box height={70} alignItems='center' display='flex'>
					<Breadcrums breadcrumbs={breadcrums} />
				</Box>
				<Grid container justify='center'>
					<Grid item lg={9}>
						<Box>
							<iframe
								className={classes.iframe}
								src={data.video}
								frameBorder='0'
								webkitallowfullscreen='true'
								mozallowfullscreen='true'
								allowFullScreen
							/>
						</Box>

						<Box>
							<Container>
								<Box marginBottom={1} marginTop={2} marginLeft={5.6}>
									<Typography variant='h6'>{data.domain}</Typography>
									<Typography variant='h6'>{data.title}</Typography>
								</Box>
								<Grid container justify='space-evenly'>
									<Grid item lg={5}>
										<Typography variant='body2'>{data.misc1}</Typography>
									</Grid>
									<Grid item lg={5}>
										<Typography variant='body2'>{data.misc2}</Typography>
									</Grid>
								</Grid>
							</Container>
						</Box>

						<Box
							display='flex'
							flexDirection='row'
							height={250}
							marginTop={2}
							className={classes.boxDecorationContainer}
						>
							<Box height={'100%'} flexGrow={1} className={classes.imageDecoration1}>
								<img alt='content-1' src={content1} width='100%' height='100%' />
							</Box>
							<Box height={'100%'} flexGrow={1} className={classes.imageDecoration2}>
								<img alt='content-2' src={content2} width='100%' height='100%' />
							</Box>
						</Box>
					</Grid>
				</Grid>

				<Box marginTop={10} display='grid' className={classes.cardContainer}>
					{data.clevel.map((item, index) => (
						<Box
							key={index.toString()}
							display='flex'
							justifyContent='center'
							marginTop={15}
						>
							<Card
								alt={item.name}
								imgName={item.picture.childImageSharp.gatsbyImageData}
								name={item.name}
								title={item.title}
								desc1={item.misc1}
								desc2={item.misc2}
							/>
						</Box>
					))}
				</Box>
			</LayoutComponent>
		</>
	)
}

export default About
