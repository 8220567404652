import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'

export const type = [
	'image/jpeg',
	'image/jpg',
	'image/png',
	'application/pdf',
	'text/csv',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/wps-writer',
	'application/vnd.ms-excel',
	'application/excel',
	'application/x-excel',
	'application/x-msexcel',
	'application/doc',
	'application/ms-doc',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

export const bodyFile = {
	file: null,
	id: null,
	uploadLoad: 0,
	uploadTotal: 0,
	isError: false,
	progress: 0,
}

export const validateSubmit = (uploadFile, errorUpload) => {
	let res = {
		isError: false,
		errorMsg: [],
	}
	if (uploadFile.length == 0) {
		let temp = []
		temp.push('Anda wajib mengunggah minimal satu dokumen')
		res.errorMsg = temp
		res.isError = true
	} else if (uploadFile.length == 1 && errorUpload) {
		let temp = []
		temp.push('Anda wajib mengunggah minimal satu dokumen')
		res.errorMsg = temp
		res.isError = true
	}
	return res
}

//convert function list user profile to array contact Id for send paramater backend approve PR
export const createArrayContactId = (data) => {
	let res = []
	data && data.length != 0 ? data.map((item) => res.push(item.contact_id)) : null
	return res
}

export const renderButtonComponent = (
	curent_approval_users,
	is_rejected,
	current_user_approvals,
	remaining_approvals,
	status,
	classes,
	allowedPenyetuju,
	approveLoading,
	user,
	handleApprovePr,
	openModal,
	handleBackPage,
	openDialog
) => {
	if (is_rejected) {
		return (
			<Button
				id='btn-back'
				className={[classes.buttonWidth, classes.kembali, classes.btnWhite].join(
					' '
				)}
				variant='outlined'
				color='secondary'
				onClick={() => handleBackPage()}
			>
				Kembali
			</Button>
		)
	} else {
		const exploded_users_ids = curent_approval_users.split(',')
		const currentApprover = exploded_users_ids.find((id) => id.includes(user.id))
		const eprocs = remaining_approvals
			? Object.keys(remaining_approvals.eproc)
			: []
		if (currentApprover && current_user_approvals === 'E-procurement') {
			return (
				<>
					<Button
						className={[classes.buttonWidth, classes.kembali, classes.btnWhite].join(
							' '
						)}
						variant='outlined'
						color='secondary'
						onClick={() => handleBackPage()}
					>
						Kembali
					</Button>
					{allowedPenyetuju && status != 4 && status != 5 ? (
						<Button
							variant='contained'
							color='primary'
							className={`${classes.buttonAction} ${classes.btnCancel}`}
							onClick={() => openModal(true)}
						>
							Tolak
						</Button>
					) : null}

					{allowedPenyetuju && eprocs.length > 1 && status != 5 ? (
						<Button
							variant='contained'
							color='secondary'
							className={classes.buttonAction}
							onClick={() => handleApprovePr()}
							disabled={approveLoading}
						>
							{approveLoading ? (
								<CircularProgress size='20px' />
							) : (
								'Setujui Purchase Request'
							)}
						</Button>
					) : status != 4 && status != 5 ? (
						<Button
							id='btn-create-po'
							variant='contained'
							color='secondary'
							className={classes.buttonAction}
							onClick={() => openDialog(true)}
							disabled={approveLoading}
						>
							Membuat Purchase Order
						</Button>
					) : null}
				</>
			)
		} else if (currentApprover) {
			return (
				<>
					<Button
						className={[classes.buttonWidth, classes.kembali, classes.btnWhite].join(
							' '
						)}
						variant='outlined'
						color='secondary'
						onClick={() => handleBackPage()}
					>
						Kembali
					</Button>
					{allowedPenyetuju && status != 4 && status != 5 ? (
						<Button
							variant='contained'
							color='primary'
							className={`${classes.buttonAction} ${classes.btnCancel}`}
							onClick={() => openModal(true)}
						>
							Tolak
						</Button>
					) : null}

					{allowedPenyetuju && status != 4 && status != 5 ? (
						<Button
							variant='contained'
							color='secondary'
							className={classes.buttonAction}
							onClick={() => handleApprovePr()}
							disabled={approveLoading}
						>
							{approveLoading ? (
								<CircularProgress size='20px' />
							) : (
								'Setujui Purchase Request'
							)}
						</Button>
					) : null}
				</>
			)
		}
	}
	return (
		<Button
			className={[classes.buttonWidth, classes.kembali, classes.btnWhite].join(
				' '
			)}
			variant='outlined'
			color='secondary'
			onClick={() => handleBackPage()}
			size='small'
		>
			Kembali
		</Button>
	)
}
