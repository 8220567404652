import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Styles from './styles'
import { Pagination, Skeleton } from '@material-ui/lab'
import { Boxes } from 'Components/svgs'
import { requestAddress } from 'States/actions/companyAddressAction'
import { CloseOutlined, SearchOutlined } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { setAddress } from 'States/actions/companyAddressAction'
import PropTypes from 'prop-types'
import {
	Box,
	Modal as MuiModal,
	Button,
	Divider,
	Fade,
	Backdrop,
	Paper,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	OutlinedInput,
	TableContainer,
} from '@material-ui/core'
import { propTypes } from 'react-recaptcha'

const CustomCell = withStyles(() => ({
	root: {
		border: 'none',
	},
}))(TableCell)

const ModalAddress = ({ open, addressType, onClose }) => {
	const classes = Styles()
	const dispatch = useDispatch()
	const [currentPage, setCurrentPage] = useState(1)
	const [region, setRegion] = useState('')
	const [name, setName] = useState('')
	const pageLimit = 5
	const rowNumber = (pagination) => {
		return (pagination.current - 1) * pageLimit + 1
	}
	const lastRowNumber = (pagination) => {
		return rowNumber(pagination) + (pagination.count - 1)
	}

	const addressData = useSelector((state) => {
		if (addressType == 'shipping') {
			return state.companyAddressReducer.shippingAddressReducer
		} else {
			return state.companyAddressReducer.billingAddressReducer
		}
	})

	useEffect(() => {
		if (open) {
			setCurrentPage(1)
			setName('')
			setRegion('')
			dispatch(requestAddress(addressType, false, 1, name, region, pageLimit))
		}
	}, [open])

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		const timeOutId = setTimeout(() => {
			searchData()
		}, 1000)
		return () => clearTimeout(timeOutId)
	}, [name, region])

	const handleSelect = (addrs) => {
		dispatch(setAddress(addressType, addrs))
		onClose()
	}

	const handlePageChange = (e, page) => {
		setCurrentPage(page)
		searchData(page)
	}

	const searchData = (page = 1) => {
		const labelName = name.length < 3 ? '' : name
		const regionName = region.length < 3 ? '' : region
		dispatch(requestAddress(addressType, false, page, labelName, regionName))
	}

	const handleSearch = () => {
		searchData()
	}

	return (
		<MuiModal
			className={classes.modal}
			open={open}
			closeAfterTransition
			onClose={() => onClose()}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open} className={classes.modalFade}>
				<Paper>
					<Box p={2} display='flex' flexDirection='column'>
						<CloseOutlined
							className={classes.modalCloseIcon}
							onClick={() => onClose()}
						/>
						<Typography variant='h6'>
							<strong>
								Ubah Alamat {addressType == 'shipping' ? 'Pengiriman' : 'Tagihan'}
							</strong>
						</Typography>
						<Divider />
						<Box mt={1.5} mx={2}>
							<Grid container spacing={2}>
								<Grid item lg={4}>
									<OutlinedInput
										margin='dense'
										value={name}
										onChange={(e) => setName(e.target.value)}
										placeholder='Masukkan Label Alamat'
										fullWidth
									/>
								</Grid>
								<Grid item lg>
									<OutlinedInput
										margin='dense'
										value={region}
										onChange={(e) => setRegion(e.target.value)}
										placeholder='Masukkan Provinsi, Kota, Kecamatan, Kelurahan, Kode Pos'
										fullWidth
									/>
								</Grid>
								<Grid item marginLeft={1.5}>
									<Button
										variant='contained'
										color='secondary'
										onClick={() => handleSearch()}
									>
										<SearchOutlined />
									</Button>
								</Grid>
							</Grid>
						</Box>
						{addressData.data ? (
							!addressData.data.result.length ? (
								<Box
									display='flex'
									alignItems='center'
									flexDirection='column'
									justifyContent='center'
									py={2}
								>
									<Boxes />
									<Typography>
										Tidak ada data alamat
										{addressType == 'shipping' ? ' pengiriman' : ' tagihan'} yang dapat
										ditampilkan
									</Typography>
								</Box>
							) : (
								<TableContainer className={classes.tableContainer}>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell style={{ width: '20%' }}>
													<strong>Label Alamat</strong>
												</TableCell>
												<TableCell style={{ width: '40%' }}>
													<strong>Alamat Lengkap</strong>
												</TableCell>
												<TableCell>
													<strong>Lokasi</strong>
												</TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{addressData.isLoading
												? [1, 2, 3, 4, 5].map((itm) => (
														<TableRow key={itm}>
															<CustomCell>
																<Skeleton variant='rect' height={30} width={200} />
															</CustomCell>
															<CustomCell>
																<Skeleton variant='rect' height={30} width={200} />
															</CustomCell>
															<CustomCell>
																<Skeleton variant='rect' height={30} width={100} />
															</CustomCell>
															<CustomCell>
																<Skeleton variant='rect' height={30} width={100} />
															</CustomCell>
														</TableRow>
												  ))
												: addressData.data.result.map((addrs, index) => (
														<TableRow key={index}>
															<CustomCell>{addrs.name}</CustomCell>
															<CustomCell>{addrs.street}</CustomCell>
															<CustomCell>{addrs.region_id.complete_name}</CustomCell>
															<CustomCell>
																{addressData.defaultAddress ? (
																	<Button
																		onClick={() => handleSelect(addrs)}
																		fullWidth
																		size='small'
																		variant={
																			addressData.defaultAddress.id === addrs.id
																				? 'contained'
																				: 'outlined'
																		}
																		color='secondary'
																	>
																		{addressData.defaultAddress.id === addrs.id
																			? 'Terpilih'
																			: 'Pilih'}
																	</Button>
																) : null}
															</CustomCell>
														</TableRow>
												  ))}
										</TableBody>
									</Table>
								</TableContainer>
							)
						) : null}
						<Divider />
						<Box my={2}>
							{!addressData.data ? null : !addressData.data.pagination
									.total_page ? null : (
								<Grid container justify='flex-end' alignItems='center'>
									<Typography className={classes.typoPage}>
										{`Menampilkan ${rowNumber(
											addressData.data.pagination
										)} - ${lastRowNumber(addressData.data.pagination)} data dari ${
											addressData.data.pagination.total_records
										}`}
									</Typography>
									<Pagination
										count={addressData.data.pagination.total_page}
										page={currentPage}
										shape='rounded'
										color='secondary'
										onChange={handlePageChange}
										siblingCount={2}
									/>
								</Grid>
							)}
						</Box>
					</Box>
				</Paper>
			</Fade>
		</MuiModal>
	)
}

ModalAddress.propTypes = {
	open: PropTypes.bool,
	addressType: propTypes.string,
	onClose: PropTypes.func,
}

export default ModalAddress
