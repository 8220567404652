import * as Yup from 'yup'

var schema = Yup.object().shape({
	name: Yup.string().required('Nama lengkap tidak boleh kosong.'),
	username: Yup.string()
		.required('Username tidak boleh kosong.')
		.min(3, 'Username harus lebih dari 3 karakter.')
		.matches(
			/^[a-z][a-z0-9_.-]*$/,
			'Username hanya boleh mengandung huruf kecil, alfanumerik "_", "-", "."'
		),
	email: Yup.string()
		.required('Email tidak boleh kosong')
		.email('Format email tidak sesuai'),
	mobile: Yup.string()
		.required('No Ponsel tidak boleh kosong')
		.matches(
			/^\+?[\d+]+$/,
			'No.Ponsel hanya boleh mengandung karakter + dan numerik'
		),
	job_title: Yup.string(),
	roles_admin: Yup.boolean(),
	roles_aggreement: Yup.boolean(),
	roles_finance: Yup.boolean(),
	roles_staff: Yup.boolean(),
})

schema = schema.test(
	// this test is added additional to any other (build-in) tests
	'myCustomCheckboxTest',
	null, // we'll return error message ourself if needed
	(obj) => {
		// only testing the checkboxes here
		if (
			obj.roles_admin ||
			obj.roles_aggreement ||
			obj.roles_finance ||
			obj.roles_staff
		) {
			// put every checkbox here
			return true // everything is fine
		}

		return new Yup.ValidationError(
			'Role wajib dipilih minimal satu', // your custom error message
			null,
			'validation_roles'
		)
	}
)

schema = schema.test(
	// this test is added additional to any other (build-in) tests
	'myCustomDepartmentTest',
	null, // we'll return error message ourself if needed
	(obj) => {
		// only testing the checkboxes here
		if (!obj.roles_staff || (obj.department && obj.department != '')) {
			// put every checkbox here
			return true // everything is fine
		}

		return new Yup.ValidationError(
			'Department wajib dipilih minimal satu', // your custom error message
			null,
			'validation_department'
		)
	}
)

export default schema
