import { DETAIL_QUOTATION } from '../../../types'
import { success, failure, loading } from '../../../commonActions'
import API from '../../../../utils/api'
import { navigate } from 'gatsby'

export const requestDetailQuotation = (rfqId) => {
	return async (dispatch) => {
		dispatch(loading(DETAIL_QUOTATION))
		try {
			const response = await API({
				method: 'GET',
				url: `/transactions/quotations/detail/${rfqId}`,
			})
			if (response.data) {
				if ('error_backend' in response.data) {
					navigate(`/${response.data.error_backend.http_status_code}`)
				} else {
					dispatch(success(DETAIL_QUOTATION, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(DETAIL_QUOTATION, err))
		}
	}
}

export const updateDetailQuotation = (id, body) => {
	return async (dispatch) => {
		try {
			const response = await API({
				method: 'PUT',
				url: `/transaction/sale-order/update/${id}`,
				data: body,
			})

			if (response.status == 200) {
				if ('error_backend' in response?.data) {
					return {
						success: false,
					}
				} else {
					return {
						success: true,
					}
				}
			}
		} catch (err) {
			dispatch(failure(DETAIL_QUOTATION, err))
			return err
		}
	}
}

export const resetAction = () => {
	return {
		type: `${DETAIL_QUOTATION}_RESET`,
	}
}
