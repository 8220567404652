// import React from 'react'
// import { useSelector } from 'react-redux'
import {
	HandshakeSidebar,
	InvoiceDollar,
	Leader,
	Gift,
	MoneyBill,
	Payment,
	Profile,
	AddressBook,
	Organization,
	Setting,
} from '../svgs'

export const getStructure = () => {
	// const auth = useSelector(state => state.auth)

	const sidebarStructure = [
		{
			id: 'dashboard',
			title: 'Dasbor',
			name: 'dasbor',
			parent: true,
			link: '/dashboard',
		},
		{
			id: 'transaksi',
			title: 'Transaksi',
			name: 'transaksi',
			parent: true,
			child: [
				{
					id: 'rfq',
					title: 'RFQ',
					name: 'transaksi.rfq',
					link: '/dashboard/rfq',
					icon: HandshakeSidebar,
				},
				{
					id: 'quotation',
					title: 'Quotation',
					name: 'transaksi.quotation',
					link: '/dashboard/quotation',
					icon: InvoiceDollar,
				},
				{
					id: 'purchase-request',
					title: 'Purchase Request',
					name: 'transaksi.pr',
					link: '/dashboard/purchase-request',
					icon: Leader,
				},
				{
					id: 'purchase-order',
					title: 'Purchase Order',
					name: 'transaksi.po',
					link: '/dashboard/purchase-order',
					icon: Gift,
				},
				{
					id: 'tagihan',
					title: 'Tagihan',
					name: 'transaksi.tagihan',
					link: '/dashboard/invoice',
					icon: MoneyBill,
				},
				{
					id: 'pembayaran',
					title: 'Pembayaran',
					name: 'transaksi.pembayaran',
					link: '/dashboard/payment',
					icon: Payment,
				},
			],
		},
		{
			id: 'perusahaan',
			title: 'Perusahaan',
			name: 'perusahaan',
			parent: true,
			child: [
				{
					id: 'profile-perusahaan',
					title: 'Profil Perusahaan',
					name: 'perusahaan.profil',
					link: '/dashboard/company-profile',
					icon: Profile,
				},
				{
					id: 'akun-bank',
					title: 'Akun Bank',
					name: 'perusahaan.bank',
					link: '/dashboard/bank-account',
					icon: MoneyBill,
				},
				{
					id: 'alamat',
					title: 'Daftar Alamat',
					name: 'perusahaan.alamat',
					link: '/dashboard/company-address',
					icon: AddressBook,
				},
				{
					id: 'daftar-akun-pengguna',
					title: 'Daftar Akun Pengguna',
					name: 'perusahaan.akun',
					link: '/dashboard/user-account',
					icon: Profile,
				},
				{
					id: 'departemen',
					title: 'Departemen',
					name: 'perusahaan.department',
					link: '/dashboard/department',
					icon: Organization,
				},
				{
					id: 'manajemen-persetujuan',
					title: 'Manajemen Persetujuan',
					name: 'perusahaan.persetujuan',
					icon: Leader,
					child: [
						{
							id: 'penyetuju-kategori',
							title: 'Penyetuju Kategori',
							name: 'perusahaan.persetujuan.kategori',
							link: '/dashboard/approval/category',
						},
						{
							id: 'penyetuju-departement',
							title: 'Penyetuju Departemen',
							name: 'perusahaan.persetujuan.departemen',
							link: '/dashboard/approval/department',
						},
						{
							id: 'e-procurement',
							title: 'E-procurement',
							name: 'perusahaan.persetujuan.proc',
							link: '/dashboard/approval/eproc',
						},
						{
							id: 'pengaturan',
							title: 'Pengaturan',
							name: 'perusahaan.persetujuan.config',
							link: '/dashboard/approval/configure',
						},
					],
				},
				{
					id: 'pengaturan-pembelian',
					title: 'Pengaturan Pembelian',
					name: 'perusahaan.pengaturan.pembelian',
					icon: Setting,
					child: [
						{
							id: 'anggaran',
							title: 'Anggaran',
							name: 'perusahaan.pengaturan.pembelian.anggaran',
							link: '/dashboard/purchase-setting/budget',
						},
					],
				},
			],
		},
		{
			id: 'mou',
			title: 'MOU',
			name: 'mou',
			parent: true,
			link: '/dashboard/mou',
		},
		{
			id: 'pusat-unduh-data',
			title: 'Pusat Unduh Data',
			name: 'pusatunduhdata',
			parent: true,
			child: [
				{
					id: 'unduh-data-transaksi',
					title: 'Unduh Data Transaksi',
					name: 'pusatunduhdata.unduhdatatransaksi',
					link: '/dashboard/download/transaction',
					icon: InvoiceDollar,
				},
				{
					id: 'unduh-data-perusahaan',
					title: 'Unduh Data Perusahaan',
					name: 'pusatunduhdata.unduhdataperusahaan',
					link: '/dashboard/download/company',
					icon: Profile,
				},
				{
					id: 'unduh-data-mou',
					title: 'Unduh Data MOU',
					name: 'pusatunduhdata.unduhdatamou',
					link: '/dashboard/download/mou',
					icon: HandshakeSidebar,
				},
			],
		},
	]

	return sidebarStructure
}
