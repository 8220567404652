import { failure, loading } from '../../commonActions'
import { GET_ALL_COUNTRY, GET_REGION, GET_ALL_STATE } from '../../types'
import API from '../../../utils/api'

const successCuntry = (type, data) => {
	return {
		type: `${type}_FULFILLED`,
		payload: {
			data,
		},
	}
}
const successRegion = (type, data) => {
	return {
		type: `${type}_FULFILLED`,
		payload: {
			data,
		},
	}
}

export const resetLocationAction = () => {
	return {
		type: `${GET_REGION}_RESET`,
	}
}

export const getAllCountry = () => {
	return async (dispatch) => {
		dispatch(loading(GET_ALL_COUNTRY))
		try {
			const response = await API({
				method: 'GET',
				url: '/country?page_size=1000',
			})

			if (response.data) {
				dispatch(successCuntry(GET_ALL_COUNTRY, response.data.data.result))
			}
		} catch (err) {
			dispatch(failure(GET_ALL_COUNTRY, err))
		}
	}
}

export const getAllState = (country_id) => {
	return async (dispatch) => {
		dispatch(loading(`${GET_ALL_STATE}`))
		try {
			const response = await API({
				method: 'GET',
				url: `/state?page_size=1000&country_id=${country_id}`,
			})

			if (response.data) {
				dispatch(successRegion(`${GET_ALL_STATE}`, response.data.data.result))
			}
		} catch (err) {
			dispatch(failure(`${GET_ALL_STATE}`))
		}
	}
}

export const getDistrict = (name) => {
	return async (dispatch) => {
		dispatch(loading(`${GET_REGION}`))
		let newName = name.charAt(0).toUpperCase() + name.slice(1)
		try {
			const response = await API({
				method: 'GET',
				url: `/regions/location/districts-villages?name=${newName}`,
			})

			if (response.data) {
				dispatch(successRegion(`${GET_REGION}`, response.data.data.result))
			}
		} catch (err) {
			dispatch(failure(`${GET_REGION}`))
		}
	}
}

export const getLocation = (name) => {
	return async (dispatch) => {
		dispatch(loading(`${GET_REGION}`))
		let newName = name.charAt(0).toUpperCase() + name.slice(1)
		try {
			const response = await API({
				method: 'GET',
				url: `/regions/location?name=${newName}`,
			})

			if (response.data) {
				dispatch(successRegion(`${GET_REGION}`, response.data.data.result))
			}
		} catch (err) {
			dispatch(failure(`${GET_REGION}`))
		}
	}
}
