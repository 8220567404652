import API from '../../../../utils/api'
import { PURCHASE_REQUEST_DETAIL } from '../../../types'
import { success, loading, failure } from '../../../commonActions'
import { navigate } from 'gatsby'

export const requestPRDetail = (prNumber) => {
	return async (dispatch) => {
		dispatch(loading(PURCHASE_REQUEST_DETAIL))
		try {
			const response = await API({
				method: 'GET',
				url: `/purchase-requests/details/${prNumber}`,
			})
			if (response) {
				if ('error_backend' in response.data) {
					navigate(`/${response.data.error_backend.http_status_code}`)
				} else {
					let newResponse = response.data
					newResponse.data.quotation.data = JSON.parse(
						newResponse.data.quotation.data
					)
					dispatch(success(PURCHASE_REQUEST_DETAIL, newResponse))
				}
			}
		} catch (err) {
			dispatch(failure(PURCHASE_REQUEST_DETAIL, err))
		}
	}
}

export const approvePR = (prNumber, token, values, storage_id) => {
	return API({
		url: `/purchase-requests/approve/${prNumber}`,
		method: 'POST',
		data: {
			token,
			notes: values.notes,
			reference_no: values.reference_no,
			storage_id: storage_id,
			po_user_mail: values.po_user_mail,
		},
	})
}

export const rejectPR = (prNumber, token, reason) => {
	return API({
		url: `/purchase-requests/reject/${prNumber}`,
		method: 'POST',
		data: {
			token,
			status_reason: reason,
		},
	})
}

export const resetAction = () => {
	return {
		type: `${PURCHASE_REQUEST_DETAIL}_RESET`,
	}
}