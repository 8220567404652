import { makeStyles } from '@material-ui/core/styles'

export const Styles = makeStyles((theme) => ({
	buttonSearch: {
		padding: '4.5px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: 0,
		flexWrap: 'no-wrap',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	gridSearch: {
		width: '85%',
	},
	buttonExport: {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
	},
	buttonAction: {
		marginLeft: '5px',
	},
	btnSave: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnCancel: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	labelRequired: {
		position: 'relative',
		'&:after': {
			content: '"*"',
			position: 'absolute',
			color: theme.palette.error.dark,
		},
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		padding: '6px 5px 5px',
		top: '0px',
		right: '2px',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	selectInput: {
		padding: '10px 0 9px 12px !important',
	},
	inputRoot: {
		paddingRight: '0 !important',
		width: 'calc(100%)',
		border: '1px solid #c8c8c8',
		borderRadius: theme.shape.borderRadius,
	},
	inputRootError: {
		paddingRight: '0 !important',
		width: 'calc(100%)',
		border: '1px solid #DC3545',
		borderRadius: theme.shape.borderRadius,
	},
	endAdornment: {
		top: 0,
	},
}))
