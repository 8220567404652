import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import LayoutComponent from '../layout'
// import { navigate } from '@reach/router'
import PropTypes from 'prop-types'

// const handleNavigate = path => {
// 	navigate(path)
// }

const LoginLayout = ({ children, classes, type = 'form' }) => {
	const classes_loginStyle = classes
	return (
		<LayoutComponent
			header={false}
			newsletter={false}
			className={classes_loginStyle.login_background}
			isFooter={false}
		>
			{type == 'form' ? (
				<Grid
					container
					justify='flex-end'
					className={classes_loginStyle.form_login}
				>
					<Grid item xs={12} sm={5} className={classes_loginStyle.container_field}>
						{children}
					</Grid>
				</Grid>
			) : type == 'message' ? (
				<Paper className={classes_loginStyle.message_container} variant='outlined'>
					{children}
				</Paper>
			) : null}
		</LayoutComponent>
	)
}

LoginLayout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	classes: PropTypes.object,
	type: PropTypes.string,
}
export default LoginLayout
