import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles({
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0 30px',
	},
	cardImg: {
		transform: 'translateY(-50%)',
	},
	cardContentWrapper: {
		marginTop: '-20%',
	},
})

export default styles
