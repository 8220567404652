import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'
import { Paper, Box, Typography } from '@material-ui/core'
import useStyles from './styles'
import CloseIcon from '@material-ui/icons/Close'

export default function Component({ children, open, handleClose, title }) {
	const classes = useStyles()
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			className={classes.modal}
		>
			<Paper>
				<Box p={1} display='flex' flexDirection='column' alignItems='flex-end'>
					<Box
						display='flex'
						width={'100%'}
						justifyContent='space-between'
						marginBottom='5px'
						alignItems='center'
					>
						<Typography variant='body1'>{title}</Typography>
						<CloseIcon
							style={{ color: 'grey', fontSize: 16, cursor: 'pointer' }}
							onClick={handleClose}
						/>
					</Box>
					{children}
				</Box>
			</Paper>
		</Modal>
	)
}

Component.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	title: PropTypes.string,
}

Component.defaultProps = {
	open: false,
}
