import React from 'react'
import styles from './styles'
import { statusText, statusStyle } from '../../assets/status'
import PropTypes from 'prop-types'

const Component = (props) => {
	let variant = statusStyle(props.status)
	let text = statusText(props.status)
	const classes = styles({ variant })
	// props.className = props.className == undefined ? classes.status : props.className + " " + classes.status
	return (
		<div className={classes.status} {...props}>
			{text}
		</div>
	)
}

Component.propTypes = {
	status: PropTypes.string.isRequired,
}

Component.displayName = 'Status Label'

export default Component
