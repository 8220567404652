import { makeStyles } from '@material-ui/core/styles'

export const categoriesStyles = makeStyles(() => ({
	gridTitle: {
		marginTop: '20px',
		display: 'flex',
		alignItems: 'center',
	},
	categoryList: {
		marginTop: '40px',
		marginBottom: '40px',
	},
	Parent: {
		paddingLeft: '40px',
		display: 'grid',
		listStyleType: 'none',
		gridTemplateRows: 'repeat(6, minmax(min-content,auto))',
		gridAutoFlow: 'column',
		padding: 0,
		margin: 0,
		'& > *': {
			paddingTop: '10px',
			borderBottom: '1px solid #ccc',
		},
	},
	link: {
		textDecoration: 'none',
		color: 'inherit',
	},
}))
