import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from 'Components/breadcrumbs'
import LayoutComponent from 'Components/layout'
import MenuCategory from 'Components/menu_category'
import { useSelector } from 'react-redux'
import { getMappingIcon } from '../../utils/category'
import { categoriesStyles } from './styles'
import { Box, Typography, Grid } from '@material-ui/core'
import { Cat10 } from 'Components/svgs'
import Sticky from 'react-sticky-el'
import { Link } from 'gatsby'

const Categories = (props) => {
	const clasess = categoriesStyles()

	const listCategory = useSelector((state) => state.listFilterCategoryReducer)
	const indexCategory = useSelector((state) => state.categoryRefReducer.data)

	const icons_map = getMappingIcon({ width: '40px', height: '40px' })
	const myRefs = useRef([])

	const scrollToRef = (i) => {
		window.scrollTo({ top: i - 170, behavior: 'smooth' })
	}

	useEffect(() => {
		if (myRefs) {
			if (myRefs.current[indexCategory.i]) {
				scrollToRef(myRefs.current[indexCategory.i].offsetTop)
			}
		}
	}, [indexCategory])

	return (
		<LayoutComponent>
			<Breadcrumbs breadcrumbs={props.breadcrumbs} />
			<Typography variant='h6'>Produk Berdasarkan Kategori</Typography>
			{listCategory.isLoading == false && listCategory.data ? (
				<Sticky>
					<MenuCategory
						categories={listCategory.data ?? []}
						m_top='0px'
						type='scroll'
						isCategories={true}
					/>
				</Sticky>
			) : null}

			{listCategory.isLoading == false && listCategory.data ? (
				<Box className={clasess.categoryList}>
					{listCategory.data.length != 0 &&
						listCategory.data.map((item, index) => (
							<Box
								ref={(el) => (myRefs.current[index] = el)}
								key={index}
								alignItems='center'
							>
								<Grid
									// onClick={() => handleNavigate(item.slug)}
									className={clasess.gridTitle}
								>
									{icons_map ? (
										icons_map[item.slug] ? (
											icons_map[item.slug]
										) : (
											<Cat10 stroke='#081E3F' width='40px' height='40px' />
										)
									) : (
										<Cat10 stroke='#081E3F' width='40px' height='40px' />
									)}
									<Link to={`/catalog/${item.slug}`} className={clasess.link}>
										<Typography
											style={{
												alignSelf: 'center',
												cursor: 'pointer',
											}}
											variant='subtitle1'
										>
											{item.category_name}
										</Typography>
									</Link>
								</Grid>

								{item.child_category && item.child_category.length ? (
									<Grid>
										<ul className={clasess.Parent}>
											{item.child_category.map((item_child, index_child) => (
												<Link
													key={index_child}
													to={`/catalog/${item_child.slug}`}
													className={clasess.link}
												>
													<li>
														<Typography
															style={{
																cursor: 'pointer',
															}}
															variant='body2'
														>
															{item_child.category_name}
														</Typography>
													</li>
												</Link>
											))}
										</ul>
									</Grid>
								) : null}
							</Box>
						))}
				</Box>
			) : null}
		</LayoutComponent>
	)
}
Categories.propTypes = {
	breadcrumbs: PropTypes.arrayOf(
		PropTypes.shape({
			segment: PropTypes.string,
			icon: PropTypes.string,
			link: PropTypes.string,
		})
	),
}

Categories.defaultProps = {
	breadcrumbs: [
		{ segment: 'Home', link: '/' },
		{ segment: 'Telusuri Kategori', link: '/' },
	],
}

export default Categories
