export const buttonFilter = [
	{
		status: 'all-step',
		state: '',
		child: false,
	},
	{
		status: 'sent',
		state: 'sent',
		child: true,
	},
	{
		status: 'sale',
		state: 'sale',
		child: false,
	},
	{
		status: 'revised',
		state: 'revised',
		child: true,
	},
	{
		status: 'cancel',
		state: 'cancel',
		child: false,
	},
	{
		status: 'expired',
		state: 'expired',
		child: true,
	},
]

export const buttonStatus = [
	{
		status: 'Semua Status',
		state: '',
	},
	{
		status: 'Baru',
		state: 1,
	},
	{
		status: 'PR Terbuat',
		state: 2,
	},
]
