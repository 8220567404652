import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 250
export const styles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerPaper: {
		width: drawerWidth,
		position: 'inherit!important',
		minHeight: '84vh',
		boxShadow: theme.shadows[2],
	},
	imgProfileContainer: {
		borderRadius: 120 / 2,
		width: 120,
		display: 'flex',
		height: 120,
		marginLeft: 'auto',
		marginTop: 20,
		overflow: 'hidden',
		marginRight: 'auto',
		boxShadow: theme.shadows[1],
	},
	listItemGutters: {
		margin: theme.spacing(0.7, 0.7),
		padding: theme.spacing(0.5, 1.4),
		cursor: 'pointer',
		position: 'relative',
		borderRadius: '20px',
		fontSize: '16px',
		fontWeight: 700,
		'&&&:hover': {
			color: theme.palette.secondary.light,
			boxShadow: theme.shadows[1],
		},
		'&&&:first-child': {
			marginTop: theme.spacing(0),
		},
		'&&&:last-child': {
			marginBottom: theme.spacing(4),
		},
	},
	listItem: {
		margin: theme.spacing(0.7, 0.7),
		padding: theme.spacing(0.5, 1.4),
		paddingLeft: theme.spacing(5),
		cursor: 'pointer',
		position: 'relative',
		borderRadius: '20px',
		fontSize: '0.9rem',
		'&&&:hover': {
			color: theme.palette.secondary.light,
			boxShadow: theme.shadows[1],
		},
	},
	activeItem: {
		color: theme.palette.secondary.main,
		boxShadow: theme.shadows[2],
		'&&&:hover': {
			color: theme.palette.secondary.main,
			boxShadow: theme.shadows[2],
		},
	},
	iconRight: {
		position: 'absolute',
		top: '50%',
		right: '0px',
		transform: 'translateY(-50%)',
	},
	listItemIcon: {
		width: '40px',
		minWidth: 'initial',
		left: '8px',
		top: '50%',
		position: 'absolute',
		transform: 'translateY(-55%)',
	},
	headingList: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	marginRight: {
		marginRight: theme.spacing(1),
	},
	textCenter: {
		textAlign: 'center',
	},
	link: {
		cursor: 'pointer',
	},
	profileName: {
		fontWeight: 600,
		fontSize: '23px',
		color: theme.palette.grey[600],
		whiteSpace: 'break-spaces',
	},
}))

export default styles
