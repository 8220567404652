/* eslint-disable react/prop-types */
import React from 'react'
import PrivateRoute from 'Components/privateRoute'
import { ProfileDetail, ProfileEdit } from '../routes'
import { rolesText } from '../constants/text'

export const ProfileNav = () => {
	return (
		<>
			<PrivateRoute
				path='/dashboard/profile'
				roles={rolesText('all')}
				Component={ProfileDetail}
			/>
			<PrivateRoute
				path='/dashboard/profile/edit'
				roles={rolesText('all')}
				Component={ProfileEdit}
			/>
		</>
	)
}
