/* eslint-disable no-use-before-define */
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, {
	createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { Box, Checkbox } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import { IndeterminateCheckBoxOutlined } from '@material-ui/icons'

const CustomCheckbox = withStyles({
	root: {
		color: '#007BFF',
		'&$checked': {
			color: '#007BFF',
		},
		width: 20,
		height: 20,
		margin: 10,
		marginRight: 8,
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} size='small' />)

const MultiSelect = ({
	items,
	selectedValues,
	label,
	placeholder,
	selectAllLabel,
	noOptionsText,
	onToggleOption,
	onSelectAll,
	getOptionLabel,
	renderOption,
	isMultiple = true,
	isFullWidth,
	classStyle,
}) => {
	const classes = styles()
	const allSelected = selectedValues && selectedValues.length > 0
	const indeterminateSelect =
		selectedValues &&
		selectedValues.length > 0 &&
		items.length !== selectedValues.length
	const showPlaceholder = selectedValues && selectedValues.length === 0
	const handleToggleSelectAll = () => {
		onSelectAll && onSelectAll(!allSelected)
	}

	const handleChange = (event, selectedOptions) => {
		if (selectedOptions.find((option) => option.value === 'select-all')) {
			handleToggleSelectAll()
		} else {
			onToggleOption && onToggleOption(selectedOptions)
		}
	}

	const handleChangeSingle = (event, selectedOptions) => {
		onToggleOption && onToggleOption(selectedOptions)
	}

	const optionRenderer = (option, { selected }) => {
		const selectAllProps =
			option.value === 'select-all' // To control the state of 'select-all' checkbox
				? {
						checked: allSelected,
						indeterminate: indeterminateSelect,
				  }
				: {}
		return (
			<>
				<CustomCheckbox
					checked={selected}
					indeterminateIcon={<IndeterminateCheckBoxOutlined />}
					{...selectAllProps}
				/>
				{getOptionLabel(option)}
			</>
		)
	}
	const inputRenderer = (params) => (
		<TextField
			{...params}
			label={label}
			placeholder={showPlaceholder ? placeholder : !isMultiple ? placeholder : ''}
			classes={{
				root: isFullWidth ? classes.textFieldFull : classes.textField,
			}}
		/>
	)
	const tagRenderer = (selected) => (
		<Box className={classes.tagRenderer}>
			{selected.length > 1 ? `${selected.length} Terpilih` : selected[0].label}
		</Box>
	)
	const getOptionSelected = (option, anotherOption) =>
		option.value === anotherOption.value
	const filter = createFilterOptions()
	return isMultiple ? (
		<Autocomplete
			multiple
			size='small'
			options={items}
			value={selectedValues}
			disableCloseOnSelect
			getOptionLabel={getOptionLabel}
			getOptionSelected={getOptionSelected}
			noOptionsText={noOptionsText}
			filterOptions={(options, params) => {
				const filtered = filter(options, params)
				return [{ label: selectAllLabel, value: 'select-all' }, ...filtered]
			}}
			onChange={handleChange}
			renderOption={optionRenderer}
			renderInput={inputRenderer}
			renderTags={tagRenderer}
			classes={{
				option: classes.option,
				input: classes.selectInput,
				inputRoot: classes.inputRoot,
				endAdornment: classes.endAdornment,
				popupIndicator: classes.selectIcon,
			}}
		/>
	) : (
		<Autocomplete
			size='small'
			options={items}
			value={selectedValues}
			defaultValue={selectedValues}
			getOptionLabel={getOptionLabel}
			onChange={handleChangeSingle}
			renderOption={(option) => renderOption(option)}
			renderInput={inputRenderer}
			classes={classStyle}
		/>
	)
}

MultiSelect.propTypes = {
	items: PropTypes.array,
	selectedValues: PropTypes.bool,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	selectAllLabel: PropTypes.string,
	noOptionsText: PropTypes.string,
	onToggleOption: PropTypes.func,
	onSelectAll: PropTypes.func,
	getOptionLabel: PropTypes.func,
	renderOption: PropTypes.func,
	isMultiple: PropTypes.bool,
	isFullWidth: PropTypes.bool,
	classStyle: PropTypes.object,
}

MultiSelect.defaultProps = {
	items: [],
	getOptionLabel: (value) => value,
}

export default MultiSelect
