import { PROVINCES, CITIES } from '../types'
import API from '../../utils/api'

const loading = (type) => {
	return {
		type: `${type}_PENDING`,
	}
}

const failure = (type) => {
	return {
		type: `${type}_REJECTED`,
	}
}

const success = (type, data) => {
	return {
		type: `${type}_FULFILLED`,
		payload: {
			...data,
		},
	}
}

export const requestProvinces = () => {
	return async (dispatch) => {
		dispatch(loading(PROVINCES))
		try {
			const response = await API({
				method: 'GET',
				url: '/state/?page_size=1000&country_id=100',
			})
			dispatch(success(PROVINCES, response.data))
		} catch (err) {
			dispatch(failure(PROVINCES))
		}
	}
}

export const requestCities = () => {
	return async (dispatch) => {
		dispatch(loading(CITIES))
		try {
			const response = await API({
				method: 'GET',
				url: '/regions/location/cities',
			})
			dispatch(success(CITIES, response.data))
		} catch (err) {
			dispatch(failure(CITIES))
		}
	}
}
