export const status = (state) => {
	switch (state) {
		case 'done':
			return 'Sedang Dalam Pengiriman'
		case 'recived':
			return 'Recived'
		case 'confirmed':
			return 'confirmed'
		case 'draft':
			return 'draft'
		case 'cancel':
			return 'Cancelled'
		case 'Confirmed':
			return 'Waiting'
		case 'assigned':
			return 'Ready'
		case 'posted':
			return 'Aktif'
		case 'not_paid':
			return 'Belum Dibayar'
		case 'paid':
			return 'Sudah Dibayar'
		default:
			return '-'
	}
}

export const type = ['image/jpeg', 'image/jpg', 'image/png']
