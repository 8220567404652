import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dashboard,
	},
	containerRoot: {
		padding: theme.spacing(3, 12),
	},
	datepicker: {
		width: 227,
	},
	tableRoot: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},
	totalPrice: {
		padding: theme.spacing(1, 3),
		justifyContent: 'space-between',
		display: 'flex',
		flex: '1',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	textareaRoot: {
		width: 380,
	},
	// addressValue: {
	// 	width: 290,
	// },
	addressValue: {
		fontSize: 14,
	},
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalFade: {
		width: 850,
		position: 'relative',
	},
	modalCloseIcon: {
		alignSelf: 'end',
		position: 'absolute',
		right: '1rem',
		'&&:hover': {
			cursor: 'pointer',
		},
	},
	containerField: {
		alignItems: 'center',
		justifyContent: 'space-around',
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing(2),
	},
	outlinedInputRoot: {
		overflow: 'hidden',
		paddingRight: 0,
		marginTop: 4,
	},
	outlinedInputadornedEnd: {
		backgroundColor: theme.palette.secondary,
	},
	selectIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		padding: '6px 6.7px',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	selectInput: {
		padding: '11px 0 11px 18px !important',
	},
	inputRoot: {
		padding: '0px 0px 0px 6px !important',
	},
	endAdornment: {
		top: 'inherit',
		right: '3px !important',
	},
	autocompleteRoot: {
		width: 250,
	},
	buttonDanger: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
	buttonWidth: {
		width: 120,
	},
	containerButton: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	kembali: {
		marginRight: theme.spacing(2),
		backgroundColor: theme.palette.background.default,
	},
	tableContainer: {
		maxHeight: 400,
		marginBottom: theme.spacing(1),
	},
	labelInput: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	},
	tooltipContainer: {
		backgroundColor: theme.palette.common.white,
		boxShadow: theme.shadows[2],
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 200,
	},
	typoPage: {
		paddingTop: theme.spacing(0.5),
		marginRight: theme.spacing(2),
	},
	btnEdit: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	inputNoRef: {
		'&::placeholder': {
			fontSize: '14px',
		},
	},
	modalSubtitle: {
		fontWeight: 500,
	},
}))

export default styles
