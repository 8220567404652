import { CONFIRM_SHIPPING } from '../../../types'
import API from '../../../../utils/api'
import moment from 'moment'

export const confirmShipping = (id, data, sale_order_id) => {
	return {
		type: CONFIRM_SHIPPING,
		payload: API({
			url: `/transactions/shipping/update/${id}`,
			method: 'PUT',
			data: {
				state: 'received',
				receiver_name: data.name,
				received_date: moment(data.date).format('YYYY-MM-DD'),
				sale_order_id: sale_order_id,
			},
		}),
	}
}
