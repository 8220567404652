import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	timelineDot: {
		padding: 5,
		margin: 0,
		marginTop: 6,
		boxShadow: 'none',
	},
	timelineDotDefault: {
		backgroundColor: '#d0d0d0',
		border: '3px solid #e8e8e8',
	},
	timelineDotActive: {
		backgroundColor: theme.palette.secondary.main,
		border: '3px solid #bbdcff',
	},
	timelineConnector: {
		width: 1,
		backgroundColor: '#d0d0d0',
		marginBottom: -7,
	},
	timelineText: {
		fontSize: 12,
	},
	timelineTextDefault: {
		fontWeight: 600,
	},
	timelineTextActive: {
		color: theme.palette.secondary.main,
		fontWeight: 'bold',
	},
}))

export default styles
