import { REPORT_FILE } from '../../../types'
import { setGlobalError } from '../../globalAction'
import API from '../../../../utils/api'
import { success, loading, failure } from '../../../commonActions'
import {
	ERROR_REPORT_LIMIT,
	ERROR_REPORT_NO_DATA,
	ERROR_REPORT_TIMEOUT,
	ERROR_REPORT_TITLE,
} from '../../../../constants/text'

export const resetAction = () => {
	return {
		type: `${REPORT_FILE}_RESET`,
	}
}

export const downloadPDFAction = (uri) => {
	return async (dispatch) => {
		dispatch(loading(REPORT_FILE))
		try {
			let response = await API({
				method: 'GET',
				url: uri,
			})
			if (response.data.error_backend) {
				let error
				error = response.data.error_backend
				dispatch(failure(REPORT_FILE, error))
			} else {
				dispatch(success(REPORT_FILE, response.data))
			}
		} catch (err) {
			dispatch(failure(REPORT_FILE, err))
		}
	}
}

export const getReportFile = (typeFile, date, urlData) => {
	let url = urlData
		? urlData + '?'
		: '/report-management/transaction/' + typeFile + '?'
	let uri = date
		? url + '&start_date=' + date.start_date + '&end_date=' + date.end_date
		: url
	return async (dispatch) => {
		dispatch(loading(REPORT_FILE))
		let payload = {
			title: ERROR_REPORT_TITLE,
			isError: true,
		}
		try {
			let response = await API({
				method: 'GET',
				url: uri,
			})
			if (response.data.error_backend) {
				let error
				error = response.data.error_backend
				dispatch(failure(REPORT_FILE, error))
			} else {
				dispatch(success(REPORT_FILE, response.data))
			}

			// hanlde if data 0
			if (!response.data.data.pagination.count) {
				payload['message'] = ERROR_REPORT_NO_DATA
				dispatch(resetAction())
				dispatch(setGlobalError(payload))
			}

			if (response.data.data.pagination.count > 1000) {
				payload['message'] = ERROR_REPORT_LIMIT
				dispatch(resetAction())
				dispatch(setGlobalError(payload))
			}
		} catch (err) {
			if (err.message?.includes('timeout')) {
				payload['message'] = ERROR_REPORT_TIMEOUT
				dispatch(setGlobalError(payload))
			}
			dispatch(failure(REPORT_FILE, err))
		}
	}
}
