import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
	list: {},
	listItem: {
		paddingTop: 0,
		paddingBottom: 0,
		paddingRight: 0,
		cursor: 'pointer',
		'&--1': {
			paddingLeft: theme.spacing(3),
		},
		'&--2': {
			paddingLeft: theme.spacing(4),
		},
	},
	listIcon: {
		cursor: 'pointer',
		justifyContent: 'flex-end',
		minWidth: 'auto',
	},
}))

export default styles
