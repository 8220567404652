/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from 'Components/dashboardLayout'
import PropTypes from 'prop-types'
import {
	Box,
	Typography,
	Grid,
	Paper,
	TextField,
	IconButton,
	Button,
	Container,
} from '@material-ui/core'
import { SearchRounded, Edit, Delete, People } from '@material-ui/icons'
import AutoComplete from '@material-ui/lab/Autocomplete'
import { navigate } from 'gatsby'
import Breadcrumbs from 'Components/breadcrumbs'
import { ApprovalStyles } from './styles'
import TableData from 'Components/tableData'
import { requestADList } from 'States/actions/Approval/Department/ADListAction'
import { requestADDelete } from 'States/actions/Approval/Department/ADDeleteAction'
import { requestDepartmentList } from 'States/actions/Company/Department/listAction'
import { requestDetailCompany } from 'States/actions/CompanyProfile/profileAction'
import * as qs from 'query-string'
import { newUri } from '../../../../utils/globalUtils'
import CustomModal from 'Components/modal'

// const Departemen = [{ title: 'Tech' }]

const ApprovalDepartment = (props) => {
	const classes = ApprovalStyles()

	const [breadcrums] = useState([
		{
			segment: 'Home',
			link: '/dashboard/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Manajemen Persetujuan',
			link: '#',
		},
		{
			segment: 'Penyetuju Departemen',
			link: '#',
		},
	])

	const dispatch = useDispatch()
	const list = useSelector((state) => state.ADListReducer)
	const dataDelete = useSelector((state) => state.ADDeleteReducer)
	const listDepartmentReducer = useSelector(
		(state) => state.departmentListReducer
	)
	const companyDetailReducer = useSelector((state) => state.companyProfile)
	const queryString = qs.parse(props.location.search)

	const [limit, setLimit] = useState(queryString.limit ? queryString.limit : 10)
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	// const [totalRows, setTotalRows] = useState(0)
	const [keyword, setKeyword] = useState(
		queryString.keyword ? queryString.keyword : ''
	)
	const state = ''
	const [selectedDataDelete, setSelectedDataDelete] = useState(0)
	const [confirmModalSubmit, setConfirmModalSubmit] = useState(false)
	const [modalNotif, setModalNotif] = useState(false)
	const [listDepartment, setListDepartment] = useState([])
	const [departmentID, setDepartmentID] = useState(
		queryString.department_id ? queryString.department_id : ''
	)
	const [statusApproval, setStatusApproval] = useState(0)

	// THIS FOR DATATABLE
	const [dataRow, setDataRow] = useState([])
	queryString.limit = limit
	queryString.page = page
	queryString.keyword = keyword
	queryString.state = state
	queryString.department_id = departmentID

	useEffect(() => {
		dispatch(requestADList({ queryString }))
		dispatch(requestDetailCompany())

		let queryDepartment = { limit: 200, filter: '{"status":"99"}' }
		dispatch(requestDepartmentList({ queryString: queryDepartment }))
	}, [])

	// hooks for set default state
	useEffect(() => {
		// success
		if (list.data && list.data.items) {
			let result = list.data.items
			if (result) {
				setDataRow(result)
				// setTotalRows(list.data.pagination.total_records)
			}
		} else {
			setDataRow([])
			// setTotalRows(0)
		}
	}, [list])

	useEffect(() => {
		if (dataDelete.data != null && typeof dataDelete.data == 'string') {
			setModalNotif(true)
		}
	}, [dataDelete])

	useEffect(() => {
		if (listDepartmentReducer.data && listDepartmentReducer.data.items) {
			let result = listDepartmentReducer.data.items
			setListDepartment(result)
		} else {
			setListDepartment([])
		}
	}, [listDepartmentReducer])

	// reset
	useEffect(() => {
		dispatch(requestADDelete('', 'RESET'))
	}, [])

	// company cek status
	useEffect(() => {
		if (companyDetailReducer.company && companyDetailReducer.company.data) {
			setStatusApproval(companyDetailReducer.company.data.is_pr_active)
		}
	}, [companyDetailReducer])

	const columns = [
		{
			name: 'Nama Lengkap',
			selector: 'name',
			sortable: true,
			hide: 'md',
			width: '150px',
		},
		{
			name: 'Username',
			selector: 'username',
			sortable: true,
			width: '150px',
		},
		{
			name: 'Email',
			selector: 'email',
			sortable: true,
			width: '180px',
		},
		{
			name: 'Departemen',
			selector: 'department_name',
			sortable: true,
			width: '150px',
		},
		{
			name: 'Level',
			selector: 'level',
			sortable: true,
			width: '125px',
		},
		{
			name: 'Dari',
			selector: 'min_transaction',
			sortable: true,
			width: '125px',
		},
		{
			name: 'Hingga',
			selector: 'max_transaction',
			sortable: true,
			width: '125px',
		},
		{
			name: 'AKSI',
			selector: 'aksi',
			sortable: true,
			hide: 'md',
			width: '140px',
			cell: (row) => (
				<Box>
					<Button
						variant='contained'
						size='small'
						className={`${classes.buttonAction} ${classes.btnEdit}`}
						onClick={(event) => {
							event.preventDefault()
							navigate('/dashboard/approval/department/edit/' + row.id, {
								replace: true,
							})
						}}
					>
						<Edit fontSize='small' />
					</Button>
					<Button
						variant='contained'
						size='small'
						disabled={dataDelete.isLoading && selectedDataDelete.id == row.id}
						className={`${classes.buttonAction} ${classes.btnDelete}`}
						onClick={() => {
							setSelectedDataDelete(row)
							setConfirmModalSubmit(true)
						}}
					>
						<Delete fontSize='small' />
					</Button>
				</Box>
			),
		},
	]

	const limitValue = [10, 25, 50, 100]

	const propsNavigate = (query) => {
		props.navigate(`/dashboard/approval/department?${query}`)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		submitSearch()
	}

	const handlePerRowsChange = (event) => {
		setLimit(event.target.value)
		queryString.limit = event.target.value
		setPage(1)
		queryString.page = 1
		submitSearch()
	}

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false

			return
		}

		const timeOutId = setTimeout(() => submitSearch(), 1000)

		return () => clearTimeout(timeOutId)
	}, [keyword])

	const handleChangeSearch = (e) => {
		let value = e.target.value
		setKeyword(value)
		setPage(1)
		queryString.page = 1
		queryString.keyword = value
	}

	function submitSearch(dID = null, k = null, s = null) {
		queryString.keyword = k != null ? k : keyword
		queryString.state = s != null ? s : state
		queryString.department_id = dID != null ? dID : departmentID
		let query = newUri(queryString)

		dispatch(requestADList({ queryString }))
		propsNavigate(query)
	}

	const handleEnterSearch = (e) => {
		if (e.key === 'Enter') {
			setPage(1)
			queryString.page = 1
			submitSearch()
		}
	}

	const handleDelete = () => {
		dispatch(requestADDelete(selectedDataDelete.id))
		setConfirmModalSubmit(false)
	}

	const handleRefreshData = () => {
		setModalNotif(false)
		dispatch(requestADList({ queryString }))
	}

	const handleOnInputChangeDeparment = (e, v, r) => {
		// console.log('handleOnInputChange', [e, v, r])
		if (v == '' || r == 'clear') {
			setDepartmentID('')
			submitSearch('')
		} else if (r == 'input') {
			if (v.length > 2) {
				let queryString = {
					limit: 100,
					status: 99,
					query: JSON.stringify({ name: v }),
					filter: '{"status":"99"}',
				}
				dispatch(requestDepartmentList({ queryString: queryString }))
			}
			submitSearch('')
		} else {
			if (listDepartment.length > 0) {
				let departmentID = listDepartment.filter(function (item) {
					return item.name == v
				})[0].id
				// console.log('departmentID', departmentID)
				if (departmentID) {
					setDepartmentID(departmentID)
					submitSearch(departmentID)
				}
			}
		}
		setPage(1)
		queryString.page = 1
	}

	const findData = (data, id) => {
		let res = data.length != 0 && data.find((element) => element.id == id)
		return res ? res : ''
	}

	return (
		<>
			<DashboardLayout>
				<Container style={{ overflow: 'hidden' }}>
					<Box>
						<Breadcrumbs breadcrumbs={breadcrums} />
					</Box>
					<Box my={2}>
						<Typography variant='h6'>
							Manajemen Persetujuan - Penyetuju Departemen
						</Typography>
					</Box>
					<Box mt={1}>
						<Paper variant='outlined'>
							<Box p={1}>
								<Grid container alignItems='center'>
									<Grid item lg={1}>
										<Grid container alignItems='center'>
											<People style={{ fontSize: 70 }} />
										</Grid>
									</Grid>
									<Grid item lg={6}>
										<Typography variant='body1'>
											Manajement persetujuan Anda berstatus{' '}
											<span style={{ color: statusApproval == 1 ? 'green' : 'red' }}>
												{statusApproval == 1 ? 'Aktif' : 'Tidak Aktif'}
											</span>
										</Typography>
										<Typography variant='body1'>
											Klik{' '}
											<a
												href='#'
												onClick={(event) => {
													event.preventDefault()
													navigate('/dashboard/approval/configure', { replace: true })
												}}
											>
												di sini
											</a>{' '}
											untuk pengaturan manajemen persetujuan
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Box>
					<Box mt={3}>
						<Grid container justify='flex-end'>
							<Button
								variant='contained'
								color='secondary'
								size='small'
								onClick={() => {
									// event.preventDefault()
									navigate('/dashboard/approval/department/add')
								}}
							>
								Tambah Penyetuju Departemen
							</Button>
						</Grid>
					</Box>
					<Box mt={2}>
						<Grid container justify='space-between'>
							<Grid container item xs={10} justify='flex-start'>
								<Grid container item xs={6} alignItems='center'>
									<AutoComplete
										id='department'
										name='department'
										options={listDepartment}
										getOptionLabel={(option) => option.name}
										value={findData(listDepartment, queryString.department_id)}
										onInputChange={(e, v, r) => {
											handleOnInputChangeDeparment(e, v, r)
										}}
										onClose={(e, r) => {
											if (r != 'select-option') {
												setDepartmentID('')
											}
										}}
										classes={{
											input: classes.selectInput,
											inputRoot: classes.inputRoot,
											endAdornment: classes.endAdornment,
											popupIndicator: classes.selectIcon,
										}}
										style={{ width: '90%' }}
										renderInput={(params) => (
											<TextField
												placeholder='Pilih Departemen'
												fullWidth
												margin='dense'
												onKeyDown={(e) => handleEnterSearch(e)}
												{...params}
											/>
										)}
									/>
								</Grid>
								<Grid container item xs={6} md={5} alignItems='center'>
									<TextField
										placeholder='Nama Lengkap / Email / Username'
										type='search'
										className={classes.searchField}
										InputProps={{
											disableUnderline: false,
										}}
										onChange={handleChangeSearch}
										onKeyDown={(e) => handleEnterSearch(e)}
										onBlur={(e) => handleChangeSearch(e)}
										defaultValue={keyword}
									/>
									<IconButton
										variant='contained'
										color='primary'
										className={classes.buttonSearch}
										onClick={() => submitSearch()}
									>
										<SearchRounded fontSize='small' />
									</IconButton>
								</Grid>
							</Grid>

							{/* comment out export to excel button */}
							{/* <Grid container item xs={2} justify='flex-end'>
								<Button
									variant='outlined'
									className={classes.buttonExport}
									size='small'
								>
									Expor Ke Excel <InsertDriveFileOutlined fontSize='small' />
								</Button>
							</Grid> */}
						</Grid>
					</Box>
					<Box mt={2}>
						<TableData
							limitValue={limitValue}
							perPage={limit}
							limitChange={handlePerRowsChange}
							columns={columns}
							isLoading={list.isLoading}
							pagination={list.data ? list.data.pagination : {}}
							data={dataRow}
							name={'Approval Department'}
							paginationPageChange={handlePageChange}
							pageNow={parseInt(queryString.page)}
						/>
						<br />
					</Box>
				</Container>
			</DashboardLayout>
			<CustomModal
				icon='question'
				open={confirmModalSubmit}
				onClose={() => setConfirmModalSubmit(false)}
			>
				<Typography variant='h6'>
					<strong>Hapus Penyetuju {selectedDataDelete.name} ?</strong>
				</Typography>
				<Box mt={2}>
					<Button
						className={classes.buttonWidth}
						size='small'
						classes={{
							contained: classes.buttonDanger,
						}}
						color='secondary'
						variant='outlined'
						onClick={() => setConfirmModalSubmit(false)}
					>
						{' '}
						Batal{' '}
					</Button>
					&nbsp;&nbsp;&nbsp;
					<Button
						className={[classes.buttonWidth, classes.buttonRed]}
						size='small'
						variant='contained'
						onClick={() => handleDelete()}
					>
						{' '}
						Ya, Hapus{' '}
					</Button>
				</Box>
			</CustomModal>
			<CustomModal
				icon={dataDelete.data && !dataDelete.isError ? 'success' : 'error'}
				open={modalNotif}
				onClose={() => setModalNotif(false)}
			>
				<Typography variant='h6'>
					{dataDelete.data && !dataDelete.isError ? (
						<strong>Penyetuju Berhasil dihapus.</strong>
					) : (
						<strong>Penyetuju Gagal dihapus.</strong>
					)}
				</Typography>
				<Box
					display='flex'
					flexDirection='row'
					mt={3}
					justifyContent='center'
					width={300}
				>
					<Button
						className={classes.buttonWidth}
						size='small'
						classes={{
							contained: classes.buttonDanger,
						}}
						variant='contained'
						onClick={() => handleRefreshData()}
					>
						{' '}
						OK{' '}
					</Button>
				</Box>
			</CustomModal>
		</>
	)
}

ApprovalDepartment.propTypes = {
	location: PropTypes.object,
	navigate: PropTypes.func,
}

ApprovalDepartment.displayName = 'ApprovalDepartment'

export default ApprovalDepartment
