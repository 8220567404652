import React from 'react'
import styles from './styles'
import LayoutComponent from 'Components/layout'
import PropTypes from 'prop-types'
import Breadcrums from 'Components/breadcrumbs'
import { Button, Typography, Container, Box } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import rfqCreatedImg from '../../../../assets/images/background/rfq-created.png'

const Component = ({ navigate }) => {
	const classes = styles()
	const breadcrums = [
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Keranjang Belanja',
			link: '',
		},
		{
			segment: 'Form RFQ',
			link: '',
		},
		{
			segment: 'RFQ Berhasil Dibuat',
			link: '',
		},
	]
	return (
		<LayoutComponent className={classes.root}>
			<Container classes={{ root: classes.containerRoot }}>
				<Box>
					<Breadcrums breadcrumbs={breadcrums} />
				</Box>
				<Box
					display='flex'
					justifyContent='center'
					marginTop={3}
					marginBottom={5}
					alignItems='center'
					flexDirection='column'
				>
					<Box display='flex' width='50%' marginBottom={1}>
						<img src={rfqCreatedImg} alt='error' width='100%' height='100%' />
					</Box>
					<Box
						display='flex'
						justifyContent='center'
						alignItems='center'
						flexDirection='column'
						mt={3}
						mb={3}
					>
						<Typography variant='h6'>
							<strong>RFQ Anda telah terbuat</strong>
						</Typography>
						<Typography variant='body1'>
							Anda akan mendapatkan email notifikasi setalah kami mengkonfirmasi RFQ
							Anda
						</Typography>
					</Box>

					<Box className={classes.buttonActionWrapper}>
						<Button
							variant='contained'
							onClick={() => navigate('/dashboard')}
							color='secondary'
							size='small'
						>
							<ArrowBackIcon fontSize='small' /> Kembali ke halaman Beranda
						</Button>
						<Button
							id='btn-show-list-rfq'
							onClick={() => navigate('/dashboard/rfq')}
							variant='contained'
							color='secondary'
							size='small'
						>
							Lihat List RFQ <ArrowForwardIcon fontSize='small' />
						</Button>
					</Box>
				</Box>
			</Container>
		</LayoutComponent>
	)
}

Component.propTypes = {
	navigate: PropTypes.func,
}

export default Component
