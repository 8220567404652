/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Router } from '@reach/router'
import { logoutAction } from 'States/actions/authAction'
import { ResetGlobalError } from 'States/actions/globalAction'
import moment from 'moment'
import * as qs from 'query-string'
import axios from 'axios'
import { init as initApm } from "@elastic/apm-rum";
import { encryptAES } from 'Utilities/cryptojs'
import { loginAction } from '../state/actions/authAction'
import { isBrowser, getUrl } from '../utils/globalUtils'
import Loading from '../navigations/loading'
import Error from '../navigations/error'
import { BankNav } from '../navigations/bankNav'
import { GeneralNav } from '../navigations/generalNav'
import { TransactionNav } from '../navigations/transactionNav'
import { MouNav } from '../navigations/mouNav'
import { ReportNav } from '../navigations/reportNav'
import { ApprovalNav } from '../navigations/approvalNav'
import { AccountNav } from '../navigations/accountNav'
import { DepartmentNav } from '../navigations/departementNav'
import { CompanyProfileNav } from '../navigations/companyProfileNav'
import { AddressNav } from '../navigations/addressNav'
import { ProfileNav } from '../navigations/profileNav'
import { BudgetNav } from '../navigations/budgetNav'

const checkLogin = () => {
	const dispatch = useDispatch()
	const authData = useSelector((state) => state.auth)
	const expiTime = moment(authData.loginExpire).utc().format()
	const currTime = moment().utc().format()
	const isExpire = moment(expiTime).isBefore(currTime)

	if (isExpire) {
		dispatch(logoutAction())
	}
}

const queryString = isBrowser() ? qs.parse(window.location.search) : {}

const handleAvetti = async (dispatch, lt) => {
	const url = getUrl()
	try {
		const response = await axios({
			method: 'GET',
			url: `${url}?token=${lt}`,
		})
		if (response.data.valid) {
			localStorage.setItem('avetti', encryptAES(JSON.stringify(response.data)))
			localStorage.setItem('avetti-token', encryptAES(lt))
			const data = {
				user: response.data.login,
				pass: response.data.loginPassword,
				redirect: '/catalog/search',
			}
			dispatch(loginAction({ ...data }))
		}
	} catch (err) {
		console.log(err)
	}
}

const IndexPage = () => {
	const dispatch = useDispatch()
	// check login session, logout if expired
	checkLogin()
	const globalData = useSelector((state) => state.globalReducer)
	useEffect(() => {
		if ('lt' in queryString) {
			handleAvetti(dispatch, queryString?.lt)
		}
	}, [])

	const dev = process.env.GATSBY_NODE_ENV !== "production";
  if (!dev) {
    const appName = process.env.GATSBY_APP_NAME || "godham";
    initApm({
      serviceName: `${appName}`,
			serverUrl: process.env.GATSBY_APM_URL,
			serviceVersion: process.env.GATSBY_APP_VERSION,
			environment: process.env.GATSBY_APP_ENV,
			breakdownMetrics: true,
			propagateTracestate: true,
    })
  }

	return (
		<>
			<Loading visible={globalData.loading} />
			<Error
				visible={globalData.isError}
				errorTitle={globalData.errorData.title}
				errorContent={globalData.errorData.message}
				onDismiss={() => dispatch(ResetGlobalError())}
			/>
			<Router>
				{/* Transaction Navigation */}
				{TransactionNav()}

				{/* Account */}
				{AccountNav()}

				{/* Company Profile */}
				{CompanyProfileNav()}

				{/* Department */}
				{DepartmentNav()}

				{/* Address */}
				{AddressNav()}

				{/* Bank */}
				{BankNav()}

				{/* Approval */}
				{ApprovalNav()}

				{/* Profile */}
				{ProfileNav()}

				{/* MOU */}
				{MouNav()}

				{/* Report / Pusat Unduh Data */}
				{ReportNav()}

				{/* general navigation */}
				{GeneralNav()}

				{/* Budget/Anggaran navigation */}
				{BudgetNav()}
			</Router>
		</>
	)
}

export default IndexPage
