import React, { useState, useEffect } from 'react'
import DashboardLayout from 'Components/dashboardLayout'
import {
	Box,
	Typography,
	Grid,
	Paper,
	TextField,
	Button,
	Container,
	CircularProgress,
} from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Breadcrumbs from 'Components/breadcrumbs'
import { Styles } from './styles'
import schema from './schema'
import { navigate } from 'gatsby'
import Modal from 'Components/modal'
import { useSelector, useDispatch } from 'react-redux'
import { requestBankMaster } from 'States/actions/Bank/listAction'
import { createBank } from 'States/actions/Bank/createAction'
import {
	detailBank,
	updateBank,
	resetAction,
} from 'States/actions/Bank/updateAction'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'

const BankAdd = ({ BankID }) => {
	const classes = Styles()
	const dispatch = useDispatch()
	const mode = BankID == 0 ? 'new' : 'edit'
	const [showLoading, setLoading] = useState(false)
	const [field, setField] = useState(null)
	const [error, setError] = useState(false)
	const newReducer = useSelector((state) => state.bankNew)
	const editReducer = useSelector((state) => state.bankUpdate)
	const listReducer = useSelector((state) => state.bankList)
	const [masterBank, setMasterBank] = useState([])

	useEffect(() => {
		if (listReducer.isLoading == false && listReducer.master != null) {
			setMasterBank(listReducer.master.data?.result || [])
		}
	}, [listReducer.isLoading, listReducer.master])

	const breadcrumbsTambah = [
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Akun Bank',
			link: '/dashboard/bank-account',
		},
		{
			segment: 'Tambah Akun Bank',
			link: '#',
		},
	]

	const breadcrubmbsUbah = [
		{
			segment: 'Home',
			link: '/',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Akun Bank',
			link: '/dashboard/bank-account',
		},
		{
			segment: 'Ubah Akun Bank',
			link: '#',
		},
	]

	const handleSubmitForm = (values) => {
		let data = {}
		data.bank_id = values.nama_bank.id
		data.branch = values.cabang_bank
		data.acc_number = values.akun_bank.toString()
		data.acc_holder_name = values.nama_akun
		// console.log('sending data', data)
		if (mode == 'edit') {
			data.id = BankID
			dispatch(updateBank(data))
		} else {
			dispatch(createBank(data))
		}
	}

	useEffect(() => {
		if (!newReducer.isError && !editReducer.isError) {
			if (mode == 'edit') {
				setLoading(editReducer.isLoading || editReducer.isLoadingData)
				if (editReducer.data) {
					setError(editReducer.isError)
				}
			} else {
				setError(newReducer.isError)
				setLoading(newReducer.isLoading)
			}
		} else {
			if (mode == 'edit') {
				setError(editReducer.isError)
				setLoading(editReducer.isLoading || editReducer.isLoadingData)
			} else {
				setError(newReducer.isError)
				setLoading(newReducer.isLoading)
			}
		}
	}, [newReducer, editReducer])

	useEffect(() => {
		if (mode == 'edit' && editReducer.data) {
			let data = {
				bank_id: editReducer.data.bank_id || '',
				branch: editReducer.data.branch || '',
				acc_number: editReducer.data.acc_number || '',
				acc_holder_name: editReducer.data.acc_holder_name || '',
			}
			setField(data)
		}
	}, [editReducer.data])

	useEffect(() => {
		setLoading(false)
		if (mode == 'edit') {
			dispatch(detailBank(BankID))
		}
		dispatch(requestBankMaster())
		return () => {
			dispatch(resetAction())
		}
	}, [])

	return (
		<DashboardLayout>
			<Container>
				<Modal open={showLoading} icon='none' disableBackdropClick>
					<Box my={3} alignContent='center'>
						<CircularProgress color='secondary' style={{ marginBottom: '24px' }} />
						{(editReducer.isLoading || newReducer.isLoading) && (
							<Typography>Menyimpan Akun Bank, Mohon Tunggu</Typography>
						)}
						{editReducer.isLoadingData && (
							<Typography>Mengambil Data Akun Bank, Mohon Tunggu</Typography>
						)}
					</Box>
				</Modal>
				<Box>
					{mode == 'new' ? (
						<Breadcrumbs breadcrumbs={breadcrumbsTambah} />
					) : (
						<Breadcrumbs breadcrumbs={breadcrubmbsUbah} />
					)}
				</Box>
				<Box mt={2}>
					<Typography variant='h6'>
						{mode == 'new' ? 'Tambah' : 'Ubah'} Akun Bank
					</Typography>
				</Box>
				<Box mt={2}>
					<Formik
						enableReinitialize
						initialValues={{
							nama_bank: field
								? {
										id: field.bank_id.id,
										name: field.bank_id.name,
								  }
								: null,
							cabang_bank: field ? field.branch : '',
							akun_bank: field ? field.acc_number : null,
							nama_akun: field ? field.acc_holder_name : '',
						}}
						validationSchema={schema}
						onSubmit={(values) => handleSubmitForm(values)}
					>
						{({ errors, touched, values, setValues }) => (
							<Form method='post'>
								<Paper>
									<Box py={1} px={2} spacing={4}>
										<Grid container>
											<Grid container item alignItems='center' xs={3}>
												<Typography variant='subtitle1' className={classes.labelRequired}>
													Nama Bank
												</Typography>
											</Grid>
											<Grid container item alignItems='center' xs={6}>
												<Field
													as={Autocomplete}
													id='nama_bank'
													name='nama_bank'
													value={values.nama_bank}
													options={masterBank}
													getOptionLabel={(option) => option.name}
													getOptionSelected={(opt, value) => opt.name == value.name}
													onChange={(_opt, val) =>
														setValues({
															...values,
															nama_bank: val ? val : null,
														})
													}
													style={{ width: '100%' }}
													classes={{
														input: classes.selectInput,
														inputRoot:
															errors.nama_bank && touched.nama_bank
																? classes.inputRootError
																: classes.inputRoot,
														endAdornment: classes.endAdornment,
														popupIndicator: classes.selectIcon,
													}}
													renderInput={(params) => {
														return <TextField {...params} placeholder='Pilih Nama Bank' />
													}}
												/>
												<ErrorMessage name='nama_bank'>
													{(msg) => (
														<Typography variant='caption' color='error'>
															{msg}
														</Typography>
													)}
												</ErrorMessage>
											</Grid>
										</Grid>

										<Grid container>
											<Grid container item alignItems='center' xs={3}>
												<Typography variant='subtitle1' className={classes.labelRequired}>
													Nama Cabang
												</Typography>
											</Grid>
											<Grid container item alignItems='center' xs={6}>
												<Field
													as={TextField}
													variant='outlined'
													color='primary'
													placeholder=''
													margin='dense'
													fullWidth
													error={errors.cabang_bank && touched.cabang_bank}
													name='cabang_bank'
												/>
												<ErrorMessage name='cabang_bank'>
													{(msg) => (
														<Typography variant='caption' color='error'>
															{msg}
														</Typography>
													)}
												</ErrorMessage>
											</Grid>
										</Grid>

										<Grid container>
											<Grid container item alignItems='center' xs={3}>
												<Typography variant='subtitle1' className={classes.labelRequired}>
													Nomor Akun Bank
												</Typography>
											</Grid>
											<Grid container item alignItems='center' xs={6}>
												<Field
													as={TextField}
													variant='outlined'
													color='primary'
													placeholder=''
													margin='dense'
													fullWidth
													error={errors.akun_bank && touched.akun_bank}
													name='akun_bank'
												/>
												<ErrorMessage name='akun_bank'>
													{(msg) => (
														<Typography variant='caption' color='error'>
															{msg}
														</Typography>
													)}
												</ErrorMessage>
												{error && (
													<Box>
														<Typography color='error' variant='caption'>
															Nomor Akun Bank sudah terdaftar
														</Typography>
													</Box>
												)}
											</Grid>
										</Grid>

										<Grid container>
											<Grid container item alignItems='center' xs={3}>
												<Typography variant='subtitle1' className={classes.labelRequired}>
													Nama Pemegang Akun
												</Typography>
											</Grid>
											<Grid container item alignItems='center' xs={6}>
												<Field
													as={TextField}
													variant='outlined'
													color='primary'
													placeholder=''
													margin='dense'
													fullWidth
													error={errors.nama_akun && touched.nama_akun}
													value={values.nama_akun}
													name='nama_akun'
												/>
												<ErrorMessage name='nama_akun'>
													{(msg) => (
														<Typography variant='caption' color='error'>
															{msg}
														</Typography>
													)}
												</ErrorMessage>
											</Grid>
										</Grid>
									</Box>
								</Paper>
								<Box mt={2}>
									<Grid container justify='flex-end'>
										<Grid item>
											<Box>
												<Button
													size='medium'
													className={`${classes.buttonAction} ${classes.btnCancel}`}
													onClick={(event) => {
														event.preventDefault()
														navigate('/dashboard/bank-account', {
															replace: true,
														})
													}}
												>
													Batal
												</Button>
												<Button
													size='medium'
													type='submit'
													className={`${classes.buttonAction} ${classes.btnSave}`}
												>
													Simpan
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

BankAdd.propTypes = {
	BankID: PropTypes.number,
}

BankAdd.defaultProps = {
	BankID: 0,
}

export default BankAdd
