import { makeStyles } from '@material-ui/core/styles'

export const detailStyles = makeStyles((theme) => ({
	'@global': {
		'.price-text': {
			color: theme.palette.alert.dark,
		},
	},
	sectionControl: {
		borderBottom: '1px solid #eee',
	},
	viewMore: {
		backgroundColor: 'white',
		height: 30,
		color: theme.palette.secondary.main,
		'& span': {
			cursor: 'pointer',
		},
	},
	viewMoreCollapsed: {
		transform: 'translateY(0px)',
		boxShadow: '0px 0px 0px #ffffffba',
		color: theme.palette.secondary.main,
		'& p': {
			cursor: 'pointer',
		},
	},
	containerDesc: {
		overflow: 'hidden',
		transition: theme.transitions.create(['max-height'], {
			duration: theme.transitions.duration.complex,
		}),
	},
	backdrop: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		left: 0,
		height: 100,
		background:
			'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(255, 255, 255) 100%)',
	},
	salePrice: {
		paddingTop: 8,
		paddingBottom: 8,
		display: 'flex',
		justifyContent: 'space-around',
		scrollSnapAlign: 'center',
		flexDirection: 'column',
		'&:nth-child(odd)': {
			backgroundColor: theme.palette.grey[200],
		},
		'&:nth-child(even)': {
			backgroundColor: theme.palette.grey[100],
		},
	},
	saleTitle: {
		backgroundColor: theme.palette.grey[100],
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		minWidth: '166.15px',
	},
	containerSalePrice: {
		overflowX: 'overlay',
		overflowY: 'hidden',
		maxWidth: 660,
		flex: 4,
		display: 'grid',
		gridAutoColumns: 120,
		gridAutoFlow: 'column',
		textAlign: 'center',
		scrollbarWidth: 'thin',
		scrollSnapType: 'x proximity',
		position: 'relative',
		scrollBehavior: 'smooth',
		scrollbarColor: '#1890ff #eee',
		'&::-webkit-scrollbar': {
			height: 5,
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.secondary.main,
		},
	},
	colorVariant: {
		display: 'grid',
		gridGap: 10,
		flex: 1,
		gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 85px))',
	},
	colorItem: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(0.5),
		cursor: 'pointer',
		alignItems: 'center',
		backgroundColor: '#EEE',
	},
	variantItem: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(0.5),
		cursor: 'pointer',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#EEE',
	},
	active: {
		backgroundColor: theme.palette.info.light,
		color: theme.palette.info.main,
	},
	model: {
		justifyContent: 'center',
	},
	buttonGroupPreppend: {
		width: 30,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		minWidth: 0,
	},
	buttonGroupAppend: {
		width: 30,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		minWidth: 0,
	},
	buttonGroupInput: {
		width: 60,
		textAlign: 'center',
		WebkitAppearance: 'textfield',
		MozAppearance: 'textfield',
		appearance: 'textfield',
		border: 'solid 1px #ccc',
		borderStyle: 'solid none',
	},
	paddingTop: {
		paddingTop: theme.spacing(3),
	},
	divWidth: {
		width: '100%',
	},
	paddingTop2: {
		paddingTop: theme.spacing(2),
	},
	paddingBottom2: {
		paddingBottom: theme.spacing(1.5),
	},
	marginTop: {
		marginTop: theme.spacing(3),
	},
}))
