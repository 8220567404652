import { PURCHASE_REQUEST_CREATE } from '../../../types'
import { success, failure, loading } from '../../../commonActions'
import API from '../../../../utils/api'

export const resetAction = () => {
	return {
		type: `${PURCHASE_REQUEST_CREATE}_RESET`,
	}
}

export const createPr = (saleOrderId, data) => {
	return async (dispatch) => {
		dispatch(loading(PURCHASE_REQUEST_CREATE))
		try {
			const response = await API({
				method: 'POST',
				url: '/purchase-requests/create',
				data: {
					sale_order_id: Number(saleOrderId),
					notes: data.notes,
					budget_data: data.budget_data,
				},
			})
			if (response.data) {
				if ('error_backend' in response.data) {
					let error
					error = response.data.error_backend
					dispatch(failure(PURCHASE_REQUEST_CREATE, error))
				} else {
					dispatch(success(PURCHASE_REQUEST_CREATE, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(PURCHASE_REQUEST_CREATE, err))
		}
	}
}
