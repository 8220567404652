import { DETAIL_PURCHASE_ORDER_BUDGET } from '../../../types'
import API from '../../../../utils/api'
import { success, failure, loading } from '../../../commonActions'

export const resetAction = () => {
	return {
		type: `${DETAIL_PURCHASE_ORDER_BUDGET}_RESET`,
	}
}

export const requestDetailPOBudget = (soId) => {
	return async (dispatch) => {
		dispatch(loading(DETAIL_PURCHASE_ORDER_BUDGET))
		try {
			const response = await API({
				url: `/budget-transactions/sale-orders/${soId}`,
			})
			if (response.data) {
				if ('error_backend' in response.data) {
					dispatch(failure(DETAIL_PURCHASE_ORDER_BUDGET))
				} else {
					dispatch(success(DETAIL_PURCHASE_ORDER_BUDGET, response.data))
				}
			}
		} catch (err) {
			dispatch(failure(DETAIL_PURCHASE_ORDER_BUDGET))
		}
	}
}
