import {
	COMPANY_PROFILE,
	UPDATE_COMPANY_PROFILE,
	COMPANY_CONTACTS,
} from '../../types'
import { success, failure, loading } from '../../commonActions'
import { navigate } from 'gatsby'
import API from '../../../utils/api'

export const resetAction = () => {
	return {
		type: `${COMPANY_PROFILE}_RESET`,
	}
}

export const resetContactAction = () => {
	return {
		type: `${COMPANY_CONTACTS}_RESET`,
	}
}

export const requestDetailCompany = () => {
	return async (dispatch, getState) => {
		const companyId = getState().auth.data.data.company_buyer_id
		dispatch(loading(COMPANY_PROFILE))
		try {
			const response = await API({
				method: 'GET',
				url: `/companies/details/${companyId}`,
			})
			dispatch(success(COMPANY_PROFILE, response.data))
		} catch (err) {
			dispatch(failure(COMPANY_PROFILE))
		}
	}
}

export const updateCompanyProfile = (values) => {
	return async (dispatch, getState) => {
		const companyId = getState().auth.data.data.company_buyer_id
		const resPartnerMedia =
			getState().companyProfile.company.data.company_details.res_partner_media_ids
		values.child_ids.map((obj) => {
			obj.function = obj.jabatan
			Object.keys(obj).forEach(() => delete obj.jabatan)
		})

		dispatch(loading(UPDATE_COMPANY_PROFILE))
		try {
			const response = await API({
				method: 'PUT',
				url: `/companies/update/${companyId}`,
				data: {
					child_ids: values.child_ids,
					company_background: values.latarBelakang,
					name: values.namaPembeli,
					phone: values.noTelp,
					mobile: values.noPonsel,
					zip: values.kodePos,
					street: values.alamat,
					region_id: values.location.id,
					res_partner_media_ids: resPartnerMedia,
					storage_id: values.storage_id,
				},
			})

			if (response) navigate('/dashboard/company-profile')
		} catch (err) {
			dispatch(failure(UPDATE_COMPANY_PROFILE))
		}
	}
}

export const getContactList = () => {
	return async (dispatch) => {
		dispatch(loading(COMPANY_CONTACTS))
		try {
			const response = await API({
				method: 'GET',
				url: '/companies/contacts',
			})
			dispatch(success(COMPANY_CONTACTS, response.data))
		} catch (err) {
			dispatch(failure(COMPANY_CONTACTS, err))
		}
	}
}
