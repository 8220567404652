import React from 'react'
import { navigate } from '@reach/router'
import {
	Button,
	Box,
	Container,
	Divider,
	Grid,
	Paper,
	Typography,
} from '@material-ui/core'
import Breadcrumbs from 'Components/breadcrumbs'
import DashboardLayout from 'Components/dashboardLayout'

const AboutBudget = () => {
	const breadcrumbs = [
		{
			segment: 'Home',
			link: '/dashboard',
		},
		{
			segment: 'Perusahaan',
			link: '#',
		},
		{
			segment: 'Pengaturan Pembelian',
			link: '#',
		},
		{
			segment: 'Anggaran',
			link: '/dashboard/purchase-setting/budget',
		},
		{
			segment: 'Tentang Anggaran',
			link: '/dashboard/purchase-setting/budget/about',
		},
	]

	return (
		<DashboardLayout>
			<Container>
				<Box>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Box>

				{/* Headline Section */}
				<Box my={2}>
					<Typography variant='h6'>Tentang Anggaran</Typography>
				</Box>

				{/* About Section */}
				<Box mb={1}>
					<Paper>
						<Box p={2}>
							Dengan menggunakan fitur anggaran maka setiap akan memproses Quotation ke
							tahap selanjutnya, pemohon terpilih wajib menginformasikan anggaran yang
							akan digunakan untuk transaksi tersebut, sehingga diharapkan perusahaan
							Anda dapat lebih mudah mengontrol transaksi yang dilakukan pada
							Mbiz.co.id
						</Box>
					</Paper>
				</Box>

				{/* Pembuatan & Penggunaan Anggaran Section */}
				<Box mb={1}>
					<Paper>
						<Box p={2}>
							<Typography variant='h6' gutterBottom>
								Pembuatan & Penggunaan Anggaran
							</Typography>
							<Divider />
							<Box mt={2}>
								<ul>
									<li>
										Pembuatan anggaran dapat dilakukan oleh akun pembeli yang memiliki
										role <strong>Admin</strong> atau <strong>Finance</strong>
									</li>
									<li>
										Proses pemilihan anggaran akan dilakukan oleh akun pembeli dengan role{' '}
										<strong>Pemohon</strong> pada saat akan memproses Quotation menjadi
										Purchase Request (jika manajemen persetujuan perusahaan aktif) ataupun
										menjadi Purchase Order (jika manajemen persetujuan perusahaan
										nonaktif)
									</li>
									<li>
										Pemohon yang wajib memilih anggaran pada saat akan memproses Quotation
										ke tahap berikutnya adalah Pemohon yang departemennya diikutsertakan
										sebagai pengguna anggaran
									</li>
									<li>
										Setelah pemohon memilih anggaran dan berhasil memproses Quotation
										menjadi Purchase Request/Purchase Order, maka{' '}
										<strong>
											sisa anggaran akan berkurang sejumlah nominal Subtotal yang tertera
											pada Quotation (harga di luar pajak yang terdapat pada transaksi)
										</strong>
									</li>
								</ul>
							</Box>
						</Box>
					</Paper>
				</Box>

				{/* Pengembalian Nilai Anggaran Section */}
				<Box mb={1}>
					<Paper>
						<Box p={2}>
							<Typography variant='h6' gutterBottom>
								Pengembalian Nilai Anggaran
							</Typography>
							<Divider />
							<Box mt={2}>
								<ul>
									<li>
										Sisa anggaran akan ditambahkan kembali jika Mbiz membatalkan dokumen
										Quotation yang sudah memiliki dokumen Purchase Request
									</li>
									<li>
										Sisa anggaran akan ditambahkan kembali jika Mbiz merevisi dokumen
										Quotation yang dilakukan Mbiz saat Quotation tersebut sudah terbentuk
										dokumen Purchase Request-nya
									</li>
									<li>
										Sisa anggaran akan ditambahkan kembali jika terdapat penolakan
										Purchase Request yang dilakukan oleh Penyetuju
									</li>
									<li>
										Sisa anggaran akan ditambahkan kembali jika Mbiz membatalkan Purchase
										Order sebelum status pengiriman Purchase Order tersebut dikirim
										seluruhnya
									</li>
								</ul>
							</Box>
						</Box>
					</Paper>
				</Box>

				{/* Button Kembali */}
				<Box my={2}>
					<Grid container justify='flex-end'>
						<Grid item>
							<Box>
								<Button
									size='small'
									variant='outlined'
									color='primary'
									onClick={(event) => {
										event.preventDefault()
										navigate('/dashboard/purchase-setting/budget', {
											replace: true,
										})
									}}
								>
									Kembali
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</DashboardLayout>
	)
}

export default AboutBudget
