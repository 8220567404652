/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import styles from '../Transactions/styles'
import Beadcrums from 'Components/breadcrumbs'
import {
	Box,
	Container,
	Typography,
	Button,
	Grid,
	Paper,
	Tooltip,
	CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import DashboardLayout from 'Components/dashboardLayout'
import DatePickerRange from 'Components/DateRange'
import {
	breadcrums,
	defaultMandatory,
	findSelectedColumn,
	mou_columns,
} from './helper/generalHelper'
import { Multiple } from '../../../components/dropdown'
import { format } from 'date-fns'
import { dateFormat } from 'Utilities/globalUtils'
import * as qs from 'query-string'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useSelector, useDispatch } from 'react-redux'
import { chunkSizeArray, isValidDate, newUri } from '../../../utils/globalUtils'
import { getReportFile } from 'States/actions/Transactions/Report/downloadFileAction'
import TableData from 'Components/tableData'
import { loadDataColumnTable } from '../Transactions/helper/loadData'
import { fileExport } from '../../../utils/exports'

const UnduhDataMOU = (props) => {
	const classes = styles()
	const dispatch = useDispatch()
	const reportFile = useSelector((state) => state.reportFile)
	const columns = mou_columns
	const colMandatory = defaultMandatory

	const queryString = qs.parse(props.location.search)

	const perPage = 10
	const [page, setPage] = useState(queryString.page ? queryString.page : 1)
	const [startDate, setStartDate] = useState(queryString.start_date ? queryString.start_date : '')
	const [endDate, setEndDate] = useState(queryString.end_date ? queryString.end_date : '')
	const [colSelected, setColSelected] = useState(
		queryString.columns ? findSelectedColumn(queryString.columns) : defaultMandatory
	)
	const [selectedAll, setSelectedAll] = useState(false)
	const [indeterminate, setIndeterminate] = useState(false)

	queryString.per_page = perPage
	queryString.page = page
	queryString.columns = colSelected.length ? colSelected.map((val) => val?.index).join(',') : ''
	queryString.start_date = startDate
	queryString.end_date = endDate

	const joinDate = (startDate, endDate) => `${startDate} ~ ${endDate}`

	const [placeHolder, setPlaceHolder] = useState(
		startDate && endDate ? joinDate(startDate, endDate) : 'Rentang Tanggal'
	)
	const [color, setColor] = useState(
		isValidDate(startDate) && isValidDate(startDate) ? '#007BFF' : '#a9acaf'
	)

	const [dataDownload, setDataDownload] = useState([])
	const [range, setRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	])
	const [dataFile, setDataFile] = useState([])
	const [headerData, setHeaderData] = useState([])
	const [renderData, setRenderData] = useState(false)

	useEffect(() => {
		let _query = newUri(queryString)
		propsNavigate(_query)
	}, [])

	const propsNavigate = (query) => {
		window.history.replaceState(null, null, `?${query}`)
	}

	const handleOnChange = (item) => {
		const str = joinDate(
			format(item.selection.startDate, 'yyyy-MM-dd'),
			format(item.selection.endDate, 'yyyy-MM-dd')
		)
		setPlaceHolder(str)
		setRange([item.selection])
		setColor('#007BFF')
		setStartDate(dateFormat(item.selection.startDate, 'yyyy-MM-dd'))
		setEndDate(dateFormat(item.selection.endDate, 'yyyy-MM-dd'))
		queryString.start_date = dateFormat(item.selection.startDate, 'yyyy-MM-dd')
		queryString.end_date = dateFormat(item.selection.endDate, 'yyyy-MM-dd')

		let _query = newUri(queryString)
		propsNavigate(_query)
	}

	const handleOnClear = () => {
		setPlaceHolder('MOU Dimulai')
		setColor('#a9acaf')
		setRange([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		])
		setStartDate('')
		setEndDate('')
		queryString.start_date = ''
		queryString.end_date = ''

		let _query = newUri(queryString)
		propsNavigate(_query)
	}

	const handleChange = (event) => {
		setColSelected(event.target.value)
	}

	const handleSelecAll = (_selectAll) => {
		if (!_selectAll) {
			setSelectedAll(true)
			setColSelected(columns)
		} else {
			setColSelected(defaultMandatory)
			setSelectedAll(false)
		}
	}

	// define effect here

	useEffect(() => {
			if (!startDate && !endDate) {
				setColor('#a9acaf')
			}
			if (defaultMandatory.length >= colSelected.length) {
				setColSelected(defaultMandatory)
			}
	}, [])

	useEffect(() => {
		if (columns.length) {
			if (selectedAll && colSelected.length < columns.length) {
				setIndeterminate(true)
				setSelectedAll(false)
			}
			if (indeterminate && colSelected.length == columns.length) {
				setIndeterminate(false)
				setSelectedAll(true)
			}
			if (colSelected.length == columns.length) {
				setSelectedAll(true)
			}
			queryString.columns = colSelected.length ? colSelected.map((val) => val?.index).join(',') : ''
			let _query = newUri(queryString)
			propsNavigate(_query)
		}
	}, [colSelected, columns, selectedAll])

	useEffect(() => {
		if (columns.length) {
			if (selectedAll && colSelected.length < columns.length) {
				setIndeterminate(true)
				setSelectedAll(false)
			}
			if (indeterminate && colSelected.length == columns.length) {
				setIndeterminate(false)
				setSelectedAll(true)
			}
			if (colSelected.length == columns.length) {
				setSelectedAll(true)
			}
			queryString.columns = colSelected.length ? colSelected.map((val) => val?.index).join(',') : ''
			let _query = newUri(queryString)
			propsNavigate(_query)
		}
	}, [colSelected, columns, selectedAll])

	const handleLoadData = () => {
		setPage(1)
		queryString.page = 1
		let date = { start_date: startDate, end_date: endDate }
		let urlData = '/report-management/mou'
		setRenderData(true)
		dispatch(getReportFile('mou', date, urlData))
		let _query = newUri(queryString)
		propsNavigate(_query)
	}

	const handlePageChange = (event, page) => {
		setPage(page)
		queryString.page = page
		let _query = newUri(queryString)
		propsNavigate(_query)
	}

	// hooks for set default state
	useEffect(() => {
		// success
		if (reportFile.data && reportFile.data.result) {
			let result = reportFile.data.result
			if (result) {
				let header = loadDataColumnTable(colSelected)
				let temp = chunkSizeArray(result, 10)
				setDataFile(temp)
				setHeaderData(header)
				setDataDownload(result)
			}
		} else {
			setDataFile([])
			setDataDownload([])
			setHeaderData([])
		}
	}, [reportFile])

	const exportData = (typeFile) => {
		let namaFile = 'Daftar MOU'
		fileExport(headerData, namaFile, dataDownload, typeFile)
	}
	return (
		<DashboardLayout>
			<Container className={classes.container}>
				<Box>
					<Beadcrums breadcrumbs={breadcrums} />
				</Box>
				<Box className={'searchContainer'}>
					<Typography variant='h6'>Unduh Data MOU</Typography>
					<br />
				</Box>
				<Grid container spacing={3} direction='row'>
					<Grid item xs={3}>
						<DatePickerRange
							ranges={range}
							color={color}
							onSelectDate={(item) => handleOnChange(item)}
							placeholder={placeHolder}
							onClear={() => handleOnClear()}
							isReport={true}
							isPlaceHolder={placeHolder == 'MOU Dimulai' ? true : false}
						/>
					</Grid>
					<Grid item xs={3}>
						<Multiple
							colSelected={colSelected}
							selectedAll={selectedAll}
							handleSelecAll={handleSelecAll}
							handleChange={handleChange}
							indeterminat={indeterminate}
							columns={columns}
							colMandatory={colMandatory}
						/>
					</Grid>
					<Grid item xs={3}>
						<Tooltip
							classes={{ tooltip: classes.tooltip }}
							title='Mohon lakukan pemilihan rentang tanggal dan kolom terlebih dahulu'
							arrow={true}
							placement='right'
							disableHoverListener={
								colSelected.length != 0 && startDate ? true : false
							}
						>
							<span>
								<Button
									variant='contained'
									color='secondary'
									size='small'
									onClick={() => {
										handleLoadData()
									}}
									disabled={
										colSelected.length != 0 && startDate && !reportFile.isLoading
											? false
											: true
									}
									className={classes.buttonStyle}
								>
									{reportFile.isLoading ? <CircularProgress size={15} /> : 'Muat Data'}
								</Button>
							</span>
						</Tooltip>
					</Grid>

					{headerData && headerData.length != 0 ? (
						<Grid
							item
							xs={3}
							container
							justify='flex-end'
							alignItems='flex-start'
							style={{ marginTop: -30 }}
						>
							<div>
								<Typography variant='subtitle1'>Unduh Data Sebagai</Typography>
							</div>
							<div
								style={{
									flexDirection: 'row',
								}}
							>
								<Button
									variant='contained'
									color='secondary'
									className={classes.buttonUnduh}
									size='small'
									startIcon={<GetAppIcon />}
									onClick={() => {
										exportData('.csv')
									}}
								>
									.csv
								</Button>
								<Button
									variant='contained'
									color='secondary'
									className={classes.buttonUnduh}
									size='small'
									startIcon={<GetAppIcon />}
									onClick={() => {
										exportData('.xls')
									}}
								>
									.xls
								</Button>
							</div>
						</Grid>
					) : null}
				</Grid>
				{renderData ? (
					<Box>
						<TableData
							perPage={perPage}
							// limitChange={handlePerRowsChange}
							columns={headerData}
							isLoading={reportFile.isLoading}
							pagination={
								!reportFile.isLoading && reportFile.data
									? reportFile.data.pagination
									: false
							}
							data={dataFile[page - 1]}
							name={'report'}
							paginationPageChange={handlePageChange}
							pageNow={page}
							// limitValue={limitValue}
							isReport={true}
							totalRowData={dataDownload ? dataDownload.length : 1}
						/>
					</Box>
				) : (
					<Paper variant='outlined' className={classes.boxSpacing}>
						<Box
							height='100%'
							display='flex'
							width='100%'
							alignItems='center'
							justifyContent='center'
						>
							<Typography variant='body1'>Belum Ada Data yang Dimuat</Typography>
						</Box>
					</Paper>
				)}
			</Container>
		</DashboardLayout>
	)
}

UnduhDataMOU.propTypes = {
	navigate: PropTypes.func,
}

export default React.memo(UnduhDataMOU)
