import { makeStyles } from '@material-ui/core/styles'
import backgroundRegister from '../../assets/images/background/register-backgrounds.jpg'

export const registerStyle = makeStyles((theme) => ({
	containers: {
		margin: 0,
		position: 'absolute',
		top: '50%',
		msTransform: 'translateY(-50%)',
		transform: 'translateY(-50%)',
		left: 0,
		right: 0,
	},
	register_background: {
		width: '100%',
		height: '100vh',
		background: `linear-gradient(60deg, rgba(98,141,193,0.75) 0%, rgba(27,42,68,0.75) 15%, rgba(27,42,68,0.85) 40%, rgba(98,141,193,0.25) 90%),url(${backgroundRegister}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		alignContent: 'center',
		textAlign: 'center',
	},
	detailWrapper: {
		textAlign: 'left',
		padding: theme.spacing(0, 4),
		display: 'flex',
		position: 'relative',
	},
	detail: {
		color: theme.palette.common.white,
		zIndex: 1,
	},
	detailTitle: {
		fontWeight: 'bold',
	},
	mbiz_image: {
		width: '150px',
		margin: '0',
		cursor: 'pointer',
		marginLeft: '-20px',
	},
	registerDivider: {
		marginBottom: theme.spacing(3),
		backgroundColor: '#777',
	},
	uri: {
		color: '#357ab6',
		cursor: 'pointer',
	},
	back: {
		display: 'flex',
		color: '#357ab6',
		cursor: 'pointer',
	},
	BoxMB: {
		marginBottom: theme.spacing(3),
		[theme.breakpoints.only('md')]: {
			marginBottom: theme.spacing(2),
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(1),
		},
	},
}))
