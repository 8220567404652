import { makeStyles } from '@material-ui/core/styles'

export const detailBudgetStyle = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.black,
	},
	backgroundTransparent: {
		backgroundColor: 'transparent',
	},
	centerBox: {
		alignItems: 'center',
	},
	columnBox: {
		display: 'flex',
		flexDirection: 'column',
	},
	detailBox: {
		display: 'flex',
		width: '100%',
		marginBottom: '8px',
	},
	departmentList: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	fontSize12: {
		fontSize: 12,
	},
	fontSize14: {
		fontSize: 14,
	},
	historyBox: {
		padding: '16px',
	},
	historyBody: {
		borderTop: '2px solid #A9ACAF4D',
		paddingTop: '8px',
	},
	historyDesc: {
		width: '100%',
		fontSize: 14,
	},
	historyHead: {
		display: 'flex',
		width: '100%',
		marginBottom: '8px',
	},
	iconColor: {
		color: theme.palette.common.black,
	},
	paginationBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
	},
	paginationWord: {
		fontSize: 16,
		marginRight: '5px',
	},
	rowBox: {
		minWidth: '250px',
	},
	smallICon: {
		width: 16,
		height: 16,
		paddingTop: '-2px',
		paddingBottom: '2px',
	},
	successLabel: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.success.main,
		padding: '4px',
		marginRight: '8px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '8px',
	},
	tooltipBox: {
		display: 'flex',
		alignItems: 'center',
	},
	tooltipContainer: {
		backgroundColor: theme.palette.common.black,
		boxShadow: theme.shadows[2],
		color: 'white',
		maxWidth: 300,
	},
	warningLabel: {
		backgroundColor: theme.palette.warning.light,
		color: theme.palette.common.black,
		padding: '8px',
		marginTop: '5px',
	},
}))
